// @flow

export const ValidationStatus: GSPValidationStatus = {
    REQUESTED: "REQUESTED",
    FETCHED: "FETCHED",
    CONTACT_SELECTED: "CONTACT_SELECTED",
    ADMIN_SELECTED: "ADMIN_SELECTED",
    EMAIL_REQUESTED: "EMAIL_REQUESTED",
    EMAIL_REQUESTED_FAILED: "EMAIL_REQUESTED_FAILED",
    EMAIL_SENT: "EMAIL_SENT",
    REJECTED: "REJECTED",
    APPROVED: "APPROVED",
    INITIALISED: "INITIALISED",
};


