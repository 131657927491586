import {Converter} from "./Converter";
import {PagingController} from "./PagingController";


export class SearchController {
    results;

    constructor(data) {
        this.results = data || [];
    }

    search(term, fields) {
        if (!fields || fields.length === 0) {
            return new SearchController(this.results);
        }

        let itemsFiltered = [];
        for (let i = 0; i < this.results.length; i++) {
            const item = this.results[i];

            for (let j = 0; j < fields.length; j++) {
                const fieldName = fields[j];
                if (!item.hasOwnProperty(fieldName)) {
                    break;
                }


                let compareable = item;

                if (compareable && compareable[fieldName]) {
                    if (typeof compareable[fieldName] === "number") {
                        compareable[fieldName] = compareable[fieldName].toString();
                    }
                    if (compareable[fieldName].toLocaleLowerCase().includes(term.toLowerCase())) {
                        itemsFiltered.push(item);
                        break;
                    }
                }

            }
        }
        return new SearchController(itemsFiltered);
    }

    orderBy(sortBy, direction, cmpCallback) {
        const cmp = cmpCallback ? cmpCallback : Converter.cmpString;

        const itemsOrdered = this.results.sort((a, b) => {
            return cmp(a[sortBy], b[sortBy], direction === "asc");
        });
        return new PagingController(itemsOrdered);
    }
}
