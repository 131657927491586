import styled from "styled-components";

export const Dot = styled.div
    .attrs({className: 'dot'})`
  height: 12px;
  width: 12px;
  border-radius: 100%;
`;

export const DotHigh = styled(Dot)
    .attrs({className: 'dot-high'})`
  background: #D40054;
`;

export const DotMedium = styled(Dot)
    .attrs({className: 'dot-medium'})`
  background: #FCB800;
`;

export const DotLow = styled(Dot)
    .attrs({className: 'dot-low'})`
  background: #7DB5D0;
`;

export const DotGrey = styled(Dot)
    .attrs({className: 'dot-grey'})`
  background: #A0A0A0;
`;
