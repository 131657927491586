//@flow

import {Drawer, withStyles} from "@material-ui/core";
import React from "react";


const styles = theme => ({
    rightPane: {
        width: "auto",
    },
    SlideIn: {
        height: "100%",
        overflowX: "hidden",
    },
});

type Props = {
    isOpen: boolean;
    classes: typeof styles;
    children: any;
}

class _ModalSlideIn extends React.Component<Props, any> {

    constructor(props) {
        super(props);

        (this: any).state = {
            open: this.props.isOpen,
        };
    }

    render() {
        const {classes} = this.props;

        return (
            <Drawer anchor="right" open={this.props.isOpen}>
                <div className={classes.SlideIn}>
                    {this.props.children}
                </div>
            </Drawer>
        );
    }
}

export const ModalSlideIn = withStyles(styles)(_ModalSlideIn);
