//@flow

import React from "react"
import {withTranslation} from "react-i18next"
import {connect} from "react-redux"
import {Container} from "reactstrap"
import {bindActionCreators, compose} from "redux"
import {InfoText} from "../../../Components/Basic/InfoText"
import {ListContainer} from "../../../Components/Basic/ListContainer"
import {openAlertDialog} from "../../../Components/Dialogs/AlertDialog/actions"
import {ModalSlideIn} from "../../../Components/Dialogs/SlideIn/ModalSlideIn"
import ErrorBoundary from "../../../Components/ErrorBoundary"
import {Button} from "@greenbone/cloud-component-library"
import {Headline} from "../../../StyledComponents/Font/Font"
import {TaskForm} from "./TaskForm"
import {TaskList} from "./TaskList"


type Props = {
    t: any;
}

type State = {
    refresh: boolean;
    isSlideInOpen: boolean;
    id: ?string;
}

class _TaskPage extends React.Component<Props, State> {

    state = {
        isSlideInOpen: false,
        refresh: false,
        id: null,
    };

    openEditTaskSlideIn = (id: string) => {
        this.setState({
            isSlideInOpen: true,
            id: id,
        });
    };

    openCreateTaskSlideIn = (id: string) => {
        this.setState({
            isSlideInOpen: true,
            id: null,
        });
    };

    handleCloseSlideIn = () => {
        this.setState({
            isSlideInOpen: false,
            refresh: !this.state.refresh,
        });
    };

    render() {
        const {t} = this.props;
        return (
            <div>
                <Headline>{t("task.taskPage.headline")}</Headline>
                <InfoText headline={""}>
                    <p>
                        {t("task.taskPage.infoText")}
                    </p>
                </InfoText>

                <ListContainer>
                    <ErrorBoundary>
                        <TaskList actions={<Button id={"CREATE_TASK"} style={{margin: 0}}
                                                   onClick={this.openCreateTaskSlideIn}>
                            {t("task.taskPage.createNewTask")}
                        </Button>} refresh={this.state.refresh} editAction={this.openEditTaskSlideIn}/>
                    </ErrorBoundary>
                    <ModalSlideIn isOpen={this.state.isSlideInOpen} key={2}>
                        <Container fluid style={{maxWidth: "800px", minWidth: "800px", padding: "4rem"}}>
                            <TaskForm onClose={this.handleCloseSlideIn} id={this.state.id}/>
                        </Container>
                    </ModalSlideIn>
                </ListContainer>
            </div>

        );
    }
}

function mapDispatchToProps(dispatch: any) {
    let actions = bindActionCreators(
        {
            openAlertDialog: openAlertDialog,
        },
        dispatch,
    );
    return {...actions, dispatch};
}

function mapStateToProps(state) {
    return {};
}

export const TaskPage = compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps),
)(_TaskPage);
