//@flow

import {Grid, withTheme} from "@material-ui/core";
import React from "react";
import {withTranslation} from "react-i18next";
import {compose} from "redux";

import {Caption, Subheadline, Text} from "../../../StyledComponents/Font/Font";


type Props = {
    task: GSPTask;
    theme: any;
    t: any;
}

class _TaskInformation extends React.Component<Props, {}> {
    render() {
        const {task, theme, t} = this.props;

        return <div style={{maxWidth: "31.25rem"}}>
            <Subheadline>                {task.name}            </Subheadline>
            <div>
                <Grid container spacing={0} justifyContent="center">
                    <Grid item xs={theme.grid.width.full}>
                        <Caption>
                            {t("scanmanagement.taskInformation.comment")}
                        </Caption>
                    </Grid>
                    <Grid item xs={theme.grid.width.full}>
                        <Text>
                            {task.comment || t("common.noComment")}
                        </Text>
                    </Grid>
                </Grid>
            </div>

        </div>;
    }
}

export const TaskInformation = compose(
    withTheme,
    withTranslation()
)(_TaskInformation);
