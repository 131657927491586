import React, {useContext, useEffect, useState} from "react"
import {ReportContext} from "./ReportContext"
import Select from "react-select"
import {Slider} from "./components/Slider"
import {ReportHelper} from "./ReportHelper"
import {
    Button,
    Caption,
    FlexColumn,
    FlexRow,
    GhostButton,
    SlideIn,
    Subheadline
} from "@greenbone/cloud-component-library"
import {SolutionFilter} from "./components/SolutionFilter"
import {Col, Row} from "reactstrap"
import {useTranslation} from "react-i18next"


export function ReportFilter(props) {
    const {
        filter, uniqueOperatingSystems, uniqueHostNames, uniqueHosts,
        uniquePorts, applyFilter, filterOpen, openFilter, closeFilter
    } = useContext(ReportContext)
    const [state, setState] = useState({...(filter || {})})
    const {
        maximalQod = 10,
        maximalSeverity = 10,
        minimalQod = 0,
        minimalSeverity = 0,
        hosts = [],
        ports = [],
        solutionTypes = [],
        operatingSystems = [],
        hostnames = []
    } = state

    const {t} = useTranslation()

    useEffect(() => {
        if (filter) {
            setState(filter)
        }
    }, [filter])

    return <>

        <Button onClick={openFilter}>Filter + </Button>

        <SlideIn onClose={(event) => {
            const target = event.target
            const tagName = target.tagName?.toLowerCase()

            if (tagName === "button" || tagName === "svg" || tagName === "path") {
                closeFilter(event)
            }

        }} open={filterOpen}>

            <FlexColumn fullHeight justifyContent={"space-between"}
                        style={{width: "35rem", paddingLeft: "1.5rem", paddingRight: "1.5rem", height: "100%"}}>
                <Row>
                    <Col>
                        <Subheadline>{t("report.filter.title")}</Subheadline>
                    </Col>
                </Row>

                <Row style={{marginBottom: "1.5rem"}}>
                    <Col>
                        <Caption>
                            {t("report.filter.qualityOfDetection")}

                        </Caption>
                        <Slider min={minimalQod} max={maximalQod} scale={[0, 100]} step={1} showStartStop
                                onChange={minMax => {
                                    setState(prevState => ({
                                        ...prevState,
                                        minimalQod: minMax[0],
                                        maximalQod: minMax[1]
                                    }))
                                }}/>
                    </Col>
                </Row>
                <Row style={{marginBottom: "1.5rem"}}>
                    <Col>
                        <Caption>{t("report.filter.severity")}</Caption>
                        <Slider min={minimalSeverity}
                                max={maximalSeverity}
                                scale={[0, 10]}
                                showScale
                                step={1}
                                tooltipValue={val => `${val}`}
                                tooltipColor={val => ReportHelper.SeverityToColor(parseInt(val))}
                                customGradient={[
                                    {from: 0, to: 4, color: "#7DB5D0"},
                                    {from: 4, to: 7, color: "#FCB800"},
                                    {from: 7, to: 10, color: "#D4003E"}
                                ]} onChange={minMax => {
                            setState(prevState => ({
                                ...prevState,
                                minimalSeverity: minMax[0],
                                maximalSeverity: minMax[1]
                            }))
                        }}/>
                    </Col>
                </Row>
                <Row style={{marginBottom: "1.5rem"}}>
                    <Col>
                        <Caption>
                            {t("report.filter.solution")}

                        </Caption>
                        <SolutionFilter

                            value={solutionTypes}
                            onChange={(value) => {
                                setState(prevState => ({
                                    ...prevState, solutionTypes: value
                                }))
                            }}
                        />
                    </Col>
                </Row>

                <Row style={{marginBottom: "1.5rem"}}>
                    <Col>
                        <Caption>{t("report.filter.port")}</Caption>
                        <Select options={uniquePorts?.map(port => ({value: port, label: port}))}
                                isMulti
                                value={ports?.map(port => ({value: port, label: port}))}
                                onChange={(value) => {
                                    setState(prevState => ({
                                        ...prevState,
                                        ports: value ? value?.map(port => port.value) : []
                                    }))

                                }}
                                name={"port"}
                                label={t("report.filter.port")}
                        />
                    </Col>
                </Row>
                <Row style={{marginBottom: "1.5rem"}}>
                    <Col>
                        <Caption>{t("report.filter.host")}</Caption>
                        <Select options={uniqueHosts?.map(host => ({value: host, label: host}))}
                                isMulti
                                value={hosts?.map(host => ({value: host, label: host}))}
                                onChange={(value) => {
                                    setState(prevState => ({
                                        ...prevState,
                                        hosts: value ? value?.map(host => host.value) : []
                                    }))

                                }}
                                name={"host"}
                                label={t("report.filter.host")}
                        />
                    </Col>
                </Row>

                <Row style={{marginBottom: "1.5rem"}}>
                    <Col>
                        <Caption>{t("report.filter.hostname")}</Caption>
                        <Select options={uniqueHostNames?.map(os => ({value: os, label: os}))}
                                isMulti
                                value={hostnames?.map(host => ({value: host, label: host}))}
                                onChange={(value) => {
                                    setState(prevState => ({
                                        ...prevState,
                                        hostnames: value ? value?.map(os => os.value) : []
                                    }))

                                }}
                                name={"hostname"}
                                label={t("report.filter.hostname")}
                        />
                    </Col>
                </Row>

                <Row style={{marginBottom: "1.5rem"}}>
                    <Col>
                        <Caption>{t("report.filter.os")}</Caption>
                        <Select options={uniqueOperatingSystems?.map(os => ({value: os, label: os}))}
                                isMulti
                                value={operatingSystems?.map(host => ({value: host, label: host}))}
                                onChange={(value) => {
                                    setState(prevState => ({
                                        ...prevState,
                                        operatingSystems: value ? value?.map(os => os.value) : []
                                    }))

                                }}
                                name={"operatingSystem"}
                                label={t("report.filter.os")}
                        />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <GhostButton onClick={() => closeFilter()}>
                            {t("report.filter.action.abort")}

                        </GhostButton>
                    </Col>
                    <Col>
                        <FlexRow justifyContent={"flex-end"}>
                            <Button onClick={() => applyFilter(state)}>
                                {t("report.filter.action.apply")}

                            </Button>
                        </FlexRow>
                    </Col>
                </Row>
            </FlexColumn>

        </SlideIn>

    </>

}
