import React, {useContext, useEffect, useMemo} from "react"
import {SimpleEntityContext} from "../../../services/Context/SimpleEntityContext"
import {DynamicTable, DynamicTableCell, DynamicTableRow} from "../../Task/components/Table/DynamicTable"
import {useTranslation} from "react-i18next"
import {DateTimeFormatter} from "../../../controller/DateTimeFormatter"
import {ColoredLabel} from "../../../Components/Label/ColorLabel"
import {IntervalTypeColorReadable, IntervalTypeReadable, IntervalTypeReadableString} from "./ScheduleForm"
import ActionElements from "../../../Components/ActionElements"

export function ScheduleList({actions, onUpdateEntry}) {

    const {schedules, deleteSchedule, updateSchedules} = useContext(SimpleEntityContext)
    const memoizedUpdateSchedules = useMemo(() => updateSchedules, [updateSchedules])

    useEffect(() => {
        memoizedUpdateSchedules()
    }, [memoizedUpdateSchedules])

    const data = React.useMemo(() => schedules, [schedules])

    const {t} = useTranslation()

    const entryGenerator = ({row, getValue, dataset}) => {
        return <DynamicTableRow {...row.getRowProps()}>
            <DynamicTableCell>
                {getValue("name")}
            </DynamicTableCell>
            <DynamicTableCell>
                {getValue("comment")}
            </DynamicTableCell>
            <DynamicTableCell>
                {DateTimeFormatter.toDateTimeString(dataset.startDate)}
            </DynamicTableCell>
            <DynamicTableCell>
                {DateTimeFormatter.toDateTimeString(dataset.endDate)}
            </DynamicTableCell>
            <DynamicTableCell>
                <ColoredLabel
                    color={IntervalTypeColorReadable[dataset.intervalType].mainColor}
                    borderColor={IntervalTypeColorReadable[dataset.intervalType].borderColor}
                >
                    {IntervalTypeReadable[dataset.intervalType]}
                </ColoredLabel>
            </DynamicTableCell>
            <DynamicTableCell>
                {dataset.intervalGap}
            </DynamicTableCell>
            <DynamicTableCell>
                <ActionElements
                    entity={dataset}
                    onUpdateEntry={() => onUpdateEntry(dataset.id)}
                    deleteEntity={() => deleteSchedule(dataset.id)}
                    confirmMessage={t("scheduleEntry.delete", {scheduleName: dataset.name})}
                />
            </DynamicTableCell>
        </DynamicTableRow>
    }

    const columns = [
        {
            Header: t("schedule.list.name"),
            accessor: "name"
        },
        {
            Header: t("schedule.list.comment"),
            accessor: "comment"
        },
        {
            Header: t("schedule.list.startDate"),
            id: "startDate",
            accessor: row => DateTimeFormatter.toDateTimeString(row.startDate)
        },
        {
            Header: t("schedule.list.endDate"),
            id: "endDate",
            accessor: row => DateTimeFormatter.toDateTimeString(row.endDate)
        },
        {
            Header: t("schedule.list.intervalType"),
            id: "intervalType",
            accessor: row => {
                const a = IntervalTypeReadableString[row.intervalType]()
                return a
            }
        },
        {
            Header: t("schedule.list.intervalGap"),
            accessor: "intervalGap"
        },
        {
            Header: t("schedule.list.action"),
            accessor: () => ""
        }
    ]

    return <DynamicTable
        data={data}
        actions={actions}
        entryGenerator={entryGenerator}
        columns={columns}
        searchableColumns={["name", "startDate", "endDate", "intervalType", "intervalGap"]}
        defaultSortBy={["name"]}
        cacheKey={"ScheduleList/2020-10-01"}

    />
}
