//@flow

import {SIODateTime} from "../../controller/SIODateTime";


export class ChartistGraphDataController {

    static getTicks(data: any) {

        if (!data || data.series.length <= 0) {
            return [];
        }

        const ticks = [];
        data.series[0].data
            .forEach(coordinates => {
                ticks.push(coordinates.x);
            });

        return ticks;
    }

    static convertSmallReportsToDataPoints = (rawElements: Array<{
        name: string,
        data: Array<GraphSmallReport>
    }>) => {

        const graphElements = [];

        rawElements
            .forEach((dataSet: {
                name: string,
                data: Array<GraphSmallReport>
            }) => {
                let graphDataSet = {name: dataSet.name, data: []};
                dataSet.data
                    .forEach((smallReport: GraphSmallReport) => {
                        if (!smallReport.scanEnd) {
                            return;
                        }
                        const xPos = smallReport.scanEnd.stripTime();
                        const yPos = smallReport.high + smallReport.medium + smallReport.low;
                        graphDataSet.data.push({x: xPos, y: yPos, scanEnd: smallReport.scanEnd});
                    });

                graphElements.push(graphDataSet);
            });

        return graphElements;
    };

    static createDateList = (numberOfDays: number, startDate: ?SIODateTime) => {
        let listOfDates = [];

        const start = startDate || SIODateTime.local();

        for (let i = 0; i < numberOfDays; i++) {
            listOfDates.push(start.add({days: (i * (-1))}).stripTime());
        }

        //$FlowFixMe flow does not understand
        return listOfDates.reverse();
    };

    static filterOutEmptySets(graphDataSets: any) {
        let listWithoutEmptySets = [];

        graphDataSets
            .forEach(dataSet => {
                const pointListWithAtLeastOnePointOnTheYAxes = dataSet.data.filter(point => {
                    return point.y !== null;
                });

                if (pointListWithAtLeastOnePointOnTheYAxes.length >= 1) {
                    listWithoutEmptySets.push(dataSet);
                }
            });

        return listWithoutEmptySets;
    }

    static filterAndInterpolateLastXDays = (graphDataSetList: any, numberOfDays: number, startDate: ?SIODateTime) => {
        let listWithCompleteDateSets = [];

        const listOfDates = ChartistGraphDataController.createDateList(numberOfDays, startDate);

        graphDataSetList
            .forEach(dataSet => {
                let emptyGraphDataSet = {
                    name: dataSet.name,
                    data: []
                };

                listOfDates
                    .forEach((date: SIODateTime) => {
                        let coordinatesForMatchingDate = dataSet.data.filter(entry => {
                            return entry.x.hasSame(date, "day");
                        });

                        coordinatesForMatchingDate = coordinatesForMatchingDate.sort(function (a, b) {
                            return b.scanEnd.toJsDate() - a.scanEnd.toJsDate();
                        });

                        if (coordinatesForMatchingDate.length !== 0) {
                            emptyGraphDataSet.data.push({
                                x: coordinatesForMatchingDate[0].x.toJsDate(),
                                y: coordinatesForMatchingDate[0].y
                            });
                        } else {
                            emptyGraphDataSet.data.push({x: date.toJsDate(), y: null});
                        }

                    });

                listWithCompleteDateSets.push(emptyGraphDataSet);
            });
        return listWithCompleteDateSets;
    };
}
