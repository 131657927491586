import {UrlProvider} from "../../UrlProvider/UrlProvider"

export class ReportNavigationUrlProvider {
    static BASEPATH = `${UrlProvider.ROOT_PATH}/report`;


    static Dashboard() {
        return `${this.BASEPATH}/dashboard`
    }

    static Grid() {
        return `${this.BASEPATH}/grid`
    }

    static Table() {
        return `${this.BASEPATH}/table`
    }

    static TableGroupHost() {
        return `${this.BASEPATH}/table/group/host`
    }

    static TableGroupName() {
        return `${this.BASEPATH}/table/group/name`
    }


}
