import React, {useContext} from "react"
import {ReportContext} from "../ReportContext"
import {Spinner} from "@greenbone/cloud-component-library"
import styled from "styled-components"

const ConstantWidth = styled.div`
    width: 1rem;
`


export function LoadingIndicator() {
    const {hasBeenFlushed} = useContext(ReportContext)

    if (!hasBeenFlushed) {
        return <Spinner/>
    }

    return <ConstantWidth></ConstantWidth>

}
