//@flow

import React from "react";


type Props = {
    children: any;
}

class _ListContainer extends React.Component<Props, {}> {

    render() {
        return (
            <div style={{marginTop: "1.25rem"}}>
                {this.props.children}
            </div>
        );
    }
}

export const ListContainer = _ListContainer;

