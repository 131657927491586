//@flow
import type {ComponentType} from "react"
import styled, {css} from "styled-components"
import {CaptionStyle, HeadlineStyle, SubheadlineStyle, TextStyle, TinyStyle} from "./FontStyles"


type Font = {
    color?: string;
    noMargin?: boolean;
    light?: boolean;
    thin?: boolean;
    marginRight?: string;
    marginLeft?: string;
    marginTop?: string;
    marginBottom?: string;
}

export const SuperHeadline = styled.h1`
  ${HeadlineStyle};
  font-size: 2rem;

`;

export const Headline: ComponentType<Font> = styled.h1`
  ${HeadlineStyle};
    margin: 0;
    margin-bottom: 0.94rem;
  ${props => props.color && css`color: ${props.color};`};
  ${props => props.noMargin && css`margin: 0;`};
  ${props => props.light && css`font-weight: 200`} ;
  ${props => props.thin && css`font-weight: 100`};
  ${props => props.marginRight && css`margin-right: ${props.marginRight}`};
  ${props => props.marginLeft && css`margin-Left: ${props.marginLeft}`};
  ${props => props.marginTop && css`margin-Top: ${props.marginTop}`};
  ${props => props.marginBottom && css`margin-Bottom: ${props.marginBottom}`};
`;

export const Subheadline: ComponentType<Font> = styled.h2`
  ${SubheadlineStyle}
  ${props => props.color && css`color: ${props.color};`}
  ${props => props.noMargin && css`margin: 0;`}
  ${props => props.light && css`font-weight: 200`}
  ${props => props.thin && css`font-weight: 100`}
  ${props => props.marginRight && css`margin-right: ${props.marginRight}`}
  ${props => props.marginLeft && css`margin-Left: ${props.marginLeft}`}
  ${props => props.marginTop && css`margin-Top: ${props.marginTop}`}
  ${props => props.marginBottom && css`margin-Bottom: ${props.marginBottom}`}
`;

export const Caption: ComponentType<Font> = styled.h3`
  ${CaptionStyle}
  ${props => props.color && css`color: ${props.color};`}
  ${props => props.noMargin && css`margin: 0;`}
  ${props => props.light && css`font-weight: 200`}
  ${props => props.thin && css`font-weight: 100`}
  ${props => props.marginRight && css`margin-right: ${props.marginRight}`}
  ${props => props.marginLeft && css`margin-Left: ${props.marginLeft}`}
  ${props => props.marginTop && css`margin-Top: ${props.marginTop}`}
  ${props => props.marginBottom && css`margin-Bottom: ${props.marginBottom}`}
`;

export const Text: ComponentType<Font> = styled.p`
  ${TextStyle}
  ${props => props.color && css`color: ${props.color};`}
  ${props => props.noMargin && css`margin: 0;`}
  ${props => props.light && css`font-weight: 200`}
  ${props => props.thin && css`font-weight: 100`}
  ${props => props.marginRight && css`margin-right: ${props.marginRight}`}
  ${props => props.marginLeft && css`margin-Left: ${props.marginLeft}`}
  ${props => props.marginTop && css`margin-Top: ${props.marginTop}`}
  ${props => props.marginBottom && css`margin-Bottom: ${props.marginBottom}`}
`;

export const Paragraph: ComponentType<Font> = styled.p`
  ${TextStyle};
  margin: 0;
  margin-top: 0.94rem;
  margin-bottom: 0.94rem;
  color: black;
  font-weight: 200;
  ${props => props.color && css`color: ${props.color};`};
  ${props => props.noMargin && css`margin: 0;`};
  ${props => props.light && css`font-weight: 200`} ;
  ${props => props.thin && css`font-weight: 100`};
  ${props => props.marginRight && css`margin-right: ${props.marginRight}`};
  ${props => props.marginLeft && css`margin-Left: ${props.marginLeft}`};
  ${props => props.marginTop && css`margin-Top: ${props.marginTop}`};
  ${props => props.marginBottom && css`margin-Bottom: ${props.marginBottom}`};
`;

export const Tiny: ComponentType<Font> = styled.p`
  ${TinyStyle}
  ${props => props.noMargin && css`margin: 0`}
  ${props => props.color && css`color: ${props.color};`}
  ${props => props.light && css`font-weight: 200`}
  ${props => props.thin && css`font-weight: 100`}
  ${props => props.marginRight && css`margin-right: ${props.marginRight}`}
  ${props => props.marginLeft && css`margin-Left: ${props.marginLeft}`}
  ${props => props.marginTop && css`margin-Top: ${props.marginTop}`}
  ${props => props.marginBottom && css`margin-Bottom: ${props.marginBottom}`}

`;

