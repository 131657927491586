import React from "react"
import styled, {useTheme} from "styled-components"
import {FlexColumn, FlexRow} from "../Flex"

const IconSurrounder = styled.div`
  padding: 6px;
  background: ${({theme}) => theme.base};
  box-shadow: 0px 2px 10px #0476AC4D;
  border-radius: 15px;
  width: 43px;
  height: 43px;
  margin-right: 20px;
  flex-shrink: 0;
  
  div {
    svg {
      color: white;
      width: 100%;
      height: 100%;
    }
  }

`

export function ContentBlock(props) {
    const {icon, children, title} = props

    const {base} = useTheme()

    return <>
        <FlexRow {...props}>
            <IconSurrounder>
                <div>
                    {icon && icon}

                </div>
            </IconSurrounder>
            <FlexColumn>
                <div style={{marginBottom: "5px", fontWeight: "bold", color: base, fontSize: "0.75rem"}}>
                    {title}
                </div>
                {children}
            </FlexColumn>

        </FlexRow>
    </>

}