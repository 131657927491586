//@flow
import React from "react";
import {Trans, withTranslation} from "react-i18next";
import {compose} from "redux";
//$FlowFixMe
import Checklist from "../../../../assets/icons/checklist_blau100.svg";
//$FlowFixMe
import Hourglass from "../../../../assets/icons/hourglass_blau100.svg";
//$FlowFixMe
import Pencil from "../../../../assets/icons/pencil_blue_100.svg";
import {Button, FlexRow} from "@greenbone/cloud-component-library";
import {Bold, Headline} from "../../../StyledComponents/Font/Font";
import {Checkbox} from "../../../StyledComponents/Form/Checkbox";
import {Modal} from "../../../StyledComponents/Modal/Modal";
import {HelpLine} from "../../Help/components/HelpLine";
import {WelcomeScreenController} from "../controller/WelcomeScreenController";


type Props = {
    t: any;
}

type State = {
    doNotShowAgain: boolean;
    checkbox: boolean;
}

class _WelcomeScreen extends React.Component<Props, State> {
    controller: WelcomeScreenController;
    state = {
        doNotShowAgain: false,
        checkbox: false,
    };

    constructor(props: any) {
        super(props);

        this.controller = new WelcomeScreenController();
    }

    componentDidMount() {
        if (this.controller.getDoNotShowAgain() === true) {
            this.setState({doNotShowAgain: true});
        }
    };

    handleDoNotShowAgainChange = (event: any) => {
        this.setState(prevState => {
            return {
                checkbox: !prevState.checkbox,
            };
        });
    };

    handleFormSubmit = (event: any) => {
        event.preventDefault();

        if (this.state.checkbox) {
            this.controller.setDoNotShowAgain(true);
        }

        this.setState({doNotShowAgain: true});
    };

    render() {
        const {t} = this.props;
        return (
            <Modal open={!this.state.doNotShowAgain}>
                <Headline style={{marginBottom: "1.8rem"}}>{t("wizard.components.welcome.toWizard")}</Headline>
                <HelpLine center icon={Hourglass}>
                    <Trans i18nKey={"wizard.welcome.5steps"}>
                        In nur <Bold>5 Schritten</Bold> bereiten Sie einen vollsändigen Scan vor.
                    </Trans>
                </HelpLine>
                <HelpLine center icon={Checklist}>
                    {t("wizard.components.welcome.components")}
                </HelpLine>
                <HelpLine center icon={Pencil}>
                    <Trans i18nKey={"wizard.welcome.afterWizard"}>
                        Alle Elemente sind im <Bold>im Nachhinein bearbeitbar</Bold> und auch <Bold>
                        außerhalb des Wizards anlegbar.
                    </Bold>
                    </Trans>
                </HelpLine>

                <form onSubmit={this.handleFormSubmit} style={{marginTop: "2rem"}}>
                    <FlexRow justifyContent={"space-between"} alignItems={"center"}>
                        <Checkbox onChange={this.handleDoNotShowAgainChange} checked={this.state.checkbox}
                                  type="checkbox" label={t("wizard.components.welcome.doNotShowAgain")}/>

                        <Button>{t("wizard.components.welcome.letsGo")}</Button>
                    </FlexRow>
                </form>
            </Modal>
        );
    }
}

export const WelcomeScreen = compose(
    withTranslation(),
)(_WelcomeScreen);
