import {IconSurrounder} from "./Shared";
import React, {useContext} from "react";
import styled, {css, ThemeContext} from "styled-components";
import {NavLink} from "react-router-dom";

const LinkStyledComponent = styled(
    ({
         highlight,
         linkStyle,
         onFocus,
         onClick,
         to,
         className,
         children
     }) =>
        <NavLink
            style={linkStyle}
            to={to}
            onFocus={onFocus}
            highlight={highlight}
            onClick={onClick}
            className={className}
        >
            {children}
        </NavLink>
)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 4rem;
  padding: 0.63rem;
  padding-left: 1.25rem;
  box-sizing: border-box;
  color: black;


  &:hover {
    text-decoration: none;
    color: ${props => props.theme.sidebar.hoverFont};
    background-color: ${props => props.theme.sidebar.hoverBackground};
  }

  ${props => props.highlight && css`
    background: ${props => props.theme.sidebar.background};

    &:hover {
      background-color: ${props => props.theme.sidebar.hoverBackground};
    }
  `}
  span {
    margin-left: 0.5rem;
  }

  ${props => props.minified && css`
    padding: 0;
    justify-content: center;

    img {
      margin: 0;
    }
  `}
`;

function Link(props) {
    //TODO - jwerremeyer move to styled-components api as soon as it ships --> https://github.com/styled-components/styled-components/pull/2765
    const {activeMenuitem, insetShadow} = useContext(ThemeContext).sidebar;

    return <LinkStyledComponent
        minified={props.minified}
        linkStyle={({isActive}) => {
            if (isActive && !props.highlight) {
                return {
                    background: activeMenuitem,
                    boxShadow: `inset 4px 0 0 ${insetShadow}`
                }
            }

            return {}
        }}
        highlight={props.highlight}
        onFocus={props.onFocus}
        onClick={props.onClick}
        to={props.to}
    >
        {props.children}
    </LinkStyledComponent>;
}


export function MenuItem(
    {
        minified,
        to,
        icon,
        text,
        highlight,
        onFocus,
        onClick
    }
) {
    return <Link
        minified={minified}
        to={to}
        highlight={highlight}
        onFocus={onFocus}
        onClick={onClick}
    >
        <IconSurrounder>
            {icon}
        </IconSurrounder>
        {(minified === false) && <span>{text}</span>}
    </Link>;
}

