//@flow

import React from "react";
import {IS_DEVELOP, IS_LOCAL} from "../../../constants";
import {Headline, Paragraph} from "../../../StyledComponents/Font/Font";


export class EnvironmentDebug extends React.Component<any, any> {
    render() {
        return (
            <div>
                <Headline>Environment</Headline>
                <Paragraph>
                    Look at some .env variables.
                </Paragraph>
                <table border="1">
                    <tr>
                        <td>IS_DEVELOP</td>
                        <td>{IS_DEVELOP === true ? "TRUE" : "FALSE"}</td>
                    </tr>
                    <tr>
                        <td>IS_LOCAL</td>
                        <td>{IS_LOCAL === true ? "TRUE" : "FALSE"}</td>
                    </tr>
                </table>
            </div>
        );
    }
}
