import {TopTenHosts} from "@greenbone/cloud-chart-library"
import React, {useEffect, useState} from "react"
import {ReportOverviewRestApiClient} from "../../service/ReportOverviewRestApiClient"
import {BoxHeader} from "../../components/BoxHeader"
import {FlexRow} from "@greenbone/cloud-component-library"
import Select from "react-select"
import {useTranslation} from "react-i18next"
import {CenteredSpinner} from "../../components/CenteredSpinner";

export function TopHosts({id}) {

    const [data, setData] = useState(null)
    const [bySeverity, setBySeverity] = useState(false)
    const {t} = useTranslation()

    useEffect(() => {
        async function f() {
            const apiClient = new ReportOverviewRestApiClient()

            let response = null
            if (bySeverity) {
                response = await apiClient.getTopHostSortedBySeverity(id)
            } else {
                response = await apiClient.getTopHostSortedByQuantity(id)
            }


            setData(response.data)
        }

        f()
    }, [id, bySeverity])


    if (data) {

        return <>
            <BoxHeader>
                <FlexRow full alignItems={"center"} justifyContent={"space-between"}>
                    {t("report.dashboard.top10Hosts")}

                </FlexRow>
                <div style={{width: "12rem"}}>
                    <Select defaultValue={{
                        value: "Quantity",
                        label: "Quantity"
                    }} onChange={(val) => {
                        if (val.value === "Severity")
                            setBySeverity(true)
                        else
                            setBySeverity(false)
                    }} options={[{
                        value: "Quantity",
                        label: "Quantity"
                    }, {
                        value: "Severity",
                        label: "Severity"
                    }]}>

                    </Select>
                </div>

            </BoxHeader>
            <TopTenHosts data={data}/>
        </>
    }

    return <CenteredSpinner height={230}/>
}
