import React from "react"
import {Route} from "react-router"
import {SubscriptionPage} from "./SubscriptionPage"
import {PlanPage} from "./Slides/PlanPage"
import {TerminationPage} from "./Termination/TerminationPage"
import {PaymentSlide} from "./Slides/PaymentSlide"
import {AddressSlide} from "./Slides/AddressSlide"
import {ConfirmationPage} from "./Slides/ConfirmationPage"
import {Switcher} from "../../Components/Router";


export function SubscriptionRouter() {

    return <Switcher>
        <Route path="/change" Component={PlanPage}/>
        <Route path="/payment" Component={PaymentSlide}/>
        <Route path="/address" Component={AddressSlide}/>
        <Route path="/confirm" Component={ConfirmationPage}/>
        <Route path="/terminate" Component={TerminationPage}/>
        <Route path="/" Component={SubscriptionPage}/>
    </Switcher>
}
