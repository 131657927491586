import React, {useContext} from "react"
import {NavLink} from "react-router-dom"
import {Branding, Content, Navbar, Wrapper} from "./index"
import {FlexColumn, FlexRow} from "../Flex"
import {Tiny} from "../Font"
import {Sidebar} from "../Sidebar"
import {LayoutContext} from ".."
import {InformationBarView} from "../InformationBar/InformationBarView"

const MockMenu = (props) => (
    <Sidebar minified={false} onMouseEnter={() => {
    }}
             onMouseLeave={() => {
             }}>
    </Sidebar>
)

export function ContentPortal({children}) {

    const domElement = document.querySelector("#content-wrapper-subnavbar-renderer")

    if (domElement) {
        return ReactDOM.createPortal(
            children,
            domElement
        )
    }
    return null

}

export function ApplicationLayout({children, logo, Logout, Menu, version, informationBar, ...other}) {

    let setRef = () => {
    }
    const context = useContext(LayoutContext)
    if (context?.setRef) {
        setRef = context?.setRef
    }

    return <>
        <Navbar>
            <FlexRow justifyContent={"space-between"} alignItems={"center"} fullWidth>
                <NavLink to={"/"}>
                    <Branding src={logo}/>
                </NavLink>
                {Logout && <Logout/>}
            </FlexRow>
        </Navbar>

        <Wrapper>
            <Menu/>


            <FlexColumn fullWidth>
                <InformationBarView/>
                <div ref={(ref) => setRef(ref)} id={"content-wrapper-subnavbar-renderer"}>
                </div>
                <Content className={"override-layout-container"} fluid>
                    {children}
                    <FlexRow className={"version-view"} justifyContent={"flex-end"}>
                        <Tiny>Version: {version}</Tiny>
                    </FlexRow>
                </Content>
            </FlexColumn>
        </Wrapper>
    </>
}

export const MockApplicationLayout = ({...props}) => <ApplicationLayout version={"123"} Menu={MockMenu} {...props}/>
