import React from "react"
import {Donut} from "../Graph/Donut/Donut"
import styled from "styled-components"
import {DotHigh, DotLow, DotMedium} from "../Dot/Dot"


const inputData = {
    version: 1,
    data: {
        high: 123,
        medium: 23,
        low: 4
    }
}

const Outer = styled.div`
  padding: 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const FlexRow = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2.5rem;
`;

const BottomGrid = styled.div
    .attrs({className: 'bottom-grid'})`
  display: grid;
  grid-template-columns: 30px 1fr 70px;
  grid-template-rows: 1fr 1fr 1fr;
  grid-row-gap: 20px;
  align-items: center;
  grid-template-areas: ". . ." ". . ." ". . .";
`;
BottomGrid.displayName = "BottomGrid";


export function VulnerabilityBySeverity({high, medium, low, graphStyle, ...other}) {

    return (

        <Outer {...other}>
            <FlexRow style={graphStyle}>
                <Donut data={[
                    {name: "critical", count: high, color: "#D4003E"},
                    {name: "medium", count: medium, color: "#FCB800"},
                    {name: "low", count: low, color: "#7DB5D0"}
                ]}
                       width={322}
                       height={322}
                       accessor={d => d.count}
                />
            </FlexRow>

            <BottomGrid>
                <DotHigh/>
                <div>Critical</div>
                <div style={{justifySelf: "end"}}>{high}</div>
                <DotMedium/>
                <div>Medium</div>
                <div style={{justifySelf: "end"}}>{medium}</div>
                <DotLow/>
                <div>Low</div>
                <div style={{justifySelf: "end"}}>{low}</div>
            </BottomGrid>
        </Outer>


    );

}


