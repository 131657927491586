import React, {useEffect, useState} from "react"
import {Col, Row} from "reactstrap"
import {Button, FlexRow, GhostButton, Input, SearchableSelect} from "@greenbone/cloud-component-library"
import styled from "styled-components"
import {useTranslation} from "react-i18next"


const A = styled.div`
  .MuiFormLabel-root {
    transform: translate(0, 1.5px) scale(0.75);
    transform-origin: top left;
  }
`

export function BillingAddress({style, countries, onSubmit, address, errors, onClose, onChange, disableAction, ...other}) {

    const {t} = useTranslation()
    const [state, setState] = useState(address)


    useEffect(() => {
        if (onChange) {
            onChange(state)
        }
    }, [state, onChange])

    const handleChange = event => {
        const {name, value} = event.target

        setState(prevState => ({...prevState, [name]: value}))
    }

    const handleCancel = event => {
        event.preventDefault()
        onClose()
    }

    const handleSubmit = event => {
        event.preventDefault()
        onSubmit(state)
    }


    return <>
        <form style={style} onSubmit={handleSubmit}>
            <Row>
                <Col xs={3}>
                    <Input onChange={handleChange} isValid={errors?.title} value={state?.title || undefined} name={"title"}
                           margin={"normal"}
                           label={"Title"}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Input isValid={errors?.firstName} value={state?.firstName || undefined} name={"firstName"}
                           onChange={handleChange}
                           margin={"normal"}
                           label={t("billingAddress.firstName")}/>
                </Col>
                <Col>
                    <Input isValid={errors?.lastName} value={state?.lastName || undefined} name={"lastName"} onChange={handleChange}
                           margin={"normal"}
                           label={t("billingAddress.lastName")}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Input isValid={errors?.companyName} value={state?.companyName || undefined} name={"companyName"}
                           onChange={handleChange} margin={"normal"}
                           label={t("billingAddress.company")}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Input isValid={errors?.streetAddress} value={state?.streetAddress || undefined} name={"streetAddress"}
                           onChange={handleChange}
                           margin={"normal"}
                           label={t("billingAddress.streetAddress")}/>
                </Col>
                <Col>
                    <Input isValid={errors?.houseNumber} value={state?.houseNumber || undefined} name={"houseNumber"}
                           onChange={handleChange} margin={"normal"}
                           label={t("billingAddress.houseNumber")}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Input isValid={errors?.postalCode} value={state?.postalCode || undefined} name={"postalCode"}
                           onChange={handleChange} margin={"normal"}
                           label={t("billingAddress.postcode")}/>
                </Col>
                <Col>
                    <Input isValid={errors?.city} name={"city"} value={state?.city || undefined} onChange={handleChange}
                           margin={"normal"}
                           label={t("billingAddress.city")}/>
                </Col>
            </Row>
            <Row style={{marginTop: "1.5rem"}}>
                <Col>
                    <A>
                        <SearchableSelect isValid={errors?.countryId} options={{
                            data: countries,
                            labelFieldName: "name",
                            valueFieldName: "id"
                        }} id={"COUNTRY-SELECT"} value={address.countryId} onChange={handleChange} name={"countryId"}
                                          label={t("billingAddress.country")}/>
                    </A>

                </Col>
                <Col></Col>
            </Row>

            {!disableAction &&
            <Row>
                <Col>
                    <FlexRow justifyContent={"space-between"}>
                        <GhostButton onClick={handleCancel}>{t("billingAddress.abort")}</GhostButton>
                        <Button>{t("billingAddress.save")}</Button>
                    </FlexRow>
                </Col>
            </Row>
            }
        </form>
    </>
}
