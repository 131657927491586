//@flow

import React from "react";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import Loader from "../../../Components/Loader/Loader";
import {CredentialsRestApiClient} from "../../../services/apiClients/Credentials/CredentialsRestApiClient";
import {RemoteResourceRestApiClient} from "../../../services/apiClients/RemoteResource/RemoteResourceRestApiClient";
import {ScheduleRestApiClient} from "../../../services/apiClients/Schedule/ScheduleRestApiClient";
import {TargetRestApiClient} from "../../../services/apiClients/Target/TargetRestApiClient";
import {TaskRestApiClient} from "../../../services/apiClients/Task/TaskRestApiClient";


type Props = {
    targetId: string;
    children: any;
    t: any;
};

class _TargetInformationWrapper extends React.Component<Props, any> {
    taskRestClient: TaskRestApiClient;
    targetRestClient: TargetRestApiClient;
    remoteResourceClient: RemoteResourceRestApiClient;
    schedulesRestClient: ScheduleRestApiClient;
    credentialsRestClient: CredentialsRestApiClient;

    state = {
        target: null,
        portLists: null,
        credentials: null,
        _exception: null,
        _loading: false,
    };

    constructor(props: any) {
        super(props);
        this.taskRestClient = new TaskRestApiClient();
        this.targetRestClient = new TargetRestApiClient();
        this.remoteResourceClient = new RemoteResourceRestApiClient();
        this.schedulesRestClient = new ScheduleRestApiClient();
        this.credentialsRestClient = new CredentialsRestApiClient();
    }

    componentDidMount() {
        this.fetchTaskData();
    }

    fetchTaskData = () => {
        this.setState({_loading: true});

        const taskData = this.taskRestClient.getAll();
        const targetData = this.targetRestClient.getOne(this.props.targetId);
        const scanConfigurationData = this.remoteResourceClient.getScanConfigurations();
        const schedulesData = this.schedulesRestClient.getAll();
        const credentialsData = this.credentialsRestClient.getAll();
        const portListData = this.remoteResourceClient.getPortLists();

        let fetchedData = Promise.all([taskData, targetData, scanConfigurationData, schedulesData, credentialsData, portListData]);
        fetchedData.then(([tasks, target, scanConfigurations, schedules, credentials, portLists]) => {

            this.setState({
                _loading: false,
                tasks: tasks,
                target: target,
                scanConfigurations: scanConfigurations,
                schedules: schedules,
                credentials: credentials,
                portLists: portLists,
            });
        }).catch(error => {
            this.setState({
                _exception: error,
                _loading: false,
            });
        });

    };

    render() {
        const {t} = this.props;
        if (this.state._loading) {
            return (<Loader/>);
        }

        if (!this.state.target) {
            return (
                <div>{t("targetInformation.noTargetFound")}</div>
            );
        }

        const {children} = this.props;
        const childrenWithProps = React.Children.map(children, child =>
            React.cloneElement(child, {
                target: this.state.target,
                portLists: this.state.portLists,
                credentials: this.state.credentials,
            }));

        return <div>{childrenWithProps}</div>;

    }

}

export const TargetInformationWrapper = compose(
    withTranslation(),
)(_TargetInformationWrapper);
