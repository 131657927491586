import React, {useEffect, useState} from "react"
import {Bold, FlexRow, Subheadline, Tiny} from "@greenbone/cloud-component-library"
import {useTranslation} from "react-i18next"
import {GroupRestApiClient} from "../../../UserRestApiClient"
import {SubscriptionRestApiClient} from "../../service/SubscriptionRestApiClient"


export function BillingDetails(props) {

    const [, setLoading] = useState(true)
    const [groupAddress, setGroupAddress] = useState(null)
    const [companyAddress, setCompanyAddress] = useState(null)
    const {t} = useTranslation()

    useEffect(() => {
        async function f() {
            const groupApiClient = new GroupRestApiClient()
            const subscriptionApiClient = new SubscriptionRestApiClient()

            const groupAddress = await groupApiClient.getBillingAddress()
            const companyAddress = await subscriptionApiClient.getGroupBillingAddress()

            setGroupAddress(groupAddress)
            setCompanyAddress(companyAddress)
        }

        f()
    }, [])

    useEffect(() => {
        if (groupAddress && companyAddress) {
            setLoading(false)
        }
    }, [groupAddress, companyAddress])

    let address = groupAddress
    if (companyAddress?.companyName) {
        address = companyAddress
    }

    return <FlexRow justifyContent={"flex-end"}>
        <div style={{paddingRight: "0.5rem"}}>
            <Subheadline>{t("billingDetails.headline")}</Subheadline>
            <Bold>
                {address?.companyName}
            </Bold>
            <br/>
            <Tiny>
                {address?.firstName} {address?.lastName}
            </Tiny>
            <br/>
            <Tiny>
                {address?.streetAddress} {address?.houseNumber}
            </Tiny>
            <br/>
            <Tiny>
                {address?.postalCode} {address?.city}
            </Tiny>
            <br/>
        </div>
    </FlexRow>
}
