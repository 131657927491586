/**
 * Search plugin-hook for react-table https://github.com/tannerlinsley/react-table
 * At the time of creation there was no documentation available on how to build custom hooks.
 * This hook has been build by following the implementation of existing hooks https://github.com/tannerlinsley/react-table/tree/master/src/plugin-hooks
 * TODO - jwerremeyer open a PR on react-table and try to get the hook into the official library
 */

import React from "react"


const actions = {
    setSearchValue: "setSearchValue"
}


export const useSearch = hooks => {
    hooks.stateReducers.push(reducer)
    hooks.useInstance.push(useInstance)
}

useSearch.pluginName = "useSearch"


function reducer(state, action, previousState, instance) {
    if (action.type === actions.init) {
        return {
            searchValue: undefined,
            ...state
        }
    }


    if (action.type === actions.setSearchValue) {
        const {searchValue} = action
        return {
            ...state,
            pageIndex: 0,
            searchValue
        }
    }
}

function toLowerCaseString(val) {
    if (val === undefined || val === null) {
        return ""
    }
    return String(val).toLowerCase()
}

function useInstance(instance) {
    const {
        rows,
        state: {searchValue},
        dispatch
    } = instance


    const setSearchValue = React.useCallback(searchValue => {
        dispatch({type: actions.setSearchValue, searchValue})
    }, [dispatch])


    const searchedRows = React.useMemo(() => {

        if (searchValue === undefined) {
            return rows
        }

        if (searchValue === "") {
            return rows
        }

        const {searchableColumns} = instance
        const ro = rows.filter(row => {
            let included = false
            searchableColumns.forEach(col => {
                const value = toLowerCaseString(row.values[col])
                if (value.indexOf(toLowerCaseString(searchValue)) >= 0) {
                    included = true
                    return
                }
            })
            return included
        })
        return ro
    }, [rows, searchValue, instance])


    Object.assign(instance, {
        rows: searchedRows,
        setSearchValue,
        foundRows: searchedRows,
        searchValue
    })
}

