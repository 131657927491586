import React, {useContext} from "react"
import {ReportContext} from "../ReportContext"
import ArrowUp from "../assets/arrow-up.svg"
import ArrowDown from "../assets/arrow-down.svg"
import styled from "styled-components"
import {useTranslation} from "react-i18next"

const Table = styled.div`
    display: grid;
    grid-template-columns: 3fr .5fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-row-gap: .5rem;

`

export const TableHead = styled.div`
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
`

const UpDown = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 0.5rem;
`

const Icon = styled.div`
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;


    &:hover {
        cursor: pointer;
        background: lightgray;
    }


    svg {
        width: 100%;
        height: 100%;
    }
`

export function Cell({children, handleSort}) {
    return <TableHead style={{fontWeight: "bold"}}>
        {(!!handleSort) && <UpDown>
            <Icon>
                <ArrowUp onClick={() => handleSort("ASC")}/>
            </Icon>
            <Icon>
                <ArrowDown onClick={() => handleSort("DESC")}/>
            </Icon>
        </UpDown>
        }

        {children}
    </TableHead>
}


export function ReportSortingGroupByVulnerability() {

    const {sort} = useContext(ReportContext)
    const {t} = useTranslation()

    return <>
        <Table templateColumns={"3fr 1fr 1fr 1fr 1fr 1fr 1fr"}>
            <TableHead>{t("report.name")}</TableHead>
            <Cell handleSort={sort("severity")}>{t("report.severity")}</Cell>
            <Cell handleSort={sort("host")}>{t("report.host")}</Cell>
            <Cell handleSort={sort("port")}>{t("report.port")}</Cell>
            <Cell handleSort={sort("solutionType")}>{t("report.solution")}</Cell>
            <Cell handleSort={sort("qod")}>{t("report.qod")}</Cell>
            <TableHead>{t("report.details")}</TableHead>
        </Table>


    </>
}


export function ReportSorting({includeOS}) {

    const {sort} = useContext(ReportContext)
    const {t} = useTranslation()

    return <>
        <TableHead>{t("report.name")}</TableHead>
        <Cell handleSort={sort("severity")}>{t("report.severity")}</Cell>
        <Cell>{t("report.operatingSystem")}</Cell>
        <Cell handleSort={sort("host")}>{t("report.host")}</Cell>
        <Cell handleSort={sort("port")}>{t("report.port")}</Cell>
        <Cell handleSort={sort("solutionType")}>{t("report.solution")}</Cell>
        <Cell handleSort={sort("qod")}>{t("report.qod")}</Cell>
        <TableHead>{t("report.details")}</TableHead>
    </>
}
