const isObjectEmpty = obj => Object.keys(obj).length === 0 && obj.constructor === Object


const validate = (schema, createMessage) => data => {

    const errors = {}

    for (const field in schema) {
        if (schema.hasOwnProperty(field)) {
            const value = data[field]
            const validators = schema[field]
            validators.forEach(validator => {
                let result = null
                try {
                    result = validator(createMessage)(value)
                } catch (e) {
                    if (validator.message) {
                        result = validator.message
                    } else {
                        result = e.message
                    }
                }
                if (result) {
                    if (Array.isArray(errors[field])) {
                        errors[field].push(result)
                    } else {
                        errors[field] = []
                        errors[field].push(result)
                    }
                }
            })
        }
    }

    return {
        values: isObjectEmpty(errors) ? {...data} : {},
        errors
    }

}


export const createSchema = (schema, language) => {

    /**
     *
     * @param message - string
     * @param defaultMessages - object containing translations {de: "Hallo", en: "Hello"}
     * @returns {*}
     */
    const createMessage = (message, defaultMessages) => {
        if (message) {
            return message
        }

        if (typeof defaultMessages === "string") {
            return defaultMessages
        }

        if (language) {
            return defaultMessages[language]
        }
        return defaultMessages["en"]
    }

    return validate(schema, createMessage)

}

export const wrapValidation = (evaluator, defaultMessages, message) => (createMessage, returnMessage) => value => {
    if (returnMessage) {
        return createMessage(message, defaultMessages)
    }

    try {
        if (!evaluator(value)) {
            return createMessage(message, defaultMessages)
        }
    } catch (e) {
        return createMessage(message, defaultMessages)
    }

}


