import {MenuItem, SimpleMenu} from "@greenbone/cloud-component-library"
import {UserSettingsUrlProvider} from "../../../../UrlProvider/UserSettingsUrlProvider"
import ProductUpdatesIcon from "../../ProductUpdates/assets/Icon_Product_Updates.svg"
import React, {useContext} from "react"
import {useTheme} from "styled-components"
import {useTranslation} from "react-i18next"
import {ProductUpdateContext} from "../../../../Context/ProductUpdatesProvider"


export function ProductUpdatesMenuItem({minified}) {
    const theme = useTheme()
    const {t} = useTranslation()
    const {isOpen} = useContext(ProductUpdateContext)


    return <SimpleMenu>
        <MenuItem
            highlight={isOpen.toString()}
            minified={minified} to={UserSettingsUrlProvider.hash.productUpdates.url}
            icon={<ProductUpdatesIcon/>} text={t("productInformation.menu.text")}
        />
    </SimpleMenu>
}
