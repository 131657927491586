import {saveAs} from "file-saver"
import React from "react"
import {connect} from "react-redux"
import {bindActionCreators, compose} from "redux"
import styled, {css} from "styled-components"
import DownloadIcon from "../../../../assets/icons/white/download-bottom.svg?url"
import {FlexRow, Snackbar} from "@greenbone/cloud-component-library"
import Loader from "../../../Components/Loader/Loader"
import {openErrorSnackbar} from "../../../Components/Snackbar/actions"
import {Select} from "../../../StyledComponents/Form/Select"
import {Colors} from "../../../Theme"
import {FileReportRestApiClient} from "../../../services/apiClients/Report/FileReportRestApiClient"
import {TaskRestApiClient} from "../../../services/apiClients/Task/TaskRestApiClient"
import {FileReportType} from "./ReportType"
import {ReportContext, withReport} from "../ReportContext"
import {withTranslation} from "react-i18next"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Switch from "@material-ui/core/Switch"
import {Logger} from "../../../controller/loggingController"

const SwitchOverride = styled(Switch)`
    span.MuiSwitch-track {
        opacity: 1 !important;
        background: ${props =>
            props.checked ?
                props.theme.button.normal.background :
                props.theme.button.normal.disabled
        };
    }
`

const options = [
    {value: FileReportType.EXECUTIVE, label: FileReportType.EXECUTIVE},
    {value: FileReportType.TECHNICAL, label: FileReportType.TECHNICAL},
    {value: FileReportType.TECHNICAL_JSON, label: FileReportType.TECHNICAL_JSON},
    {value: FileReportType.EXECUTIVE_JSON, label: FileReportType.EXECUTIVE_JSON},
    {value: FileReportType.XML, label: FileReportType.XML}
]

const DownloadControl = styled(FlexRow)`
    min-width: 12.5rem;
    display: flex;
    flex-direction: column;
    margin-right: 1rem;
`

const SaveButton = styled.button`
    background: ${props => props.theme.button.normal.background};
    min-width: 2.188rem;
    max-width: 2.188rem;
    height: 2.188rem;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    border: none;
    box-shadow: none;

    &:hover {
        cursor: pointer;
        background: ${props => props.theme.button.normal.hover};
    }

    &:active {
        cursor: pointer;
        background: ${props => props.theme.button.normal.hover};
    }

    span {
        display: block;
        height: 100%;
        width: 100%;
        background: url(${props => props.downloadUrl});
        color: white;
        background-size: 80%;
        background-position: center;
        background-repeat: no-repeat;
    }
`

const DownloadText = styled.div`
    color: ${Colors.grey.normal};
    position: absolute;
    margin-top: -1.5rem;
`


class _ReportSelectDownloader extends React.Component {
    fileReportRestApiClient = new FileReportRestApiClient()
    taskRestApiClient = new TaskRestApiClient()

    state = {
        selectedOption: options[0].value,
        anonymized: false,
        _loading: false,
        _exception: null
    }

    handleChange = ({name, value}: { name: string, value: any }) => {
        this.setState({selectedOption: value})
    }

    handleClick = (event: any) => {
        event.preventDefault()

        this.downloadReport(this.state.anonymized)
    }

    downloadReport = async (anonymized) => {
        const {t} = this.props

        try {
            this.setState({_loading: true})
            const {head} = this.context
            const report = head
            const {filter} = this.props

            filter.anonymized = anonymized

            let reportRequest = null
            let fileName = null

            const task = await this.taskRestApiClient.getOne(report.taskId)
            const date = report.scanEnd ? report.scanEnd.toDateString() : ""

            switch (this.state.selectedOption) {
                case FileReportType.EXECUTIVE:
                    reportRequest = this.fileReportRestApiClient.getExecutivePDFReport(report.taskExecutionId, filter)
                    fileName = `${date}_executive_${task.name}_GSP.pdf`
                    break

                case FileReportType.TECHNICAL:
                    reportRequest = this.fileReportRestApiClient.getTechnicalPDFReport(report.taskExecutionId, filter)
                    fileName = `${date}_technical_${task.name}_GSP.pdf`
                    break

                case FileReportType.EXECUTIVE_JSON:
                    reportRequest = this.fileReportRestApiClient.getExecutiveJSONReport(report.taskExecutionId, filter)
                    fileName = `${date}_executive_${task.name}_GSP.json`
                    break

                case FileReportType.TECHNICAL_JSON:
                    reportRequest = this.fileReportRestApiClient.getTechnicalJSONReport(report.taskExecutionId, filter)
                    fileName = `${date}_technical_${task.name}_GSP.json`
                    break


                case FileReportType.XML:
                    reportRequest = this.fileReportRestApiClient.openVasXML(report.taskExecutionId, filter)
                    fileName = `${date}_openvas_${task.name}.xml`
                    break

                default:
                    Logger.warn(`<ReportSelectDownloader> default case this.state.selectedOption: ${this.state.selectedOption}`)
                    Snackbar.Error(t("reportSelectDownload.downloadError"))
                    return
            }


            reportRequest
                .then(async reportBlob => {
                    this.setState({_loading: false})
                    saveAs(reportBlob, fileName)

                })
                .catch(_exception => {
                    Snackbar.Error(t("reportSelectDownload.downloadError"))
                    Logger.exception(_exception)
                    this.setState({_loading: false, _exception})
                })
        } catch (e) {
            Logger.exception(e)
            Snackbar.Error(t("reportSelectDownload.downloadError"))
        }

    }

    handleSwitchChange = event => {
        this.setState(prevState => ({anonymized: !prevState.anonymized}))
    }

    render() {
        const {selectedOption, _loading, _exception} = this.state

        return (
            <FlexRow alignItems={"center"}>
                <FormControlLabel style={{margin: 0, marginRight: "1rem"}}
                                  control={
                                      <SwitchOverride
                                          checked={this.state.anonymized}
                                          onChange={this.handleSwitchChange}
                                          name="anonymized"
                                          color={"default"}
                                      />
                                  }
                                  label="Anonymized"
                />
                <DownloadControl>
                    <DownloadText>Download</DownloadText>
                    <FlexRow>
                        <Select
                            value={selectedOption}
                            onChange={this.handleChange}
                            options={options}
                            name={"report type"}
                        />
                        <SaveButton
                            error={_exception && true}
                            disabled={_loading}
                            onClick={this.handleClick}
                            downloadUrl={DownloadIcon}
                        >
                            {_loading ? <Loader type={"INLINE"}/> : <span></span>}
                        </SaveButton>
                    </FlexRow>
                </DownloadControl>
            </FlexRow>


        )
    }
}

function mapDispatchToProps(dispatch: any) {
    let actions = bindActionCreators({
            openErrorSnackbar: openErrorSnackbar
        }, dispatch
    )
    return {...actions, dispatch}
}

function mapStateToProps(state) {
    return {}
}


_ReportSelectDownloader.contextType = ReportContext

export const ReportSelectDownloader = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withReport,
    withTranslation()
)(_ReportSelectDownloader)

