import styled from "styled-components"

export const Dot = styled.div`
  background: black;
  height: 0.75rem;
  width: 0.75rem;
  border-radius: 50%;
  margin-right:0.625rem;
  background: ${({color}) => color};
`

export const Li = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.7rem;
    `