//@flow

import React from "react"
import styled from "styled-components"
import Arrow from "../../../assets/icons/arrow-down-1.svg?url"
import {Colors} from "../../Theme"


const Selector = styled.div`
  box-sizing: border-box;
  border: 1px solid ${Colors.grey.light};
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  height: 2.188rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;

  &:hover {
    cursor: pointer;
    border: 1px solid ${Colors.grey.normal};;
  }
`;

const ArrowArea = styled.div`
  box-sizing: border-box;
  width: 1.875rem;
  background-image: url(${Arrow});
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center ;
`;

const Selections = styled.div`
  box-sizing: border-box;
  border: 1px solid ${Colors.grey.light};
  position: absolute;
  min-height: 2.188rem;
  width: inherit;
  background: red;
  margin-top: 2.188rem;
  z-index: 10;

  &:last-child{
    border-bottom: none;
  }
`;

const Option = styled.div`
  height: 2.188rem;
  background: white;
  box-sizing: border-box;
  border-bottom: 1px solid ${Colors.grey.light};
  color: ${Colors.grey.normal};

  display: flex;
  padding-left: 0.313rem;
  align-items: center;

  &:hover {
    color: white;
    background: ${Colors.blue.normal};
  }
`;

const DisplayText = styled.div`
  padding-left: 0.625rem;
  display: flex;
  align-items: center;
  color: ${Colors.grey.normal};
`;

type Props = {
    value: any,
    onChange: any,
    options: any,
    name: string
}

type State = {
    isOpen: boolean
}

export class Select extends React.Component<Props, State> {
    node = null;
    state = {
        isOpen: false,
    };

    componentDidMount(): void {
        window.document.body.addEventListener("click", this.handleClose);
    }

    componentWillUnmount(): void {
        window.document.body.removeEventListener("click", this.handleClose);
    }

    selectOption = (value: any) => {
        if (this.props.onChange) {
            this.props.onChange({name: this.props.name, value: value});
        }
    };

    handleOpen = (event: any) => {
        this.setState(prevState => {
            return {isOpen: !prevState.isOpen};
        });
    };

    handleClose = (event: any) => {
        if (!this.node) {
            return;
        }

        if (!this.node.contains(event.target)) {
            if (this.state.isOpen) {
                this.setState({isOpen: false});
            }
        }
    };

    render() {
        const {options, value} = this.props;
        const {isOpen} = this.state;

        return (
            <>
                <Selector ref={node => this.node = node} onClick={this.handleOpen}>
                    <DisplayText>
                        {options.find(op => op.value === value).label}
                    </DisplayText>
                    <ArrowArea>
                    </ArrowArea>
                    {isOpen && <Selections>
                        {options.map(data => {
                            return <Option key={data.value} onClick={(event) => {
                                event.preventDefault();
                                this.selectOption(data.value);
                            }} value={data.value}>{data.label}</Option>;
                        })}
                    </Selections>}
                </Selector>
            </>
        );
    }
}
