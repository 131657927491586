//@flow
import * as HttpStatuscode from "http-status-codes";


export class MethodNotAllowedError extends Error {
    httpStatus = HttpStatuscode.METHOD_NOT_ALLOWED;
    type = MethodNotAllowedError;

    constructor(message: string = "") {
        super(`Method not allowed '${message}'`);
    }
}
