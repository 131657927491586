//@flow
import {NavLink} from "react-router-dom"
import {Container} from "reactstrap"
import styled from "styled-components"
import {Colors} from "../../Theme"

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: stretch;
`

export const Sidebar = styled.nav`
  min-width: 18.75rem;
  max-width: 18.75rem;
  min-height: calc(100vh - 3.13rem);
  background: ${props => Colors.grey.normal};
  color: #fff;
`;

export const Content = styled(Container)`
  padding: 1.875rem;
  min-width: 0px;
`;

export const Navbar = styled.div`
  position: relative;
  height: 3.75rem;
  background: ${props => props.theme.navbar.background};
  display: flex;
  align-items: center;
  align-content: center;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,0.32);

`;

export const Branding = styled.img`
  max-height: 2.8rem;
  margin-left: 1.25rem;
`;

export const List = styled.ul`
  margin-top: 1.5625rem;
  color: #fff;
  list-style: none;
  padding: 0;
`;

export const ListItem = styled.li`
  padding: 0.63rem 1.875rem 0.63rem ;
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  ${props => props.active && `background-color: ${Colors.grey.dark};`}
`;


export const Link = styled(NavLink)`
  color: white;

  &:hover {
    color: white;
    text-decoration: none;
  }
`;
