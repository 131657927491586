import React, {useContext, useEffect, useState} from "react"
import {Col, Row} from "reactstrap"
import {
    Button,
    ButtonSpinner,
    FlexRow,
    GhostButton,
    Headline,
    Input,
    Snackbar,
    Spinner,
    Subheadline
} from "@greenbone/cloud-component-library"
import {SlideNavigation} from "../components/SlideNavigation"
import {SubscriptionContext, SubscriptionStep} from "../SubscriptionProvider"
import {GreyBoxHead, GreyBoxSimpleHead} from "./components/GreyBox"
import {BillingAddress} from "./components/BillingAddress"
import {GroupRestApiClient} from "../../UserRestApiClient"
import {SubscriptionRestApiClient} from "../service/SubscriptionRestApiClient"
import {useNavigate, useSearchParams} from "react-router-dom"
import {SubscriptionNavigationUrlProvider} from "../SubscriptionNavigationUrlProvider"
import {useTranslation} from "react-i18next"
import {ForbiddenError} from "../../../services/Exceptions";


export function AddressSlide(props) {


    const {setStep, update} = useContext(SubscriptionContext)

    const [loading, setLoading] = useState(true)
    const [groupAddressErrors, setGroupAddressErrors] = useState()
    const [companyAddressErrors, setCompanyAddressErrors] = useState()
    const [groupAddress, setGroupAddress] = useState(undefined)
    const [companyAddress, setCompanyAddress] = useState(undefined)
    const [useCompanyAddress, setUseCompanyAddress] = useState(false)
    const [companyTransmitSuccess, setCompanyTransmitSuccess] = useState(false)
    const [groupTransmitSuccess, setGroupTransmitSuccess] = useState(false)
    const [vatTransmitSuccess, setVatTransmitSuccess] = useState(false)
    const [countries, setCountries] = useState([])
    const [vat, setVat] = useState()
    const [vatError, setVatError] = useState(null)
    const [isSending, setSending] = useState(false)

    const {t} = useTranslation()

    const navigate = useNavigate()
    const [searchParams] = useSearchParams()

    const next = () => {
        setSending(false)

        navigate({
            pathname: SubscriptionNavigationUrlProvider.payment(),
            search: `?${new URLSearchParams({
                extern: searchParams.get('extern'),
                intern: searchParams.get('intern')
            }).toString()}`
        })
    }

    const submitCompanyAddress = (values) => {
        const apiClient = new SubscriptionRestApiClient()
        apiClient.setGroupBillingAddress(companyAddress)
            .then(response => {
                // console.log("submitCompanyAddress", response)

                if (!response) {
                    setCompanyTransmitSuccess(true)
                    return
                }


                if (response?.status === 400 || response?.status === "BAD_REQUEST") {
                    setCompanyAddressErrors(response?.fieldErrors)
                    setCompanyTransmitSuccess(false)
                    return
                }

                if (response?.status === 428) {
                    setVatError(response?.message)
                    setCompanyTransmitSuccess(false)
                    return
                }

                throw new Error(response?.message)
            })
            .catch(e => {
                // console.log("submitCompanyAddress e", e, e.status)

                if (e?.status === 428) {
                    setVatError("VAT number must be set if address country is not Germany")
                    setCompanyTransmitSuccess(false)
                    return
                }

                Snackbar.Error("An error occurred")
            })
    }

    const submitVat = async () => {
        const apiClient = new SubscriptionRestApiClient()

        // if (vat === "" || vat === null) {
        //     setVatTransmitSuccess(true)
        //     return
        // }


        await apiClient.setGroupVat({vatNumber: vat})
            .then(response => {
                // console.log("vat", response)
                if (!response) {
                    setVatTransmitSuccess(true)
                    setVatError(null)
                    return
                }
                if (response?.status === 400 || response?.status === "BAD_REQUEST") {
                    setVatError(response?.fieldErrors?.vatNumber)
                    return
                }

                if (response?.status === 428) {
                    setVatError(response?.message)
                    return
                }
                //if 428 set company address


                throw new Error(response?.message)

            })
            .catch(async (e) => {
                // console.log("vat e", e)
                if (e?.status === 428) {
                    setVatError("VAT number must be set if address country is not Germany")
                    return
                }

                if (e instanceof ForbiddenError){
                    Snackbar.Error(t("components.error.boundary.noPermission"))
                }

                setVatError("Invalid value")
            })
    }

    const submitGroupAddress = async (values) => {
        const apiClient = new GroupRestApiClient()

        await apiClient.setBillingAddress(groupAddress)
            .then(response => {
                // console.log("submitGroupAddress", response)

                if (!response) {
                    setGroupTransmitSuccess(true)
                    return
                }

                if (response?.status === 428) {
                    setGroupTransmitSuccess(false)
                    setVatError("This field required")
                    return
                }

                if (response?.status === 400 || response?.status === "BAD_REQUEST") {
                    setGroupAddressErrors(response?.fieldErrors)
                    setGroupTransmitSuccess(false)
                    return
                }

                throw new Error(response?.message)
            })
            .catch(e => {
                // console.log("submitGroupAddress e", e)
                Snackbar.Error("An error occured")
            })
    }


    const handleBack = (event) => {
        setLoading(false)

        navigate({
            pathname: SubscriptionNavigationUrlProvider.change(),
            search: `?${new URLSearchParams({
                extern: searchParams.get('extern'),
                intern: searchParams.get('intern')
            }).toString()}`
        })
    }


    const handleSubmit = async () => {
        setSending(true)
        await submitGroupAddress()

        await submitVat()


        if (useCompanyAddress) {
            await submitCompanyAddress()
        }
    }

    useEffect(() => {

        if (!vatTransmitSuccess) {
            return
        }

        update()

        if (companyTransmitSuccess && groupTransmitSuccess) {
            next()
        }

        if (groupTransmitSuccess && useCompanyAddress === false) {
            next()
        }
        // eslint-disable-next-line
    }, [companyTransmitSuccess, groupTransmitSuccess, useCompanyAddress, vatTransmitSuccess])

    useEffect(() => {
        setStep(SubscriptionStep.Address)
    }, [setStep])

    useEffect(() => {
        async function f() {
            const groupApiClient = new GroupRestApiClient()
            const subscriptionApiClient = new SubscriptionRestApiClient()

            const groupAddress = await groupApiClient.getBillingAddress()
            const companyAddress = await subscriptionApiClient.getGroupBillingAddress()

            const countryList = await groupApiClient.getCountryCodes()


            subscriptionApiClient.getGroupVat()
                .then(response => {
                    setVat(response.vatNumber || undefined)
                })
                .catch(e => {
                    console.log(e)
                })

            setCountries(countryList)
            setGroupAddress(groupAddress)
            setCompanyAddress(companyAddress)

            if (companyAddress?.title) {
                setUseCompanyAddress(true)
            }
        }

        f()
    }, [])

    useEffect(() => {
        if (groupAddress && companyAddress && countries.length > 0) {
            setLoading(false)
        }
    }, [groupAddress, companyAddress, countries])


    return <>

        <Row>
            <Col>
                <Headline>{t("addressSlide.headline")}</Headline>
            </Col>
        </Row>
        <Row>
            <Col>
                <SlideNavigation/>
            </Col>
        </Row>
        {loading ? <Spinner/> :
            <Row>
                <Col xs={7}>

                    <Row style={{marginBottom: "2rem"}}>
                        <Col>

                            <GreyBoxSimpleHead title={t("addressSlide.companyAddress")}
                                               text={t("addressSlide.companyAddress.desc")}/>
                            <BillingAddress countries={countries} disableAction={true} errors={groupAddressErrors}
                                            address={groupAddress}
                                            onChange={address => setGroupAddress(address)}/>

                        </Col>
                    </Row>
                    <Row style={{marginBottom: "2rem"}}>
                        <Col>
                            <Subheadline>{t("vat.headline")}</Subheadline>
                            <Input isValid={vatError} type={"text"} value={vat}
                                   onChange={(event) => setVat(event.target.value)}
                                   name={"vat"}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FlexRow style={{visibility: useCompanyAddress ? "hidden" : "visible"}}
                                     justifyContent={"flex-end"}>
                                <GhostButton onClick={handleBack}>
                                    {t("addressSlide.back")}
                                </GhostButton>

                                <Button style={{marginLeft: "1rem"}} disabled={isSending} onClick={handleSubmit}>
                                    {t("addressSlide.continue")} {isSending && <ButtonSpinner/>}
                                </Button>
                            </FlexRow>

                        </Col>
                    </Row>
                    <Row>
                        <Col>

                            <GreyBoxHead checkbox selected={useCompanyAddress}
                                         onSelect={(event) => {

                                             setGroupTransmitSuccess(false)
                                             setCompanyTransmitSuccess(false)
                                             setVatTransmitSuccess(false)

                                             if (companyAddress?.title) {
                                                 return
                                             }
                                             setUseCompanyAddress(prevState => {
                                                 return !prevState
                                             })
                                         }}
                                         title={t("addressSlide.useDifferentBillingAddress")}
                                         text={t("addressSlide.useDifferentBillingAddress.desc")}>
                            </GreyBoxHead>
                            <BillingAddress countries={countries} disableAction={true}
                                            style={{display: useCompanyAddress ? "block" : "none"}}
                                            errors={companyAddressErrors} address={companyAddress}
                                            onChange={address => setCompanyAddress(address)}/>

                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FlexRow style={{visibility: !useCompanyAddress ? "hidden" : "visible"}}
                                     justifyContent={"flex-end"}>
                                <GhostButton onClick={handleBack}>
                                    {t("addressSlide.back")}
                                </GhostButton>

                                <Button style={{marginLeft: "1rem"}} disabled={isSending} onClick={handleSubmit}>
                                    {t("addressSlide.continue")} {isSending && <ButtonSpinner/>}
                                </Button>
                            </FlexRow>

                        </Col>
                    </Row>
                </Col>
            </Row>
        }


    </>
}
