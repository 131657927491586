import React from "react"
import styled from "styled-components"
import {FlexRow} from "../Flex"

const Around = styled.div`
  input {
    width: 100%;
  }

  input[type=range]:focus {
    outline: none;
  }

  input[type=range]:focus::-webkit-slider-thumb,
  input[type=range]:focus::-moz-range-thumb,
  input[type=range]:focus::-ms-thumb {
    outline: none;
  }

  input[type="range"]::-moz-range-thumb {
    border-radius: 50%;
    pointer-events: all;
    height: 1.2rem;
    width: 1.2rem;
    background: ${props => props.theme.slider.thumb.background};
    border: 2px solid ${({theme: {slider}}) => slider.thumb.border};
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
    -webkit-appearance: none;

  }

  input[type="range"]::-moz-range-track {
    height: .5rem;
    background: ${({theme: {slider}}) => slider.background};
    border: none;
    border-radius: 3px;

  }

`

export function RangeSlider({min, max, step, value, minLabel, maxLabel, onChange}) {
    return <Around>
        <FlexRow justifyContent={"space-between"}>
            <div>{minLabel && minLabel({min, max, step, value})}</div>
            <div>{maxLabel && maxLabel({min, max, step, value})}</div>
        </FlexRow>
        <input onChange={onChange} value={value} min={min} max={max} step={step} type={"range"}/>
    </Around>
}
