//@flow

import {withStyles} from "@material-ui/core";
import React from "react";


const styles = theme => ({
    dt: {
        float: "left",
        clear: "left",
        width: "11.25rem",
        fontWeight: "bold",
        "&:after": {
            content: "':'",
        },
    },
});

class _Term extends React.Component<any, any> {

    render() {

        const {classes} = this.props;

        return (
            <dt className={classes.dt}>
                {this.props.children}
            </dt>
        );
    }
}

export const Term = withStyles(styles)(_Term);
