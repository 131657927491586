import React, {useState} from "react"
import Triangle from "../assets/triangle.svg"
import {Caption, FlexColumn, FlexRow} from "@greenbone/cloud-component-library"
import styled, {css} from "styled-components"
import {VulnerabilityDetails} from "./VulnerabilityDetails"
import {Severity} from "./TableComponents"


export const VulCaption = styled(Caption)`


  color:  #4C4C4D;
  margin: 0;
`

const TriangleSurrounder = styled.div`
  color: #c2c2c2;

  svg {
  width: 0.5rem;
  height: 0.5rem;

  ${props => props.open && css`
    transform: rotate(180deg);
  `}
  }

`
const HoverMe = styled(FlexRow)`
  min-height: 3.75rem;
  border: 1px solid #D5D5D5;
  border-left: 0.25rem solid #D5D5D5;
  padding-left: 1rem;
  padding-right: 1rem;
  background: #D5D5D50D;


  ${({range, open, color}) => open && css`
    border: 1px solid ${props => props.theme.base};
    border-left: 4px solid ${props => props.theme.base};
    background: ${props => props.theme.base}0D;

    ${VulCaption}{
      color: ${props => props.theme.base};
    }


  `}

  &:hover {
    cursor: pointer;
  }
`


export function Extendable({name, color, children, defaultOpen}) {
    const [open, setOpen] = useState(!!defaultOpen)

    return <>
        <HoverMe color={color} open={open} onClick={() => setOpen(prevState => !prevState)}
                 justifyContent={"space-between"}
                 alignItems={"center"}>
            <VulCaption>
                {name}
            </VulCaption>

            <TriangleSurrounder open={open}>
                <Triangle/>
            </TriangleSurrounder>
        </HoverMe>
        {open && <div style={{padding: "1rem"}}>
            {children}
        </div>}
    </>
}

export function MiniVulnerability({vulnerability, range}) {

    const {name} = vulnerability
    const [open, setOpen] = useState(false)


    return <>
        <HoverMe range={range} open={open} onClick={() => setOpen(prevState => !prevState)}
                 justifyContent={"space-between"}
                 alignItems={"center"}>
            <FlexRow justifyContent={"space-between"}
                     alignItems={"center"}>
                <Severity style={{marginRight: "0.8rem"}} severity={vulnerability.severity}>
                    {vulnerability.severity}
                </Severity>
                <VulCaption>
                    {name}
                </VulCaption>
            </FlexRow>

            <TriangleSurrounder open={open}>
                <Triangle/>
            </TriangleSurrounder>
        </HoverMe>
        {
            open && <FlexColumn style={{padding: "1rem"}}>
                <VulnerabilityDetails small={true} vulnerability={vulnerability}/>
            </FlexColumn>
        }

    </>

}
