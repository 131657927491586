//@flow

import {TableCell, withStyles} from "@material-ui/core";
import React from "react";
import {compose} from "redux";
import {Colors} from "../../Theme";


const styles = (theme: any) => ({
    tableCell: {
        fontFamily: "Netto, sans-serif",
        textDecoration: "underline",
        cursor: "pointer",
        "&:hover": {
            backgroundColor: Colors.grey.normal,
            color: "white"
        },
        fontWeight: "400",
        borderBottom: "1px solid rgba(224, 224, 224, 1)",
    },
});

type Props = {
    onClick: any;
    children: any;
    classes: typeof styles;
    element: ?any;
}

class ClickableTableCell extends React.Component<Props, {}> {

    render() {
        const {classes} = this.props;
        let {element} = this.props;
        if (!element) {
            element = TableCell;
        }

        return React.createElement(
            element,
            {
                className: classes.tableCell,
                onClick: this.props.onClick,

            },
            this.props.children,
        );
    }
}

export default compose(
    withStyles(styles),
)(ClickableTableCell);
