import {FetchClient, RestApiClient} from "@greenbone/cloud-component-library"
import {BASEURL} from "../../constants"

function ErrorHandler(e) {
    throw e
}

export class LegalRestApiClient {
    constructor(
        apiClient = new RestApiClient(
            new FetchClient(null, BASEURL).getUnauthenticatedInstance(),
            ErrorHandler
        )) {
        this.apiClient = apiClient
    }

    getImprint = lang => async () => {
        return await this.apiClient.get(`/vmssp-admin/public/legal/${lang}/imprint`)
    }

    termsOfService = lang => async () => {
        return await this.apiClient.get(`/vmssp-admin/public/legal/${lang}/termsOfUse`)
    }

    dataProtectionPolicy = lang => async () => {
        return await this.apiClient.get(`/vmssp-admin/public/legal/${lang}/privacyPolicy`)
    }

    settings = async () => {
        return await this.apiClient.get(`/vmssp-admin/public/legal/settings`)
    }


}
