//@flow

import React from "react"
import styled, {css} from "styled-components"
import {ColorTheme} from "../../Theme"
import {MenuContext} from "@greenbone/cloud-component-library"
import {ArrowLeftIcon} from "../../../assets/icons/arrow-left"
import {ArrowRightIcon} from "../../../assets/icons/arrow-right"


const MinifyButton = styled.div`
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 2px solid ${props => props.theme.sidebar.border};
  border-left: none;
  width: 1.75rem;
  height: 1.75rem;
  align-items: center;
  display: flex;
  justify-content: center;
  align-content: center;
  position: absolute;
  transform: translate(-2px);
  background: ${props => props.theme.sidebar.background};
  top: 4.5625rem;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.2s, opacity 0.1s ease-in-out;

  &:hover {
    cursor: pointer
  }

  ${props => props.show && css`visibility: visible; opacity: 1;`}
`;

const Icon = styled.div`
  height: 0.75rem;
  width: 0.75rem;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    height: 100%;
  }

`;

export class Minify extends React.Component<any, any> {
    render() {
        return (
            <MenuContext.Consumer>
                {({minified, toggleMinify, showMinifyButton, showMinifyButtonEvent, hideMinifyButtonEvent}: any) =>
                    <div style={{width: 0, overflow: "shown"}}
                         onMouseEnter={showMinifyButtonEvent}
                         onMouseLeave={hideMinifyButtonEvent}
                    >
                        <MinifyButton show={showMinifyButton} onClick={toggleMinify}>
                            <Icon>
                                {minified ? <ArrowRightIcon color={ColorTheme.sidebar.font}/> :
                                    <ArrowLeftIcon color={ColorTheme.sidebar.font}/>}
                            </Icon>
                        </MinifyButton>
                    </div>
                }
            </MenuContext.Consumer>
        );
    }
}
