//@flow

import {CONSTANTS} from "./actions";


type State = {
    Open: boolean;
}

const initialState: State = {
    Open: true,
};

const reducer = (state: State = initialState, action: any) => {
    switch (action.type) {

        case CONSTANTS.OPEN_WELCOME_DIALOG :
            return {
                ...state,
                Open: true,
            };

        case CONSTANTS.CLOSE_WELCOME_DIALOG:
            return {
                ...state,
                Open: false,
            };

        default:
            return {...state};
    }
};

export default reducer;
