import * as HttpStatuscode from "http-status-codes"
import {ForbiddenError, InvalidStatusCodeError} from "../../Exceptions"
import {BaseApiClient} from "../Base/BaseApiClient"
import {FileReportUrlProvider} from "./FileReportUrlProvider"
import i18next from "i18next"

const t = i18next.t

const defaultFilter = {
    minimalSeverity: 1,
    anonymized: false
}

export class FileReportRestApiClient extends BaseApiClient {

    constructor(refreshAuth = true) {
        super(new FileReportUrlProvider())
    }

    /**
     * Get technical pdf report for task
     * @param reportId - report id
     * @returns {Promise<any>}
     */
    async getTechnicalPDFReport(reportId, body): Promise<string> {
        const response: Response = await this.httpJsonClient.post(this.urlProvider.technicalReport(reportId), body || defaultFilter)
        if (response.status === HttpStatuscode.OK) {
            return await response.blob()
        }
        if (response.status === HttpStatuscode.FORBIDDEN) {
            throw new ForbiddenError(t("components.error.boundary.noPermission"))
        }
        throw new InvalidStatusCodeError(response.status)
    }

    /**
     * Get executive pdf report for task
     * @param reportId - report id
     * @returns {Promise<any>}
     */
    async getExecutivePDFReport(reportId, body): Promise<string> {
        const response: Response = await this.httpJsonClient.post(this.urlProvider.executiveReport(reportId), body || defaultFilter)
        if (response.status === HttpStatuscode.OK) {
            return await response.blob()
        }
        if (response.status === HttpStatuscode.FORBIDDEN) {
            throw new ForbiddenError(t("components.error.boundary.noPermission"))
        }
        throw new InvalidStatusCodeError(response.status)
    }


    /**
     * Get executive pdf report for task
     * @param reportId - report id
     * @returns {Promise<any>}
     */
    async getExecutiveJSONReport(reportId, body): Promise<string> {
        const response: Response = await this.httpJsonClient.post(this.urlProvider.executiveReportJson(reportId), body || defaultFilter)
        if (response.status === HttpStatuscode.OK) {
            return await response.blob()
        }
        if (response.status === HttpStatuscode.FORBIDDEN) {
            throw new ForbiddenError(t("components.error.boundary.noPermission"))
        }
        throw new InvalidStatusCodeError(response.status)
    }

    /**
     * Get technical pdf report for task
     * @param reportId - report id
     * @returns {Promise<any>}
     */
    async getTechnicalJSONReport(reportId, body): Promise<string> {
        const response: Response = await this.httpJsonClient.post(this.urlProvider.technicalReportJson(reportId), body || defaultFilter)
        if (response.status === HttpStatuscode.OK) {
            return await response.blob()
        }
        if (response.status === HttpStatuscode.FORBIDDEN) {
            throw new ForbiddenError(t("components.error.boundary.noPermission"))
        }
        throw new InvalidStatusCodeError(response.status)
    }


    /**
     *
     * @param reportId
     * @param body
     * @returns {Promise<Blob>}
     */
    async openVasXML(reportId, body): Promise<string> {
        const response: Response = await this.httpJsonClient.post(this.urlProvider.openVasXML(reportId), body || defaultFilter)
        if (response.status === HttpStatuscode.OK) {
            return await response.blob()
        }
        if (response.status === HttpStatuscode.FORBIDDEN) {
            throw new ForbiddenError(t("components.error.boundary.noPermission"))
        }
        throw new InvalidStatusCodeError(response.status)
    }

}

