//@flow

import React from "react";
import {Col} from "reactstrap";
import {TabBox} from "./TabBox";


type Props = {
    contact: GSPHost;
    disabled?: boolean;
    active: boolean;
    onClick: EventCallback
}

export class HostValidationContactCard extends React.Component<Props, {}> {
    render() {
        const {contact, active, onClick} = this.props;

        return (
            <Col>
                <TabBox onClick={onClick} active={active} title={contact.name || "Empty"}>
                    {contact.email && (
                        <span>{contact.email}</span>
                    )}
                </TabBox>
            </Col>
        );
    }
}

