import {Col, Row} from "reactstrap"
import {Button, ButtonSpinner, Headline, Snackbar} from "@greenbone/cloud-component-library"
import {Trans, useTranslation} from "react-i18next"
import {Colors} from "../../../Theme"
import React, {useState} from "react"
import {TeamRestApiClient} from "../service/TeamRestApiClient"
import {bindActionCreators, compose} from "redux"
import {connect} from "react-redux"
import {openConfirmDialog} from "../../../Components/Dialogs/ConfirmDialog/actions"


function _DeleteTeam({openConfirmDialog}) {


    const {t} = useTranslation()
    const [sending, setSending] = useState(false)


    const handleDelete = () => {
        const apiClient = new TeamRestApiClient()
        openConfirmDialog(t("common.error.danger"), t("deleteTeam.sureToDeleteAccount"), () => {
            setSending(true)
            apiClient.delete()
                .then(response => {
                    if (response === null) {
                        setSending(false)
                        Snackbar.Success(t("deleteTeam.deletionRequestedCheckMail"))
                        return
                    }
                    throw response
                })
                .catch(e => {
                    setSending(false)

                    if (e?.status === 422) {
                        Snackbar.Error(t("deleteTeam.alreadyRequested"))
                        return
                    }

                    Snackbar.Error(e.toString())
                })
        })


    }

    return <>
        <Row style={{marginTop: "4rem"}}>
            <Col>
                <Headline>
                    {t("teamrouter.deleteTeam.headline")}
                </Headline>
            </Col>
        </Row>
        <Row>
            <Col>
                <p>
                    <Trans i18nKey={"teamRouter.deleteDescription"}>
                        You can permanently delete your and your teams account. All targets and reports will be
                        removed, active subscriptions will expire.
                        <span style={{color: Colors.red.normal}}>You and your team will no longer be able to sign in or use the application</span>
                        An email with an confirmation link will be sent, to confirm the delete action.
                    </Trans>
                </p>
            </Col>
        </Row>
        <Row>
            <Col>
                <Button disabled={sending} onClick={() => handleDelete()}>{t("teamrouter.deleteTeam.action")}
                    {sending && <ButtonSpinner/>}</Button>
            </Col>
        </Row>
    </>
}


function mapDispatchToProps(dispatch: any) {
    let actions = bindActionCreators({
        openConfirmDialog: openConfirmDialog
    }, dispatch)
    return {...actions, dispatch}
}

function mapStateToProps(state) {
    return {}
}

export const DeleteTeam = compose(
    connect(mapStateToProps, mapDispatchToProps)
)(_DeleteTeam)
