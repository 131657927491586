import React, {useContext} from "react"
import {InformationBarContext} from "./InformatinBarContext"
import styled from "styled-components"
import {IconButton} from "@material-ui/core"
import {Close} from "@material-ui/icons"
import {GhostButton} from "../../StyledComponents/Button/Button"
import {FlexRow} from "@greenbone/cloud-component-library"


export const Bar = styled.div`
    background: ${props => props.background};
    color: ${props => props.foreground};
  height: 3.4rem;
  box-sizing: border-box;
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ActionButton = styled(GhostButton)`
  color: ${props => props.foreground};
  border-color: black;
  margin: 0;

  &:hover {
    color: black;
    border-color: black;
  }
`;


export function InformationBarView(props) {

    const {bars} = useContext(InformationBarContext);


    //component, close, action, actionText, uuid, color
    return <>
        {bars.map(({component, close, action, actionText, uuid, color}) => (
            <Bar key={uuid}
                 background={color.background}
                 foreground={color.foreground}>
                <FlexRow alignItems={"center"} fullWidth justifyContent={"space-between"}>
                    <div style={{ flex: 1, textAlign: "center" }}>{component}</div>
                    {action && <ActionButton
                        foreground={color.foreground}
                        onClick={() => {
                            action();
                    }}>
                        {actionText}
                    </ActionButton>}
                </FlexRow>

                <IconButton onClick={close}>
                    <Close/>
                </IconButton>
            </Bar>
        ))}
    </>;

}
