import React, {useState} from "react"

import {useTranslation} from "react-i18next"
import styled from "styled-components"
import {Bold, Caption, FlexColumn, FlexRow, IconButton, SlideIn, Text} from "@greenbone/cloud-component-library"
import {SmallBox} from "../../../StyledComponents/Box/Box"
import {Colors} from "../../../Theme"
import {Tooltip} from "../../../Components/Dialogs/Callout"
import {ReportHelper} from "../ReportHelper"
import DetailsIcon from "../assets/details.svg"
import {VulnerabilityDetails} from "./VulnerabilityDetails"
import {SwitchSolutionTab} from "./SolutionTab"
import {MiniatureOperatingSystemIcon} from "./OperatingSystemTab"


const SvgWrapper = styled.div`
    color: #868686;
`

const Severity = styled.div`
    background-color: ${props => ReportHelper.SeverityToColor(props.severity)};
    font-size: 1rem;
    color: white;
    border-radius: 4px;
    height: 2.25rem;
    width: 2.625rem;
    min-width: 2.625rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 1rem;
`

const ReportDetails = styled(SmallBox)`
    padding: 0;
    border: 0.75px solid rgba(0, 0, 0, .34);
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    margin-bottom: 1rem;
    margin-right: 1rem;
    border-left: none;
    /*   box-shadow: 1px 1px 0px #999,
                    2px 2px 0px #999,
                    3px 3px 0px #999,
                    4px 4px 0px #999,
                    5px 5px 0px #999,
                    6px 6px 0px #999;*/
`

const InnerContainer = styled(FlexColumn)`
    padding: 1.25rem;
    justify-content: space-between;
    flex-grow: 1;
    width: 100%;
`

const Seperator = styled.hr`
    margin-top: 0.63rem;
    margin-bottom: 0.63rem;
    padding: 0;
    height: 1px;
    color: #D5D5D5;
    background-color: #D5D5D5;
    border: 0;
    width: 100%;
`


const Name = styled(Caption)`
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 0.94rem;
    margin-bottom: .3125rem;
    font-weight: bold;
    white-space: nowrap; /* Don't forget this one */
`


const LightText = styled.span`
    color: rgba(0, 0, 0, .34);
`

const AutoLength = styled.span`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; /* Don't forget this one */
`

const HeaderGrid = styled.div`
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: .5rem;
    grid-template-rows: 1fr 1fr 1fr;
`

const HeaderTable = ({severity, hostname, os}) => {
    const {t} = useTranslation()

    return <HeaderGrid>
        <div>
            <LightText>
                {t("report.severityText.severity")}:
            </LightText>
        </div>
        <div style={{color: ReportHelper.SeverityToColor(severity)}}>
            {ReportHelper.SeverityToString(severity)}
        </div>
        {hostname && <>
            <div>
                <LightText>
                    Hostname:
                </LightText>
            </div>
            <AutoLength>
                {hostname}
            </AutoLength>
        </>}
        {os && <>
            <div>
                <LightText>
                    {t("report.filter.os")}:
                </LightText>
            </div>


            <AutoLength style={{position: "relative"}}>
                <MiniatureOperatingSystemIcon os={os}/>
                {os}
            </AutoLength>


        </>}

    </HeaderGrid>
}

const SeverityIndicator = styled.div`
    background-color: ${props => ReportHelper.SeverityToColor(props.severity)};
    width: 0.375rem;
    min-width: 0.375rem;
`

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: .8fr 1fr .4fr;
    grid-template-rows: 1.5fr 1fr;
    grid-row-gap: .5rem;
    grid-template-areas:
    ". . ."
    ". . .";
    align-items: center;
`

export function VulnerabilityBox({vulnerability}) {

    const [open, setOpen] = useState(false)

    const {t} = useTranslation()

    const handleClick = (event: any) => {
        setOpen(prevState => !prevState)
    }


    return <ReportDetails>
        <SlideIn open={open} onClose={handleClick}>
            <VulnerabilityDetails vulnerability={vulnerability}/>
        </SlideIn>
        <FlexRow full>
            <SeverityIndicator severity={vulnerability.severity}/>
            <InnerContainer>
                <FlexColumn>
                    <FlexRow alignItems={"center"} justifyContent={"space-between"}>
                        <Name>{vulnerability.name}</Name>
                        <Severity severity={vulnerability.severity}>
                            {t("report.vulnerability.severity", {"number": vulnerability.severity})}
                        </Severity>
                    </FlexRow>
                    <HeaderTable severity={vulnerability.severity} os={vulnerability.operatingSystem}
                                 hostname={vulnerability.hostname}/>
                    <Seperator/>
                </FlexColumn>

                <GridContainer>
                    <div>
                        <Bold style={{marginBottom: "0", color: "rgba(0,0,0, .34)"}}>Host</Bold><br/>
                        <Text color={Colors.grey.dark}>{vulnerability.host}</Text>
                    </div>
                    <div>
                        <Bold style={{marginBottom: "0", color: "rgba(0,0,0, .34)"}}>Port</Bold><br/>
                        <Text color={Colors.grey.dark}>{vulnerability.port}</Text>
                    </div>
                    <div style={{justifySelf: "end"}}>
                        <IconButton onClick={handleClick}>
                            <SvgWrapper>
                                <DetailsIcon/>
                            </SvgWrapper>
                        </IconButton>
                    </div>
                    <div>
                        <Bold style={{margin: 0, marginRight: "0.5rem"}}
                              color={Colors.grey.dark}>{t("scanmanagement.solutionType")} </Bold>

                    </div>
                    <div><SwitchSolutionTab type={vulnerability.solutionType} small={true}/></div>
                    <div>


                        <Tooltip ID={"QOD"} tooltip={(
                            <div>
                                Quality of Detection
                            </div>
                        )}>
                            <Text color={Colors.grey.dark}>
                                <Bold color={Colors.grey.dark}>QoD: </Bold>
                                {vulnerability?.qod}%
                            </Text>
                        </Tooltip>
                    </div>
                </GridContainer>


            </InnerContainer>
        </FlexRow>
    </ReportDetails>

}


