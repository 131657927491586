import {withStyles, withTheme} from "@material-ui/core"
import React from "react"
import {Col, Row} from "reactstrap"
import {compose} from "redux"
import {SIODateTime} from "../../../controller/SIODateTime"
import DatePicker from "react-datepicker"
import {withTranslation} from "react-i18next"

import Checkmark from "../../../../assets/inputs/checkmark.svg?url"
import Cross from "../../../../assets/inputs/remove-red.svg?url"

const styles = theme => ({
    fullWidth: {
        width: "100%"
    }
})

type Props = {
    +name: string;
    +date: ?SIODateTime;
    +classes: typeof styles;
    +theme: any;
    +handleDateTimeChange: (name: string, value: ?SIODateTime) => {};
    +isValid?: boolean
}

type State = {
    dateTime: Date
}

class _DateTimeInput extends React.Component<Props, State> {
    static defaultProps = {
        isValid: undefined
    }

    constructor(props) {
        super(props)

        this.state = {
            dateTime: props.date?.toJSDate()
        }
    }

    handleDateTimeChange = (dateTime: Date) => {
        this.setState(
            {
                dateTime
            },
            this._handleDateTimeChange
        )
    }


    _handleDateTimeChange = () => {

        if (this.state.dateTime) {
            let parsedDate = SIODateTime.fromJSDate(this.state.dateTime)
            this.props.handleDateTimeChange(
                this.props.name, parsedDate
            )
            return
        }
        this.props.handleDateTimeChange(this.props.name, null)
    }

    get validationIcon() {
        const isValid = this.props.isValid;
        if (isValid === undefined) {
            return undefined;
        }

        return <img style={{height: "1rem", paddingLeft: ".5rem"}} src={isValid ? Checkmark: Cross} alt=""/>;
    }

    render() {
        return <Row>
            <Col>
                <DatePicker timeFormat="p"
                            dateFormat="Pp"
                            selected={this.state.dateTime}
                            onChange={date => this.handleDateTimeChange(date)}
                            className={this.props.isValid === false ? 'invalid-datetime' : ''}
                            showTimeSelect/>
                {this.validationIcon}
            </Col>
        </Row>
    }
}

export const DateTimeInput = compose(
    withTheme,
    withStyles(styles),
    withTranslation()
)(_DateTimeInput)
