export class DonutController {
    static CalculateTotal(list, accessor) {
        let count = 0
        list.forEach(entry => count += accessor(entry))
        return count
    }

    static GetColorFromArray(colorScheme, index) {
        if (index < colorScheme.length) {
            return colorScheme[index]
        } else {
            const calculatedIndex = index % colorScheme.length
            return colorScheme[calculatedIndex]
        }
    }

    static ConvertDataForDrawing(data, accessor, colorScheme) {
        if (colorScheme) {
            if (Array.isArray(colorScheme)) {
                if (colorScheme.length >= 1) {
                    return data.map((entry, index) => ({
                        ...entry,
                        count: accessor(entry),
                        color: this.GetColorFromArray(colorScheme, index)
                    }))
                }
            }
        }
        return data.map(entry => ({...entry, count: accessor(entry)}))
    }

    static GenerateNullData(list) {
        return list.map(item => {
            if (item.color) {
                return {count: 0, color: item.color}
            }
            return {count: 0}
        })
    }


}
