

export class InvalidStatusCodeError extends Error {
    type = InvalidStatusCodeError;


    httpStatus() {
        return this.status;
    }

    constructor(statusCode) {
        super(`Received invalid status from server '${statusCode}'`);
        this.status = statusCode;
    }
}
