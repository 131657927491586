//@flow

import {withStyles, withTheme} from "@material-ui/core"
import React, {Component} from "react"
import {withTranslation} from "react-i18next"
import {compose} from "redux"
import styled from "styled-components"
import ArrowDown from "../../../assets/icons/notification/arrow-down.svg?url"
import ArrowUp from "../../../assets/icons/notification/arrow-up.svg?url"
import {Button} from "@greenbone/cloud-component-library"
import {Bold} from "../../StyledComponents/Font/Font"
import {Checkbox} from "../../StyledComponents/Form/Checkbox"
import {Input} from "../../StyledComponents/Form/Input"
import {Colors} from "../../Theme"


const styles = () => ({
    paragraph: {
        fontSize: "0.90em",
        lineHeight: "1.2em",
        textAlign: "justify"
    }
})

const Icon = styled.img`
  height: 1.6rem;
  cursor: pointer;
  margin-right: 0.5rem;
`;

const NotificationTypeIcon = styled.img`
  height: 2.4rem;
`;

type Props = {
    onChange?: any;
    onSave?: any;
    isChecked: boolean;
    onCheck: any;
    isValid?: any;

    children?: any;
    t: any;

    icon: any;
    title: string;
    description: any;
    helpTitle?: string;
    webhook?: ?string;
}

type State = {
    isHelpOpen: boolean
}

class _NotificationConfigBox extends Component<Props, State> {
    state = {
        isHelpOpen: false
    };

    toggleState = () => {
        this.setState(prevState => {
            return {isHelpOpen: !prevState.isHelpOpen};
        });
    };

    render() {
        const {isHelpOpen} = this.state;
        const {
            webhook, icon, title, description, helpTitle, isValid, isChecked, children, onChange, onCheck, onSave, t
        } = this.props;

        return (
            <div style={{display: "flex", marginBottom: "2rem"}}>
                <div style={{display: "flex"}}>
                    <div><Checkbox checked={isChecked} onClick={onCheck} label={<NotificationTypeIcon src={icon}/>}/>
                    </div>
                </div>
                <div style={{width: "100%"}}>
                    <div style={{marginTop: "0.8rem", marginBottom: "0.5rem"}}><Bold>{title}</Bold></div>
                    <div style={{marginBottom: "0.5rem"}}>{description}</div>
                    {isChecked && <>
                        {children && <div style={{marginBottom: "0.5rem"}} onClick={this.toggleState}>
                            <span>{isHelpOpen ? <Icon src={ArrowUp}/> : <Icon
                                src={ArrowDown}/>}</span>
                            {helpTitle}
                        </div>}
                        <div style={{marginLeft: "1rem", color: Colors.grey.normal}}>
                            {isHelpOpen && this.props.children}
                        </div>
                        {webhook !== undefined && <div>
                            <Input isValid={isValid} value={webhook} onChange={onChange}
                                   label={t("component.userNotificationConfigBox.webhook.label")}/>
                            <Button onClick={onSave}>{t("component.userNotificationConfigBox.webhook.save")}</Button>
                        </div>}
                    </>}
                </div>
            </div>
        );
    }
}

export const NotificationConfigBox = compose(
    withStyles(styles),
    withTheme,
    withTranslation()
)(_NotificationConfigBox);
