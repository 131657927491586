import React from 'react'
import {FlexRow, Paragraph, Subheadline} from "@greenbone/cloud-component-library"
import {FormControlLabel, Switch} from "@material-ui/core"
import {useTranslation} from "react-i18next"


export function JumpIn({isManaged, adminJumpInEnabled, setState}) {
    const {t} = useTranslation()

    return <>
        <Subheadline>
            {t("jumpIn.headline")}
        </Subheadline>
        <FlexRow>
            <FormControlLabel
                control={
                    <Switch
                        disabled={!isManaged}
                        checked={adminJumpInEnabled}
                        onChange={() => setState(prevState => ({
                            ...prevState,
                            adminJumpInEnabled: !prevState.adminJumpInEnabled
                        }))}
                        name={"FullAccess"}
                    />}
                label={adminJumpInEnabled ? t("Enabled") : t("Disabled")}
            />
        </FlexRow>
        <Paragraph>
            {t("jumpIn.description")}
        </Paragraph>
    </>
}
