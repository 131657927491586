import React from "react";
import {Button} from "../Button";
import {FlexRow} from "../Flex";
import {Paragraph} from "../Font";
import styled from "styled-components";
import {Link} from "react-router-dom";
import {ShadeBlendColor} from "./ShadeBlendColors";

const EuroSign = styled.span`
  font-size: 1.25rem;
  line-height: 1em;
  margin-right: 0.25rem;
`;


const PriceComponent = styled.span`
  font-size: 1.3rem;
  height: 1.2em;
`;

const Duration = styled.span`
  font-size: 1rem;
  line-height: 1em;
  align-self: flex-end;
`;

export const PriceDisplay = ({price, text}) => {

    return <FlexRow justifyContent={"center"} alignItems={"center"} style={{height: "4rem"}}>
        <FlexRow>
            <EuroSign>€</EuroSign> <PriceComponent>{price}</PriceComponent><Duration>/{text}</Duration>
        </FlexRow>

    </FlexRow>;
};


export const Colgroup = styled.colgroup`

`;

export const Spacer = styled.td`
  width: 0rem;
`;

export const PlanCell = styled.td`
  width: 8rem;
  text-align: center;
  vertical-align: center;
  height: 2rem;
`;


export const PlanHeadCell = styled(PlanCell)`
background: ${props => props.theme.navbar.background};



div {
    color: ${props => props.theme.navbar.font};
    width: 10.4375rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 0.9375rem;
    font-size: 1rem;
    padding: .5rem;
}
`;


export const TableBody = styled.tbody`

    td {
      height: 3rem;
    }

  .color-icon {
   /* color: ${props => props.theme.navbar.background} */
  }

  tr:first-child > td:first-child {
    vertical-align: top;
  }
  
  tr:first-child > td {
      border-top: 1px solid #D5D5D5;
  }

  tr:nth-child(n) > td:first-child {
    width: 28.125rem;
    font-size: 0.875rem;
  }


  tr:last-child > td:nth-child(odd){

   overflow: hidden;
  }

  tr:nth-last-child(2) > td:first-child {
     border-bottom:1px solid #D5D5D5;
  }

  tr:nth-child(n) > td:nth-child(odd) {
   border-left: 1px solid #D5D5D5;
   border-right: 1px solid #D5D5D5;
  }


  tr:last-child > td:nth-child(n) {
    border: none;
  }


  tr:first-child > td:nth-child(even) {
    border-top: none;
  }


  tr:nth-child(odd) > td:nth-child(n)  {
    background: rgba(0,0,0, 0.05);

  }

  tr:nth-child(odd) > td[data-highlight="true"] {

   /*background: ${props => ShadeBlendColor(props.theme.navbar.background, 8)};*/
}


  tr:nth-child(1) > td:nth-child(n), tr:last-child > td:nth-child(n){
    background: transparent;
  }

  tr:nth-child(2) > td:nth-child(odd) {
    background: ${props => props.theme.navbar.background};
    color: ${props => props.theme.navbar.font};
    font-weight: bold;
    font-size: 2.5rem;
  }


  tr:nth-child(3) > td:nth-child(odd) {
    background: ${props => ShadeBlendColor(props.theme.navbar.background, 3)};
    color:  ${props => props.theme.navbar.font};

  }

  tr:first-child > td:nth-child(odd) {
    background: ${props => props.theme.navbar.background};
  }

  tr:first-child > td:first-child,  tr:nth-child(2) > td:first-child {
    background: transparent;
    color: black;
    font-weight: normal;
    font-size: 1rem;
  }

  tr:nth-child(3) > td:first-child {
    background: rgba(0,0,0, 0.05);
    color: black;
  }

  tr:not(:first-child), tr:not(:last-child) {
        text-align: center;
        vertical-align: middle;
        height: 2.5rem;
  }

  tr:not(:first-child):not(:last-child){
        border-bottom: 1px solid rgba(168, 168, 168, 0.2)
  }

   tr:nth-child(n) > td:nth-child(1) {
      border: none;
   }

   tr:nth-child(n) > td:nth-child(n):not(:nth-child(3)) {
    border-left: none;
   }

  td:first-child {
    text-align: left;
    padding-left: 1rem;
  }

`;


export const BuyButton = styled(Button)`
  width: 100%;
  height: 50px;
  font-weight: bold;
  margin: 0;
  border-radius: 0;
`;


export const TerminateButton = styled(Paragraph.withComponent(Link))`
  text-decoration: none;
  margin: 0;
  padding: 0;
`;

