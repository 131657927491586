//@flow

import {EntityUrlProvider} from "../Base/EntityUrlProvider";


class TargetUrlProvider implements EntityUrlProvider {
    ENTITY_ROUTE = "/targets";

    getAll() {
        return `${this.ENTITY_ROUTE}`;
    }

    getOne(id: string) {
        return `${this.ENTITY_ROUTE}/${id}`;
    }

    create() {
        return `${this.ENTITY_ROUTE}`;
    }

    update(id: string) {
        return `${this.ENTITY_ROUTE}/${id}`;
    }

    delete(id: string) {
        return `${this.ENTITY_ROUTE}/${id}`;
    }
}

export {TargetUrlProvider};
