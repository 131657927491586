//@flow

import styled, {css} from "styled-components"
import {Colors, ColorTheme} from "../../Theme"
import {Button} from "@greenbone/cloud-component-library"


export const SortButton = styled(Button)`
    border-radius: 3px;
    border: 1px solid ${Colors.grey.light};
    background-color: white;
    color: ${Colors.grey.light};
    min-width: 3.13rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    margin-top: 0.31rem;
    margin-bottom: 0.31rem;
    font-weight: normal;
    font-family: DroidSans, sans-serif;

    &:hover {
        background-color: white;
    }

    &:focus {
        outline: 1px solid #fff;
        outline-offset: -0.25rem;
    }

    ${props => props.disabled && css`
        &:hover {
            border: 1px solid ${Colors.grey.light};
        }

        &:focus {
            outline: 1px solid #fff;
            outline-offset: -0.25rem;
        }
    `}

    ${props => props.active && css`
        border: 2px solid ${props => props.theme.button.normal.background};
        color: ${props => props.theme.button.normal.background};
        font-weight: bold;

        &:hover {
            border: 2px solid ${props => props.theme.button.normal.hover};
        }

        &:focus {
            outline: 2px solid #fff;
            outline-offset: -0.25rem;
        }
    `}
`

export const FirstButton = styled(SortButton)`
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    ${props => props.active && css`
        background: ${props => props.theme.button.normal.background};
        color: ${props => props.theme.button.normal.text};
        border-right: 2px solid ${props => props.theme.button.normal.background};

        &:hover {
            background: ${props => props.theme.button.normal.background};
        }
    `}
    &:focus {
        outline: none;
    }

`

FirstButton.defaultProps = {
    theme: ColorTheme
}

export const SecondButton = styled(SortButton)`
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    &:focus {
        outline: none;
    }

    ${props => props.active && css`
        background: ${props => props.theme.button.normal.background};
        color: ${props => props.theme.button.normal.text};
        border-left: 2px solid ${props => props.theme.button.normal.background};

        &:hover {
            background: ${props => props.theme.button.normal.background};
        }
    `}

    margin-left: 0rem !important;
`

SecondButton.defaultProps = {
    theme: ColorTheme
}

