import {RestApiClient} from "@greenbone/cloud-component-library"
import {globalInstanceStorage} from "../../../../GlobalSingle"
import {ReportOverviewUrlProvider} from "./ReportOverviewUrlProvider"

function ErrorHandler(e) {
    throw e;
}

export class ReportOverviewRestApiClient {
    constructor(
        apiClient = new RestApiClient(
            globalInstanceStorage.getFetchClient(),
            ErrorHandler
        )) {
        this.apiClient = apiClient;
        this.urlProvider = new ReportOverviewUrlProvider();
    }

    getRiskOfReport = async (id) => {
        return await this.apiClient.get(this.urlProvider.riskOfReport(id));
    };

    async getVulnerabilitiesWithSolution(id) {
        return await this.apiClient.get(this.urlProvider.vulnerabilitiesWithSolution(id));
    }

    async getVulnerabilityBySeverity(id) {
        return await this.apiClient.get(this.urlProvider.vulnerabilityBySeverity(id));
    }

    async getTopHostSortedByQuantity(id) {
        return await this.apiClient.get(this.urlProvider.topHostSortedByQuantity(id));
    }

    async getTopHostSortedBySeverity(id) {
        return await this.apiClient.get(this.urlProvider.topHostSortedBySeverity(id));
    }

    async getVulnerabilitySortedBySolution(id) {
        return await this.apiClient.get(this.urlProvider.vulnerabilitySortedBySolution(id));
    }

    async getTotalVulnerabilities(id) {
        return await this.apiClient.get(this.urlProvider.totalVulnerabilities(id));
    }
}
