import React from "react"
import styled, {css} from "styled-components"

const NavContainer = styled.div`
  min-width: 19rem;
  max-width: 19rem;
  min-height: calc(100vh - 3.75rem);
  background: ${props => props.theme.sidebar.background};
  color: ${props => props.theme.sidebar.font};
  transition: all 0.3s;
  border-right: 2px solid ${props => props.theme.sidebar.border};
  
  ${props => props.minified && css`
    min-width: 4.75rem;
    max-width: 4.75rem;
  `};
`;

const Nav = styled.nav`
 min-height: calc(100vh - 3.75rem);
 display: flex;
 flex-direction: column;
 justify-content: space-between;
 box-sizing: border-box;
 padding-bottom: 1.5rem;
`;


export function Sidebar({minified, onMouseEnter, onMouseLeave, children, navStyle, ...other}) {
    return <NavContainer minified={minified} onMouseEnter={onMouseEnter} {...other}
                         onMouseLeave={onMouseLeave}>
        <Nav style={navStyle}>
            {children}
        </Nav>
    </NavContainer>
}
