//@flow

import {IconButton, Tooltip, withStyles, withTheme} from "@material-ui/core"
import {AssignmentInd} from "@material-ui/icons"
import React from "react"
import {withTranslation} from "react-i18next"
import {connect} from "react-redux"
import {bindActionCreators, compose} from "redux"
import {openConfirmDialog} from "../Dialogs/ConfirmDialog/actions"


const styles = theme => ({})

type Props = {
    classes: typeof styles,
    theme: any,
    openConfirmDialog: typeof openConfirmDialog,
    changeMainUser: () => {},
    emailAddress: string,
    t: any,
}

class ChangeMainUserButton extends React.Component<Props, {}> {

    changeMainUser = (event: SyntheticEvent<HTMLButtonElement>) => {
        const {t, emailAddress} = this.props
        this.props.openConfirmDialog(t("common.error.danger"), t(`Do you want to make '{{email}}' the main user?`, {"email": emailAddress}), () => {
            this.props.changeMainUser()
        })
    }

    render() {
        const {t} = this.props

        return (
            <Tooltip title={t("Make this user the main user")}>
                <IconButton disabled={this.props.disabled} onClick={this.changeMainUser}>
                    <AssignmentInd/>
                </IconButton>
            </Tooltip>
        )
    }
}

function mapDispatchToProps(dispatch: any) {
    let actions = bindActionCreators({
        openConfirmDialog: openConfirmDialog
    }, dispatch)
    return {...actions, dispatch}
}

function mapStateToProps(state) {
    return {}
}

export default compose(
    withStyles(styles),
    withTheme,
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps)
)(ChangeMainUserButton)
