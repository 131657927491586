import jwt from "jsonwebtoken";

export function getProperty(token, property) {
    if (!token) {
        return null;
    }

    const decoded = jwt.decode(token);
    if (!property) {
        return decoded;
    }
    if (!decoded) {
        throw new Error("Invalid JWT token");
    }

    return decoded[property] || null;
}

export function getName(token) {
    return getProperty(token, "name");
}

export function getUsername(token) {
    return getProperty(token, "preferred_username");
}

export function getGivenName(token) {
    return getProperty(token, "given_name");
}

export function getFamilyName(token) {
    return getProperty(token, "family_name");
}

export function getEmail(token) {
    return getProperty(token, "email");
}
