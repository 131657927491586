import React, {useState} from 'react';
import {Button, ButtonSpinner, Headline} from "@greenbone/cloud-component-library";
import {useTranslation} from "react-i18next";
import {Col, Row} from "reactstrap";
import Select from "react-select";
import InputLabel from "@material-ui/core/InputLabel";
import PermissionsService from "./PermissionsService";

export const MemberPermissionsForm = ({userId, defaultPermissionLevel, close}) => {
    const permissionsService = new PermissionsService();

    const [permissionLevel, setPermissionLevel] = useState(defaultPermissionLevel)
    const [sending, setSending] = useState(false)

    const {t} = useTranslation()

    const handleSubmit = async (event) => {
        event.preventDefault()
        setSending(true)

        await permissionsService.putUserPermissions(userId, permissionLevel)

        setSending(false)
        close()
    }

    const options = [
        {
            value: 'READ',
            label: t('permissions.options.read')
        },
        {
            value: 'WRITE',
            label: t('permissions.options.write')
        }
    ];

    const defaultValue = options.find(o => o.value === defaultPermissionLevel) || options[0]

    return <div style={{width: "30rem"}}>
        <Headline className="Font__Headline">{t('permissions.headline')}</Headline>
        <form onSubmit={handleSubmit}>
            <Row style={{marginBottom: "2rem"}}>
                <Col>
                    <InputLabel htmlFor="permissions" style={{marginBottom: ".5rem"}}>{t('permissions.headline')}</InputLabel>
                    <Select
                        id="permissions"
                        defaultValue={defaultValue}
                        theme={(theme) => ({
                            ...theme,
                            borderRadius: "2px",
                        })}
                        onChange={val => setPermissionLevel(val.value)}
                        options={options}
                    >
                    </Select>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button
                        id="sendPermissions"
                        style={{margin: 0}}
                        disabled={sending}
                    >
                        {t("permissions.save")}
                        <ButtonSpinner hidden={!sending}/>
                    </Button>
                </Col>
            </Row>
        </form>
    </div>
}
