//@flow

import React from "react"
import {withTranslation} from "react-i18next"
import {connect} from "react-redux"
import {bindActionCreators, compose} from "redux"
import {InfoText} from "../../../Components/Basic/InfoText"
import {ListContainer} from "../../../Components/Basic/ListContainer"
import {openAlertDialog} from "../../../Components/Dialogs/AlertDialog/actions"
import {ModalSlideIn} from "../../../Components/Dialogs/SlideIn/ModalSlideIn"
import ErrorBoundary from "../../../Components/ErrorBoundary"
import {Button} from "@greenbone/cloud-component-library"
import {Headline} from "../../../StyledComponents/Font/Font"
import {CredentialsEdit} from "./CredentialsEdit"
import {CredentialsList} from "./CredentialsList"


type State = {
    refresh: boolean;
    isSlideInOpen: boolean;
    id: ?string;
}

type Props = {
    t: any;
}

class _CredentialsPage extends React.Component<Props, State> {

    state = {
        isSlideInOpen: false,
        refresh: false,
        id: null,
    };

    openEditCredentialsSlideIn = (id: string) => {
        this.setState({
            isSlideInOpen: true,
            id: id
        })
    };

    openCreateCredentialsSlideIn = () => {
        this.setState({
            isSlideInOpen: true,
            id: null
        })
    }

    handleCloseSlideIn = () => {
        this.setState({
            isSlideInOpen: false,
            refresh: !this.state.refresh,
        });
    };

    render() {
        const {t} = this.props;
        return (
            <div>
                <Headline>{t("credentials.components.information.loginData")}</Headline>
                <InfoText headline={""}>
                    <p>
                        {t("credentials.components.page.loginInformation")}
                    </p>
                </InfoText>

                <ListContainer>
                    <ErrorBoundary>
                        <CredentialsList
                            actions={<Button style={{margin: 0}} onClick={this.openCreateCredentialsSlideIn}>
                                {t("credentials.components.page.newLoginData")}
                            </Button>} refresh={this.state.refresh} onUpdateEntry={this.openEditCredentialsSlideIn}/>
                    </ErrorBoundary>
                    <ModalSlideIn isOpen={this.state.isSlideInOpen} key={2}>
                        <CredentialsEdit onClose={this.handleCloseSlideIn} id={this.state.id}/>
                    </ModalSlideIn>
                </ListContainer>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    let actions = bindActionCreators(
        {
            openAlertDialog: openAlertDialog,
        },
        dispatch,
    );
    return {...actions, dispatch};
}

function mapStateToProps(state) {
    return {};
}

export const CredentialsPage = compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps),
)(_CredentialsPage);
