import Chartist from "chartist"
import "chartist-plugin-legend"
import "chartist-plugin-pointlabels"
import "chartist-plugin-tooltips-updated"
import "jquery"
import React from "react"
import {withTranslation} from "react-i18next"
import ChartistGraph from "@greenbone/cloud-react-chartist"
import {SIODateTime} from "../../controller/SIODateTime"
import {Headline} from "../../StyledComponents/Font/Font"
import {FlexRow} from "@greenbone/cloud-component-library"
import {ChartistGraphDataController} from "./ChartistGraphDataController"
//$FlowFixMe
import "./scss/chartist.scss"
//$FlowFixMe
import "./scss/legend.scss"
import {compose} from "redux"
import styled from "styled-components"

type Props = {
    data: ? Array<any>;
    legendText: string;
    t: any
}

const NotEnoughData = styled(Headline)`
  position: absolute;
  text-align: center;
  margin-top: 11rem;
  margin-left: calc(50vw - 20rem);
`;

class _LineGraph extends React.Component<Props, {}> {

    render() {
        const {t} = this.props;
        const {data} = this.props;

        // if (!data || data.length < 1) {
        //
        //     return <FlexRow alignItems={"center"} justifyContent={"center"}>
        //         <Headline style={{
        //             margin: "0",
        //             height: "1em",
        //             lineHeight: "1em",
        //         }}>{t("components.table.enhanced.notEnoughData")}</Headline>
        //     </FlexRow>;
        //
        // }

        const inputData = {
            series: [
                ...data
            ]
        };

        const options = {
            plugins: [
                Chartist.plugins.legend({
                    position: "bottom"
                }),
                Chartist.plugins.ctPointLabels({
                    textAnchor: "middle",
                    labelInterpolationFnc: function (value) {
                        if (value.toString().includes(",")) {
                            return (value.split(",")[1]);
                        }
                        return 0;
                    }
                }),
                Chartist.plugins.tooltip({
                    anchorToPoint: false,
                    transformTooltipTextFnc: function (tooltip) {
                        const xy = tooltip.split(",");
                        return xy[1];
                    }
                })

            ],
            height: "25rem",
            showArea: true,
            chartPadding: {
                right: 60,
                top: 25
            },
            lineSmooth: Chartist.Interpolation.monotoneCubic({
                fillHoles: true
            }),
            axisY: {
                scaleMinSpace: 40,
                onlyInteger: true
            },
            axisX: {
                type: Chartist.FixedScaleAxis,
                divisor: 10,
                ticks: [
                    ...ChartistGraphDataController.getTicks(inputData)
                ],

                labelInterpolationFnc: function (value) {
                    return SIODateTime.fromMillis(value.getTime()).toDateString();
                }
            }
        };

        const type = "Line";

        return (
            <div>
                {
                    (!data || data.length < 1) &&
                    <NotEnoughData>{t("components.table.enhanced.notEnoughData")}</NotEnoughData>
                }
                <FlexRow>
                    <div style={{width: "20px", display: "flex", alignItems: "center", justifyContent: "center"}}>
                        <div style={{transform: "rotate(-90deg)"}}>
                            <nobr>
                                {t("scanmanagement.graph.legend")}
                            </nobr>
                        </div>
                    </div>
                    <ChartistGraph style={{width: "100%"}} data={inputData} options={options} type={type}/>
                </FlexRow>
            </div>

        );
    }
}

export const LineGraph = compose(
    withTranslation()
)(_LineGraph);
