import React, {useContext} from "react"
import {InformationBarContext} from "./InformatinBarContext"
import styled from "styled-components"
import {ColorTheme} from "../../../Theme"
import {IconButton} from "@material-ui/core"
import {Close} from "@material-ui/icons"
import {GhostButton} from "../Button"
import {FlexRow} from "../Flex"


export const Bar = styled.div`
  background: ${props => (ColorTheme.alerts[props.type].background)};
  color: ${props => (ColorTheme.alerts[props.type].foreground)};
  height: 3.4rem;
  box-sizing: border-box;
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ActionButton = styled(GhostButton)`
  color: black;
  border-color: black;
  margin: 0;

  &:hover {
    color: black;
    border-color: black;
  }
`


export function InformationBarView(props) {

    const {bars} = useContext(InformationBarContext)


    //type, component, close, action, actionText
    return <>
        {bars.map(({type, component, close, action, actionText, uuid}) => (
            <Bar type={type} key={uuid}>
                <FlexRow alignItems={"center"} fullWidth justifyContent={"space-between"}>
                    {component}
                    {action && <ActionButton onClick={() => {
                        action()
                    }}>
                        {actionText}
                    </ActionButton>}
                </FlexRow>

                <IconButton onClick={close}>
                    <Close/>
                </IconButton>
            </Bar>
        ))}
    </>;

}
