// @flow

import React from "react";
import {withTranslation} from "react-i18next";
import {ColSmall} from "./Grid";
import {TabBox} from "./TabBox";
import {compose} from "redux";


type Props = {
    onChange: EventCallback;
    selectedId: ?string;
    schedules: Array<GSPScheduleEntity>;
    t: any;
}

export class _ScheduleSelect extends React.Component<Props> {

    render() {
        const {schedules, selectedId, onChange, t} = this.props;

        return <React.Fragment>{
            schedules.length ?
                schedules.map((schedule: GSPScheduleEntity) => {
                    return <ColSmall key={schedule.id} xl={3} lg={6} md={6} sm={12} style={{marginBottom: "1.88rem"}}>
                        <TabBox style={{height: "6rem"}}
                                title={schedule.name}
                                active={schedule.id === selectedId}
                                onClick={() => {
                                    onChange(schedule.id);
                                }}>

                            {schedule.startDate.toFormat("cccc | HH:mm 'Uhr'")}
                        </TabBox>
                    </ColSmall>;
                })
                :
                <ColSmall>{t("wizard.components.credentialSelect.notFound")}</ColSmall>
        }</React.Fragment>;
    }
}

export const ScheduleSelect = compose(
    withTranslation(),
)(_ScheduleSelect);
