import React from 'react';
import {useTranslation} from 'react-i18next';
import ActionElements from '../../../Components/ActionElements';
import {DynamicTableCell, DynamicTableRow} from "../../../areas/Task/components/Table/DynamicTable";
import {Table} from "@greenbone/cloud-dynamic-table"
import InputWarning from "../../../Layout/components/InputWarning";

export function AdditionalEmailList({emails, actions, onUpdateEntry, onDeleteEntry, domainMismatch}) {
    const {t} = useTranslation();
    const deleteEmail = (email) => {
        onDeleteEntry(email)
    };

    const entryGenerator = ({row, getValue, dataset}) => {
        return <DynamicTableRow {...row.getRowProps()}>
            <DynamicTableCell>
                {getValue("email")}
                {domainMismatch && (
                    <InputWarning
                        message={t("additionalEmails.domain.warning")}
                    />
                )}
            </DynamicTableCell>
            <DynamicTableCell>
                <ActionElements
                    entity={dataset}
                    onUpdateEntry={() => onUpdateEntry(dataset.email)}
                    deleteEntity={() => deleteEmail(dataset.email)}
                    confirmMessage={t("notificationPage.additionalEmails.action.delete", {email: dataset.email})}
                />
            </DynamicTableCell>
        </DynamicTableRow>
    }

    const columns = [
        {
            Header: t("additionalEmails.list.email"),
            accessor: "email"
        },
        {
            Header: t("additionalEmails.list.action"),
            accessor: () => ""
        }
    ];

    return (
        <Table
            data={emails}
            id="additionalEmailTable"
            entryGenerator={entryGenerator}
            columns={columns}
            actions={actions}
        />
    );
}
