//@flow

import {Button, IconButton, withStyles} from "@material-ui/core"
import {Delete} from "@material-ui/icons"
import React from "react"
import {withTranslation} from "react-i18next"
import {connect} from "react-redux"
import {bindActionCreators, compose} from "redux"
import {Parser} from "../../Helper/Parser"
import {FlexRow} from "@greenbone/cloud-component-library"
import {openAlertDialog} from "../Dialogs/AlertDialog/actions"


const styles = theme => ({
    root: {
        flexGrow: 1,
        marginTop: theme.spacing(3)
    },
    uploadButton: {
        width: "74%",
        positions: "relativ",
        padding: "0",
    },
    uploadButtonDelete: {
        minWidth: "3.75rem",
        padding: "0",
    },
});

type Props = {
    classes: typeof styles;
    theme: any;
    onFileSelect: (string, string) => void;
    errorFileSelection: (string) => void;
    onFileRemove: () => void;
    openAlertDialog: typeof openAlertDialog;
    t:any;
}

type State = {
    fileName: string;
    isFileSelected: boolean;
};

class UploadComponent extends React.Component<Props, State> {
    constructor(props) {
        super(props);

        this.state = {
            fileName: "",
            isFileSelected: false,
        };
    }

    onFileSelected = (event: any) => {
        const {t} = this.props;
        event.preventDefault();
        const file: File = event.target.files[0];
        if (file.size > 16 * 1024) {
            this.props.openAlertDialog(t("common.messages.error"),t("common.error.message.fileToLarge"));
            return;
        }
        Parser.parseBase64(file)
            .then(parsedFile => {
                this.props.onFileSelect(file.name, parsedFile);

                this.setState({
                    fileName: file.name,
                });
            })
            .catch((exception: Error) => {
                this.props.errorFileSelection(exception.toString());
                this.setState({
                    fileName: "",
                });
            });
    };

    onFileRemove = (event: any) => {
        this.props.onFileRemove();
        this.setState({
            fileName: "",
        });
    };

    render() {
        const {classes,t} = this.props;

        return (
            <FlexRow full alignItems={"center"}>
                <IconButton
                    className={classes.uploadButtonDelete}
                    onClick={this.onFileRemove}
                    disabled={this.state.fileName === ""}>
                    <Delete/>
                </IconButton>
                <Button
                    label={t("action.fileSelect")}
                    color="primary"
                    variant={"contained"}
                    component="label"
                    className={classes.uploadButton}
                    name={"UploadButton"}>
                    {t("action.fileSelect")}
                    <input type="file"
                           name={"uploadInput"}
                           style={{display: "none"}}
                           onChange={this.onFileSelected}/>
                </Button>


            </FlexRow>
        );
    }
}

function mapDispatchToProps(dispatch) {
    let actions = bindActionCreators({
        openAlertDialog: openAlertDialog,
    }, dispatch);
    return {...actions, dispatch};
}

function mapStateToProps(state) {
    return {};
}

export default compose(
    withStyles(styles, {withTheme: true}),
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps),
)(UploadComponent);
