import React, {useEffect, useRef} from "react"
import {Spinner} from "@greenbone/cloud-component-library"
import {useTranslation} from "react-i18next"

export function GreenbonePrivacyPolicy() {

    const {i18n} = useTranslation()
    const containerElement = useRef(null)

    const language = i18n?.language

    useEffect(() => {
        fetch(`/ui/manual/legal/${language}/privacy.html`)
            .then(async response => {
                const html = await response.text()
                containerElement.current.innerHTML = html
            })
            .catch(e => {
                console.log(e)
            })
    }, [language])

    return <div ref={containerElement}>
        <Spinner/>
    </div>
}

