//@flow

import {Typography} from "@material-ui/core"
import React from "react"
import {withTranslation} from "react-i18next"
import {compose} from "redux"
import styled from "styled-components"
import InfoIconSVG from "../../../../assets/icons/scan-management/information-circle-grey.svg?url"
import SlideIn from "../../../Components/Dialogs/SlideIn"
import {Subheadline, Text} from "../../../Components/Font/Font"


export const InfoIcon = styled.div`
  background-image: url(${InfoIconSVG});
  background-repeat: no-repeat;
  height: 1.56rem;
  width: 1.56rem;
  margin-left: 0.63rem;
  margin-top: 0.31rem;

  &:hover {
    cursor: pointer;
  }
`;

type State = {
    open: boolean;
}

type Props = {
    status: ?string;
    t: any;
}

class _ExportFailedInformation extends React.Component<Props, State> {

    state = {
        open: false,
    };

    handleCloseSlide = (event: any) => {
        this.setState({open: false});
    };

    handleOpenSlide = (event: any) => {
        this.setState({open: true});
    };

    newInformation(headline: string, body: any) {
        return {
            headline,
            body,
        };
    }

    getTextForState(state: ?string) {
        const {t} = this.props;

        if (state === null) {
            return this.newInformation(t("exportFailed.unknownErrorHeader"),
                <div>
                    <Text>
                        {t("exportFailed.unknownErrorDetail")}
                    </Text>
                </div>,
            );
        }

        return {
            TIMEOUT: this.newInformation(
                t("exportFailed.timeoutHeader"),
                <div>
                    <Typography>
                        {t("exportFailed.timeoutDetail1")}
                    </Typography>
                    <Typography>
                        {t("exportFailed.timeoutDetail2")}
                    </Typography>
                </div>,
            ),
            INCOMPLETE_EXPORT_DATA: this.newInformation(t("exportFailed.incompleteDataHeader"),
                <div>
                    <Text light>
                        {t("exportFailed.incompleteData")}
                    </Text>
                    <ul>
                        <li>
                            <Text light>
                                {t("exportFailed.incompleteDataElement1")}
                            </Text>
                        </li>
                        <li>
                            <Text light>
                                {t("exportFailed.incompleteDataElement2")}
                            </Text>
                        </li>
                    </ul>
                </div>,
            ),
            TRANSFER_ERROR: this.newInformation(t("exportFailed.transferErrorHeader"),
                <Text light>
                    {t("exportFailed.transferErrorDetail")}
                </Text>,
            ),
            VALIDATION_ERROR: this.newInformation(t("exportFailed.validationErrorHeader"),
                <div>
                    <Text light>
                        {t("exportFailed.validationErrorDetail")}
                    </Text>
                    <ul>
                        <li>
                            <Text light>
                                {t("exportFailed.validationErrorElement1")}
                            </Text>
                        </li>
                        <li>
                            <Text light>
                                {t("exportFailed.validationErrorElement2")}
                            </Text>
                        </li>
                    </ul>
                </div>,
            ),
            IS_INACTIVE_ERROR: this.newInformation(t("exportFailed.noPlanForThisType"),
                <div>
                    <Text light>
                        {t("exportFailed.noPlanForThisType1")}
                    </Text>
                    <Text light>
                        {t("exportFailed.noPlanForThisType2")}
                    </Text>
                </div>
            ),
            LIMITATION_ERROR: this.newInformation(t("exportFailed.limitReachedHeader"),
                <div>
                    <Text light>
                        {t("exportFailed.limitReachedDetail1")}
                    </Text>
                    <Text light>
                        {t("exportFailed.limitReachedDetail2")}
                    </Text>
                </div>
            )
            //$FlowFixMe - Flow does not understand that this is null checked
        }[state];
    }

    render() {

        const element = this.getTextForState(this.props.status);

        if (!element) {
            return null;
        }

        return [
            <InfoIcon key={0} onClick={this.handleOpenSlide}/>,
            <SlideIn key={1} open={this.state.open} onClose={this.handleCloseSlide}>
                <div style={{maxWidth: "31.25rem"}}>
                    <Subheadline>
                        {element.headline}
                    </Subheadline>
                    {element.body}
                </div>

            </SlideIn>,
        ];
    }

}

export const ExportFailedInformation = compose(
    withTranslation(),
)(_ExportFailedInformation);
