import * as React from "react"
import {useTranslation} from "react-i18next"
import {PasswordInput} from "../Components/Input/Components/Password"
import {Validator} from "../Helper/Validator"


class FormFieldValidator {
    MessageComponent = (props: any) => {
        const {t} = useTranslation()
        return <>{t("validation.inputInvalid")}</>
    }
    //$FlowFixMe - Known Flow bug gitissue 4952
    message = <this.MessageComponent/>

    constructor(message: ?string | React.Node) {
        if (message) {
            this.message = message
        }
    }


    isValid(value: any) {
        return !!value
    };

    validate(value: any) {
        return this.isValid(value) || this.message
    }
}

const IsNotEmptyStringMessageComponent = (props) => {
    const {t} = useTranslation()
    return <>{t("validation.fieldNotEmpty")}</>
}

export class Required extends FormFieldValidator {

    validate(value: any) {
        return !!value;
    }
}

export class IsNotEmptyString extends FormFieldValidator {


    //$FlowFixMe - Known Flow bug gitissue 4952
    message = <IsNotEmptyStringMessageComponent/>

    validate(value: any) {
        if (value && value !== "") {
            return true
        }
        return <this.MessageComponent/>
    }
}

export class IsAnything extends FormFieldValidator {
    validate(value: any) {
        return true
    }
}

const IsNotEmptyListComponent = ({}) => {
    const {t} = useTranslation()
    return <>{t("validation.choiceNotEmpty")}</>
}

export class IsNotEmptyList extends FormFieldValidator {
    //$FlowFixMe - Known Flow bug gitissue 4952
    message = <IsNotEmptyListComponent/>


    validate(value: Array<any>) {
        if (value && value.length > 0) {
            return true
        }
        return this.message
    }
}

const IsIntegerMessageComponent = ({customText}) => {
    const {t} = useTranslation()
    if (customText)
        return <>{customText}</>
    return <>{t("validation.unsignedInt")}</>
}

export class IsInteger extends FormFieldValidator {

    //$FlowFixMe - Known Flow bug gitissue 4952
    message = <IsIntegerMessageComponent customText={this.customText}/>

    constructor(message) {
        super(<>message</>)
        this.customText = message
    }


    validate(value: any) {
        const number = Number.parseInt(value)
        if (Number.isInteger(number)) {
            return true
        }

        return this.message
    }
}

export class IsOptionalString extends FormFieldValidator {
    validate(value: any) {
        return true
    }
}

export class IsEmail extends FormFieldValidator {
    isValid(value: any) {
        if (value) {
            return Validator.isEMail(value)
        }
        return false
    }
}

export class CustomCallback extends FormFieldValidator {
    callback = null

    constructor(message: ?string, callback: any) {
        super(message)
        this.callback = callback
    }

    isValid(value: any) {
        if (this.callback) {
            return this.callback(value)
        }
        return false
    }
}

export class CustomValidationMethod extends FormFieldValidator {
    callback = null

    constructor(message: ?string | React.Node, callback: any) {
        super(message)
        this.callback = callback
    }

    validate(value: any) {
        if (this.callback) {
            return this.callback(value)
        }
        return false
    }
}

export class IsPasswordStrong extends FormFieldValidator {
    isValid(value: string) {
        return PasswordInput.isPasswordStrongEnough(value)
    }
}
