//@flow

import {DateTime} from "luxon"
import {i18n} from "../../i18n"
import {BritishDateFormat, BritishDateTimeFormat, GermanDateFormat, GermanDateTimeFormat} from "./DateTimeFormats"


export class SIODateTime {
    dateTime: DateTime

    /**
     * Not for public use, only needed to create the object. Call local() instead.
     * @param dateTime
     */
    constructor(dateTime: DateTime) {
        this.dateTime = dateTime ? dateTime : dateTime.utc();
    }

    static fromMillis(milliseconds: number) {
        return new SIODateTime(DateTime.fromMillis(milliseconds));
    }

    /**
     * Creates a new SIODateTime, all parameters are optional.
     * @param year
     * @param month
     * @param day
     * @param hour
     * @param minute
     * @param second
     * @param millisecond
     * @returns {SIODateTime}
     */
    static local(year?: number,
                 month?: number,
                 day?: number,
                 hour?: number,
                 minute?: number,
                 second?: number,
                 millisecond?: number
    ) {
        return new SIODateTime(DateTime.local(year, month, day, hour, minute, second, millisecond));
    }

    static utc(year?: number,
               month?: number,
               day?: number,
               hour?: number,
               minute?: number,
               second?: number,
               millisecond?: number
    ) {
        return new SIODateTime(DateTime.utc(year, month, day, hour, minute, second, millisecond));
    }

    get isSIODateTime() {
        return true;
    }

    /**
     * Checks if the SIODateTime object is valid.
     * @returns {boolean}
     */
    get isValid() {
        return this.dateTime.isValid
    }

    toJsDate() {
        return this.dateTime.toJSDate()
    }

    toJSDate() {
        return this.dateTime.toJSDate()
    }

    static fromJSDate(date) {
        return new SIODateTime(DateTime.fromJSDate(date))
    }

    /**
     * Required for object comparison
     * @returns {number}
     */
    valueOf(): number {
        return this.dateTime.toMillis()
    }

    equals(other: SIODateTime) {
        return this.dateTime.equals(other.dateTime);
    }

    setLocale(locale: string) {
        return new SIODateTime(this.dateTime.setLocale(locale));
    }

    setZone(zone: string) {
        return new SIODateTime(this.dateTime.setZone(zone));
    }

    /**
     * Parses an ISO DateTime String and returns an SIODateTime object.
     * Examples for ISO Strings:
     * 2007-08-31T16:47+00:00        16:47 Uhr am 31. August 2007 in der Zeitzone UTC.
     * 2007-12-24T18:21Z             18:21 Uhr am 24. Dezember 2007, ebenfalls in der Zeitzone UTC.
     * 2008-02-01T09:00:22+05        9:00:22 Uhr am 1. Februar 2008 in einer Zeitzone, die UTC fünf Stunden voraus ist, beispielsweise in der in Pakistan festgelegten Zonenzeit.
     * 2009-01-01T12:00:00+01:00     12:00:00 Uhr am 1. Januar 2009 in Wien (MEZ)
     * 2009-06-30T18:30:00+02:00     18:30:00 Uhr am 30. Juni 2009 in Wien (MESZ – Sommerzeit)
     * @param isoString : string
     * @returns {SIODateTime}
     */
    static parseIsoString(isoString: string): SIODateTime {
        return new SIODateTime(DateTime.fromISO(isoString));
    }

    /**
     * Returns a string formatted as HH:mm
     * @returns {string}
     */
    toTimeString(): string {
        return this.toFormat("HH:mm");
    }

    /**
     * Returns a date formatted in german or british english
     * @returns {string}
     */
    toDateString(): string {
        if (i18n.language === "en") {
            return this.toFormat(BritishDateFormat);
        }
        return this.toFormat(GermanDateFormat);
    }

    /**
     * Returns a dateTime formatted in german or british english
     * @returns {string}
     */
    toDateTimeString(): string {
        if (i18n.language === "en") {
            return this.toFormat(BritishDateTimeFormat);
        }
        return this.toFormat(GermanDateTimeFormat);
    }

    /**
     * Returns the date as an ISO String.
     * The ISO Format is specifically designed for the GSP Backend,
     * for that reason seconds, and milliseconds are omitted.
     * Returns a string in the following format "yyyy-MM-dd'T'HH:mmZZZ"
     * @returns {string}
     */
    toIsoString(): string {
        return this.toFormat("yyyy-MM-dd'T'HH:mmZZZ");
    }

    /**
     * Standalone token     Description                                                         Example
     S                      millisecond,no padding                                              54
     SSS                    millisecond,padded to 3                                             054
     u                      fractional seconds, functionally identical to SSS                   054
     s                      second, no padding                                                  4
     ss                     second, padded to 2 padding                                         04
     m                      minute, no padding                                                  7
     mm                     minute, padded to 2                                                 07
     h                      hour in 12-hour time, no padding                                    1
     hh                     hour in 12-hour time, padded to 2                                   01
     H                      hour in 24-hour time, no padding                                    9
     HH                     hour in 24-hour time, padded to 2                                   13
     Z                      narrow offset                                                       +5
     ZZ                     short offset                                                        +05:00
     ZZZ                    techie offset                                                       +0500
     ZZZZ                   abbreviated named offset                                            EST
     ZZZZZ                  unabbreviated named offset                                          Eastern Standard Time
     z                      IANA zone                                                           America/New_York
     a                      meridiem                                                            AM
     d                      day of the month, no padding                                        6
     dd                     day of the month, padded to 2                                       06
     c        E             day of the week, as number from 1-7 (Monday is 1, Sunday is 7)      3
     ccc      EEE           day of the week, as an abbreviate localized string                  Wed
     cccc     EEEE          day of the week, as an unabbreviated localized string               Wednesday
     ccccc    EEEEE         day of the week, as a single localized letter                       W
     L        M             month as an unpadded number                                         8
     LL       MM            month as an padded number                                           08
     LLL      MMM           month as an abbreviated localized string                            Aug
     LLLL     MMMM          month as an unabbreviated localized string                          August
     LLLLL    MMMMM         month as a single localized letter                                  A
     y                      year, unpadded                                                      2014
     yy                     two-digit year                                                      14
     yyyy                   four- to six- digit year, pads to 4                                 2014
     G                      abbreviated localized era                                           AD
     GG                     unabbreviated localized era                                         Anno Domini
     GGGGG                  one-letter localized era                                            A
     kk                     ISO week year, unpadded                                             17
     kkkk                   ISO week year, padded to 4                                          2014
     W                      ISO week number, unpadded                                           32
     WW                     ISO week number, padded to 2                                        32
     o                      ordinal (day of year), unpadded                                     218
     ooo                    ordinal (day of year), padded to 3                                  218
     q                      quarter, no padding                                                 3
     qq                     quarter, padded to 2                                                03
     D                      localized numeric date                                              9/4/2017
     DD                     localized date with abbreviated month                               Aug 6, 2014
     DDD                    localized date with full month                                      August 6, 2014
     DDDD                   localized date with full month and weekday                          Wednesday, August 6, 2014
     t                      localized time                                                      9:07 AM
     tt                     localized time with seconds                                         1:07:04 PM
     ttt                    localized time with seconds and abbreviated offset                  1:07:04 PM EDT
     tttt                   localized time with seconds and full offset                         1:07:04 PM Eastern Daylight Time
     T                      localized 24-hour time                                              13:07
     TT                     localized 24-hour time with seconds                                 13:07:04
     TTT                    localized 24-hour time with seconds and abbreviated offset          13:07:04 EDT
     TTTT                   localized 24-hour time with seconds and full offset                 13:07:04 Eastern Daylight Time
     f                      short localized date and time                                       8/6/2014, 1:07 PM
     ff                     less short localized date and time                                  Aug 6, 2014, 1:07 PM
     fff                    verbose localized date and time                                     August 6, 2014, 1:07 PM EDT
     ffff                   extra verbose localized date and time                               Wednesday, August 6, 2014, 1:07 PM Eastern Daylight Time
     F                      short localized date and time with seconds                          8/6/2014, 1:07:04 PM
     FF                     less short localized date and time with seconds                     Aug 6, 2014, 1:07:04 PM
     FFF                    verbose localized date and time with seconds                        August 6, 2014, 1:07:04 PM EDT
     FFFF                   extra verbose localized date and time with seconds                  Wednesday, August 6, 2014, 1:07:04 PM Eastern Daylight Time
     X                      unix timestamp in seconds                                           1407287224
     x                      unix timestamp in milliseconds                                      1407287224054
     * See https://moment.github.io/luxon/docs/manual/formatting.html
     * @param formatString
     * @returns {string}
     */
    toFormat(formatString: string): string {
        if (formatString === "yyyy-MM-dd") {
            return `${this.dateTime.year}-${this.twoDigits(this.dateTime.month)}-${this.twoDigits(this.dateTime.day)}`;
        } else if (formatString === "dd.MM.yyyy HH:mm 'Uhr'") {
            return `${this.twoDigits(this.dateTime.day)}.${this.twoDigits(this.dateTime.month)}.${this.dateTime.year} ` +
                `${this.twoDigits(this.dateTime.hour)}:${this.twoDigits(this.dateTime.minute)} Uhr`;
        } else {
            return this.dateTime.toFormat(formatString);
        }
    }

    twoDigits(value: number) {
        return value < 10 ? "0" + value : value;
    }

    /**
     * Allow calculation with dates.
     * To substract pass a negative value;
     * @param object
     * @returns {DateTime}
     */
    add(object: {
        years?: number,
        months?: number,
        days?: number,
        hours?: number,
        minutes?: number,
        seconds?: number,
        milliseconds?: number
    }) {
        return new SIODateTime(this.dateTime.plus(object));
    }

    /**
     * Compares if the passed time unit is the same on both dates
     * @param dateTime
     * @param timeElement
     * @returns {boolean}
     */
    hasSame(dateTime: SIODateTime, timeElement: "millisecond" | "second" | "minute" | "hour" | "day" | "month" | "year") {
        return this.dateTime.hasSame(dateTime, timeElement);
    }

    /**
     * Resets the time to 00:00
     * @param dateTime
     * @returns {SIODateTime}
     */
    stripTime() {
        if (!this.isValid) {
            throw new Error("Date is invalid");
        }

        const dateTime = this.dateTime;

        return SIODateTime.utc(dateTime.year, dateTime.month, dateTime.day);
    }

    toString() {
        this.dateTime.toString();
    }

}
