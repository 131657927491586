import {useContext, useEffect, useState} from "react"
import {SubscriptionContext} from "../Subscritpion/SubscriptionProvider"
import {useInformationBar} from "../../Layout/components/InformatinBarContext"
import {useNavigate} from "react-router"
import {GroupSettingsUrlProvider} from "../../UrlProvider/GroupSettingsUrlProvider"
import {useTranslation} from "react-i18next"


export const AddressAlertBarRenderer = () => {
    const {billingConfigured, loading} = useContext(SubscriptionContext)
    const {warning, closeByUuid} = useInformationBar()
    const navigate = useNavigate()
    const {t} = useTranslation()
    const [id, setId] = useState(null)


    useEffect(() => {
        if (!loading && billingConfigured === false) {
            setId(
                warning(`${t("addressAlertBarRenderer.missingAddress.headline")}`
                    , () => {
                        navigate(GroupSettingsUrlProvider.billing())
                    }, t("addressAlertBarRenderer.missingAddress.text"))
            )
        }

        if (!loading && billingConfigured === true) {
            closeByUuid(id)
        }
    }, [billingConfigured, loading])


    return null
}
