//@flow
import * as HttpStatuscode from "http-status-codes";


export class PayloadTooLargeError extends Error {
    type = PayloadTooLargeError;
    status: number = HttpStatuscode.REQUEST_TOO_LONG;

    constructor(message: string = "") {
        super(`Datei zu Groß '${message}'`);
    }
}
