import {RestApiClient} from "@greenbone/cloud-component-library";
import {globalInstanceStorage} from "../../GlobalSingle";


function errorHandler (e) {
    throw e;
}

export default class PermissionsService {

    constructor() {
        this.client = new RestApiClient(
            globalInstanceStorage.getFetchClient(),
            errorHandler
        )
    }

    async getUserPermissions(userId) {
        return await this.client.get(this.baseUrl(userId));
    }

    async putUserPermissions(userId, permissionLevel) {
        return await this.client.put(this.baseUrl(userId), {permission: permissionLevel})
    }

    baseUrl(userId) {
        return `/usermanagement/user/${userId}/permissions`;
    }
}
