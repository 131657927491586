import React, {useState} from "react"
import styled, {css} from "styled-components"
import {Polygon} from "./Polygon"
import {FlexRow} from "../Flex"

const IconSurrounder = styled.div`
  margin-right: 30px;
  display:  flex;
  justify-content: center;
  flex-shrink: 0;
  svg {
    
    width: 13px;
    height: 7px;
    color: #CCCCCC;
    ${({open, theme}) => open && css`
      transform: rotate(180deg);
      color: ${theme.base}
    `}
  }
`

const Title = styled.span`
  color: #4C4C4D
`

const Body = styled.div`
  padding: 19px;
  display: none;
  ${({open}) => open && css`
    display: block;
  `}
`


export function Accordion(props) {
    const {children, title} = props
    const [open, setOpen] = useState(false)

    const handleClick = event => {
        event.preventDefault()

        setOpen(prevState => !prevState)
    }

    return <div style={{marginBottom: "30px"}}  {...props}>
        <FlexRow style={{cursor: "pointer"}} onClick={handleClick} alignItems={"center"}>
            <IconSurrounder open={open}>
                <Polygon/>
            </IconSurrounder>
            <Title>
                {title}
            </Title>
        </FlexRow>
        <Body open={open}>
            {children}
        </Body>
    </div>
}