import React, {useState} from "react"
import styled, {css} from "styled-components"
import ShieldIcon from "../assets/check-shield.svg"
import BubbleIcon from "../assets/alert-user.svg"
import CogHandIcon from "../assets/cog-hand-give.svg"
import CogSearchIcon from "../assets/cog-search-1.svg"
import LaptopIcon from "../assets/alert-message-laptop.svg"

const hexCharacters = "a-f\\d"
const match3or4Hex = `#?[${hexCharacters}]{3}[${hexCharacters}]?`
const match6or8Hex = `#?[${hexCharacters}]{6}([${hexCharacters}]{2})?`
const nonHexChars = new RegExp(`[^#${hexCharacters}]`, "gi")
const validHexSize = new RegExp(`^${match3or4Hex}$|^${match6or8Hex}$`, "i")


export const ESolutionType = {
    Mitigation: "Mitigation",
    Workaround: "Workaround",
    VendorFix: "VendorFix",
    WillNotFix: "WillNotFix"
}


function hexToRgb(hex) {
    if (typeof hex !== "string" || nonHexChars.test(hex) || !validHexSize.test(hex)) {
        throw new TypeError("Expected a valid hex string")
    }

    hex = hex.replace(/^#/, "")
    let alpha = 1

    if (hex.length === 8) {
        alpha = parseInt(hex.slice(6, 8), 16) / 255
        hex = hex.slice(0, 6)
    }

    if (hex.length === 4) {
        alpha = parseInt(hex.slice(3, 4).repeat(2), 16) / 255
        hex = hex.slice(0, 3)
    }

    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
    }

    const num = parseInt(hex, 16)
    const red = num >> 16
    const green = (num >> 8) & 255
    const blue = num & 255

    return {red, green, blue, alpha}
}

function addAlphaToHex(hex, alpha) {
    const {red, green, blue} = hexToRgb(hex)
    return `rgba(${red}, ${green}, ${blue}, ${alpha})`
}

export const SolutionTypeOuter = styled.div`
    transition: width 1s;
    box-sizing: border-box;

    & + & {
        margin-left: 1rem;
    }

    width: 3.125rem;
    ${props => props.open && css`
        width: 12rem;
    `};

    height: 3.125rem;
    border-radius: 1.5625rem;
    padding-left: 0.625rem;
    padding-right: 0.625rem;
    background: ${props => addAlphaToHex(props.color, props.active ? 1 : 0.15)};
    color: ${props => props.iconColor};
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${props => props.active && css`
        color: white;
        border: 2px solid ${props => props.iconColor};
    `}

    ${props => props.small && css`
        height: 40px;
        width: 150px;
    `}
`

export const ScaleSolutionTab = styled.div`
    ${SolutionTypeOuter} {
        ${({left}) => left && "transform-origin: left;"}
        transform: scale(${props => props.scale ? props.scale : 0.8});
    }
`

const Icon = styled.div`
    width: 1.875rem;
    height: 1.875rem;
    flex-shrink: 0;

    ${props => props.small && css`
        height: 1.5rem;
        width: 1.5rem;
    `}
    svg {
        width: 100%;
        height: 100%;
    }
`

const Text = styled.div`
    white-space: nowrap;
    width: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;

    ${props => props.small && css`
        font-size: .8rem;
    `}
`

export function SolutionTab({icon, children, color, iconColor, active, defaultOpen, small, ...other}) {
    const [open, setOpen] = useState(!!defaultOpen)

    const onMouseEnter = event => {
        if (!defaultOpen) {
            setOpen(true)
        }

    }

    const onMouseLeave = event => {
        if (!defaultOpen)
            setOpen(false)
    }

    return <SolutionTypeOuter small={small} active={active} color={color} iconColor={iconColor} open={open}
                              onMouseEnter={onMouseEnter}
                              onMouseLeave={onMouseLeave} {...other}>
        <Icon small={small}>
            {icon}
        </Icon>
        <Text small={small} style={{display: open ? "flex" : "none"}}>
            {children}
        </Text>
    </SolutionTypeOuter>
}


export function SolutionTypeToText(type) {
    switch (type) {
        case "Mitigation":
            return "Temporary Fix"

        case "Workaround":
            return "Risk Reduction"

        case "VendorFix":
            return "Official Fix"

        case "WillNotFix":
            return "Searching For Fix"

        case "":
            return "No Fix Available"

        default:
            return ""
    }
}


export const SwitchSolutionTab = ({type, open, small}) => {
    if (open !== false) {
        open = true
    }

    if (small !== true) {
        small = false
    }


    const
        color = "#7DB5D0",
        iconColor = "#0476AC"


    switch (type) {
        case ESolutionType.Mitigation:
            return <SolutionTab small={small} defaultOpen={open} color={color}
                                iconColor={iconColor}
                                icon={<BubbleIcon/>}>
                {SolutionTypeToText(type)}
            </SolutionTab>

        case ESolutionType.Workaround:
            return <SolutionTab small={small} defaultOpen={open}
                                color={color}
                                iconColor={iconColor} icon={<ShieldIcon/>}>
                {SolutionTypeToText(type)}
            </SolutionTab>

        case ESolutionType.VendorFix:
            return <SolutionTab small={small} defaultOpen={open}
                                color={color}
                                iconColor={iconColor} icon={<CogHandIcon/>}>
                {SolutionTypeToText(type)}
            </SolutionTab>

        case ESolutionType.WillNotFix:
            return <SolutionTab small={small} defaultOpen={open}
                                color={color}
                                iconColor={iconColor} icon={<CogSearchIcon/>}>
                {SolutionTypeToText(type)}
            </SolutionTab>

        default:
            return <SolutionTab small={small} defaultOpen={open}
                                color={color}
                                iconColor={iconColor} icon={<LaptopIcon/>}>
                {SolutionTypeToText(type)}
            </SolutionTab>
    }
}

export function GetSolutionTabByType(type, open = true) {

    return <SwitchSolutionTab type={type} open={open} small={false}/>


}
