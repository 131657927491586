import {GroupSettingsUrlProvider} from "../../UrlProvider/GroupSettingsUrlProvider";

export class SubscriptionNavigationUrlProvider {
    static change() {
        return `${GroupSettingsUrlProvider.subscription()}/change`;
    }

    static address() {
        return `${GroupSettingsUrlProvider.subscription()}/address`;
    }

    static payment() {
        return `${GroupSettingsUrlProvider.subscription()}/payment`;
    }

    static confirm() {
        return `${GroupSettingsUrlProvider.subscription()}/confirm`;
    }
}
