//@flow

import React from "react";
import {useTranslation} from "react-i18next";
import {CredentialTabBox} from "./CredentialTabBox";
import {ColSmall} from "./Grid";


type Props = {
    handleClick: EventCallback;
    credentials: Array<GSPCredentialsDto>;
    selectedId: ?string;
}

export function CredentialSelect(props: Props) {
    const {t} = useTranslation();
    const {credentials, handleClick, selectedId} = props;

    return (

        <React.Fragment>
            {
                credentials.length ?
                    credentials.map((credential: GSPCredentialsDto) => (
                        <ColSmall key={credential.id} xl={3} lg={6} md={6} sm={12} style={{marginBottom: "1.88rem"}}>
                            <CredentialTabBox active={selectedId === credential.id} onClick={handleClick}
                                              credential={credential}/>
                        </ColSmall>
                    ))
                    :
                    <ColSmall>{t("wizard.components.credentialSelect.notFound")}</ColSmall>
            }
        </React.Fragment>

    );
}
