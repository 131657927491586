// @flow

import React from "react"
import styled from "styled-components"
import {Colors} from "../../../Theme"


type Props = {
    title: string;
    children?: any;
}

const Text = styled.div`
  background-color: ${Colors.yellow.light};
  padding: 0.19rem 0.50rem;
  display: inline-block;
  margin-right: 2px;
  float: right;
  top: -1.85rem;
  position: relative;
  color: ${Colors.grey.dark}
`;

export const Recommendation = (props: Props) => {
    return <React.Fragment>
        <div>
            <Text>{props.title}</Text>
        </div>
        {props.children}
    </React.Fragment>;
};
