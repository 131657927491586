import React from "react"
import {SubscriptionOverview} from "./Slides/SubscriptionOverview"
import ErrorBoundary from "../../Components/ErrorBoundary"


export function SubscriptionPage(props) {
    return <ErrorBoundary>
        <SubscriptionOverview/>
    </ErrorBoundary>

}
