//@flow

import {SnackbarTypes} from "./index";


interface ICONSTANTS {
    OPEN_SNACKBAR: string;
    CLOSE_SNACKBAR: string;
}

export const CONSTANTS: ICONSTANTS = {
    OPEN_SNACKBAR: "OPEN_SNACKBAR",
    CLOSE_SNACKBAR: "CLOSE_SNACKBAR",

};

export const openInfoSnackbar = (message: string) => {
    return {
        type: CONSTANTS.OPEN_SNACKBAR,
        message,
        snackbarType: SnackbarTypes.Info,
    };
};

export const openSuccessSnackbar = (message: string) => {
    return {
        type: CONSTANTS.OPEN_SNACKBAR,
        message,
        snackbarType: SnackbarTypes.Success,
    };
};

export const openWarningSnackbar = (message: string) => {
    return {
        type: CONSTANTS.OPEN_SNACKBAR,
        message,
        snackbarType: SnackbarTypes.Warning,
    };
};

export const openErrorSnackbar = (message: string) => {
    return {
        type: CONSTANTS.OPEN_SNACKBAR,
        message,
        snackbarType: SnackbarTypes.Error,
    };
};

export const closeSnackbar = () => ({
    type: CONSTANTS.CLOSE_SNACKBAR,
});
