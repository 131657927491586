import React, {useEffect, useState} from "react"
import {VulnerabilitiesWithSolution} from "@greenbone/cloud-chart-library"
import {ReportOverviewRestApiClient} from "../../service/ReportOverviewRestApiClient"
import {FlexRow, Subheadline} from "@greenbone/cloud-component-library"
import {SolutionTypeToText} from "../../components/SolutionTab"
import {CenteredSpinner} from "../../components/CenteredSpinner";
import {useTranslation} from "react-i18next";


export function FixPercentageWithTheFollowingSolutions({id}) {

    const [data, setData] = useState(null)
    const {t} = useTranslation()

    useEffect(() => {
            async function f() {
                const apiClient = new ReportOverviewRestApiClient()

                const response = await apiClient.getVulnerabilitiesWithSolution(id)

                setData(response.data)
                // setData([{
                //     "solution": "Replace the SSL/TLS certificate with one signed by a trusted certificate authority.",
                //     "solutionType": "Mitigation",
                //     "count": 1
                // }, {"solution": "", "solutionType": "other", "count": 0}])
            }

            f()
        },
        [id]
    )

    if (data) {
        if (data.length < 2) {
            return <FlexRow full justifyContent={"center"} alignItems={"center"}>
                <Subheadline>{t("report.dashboard.noSolution")}</Subheadline>
            </FlexRow>
        }

        let other = data.find((entry => entry.solutionType === "other"))
        let rest = data.filter(entry => entry.solutionType !== "other")


        rest = rest.map(i => ({...i, solutionType: SolutionTypeToText(i.solutionType)}))


        if (rest.length === 1) {
            return <VulnerabilitiesWithSolution first={rest[0]}
                                                second={{"solution": "", "solutionType": "None", "count": 0}}
                                                other={other}/>
        }
        return <VulnerabilitiesWithSolution first={rest[0]}
                                            second={rest[1]}
                                            other={other}/>

    }

    return <CenteredSpinner height={"18.125rem"}/>
}
