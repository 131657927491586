//@flow

export const SnackbarTypes = {
    Success: "success",
    Error: "error",
    Warning: "warning",
    Info: "info",
};





