// @flow

import React from "react"
import {Trans} from "react-i18next"


export class ScanConfigurationsController {
    descriptions = {
        "1": <Trans i18nKey={"scanConfigurations.analysestandard.text"}/>,
        "2": <Trans i18nKey={"scanConfigurations.analyseaggressive.text"}/>,
        "3": <Trans i18nKey={"scanConfigurations.Discovery.text"}/>,
        "4": <Trans i18nKey={"scanConfigurations.HostDiscovery.text"}/>,
        "5": <Trans i18nKey={"scanConfigurations.SystemDiscovery.text"}/>,
        "8": <Trans i18nKey={"scanConfigurations.analysestandard.text"}/>,
        "_default": <Trans i18nKey={"scanconfiguration.description.no"}/>
    }

    getDescriptionById(id: string) {
        if (this.descriptions.hasOwnProperty(id)) {
            return this.descriptions[id];
        }
        return this.descriptions["_default"];
    }
}
