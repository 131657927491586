import React from 'react'
import {useTranslation} from "react-i18next"
import {useLocation} from "react-router"
import {ListMenu, MenuContext} from "@greenbone/cloud-component-library"
import ConfigurationIcon from "../../../../../assets/icons/configuration.svg"
import TeamIcon from "../../../../../assets/icons/team-meeting.svg"
import TaskListPen from "../../../../../assets/icons/task-list-pen.svg"
import Receipt from "../../../../../assets/icons/receipt.svg"
import {GroupSettingsUrlProvider} from "../../../../UrlProvider/GroupSettingsUrlProvider"
import SecurityIcon from "@material-ui/icons/Security"
import {JWT} from "../../../../Helper/JWT"
import {Logger} from "../../../../controller/loggingController"


const MENU_NAME = "groupSettingsMenu"


function getMenuItems(t) {
    const managedSecurityItem = {
        text: t("Managed Security"),
        to: GroupSettingsUrlProvider.security(),
        icon: SecurityIcon
    }

    try {
        const jwt = new JWT()
        if (jwt.hasUserRole()) {
            const navItems = []
            navItems.push({
                text: t("menu.userMenu.team"),
                to: GroupSettingsUrlProvider.team(),
                icon: TeamIcon
            })
            navItems.push({
                text: t("Subscription"),
                to: GroupSettingsUrlProvider.subscription(),
                icon: TaskListPen
            })

            navItems.push({
                text: t("Billing"),
                to: GroupSettingsUrlProvider.billing(),
                icon: TeamIcon
            })

            if (!jwt.isManaged()) {
                navItems.push({
                    text: t("Invoice"),
                    to: GroupSettingsUrlProvider.invoice(),
                    icon: Receipt
                })
            }

            navItems.push(managedSecurityItem)

            return navItems
        }
    } catch (e) {
        Logger.exception(e)
    }


    return [
        {
            text: t("menu.userMenu.team"),
            to: GroupSettingsUrlProvider.team(),
            icon: TeamIcon
        },
        {
            text: t("Subscription"),
            to: GroupSettingsUrlProvider.subscription(),
            icon: TaskListPen
        },
        {
            text: t("Billing"),
            to: GroupSettingsUrlProvider.billing(),
            icon: TeamIcon
        },
        {
            text: t("Invoice"),
            to: GroupSettingsUrlProvider.invoice(),
            icon: Receipt
        }
    ]

}


export function GroupSettingsMenu() {
    const {t} = useTranslation()
    const {pathname} = useLocation()

    const isActive = () => GroupSettingsUrlProvider.pathHasSameBase(pathname)

    return <MenuContext.Consumer>
        {({toggle, minified, ...rest}) => {
            const toggleMenu = toggle(MENU_NAME)
            const isMenuOpen = rest[MENU_NAME]

            return <ListMenu isActive={isActive()} isOpen={isMenuOpen} rootIcon={<ConfigurationIcon/>}
                             rootText={t("Team Settings")} minified={minified} onClickRoot={(event) => {
                toggleMenu(event, true)
            }} rootUrl={GroupSettingsUrlProvider.getBase()}
                             menuItems={getMenuItems(t)}/>
        }}
    </MenuContext.Consumer>
}
