//@flow

import {Button, withStyles} from "@material-ui/core"
import React from "react"
import ReactDOMServer from "react-dom/server"
import {withTranslation} from "react-i18next"
import {compose} from "redux"
import {BorderBox} from "../../StyledComponents/Box/Box"
import {Subheadline} from "../../StyledComponents/Font/Font"


const styles = theme => ({
    container: {
        padding: "1.56rem"
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "flex-end"
    }
})

type Props = {
    children: any;
    headline: string;
    classes: typeof styles;
    t: any;
}

type State = {
    open: boolean,
    length: number,
    content: any,
    showButton: boolean
}

class _InfoText extends React.Component<Props, State> {
    state = {
        open: false,
        length: 360,
        content: ReactDOMServer.renderToStaticMarkup(this.props.children),
        showButton: true
    }

    componentDidMount() {
        if (this.state.length > this.state.content.length) {
            this.setState({
                open: true,
                length: this.state.content.length,
                showButton: false
            })
        }
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        const domString = ReactDOMServer.renderToStaticMarkup(this.props.children)
        if (prevState.content !== domString) {
            this.setState({
                content: domString
            })
        }


    }

    render() {
        const {classes, t} = this.props

        return (
            <BorderBox padding={true}>
                {
                    this.props.headline && <Subheadline>
                        {this.props.headline}
                    </Subheadline>
                }

                <div>
                    {
                        this.state.open ? (
                                <span dangerouslySetInnerHTML={{__html: this.state.content}}></span>
                            )
                            :
                            (<span
                                dangerouslySetInnerHTML={{__html: this.state.content.substring(0, this.state.length) + "..."}}></span>)
                    }

                    <div className={classes.buttonContainer}>
                        {
                            this.state.showButton &&
                            <div>
                                {this.state.open ? (
                                    <Button onClick={(event) => {
                                        this.setState({open: false})
                                    }}>{t("common.showLess")}</Button>
                                ) : (
                                    <Button onClick={(event) => {
                                        this.setState({open: true})
                                    }}>{t("common.showMore")}</Button>
                                )}
                            </div>
                        }
                    </div>
                </div>
            </BorderBox>)
    }
}

export const InfoText = compose(withStyles(styles), withTranslation())(_InfoText)
