//@flow

import {UrlProvider} from "./UrlProvider";


export class EntityUrlProvider extends UrlProvider {
    static BASE_URL = `${UrlProvider.ROOT_PATH}/entities`;

    static getBase() {
        return EntityUrlProvider.BASE_URL;
    }

    static task() {
        return `${EntityUrlProvider.getBase()}/task/`;
    }

    static taskList() {
        return `${EntityUrlProvider.getBase()}/task/list/`;
    }

    static target() {
        return `${EntityUrlProvider.getBase()}/target/`;
    }

    static targetList() {
        return `${EntityUrlProvider.getBase()}/target/list/`;
    }

    static hosts() {
        return `${EntityUrlProvider.getBase()}/hosts/`;
    }

    static hostList() {
        return `${EntityUrlProvider.getBase()}/hosts/list/`;
    }

    static targetHostValidation(targetId: string) {
        return `${EntityUrlProvider.getBase()}/hosts/target/${targetId}/host_validation`;
    }

    static schedule() {
        return `${EntityUrlProvider.getBase()}/schedule/`;
    }

    static scheduleList() {
        return `${EntityUrlProvider.getBase()}/schedule/list/`;
    }

    static credentials() {
        return `${EntityUrlProvider.getBase()}/credentials/`;
    }

    static credentialList() {
        return `${EntityUrlProvider.getBase()}/credentials/list/`;
    }

    static gateway() {
        return `${EntityUrlProvider.getBase()}/gateway/`;
    }

    static gatewayList() {
        return `${EntityUrlProvider.getBase()}/gateway/list/`;
    }
}
