import React from 'react';
import styled from 'styled-components';
import {Headline, Subheadline} from "@greenbone/cloud-component-library";
import {ListItem, UnorderedList} from "../../../StyledComponents/List/List"
import {useTranslation} from "react-i18next";

// Styled components
const ContentBlock = styled.p`
    margin-top: 40px;
    font-size: 1rem;
    line-height: 1.5;
`;

const ImportantLabel = styled.span`
    background-color: #D32F2F;
    color: white;
    padding: 6px 12px;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: bold;
`;
const Spacer = styled.span`
  width: 10px;
  display: inline-block;
`;

export function MigrationUpdates() {
    const {t} = useTranslation();

    return (
        <div style={{maxWidth: "46.88rem"}}>
            <Headline>{t("productUpdates.headline2")}</Headline>
            <Subheadline><ImportantLabel>{t("productUpdates.important")}</ImportantLabel>
                <Spacer />
                {t("productUpdates.headline3")}
            </Subheadline>
            <ContentBlock>{t("productUpdates.content.whatDoYouNeedToDo")}</ContentBlock>
            <ContentBlock>{t("productUpdates.content.firewall")}</ContentBlock>
            <UnorderedList>
                <ListItem>{t("productUpdates.content.changeDate")} 29.05.2024</ListItem>
                <ListItem>{t("productUpdates.content.prevIp")} 195.252.156.97</ListItem>
                <ListItem>{t("productUpdates.content.newIp")} 217.72.202.36</ListItem>
            </UnorderedList>
        </div>
    );
}
