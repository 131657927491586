import React, {useContext, useEffect, useReducer, useState} from "react"
import {ReportContext} from "../ReportContext"
import {
    ClickableTableCell,
    LastClickableTableCell,
    NameCell,
    Severity,
    Table,
    TableCell
} from "../components/TableComponents"
import {ReportRestApiClient} from "../service/ReportRestApiClient"
import {Bold, FlexRow, SlideIn, Subheadline} from "@greenbone/cloud-component-library"
import {ReportHelper} from "../ReportHelper"
import MonitorIcon from "../assets/monitor.svg"
import {Col, Container, Row} from "reactstrap"
import {MiniVulnerability} from "../components/MiniVulnerability"
import {Square, SquareWithIcon} from "../components/Squares"
import {ViewSwitcher} from "../components/ViewSwitcher"
import {Cell, TableHead} from "../components/ReportSorting"
import {ReportPaging} from "../components/ReportPaging"
import {ReportSelectDownloader} from "../components/ReportSelectDownloader"
import {OperatingSystemIcon} from "../components/OperatingSystemTab"
import {HideNullable} from "../components/NullableText"
import styled from "styled-components"
import ErrorBoundary from "../../../Components/ErrorBoundary";


const ShowVulnerabilities = ({host, range}) => {
    const {getFilter, report} = useContext(ReportContext)

    const [vulnerabilities, setVulnerabilities] = useState([])

    useEffect(() => {
        async function f() {
            const apiClient = new ReportRestApiClient()
            const response = await apiClient.vulnerabilitiesByHostAndSeverity(report, host.host, range, getFilter())
            setVulnerabilities(response.data)
        }

        f()
    }, [host, range])




    return <>
        <Container style={{width: "50rem"}}>
            <Row style={{marginBottom: "1rem"}}>
                <Col>
                    <FlexRow alignItems={"center"}>
                        <Square range={range}>
                            {ReportHelper.RangeToString(range)}
                        </Square>
                        <Subheadline style={{margin: 0}}>
                            Top 20 vulnerabilities with the same severity
                        </Subheadline>
                    </FlexRow>
                </Col>
            </Row>
            <Row style={{marginBottom: "1rem"}}>
                <Col>

                    <FlexRow alignItems={"center"}>
                        <SquareWithIcon>
                            <MonitorIcon/>
                        </SquareWithIcon>
                        <Bold style={{margin: 0}}>Host {host.host}</Bold>
                    </FlexRow>
                </Col>
            </Row>

            {vulnerabilities && vulnerabilities.sort((a, b) => (a.severity < b.severity) ? 1 : -1).map((vulnerability, index) => <Row
                style={{marginBottom: "1.5rem"}} key={index}>
                <Col>
                    <MiniVulnerability range={range} index={index} vulnerability={vulnerability}/>
                </Col>
            </Row>)}

        </Container>


    </>
}

const reducer = (state, action) => {
    switch (action.type) {
        case "OPEN":
            return {open: true, range: action.range}
        default:
            break
    }

    return {open: false, range: "LOW"}
}

const Hostname = styled.div`
    font-size: 0.75rem;
    color: #545454;
`

const GridRow = ({host}) => {
    const [state, dispatch] = useReducer(reducer, {open: false, range: "LOW"})


    return <>
        <NameCell severity={host.maxSeverity}>
            <div>
                {host.host}
                <HideNullable value={host.hostname}>
                    <Hostname>{host.hostname}</Hostname>
                </HideNullable>
            </div>

        </NameCell>
        <TableCell>

            <OperatingSystemIcon os={host.operatingSystem}/>

        </TableCell>
        <TableCell>
            <Severity severity={host.maxSeverity}>
                {ReportHelper.toFixed(host.maxSeverity)}
            </Severity>
        </TableCell>
        <ClickableTableCell onClick={() => dispatch({type: "OPEN", range: "HIGH"})}>
            {host.high}
        </ClickableTableCell>
        <ClickableTableCell onClick={() => dispatch({type: "OPEN", range: "MEDIUM"})}>
            {host.medium}
        </ClickableTableCell>
        <LastClickableTableCell onClick={() => dispatch({type: "OPEN", range: "LOW"})}>
            {host.low}
        </LastClickableTableCell>

        <SlideIn open={state.open} onClose={() => dispatch({action: "CLOSE"})}>
            {state.open && <ShowVulnerabilities host={host} range={state.range}/>}
        </SlideIn>
    </>
}

function Sort(props) {
    const {sort} = useContext(ReportContext)

    return <Table templateColumns={"1fr 1fr 1fr 1fr 1fr 1fr"}>
        <TableHead>Name</TableHead>
        <Cell>OS</Cell>
        <Cell handleSort={sort("severity")}>Severity</Cell>
        <Cell handleSort={sort("high")}>High</Cell>
        <Cell handleSort={sort("medium")}>Medium</Cell>
        <Cell handleSort={sort("low")}>Low</Cell>
    </Table>
}

export function GroupedByHost(props) {

    const {groupedHosts} = useContext(ReportContext)

    return <ErrorBoundary>
        <FlexRow alignItems={"center"} justifyContent={"space-between"}>
            <ViewSwitcher/>
            <FlexRow alignItems={"center"}>
                <ReportSelectDownloader/>
            </FlexRow>
        </FlexRow>

        <hr/>
        <Sort/>
        <Table templateColumns={"1fr 1fr 1fr 1fr 1fr 1fr"}>
            {
                groupedHosts?.map((host, index) => <GridRow host={host} key={index}/>)
            }
        </Table>
        <ReportPaging/>
    </ErrorBoundary>
}
