// @flow

export class UrlProvider {
    static ROOT_PATH = "/ui/gsp";
    static BASE_URL: string;

    static getBase(): string {
        return this.BASE_URL;
    }

    /**
     * Validates that a given url starts with base url
     * @param url
     * @returns {boolean}
     */
    static pathHasSameBase(url: string): boolean {
        return url.startsWith(this.getBase());
    }
}
