// @flow

import {withStyles} from "@material-ui/core";
import React from "react";


const styles = ({
    stateLabel: {
        borderRadius: "3px",
        padding: "0.25rem",
        textAlign: "center",
        fontWeight: "bold",
        border: "solid 2px",
    },
});

type Props = {
    classes: typeof styles;
    children: any;
    color: string;
    borderColor: string;
    textColor: ?string;
}

class ColoredLabelComponent extends React.Component<Props> {
    static defaultProps = {
        textColor: "#ffffff",
    };

    render() {
        const {classes} = this.props;
        const textColor = this.props.textColor;
        return (
            <div className={classes.stateLabel}
                 style={{backgroundColor: this.props.color, color: textColor, borderColor: this.props.borderColor}}>
                {this.props.children}
            </div>
        );
    }
}

export const ColoredLabel = withStyles(styles)(ColoredLabelComponent);
