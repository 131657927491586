//@flow

import React from "react"
import ClickAbleTableCell from "../../Table/ClickableTableCell"
import SlideIn from "./index"


type Props = {
    onOpen?: () => void;
    onClose?: () => void;
    data: any;
    children: any;
    element: any;
}

type State = {
    open: boolean;
}

class ExpansionPanelRow extends React.Component<Props, State> {
    state = {
        open: false,
    };

    handleOpenSlide = (event: any) => {
        this.setState({
            open: true,
        });
        if (this.props.onOpen) {
            this.props.onOpen();
        }
    };

    handleCloseSlide = (event: any) => {
        this.setState({
            open: false,
        });
        if (this.props.onClose) {
            this.props.onClose();
        }
    };

    render() {
        return [
            <ClickAbleTableCell element={this.props.element} key={1} onClick={this.handleOpenSlide}>
                {this.props.children}
            </ClickAbleTableCell>,
            <SlideIn open={this.state.open} onClose={this.handleCloseSlide} key={2}>
                {this.props.data}
            </SlideIn>,
        ];
    }
}

export default ExpansionPanelRow;
