import {Bold, FlexRow} from "@greenbone/cloud-component-library"
import {GetSolutionTabByType, ScaleSolutionTab} from "./components/SolutionTab"
import React from "react"

import {Trans, useTranslation} from "react-i18next"
import {NewLineParagraph} from "./components/NewLineParagraph"
import {GroupSettingsUrlProvider} from "../../UrlProvider/GroupSettingsUrlProvider"
import {NavLink} from "react-router-dom"
import {DetailsBlock} from "./DetailsBlock"


function HostLimitSolution({vulnerability, small}) {
    const {t} = useTranslation()

    return <DetailsBlock small={small} title={t("scanmanagement.solution")}>
        <FlexRow alignItems={"center"}>
            <Bold style={{
                margin: "0",
                marginRight: "1rem"
            }}>{t("scanmanagement.solutionType")}</Bold>
            <ScaleSolutionTab>
                {GetSolutionTabByType(vulnerability.solutionType)}
            </ScaleSolutionTab>
        </FlexRow>
        <NewLineParagraph>
            <Trans i18nKey={"scanmanagement.HostLimitSolution.subscriptionUpgrade"}>
                Please go to <NavLink to={GroupSettingsUrlProvider.subscription()}>Subscription</NavLink> and increase
                the subscription by the number of un-scanned detected
                alive IP addresses in your target network to cover all of the alive hosts in this network.
            </Trans>
        </NewLineParagraph>
    </DetailsBlock>
}

export function VulnerabilitySolutionInfo({vulnerability, small}) {
    const {t} = useTranslation()

    if (vulnerability.oid === "0.0.0.0.0.0.0.0.0.0") {
        return <HostLimitSolution vulnerability={vulnerability} small={small}/>
    }

    return <DetailsBlock small={small} title={t("scanmanagement.solution")}>
        <FlexRow alignItems={"center"}>
            <Bold style={{
                margin: "0",
                marginRight: "1rem"
            }}>{t("scanmanagement.solutionType")}</Bold>
            <ScaleSolutionTab>
                {GetSolutionTabByType(vulnerability.solutionType)}
            </ScaleSolutionTab>

        </FlexRow>
        <NewLineParagraph>
            {vulnerability.solution}
        </NewLineParagraph>
    </DetailsBlock>
}
