const HttpMethod = {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    DELETE: "DELETE",
    PATCH: "PATCH"
};

export class FetchClient {
    keycloak;
    apiBaseUrl;
    authenticationRequired;

    constructor(keycloak, apiBaseUrl, authenticationRequired = true) {
        this.keycloak = keycloak;
        this.apiBaseUrl = apiBaseUrl;
        this.authenticationRequired = authenticationRequired;
    }

    getUnauthenticatedInstance() {
        return new FetchClient(this.keycloak, this.apiBaseUrl, false);
    }

    useDifferentHost(host) {
        return new FetchClient(this.keycloak, host, this.authenticationRequired);
    }


    getHeaders = async (additionalHeaders = {}) => {
        return this.keycloak.updateToken(10)
            .then(() => {
                return {
                    "Content-Type": "application/json",
                    ...additionalHeaders,
                    "Authorization": `bearer ${this.keycloak.token}`
                };
            })
            .catch(this.keycloak.login);
    };

    /**
     * Builds the url for a specified endpoint
     *
     * @param {string} url_endpoint url endpoint
     * @return {string}
     */
    _buildUrl(url_endpoint) {
        return `${this.apiBaseUrl}${url_endpoint}`;
    }

    async request(urlEndpoint, method, body, absolute = false) {
        let headers = {"Content-Type": "application/json"};
        if (this.authenticationRequired) {
            headers = await this.getHeaders();
        }

        if (body) {
            if (typeof body != "string") {
                body = JSON.stringify(body);
            }
        }

        if (absolute) {
            return fetch(urlEndpoint, {
                method,
                headers,
                body
            });
        }


        return fetch(this._buildUrl(urlEndpoint), {
            method,
            headers,
            body
        });
    }

    async get(urlEndpoint) {
        return this.request(urlEndpoint, HttpMethod.GET);
    }

    async getAbsolute(urlEndpoint) {
        return this.request(urlEndpoint, HttpMethod.GET, undefined, true);
    }

    async delete(urlEndpoint) {
        return this.request(urlEndpoint, HttpMethod.DELETE);
    }

    async post(urlEndpoint, body) {
        return this.request(urlEndpoint, HttpMethod.POST, body);
    }

    async put(urlEndpoint, body) {
        return this.request(urlEndpoint, HttpMethod.PUT, body);
    }

    async patch(urlEndpoint, body) {
        return this.request(urlEndpoint, HttpMethod.PATCH, body);
    }

}
