import {loadStripe} from "@stripe/stripe-js/pure"

loadStripe.setLoadParameters({advancedFraudSignals: false})


class GlobalInstanceStorage {

    keycloak = null
    fetchClient = null
    stripeInstance = null

    updateStripePublicKey(key) {
        this.stripeInstance = loadStripe(key)
    }

    getStripe(): null {
        return this.stripeInstance
    }

    updateKeycloak(keycloakInstance) {
        this.keycloak = keycloakInstance
    }

    updateFetchClient(fetchInstance) {
        this.fetchClient = fetchInstance
    }

    getKeycloak() {
        return this.keycloak
    }

    getFetchClient() {
        return this.fetchClient;
    }


}


export let globalInstanceStorage = new GlobalInstanceStorage();
