import React from "react"
import {Route, useLocation} from "react-router"
import {Link} from "react-router-dom"
import Switcher from "../../Components/Router/Components/Switcher"
import {Col, Container, Row} from "reactstrap"
import {FlexRow, Headline} from "@greenbone/cloud-component-library"
import {MemberList} from "./Components/MemberList"
import {InviteList} from "./Components/InviteList"
import {FirstButton, SecondButton} from "../../StyledComponents/Button/SwitchButton"
import {TeamProvider} from "./Components/TeamContext"
import {InfoText} from "../../Components/Basic/InfoText"
import {Trans, useTranslation} from "react-i18next"
import {GroupSettingsUrlProvider} from "../../UrlProvider/GroupSettingsUrlProvider"
import {DeleteTeam} from "./Components/DeleteTeam"
import {ConfirmDelete} from "./Components/ConfirmDelete"

function PageDescription() {

    const location = useLocation()
    const {t} = useTranslation()

    return <>
        <Row>
            <Col>
                <Headline>Team</Headline>
            </Col>
        </Row>
        <Row>
            <Col>
                <InfoText headline={""}>
                    <p>
                        <Trans i18nKey={"teamRouter.help"}>
                            With teams you are able to add more users to your account. All users are operating
                            with the same rights and use the subscription of the main account. To add more
                            users, create an invite. The user will receive an invite e-mail, can register his
                            account and join this team.
                            If you disable an account, the user can no longer log in.
                        </Trans>
                    </p>
                </InfoText>
            </Col>
        </Row>
        <Row>
            <Col>
                <FlexRow justifyContent={"start"}>
                    <Link to={GroupSettingsUrlProvider.team()}>
                        <FirstButton
                            active={location.pathname.endsWith("team")}>
                            {t("teamRouter.members")}
                        </FirstButton>
                    </Link>
                    <Link to={`${GroupSettingsUrlProvider.team()}/invites`}>
                        <SecondButton
                            active={location.pathname.endsWith("invites")}>
                            {t("teamRouter.invites")}
                        </SecondButton>
                    </Link>
                </FlexRow>
            </Col>
        </Row>
    </>
}

export function TeamRouter() {


    return (
        <TeamProvider>
            <Container fluid>
                <Switcher>
                    <Route path="/" exact={true} Component={PageDescription}/>
                    <Route path="/invites" Component={PageDescription}/>
                </Switcher>

                <Row>
                    <Col>
                        <Switcher>
                            <Route path="/" exact={true} Component={MemberList}/>
                            <Route path="/invites" Component={InviteList}/>
                            <Route path="/delete/confirm" Component={ConfirmDelete}/>
                        </Switcher>
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Switcher>
                            <Route path="/" exact={true} Component={DeleteTeam}/>
                            <Route path="/invites" Component={DeleteTeam}/>
                        </Switcher>
                    </Col>
                </Row>

            </Container>
        </TeamProvider>
    )
}


