import {JWT} from "../Helper/JWT"


export class StringLocalStorage {
    key = null

    constructor(key) {
        this.key = `${new JWT().subject()}-${key}`
    }

    load = () => {
        return localStorage.getItem(this.key)
    }

    store = (value) => {
        localStorage.setItem(this.key, value)
    }
}
