//@flow

import {Logger} from "../../../controller/loggingController";


interface ICONSTANTS {
    OPEN_DIALOG: string;
    CLOSE_DIALOG: string;
    OPEN_CONFIRM_DIALOG: string;
    CLOSE_CONFIRM_DIALOG: string;
}

export const CONSTANTS: ICONSTANTS = {
    OPEN_DIALOG: "OPEN_DIALOG",
    CLOSE_DIALOG: "CLOSE_DIALOG",
    OPEN_CONFIRM_DIALOG: "OPEN_CONFIRM_DIALOG",
    CLOSE_CONFIRM_DIALOG: "CLOSE_CONFIRM_DIALOG",
};

export const openConfirmDialog = (title: string, text: string, callback: any) => ({
    type: CONSTANTS.OPEN_DIALOG,
    title: title,
    text: text,
    callback: callback,
});

export const closeConfirmDialog = () => ({
    type: CONSTANTS.CLOSE_DIALOG,
});

export const openDialog = (title: string, text: string, callback: any) => {
    Logger.warn("DEPRECATED use openConfirmDialog instead");
    return {
        type: CONSTANTS.OPEN_DIALOG,
        title: title,
        text: text,
        callback: callback,
    };
};

export const closeDialog = () => {
    Logger.warn("DEPRECATED use closeConfirmDialog instead");
    return {
        type: CONSTANTS.CLOSE_DIALOG,
    };
};
