import React from "react"
import {ReportProvider} from "./ReportContext"
import {ReportInner} from "./ReportInner"


export function ReportIndex(props) {

    return <>
        <ReportProvider>
            <ReportInner/>
        </ReportProvider>
    </>
}
