import React from "react"
import ReactTooltip from "react-tooltip"
import styled from "styled-components"


const TooltipBody = styled.div`
  color: black;
`

type Props = {
    tooltip: any;
    children: any;
}

export function Tooltip({
                            tooltip,
                            children,
                            id,
                            type = "light", effect = "solid"
                        }) {
    return <>
        <div data-tip data-for={id} id={id}>
            {children}
        </div>
        <ReactTooltip id={id} type={type} effect={effect}>
            <TooltipBody>
                {tooltip}
            </TooltipBody>
        </ReactTooltip>
    </>;

}

export class Callout extends React.Component<Props> {

    render() {
        const {children} = this.props;
        const childrenWithProps = React.Children.map(children, child =>
            React.cloneElement(child, {...this.props, children: child.props.children})
        );
        return [
            <div key={0} data-tip data-for='GSP-START-BUTTON-DISABLED'>
                {childrenWithProps}
            </div>,
            <ReactTooltip key={1} id='GSP-START-BUTTON-DISABLED' type='warning' effect='solid'>
                <TooltipBody>
                    {this.props.tooltip}
                </TooltipBody>

            </ReactTooltip>];
    }
}
