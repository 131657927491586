import React from "react";

export const LayoutContext = React.createContext();

export class LayoutProvider extends React.Component {

    state = {
        ref: null
    };

    setRef = (ref) => {
        this.setState({ref});
    };

    shouldComponentUpdate(nextProps: Readonly<P>, nextState: Readonly<S>, nextContext: any): boolean {
        if (this.state.ref !== null) {
            return false;
        }
        return true;
    }

    render() {
        return <LayoutContext.Provider value={{
            setRef: this.setRef,
            ref: this.state.ref
        }}>
            {this.props.children}
        </LayoutContext.Provider>;
    }

}
