// @flow

import Promise from "bluebird"
import React from "react"
import {Trans, withTranslation} from "react-i18next"
import {connect} from "react-redux"
import {Col, Row} from "reactstrap"
import {bindActionCreators, compose} from "redux"
import {openAlertDialog} from "../../../Components/Dialogs/AlertDialog/actions"
import {SearchableDropdown} from "../../../Components/Input"
import {DateTimeInput} from "../../../Components/Input/Components/DateTimeInput"
import Loader from "../../../Components/Loader/Loader"
import {openErrorSnackbar, openInfoSnackbar, openSuccessSnackbar} from "../../../Components/Snackbar/actions"
import {SIODateTime} from "../../../controller/SIODateTime"
import {Value} from "../../../Helper/Validator"
import {AbortButton, Button, Input, Snackbar} from "@greenbone/cloud-component-library"
import {Headline, Subheadline} from "../../../StyledComponents/Font/Font"
import {getValidationResult} from "../../../controller/FieldValidator"
import {IsNotEmptyString, IsOptionalString, Required} from "../../../controller/FormValidators"
import {ScheduleRestApiClient} from "../../../services/apiClients/Schedule/ScheduleRestApiClient"
import {ConflictError, CreateErrors, ForbiddenError} from "../../../services/Exceptions"
import {SimpleEntityContext} from "../../../services/Context/SimpleEntityContext"
import {i18n} from "../../../../i18n"

export const IntervalType = {
    DAY: "DAY",
    WEEK: "WEEK",
    MONTH: "MONTH"
}

export const IntervalTypeReadable = {
    [IntervalType.DAY]: <Trans i18nKey={"schedule.day"}/>,
    [IntervalType.WEEK]: <Trans i18nKey={"schedule.week"}/>,
    [IntervalType.MONTH]: <Trans i18nKey={"schedule.month"}/>
}

export const IntervalTypeReadableString = {
    [IntervalType.DAY]: () => i18n.t("schedule.day"),
    [IntervalType.WEEK]: () => i18n.t("schedule.week"),
    [IntervalType.MONTH]: () => i18n.t("schedule.month")
}

export const IntervalTypeColorReadable = {
    [IntervalType.DAY]: {
        mainColor: "#3d4f72",
        borderColor: "#1b3059"
    },
    [IntervalType.WEEK]: {
        mainColor: "#5f6e8b",
        borderColor: "#3d4f72"

    },
    [IntervalType.MONTH]: {
        mainColor: "#828da4",
        borderColor: "#5f6e8b"
    }
}

export function createGSPScheduleDto(schedule: GSPSchedule): GSPScheduleDto {
    return {
        name: schedule.name,
        comment: schedule.comment,
        startDate: schedule.startDate,
        endDate: schedule.endDate ? schedule.endDate : undefined,
        intervalGap: schedule.intervalGap,
        intervalType: schedule.intervalType
    }
}

type Props = {
    openInfoSnackbar: typeof openInfoSnackbar;
    openErrorSnackbar: typeof openErrorSnackbar;
    openSuccessSnackbar: typeof openSuccessSnackbar;
    openAlertDialog: typeof openAlertDialog;
    onClose: EventCallback;
    id: ?string;
    t: any;
}

type State = {
    fields: {
        name: string;
        comment: string;
        startDate: SIODateTime,
        endDate: ?SIODateTime,
        intervalType: string,
        intervalGap: number,
    },
    fieldStatus: {
        name: ?boolean;
        comment: ?boolean;
        startDate: ?boolean,
        endDate: ?boolean,
        intervalType: ?boolean,
        intervalGap: ?boolean,
    };
    isValid: boolean;
    _loading: boolean;
    _saving: boolean;
    _exception: any
}

export class _ScheduleCreate extends React.Component<Props, State> {
    scheduleRestClient: ScheduleRestApiClient
    setStateAsync: Promise

    state: State = {
        fields: {
            name: "",
            comment: "",
            startDate: SIODateTime.local().add({minutes: 5}),
            endDate: undefined,
            intervalType: IntervalType.DAY,
            intervalGap: 1
        },
        fieldStatus: {
            name: null,
            comment: null,
            startDate: undefined,
            endDate: undefined,
            intervalGap: null,
            intervalType: null
        },
        isValid: false,
        _exception: null,
        _loading: false,
        _saving: false
    }
    FieldValidators = {
        name: new IsNotEmptyString(),
        comment: new IsOptionalString(),
        startDate: new Required(),
        endDate: new IsOptionalString(),
        intervalGap: new IsNotEmptyString(),
        intervalType: new IsNotEmptyString()
    }

    constructor(props: Props) {
        super(props)
        this.scheduleRestClient = new ScheduleRestApiClient()
        this.setStateAsync = Promise.promisify(this.setState)
    }

    componentDidMount(): void {
        if (this.props.id) {
            this.loadSchedule(this.props.id)
        }
    }

    loadSchedule(scheduleId: string) {
        this.setState({_loading: true})

        this.scheduleRestClient
            .getOne(scheduleId)
            .then((schedule: GSPScheduleEntity) => {
                let fields = {
                    ...schedule,
                    startDate: (schedule.startDate),
                    endDate: schedule.endDate ? schedule.endDate : undefined
                }

                this.setState({
                    fields,
                    _loading: false
                })
            })
            .catch(error => {
                this.setState({
                    _exception: error,
                    _loading: false
                })
            })
    }

    handleOnChangeField = (event: any) => {
        const {name, value} = event.target

        this.setState(prevState => {
            let fields = prevState.fields
            fields[name] = value
            return {fields}
        })
    }

    handleDateTimeChange = (name: string, date: ?Date) => {
        this.setState(prevState => {
            let fields = prevState.fields
            fields[name] = date
            return {fields}
        })
    }

    validateFields = async (): Promise<void> => {
        const {isValid, fieldValidity} = getValidationResult(
            this.state.fields,
            this.FieldValidators
        )

        await this.setStateAsync({fieldStatus: fieldValidity, isValid})
    }

    onFormSubmit = (event: SyntheticEvent<HTMLFormElement>) => {
        event.preventDefault()

        this.validateFields()
            .then(() => {
                this.handleFormSubmit()
            })
    }

    handleFormSubmit = () => {
        const {isValid, fields} = this.state
        const {t} = this.props
        if (!isValid) {

            return
        }

        //$FlowFixMe - Flow does not understand the custom comparer implementation
        if (fields.endDate && fields.startDate && fields.startDate > fields.endDate) {
            Snackbar.Error(t("schedule.error.endBeforeStart"))
            return false
        }

        //$FlowFixMe - Flow does not understand the custom comparer implementation
        if (fields.endDate && fields.endDate < SIODateTime.local()) {
            Snackbar.Error(t("schedule.error.enddatePast"))
            return false
        }

        this.createOrUpdateSchedule(createGSPScheduleDto(this.state.fields))
    }

    redirectToList = () => {
        this.props.onClose()
    }

    createOrUpdateSchedule = (schedule: any) => {
        const scheduleName = schedule.name
        const {t} = this.props

        this.setState({_saving: true})
        if (this.props.id) {
            this.scheduleRestClient.updateEntity({...schedule, id: this.props.id})
                .then(() => {
                    this.redirectToList()
                    this.context.updateSchedules()
                    Snackbar.Success(t("schedule.updated", {scheduleName}))
                })
                .catch(error => {
                    if (error.type === ForbiddenError) {
                        Snackbar.Error(error.message)
                    } else if (error.type === ConflictError) {
                        Snackbar.Error(error?.responseBody?.message)
                    } else if (Value(error.type).isInList(CreateErrors)) {
                        this.props.openAlertDialog(t("common.messages.error"), error.message)
                        this.setState({_saving: false})
                    } else {
                        this.setState({_exception: error})
                    }
                })
        } else {
            this.scheduleRestClient.createEntity(schedule)
                .then(() => {
                    this.redirectToList()
                    this.context.updateSchedules()
                    Snackbar.Success(t("common.action.created", {scheduleName}))
                })
                .catch(error => {
                    if (error.type === ForbiddenError) {
                        Snackbar.Error(error.message)
                    } else if (error.type === ConflictError) {
                        Snackbar.Error(error?.responseBody?.message)
                    } else if (Value(error.type).isInList(CreateErrors)) {
                        this.props.openAlertDialog(t("common.error.danger"), error.message)
                        this.setState({_saving: false})
                    } else {
                        this.setState({_exception: error})
                    }
                })
        }

    }

    render() {
        const {fields, fieldStatus, _loading} = this.state
        const {t} = this.props

        if (_loading) {
            return <Loader/>
        }

        return <React.Fragment>
            <Headline>{this.props.id ? t("schedule.edit.headline") : t("schedule.create.headline")}</Headline>

            <Row style={{marginBottom: "2rem"}}>
                <Col>
                    <Input onChange={this.handleOnChangeField} name={"name"} label={t("schedule.label.name")}
                           value={fields.name || ""}
                           isValid={fieldStatus.name}/>
                </Col>
            </Row>

            <Row style={{marginBottom: "5rem"}}>
                <Col>
                    <Input onChange={this.handleOnChangeField} name={"comment"} label={t("common.description.optional")}
                           value={fields.comment || ""}
                           isValid={fieldStatus.comment}/>
                </Col>
            </Row>

            <Row style={{marginBottom: "5rem"}}>
                <Col>
                    <Subheadline>{t("schedule.label.startDate")}</Subheadline>
                    <DateTimeInput name={"startDate"}
                                   date={fields.startDate}
                                   handleDateTimeChange={this.handleDateTimeChange}
                                   isValid={fieldStatus.startDate}/>
                </Col>
                <Col>
                    <Subheadline>{t("schedule.label.endDate")}</Subheadline>
                    <DateTimeInput name={"endDate"}
                                   date={fields.endDate}
                                   handleDateTimeChange={this.handleDateTimeChange}
                                   isValid={fieldStatus.endDate}/>
                </Col>
            </Row>

            <Row><Col><Subheadline>{t("schedule.label.interval.headline")}</Subheadline></Col></Row>

            <Row style={{marginBottom: "4rem", alignItems: "flex-end"}}>
                <Col xs={6}>
                    <SearchableDropdown
                        onChange={this.handleOnChangeField}
                        name={"intervalType"}
                        value={fields.intervalType || ""}
                        options={{
                            data: Object.keys(IntervalType).map((type, i) => {
                                return {
                                    id: type,
                                    name: IntervalTypeReadable[IntervalType[type]]
                                }
                            }), valueFieldName: "id", labelFieldName: "name"
                        }}
                        label={t("schedule.label.interval")}
                        isValid={fieldStatus.intervalType}/>
                </Col>
                <Col xs={3}>
                    <Input style={{width: "100%"}} type={"number"} onChange={this.handleOnChangeField}
                           name={"intervalGap"}
                           label={t("schedule.label.intervalGap")}
                           value={fields.intervalGap || ""}
                           isValid={fieldStatus.intervalGap}
                           inputProps={{min: "0", max: "999", step: "1"}}
                    />
                </Col>
            </Row>

            <Row>
                <Col>
                    <div style={{justifyContent: "space-between", display: "flex"}}>
                        <AbortButton onClick={this.props.onClose}>{t("common.action.abort")}</AbortButton>
                        <Button
                            onClick={this.onFormSubmit}>{this.props.id ? t("scheduleForm.update") : t("scheduleForm.create")}</Button>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    }
}

function mapDispatchToProps(dispatch) {
    let actions = bindActionCreators({
        openAlertDialog: openAlertDialog,
        openSuccessSnackbar: openSuccessSnackbar,
        openErrorSnackbar: openErrorSnackbar,
        openInfoSnackbar: openInfoSnackbar
    }, dispatch)
    return {...actions, dispatch}
}

function mapStateToProps(state) {
    return {}
}

//$FlowFixMe
_ScheduleCreate.contextType = SimpleEntityContext

export const ScheduleForm = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(_ScheduleCreate)
