import React from "react"
import styled, {css} from "styled-components"
import {SearchController} from "../Helper/SearchController"
import {TablePagination} from "./TablePagination"
import {SortableTableHead} from "./SortableTableHead"


export const TableCell = styled.td`
  height: 3.0625rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-bottom: 1px solid #dddddd;
  
  ${props => props.numeric && css`text-align: right;`}
`

export const TableHead = styled.th`
  text-align: left;
  height: 3.3125rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-bottom: 1px solid #dddddd;
  
  ${props => props.clickable && css`
    cursor: pointer;
  `}
  ${props => props.numeric && css`text-align: right;`}
  
`;


export const TableRow = styled.tr`

`;


export const TableBody = styled.tbody`
  ${TableRow}:nth-child(even) {
    background-color: #FBFBFB;
  }
`;


const RawTable = styled.table`
    border-radius: 3px;
    border-collapse: collapse;
    font-family: 'DroidSans', Arial, sans-serif;
    box-sizing: border-box;
    width: 100%;
    font-size: 1rem;
`;

const Around = styled.div`
  /* border: 1px solid #dddddd;*/
  border-radius: 3px;
`;


export class Table extends React.Component {
    timer;

    constructor(props) {
        super(props);

        if (!isNaN(props.defaultPageSize)) {
            this.state = {
                rowsPerPage: this.props.defaultPageSize,
                page: 0,
                orderBy: this.props.orderBy || "",
                order: this.props.order || "asc",
                elements: this.props.elements,
                filterText: ""
            };
        } else {
            this.state = {
                rowsPerPage: 10,
                page: 0,
                orderBy: this.props.orderBy || "",
                order: this.props.order || "asc",
                elements: this.props.elements,
                filterText: ""
            };
        }


    }


    handleSearchInputChange = (event) => {
        const text = event.target.value;

        clearTimeout(this.timer);

        this.timer = setTimeout(() => {
            this.setState({filterText: text});
        }, 250);

    };

    handleRequestSort = (event, column) => {


        const orderBy = column.id;
        let order = "desc";

        if (this.state.orderBy === column.id && this.state.order === "desc") {
            order = "asc";
        }


        this.setState({
            order: order,
            orderBy: orderBy
        });
    };

    handleChangePage = (event, page) => {
        const maxPage = this.calculateMaxPageCount();

        if (page >= 0 && page <= maxPage) {
            this.setState({page});
        }

    };

    handleChangeRowsPerPage = event => {
        const {page} = this.state;

        const newPageSize = event.target.value;

        const maxPage = this.calculateMaxPageCount();
        const newMaxPage = this.calculateMaxPageCount(newPageSize);


        if (newMaxPage < maxPage && page === maxPage) {
            this.setState({page: newMaxPage});
        }

        this.setState({rowsPerPage: newPageSize});
    };

    calculateMaxPageCount = (overridePageSize) => {
        const {rowsPerPage, filterText, orderBy, order} = this.state;
        const {data, searchableFields} = this.props;

        const elements = new SearchController(data)
            .search(filterText, searchableFields || [])
            .orderBy(orderBy, order);

        if (overridePageSize) {
            return Math.trunc(elements.results.length / overridePageSize);
        }
        return Math.trunc(elements.results.length / rowsPerPage);
    };

    render() {
        const {data, generator, columns, searchableFields, actions, headline} = this.props
        const {order, orderBy, filterText, rowsPerPage, page} = this.state


        const elements = new SearchController(data)
            .search(filterText, searchableFields || [])
            .orderBy(orderBy, order)

        const pagedElements = elements.paging(page + 1, rowsPerPage).results


        let emptyRowCount = 0

        if (rowsPerPage > pagedElements.length)
            emptyRowCount = 0


        if (rowsPerPage > pagedElements.length && page > 0) {
            emptyRowCount = rowsPerPage - pagedElements.length
        }


        return (
            <Around>
                <RawTable>
                    <SortableTableHead headline={headline}
                                       showSearchField={searchableFields && searchableFields.length > 0}
                                       handleSearchInputChange={this.handleSearchInputChange} order={order}
                                       orderBy={orderBy} columns={columns}
                                       onRequestSort={this.handleRequestSort} actions={actions}/>
                    <TableBody>
                        {pagedElements.map((row, index) => generator(row, index))}
                        {emptyRowCount > 0 && (
                            <TableRow>
                                <TableCell style={{height: `calc(3.0625rem * ${emptyRowCount})`}}
                                           colSpan={columns.length}/>
                            </TableRow>
                        )}
                    </TableBody>
                    <tfoot>
                    <TableRow>
                        <TableCell colSpan={columns.length}>
                            <TablePagination defaultPageSize={this.props.defaultPageSize}
                                             handleChangePage={this.handleChangePage}
                                             handleChangeRowPerPage={this.handleChangeRowsPerPage}
                                             count={elements.results.length} rowsPerPage={rowsPerPage} page={page}/>
                        </TableCell>
                    </TableRow>
                    </tfoot>
                </RawTable>
            </Around>
        );
    }


}
