import {Col, Row} from "reactstrap"
import {Spinner, Subheadline, Text} from "@greenbone/cloud-component-library"
import {ChipInput} from "../../../StyledComponents/Form/ChipInput"
import React from "react"
import {useTranslation} from "react-i18next"


export function IncludedAddressInputArea({
                                             isInternal,
                                             resolvingDomain,
                                             handleIncludedHostsAdd,
                                             handleIncludedHostRemove,
                                             inputRef,
                                             includedHosts,
                                             isValid
                                         }) {
    const {t} = useTranslation()

    return <Row style={{marginBottom: "4rem"}}>
        <Col>
            <Subheadline style={{marginBottom: 0}}>
                {t("common.targetCreate.includedHosts")}
            </Subheadline>

            {isInternal ? <Text>{t("common.targetCreate.hostFormat.intern")}</Text>
                : <Text>{t("common.targetCreate.hostFormat")}</Text>}
            {resolvingDomain && <><br/>{t("targetForm.resolving")} <Spinner/></>}

            <ChipInput name={"host"}
                       style={{width: "100%"}}
                       label={t("common.targetCreate.addIncludedHosts")}
                       onBlur={(event) => {
                           handleIncludedHostsAdd(event.target.value)
                       }}
                       onAdd={handleIncludedHostsAdd}
                       helperText={t("common.targetCreate.separation")}
                       value={includedHosts}
                       newChipKeyCodes={[32, 188, 113, 9, 13]}
                       onDelete={handleIncludedHostRemove}
                       inputRef={inputRef}
                       isValid={isValid}
            />
        </Col>
    </Row>
}
