import styled, {css, ThemeContext} from "styled-components";
import React, {useContext} from "react";
import {NavLink} from "react-router-dom";

import {IconSurrounder} from "./Shared";
import {IconButton} from "@material-ui/core";
import ArrowUp from "../../../assets/icons/ArrowUp";
import ArrowDown from "../../../assets/icons/ArrowDown";
import {MenuContext} from "./MenuContext";


export const RootLinkStyledComponent = styled((
        {
            onClick,
            linkStyle,
            className,
            children,
            to,
            open
        }
    ) =>
        <NavLink
            className={className}
            to={to}
            onClick={onClick}
            style={linkStyle}
            open={open}
        >
            {children}
        </NavLink>
)`
  display: flex;
  justify-content: space-between;
  padding: 0.63rem;
  padding-left: 1.25rem;
  align-items: center;
  height: 4rem;
  box-sizing: border-box;
  color: ${props => props.theme.sidebar.font};

  &:hover {
    text-decoration: none;
    color: ${props => props.theme.sidebar.hoverFont};
    background-color: ${props => props.theme.sidebar.hoverBackground};
    ${props => props.open && css`box-shadow: inset 4px 0 0 ${props => props.theme.sidebar.insetShadow};`}
  }

  ${props => props.minified && css`
    padding: 0;
    justify-content: center;

    img {
      margin: 0;
    }
  `}
`;

export const IconForButton = styled.div`
  height: 1.06rem;
  width: 1.06rem;
  display: flex;

  svg {
    height: 1.06rem;
    width: 1.06rem;
  }
`;

export const InnerLink = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-left: 0.5rem;
  }
`;


export function ListMenuRootItem({extended, ...props}) {
    //TODO - jwerremeyer move to styled-components api as soon as it ships --> https://github.com/styled-components/styled-components/pull/2765
    const {activeMenuitem, insetShadow} = useContext(ThemeContext).sidebar;
    const {minified} = useContext(MenuContext);
    const IconComponent = props.icon;

    return (
        <RootLinkStyledComponent
            onClick={props.onClick}
            minified={minified}
            linkStyle={({isActive}) => {
                if (isActive) {
                    return {background: activeMenuitem, boxShadow: `inset 4px 0 0 ${insetShadow}`}
                }

                return {}
            }}
            {...props}
        >
            <InnerLink>
                <IconSurrounder>{IconComponent}</IconSurrounder>
                {(minified === false) && <span>{props.text}</span>}
            </InnerLink>

            {(minified === false) && <IconButton>
                {extended ? <IconForButton tabIndex={"-1"}><ArrowUp/></IconForButton> :
                    <IconForButton tabIndex={"-1"}><ArrowDown/></IconForButton>}
            </IconButton>}
        </RootLinkStyledComponent>
    );
}
