//@flow

export const ReportStatus: GSPReportStatus = {
    REQUESTED: "REQUESTED",
    RUNNING: "RUNNING",
    DONE: "DONE",
    STOP_REQUESTED: "STOP_REQUESTED",
    STOPPED: "STOPPED",
    DELETED: "DELETED",
    INTERNAL_ERROR: "INTERNAL_ERROR",
    INTERRUPTED: "INTERRUPTED"
};
