import * as HttpStatuscode from "http-status-codes"
import {Value} from "../../../Helper/Validator"
import i18next from "i18next"
import {
    BadRequestError,
    ConflictError,
    ForbiddenError,
    InvalidStatusCodeError,
    NotAcceptableError,
    NotFoundError,
    PayloadTooLargeError,
    UnprocessableEntityError
} from "../../Exceptions"
import {ApiClient} from "./ApiClient"
import {globalInstanceStorage} from "../../../../GlobalSingle"


class EntityRestApiClient extends ApiClient {
    urlProvider
    httpClient

    constructor(urlProvider, httpClient = globalInstanceStorage.getFetchClient()) {
        super()
        this.urlProvider = urlProvider
        this.httpClient = httpClient
    }

    /**
     * Prepare an Object for rest transport and JSON stringify
     *
     * - convert all `Date` to `string` with format `YYYY-MM-DDTHH:mmZZ`
     *
     * @param value any json object
     * @returns {*}
     */
    static prepareObject(value) {
        Object.keys(value)
            .forEach(item => {
                if (value[item] && value[item].isSIODateTime) {
                    value[item] = value[item].toIsoString()
                }
            })
        return value
    }

    async getAll() {
        const response = await this.httpClient.get(this.urlProvider.getAll())

        if (response.status === HttpStatuscode.OK) {
            return this.parseJsonText(await response.text())
        } else if (response.status === HttpStatuscode.FORBIDDEN) {
            const responseBody = await this.getJsonResponse(response)
            throw new ForbiddenError(i18next.t("components.error.boundary.noPermission"), responseBody?.message)
        }
        throw new InvalidStatusCodeError(response.status)
    }

    async getOne(id) {
        const response = await this
            .httpClient
            .get(this.urlProvider.getOne(id))

        if (response.status === HttpStatuscode.OK) {
            return this.parseJsonText(await response.text())
        } else if (response.status === HttpStatuscode.NOT_FOUND) {
            throw new NotFoundError(i18next.t("common.client.entityNotFound"))
        } else if (response.status === HttpStatuscode.FORBIDDEN) {
            const responseBody = await this.getJsonResponse(response)
            throw new ForbiddenError(i18next.t("components.error.boundary.noPermission"), responseBody?.message)
        }
        throw new InvalidStatusCodeError(response.status)
    }

    async getJsonResponse(response) {
        try {
            const rawJson = await response.text()
            return this.parseJsonText(rawJson)
        } catch (e) {
            console.error(e)
        }
        return null
    }

    async createEntity(entity) {
        const response = await this
            .httpClient
            .post(this.urlProvider.create(), EntityRestApiClient.prepareObject(entity))

        if (Value(response.status).isInList([HttpStatuscode.CREATED, HttpStatuscode.OK])) {
            return
        } else if (response.status === HttpStatuscode.CONFLICT) {
            const responseBody = await this.getJsonResponse(response)
            throw new ConflictError(i18next.t("common.client.createFailed.invalidData"), responseBody)
        } else if (response.status === HttpStatuscode.BAD_REQUEST) {
            const responseBody = await this.getJsonResponse(response)
            throw new BadRequestError(i18next.t("common.client.createFailed.invalidRequest"), responseBody?.fieldErrors)
        } else if (response.status === HttpStatuscode.FORBIDDEN) {
            const responseBody = await this.getJsonResponse(response)
            throw new ForbiddenError(i18next.t("components.error.boundary.noPermission"), responseBody?.message)
        } else if (response.status === HttpStatuscode.NOT_ACCEPTABLE) {
            throw new NotAcceptableError(i18next.t("common.client.createFailed.notAcceptable"))
        } else if (response.status === HttpStatuscode.UNPROCESSABLE_ENTITY) {
            throw new UnprocessableEntityError(i18next.t("common.client.createFailed.unprocessableEntity"))
        } else if (response.status === HttpStatuscode.REQUEST_TOO_LONG) {
            throw new PayloadTooLargeError(i18next.t("common.client.createFailed.requestToLong"))
        }
        throw new InvalidStatusCodeError(response.status)
    }

    async updateEntity(entity) {

        const response = await this
            .httpClient
            .put(this.urlProvider.update(entity.id), EntityRestApiClient.prepareObject(entity))

        if (Value(response.status).isInList([HttpStatuscode.NO_CONTENT, HttpStatuscode.OK])) {
            return
        } else if (response.status === HttpStatuscode.NOT_FOUND) {
            throw new NotFoundError(i18next.t("common.client.updateFailed.notFound"))
        } else if (response.status === HttpStatuscode.CONFLICT) {
            const responseBody = await this.getJsonResponse(response)
            throw new ConflictError(i18next.t("common.client.updateFailed.invalidData"), responseBody)
        } else if (response.status === HttpStatuscode.BAD_REQUEST) {
            const responseBody = await this.getJsonResponse(response)
            throw new BadRequestError(i18next.t("common.client.updateFailed.invalidRequest"), responseBody?.fieldErrors)
        } else if (response.status === HttpStatuscode.FORBIDDEN) {
            const responseBody = await this.getJsonResponse(response)
            throw new ForbiddenError(i18next.t("components.error.boundary.noPermission"), responseBody?.message)
        } else if (response.status === HttpStatuscode.NOT_ACCEPTABLE) {
            throw new NotAcceptableError(i18next.t("common.client.updateFailed.notAcceptable"))
        } else if (response.status === HttpStatuscode.REQUEST_TOO_LONG) {
            throw new PayloadTooLargeError(i18next.t("common.client.updateFailed.requestToLong"))
        }
        throw new InvalidStatusCodeError(response.status)
    }

    async deleteEntity(entityId) {

        const response = await this
            .httpClient
            .delete(this.urlProvider.delete(entityId))

        if (Value(response.status).isInList([HttpStatuscode.NO_CONTENT, HttpStatuscode.OK])) {
            return
        } else if (response.status === HttpStatuscode.NOT_FOUND) {
            throw new NotFoundError(i18next.t("common.client.deleteFailed.notFound"))
        } else if (response.status === HttpStatuscode.CONFLICT) {
            const responseBody = await this.getJsonResponse(response)
            throw new ConflictError(i18next.t("common.client.deleteFailed.invalidData"), responseBody)
        } else if (response.status === HttpStatuscode.BAD_REQUEST) {
            const responseBody = await this.getJsonResponse(response)
            throw new BadRequestError(i18next.t("common.client.deleteFailed.invalidRequest"), responseBody?.fieldErrors)
        } else if (response.status === HttpStatuscode.FORBIDDEN) {
            const responseBody = await this.getJsonResponse(response)
            throw new ForbiddenError(i18next.t("components.error.boundary.noPermission"), responseBody?.message)
        }
        throw new InvalidStatusCodeError(response.status)
    }
}

export {EntityRestApiClient}
