//@flow

import {Cookie} from "../../../Helper/Cookie";


export class WelcomeScreenController {
    cookieName = "WIZARD_WELCOME_SCREEN_DO_NOT_SHOW_AGAIN";

    setDoNotShowAgain = (isAccepted: boolean) => {
        Cookie.set(this.cookieName, true.toString(), 300);
    };

    getDoNotShowAgain = (): boolean => {
        return Cookie.get(this.cookieName) !== null;
    };

    clearDoNotShowAgain = () => {
        Cookie.erase(this.cookieName);
    };
}
