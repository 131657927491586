import React, {useContext, useEffect, useState} from "react"
import {Col, Row} from "reactstrap"
import {AbortButton, Button, FlexRow, GhostButton, Headline, Radio, Snackbar} from "@greenbone/cloud-component-library"
import {CreditCard} from "./components/CreditCard"
import {PayPal} from "./components/PayPal"
import {Invoice} from "./components/Invoice"
import {PaymentOptions, SubscriptionContext, SubscriptionStep} from "../SubscriptionProvider"
import {SlideNavigation} from "../components/SlideNavigation"
import {useNavigate, useLocation} from "react-router"
import queryString from "query-string"
import {SubscriptionNavigationUrlProvider} from "../SubscriptionNavigationUrlProvider"
import {SubscriptionRestApiClient} from "../service/SubscriptionRestApiClient"
import {GreyBoxHead, Icon} from "./components/GreyBox"
import Visa from "./components/assets/LoVisa.svg?url"
import MasterCard from "./components/assets/mc_symbol.svg?url"
import Amex from "./components/assets/American_Express.svg?url"
import {useTranslation} from "react-i18next"
import {ButtonSpinner} from "../../../Components/Animations/Button/ButtonSpinner"
import {PricingTable} from "./components/PricingTable"
import {CreditCardInfo} from "./components/CreditCardInfo"

let payFormRef = null

export function PaymentSlide() {

    const {t} = useTranslation()
    const {
        paymentOptions,
        setStep,
        update,
        paymentOption,
        creditCardSet,
        cardInformation
    } = useContext(SubscriptionContext)


    const [sending, setSending] = useState(false)

    const [option, setOption] = useState(null)
    const [cardHolder, setCardHolder] = useState(null)
    const [paymentSetFromBackend, setPaymentSetFromBackend] = useState(false)
    const [changeCard, setChangeCard] = useState(false)
    const [amounts, setAmounts] = useState({intern: 0, extern: 0})

    const navigate = useNavigate();
    const location = useLocation();

    const next = (isNewCard = false) => {
        const queryParams = queryString.parse(location.search)

        const params = new URLSearchParams({
            intern: queryParams?.intern,
            extern: queryParams?.extern,
        });

        if (isNewCard) {
            params.set("newCard", "true");
        }

        navigate({
            pathname: SubscriptionNavigationUrlProvider.confirm(),
            search: `?${params.toString()}`
        })
    }

    useEffect(() => {
        const queryParams = queryString.parse(location.search)
        setAmounts({
            intern: queryParams?.intern,
            extern: queryParams?.extern
        })
    }, [])


    const handleBack = event => {
        const queryParams = queryString.parse(location.search)

        navigate({
            pathname: SubscriptionNavigationUrlProvider.address(),
            search: `?${new URLSearchParams({
                intern: queryParams?.intern,
                extern: queryParams?.extern
            }).toString()}`
        })
    }

    function handleBadRequest(response) {
        if (response?.fieldErrors?.card_token) {
            const cardTokenErrors = response?.fieldErrors?.card_token
            if (cardTokenErrors && cardTokenErrors[0] === "card declined") {
                setSending(false)
                Snackbar.Warning(t("paymentInfo.creditcard.declined"))
                return
            }
        }
        setSending(false)
        Snackbar.Warning(t("payment.selectedOptionNotAvailable"))

    }

    const handleSubmit = (event) => {
        if (option) {
            setSending(true)
            const apiClient = new SubscriptionRestApiClient()


            if (option === PaymentOptions.CREDIT_CARD) {
                payFormRef.handleSubmit(cardHolder)
                    .then(response => {
                        const tokenId = response?.token?.id;
                        if (tokenId) {
                            if (changeCard) {
                                apiClient.setBillingMethodToCreditCard(tokenId)
                                    .then(response => {
                                        if (response?.status === "BAD_REQUEST") {
                                            handleBadRequest(response)
                                        } else {
                                            setSending(false)
                                            update()
                                            next(true)
                                        }

                                    })
                                    .catch(() => {
                                        setSending(false)
                                        Snackbar.Warning(t("payment.selectedOptionNotAvailable"))
                                    })
                                return
                            } else {
                                apiClient.setBillingMethodToCreditCard(tokenId)
                                    .then(response => {
                                        if (response?.status === "BAD_REQUEST") {
                                            handleBadRequest(response)
                                        } else {
                                            setSending(false)
                                            update()
                                            next()
                                        }

                                    })
                                    .catch(() => {
                                        setSending(false)
                                        Snackbar.Warning(t("payment.selectedOptionNotAvailable"))
                                    })
                            }

                        } else if (paymentSetFromBackend && changeCard === false) {
                            apiClient.setBillingMethodToCreditCard()
                                .then(response => {
                                    if (response?.status === "BAD_REQUEST") {
                                        handleBadRequest(response)
                                    } else {
                                        setSending(false)
                                        update()
                                        next()
                                    }

                                })
                                .catch(() => {
                                    setSending(false)
                                    Snackbar.Warning(t("payment.selectedOptionNotAvailable"))
                                })
                        } else {
                            setSending(false)
                            Snackbar.Error(t("payment.checkCreditCardData"))
                        }

                    })
                    .catch(e => {
                        setSending(false)
                        Snackbar.Error(t("payment.checkCreditCardData"))
                    })
            } else if (option === PaymentOptions.INVOICE) {
                apiClient.setBillingMethodToInvoice()
                    .then(response => {
                        update()
                        next()
                        setSending(false)
                    })
                    .catch(e => {
                        setSending(false)
                        Snackbar.Error("Please check your credit card data")
                    })

            } else {
                setSending(false)
                Snackbar.Warning("The selected payment option is currently not available")
            }

        } else {
            setSending(false)
            Snackbar.Error("Please select a payment method")
        }
    }

    useEffect(() => {
        setStep(SubscriptionStep.Payment)
    }, [setStep])

    useEffect(() => {
        setOption(paymentOption)
        if (paymentOption === PaymentOptions.CREDIT_CARD || creditCardSet === true) {
            setPaymentSetFromBackend(true)
        }
    }, [paymentOption, creditCardSet])

    return <>
        <Row>
            <Col>
                <Headline>{t("paymentInfo.paymentOption")}</Headline>
            </Col>
        </Row>
        <Row>
            <Col>
                <SlideNavigation/>
            </Col>
        </Row>
        <Row>


            <Col xs={7}>
                <Row>
                    <Col xs={8}>
                        {paymentOptions.indexOf(PaymentOptions.CREDIT_CARD) > -1 &&
                        <Row style={{marginBottom: "2.25rem"}}>
                            <Col>
                                <Row>
                                    <Col>
                                        <GreyBoxHead title={"Credit Card"}
                                                     selected={option === PaymentOptions.CREDIT_CARD}
                                                     onSelect={() => setOption(PaymentOptions.CREDIT_CARD)}
                                                     text={"Safe money transfer by credit card."}>

                                            <FlexRow alignItems={"center"}>
                                                <Icon as={"img"} src={Visa}>
                                                </Icon>
                                                <Icon as={"img"} src={MasterCard}>
                                                </Icon>
                                                <Icon as={"img"} src={Amex}>
                                                </Icon>
                                            </FlexRow>
                                        </GreyBoxHead>
                                        {(changeCard === false && paymentSetFromBackend === true) &&
                                        <FlexRow>
                                            <Radio style={{visibility: "hidden"}}/>
                                            <Button onClick={() => setChangeCard(true)}>
                                                Change Card
                                            </Button>
                                        </FlexRow>}

                                    </Col>
                                    {(changeCard === false && paymentSetFromBackend) &&
                                    <CreditCardInfo cardInfo={cardInformation}/>
                                    }
                                </Row>
                                <Row>
                                    <Col>
                                        <CreditCard
                                            style={{display: ((option === PaymentOptions.CREDIT_CARD && !paymentSetFromBackend) || changeCard === true) ? "block" : "none"}}
                                            payFormRef={(ref) => {
                                                payFormRef = ref
                                            }} setCardHolder={(event) => {
                                            const value = event.target.value
                                            setCardHolder(value)
                                        }} selected={option === PaymentOptions.CREDIT_CARD}
                                            onSelect={() => setOption(PaymentOptions.CREDIT_CARD)}/>
                                    </Col>
                                </Row>
                                {changeCard && <Row style={{marginTop: "1.5rem"}}>
                                    <Col>
                                        <FlexRow>
                                            <Radio style={{visibility: "hidden"}}/>
                                            <AbortButton onClick={() => setChangeCard(false)}>Abort</AbortButton>
                                            <Button onClick={handleSubmit}>Save and Continue {sending &&
                                            <ButtonSpinner/>}</Button>
                                        </FlexRow>
                                    </Col>
                                </Row>}


                            </Col>
                        </Row>
                        }

                        {paymentOptions.indexOf(PaymentOptions.PAYPAL) > -1 &&
                        <Row style={{marginBottom: "2.25rem"}}>
                            <Col>
                                <PayPal selected={option === PaymentOptions.PAYPAL}
                                        onSelect={() => setOption(PaymentOptions.PAYPAL)}/>
                            </Col>
                        </Row>
                        }

                        {paymentOptions.indexOf(PaymentOptions.INVOICE) > -1 &&
                        <Row style={{marginBottom: "2.25rem"}}>
                            <Col>
                                <Invoice selected={option === PaymentOptions.INVOICE}
                                         onSelect={() => setOption(PaymentOptions.INVOICE)}/>
                            </Col>
                        </Row>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <PricingTable intern={amounts.intern} extern={amounts.extern}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FlexRow justifyContent={"flex-end"}>
                            <GhostButton onClick={handleBack}>
                                {t("paymentSlide.back")}
                            </GhostButton>

                            {changeCard === false && <Button style={{marginLeft: "1rem"}}
                                                             onClick={handleSubmit}>{t("paymentSlide.continue")} {sending &&
                            <ButtonSpinner/>}
                            </Button>}

                        </FlexRow>
                    </Col>
                </Row>
            </Col>


        </Row>
    </>
}
