import React from "react";

export const TokenContext = React.createContext({});

export class TokenProvider extends React.Component {

    state = {
        token: null,
        isAuthenticated: false,
        loading: true
    };

    setToken = (token) => {
        this.setState({token: token});
    };

    setAuthenticated(authenticated) {
        this.setState({authenticated: authenticated});
    }

    render() {
        return (
            <TokenContext.Provider value={{
                ...this.state,
                setToken: this.setToken.bind(this),
                setAuthenticated: this.setAuthenticated
            }}>
                {this.props.children}
            </TokenContext.Provider>
        );
    }
}
