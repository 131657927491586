// @flow

import * as React from "react"
import styled, {css} from "styled-components"
import {Colors} from "../../../Theme"


const Box = styled.div`
  border: solid 2px ${Colors.grey.lighter};
  ${props => props.active && css`
    border: solid 2px ${props => props.theme.button.normal.background};
  `};
  ${props => (!props.active && props.onClick) && css`
    &:hover {
      border: solid 2px ${Colors.grey.light};
    }
  `};
  ${props => props.onClick && css`
    cursor: pointer;
  `};
  border-radius: 3px;
  color: ${Colors.grey.light};
  padding: 0.94rem;
  height: 100%;
  width: 100%;
`;

const BoxElements = styled.div`
  font-size: 1.00rem;
  border-radius: 2px;
`;

const Headline = styled.h1`
  font-size: 1.19rem;
  color: ${props => props.theme.button.normal.background};
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: none;
  white-space: nowrap;

    ${props => props.active && css`
    font-weight: bold;
  `};
`;

type Props = {
    title: string;
    children: ?any;
    active?: boolean;
    onClick?: any;
    style?: any;
}

export class TabBox extends React.Component<Props> {
    static defaultProps = {
        active: false,
    };

    render() {
        const {children, title, active, onClick, style} = this.props;

        return <Box style={style} active={active} onClick={onClick}>
            <Headline active={active}>{title}</Headline>
            <BoxElements>
                {children}
            </BoxElements>
        </Box>;
    }
}
