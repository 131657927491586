//@flow

import {withStyles, withTheme} from "@material-ui/core"
import React from "react"
import {withTranslation} from "react-i18next"
import {connect} from "react-redux"
import {bindActionCreators, compose} from "redux"
import {openAlertDialog} from "../../../../Components/Dialogs/AlertDialog/actions"
import {ChartistGraphDataController} from "../../../../Components/LineGraph/ChartistGraphDataController"
import {LineGraph} from "../../../../Components/LineGraph/index"
import {SmallReportDataController} from "../../../../Components/LineGraph/SmallReportDataController"
import Loader from "../../../../Components/Loader/Loader"
import {openErrorSnackbar} from "../../../../Components/Snackbar/actions"
import {SIODateTime} from "../../../../controller/SIODateTime"
import {Value} from "../../../../Helper/Validator"
import {ReportRestApiClient} from "../../../../services/apiClients/Report/ReportRestApiClient"
import {ForbiddenError, LoadErrors} from "../../../../services/Exceptions"
import {AlertContext} from "../../../../Context/AlertContext"
import {AlertBarType} from "../../../../Layout/components/InformationBarTypes"
import {Snackbar} from "@greenbone/cloud-component-library"

const styles = theme => ({
    lineGraph: {
        height: "100%",
        fontFamily: ["Netto", "sans-serif"]
    }
});

type Props = {
    +openAlertDialog: typeof openAlertDialog;
    +openErrorSnackbar: typeof openErrorSnackbar;
    classes: typeof styles;
    t: any;
}

type State = {
    smallReports: Array<GraphSmallReport>,
    _loading: boolean,
    _exception: ?boolean,
};

class _SeverityGraph extends React.Component<Props, State> {
    reportRestApiClient: ReportRestApiClient;

    state = {
        smallReports: [],
        _loading: true,
        _exception: null
    };

    constructor(props) {
        super(props);

        this.reportRestApiClient = new ReportRestApiClient();
    }

    componentDidMount() {
        this.loadAllSmallReports();
    }

    loadAllSmallReports() {
        this.setState({_loading: true});

        return this.reportRestApiClient.getSmallReportsForGraph()
            .then((smallReports: Array<GraphSmallReport>) => {

                this.setState({
                    smallReports: smallReports,
                    _loading: false
                });
            })
            .catch(error => {
                if (error.type === ForbiddenError) {
                    Snackbar.Error(error.message);
                } else if (Value(error.type).isInList(LoadErrors)) {
                    // this.props.openAlertDialog(t("common.messages.error"), error.message);
                    this.context.addMessage(`${error.message} - reports could not be fetched`, AlertBarType.Error);
                } else {
                    this.setState({
                        _exception: error,
                        _loading: false
                    });
                }
            }).finally(() => {
                this.setState({_loading: false});
            });
    }

    render() {
        const {t} = this.props;
        if (this.state._exception) {
            throw this.state._exception;
        }
        if (this.state._loading) {
            return (<Loader/>);
        }

        let correctedData = SmallReportDataController.filterSmallReportsByDate(this.state.smallReports, SIODateTime.local().add({days: -10}));
        correctedData = SmallReportDataController.groupByTask(correctedData);
        correctedData = ChartistGraphDataController.convertSmallReportsToDataPoints(correctedData);
        correctedData = ChartistGraphDataController.filterAndInterpolateLastXDays(correctedData, 10, null);
        correctedData = ChartistGraphDataController.filterOutEmptySets(correctedData);

        return (
            <LineGraph data={correctedData} legendText={t("scanManagement.components.severityGraph.sum")}/>
        );
    }
}

//$FlowFixMe
_SeverityGraph.contextType = AlertContext;

function mapDispatchToProps(dispatch) {
    let actions = bindActionCreators({
        openAlertDialog: openAlertDialog,
        openErrorSnackbar: openErrorSnackbar
    }, dispatch);
    return {...actions, dispatch};
}

function mapStateToProps(state) {
    return {};
}

export const SeverityGraph = compose(
    withStyles(styles),
    withTheme,
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps)
)(_SeverityGraph);
