import {useEffect, useState} from "react"
import {LegalRestApiClient} from "./LegalRestApiClient"

export function useLegalConfig() {
    const [legalConfig, setLegalConfig] = useState({})
    const [isLoading, setLoading] = useState(false)

    useEffect(() => {
        function f() {
            const client = new LegalRestApiClient()
            client.settings()
                .then(response => {
                    setLegalConfig(response)
                })
                .catch(e => Snackbar.Error(e.toString()))
                .finally(() => setLoading(false))
        }

        f()
    }, [])

    return {
        termsOfUseEnabled: legalConfig?.termsOfUseEnabled,
        privacyPolicyEnabled: legalConfig?.privacyPolicyEnabled,
        isLoading: isLoading
    }
}
