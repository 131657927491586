//@flow

class UserManagementUrlProvider {
    ENTITY_ROUTE = "/usermanagement";

    user() {
        return `${this.ENTITY_ROUTE}/user`;
    }

    language() {
        return `${this.ENTITY_ROUTE}/config/context`;
    }

    updatePassword() {
        return `${this.ENTITY_ROUTE}/update_password`;
    }

    notification() {
        return `${this.ENTITY_ROUTE}/config/notifications`;
    }

    invoices() {
        return `/billing/user/invoices`
    }

    subscription() {
        return `${this.ENTITY_ROUTE}/subscription`;
    }

    subscriptionInvoice() {
        return `${this.ENTITY_ROUTE}/subscription/invoice`;
    }

    cancelSubscription() {
        return `${this.ENTITY_ROUTE}/subscription/cancel`;
    }

    revokeCancelSubscription() {
        return `${this.ENTITY_ROUTE}/subscription/reactivate`;
    }

    billingAddress() {
        return `${this.ENTITY_ROUTE}/subscription/billing_address`;
    }

    enterpriceLicence() {
        return `${this.ENTITY_ROUTE}/subscription/enterprise`;
    }

    payment() { //Also CreditCard
        return `${this.ENTITY_ROUTE}/payment`;
    }

    webhookCheck() {
        return `${this.ENTITY_ROUTE}/message/check`;
    }
}

export {UserManagementUrlProvider};
