import React from "react"
import styled from "styled-components"
import {Drawer} from "@material-ui/core"
import {IconButton} from "../Button"
import SvgClose from "../../../assets/icons/Close"

const Inner = styled.div`
  padding: 0.94rem;
`

export function SlideIn({open, onClose, children, anchor = "right", innerStyle, ...other}) {
    return (
        <Drawer open={open} onClose={onClose} anchor={anchor} {...other}>
            <div>
                <IconButton onClick={onClose}>
                    <SvgClose/>
                </IconButton>
            </div>
            <Inner style={innerStyle}>
                {children}
            </Inner>
        </Drawer>
    );
}
