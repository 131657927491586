import React from "react"


export function Polygon(props) {
    return <svg {...props} viewBox="0 0 85 45" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M42.5 45L0.497772 0.75L84.5022 0.75L42.5 45Z" fill="currentColor"/>
    </svg>
}



