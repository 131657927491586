// Define a default UI for filtering
import React from "react"

import {isStringBlankOrEmpty} from "@greenbone/cloud-validation-library"
import {StyledSelect} from "./StyledComponents"

export function makeUnique(array, accessor) {
    const result = []
    const map = new Map()
    for (const item of array) {
        const value = accessor(item)

        if (!map.has(value)) {
            map.set(value, true)    // set any value to Map
            result.push({
                ...item
            })
        }
    }

    return result.filter(row => !isStringBlankOrEmpty(accessor(row)))
}

export function DefaultColumnFilter({column, data}) {
    const {setFilter} = column

    const distinct = makeUnique(data, column.accessor)
    const labelDisplay = column.filterLabel || column.accessor

    return (
        <StyledSelect
            isClearable={true}
            options={distinct.map(row => ({
                label: labelDisplay(row),
                value: column.accessor(row)
            }))}
            onChange={(option) => setFilter(option?.value || undefined)}
        />
    )
}
