//@flow

import React from "react"
import {withTranslation} from "react-i18next"
import {compose} from "redux"
import {EntityUrlProvider} from "../../../../UrlProvider/EntityUrlProvider"
import {Collapsible, ListMenuItem, ListMenuRootItem, MenuContext, TopLi} from "@greenbone/cloud-component-library"
//$FlowFixMe
import LocationTarget from "../../../../../assets/icons/location-target.svg"
//$FlowFixMe
import ActionLock from "../../../../../assets/icons/single-neutral-actions-lock.svg"
//$FlowFixMe
import Stopwatch from "../../../../../assets/icons/stopwatch.svg"
//$FlowFixMe
import TaskList from "../../../../../assets/icons/task_scan.svg"
//$FlowFixMe
import GatewayIcon from "../../../../../assets/icons/server-server-exchange.svg"
//$FlowFixMe
import TaskConfiguration from "../../../../../assets/icons/task_configuration.svg"
import {withLocation} from "../../../../../hocs/withLocation";

type Props = {
    location: any;
    t: any;
}


class _TaskMenu extends React.Component<Props, {}> {
    get isActive() {
        const {pathname} = this.props.location
        return EntityUrlProvider.pathHasSameBase(pathname);
    };

    render() {
        const {t} = this.props;

        return <MenuContext.Consumer>
            {({minified, toggle, ...rest}: any) => {
                const taskOpen = rest[`taskOpen`];
                const toggleTask = toggle("taskOpen");

                return <TopLi open={this.isActive}>
                    <ListMenuRootItem open={this.isActive} extended={taskOpen} onClick={(event) => {
                        toggleTask(event, true)
                    }} to={EntityUrlProvider.getBase()} icon={<TaskConfiguration />} text={t("menu.scanConfiguration")}/>
                    <Collapsible style={{display: taskOpen ? "block" : "none"}}>
                        <ListMenuItem open={this.isActive} minified={minified} to={EntityUrlProvider.taskList()}
                                      icon={TaskList}>
                            {t("menu.taskMenu.tasks")}

                        </ListMenuItem>
                        <ListMenuItem open={this.isActive} minified={minified} to={EntityUrlProvider.targetList()}
                                      icon={LocationTarget}>
                            {t("menu.taskMenu.targets")}

                        </ListMenuItem>
                        <ListMenuItem open={this.isActive} minified={minified} to={EntityUrlProvider.credentialList()}
                                      icon={ActionLock}>
                            {t("menu.taskMenu.credentials")}

                        </ListMenuItem>
                        <ListMenuItem open={this.isActive} minified={minified} to={EntityUrlProvider.scheduleList()}
                                      icon={Stopwatch}>
                            {t("menu.taskMenu.schedules")}
                        </ListMenuItem>
                        <ListMenuItem open={this.isActive} minified={minified} to={EntityUrlProvider.gatewayList()}
                                      icon={GatewayIcon}>
                            Gateways
                        </ListMenuItem>
                    </Collapsible>
                </TopLi>;
            }}
        </MenuContext.Consumer>;
    }
}

export const TaskMenu = compose(
    withLocation,
    withTranslation()
)(_TaskMenu);
