//@flow

import type {ComponentType} from "react"
import styled, {css} from "styled-components"
import {Colors} from "../../Theme"


type Props = {
    roundTopCorners?: boolean;
    roundBottomCorners?: boolean;
    noTopBorder?: boolean
}

export const Box: ComponentType<Props> = styled.div`
  border: 1px solid rgb(192, 192, 192);
  background-color: white;
  border-radius: 0;

  ${props => props.roundTopCorners && css`
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  `}

   ${props => props.roundBottomCorners && css`
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  `}

   ${props => props.noTopBorder && css`
    border-top: 0;
   `}

  margin: 0;
  flex-direction: column;
  padding: 1.25rem;
  padding-bottom: 3.13rem;
  box-sizing: border-box;
`;

export const SmallBox: ComponentType<Props> = styled(Box)`
  padding: 1.25rem;
`;

export const BorderBox = styled.div`
    ${props => props.theme.bordered && css`
      border: 1px solid ${Colors.grey.light};
      ${props => props.padding && `padding: 1.125rem;`};
      border-radius: 3px;
    `}
`;
