import {wrapValidation} from "./ValidationFunctions"
import {
    isAddressInsideSubnetPrimitive,
    isMaxPrimitive,
    isMinPrimitive,
    isNumberPrimitive, isSubnetInsideSubnetPrimitive, isSubnetPrimitive,
    regexMatchPrimitive,
    regexNoMatchPrimitive, unEqualPrimitive
} from "./Primitives"

export const isNumber = message => {
    return wrapValidation(isNumberPrimitive, {
        de: "Muss eine Zahl sein",
        en: "Must be a number"
    }, message)
}

export const isMax = (max, message) => {
    return wrapValidation(isMaxPrimitive(max), {
        de: `Muss kleiner als ${max} sein`,
        en: `Must be smaller than ${max}`
    }, message)
}

export const isMin = (min, message) => {
    return wrapValidation(isMinPrimitive(min), {
        de: `Muss größer als ${min} sein`,
        en: `Must be higher than ${min}`
    }, message)
}

export const isMatch = (regex, message) => {
    return wrapValidation(regexMatchPrimitive(regex), {
        de: `Entspricht nicht dem erwartetem pattern`,
        en: `Must match the expected pattern`
    }, message)
}

export const isNoMatch = (regex, message) => {
    return wrapValidation(regexNoMatchPrimitive(regex), {
        de: `Das pattern ist nicht erlaubt`,
        en: `The pattern is not allowed`
    }, message)
}

export const isNotEqual = (value, message) => {
    return wrapValidation(unEqualPrimitive(value), {
        de: `Der Wert ist nicht erlaubt`,
        en: `The value is not allowed`
    }, message)
}


export const isSubnet = (message) => {
    return wrapValidation(isSubnetPrimitive, {
        de: `Muss ein Netzwerk sein`,
        en: `Must be a network`
    }, message)
}

export const isNoSubnetOverlap = (subnet, message) => {
    return wrapValidation((value) => !isSubnetInsideSubnetPrimitive(subnet)(value), {
        de: `Netzwerk darf nicht überlappen`,
        en: `Network must not overlap`
    }, message)
}

export const isAddressNotInsideSubnet = (subnet, message) => {
    return wrapValidation(value => !isAddressInsideSubnetPrimitive(subnet)(value), {
        de: `Adresse darf nicht in ${subnet} liegen`,
        en: `${subnet} addresses are not allowed`
    }, message)
}

export const isAddressInsideSubnet = (subnet, message) => {
    return wrapValidation(isAddressInsideSubnetPrimitive(subnet), {
        de: `Adresse muss in ${subnet} liegen`,
        en: `Address must be inside ${subnet}`
    }, message)
}

export const isSubnetOverlap = (subnet, message) => {
    return wrapValidation(isSubnetInsideSubnetPrimitive(subnet), {
        de: `Netzwerk muss überlappen`,
        en: `Network must overlap`
    }, message)
}
