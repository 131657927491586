//@flow

import {SIODateTime} from "../../../controller/SIODateTime"

export const dateFormats = [
    {regex: /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z?$/},
    {regex: /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}Z?$/},
    {regex: /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}\+\d{4}/},
    {regex: /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\+\d{2}:\d{2}/},
    {regex: /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/},
    {regex: /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\+\d{4}/},
    {regex: /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}\+\d{4}/}
]

export class ApiClient {
    parseJsonText(jsonString: string): any {

        if (!jsonString) {
            return null
        }
        return JSON.parse(jsonString, (key, value) => {
            if (typeof value === "string") {
                for (let i = 0; i < dateFormats.length; i++) {
                    if (dateFormats[i].regex.test(value)) {
                        return SIODateTime.parseIsoString(value)
                    }
                }
            }
            return value
        })
    }
}
