import React, {useContext} from "react"
import {ReportContext} from "../ReportContext"
import {VulnerabilityBox} from "../components/VulnerabilityBox"

import styled from "styled-components"
import {FlexRow} from "@greenbone/cloud-component-library"
import {ReportPaging} from "../components/ReportPaging"
import {ReportSelectDownloader} from "../components/ReportSelectDownloader"
import {LoadingIndicator} from "../components/LoadingIndicator"
import {SearchTextIndicator} from "../components/SearchTextIndicator"
import ErrorBoundary from "../../../Components/ErrorBoundary";

const Grid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
`

export function ReportGrid(props) {
    const {vulnerabilities} = useContext(ReportContext)

    return <ErrorBoundary>
        <FlexRow alignItems={"center"} justifyContent={"flex-end"}>
            <SearchTextIndicator/>
            <LoadingIndicator/>
            <ReportSelectDownloader/>
        </FlexRow>
        <hr/>
        <Grid>
            {vulnerabilities?.map((vulnerability, index) =>

                <VulnerabilityBox key={index} vulnerability={vulnerability}/>
            )}
        </Grid>
        <FlexRow>
            <ReportPaging/>
        </FlexRow>
    </ErrorBoundary>

}

