import React from "react";
import styled from "styled-components";
import {Headline, Text} from "../Font";
import PropTypes from "prop-types";
import {FlexRow} from "../Flex";


const Button = styled.button`
  border: none;
  background: #F65656;
  padding: 0.5rem;
  margin: 0;
  width: 100%;
  color: white;
`;

Button.displayName = "Button";

const Inner = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Outer = styled.div`
  box-shadow: 0 1px 2px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.12);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
  background: ${props => props.theme.error.background};
  border-radius: 3px;
  min-width: 20rem;
  
  ${Headline}{
    color: ${props => props.theme.error.color};
    margin: 0;
  }
`;

const IconBoundary = styled.div`
  height: 50px;
  width: 50px;
  color: ${props => props.theme.error.color};
  margin-bottom: 0.5rem;
  
   svg {
      height: 100%;
      width: 100%;
   }
`;

export const ErrorDisplay = ({headline, text, onClick, ...other}) => {
    return <FlexRow justifyContent={"center"} {...other}>
        <Outer>
            <Inner>
                <IconBoundary>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <path d="M0 0h24v24H0z" fill="none"/>
                        <path fill="currentColor" d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"/>
                    </svg>
                </IconBoundary>
                <Headline>{headline}</Headline>
                <Text>{text}</Text>
            </Inner>
            {onClick && <Button onClick={onClick}>Reload</Button>}

        </Outer>
    </FlexRow>;
};

ErrorDisplay.propTypes = {
    headline: PropTypes.string,
    text: PropTypes.string,
    onClick: PropTypes.func
};
