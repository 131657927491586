import React from "react";
import styled from "styled-components";

export const Spinner = styled.div`
  @keyframes spinner-donut-anim {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  display: inline-block;
  margin: 0px;
  border: .25rem solid ${props => props.theme.spinner.background};
  border-left: .25rem solid  ${props => props.theme.spinner.foreground};
  border-radius: 50%;
  
  width: 1rem;
  height: 1rem;
  animation: spinner-donut-anim 1.2s linear infinite;
`;
