import React from 'react';
import PropTypes from 'prop-types';

const InputWarning = ({ message }) => {
    const defaultStyle = {
        color: '#ffa000',
        fontSize: 12,
        fontWeight: 800,
        paddingTop: 5,
    };

    return (
        <div style={defaultStyle}>
            {message}
        </div>
    );
};

InputWarning.propTypes = {
    message: PropTypes.string.isRequired
};

export default InputWarning;
