//@flow

import Promise from "bluebird"
import React from "react"
import {Col, Row} from "reactstrap"
import {AbortButton, Button, FlexRow, GhostButton} from "@greenbone/cloud-component-library"
import {ModalSlideIn} from "../../../Components/Dialogs/SlideIn/ModalSlideIn"
import Loader from "../../../Components/Loader/Loader"
import {Converter} from "../../../Helper/Converter"
import {Caption, Headline, Paragraph, Subheadline} from "../../../StyledComponents/Font/Font"
import {Input} from "../../../StyledComponents/Form/Input"
import {getValidationResult} from "../../../controller/FieldValidator"
import {IsNotEmptyString} from "../../../controller/FormValidators"
import {CredentialsEdit} from "../../Credentials/components/CredentialsEdit"
import {CredentialsRestApiClient} from "../../../services/apiClients/Credentials/CredentialsRestApiClient"
import {CredentialSelect} from "../components/CredentialSelect"
import {RowSmall} from "../components/Grid"
import {InfoBox, InfoHeadline} from "../components/InfoBox"
import {compose} from "redux"
import {withTranslation} from "react-i18next"


export type CredentialFields = {
    selectedWindowsCredentialId: ?string;
    selectedSSHCredentialId: ?string;
    selectedSSHCredentialPort: number;
    selectedVMWareCredentialId: ?string;
}

type Props = {
    nextStep: EventCallback;
    previousStep: EventCallback;
    cancelWizard: EventCallback;
    pageNumber: number;
    initValues: CredentialFields;
    handleSaveAndNext: EventCallback;
    t: any;
}

type State = {
    fields: CredentialFields;
    fieldStatus: {
        selectedSSHCredentialPort: ?boolean;
    };
    isValid: boolean,
    isCredentialCreateOpen: boolean;
    _loading: boolean;
    _exception: any;
    credentials: Array<GSPCredentialsDto>;
}

class _Credential extends React.Component<Props, State> {
    credentialsRestApiClient: CredentialsRestApiClient;
    setStateAsync: Promise;
    FieldValidators = {
        selectedSSHCredentialPort: new IsNotEmptyString(),
    };

    constructor(props: Props) {
        super(props);

        this.state = {
            fields: {...props.initValues},
            fieldStatus: {
                selectedSSHCredentialPort: null,
            },
            isValid: false,
            credentials: [],
            isCredentialCreateOpen: false,
            _loading: false,
            _exception: null,
        };

        this.credentialsRestApiClient = new CredentialsRestApiClient();
        this.setStateAsync = Promise.promisify(this.setState);
    }

    componentDidMount() {
        this.loadCredentials();
    }

    loadCredentials = () => {
        this.setState({_loading: true});
        this.credentialsRestApiClient.getAll()
            .then(credentials => {
                this.setState({
                    credentials: credentials.sort((a: GSPTarget, b: GSPTarget) => {
                        return Converter.cmpString(a.name.toLowerCase(), b.name.toLowerCase());
                    }),
                });
            })
            .catch(exception => {
                this.setState({_exception: exception});
            })
            .finally(() => {
                this.setState({_loading: false});
            });
    };

    handleChangeCredential = (name: string) => (credentialId: string) => {
        this.setState(prevState => {
            let fields = prevState.fields;
            fields[name] = prevState.fields[name] === credentialId ? null : credentialId;
            return {fields};
        });
    };

    handleWindowsCredentialClick = this.handleChangeCredential("selectedWindowsCredentialId");
    handleSSHCredentialClick = this.handleChangeCredential("selectedSSHCredentialId");
    handleVMWareCredentialClick = this.handleChangeCredential("selectedVMWareCredentialId");

    handleOpenSlideIn = (event: any) => {
        this.setState({isCredentialCreateOpen: true});
    };

    handleCloseSlideIn = (event: any) => {
        this.setState({isCredentialCreateOpen: false});
        this.loadCredentials();
    };

    validateFields = async (): Promise<void> => {
        const {isValid, fieldValidity} = getValidationResult(
            this.state.fields,
            this.FieldValidators,
        );

        await this.setStateAsync({fieldStatus: fieldValidity, isValid});
    };

    onFormSubmit = (event: SyntheticEvent<HTMLFormElement>) => {
        event.preventDefault();

        this.validateFields()
            .then(() => {
                this.handleFormSubmit();
            });
    };

    handleFormSubmit = () => {
        const {isValid} = this.state;
        const {
            selectedVMWareCredentialId, selectedWindowsCredentialId, selectedSSHCredentialId, selectedSSHCredentialPort,
        } = this.state.fields;

        if (!isValid) {
            return;
        }

        this.props.handleSaveAndNext({
            selectedSSHCredentialId,
            selectedWindowsCredentialId,
            selectedSSHCredentialPort,
            selectedVMWareCredentialId,
        });
    };

    handleOnChangeField = (event: any) => {
        const {name, value} = event.target;

        this.setState(prevState => {
            let fields = prevState.fields;
            fields[name] = value;
            return {fields};
        });
    };

    render() {
        const {t} = this.props;
        const {cancelWizard, previousStep, nextStep} = this.props;
        const {fields, _loading, credentials, isCredentialCreateOpen, fieldStatus} = this.state;

        return (
            <React.Fragment>
                <Row style={{marginBottom: "2rem"}}>
                    <Col lg={9} md={12}>
                        <FlexRow justifyContent={"space-between"}>
                            <Headline noMargin>{t("wizard.steps.credential.loginData")}</Headline>
                            <GhostButton style={{margin: 0}} variant={"negative"} onClick={nextStep}>
                                {t("wizard.steps.credential.skipLogin")}
                            </GhostButton>
                        </FlexRow>

                        <Paragraph>
                            {t("wizard.steps.credential.loginDescription")}
                        </Paragraph>
                    </Col>
                </Row>

                <Row style={{marginBottom: "4rem"}}>
                    <Col lg={{size: 9, order: 1}} md={{size: 12, order: 2}}>
                        <FlexRow justifyContent={"space-between"}>
                            <Subheadline>{t("wizard.steps.credential.alreadyCreatedLogin")}</Subheadline>
                            <Button style={{margin: 0}} onClick={this.handleOpenSlideIn}>
                                {t("wizard.steps.credential.newLogin")}
                            </Button>
                        </FlexRow>

                        <Caption>{t("wizard.steps.credential.windowsLogin")}</Caption>
                        <RowSmall style={{marginBottom: "1.25rem"}}>
                            {_loading ? <Col><Loader/></Col> :
                                <CredentialSelect credentials={credentials}
                                                  handleClick={this.handleWindowsCredentialClick}
                                                  selectedId={fields.selectedWindowsCredentialId}/>
                            }
                        </RowSmall>

                        <Row style={{marginBottom: "1rem"}}>
                            <Col xs={12}>
                                <Caption>{t("wizard.steps.credential.secureShell")}</Caption>
                                <Paragraph style={{marginBottom: "0"}}>
                                    {t("wizard.steps.credential.youCanSelectAPort")}
                                </Paragraph>
                            </Col>
                        </Row>
                        <Row style={{marginBottom: "1rem"}}>
                            <Col xs={12} sm={3}>
                                <Input type={"number"} onChange={this.handleOnChangeField}
                                       name={"selectedSSHCredentialPort"} label={t("wizard.steps.credential.port")}
                                       value={fields.selectedSSHCredentialPort || ""}
                                       isValid={fieldStatus.selectedSSHCredentialPort}/>
                            </Col>
                        </Row>

                        <RowSmall style={{marginBottom: "1.25rem"}}>
                            {_loading ? <Col><Loader/></Col> :
                                <CredentialSelect credentials={credentials}
                                                  handleClick={this.handleSSHCredentialClick}
                                                  selectedId={fields.selectedSSHCredentialId}/>
                            }
                        </RowSmall>

                        <Caption>{t("wizard.steps.credential.vMWareESXi")}</Caption>
                        <RowSmall style={{marginBottom: "1.25rem"}}>
                            {_loading ? <Col><Loader/></Col> :
                                <CredentialSelect credentials={credentials}
                                                  handleClick={this.handleVMWareCredentialClick}
                                                  selectedId={fields.selectedVMWareCredentialId}/>
                            }
                        </RowSmall>
                    </Col>
                    <Col lg={{size: 3, order: 2}} md={{size: 12, order: 1}}>
                        <InfoBox type={"tip"}>
                            <InfoHeadline>{t("credentials.components.information.loginData")}</InfoHeadline>
                            <Paragraph>
                                {t("wizard.steps.credential.scanInformation")}
                            </Paragraph>
                        </InfoBox>
                    </Col>
                </Row>

                <Row>
                    <Col lg={9} md={12}>
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                            <GhostButton onClick={cancelWizard}>{t("common.action.abort")}</GhostButton>
                            <div style={{gridColumnGap: "2.3rem", display: "grid", gridTemplateColumns: "auto auto"}}>
                                <AbortButton onClick={previousStep}>{t("common.action.back")}</AbortButton>
                                <Button onClick={this.onFormSubmit}>{t("common.action.saveAndContinue")}</Button>
                            </div>
                        </div>
                    </Col>
                </Row>

                <ModalSlideIn isOpen={isCredentialCreateOpen}>
                    <CredentialsEdit onClose={this.handleCloseSlideIn} id={null}/>
                </ModalSlideIn>
            </React.Fragment>
        );
    }
}

export const Credential = compose(
    withTranslation(),
)(_Credential);
