// @flow

import React from "react";
import {withTranslation} from "react-i18next";
import {Col} from "reactstrap";
import {compose} from "redux";
import Loader from "../../../Components/Loader/Loader";
import {Converter} from "../../../Helper/Converter";
import {RemoteResourceRestApiClient} from "../../../services/apiClients/RemoteResource/RemoteResourceRestApiClient";
import {ScanConfigurationsController} from "../controller/ScanConfigurationsController";
import {ColSmall} from "./Grid";
import {Recommendation} from "./Recommendation";
import {TabBox} from "./TabBox";


type Props = {
    onChange: EventCallback;
    selectedId: ?number;
    limit: number;
    t: any;
}

type State = {
    _loading: boolean;
    _exception: ?any;
    scanConfigurations: Array<GSPScanConfigurationRef>;
    recommendedId: number;
}

class _ScanConfigurationsSelect extends React.Component<Props, State> {
    remoteResourceClient: RemoteResourceRestApiClient;
    scanConfigurationsController: ScanConfigurationsController;

    state = {
        _loading: true,
        _exception: null,
        scanConfigurations: [],
        recommendedId: 1,
    };

    constructor(props: Props) {
        super(props);
        this.remoteResourceClient = new RemoteResourceRestApiClient();
        this.scanConfigurationsController = new ScanConfigurationsController();
    }

    componentDidMount() {
        this.loadScanConfigurations();
    }

    loadScanConfigurations = () => {
        this.setState({_loading: true});

        this.remoteResourceClient.getScanConfigurations()
            .then((scanConfigurations) => {
                this.setState({
                    scanConfigurations: scanConfigurations,
                });
            })
            .catch(error => {
                this.setState({
                    _exception: error,
                });
            })
            .finally(() => {
                this.setState({_loading: false});
            });
    };

    getScanConfigurations(): Array<GSPScanConfigurationRef> {
        const {scanConfigurations} = this.state;
        const {limit, t} = this.props;
        const sortOrder = [t("wizard.components.ScanConfiguration.analyseStandard"), t("wizard.components.ScanConfiguration.analyse.Aggressive"), t("wizard.components.ScanConfiguration.Discovery")];

        return scanConfigurations
            .filter((scanConfig: GSPScanConfigurationRef) => {
                return scanConfig.name !== "empty";
            })
            .sort((a: GSPScanConfigurationRef, b: GSPScanConfigurationRef) => {
                const aSortOrder = sortOrder.includes(a.name) ? sortOrder.indexOf(a.name) : 99;
                const bSortOrder = sortOrder.includes(b.name) ? sortOrder.indexOf(b.name) : 99;
                return Converter.cmpNumber(aSortOrder, bSortOrder);
            })
            .slice(0, limit || 99);
    }

    render() {
        const {_exception, _loading, recommendedId} = this.state;
        const {selectedId, onChange, t} = this.props;

        if (_exception) {
            throw _exception;
        }

        if (_loading) {
            return (<Col><Loader/></Col>);
        }

        return <React.Fragment>{
            this.getScanConfigurations()
                .map((scanConfig: GSPScanConfigurationRef) => {
                    return <ColSmall key={scanConfig.id} lg={4} style={{marginBottom: "1.88rem"}}>
                        {scanConfig.id === recommendedId ?
                            <Recommendation title={t("wizard.components.ScanConfiguration.ourSuggestion")}>
                                <TabBox style={{minHeight: "10rem"}} title={scanConfig.name}
                                        active={scanConfig.id === selectedId} onClick={() => {
                                    onChange(scanConfig.id);
                                }}>{(this.scanConfigurationsController.getDescriptionById(scanConfig.id))}</TabBox>
                            </Recommendation>
                            :
                            <TabBox style={{minHeight: "10rem"}} title={scanConfig.name}
                                    active={scanConfig.id === selectedId}
                                    onClick={() => {
                                        onChange(scanConfig.id);
                                    }}>{
                                (this.scanConfigurationsController.getDescriptionById(scanConfig.id))
                            }

                            </TabBox>
                        }
                    </ColSmall>;
                })
        }</React.Fragment>;
    }
}

export const ScanConfigurationsSelect = compose(
    withTranslation(),
)(_ScanConfigurationsSelect);
