import React from "react"
import styled, {css} from "styled-components"
import ReactTooltip from "react-tooltip"
import {ResolveOSIcon} from "../operatingSystem/ResolveOSIcon"
import md5 from "md5"

export const OSTypeOuter = styled.div`
    box-sizing: border-box;
    height: 3.125rem;
    border-radius: 1.5625rem;
    padding-left: 0.625rem;
    padding-right: 0.625rem;
    background: #c4c4c4;
    color: #4c4c4d;
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${props => props.small && css`
        height: 40px;
        width: 150px;
    `}
`

const Icon = styled.div`
    width: 1.875rem;
    height: 1.875rem;
    flex-shrink: 0;

    ${props => props.small && css`
        height: 1.5rem;
        width: 1.5rem;
    `}
    svg {
        width: 100%;
        height: 100%;
    }

    path {
        fill: #545454;
    }


`


export function OperatingSystemIcon({os}) {

    const hashedID = md5(`${os}`)

    const osName = os || "Unknown"

    return <>
        <Icon data-for={hashedID}
              data-tip={osName}
              style={{color: "#545454"}}>
            <ResolveOSIcon os={os}/>
        </Icon>
        <ReactTooltip id={hashedID}/>
    </>
}

const MiniatureIcon = styled.div`
    position: relative;
    width: 1rem;
    height: 1rem;
    flex-shrink: 0;
    display: inline-block;
    margin-right: .5rem;
    vertical-align: text-top;

    svg {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }

    path {
        fill: #545454;
    }
`

export function MiniatureOperatingSystemIcon({os}) {
    return <MiniatureIcon style={{color: "#545454"}}>
        <ResolveOSIcon os={os}/>
    </MiniatureIcon>
}


