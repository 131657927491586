import React from "react";
import {AlertBox, AlertBoxType} from "../AlertBox";
import {CustomError, ForbiddenError, NotFoundError} from "../Helper/Exceptions";

export function AlertIdentifier({exception}) {
    if (!exception) {
        return null;
    }

    switch (exception.type) {

        case NotFoundError: {
            return <AlertBox title={"Error"} text={"The action could not be executed."} type={AlertBoxType.Error}/>;
        }

        case ForbiddenError: {
            return <AlertBox title={"Permission Error"} text={"You're not allowed to do that."}
                             type={AlertBoxType.Error}/>;
        }

        case CustomError: {
            return <AlertBox title={"Error"} text={exception.toString()}
                             type={AlertBoxType.Error}/>;
        }

        default:
            return <AlertBox title={"Error"} text={"The action could not be executed."} type={AlertBoxType.Error}/>;
    }
}
