
import * as HttpStatuscode from "http-status-codes";


export class BadRequestError extends Error {
    httpStatus = HttpStatuscode.BAD_REQUEST;
    type = BadRequestError;

    constructor(message = "") {
        super(`Bad request: '${message}'`);
    }
}
