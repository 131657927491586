export class FormHelper {
    /**
     * Matches fields against field errors and returns the result,
     * if a setState callback is passed it will be called with the result
     * @param fields - Fields to be validated (state fieldErrors block)
     * @param fieldErrors - Field errors received from api response
     * @param setState - optional - component setState Callback
     * @param stateFieldName - optional - different name used for field object
     * @returns {{[p: string]: *}}
     */
    static handleFieldErrors(fields, fieldErrors, setState = null, stateFieldName = "fieldErrors") {
        fields = FormHelper.setAllFieldsToValue(fields, true);

        const newStateObject = {
            [stateFieldName]: {
                ...fields,
                ...fieldErrors
            }
        };

        if (setState) {
            setState(newStateObject);
        }

        return newStateObject;


    }


    /**
     * Sets all fields in the object to the passed value
     * @param object
     * @param value
     * @returns The passed object with all fields sets to passed value
     */
    static setAllFieldsToValue(object, value) {
        for (let field in object) {
            if (object.hasOwnProperty(field)) {
                object[field] = value;
            }
        }

        return object;
    }
}


