import React from "react"
import styled, {css} from "styled-components"

const Box = styled.div`
  background: #FAFAFA;
  border-bottom: 1px solid #D5D5D5;
  height: 4rem;
  box-sizing: border-box;
  display: flex;
`

export const SubNavbarItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 200px;
  color: ${props => props.theme.sidebar.font};
  
  &:hover {
   color: ${props => props.theme.sidebar.hoverFont};
   background-color: ${props => props.theme.sidebar.hoverBackground};
   cursor: pointer;
  }
  
  ${props => props.active && css`
     color: ${props => props.theme.sidebar.hoverFont};
     font-weight: bold;
      background-color: ${props => props.theme.sidebar.hoverBackground};
  `}
             
  
`;


export function SubNavbar({children}) {
    return <Box>
        {children}
    </Box>;
}
