(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@material-ui/core"), require("jss"), require("react"), require("react-dom"), require("react-router-dom"), require("styled-components"));
	else if(typeof define === 'function' && define.amd)
		define("Library", ["@material-ui/core", "jss", "react", "react-dom", "react-router-dom", "styled-components"], factory);
	else if(typeof exports === 'object')
		exports["Library"] = factory(require("@material-ui/core"), require("jss"), require("react"), require("react-dom"), require("react-router-dom"), require("styled-components"));
	else
		root["Library"] = factory(root["@material-ui/core"], root["jss"], root["react"], root["react-dom"], root["react-router-dom"], root["styled-components"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__7276__, __WEBPACK_EXTERNAL_MODULE__8288__, __WEBPACK_EXTERNAL_MODULE__9155__, __WEBPACK_EXTERNAL_MODULE__9514__, __WEBPACK_EXTERNAL_MODULE__4442__, __WEBPACK_EXTERNAL_MODULE__8694__) => {
return 