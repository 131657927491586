
import * as HttpStatuscode from "http-status-codes";


export class UnauthorizedError extends Error {
    httpStatus = HttpStatuscode.UNAUTHORIZED;
    type = UnauthorizedError;

    constructor(message = "") {
        super(message);
    }
}
