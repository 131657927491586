import React, {useContext, useEffect} from "react"
import {Col, Row} from "reactstrap"
import {
    AlertBox,
    AlertBoxType,
    Button,
    Headline,
    Paragraph,
    Spinner,
    Subheadline
} from "@greenbone/cloud-component-library"
import {useNavigate} from "react-router"
import {PaymentOptions, SubscriptionContext, SubscriptionStep} from "../SubscriptionProvider"
import {SubscriptionNavigationUrlProvider} from "../SubscriptionNavigationUrlProvider"
import {useTranslation} from "react-i18next"
import {JWT} from "../../../Helper/JWT"
import styled from "styled-components"
import {Converter} from "../../../Helper/Converter"
import ReactTooltip from "react-tooltip"
import {isDate} from "lodash"

function isPaymentOptionAvailable(paymentOptions) {
    return paymentOptions.indexOf(PaymentOptions.CREDIT_CARD) > -1 || paymentOptions.indexOf(PaymentOptions.INVOICE) > -1
}

export function SubscriptionOverview() {

    const {subscriptions, loading, setStep, error} = useContext(SubscriptionContext)
    const navigate = useNavigate()
    const {t} = useTranslation()
    const userIsManaged = new JWT()?.isManaged()
    const userHasNextSubscription = !(subscriptions?.next === null)

    const handlePlanChange = () => {
        navigate({
            pathname: SubscriptionNavigationUrlProvider.change(),
            search: `?${new URLSearchParams().toString()}`
        })

    }

    useEffect(() => {
        setStep(SubscriptionStep.Overview)
    }, [setStep])


    if (loading) {
        return <Spinner/>
    }

    if (error) {
        return <>
            <Row>
                <Col>
                    <Headline>{t("subscriptionOverview.headline")}</Headline>
                </Col>
            </Row>
            <Row>
                <Col>
                    <AlertBox title={"Error"}
                              text={error.toString()}
                              type={AlertBoxType.Error}/>
                </Col>
            </Row>
        </>
    }

    return <>
        <Row>
            <Col>
                <Headline>{t("subscriptionOverview.headline")}</Headline>
            </Col>

        </Row>
        <Row>
            <Col>
                <Subheadline>
                    {userIsManaged ? t("subscriptionOverview.ipstotal") : t("subscriptionOverview.currentSubscription")}
                </Subheadline>
                <div>
                    <SubscriptionDetails subscription={subscriptions?.current}/>
                </div>
            </Col>
            <Col xs={1}/>
            {
                !userIsManaged &&
                <Col>
                    <Subheadline>
                        {t("subscriptionOverview.nextSubscription")}
                    </Subheadline>
                    <div>
                        <SubscriptionDetails subscription={subscriptions?.next}/>
                    </div>
                </Col>
            }
        </Row>
        <Row>
            <br>
            </br>
        </Row>
        <Row>
            <Col>
                {!userIsManaged && userHasNextSubscription &&
                    <>
                        <span>
                            {t("subscriptionBox.upgrade.info")}
                        </span>
                    </>
                }
            </Col>
        </Row>
        <Row>
            <Col>
                {!userIsManaged && userHasNextSubscription &&
                    <UpgradeButton/>
                }
            </Col>
        </Row>
        <Row>
            <Col>
                <hr/>
            </Col>
        </Row>
        <Row>
            <Col>
                <Headline>{t("subscriptionOverview.info")}</Headline>
            </Col>
        </Row>
        <Row>
            <Col>
                <Subheadline>
                    {t("subscriptionOverview.ipsInternal")}
                </Subheadline>
            </Col>
            <Col xs={1}/>
            <Col>
                <Subheadline>
                    {t("subscriptionOverview.ipsExternal")}
                </Subheadline>
            </Col>
        </Row>
        <Row>
            <Col>
                <Paragraph>
                    {t("subscriptionOverview.internalText")}
                </Paragraph>
            </Col>
            <Col xs={1}/>
            <Col>
                <Paragraph>
                    {t("subscriptionOverview.externalText")}
                </Paragraph>
            </Col>
        </Row>
    </>
}


const SubscriptionDetailsContainer = styled.div`
    max-width: 30rem;
`

function UpgradeButton() {
    const {paymentOptions} = useContext(SubscriptionContext)
    const navigate = useNavigate()
    const handlePlanChange = () => {
        navigate({
            pathname: SubscriptionNavigationUrlProvider.change(),
            search: `?${new URLSearchParams().toString()}`
        })

    }
    return (
        <>
        <span data-tip data-for="UPGRADE-BUTTON">
          <Button
              onClick={handlePlanChange}
              disabled={!isPaymentOptionAvailable(paymentOptions)}
          >
            Upgrade
          </Button>
        </span>

            {!isPaymentOptionAvailable(paymentOptions) && (
                <ReactTooltip id="UPGRADE-BUTTON" type="dark" effect="solid">
                    The provider needs to enable a payment method.
                </ReactTooltip>
            )}
        </>
    );
}

function SubscriptionDetails({subscription}) {
    const {t} = useTranslation()
    const {setStep} = useContext(SubscriptionContext)
    const userIsManaged = new JWT()?.isManaged()

    useEffect(() => {
        setStep(SubscriptionStep.Overview)
    }, [setStep])

    if (!subscription) return (
        <span>
            <Row>
                <Col>
                    {
                        subscription?.paymentOption === "NONE" ?
                            t("subscriptionBox.canceled") :
                            t("subscriptionBox.upgrade")
                    }
                </Col>
            </Row>
            <Row>
                <Col>
                    {!userIsManaged &&
                        <UpgradeButton/>
                    }
                </Col>
            </Row>
        </span>
    )

    return (
        <SubscriptionDetailsContainer>
            <Row>
                <Col>
                    {t("subscriptionOverview.numberOfIp")}
                </Col>
                <Col>
                    {(subscription?.product.ipsExternal + subscription?.product.ipsInternal) || 0}
                </Col>
            </Row>

            {((new JWT())?.isManaged() === false) && <SelfServiceDetails subscription={subscription}/>}
        </SubscriptionDetailsContainer>

    )
}

const formatDate = (_date, language) => {
    let date = _date
    if (!isDate(date)) date = new Date(date)
    const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
    };

    return new Date(date).toLocaleDateString(language, options);
}

const getPaymentOptionText = () => {
    const { t } = useTranslation();
    const subscription = useContext(SubscriptionContext)
    switch (subscription?.paymentOption) {
        case 'INVOICE': return t('subscriptionBox.invoice');
        case 'CREDIT_CARD': return t('subscriptionBox.creditCard');
        case 'PROJECT_KEY': return t('subscriptionBox.projectKey');
        case 'LICENCE_KEY': return t('subscriptionBox.licenceKey');
        default: return subscription?.paymentOption;
    }
};

function SelfServiceDetails({subscription}) {
    const {t, i18n} = useTranslation()
    return <>
        <Row>
            <Col>
                {t("subscriptionBox.internalScannable.ips")}
            </Col>
            <Col>
                {(subscription?.product.ipsInternal) || 0}
            </Col>
        </Row>
        <Row>
            <Col>
                {t("subscriptionBox.externalScannable.ips")}
            </Col>
            <Col>
                {(subscription?.product.ipsExternal) || 0}
            </Col>
        </Row>
        <Row>
            <Col>
                {t("subscriptionBox.price")}
            </Col>
            <Col>
                {Converter.asCurrency(subscription?.product.price || 0, i18n.language)}
            </Col>
        </Row>
        <Row>
            <Col>
                {t("subscriptionBox.billingPeriod")}
            </Col>
            <Col>
                {subscription?.endedAt && subscription?.startedAt ?
                    formatDate(new Date(subscription?.startedAt), i18n.language) + " - " + formatDate(new Date(subscription?.endedAt), i18n.language)
                    : "-"}
            </Col>
        </Row>
        <Row>
            <Col>
                {t("subscriptionBox.paymentOption")}
            </Col>
            <Col>
                {subscription?.paymentOption ? getPaymentOptionText() : "-"}
            </Col>
        </Row>
    </>
}
