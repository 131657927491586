import React from 'react'
import {FlexRow, Paragraph, Subheadline, Text} from "@greenbone/cloud-component-library"
import {FormControlLabel, Switch} from "@material-ui/core"
import {useTranslation} from "react-i18next"


export function ReportAccess({isManaged, adminReportAccessEnabled, setState}) {
    const {t} = useTranslation()

    return <>
        <Subheadline>
            Report Access
        </Subheadline>
        <FlexRow>
            <FormControlLabel
                control={
                    <Switch
                        disabled={!isManaged}
                        checked={adminReportAccessEnabled}
                        onChange={() => setState(prevState => ({
                            ...prevState,
                            adminReportAccessEnabled: !prevState.adminReportAccessEnabled
                        }))}
                        name={"ManagedSecurity"}
                    />}
                label={adminReportAccessEnabled ? t("Enabled") : t("Disabled")}
            />
        </FlexRow>
        <Paragraph>
            {t("ManagedSecurity.description")}
        </Paragraph>
        <ul>
            <li>
                <Text>{t("ManagedSecurity.bullet.1")}</Text>
            </li>
            <li>
                <Text>{t("ManagedSecurity.bullet.2")}</Text>
            </li>
        </ul>
    </>
}
