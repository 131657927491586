// @flow
import {IconButton, withStyles} from "@material-ui/core";
import {FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage} from "@material-ui/icons";
import React from "react";


const styles = theme => ({
    root: {
        flexShrink: 0,
        color: theme.palette.text.secondary,
        marginLeft: theme.spacing(2.5)
    },
});

type Props = {
    onPageChange: (any, number) => void;
    page: number;
    count: number;
    rowsPerPage: number;
    classes: typeof styles;
    theme: any;
}

class TablePaginationActions extends React.Component<Props> {
    handleFirstPageButtonClick = (event: any) => {
        this.props.onPageChange(event, 0);
    };

    handleBackButtonClick = (event: any) => {
        this.props.onPageChange(event, this.props.page - 1);
    };

    handleNextButtonClick = (event: any) => {
        this.props.onPageChange(event, this.props.page + 1);
    };

    handleLastPageButtonClick = (event: any) => {
        this.props.onPageChange(
            event,
            Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1),
        );
    };

    render() {
        const {classes, count, page, rowsPerPage} = this.props;

        return (
            <div className={classes.root}>
                <IconButton
                    onClick={this.handleFirstPageButtonClick}
                    disabled={page === 0}
                    aria-label="First Page"
                >
                    <FirstPage/>
                </IconButton>
                <IconButton
                    onClick={this.handleBackButtonClick}
                    disabled={page === 0}
                    aria-label="Previous Page"
                >
                    <KeyboardArrowLeft/>
                </IconButton>
                <IconButton
                    onClick={this.handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="Next Page"
                >
                    <KeyboardArrowRight/>
                </IconButton>
                <IconButton
                    onClick={this.handleLastPageButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="Last Page"
                >
                    <LastPage/>
                </IconButton>
            </div>
        );
    }
}

export default withStyles(styles)(TablePaginationActions);
