import styled from "styled-components"


export const BoxHeader = styled.div`
  background: #F7F7F7;
  color: #4C4C4D;
  font-size: 1.25rem;
  height: 4.0625rem;
  padding-left: 2rem;
  display: flex;
  align-items: center;

`;
