import React from 'react'
import {useParams} from 'react-router'

export const withParams = (Component: any) => {
    return (props: any) => {
        const params = useParams()

        return <Component params={params} {...props} />;
    };
};
