//@flow
import {css} from "styled-components"
import {Colors} from "../../Theme"


export const HeadlineStyle = css`
  color: ${Colors.blue.normal};
  font-family: Netto,sans-serif;
  font-size: 2rem;
  font-weight: normal;
`

export const SubheadlineStyle = css`
  font-weight: normal;
  color: ${Colors.blue.normal};
  font-family: Netto, sans-serif;
  font-size: 1.63rem;
`;

export const CaptionStyle = css`
  font-weight: normal;
  color:  ${Colors.blue.normal};
  font-family: Netto, sans-serif;
  font-size: 1.25rem;
`;

export const TextStyle = css`
  color: ${Colors.grey.dark};
  font-family: Netto, sans-serif;
  font-size: 1rem;
`;

export const TinyStyle = css`
  color: rgb(183, 183, 183);
  font-family: Netto, sans-serif;
  font-size: 0.75rem;
  ${props => props.noWrap && "white-space: nowrap"}
`;

