import React, {useContext, useEffect, useState} from "react"
import {i18n} from "../../../../../i18n"
import styled from "styled-components"
import {useTranslation} from "react-i18next"
import {SubscriptionContext} from "../../SubscriptionProvider"
import {AlertBox, AlertBoxType, Button, Caption, FlexRow, Snackbar} from "@greenbone/cloud-component-library"
import {isNumberPrimitive} from "@greenbone/cloud-validation-library"

const Table = styled.table`
    width: 100%;

    th {
        padding-right: .5rem;
        padding-left: .5rem;
    }

    td {
        padding-right: .5rem;
        padding-left: .5rem;
    }

    thead tr:first-child {
        border-bottom: 1px solid lightgrey;
    }

    thead tr:last-child td:first-child {
        padding-left: .5rem;
    }

    thead tr:last-child {
        background: #f3f3f3;
    }

    thead tr th:not(:first-child) {
        text-align: right;
    }

    thead tr td:not(:first-child) {
        text-align: right;
    }


    tbody tr td:not(:first-child) {
        text-align: right;
    }

    tbody tr:nth-child(even) {
        background: #f3f3f3;
    }

    tbody tr td:first-child {
        padding-left: 1rem;
    }

    tbody tr:last-child td {
        border-bottom: 1px solid lightgrey;
    }

    td {
        height: 3.2rem;
    }


    tfoot tr td:last-child {
        text-align: right;
    }

    tfoot tr td:nth-child(3) {
        padding-left: .5rem;
    }

    tfoot tr:nth-child(odd) {
        & td:nth-child(3) {
            background: #f3f3f3;
        }

        & td:nth-child(4) {
            background: #f3f3f3;
        }
    }




`


//TODO - jwerremeyer update translation layer with this functionality
function toCurrency(value) {
    if (i18n.language === "de") {
        return new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR"}).format(value)

    }
    return new Intl.NumberFormat("en-US", {style: "currency", currency: "EUR"}).format(value)
}

export function PricingTable(props) {

    const {next} = props


    const {
        groupAddress,
        companyAddress,
        calculatePrice,
        getPriceList
    } = useContext(SubscriptionContext)

    const {t} = useTranslation()
    const [useVAT, setUseVat] = useState(false)
    const [price, setPrice] = useState(0)
    const [priceList, setPriceList] = useState([])
    const [isInvalid, setIsInvalid] = useState(false)
    const [amounts, setAmount] = useState({intern: null, extern: null})
    const [invalidInput, setInvalidInput] = useState(false)

    useEffect(() => {
        const {intern, extern} = props
        setAmount({
            "intern": Number.parseInt(intern),
            "extern": Number.parseInt(extern)
        })
    }, [props])

    useEffect(() => {
        if (companyAddress?.countryId === 81) {
            setUseVat(true)
        }

        if (groupAddress?.countryId === 81 && companyAddress?.countryId === null) {
            setUseVat(true)
        }

    }, [groupAddress, companyAddress])

    useEffect(() => {

        const {intern, extern} = amounts
        setInvalidInput(false)

        let localInvalid = false
        if (isNaN(intern) || isNaN(extern)) {
            setInvalidInput(true)
            return
        }

        if (intern === null || extern === null) {
            setInvalidInput(true)
            return
        }

        if (intern === 0 && extern === 0) {
            setInvalidInput(true)
            return
        }


        if (localInvalid === false) {
            const priceListPromise = getPriceList(intern + extern)
            const pricePromise = calculatePrice(intern, extern)
            Promise.all([pricePromise, priceListPromise])
                .then(([price, priceList]) => {
                    if (isNumberPrimitive(price.price)) {
                        setPrice(price.price)
                        setPriceList(priceList)
                        setIsInvalid(false)
                    } else {
                        Snackbar.Error(t("confirmationPage.couldNotCalculatePrice"))
                        setIsInvalid(true)
                    }

                })
                .catch(e => {
                    Snackbar.Error(t("confirmationPage.couldNotCalculatePrice"))
                    setIsInvalid(true)
                })
        }


    }, [amounts])


    const Next = () => {
        const {ipOrderMinimum} = useContext(SubscriptionContext)

        if (next)
            return <FlexRow justifyContent={"flex-end"}>
                <Button disabled={isInvalid || (amounts.intern + amounts.extern) < ipOrderMinimum}
                        onClick={() => next(amounts?.intern, amounts?.extern)}>{t("common.action.continue")}</Button>
            </FlexRow>

        return null
    }

    if (isInvalid) {
        return <>
            <AlertBox title={t("pricingTable.InvalidAmount")} type={AlertBoxType.Warning}>
                {t("pricingTable.InvalidAmount.text")}
            </AlertBox>
            <Next/>
        </>
    }

    if (invalidInput) {
        return <Next/>
    }

    return <>
        <Caption style={{marginTop: "2rem"}}>{t("confirmationPage.summary")}</Caption>

        <Table>
            <thead>
            <tr>
                <th>{t("confirmationPage.description")}</th>
                <th>{t("confirmationPage.QTY")}</th>
                <th>{t("confirmationPage.UnitPrice")}</th>
                <th>{t("confirmationPage.Amount")}</th>
            </tr>
            <tr>
                <td>{t("confirmationPage.VulerabilityScanning")}</td>
                <td>{amounts.intern + amounts.extern}</td>
                <td></td>
                <td></td>
            </tr>
            </thead>
            <tbody>

            {priceList?.map((entry, i) => {
                const {qty, unitPrice, amount, min, max} = entry

                return <tr key={i}>
                    <td>{t("confirmationPage.IPCount")} {min} - {max}</td>
                    <td>
                        {qty}
                    </td>
                    <td>
                        {toCurrency(unitPrice)}
                    </td>
                    <td>
                        {toCurrency(amount)}
                    </td>
                </tr>

            })
            }
            </tbody>
            <tfoot>
            <tr>
                <td></td>
                <td></td>
                <td>{t("confirmationPage.Subtotal")}</td>
                <td> {toCurrency(price)}</td>
            </tr>
            {useVAT ? <>
                    <tr>
                        <td></td>
                        <td></td>
                        <td>{t("confirmationPage.VAT")}(19%)</td>
                        <td> {toCurrency(price * 0.19)}</td>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        <td>{t("confirmationPage.AmountDue")}</td>
                        <td> {toCurrency(price + price * 0.19)}</td>
                    </tr>
                </> :
                <>
                    <tr>
                        <td></td>
                        <td></td>
                        <td>{t("confirmationPage.AmountDue")}</td>
                        <td> {toCurrency(price)}</td>
                    </tr>
                </>
            }

            </tfoot>
        </Table>
        <Next/>

    </>
}
