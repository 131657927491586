// @flow

import {withStyles, withTheme} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import React from "react";
import {compose} from "redux";
import type {LoaderType} from "./LoaderType";
import {loaderType} from "./LoaderType";


const styles = theme => ({
    progressInline: {
        margin: "0.31rem auto",
    },
});

type Props = {
    classes: typeof styles,
    theme: any,
    type: LoaderType
}

class Loader extends React.Component<Props> {
    static defaultProps = {
        type: loaderType.FULL,
    };

    render() {
        const {classes} = this.props;

        const className = this.props.type === loaderType.FULL ? classes.progress : classes.progressInline;
        let size = "4.50rem";
        if (this.props.type === loaderType.INLINE) {
            size = "1.50rem";
        }
        else if (this.props.type === loaderType.BUTTON) {
            size = "100%";
        }

        return (
            <div align="center">
                <CircularProgress
                    className={className}
                    size={size}
                    color="primary"/>
            </div>
        );
    }
}

export default compose(withTheme, withStyles(styles))(Loader);

