//@flow

import React from "react";
import {withTranslation} from "react-i18next";
import {Col, Row} from "reactstrap";
import {UserNotification} from "./UserNotification";
import {Headline} from "../../StyledComponents/Font/Font";
import {compose} from "redux";
import ErrorBoundary from "../../Components/ErrorBoundary";


type Props = {
    t: any;
}

class _NotificationPage extends React.Component<Props> {

    render() {
        const {t} = this.props;
        return <Row>
            <Col xs={12} xl={8}>
                <Headline>{t("notificationPage.scanSummary.title")}</Headline>
                <ErrorBoundary>
                    <UserNotification/>
                </ErrorBoundary>

            </Col>
        </Row>;
    }
}

export const NotificationPage = compose(
    withTranslation(),
)(_NotificationPage);
