//@flow


import React from "react"
import styled from "styled-components"
import {Bold, Text} from "../../StyledComponents/Font/Font"
import {Colors} from "../../Theme"


const StyledBox = styled.div`
  background: ${Colors.grey.darker};
  padding: 0.63rem;
  color: white;
`

export const NotificationBox = (props: { text: any }) => {
    return <StyledBox>
        <Text>
            <Bold>
                {props.text}
            </Bold>
        </Text>
    </StyledBox>;
};
