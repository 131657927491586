import {FlexRow, Headline, Paragraph, SubNavbar, SubNavbarItem} from "@greenbone/cloud-component-library"
import {useTranslation} from "react-i18next"
import {useLocation} from "react-router"
import {ReportHead} from "./components/ReportHead"
import {ReportSearch} from "./components/ReportSearch"
import {ReportRouter} from "./ReportRouter"
import {ReportContext, Views} from "./ReportContext"
import React, {useContext} from "react"

import {ReportNavigationUrlProvider} from "./ReportNavigationUrlProvider"
import queryString from "query-string"
import {Code} from "./components/VulnerabilityDetails"
import {LayoutContext} from "../../Layout/LayoutContext"
import * as ReactDOM from "react-dom"
import {ReportError} from "./components/ReportError"
import ErrorBoundary from "../../Components/ErrorBoundary"

export function ContentPortal({children}) {
    const {ref} = useContext(LayoutContext)

    if (ref) {
        return ReactDOM.createPortal(
            children,
            ref
        )
    }
    return null

}


function getParam(location) {
    return queryString.parse(location.search)
}

function Navbar(props) {

    const {navigate, changeView} = useContext(ReportContext)
    const {pathname} = useLocation()
    const {t} = useTranslation()

    const navigateView = (view, path) => {
        changeView(view)

        navigate(path)
    }

    return <SubNavbar>
        <SubNavbarItem active={pathname.includes(ReportNavigationUrlProvider.Dashboard())}
                       onClick={() => navigateView(Views.GRID, ReportNavigationUrlProvider.Dashboard())}>
            {t("reporting.nav.dashboard")}
        </SubNavbarItem>
        <SubNavbarItem active={pathname.includes(ReportNavigationUrlProvider.Grid())}
                       onClick={() => navigateView(Views.GRID, ReportNavigationUrlProvider.Grid())}>
            {t("reporting.nav.grid")}
        </SubNavbarItem>
        <SubNavbarItem active={pathname.includes(ReportNavigationUrlProvider.Table())}
                       onClick={() => navigateView(Views.TABLE_OVERVIEW, ReportNavigationUrlProvider.Table())}>
            {t("reporting.nav.table")}
        </SubNavbarItem>
    </SubNavbar>
}

export function ReportInner() {

    const {pathname} = useLocation()
    const {head, error} = useContext(ReportContext)
    const {t} = useTranslation()

    if (error !== false) {
        return <ReportError status={error}/>
    }

    if (head) {
        if (head.isMigrated) {
            return <>
                <ContentPortal>
                    <Navbar/>
                </ContentPortal>

                <ErrorBoundary>
                    <FlexRow justifyContent={"space-between"}>
                        <ReportHead/>
                        {pathname !== "/ui/gsp/report/dashboard" && <ReportSearch/>}
                    </FlexRow>
                </ErrorBoundary>

                <ErrorBoundary>
                    <ReportRouter/>
                </ErrorBoundary>
            </>
        }


        const debugInformation = {
            reportId: getParam(location)?.report,
            taskId: getParam(location)?.task,
            taskExecutionId: head?.taskExecutionId

        }


        return <>
            <Headline>{t("reportinner.hasNotBeenMigrated")}</Headline>
            <Paragraph>
                {t("reportinner.hasNotBeenMigrated.text")}
            </Paragraph>
            <Code>
                {JSON.stringify(debugInformation, null, "   ")}
            </Code>
        </>
    }

    return null
}


