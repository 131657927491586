import {CircularProgress} from "@material-ui/core"
import React, {useContext} from "react"
import {ThemeContext} from "styled-components"


export function ButtonSpinner({hidden}) {
    if (hidden === true) {
        return null
    }

    const theme = useContext(ThemeContext)

    return (
        <CircularProgress style={{
            width: "1.25rem",
            height: "1.25rem",
            color: theme?.button?.normal?.text
        }}/>
    )
}

