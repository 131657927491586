import React from "react"

import {SubscriptionRestApiClient} from "./service/SubscriptionRestApiClient"
import {GroupRestApiClient} from "../UserRestApiClient"
import {i18n} from "../../../i18n"
import {globalInstanceStorage} from "../../../GlobalSingle"

export const SubscriptionStep = {
    Overview: "Overview",
    Address: "Address",
    Payment: "Payment",
    Confirm: "Confirm",
    Plan: "Plan"

}


export const PaymentOptions = {
    "INVOICE": "INVOICE",
    "LICENCE_KEY": "LICENCE_KEY",
    "CREDIT_CARD": "CREDIT_CARD",
    "PAYPAL": "PAYPAL",
    "NONE": "NONE"
}


export const SubscriptionContext = React.createContext({})

export class SubscriptionProvider extends React.Component {

    state = {
        loading: true,
        subscriptions: null,
        error: null,
        currentStep: SubscriptionStep.Overview,
        finishedSteps: [],
        groupAddress: null,
        companyAddress: null,
        paymentOptions: [],
        pricing: [],
        creditCardSet: false,
        cardInformation: null,
        ipOrderMinimum: 1
    }


    constructor(props) {
        super(props)
        this.apiClient = new SubscriptionRestApiClient()
    }

    componentDidMount(): void {
        this.loadAvailablePaymentMethods()
        this.update()
        this.loadPricing()
    }


    update = (setLoading) => {
        const f = async () => {
            if (setLoading)
                this.setState({loading: true})
            await this.loadCurrentSubscriptionOverview()
            await this.loadCurrentPaymentMethod()
            await this.loadGroupBillingAddress()
            await this.loadCompanyBillingAddress()
            await this.loadCreditCard()
            this.setState({loading: false})
        }

        f()
    }

    loadPricing = async () => {
        try {
            const response = await this.apiClient.getIpPricing(i18n.language)
            this.setState({pricing: response?.prices, ipOrderMinimum: response?.ipOrderMinimum || 1})
            return response?.prices
        } catch (e) {
            this.setState({loading: false, error: e})
        }
    }

    loadCreditCard = async () => {
        return this.apiClient.getCurrentCreditCard()
            .then(response => {
                this.setState({cardInformation: response})
            })
            .catch(e => {
                console.error(e)
            })
    }

    getPriceList = async (count, t = t => t) => {
        let brackets = this.state.pricing
        if (this.state.pricing?.length <= 0) {
            brackets = await this.loadPricing()
        }

        const newList = []

        for (let i = 0; i < brackets.length; i++) {
            const bracket = brackets[i]
            const qty = bracket.maxAmount - bracket.minAmount + 1
            if (count > qty) {
                newList.push({
                    qty,
                    unitPrice: bracket.sellingPrice,
                    amount: bracket.sellingPrice * qty,
                    max: bracket.maxAmount,
                    min: bracket.minAmount
                })
                count = count - qty
            } else if (count <= bracket.maxAmount) {
                newList.push({
                    qty: count,
                    unitPrice: bracket.sellingPrice,
                    amount: bracket.sellingPrice * count,
                    max: bracket.maxAmount,
                    min: bracket.minAmount
                })
                return newList

            }
        }
        return newList

    }


    loadAvailablePaymentMethods = async () => {
        try {
            const response = await this.apiClient.getAvailableSubscriptionMethods()
            for (let i = 0; i < response.length; i++) {
                if (response[i]?.type === PaymentOptions.CREDIT_CARD) {
                    globalInstanceStorage.updateStripePublicKey(response[i]?.publicAccessKey)
                }
            }

            this.setState({paymentOptions: response?.map(option => option.type)})
        } catch (e) {
            this.setState({loading: false, error: e})
        }
    }

    loadCurrentPaymentMethod = async () => {
        try {
            const response = await this.apiClient.getPaymentMethod()
            this.setState({paymentOption: response.paymentOption, creditCardSet: response.creditCardSet})
        } catch (e) {
            this.setState({loading: false, error: e})
        }
    }

    loadGroupBillingAddress = async () => {
        try {
            const apiClient = new GroupRestApiClient()
            const response = await apiClient.getBillingAddress()
            this.setState({groupAddress: response})
        } catch (e) {
            this.setState({loading: false, error: e})
        }
    }

    loadCompanyBillingAddress = async () => {
        try {
            const response = await this.apiClient.getGroupBillingAddress()
            this.setState({companyAddress: response})
        } catch (e) {
            this.setState({loading: false, error: e})
        }
    }


    loadCurrentSubscriptionOverview = async () => {

        try {
            const response = await this.apiClient.getSubscriptionOverview()

            this.setState({
                subscriptions: {...response?.subscriptions?.IP_SCAN},
                billingConfigured: response?.billingConfigured,
                paymentConfigured: response?.paymentConfigured

            })
        } catch (e) {
            this.setState({loading: false, error: e})
        }
    }

    setStep = (step) => {
        if (step === SubscriptionStep.Overview) {
            this.setState({currentStep: step, finishedSteps: []})
        }

        if (step === SubscriptionStep.Plan) {
            this.setState({currentStep: step, finishedSteps: [SubscriptionStep.Plan]})
        }

        if (step === SubscriptionStep.Address) {
            this.setState({currentStep: step, finishedSteps: [SubscriptionStep.Plan]})
        }

        if (step === SubscriptionStep.Payment) {
            this.setState({currentStep: step, finishedSteps: [SubscriptionStep.Address, SubscriptionStep.Plan]})
        }

        if (step === SubscriptionStep.Confirm) {
            this.setState({
                currentStep: step,
                finishedSteps: [SubscriptionStep.Address, SubscriptionStep.Plan, SubscriptionStep.Payment]
            })
        }

    }

    placeOrder = async (intern, extern, instant) => {
        return this.apiClient.subscribe({
            "ipsExternal": extern,
            "ipsInternal": intern,
            "switchImmediate": instant
        })
    }

    calculatePrice = async (intern, extern) => {
        const calc = intern + extern
        return this.apiClient.calculatePrice(calc)
    }

    setCardInformation = (cardInformation) => {
        this.setCardInformation({cardInformation})
    }

    render() {
        return (
            <SubscriptionContext.Provider value={{
                ...this.state,
                setCardInformation: this.setCardInformation,
                update: this.update,
                setStep: this.setStep,
                calculatePrice: this.calculatePrice,
                subscribe: this.placeOrder,
                error: this.state.error,
                getPriceList: this.getPriceList,
                paymentConfigured: this.state.paymentConfigured,
                ipOrderMinimum: this.state.ipOrderMinimum
            }}>
                {this.props.children}
            </SubscriptionContext.Provider>
        )
    }
}
