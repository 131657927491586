import React from "react";
import * as d3 from "d3";
import {DonutFactory} from "../Graph/Donut/DonutFactory";
import {MathHelper} from "../Graph/Helper/MathHelper";

export class ReportSeverity extends React.Component {
    constructor(props) {
        super(props);

        this.dimensions = {
            width: this.props.width,
            height: this.props.height
        };

        this.data = this.props.severity;
        this.radius = Math.min(this.dimensions.width, this.dimensions.height) / 2;
    }

    componentDidMount() {
        this.createBaseGraph();
        this.draw(0);
        this.draw(this.props.severity);
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        this.draw(nextProps.severity);
        return false;
    }

    createBaseGraph() {
        const node = this.node;
        const g = d3.select(node)
            .attr("viewBox", `0 0 ${this.dimensions.width} ${this.dimensions.height}`)
            .append("g")
            .attr("transform", `translate(${this.dimensions.width / 2},${this.dimensions.height / 2})`);

        g.append("text")
            .text(this.data)
            .attr("class", "main")
            .attr("font-size", "3.125rem")
            .attr("dominant-baseline", "central")
            .style("text-anchor", "middle");

        g.append("text")
            .text("Total")
            .attr("font-size", ".875rem")
            .attr("dominant-baseline", "central")
            .attr("transform", `translate(${0},${-50})`)
            .style("text-anchor", "middle");

    }

    draw(severity) {
        const node = this.node;
        const arc = DonutFactory.ArcFactory(this.radius);
        const arcTween = DonutFactory.ArcTweenFactory(arc);

        const scale = d3.scaleLinear()
            .domain([0, 10])
            .range([-105, 105]);

        const data = [{
            startAngle: MathHelper.DegreeToRadian(-105),
            endAngle: MathHelper.DegreeToRadian(scale(severity)),
            color: "#D4003E"
        }, {
            startAngle: MathHelper.DegreeToRadian(scale(severity)),
            endAngle: MathHelper.DegreeToRadian(105),
            color: "#D5D5D5"
        }];

        d3.select(node)
            .select(".main")
            .transition()
            .text(severity);

        const paths = d3.select(node)
            .select("g")
            .selectAll("path")
            .data(data);

        paths.transition().duration(2000).attrTween("d", arcTween);

        paths.enter()
            .append("path")
            .attr("d", arc)
            .style("fill", (_, i) => {
                return data[i].color;
            });


    }

    render() {
        return <svg style={{width: "100%", height: "100%"}} ref={node => this.node = node}>
        </svg>;
    }


}
