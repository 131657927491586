export class MathHelper {

    static Percentage(all, value) {
        if (all === 0 || value === 0)
            return 0


        return ((value / all) * 100)
    }


    static PercentageString(all, value, fraction = 0) {
        if (all === 0 || value === 0)
            return Number(0).toFixed(fraction)
        return ((value / all) * 100).toFixed(fraction)
    }

    static SumUp(list, accessor) {
        let sum = 0;
        list.forEach(item => sum += accessor(item));
        return sum;
    }

    static DegreeToRadian(degrees) {
        return degrees * Math.PI / 180;
    }
}

