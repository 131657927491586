//@flow

import {EntityRestApiClient} from "../Base/EntityRestApiClient";
import {TargetUrlProvider} from "./TargetUrlProvider";


export class TargetRestApiClient extends EntityRestApiClient {

    constructor() {
        super(new TargetUrlProvider());
    }

}
