//@flow

import {EntityRestApiClient} from "../Base/EntityRestApiClient";
import {TaskUrlProvider} from "./TaskUrlProvider";


export class TaskRestApiClient extends EntityRestApiClient {

    constructor() {
        super(new TaskUrlProvider());
    }

}
