//@flow

import {BadRequestError} from "./BadRequestError"
import {ConflictError} from "./ConflictError"
import {FailedDependencyError} from "./FailedDependencyError"
import {ForbiddenError} from "./ForbiddenError"
import {InvalidStatusCodeError} from "./InvalidStatusCodeError"
import {MethodNotAllowedError} from "./MethodNotAllowedError"
import {NoContentError} from "./NoContentError"
import {NotAcceptableError} from "./NotAcceptableError"
import {NotFoundError} from "./NotFoundError"
import {PayloadTooLargeError} from "./PayloadTooLargeError"
import {ServiceNotAvailableError} from "./ServiceNotAvailableError"
import {UnauthorizedError} from "./UnauthorizedError"
import {UnprocessableEntityError} from "./UnprocessableEntityError"


export {
    NotFoundError,
    ServiceNotAvailableError,
    BadRequestError,
    ConflictError,
    UnauthorizedError,
    InvalidStatusCodeError,
    ForbiddenError,
    NoContentError,
    UnprocessableEntityError,
    MethodNotAllowedError,
    NotAcceptableError,
    FailedDependencyError,
    PayloadTooLargeError,
};

export const LoadErrors = [ConflictError, BadRequestError, NotFoundError, ServiceNotAvailableError, ForbiddenError];
export const CreateErrors = [ConflictError, BadRequestError, PayloadTooLargeError, ServiceNotAvailableError, ForbiddenError, UnprocessableEntityError];
export const UpdateErrors = [NotFoundError, ConflictError, BadRequestError, PayloadTooLargeError, ServiceNotAvailableError, ForbiddenError, UnprocessableEntityError];
export const DeleteErrors = [NotFoundError, ServiceNotAvailableError, ForbiddenError, UnprocessableEntityError];
export const DeleteWarnings = [ConflictError, BadRequestError];

export const TaskStartErrors = [ConflictError, BadRequestError, ServiceNotAvailableError, ForbiddenError]
export const TaskStopErrors = [ConflictError, BadRequestError, NotFoundError, ServiceNotAvailableError, ForbiddenError]
