import {useEffect, useReducer, useState} from "react"

/**
 * Executes passed promise and returns the result.
 * Also provides loading and error state.
 * If a Promise based on an http restApiClient is passed the promised needs to be created as an arrow function or .bind(<context>) needs to be called.
 * @param request
 * @returns {[data, error, loading, (value: (((prevState: Object) => Object) | Object)) => void]}
 */
export function useApiRequest(request) {
    const [data, setData] = useState(null)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
            (async () => {
                setLoading(true)
                try {
                    const response = await request()
                    setLoading(false)
                    if (response.entity) {
                        setData(response.entity)
                    } else {
                        setData(response)
                    }

                } catch (e) {
                    setLoading(false)
                    setError(e)
                }
            })()
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [])

    return [data, loading, error, setData]
}


const ACTIONS = {
    DATALOADED: "DATALOADED",
    SETDATA: "SETDATA",
    SETERROR: "SETERROR",
    SETLOADING: "SETLOADING"
}

function reducer(state, {action, data}) {
    switch (action) {
        case ACTIONS.SETLOADING:
            return {...state, loading: true}
        case ACTIONS.DATALOADED:
            return {...state, loading: false, data}
        case ACTIONS.SETDATA:
            return {...state, data}
        case ACTIONS.SETERROR:
            return {...state, loading: false, error: data}
        default:
            return state
    }
}


/**
 *
 * @param request - Promise which returns a response
 * @param responseAccessor -  Callback that allows direct access to part of the response
 * @returns [data, loading, error, setData]
 */
export function useRequest(request, responseAccessor) {
    const [state, dispatch] = useReducer(reducer, {loading: true, data: null, error: null})
    useEffect(() => {
        dispatch({action: ACTIONS.SETLOADING})
        request()
            .then(response => {
                if (responseAccessor)
                    dispatch({action: ACTIONS.DATALOADED, data: responseAccessor(response)})
                else
                    dispatch({action: ACTIONS.DATALOADED, data: response})
            })
            .catch(e => {
                dispatch({action: ACTIONS.SETERROR, data: e})
            })
    }, [])

    function setData(data) {
        if (typeof data === "function") {
            dispatch({action: ACTIONS.SETDATA, data: data(state.data)})
        } else {
            dispatch({action: ACTIONS.SETDATA, data})
        }

    }

    return [state.data, state.loading, state.error, setData]
}