import React from "react"
import {GreyBoxHead, Icon} from "./GreyBox"
import InvoiceIcon from "./assets/invoice.svg?url"
import {FlexRow} from "@greenbone/cloud-component-library"
import {useTranslation} from "react-i18next"

export function Invoice({selected, onSelect}) {
    const {t} = useTranslation()

    return <div>
        <GreyBoxHead selected={selected}
                     onSelect={onSelect}
                     title={t("paymentInfo.paymentOption.invoice")}
                     text={t("billing.invoice.selection.info")}>
            <FlexRow justifyContent={"flex-end"} alignItems={"center"}>
                <Icon style={{height: "0.8em", marginLeft: "0.2rem"}} as={"img"} src={InvoiceIcon}>

                </Icon>
            </FlexRow>

        </GreyBoxHead>
    </div>;
}
