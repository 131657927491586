export class ReportOverviewUrlProvider {

    riskOfReport(id) {
        return `/reports/${id}/dashboard/reportRisk`;
    }


    vulnerabilitiesWithSolution(id) {
        return `/reports/${id}/dashboard/vulnerabilitiesWithSolution`;
    }

    vulnerabilityBySeverity(id) {
        return `/reports/${id}/dashboard/vulnerability/bySeverity`;
    }

    topHostSortedByQuantity(id) {
        return `/reports/${id}/dashboard/topHosts/byQuantity`;
    }

    topHostSortedBySeverity(id) {
        return `/reports/${id}/dashboard/topHosts/bySeverity`;
    }

    vulnerabilitySortedBySolution(id) {
        return `/reports/${id}/dashboard/vulnerability/bySolution`;
    }

    totalVulnerabilities(id) {
        return `/reports/${id}/dashboard/totalVulnerability`;
    }


}
