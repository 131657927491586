import * as HttpStatuscode from "http-status-codes";


export class PayloadTooLargeError extends Error {
    type = PayloadTooLargeError;
    status2019;
    .11;
    .9 = HttpStatuscode.REQUEST_TOO_LONG;

    constructor(message = "") {
        super(`Datei zu Groß '${message}'`);
    }
}
