import React, {Component, useEffect, useState} from "react"
import * as hashquery from "hashquery"
import {useLocation} from "react-router"
import {Snackbar} from "@greenbone/cloud-component-library"
import {BASEURL} from "../constants"
import {ApiClient} from "../services/apiClients/Base/ApiClient"
import {StringLocalStorage} from "../controller/StringLocalStorage"
import {UseIsOpen} from "../../Hooks/useIsOpen"
import {useDataFetchState} from "../../Hooks/useDataFetchState"
import {useTranslation} from "react-i18next"
import {UserSettingsUrlProvider} from "../UrlProvider/UserSettingsUrlProvider"
import {Logger} from "../controller/loggingController"

const PARAMNAME = UserSettingsUrlProvider.hash.productUpdates.param

export const ProductUpdateContext = React.createContext({})


export function ProductUpdateProvider({children}) {
    const {isOpen, setOpen} = UseIsOpen()
    const {productUpdates, isLoading, setData, startLoading, stopLoading} = useDataFetchState("productUpdates")
    const [hasNew, setHasNew] = useState(false)

    let location = useLocation()

    const {t} = useTranslation()

    useEffect(() => {
        try {
            handleHashChange()
        } catch (e) {
            Logger.error(`ProductUpdatesProvider crashed hard. `)
            Logger.exception(e)
        }

    }, [location])

    useEffect(() => {
        try {
            if (Array.isArray(productUpdates)) {
                const hasNewVersion = isNewVersionAvailable(productUpdates, loadLastSeenVersionFromStorage())
                setHasNew(hasNewVersion)
                /*
                 * GCS-648 Disabling regular product update notification
                if (hasNewVersion) {
                    Snackbar.Info(t("productUpdates.hasBeenUpdatedSnackbar"))
                }
                */
            }
        } catch (e) {
            Logger.error(`ProductUpdatesProvider crashed hard. `)
            Logger.exception(e)
        }

    }, [productUpdates])


    const isNewVersionAvailable = (productUpdates, storedLatestVersionString) => {
        if (!storedLatestVersionString) {
            return true
        }
        const latestVersion = getLatestVersion(productUpdates)

        if (!latestVersion) {
            return false
        }

        return latestVersion !== storedLatestVersionString

    }


    useEffect(() => {
        try {
            startLoading(true)
            const url = new URL("/ui/manual/changes.json", BASEURL).toString()
            fetch(url)
                .then(async response => {
                    const body = await response.text()
                    const updates = new ApiClient().parseJsonText(body)

                    const result = updates.map(version => {
                        const groups = version.changed.reduce((groups, item) => {
                            const group = (groups[item.type] || [])
                            group.push(item)
                            groups[item.type] = group
                            return groups

                        }, {})
                        return {...version, groups}
                    })

                    setData(result)

                })
                .catch(e => {
                    stopLoading()
                })
        } catch (e) {
            Logger.error(`ProductUpdatesProvider crashed hard. `)
            Logger.exception(e)
        }

    }, [])

    const getLatestVersion = (productUpdates) => {
        productUpdates.sort((a, b) => (a.version > b.version) ? -1 : 1)
        return [...productUpdates].shift()?.version
    }

    const loadLastSeenVersionFromStorage = () => {
        const storage = new StringLocalStorage("LAST_PRODUCT_UPDATES_VERSION")
        return storage.load()
    }

    const saveLastSeenVersion = () => {
        if (productUpdates) {

            const storage = new StringLocalStorage("LAST_PRODUCT_UPDATES_VERSION")

            const version = getLatestVersion(productUpdates)

            if (version) {
                storage.store(version)
            }
        }
    }

    const handleHashChange = () => {
        if (hashquery.get(PARAMNAME)) {

            setOpen(true)
            setHasNew(false)
        } else {
            setOpen(false)
        }
    }

    const close = () => {
        saveLastSeenVersion()
        setOpen(false)
        hashquery.del(PARAMNAME)
    }

    return <ProductUpdateContext.Provider value={{isOpen, productUpdates, close, hasNew, isLoading}}>
        {children}
    </ProductUpdateContext.Provider>
}


export const withProductUpdateContext = (Component) => {
    return (props) => (
        <ProductUpdateContext.Consumer>
            {(contextProps) => {
                return <Component {...{productUpdateContext: {...contextProps}}} {...props} />
            }}
        </ProductUpdateContext.Consumer>
    )
}
