import React, {useContext} from "react"
import {ReportContext} from "../ReportContext"
import {useTranslation} from "react-i18next"


export function SearchTextIndicator() {
    const {searchTerm, hasBeenFlushed} = useContext(ReportContext)
    const {t} = useTranslation()

    if (searchTerm) {
        if (hasBeenFlushed) {
            return <div style={{lineBreak: "anywhere"}}>{t("general.resultsFor")}{searchTerm}</div>
        } else {
            return <div style={{lineBreak: "anywhere"}}>{t("general.searchingFor")}{searchTerm}</div>
        }
    }
    return null
}
