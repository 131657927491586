import React, {useEffect, useState} from "react"
import {ReportOverviewRestApiClient} from "../../service/ReportOverviewRestApiClient"
import {VulnerabilityBySeverity} from "@greenbone/cloud-chart-library"
import styled from "styled-components"
import {CenteredSpinner} from "../../components/CenteredSpinner";

const GraphStyleAbstraction = styled(VulnerabilityBySeverity)`
  svg {
    width: 100%;
    height: 100%;
  }
`

export function VulnerabilityBySeverityAbstraction({id}) {

    const [data, setData] = useState(null);
    useEffect(() => {
        async function f() {
            const apiClient = new ReportOverviewRestApiClient();
            const response = await apiClient.getVulnerabilityBySeverity(id);

            setData(response.data);
        }

        f();

    }, [id]);


    if (data) {
        return <div>
            <GraphStyleAbstraction
                graphStyle={{maxWidth: "70%", alignSelf: "center", minWidth: "18.125rem", minHeight: "18.125rem"}}
                high={data.high} medium={data.medium} low={data.low}/>
        </div>;

    }


    return <CenteredSpinner height={"18.125rem"}/>

}
