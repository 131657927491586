//@flow

import React from "react"
import {withTranslation} from "react-i18next"
import {connect} from "react-redux"
import {Col, Row} from "reactstrap"
import {bindActionCreators, compose} from "redux"
import {ButtonSpinner} from "../../../Components/Animations/Button/ButtonSpinner"
import {AbortButton, Button, FlexRow, GhostButton, Snackbar} from "@greenbone/cloud-component-library"
import Loader from "../../../Components/Loader/Loader"
import {openErrorSnackbar, openSuccessSnackbar} from "../../../Components/Snackbar/actions"
import {Headline, Paragraph, Subheadline} from "../../../StyledComponents/Font/Font"
import {ScanmanagementUrlProvider} from "../../../UrlProvider/ScanmanagementUrlProvider"
import {TargetRestApiClient} from "../../../services/apiClients/Target/TargetRestApiClient"
import {HostValidationEntry} from "../components/HostValidationEntry"
import {WizardController} from "../controller/WizardController"
import {NavigateFunction} from "react-router";
import {withNavigate} from "../../../../hocs/withNavigate";

type Props = {
    nextStep: EventCallback;
    previousStep: EventCallback;
    cancelWizard: EventCallback;
    pageNumber: number;
    openErrorSnackbar: typeof openErrorSnackbar;
    openSuccessSnackbar: typeof openSuccessSnackbar;
    readOnlyStore: any;
    t: any;
    navigate: NavigateFunction
}

type State = {
    target: ?GSPTarget;
    _loading: boolean;
    _sending: boolean;
}

export class _HostValidation extends React.Component<Props, State> {
    wizardController: WizardController;
    targetRestApiClient: TargetRestApiClient;

    state = {
        target: null,
        _loading: false,
        _sending: false
    };

    constructor(props: Props) {
        super(props);
        this.targetRestApiClient = new TargetRestApiClient();
        this.wizardController = new WizardController(this.props.readOnlyStore);
    }

    componentDidMount() {
        const targetId = this.props.readOnlyStore.Target.targetId;

        if (targetId) {
            this.loadTarget(targetId);
        }
    }

    loadTarget = (targetId: string) => {
        this.setState({_loading: true});
        this.targetRestApiClient.getOne(targetId)
            .then(target => {
                this.setState({
                    target: target
                });
            })
            .finally(() => {
                this.setState({_loading: false});
            });
    };

    create = () => {
        const {readOnlyStore, t} = this.props;

        this.setState({_sending: true});
        this.wizardController.createAll()
            .then(() => {
                this.props.navigate(ScanmanagementUrlProvider.scanmanagement());
                Snackbar.Success(t("wizard.hostValidation.taskCreated", {name: readOnlyStore.Task.name}));
            })
            .catch((error: Error) => {
                Snackbar.Error(error.message);
            })
            .finally(() => {
                this.setState({_sending: false});
            });
    };

    render() {
        const {_loading, target, _sending} = this.state;
        const {cancelWizard, previousStep, t} = this.props;

        if (_loading) {
            return <Loader/>;
        }

        return <React.Fragment>
            <Row style={{marginBottom: "2rem"}}>
                <Col lg={9} md={12}>
                    <Headline>{t("wizard.components.hostValidation.validateHost")}</Headline>
                    <Paragraph>
                        {t("wizard.components.hostValidation.explanation.validateHost")}
                    </Paragraph>
                </Col>
            </Row>

            {target?.isInternal ?
                <Row>
                    <Col lg={9} md={12}>
                        <Subheadline>
                            {t("wizard.components.hostValidation.notRequired.headline")}
                        </Subheadline>

                        <Paragraph>
                            {t("wizard.components.hostValidation.notRequired.text")}
                        </Paragraph>
                    </Col>
                </Row>
                :
                <>
                    <Row style={{marginBottom: "1rem"}}>
                        <Col lg={9} md={12}>
                            <Subheadline>
                                {t("wizard.components.hostValidation.chooseTechnician")}
                            </Subheadline>

                            <Paragraph>
                                {t("wizard.components.hostValidation.explanation.chooseTechnician")}
                            </Paragraph>
                        </Col>
                    </Row>
                    <Row style={{marginBottom: "4rem"}}>
                        <Col lg={9} md={12}>
                            <Row>
                                <Col xs={8}>
                                    {
                                        (target && target.includedHosts) &&
                                        (target.includedHosts).map((host: GSPHost) => {
                                            return <HostValidationEntry
                                                key={host.id}
                                                targetId={target.id}
                                                hostId={host.id}
                                                representation={host.representation}/>;
                                        })
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </>}
            <Row>
                <Col lg={9} md={12}>
                    <FlexRow justifyContent={"space-between"}>
                        <GhostButton onClick={cancelWizard}>{t("common.action.abort")}</GhostButton>

                        <div style={{
                            display: "grid",
                            gridTemplateColumns: "auto auto",
                            gridColumnGap: "2.3rem"
                        }}>
                            <AbortButton onClick={previousStep}>{t("common.action.back")}</AbortButton>
                            <Button onClick={this.create}>
                                <ButtonSpinner hidden={(!_sending)}/>
                                {t("wizard.components.hostValidation.prepareScan")}
                            </Button>
                        </div>
                    </FlexRow>
                </Col>
            </Row>
        </React.Fragment>;
    }
}

function mapDispatchToProps(dispatch) {
    let actions = bindActionCreators({
        openErrorSnackbar: openErrorSnackbar,
        openSuccessSnackbar: openSuccessSnackbar
    }, dispatch);
    return {...actions, dispatch};
}

function mapStateToProps(state) {
    return {};
}

export const HostValidation = compose(
    withTranslation(),
    withNavigate,
    connect(mapStateToProps, mapDispatchToProps)
)(_HostValidation);
