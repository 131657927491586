import * as React from "react";
const SvgArrowUp = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      enableBackground: "new 0 0 24 24",
    }}
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M.5 18.9c-.1 0-.2 0-.3-.1-.2-.2-.2-.6 0-.8L11.3 5.5l.1-.1c.2-.2.4-.3.7-.3.3 0 .6.1.8.3l11 12.6c.2.2.2.5 0 .7-.1.1-.2.1-.3.1-.1 0-.3-.1-.4-.2L12 6.1.9 18.7c-.1.1-.2.2-.4.2z"
    />
  </svg>
);
export default SvgArrowUp;
