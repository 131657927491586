//@flow

import React from "react";
import {AlertBarType} from "../Layout/components/InformationBarTypes";
import HttpStatusCode from "http-status-codes";
import {Trans} from "react-i18next";
import {ForbiddenError} from "../services/Exceptions";

//$FlowFixMe
export const AlertContext = React.createContext({});

type State = {
    messages: Array<{ message: string | Trans, type: typeof AlertBarType }>
}


export class AlertProvider extends React.Component<any, State> {
    state = {
        messages: []
    };

    addMessage = (message: string | typeof Trans, type: any) => {
        if (message.type) {
            if (message.type !== Trans) {
                throw new Error("Passed illegal message type, allowed are <Trans/> and string");
            }
        }

        this.setState(prevState => {
            if (prevState.messages.find(m => m.message === message && m.type === type)) {
                return;
            }


            if (message.props) {
                if (message.props.i18nKey) {
                    if (prevState.messages.find(m => {
                        if (m.message.props) {
                            if (m.message.props.i18nKey) {
                                //$FlowFixMe
                                return m.message.props.i18nKey === message.props.i18nKey && m.type === type;
                            }
                        }
                        return false;
                    })) {
                        return;
                    }
                }
            }


            return {messages: [...prevState.messages, {message, type}]};
        });
    };

    handleError = (error: any) => {
        if (error.type) {
            if (error.type === ForbiddenError) {
                this.handleStatus(503);
                return;
            }
        }
        if (error.httpStatus) {
            this.handleStatus(error.httpStatus());
            return;
        }


        this.addMessage(error.toString(), AlertBarType.Error);
    };

    handleStatus = (status: number, additionalText?: string) => {
        switch (status) {
            case HttpStatusCode.NOT_FOUND:
                this.addMessage(<Trans i18nKey={"alertContext.notFound"}></Trans>, AlertBarType.Error);
                break;
            case HttpStatusCode.UNPROCESSABLE_ENTITY:
                this.addMessage(
                    <Trans i18nKey={"alertContext.unprocessableEntity"}></Trans>, AlertBarType.Error);
                break;
            case HttpStatusCode.FORBIDDEN:
                this.addMessage(<Trans i18nKey={"alertContext.forbidden"}></Trans>, AlertBarType.Error);
                break;
            case HttpStatusCode.SERVICE_UNAVAILABLE:
                this.addMessage(<Trans i18nKey={"alertContext.serviceUnavailable"}></Trans>, AlertBarType.Error);
                break;
            default:
                this.addMessage(
                    <Trans i18nKey={"alertContext.unknownStatusCode"}></Trans>, AlertBarType.Error);
        }
    };

    render() {

        return <AlertContext.Provider value={
            {
                ...this.state,
                addMessage: this.addMessage,
                handleStatus: this.handleStatus,
                handleError: this.handleError
            }
        }>{this.props.children}</AlertContext.Provider>;
    }
}





