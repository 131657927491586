//@flow

import * as HttpStatuscode from "http-status-codes"
import {InvalidStatusCodeError} from "../../Exceptions"
import {RemoteResourceUrlProvider} from "./RemoteResourceUrlProvider"
import {globalInstanceStorage} from "../../../../GlobalSingle"
import {SCANCONFIG_SORT_ORDER_MAP} from "../../../constants"


class RemoteResourceRestApiClient {
    httpClient: any
    urlProvider: RemoteResourceUrlProvider

    constructor() {
        this.urlProvider = new RemoteResourceUrlProvider()
        this.httpClient = globalInstanceStorage.getFetchClient()
    }

    async getGateways(): Promise<any> {
        const response = await this.httpClient.get(this.urlProvider.getAllGateways())
        if (response.status === HttpStatuscode.OK) {
            return await JSON.parse(await response.text())
        }
        throw new InvalidStatusCodeError(response.status)
    }

    async getScanConfigurations(): Promise<any> {
        const response = await this.httpClient.get(this.urlProvider.getAllScanConfigurations())
        if (response.status === HttpStatuscode.OK) {
            const scanConfigs = await JSON.parse(await response.text())

            scanConfigs.sort(function (a, b) {
                return (SCANCONFIG_SORT_ORDER_MAP[a.id] - SCANCONFIG_SORT_ORDER_MAP[b.id]) || a.name.localeCompare(b.name)
            })

            return scanConfigs
        }
        throw new InvalidStatusCodeError(response.status)
    }

    async getAliveTests(): Promise<any> {

        const response = await this.httpClient.get(this.urlProvider.getAllAliveTests())
        if (response.status === HttpStatuscode.OK) {
            return await JSON.parse(await response.text())
        }
        throw new InvalidStatusCodeError(response.status)
    }

    async getPortLists(): Promise<any> {
        const response = await this.httpClient.get(this.urlProvider.getAllPortLists())
        if (response.status === HttpStatuscode.OK) {
            return await JSON.parse(await response.text())
        }
        throw new InvalidStatusCodeError(response.status)
    }

}

export {RemoteResourceRestApiClient}
