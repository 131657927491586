import {Snackbar as SnackbarLib, SnackbarType} from "./SnackbarLib"
import "./Snackbar.css"

export class Snackbar {
    static show(text) {
        Snackbar.custom({text})
    }

    static custom(params) {
        SnackbarLib.show({pos: "top-center", actionTextColor: "white", actionText: "Close", ...params})
    }

    static Error(text) {
        SnackbarLib.show({
            type: SnackbarType.Error,
            pos: "top-center",
            backgroundColor: "#d32f2f",
            actionTextColor: "#d2d2d2",
            actionText: "Close",
            text
        });
    }

    static Warning(text) {
        SnackbarLib.show({
            type: SnackbarType.Warning,
            pos: "top-center",
            backgroundColor: "#ffa000",
            actionTextColor: "#d2d2d2",
            actionText: "Close",
            text
        });
    }

    static Info(text) {
        SnackbarLib.show({
            type: SnackbarType.Info,
            pos: "top-center",
            backgroundColor: "#1976d2",
            actionTextColor: "#d2d2d2",
            actionText: "Close",
            text
        });
    }

    static Success(text) {
        SnackbarLib.show({
            type: SnackbarType.Success,
            pos: "top-center",
            backgroundColor: "#43a047",
            actionTextColor: "#d2d2d2",
            actionText: "Close",
            text
        });
    }
}
