// @flow

import {Grid, withStyles, withTheme} from "@material-ui/core"
import {FlexRow} from "@greenbone/cloud-component-library"
import React from "react"
import {withTranslation} from "react-i18next"
import {compose} from "redux"
import styled from "styled-components"
import {TargetFormMode as TargetType} from "../Form/TargetFormMode"
import OpenInNew from "../assets/open_in_new.svg"


const styles = theme => ({
    ul: {
        listStyle: "none",
        marginLeft: "0",
        paddingLeft: "0"
    }
})

type Props = {
    classes: typeof styles,
    theme: any;
    includedHosts: Array<GSPHost>;
    excludedHosts: Array<GSPHost>;
    t: any;
}

const Icon = styled.div`
    width: 1rem;
    height: 1rem;
    position: relative;

    svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`

const Hostname = ({hostname}) => {


    return <FlexRow alignItems={"center"}>
        <div>
            {hostname}
        </div>
        <a rel={"noopener noreferrer"} href={`https://${hostname}`} target={"_blank"}>
            <Icon>
                <OpenInNew/>
            </Icon>
        </a>
    </FlexRow>
}

function Address({host, targetType}) {
    if (targetType === TargetType.HOSTNAME) {
        return <Hostname hostname={host.representation}/>
    }
    return host.representation
}

class _HostList extends React.Component<Props> {

    render() {
        const {theme, includedHosts, excludedHosts, classes, t, targetType} = this.props
        return (
            <div>
                <Grid container spacing={0} justifyContent="center">
                    <Grid item xs={theme.grid.width.oneThird} md={6}>
                        <div style={{fontWeight: "bold"}}>
                            {t("target.details.host")}
                        </div>
                        {
                            includedHosts && <ul className={classes.ul}>
                                {includedHosts.map((host: GSPHost) =>
                                    <li key={host.id} style={{paddingLeft: "0px"}}>
                                        <Address host={host} targetType={targetType}/>
                                    </li>
                                )}
                            </ul>
                        }
                    </Grid>
                    {targetType === "IP" ? <Grid item xs={theme.grid.width.oneThird} md={6}>
                        <div style={{fontWeight: "bold"}}>
                            {t("target.details.excludedHost")}
                        </div>
                        {
                            excludedHosts && <ul className={classes.ul}>
                                {excludedHosts.map((host) =>
                                    <li key={host.id}>
                                        {host.representation}
                                    </li>
                                )}
                            </ul>
                        }
                    </Grid> : <Grid item xs={theme.grid.width.oneThird} md={6}></Grid>}

                </Grid>
            </div>
        )
    }

}

export const HostList = compose(
    withStyles(styles),
    withTheme,
    withTranslation()
)(_HostList)
