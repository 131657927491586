import React, {useContext} from "react"
import {v4 as uuidv4} from "uuid"
import {InformationBarType} from "./InformationBarTypes"

export const BarTypes = {
    INFO: "INFO"
}

export const InformationBarContext = React.createContext()

export class InformationBarProvider extends React.Component {
    state = {
        bars: []
    }

    close = (index) => {
        this.setState(prevState => {
            let bars = prevState.bars
            bars.splice(index, 1)

            return {bars}
        })
    }

    showBar = (type, component, action, actionText) => {

        if (!(this.state.bars.find(bar => bar.component === component))) {
            const uuid = uuidv4()
            this.setState(prevState => ({
                bars: [...prevState.bars, {type, component, close: this.close, action, actionText, uuid}]
            }))
            return uuid
        }

        return null
    }


    showInfo(component) {
        return this.showBar(InformationBarType.Info, component)
    }


    showWarning = (component, action, actionText) => {
        return this.showBar(InformationBarType.Warning, component, action, actionText)
    }


    showError(component) {
        return this.showBar(InformationBarType.Error, component)
    }


    showSuccess(component) {
        return this.showBar(InformationBarType.Success, component)
    }

    closeByUuid = (uuid) => {
        if (uuid) {
            const index = this.state.bars.findIndex(bar => bar.uuid === uuid)
            this.close(index)
        }

    }


    render() {
        return <InformationBarContext.Provider value={
            {
                bars: this.state.bars,
                showBar: this.showBar,
                info: this.showInfo,
                warning: this.showWarning,
                success: this.showSuccess,
                error: this.showError,
                closeByUuid: this.closeByUuid
            }
        }
        >
            {this.props.children}
        </InformationBarContext.Provider>
    }
}


export function useInformationBar() {
    const {bars, showBar, info, warning, success, error, closeByUuid} = useContext(InformationBarContext)

    return {bars, showBar, info, warning, success, error, closeByUuid}
}
