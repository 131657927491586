import React from "react"
import {FlexRow} from "@greenbone/cloud-component-library"
import {FirstButton, SecondButton} from "../../../StyledComponents/Button/SwitchButton"
import {useTranslation} from "react-i18next"

export function RegTypeSwitch({type, onChange}) {

    const {t} = useTranslation()


    return <FlexRow style={{marginTop: "2rem"}} justifyContent={"start"}>
        <FirstButton
            active={type === "WEB"}
            onClick={(event) => {
                event.preventDefault()
                onChange("WEB")
            }}>
            {t("gateway.regTypeSwitch.basic")}
        </FirstButton>
        <SecondButton
            active={type === "CLI"}
            onClick={(event) => {
                event.preventDefault()
                onChange("CLI")
            }}>
            {t("gateway.regTypeSwitch.advanced")}
        </SecondButton>
    </FlexRow>

}

