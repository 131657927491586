//@flow

import React from "react";
import {NavLink, Route} from "react-router-dom";
import Switcher from "../Components/Router/Components/Switcher";
import {ClearPersistance} from "../areas/Develop/components/Clear";
import {EnvironmentDebug} from "../areas/Develop/components/EnvironmentDebug";
import {ErrorPerpetrator} from "../areas/Develop/components/ErrorPerpetrator";
import {JWTDevelop} from "../areas/Develop/components/JWTDevelop";


const Home = (props: any) => {
    return (
        <div>
            Wählen Sie einen Punkt aus der Navigation
        </div>
    );
};

export class DeveloperLayout extends React.Component<any, any> {

    render() {
        return (
            <div>
                <ul>
                    <li>
                        <NavLink to="clear">Clear Persistence</NavLink>
                    </li>
                    <li>
                        <NavLink to="jwt">JWT</NavLink>
                    </li>
                    <li>
                        <NavLink to="error">Error</NavLink>
                    </li>
                    <li>
                        <NavLink to="env">Environment</NavLink>
                    </li>
                </ul>

                <h1>Achtung!</h1>
                <p>Sie haben einen Bereich betreten der nur zur Analyse und Fehlerbhebung gedacht ist. Sollte man Ihnen
                    nicht expliziet mitgeteilt haben diesen Bereich zu besuchen ist er mit vorsicht zu genießen.</p>
                <div>
                    <Switcher>
                        <Route path="/env" Component={EnvironmentDebug}/>
                        <Route path="/clear" Component={ClearPersistance}/>
                        <Route path="/jwt" Component={JWTDevelop}/>
                        <Route path="/error" Component={ErrorPerpetrator}/>
                        <Route path="/" Component={Home}/>
                    </Switcher>
                </div>
            </div>
        );
    }
}
