import React from 'react'
import {useNavigate} from 'react-router'

export const withNavigate = (Component: any) => {
    return (props: any) => {
        const navigate = useNavigate()

        return <Component navigate={navigate} {...props} />;
    };
};
