//@flow

interface ICONSTANTS {
    OPEN_WELCOME_DIALOG: string;
    CLOSE_WELCOME_DIALOG: string;
}

export const CONSTANTS: ICONSTANTS = {
    OPEN_WELCOME_DIALOG: "OPEN_WELCOME_DIALOG",
    CLOSE_WELCOME_DIALOG: "CLOSE_WELCOME_DIALOG",

};

export const openWelcomeDialog = () => ({
    type: CONSTANTS.OPEN_WELCOME_DIALOG,
});

export const closeWelcomeDialog = () => ({
    type: CONSTANTS.CLOSE_WELCOME_DIALOG,
});
