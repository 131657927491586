const OPERATINGSYSTEM = {
    WINDOWS: "WINDOWS",
    WINDOWS_OLD: "WINDOWS_OLD",
    UBUNTU: "UBUNTU",
    DEBIAN: "DEBIAN",
    CENTOS: "CENTOS",
    HP: "HP",
    REDHAT: "REDHAT",
    LINUX: "LINUX",
    SUSE: "SUSE",
    HUAWEI: "HUAWEI",
    FREEBSD: "FREEBSD",
    IBM: "IBM",
    KYOCERA: "KYOCERA",
    SONICWALL: "SONICWALL",
    NETAPP: "NETAPP",
    FORTI: "FORTI",
    PALOALTO: "PALOALTO",
    CITRIX: "CITRIX",
    CISCO: "CISCO",
    SYNOLOGY: "SYNOLOGY",
    VMWARE: "VMWARE",
    MACOS: "MACOS",
    IOS: "IOS",
    APPLETV: "APPLETV",
    OPENBSD: "OPENBSD",
    GREENBONE: "GREENBONE"


}


const RegexOSMap = new Map()
RegexOSMap.set(/^(?=.*\bWindows\b)(?=.*\bServer\b)(?=.*\b2019\b).*$/i, OPERATINGSYSTEM.WINDOWS)
RegexOSMap.set(/^(?=.*\bWindows\b)(?=.*\bServer\b)(?=.*\b2012\b).*$/i, OPERATINGSYSTEM.WINDOWS)
RegexOSMap.set(/^(?=.*\bWindows\b)(?=.*\bServer\b)(?=.*\b2016\b).*$/i, OPERATINGSYSTEM.WINDOWS)
RegexOSMap.set(/^(?=.*\bWindows\b)(?=.*\b10\b).*$/i, OPERATINGSYSTEM.WINDOWS)
RegexOSMap.set(/^(?=.*\bWindows\b)(?=.*\b8.1\b).*$/i, OPERATINGSYSTEM.WINDOWS)
RegexOSMap.set(/^(?=.*\bWindows\b)(?=.*\bWindows\b).*$/i, OPERATINGSYSTEM.WINDOWS)
RegexOSMap.set(/^(?=.*\bMicrosoft\b).*$/i, OPERATINGSYSTEM.WINDOWS)
RegexOSMap.set(/^(?=.*\bWindows\b)(?=.*\bServer\b)(?=.*\b2008\b).*$/i, OPERATINGSYSTEM.WINDOWS_OLD)

RegexOSMap.set(/^(?=.*\bUbuntu\b).*$/i, OPERATINGSYSTEM.UBUNTU)
RegexOSMap.set(/^(?=.*\bDebian\b).*$/i, OPERATINGSYSTEM.DEBIAN)
RegexOSMap.set(/^(?=.*\bCentOS\b).*$/i, OPERATINGSYSTEM.CENTOS)
RegexOSMap.set(/^(?=.*\bHP\b).*$/i, OPERATINGSYSTEM.HP)
RegexOSMap.set(/^(?=.*\bRedHat\b).*$/i, OPERATINGSYSTEM.REDHAT)
RegexOSMap.set(/^(?=.*\bSuse\b).*$/i, OPERATINGSYSTEM.SUSE)
RegexOSMap.set(/^(?=.*\bHuawei\b).*$/i, OPERATINGSYSTEM.HUAWEI)
RegexOSMap.set(/^(?=.*\bFreeBSD\b).*$/i, OPERATINGSYSTEM.FREEBSD)
RegexOSMap.set(/^(?=.*\bLinux\b).*$/i, OPERATINGSYSTEM.LINUX)
RegexOSMap.set(/^(?=.*\bIBM\b).*$/i, OPERATINGSYSTEM.IBM)
RegexOSMap.set(/^(?=.*\bSonicWall\b).*$/i, OPERATINGSYSTEM.SONICWALL)
RegexOSMap.set(/^(?=.*\bKyocera\b).*$/i, OPERATINGSYSTEM.KYOCERA)
RegexOSMap.set(/^(?=.*\bNetApp\b).*$/i, OPERATINGSYSTEM.NETAPP)
RegexOSMap.set(/^(?=.*\bPalo Alto\b).*$/i, OPERATINGSYSTEM.PALOALTO)
RegexOSMap.set(/^(?=.*\bCitrix\b).*$/i, OPERATINGSYSTEM.CITRIX)
RegexOSMap.set(/^(?=.*\bCisco\b).*$/i, OPERATINGSYSTEM.CISCO)
RegexOSMap.set(/^(?=.*\bSynology\b).*$/i, OPERATINGSYSTEM.SYNOLOGY)
RegexOSMap.set(/^(?=.*\bVMware\b).*$/i, OPERATINGSYSTEM.VMWARE)
RegexOSMap.set(/^(?=.*\bApple Mac\b).*$/i, OPERATINGSYSTEM.MACOS)
RegexOSMap.set(/^(?=.*\bApple iOS\b).*$/i, OPERATINGSYSTEM.IOS)
RegexOSMap.set(/^(?=.*\bApple TV\b).*$/i, OPERATINGSYSTEM.APPLETV)
RegexOSMap.set(/^(?=.*\bOpenBSD\b).*$/i, OPERATINGSYSTEM.OPENBSD)
RegexOSMap.set(/^(?=.*\bGreenbone\b).*$/i, OPERATINGSYSTEM.GREENBONE)
RegexOSMap.set(/^(?=.*Forti).*$/i, OPERATINGSYSTEM.FORTI)


function stringToOperatingSystem(input) {
    for (const [key, value] of RegexOSMap) {
        // console.log(key.exec(input))
        if (key.exec(input) !== null) {
            return value
        }
    }
    return null

}

export {
    RegexOSMap,
    OPERATINGSYSTEM,
    stringToOperatingSystem
}

// module.exports =  {
//     RegexOSMap,
//     OPERATINGSYSTEM,
//     stringToOperatingSystem
// }

