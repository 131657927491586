//@flow

import React from "react";
import {Route} from "react-router-dom";
import ErrorBoundary from "../../Components/ErrorBoundary";
import {Switcher} from "../../Components/Router";
import {HostValidation} from "./components/HostValidation";


type Props = {
    history: any;
}

export class HostRouter extends React.Component<Props, {}> {

    render() {

        return (

            <Switcher>
                <Route path="/target/:id/host_validation"
                       element={<ErrorBoundary><HostValidation/></ErrorBoundary>}/>
            </Switcher>

        );
    }
}
