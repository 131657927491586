import React, {useState, useEffect, useCallback} from "react";
import {useTheme} from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        flexShrink: 0,
        marginLeft: "2rem"
    }
}));

export function PageableTablePaginationActions({page, count, totalPages, onChangePage}) {
    const classes = useStyles();
    const theme = useTheme();

    const [requestedPage, setRequestedPage] = useState(page);

    useEffect(() => {
        const timer = setTimeout(() => {
            onChangePage(requestedPage);
        }, 300);

        return () => clearTimeout(timer);
    }, [requestedPage, onChangePage]);

    const handlePageChange = useCallback((newPage) => {
        setRequestedPage(newPage);
    }, []);

    return (
        <div className={classes.root}>
            <IconButton
                onClick={() => handlePageChange(0)}
                disabled={page === 0}
                aria-label="first page"
                size="large">
                {theme.direction === "rtl" ? <LastPageIcon/> : <FirstPageIcon/>}
            </IconButton>
            <IconButton
                onClick={() => handlePageChange(Math.max(0, page - 1))}
                disabled={page === 0}
                aria-label="previous page"
                size="large">
                {theme.direction === "rtl" ? <KeyboardArrowRight/> : <KeyboardArrowLeft/>}
            </IconButton>
            <IconButton
                onClick={() => handlePageChange(page + 1)}
                disabled={page >= totalPages - 1}
                aria-label="next page"
                size="large">
                {theme.direction === "rtl" ? <KeyboardArrowLeft/> : <KeyboardArrowRight/>}
            </IconButton>
            <IconButton
                onClick={() => handlePageChange(totalPages - 1)}
                disabled={page >= totalPages - 1}
                aria-label="last page"
                size="large">
                {theme.direction === "rtl" ? <FirstPageIcon/> : <LastPageIcon/>}
            </IconButton>
        </div>
    );
}
