import {RestApiClient} from "@greenbone/cloud-component-library"
import {globalInstanceStorage} from "../../../../GlobalSingle"


function ErrorHandler(e) {
    throw e
}

export class TeamRestApiClient {
    constructor(apiClient = new RestApiClient(
        globalInstanceStorage.getFetchClient(),
        ErrorHandler
    ))
    {
        this.apiClient = apiClient
    }

    async delete() {
        return await this.apiClient.get("/usermanagement/group/delete-request")
    }

    async deleteConfirm(token) {
        return await this.apiClient.delete(`/usermanagement/group?token=${token}`)
    }

    async getMembers() {
        return await this.apiClient.get(`/usermanagement/group/users`)
    }

    async getInvites() {
        return await this.apiClient.get(`/usermanagement/group/invites`)
    }

    async postInvite(email) {
        return await this.apiClient.post(`/usermanagement/group/invites`, {email})
    }

    async deleteInvite(id) {
        return await this.apiClient.delete(`/usermanagement/group/invites/${id}`)
    }

    async getResendInvite(id) {
        return await this.apiClient.get(`/usermanagement/group/invites/${id}/resend`)
    }

    async putActivateUser(id) {
        return await this.apiClient.put(`/usermanagement/group/users/${id}/activate`)
    }

    async putDeactivateUser(id) {
        return await this.apiClient.put(`/usermanagement/group/users/${id}/deactivate`)
    }

    async makeMainUser(userId) {
        return await this.apiClient.put(`/usermanagement/group/users/${userId}/main-user`)
    }
}
