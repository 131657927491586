import React from "react"
import {createGlobalStyle} from "styled-components"
import {BASEURL} from "../../constants"
import {useTranslation} from "react-i18next"
import {useSearchParams} from "react-router-dom";

const Global = createGlobalStyle`
    .override-layout-container {
        padding: 0;
        margin: 0;
        height: 100%;
    }

    .version-view {
        display: none;
    }
`

export function ManualPage(props) {
    const {i18n} = useTranslation()

    const [searchParams] = useSearchParams()
    const jump = searchParams.get('jump')

    return <>
        <Global/>
        <iframe style={{
            width: "100%",
            height: "calc(100% - 0.5rem)",
            border: "none"
        }}
                title={"Manual"} src={`${BASEURL}/ui/manual/${i18n.language}/${jump ? jump : ""}`}
        ></iframe>
    </>
}
