import {LocalStorage} from "../LocalStorage"
import {isStringBlankOrEmpty} from "@greenbone/cloud-validation-library"
import {actions} from "react-table"


export const useSortCache = hooks => {
    hooks.stateReducers.push(reducer)
}

function reducer(state, action, previousState, instance) {
    const {cacheKey, columns} = instance


    if (isStringBlankOrEmpty(cacheKey)) {
        return
    }


    if (action.type === actions.init) {
        function loadState() {
            const localStorage = new LocalStorage(`${cacheKey}-sortBy`)
            return localStorage.loadState()
        }

        const loadedState = loadState()
        if (!loadedState) {
            return
        }

        const {sortBy} = loadedState
        if (!Array.isArray(sortBy) && sortBy?.length <= 0) {
            return
        }

        const existingColumns = sortBy.filter(sort => columns.map(col => {
            if (col?.id) {
                return col.id
            }
            return col.accessor
        }).includes(sort.id))
        if (existingColumns.length <= 0) {
            return
        }

        return {...state, sortBy: existingColumns}
    }


    if (!actions.toggleSortBy) {
        return
    }

    if (action.type === actions.toggleSortBy) {
        function saveState(ob) {
            const localStorage = new LocalStorage(`${cacheKey}-sortBy`)
            localStorage.saveState(ob)
        }

        saveState({sortBy: state.sortBy})
    }
}
