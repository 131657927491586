//@flow

import {InputLabel} from "@material-ui/core"
import React from "react"
import Select from "react-select"
import styled from "styled-components"


const Fieldgroup = styled.div``

type Props = {
    options: {
        data: Array<any>,
        valueFieldName: string,
        labelFieldName: string
    };
    placeholder?: string;
    value: string | number | null | any;
    onChange: any;
    name: string;
    label: string;
    menuPlacement?: any;
}

export class SearchableDropdown extends React.Component<Props> {
    get convertedData(): Array<any> {
        const {options} = this.props;
        if (!options.data) {
            return [];
        }

        let optionList = [];
        for (let i = 0; i < options.data.length; i++) {
            optionList.push({
                value: options.data[i][options.valueFieldName],
                label: options.data[i][options.labelFieldName],
            });
        }
        return optionList;
    }

    handleChange = (value: any) => {
        this.props.onChange({
            target: {
                value: value.value,
                name: this.props.name,
            },
        });
    };

    getItemByValue(value: ?string | number) {
        if (this.convertedData.length === 0) {
            return null;
        }
        for (let i = 0; i < this.convertedData.length; i++) {
            if (this.convertedData[i].value === value) {
                return this.convertedData[i];
            }
        }
    }

    render() {
        return (
            <Fieldgroup>
                <InputLabel htmlFor="controlled-open-select">{this.props.label}</InputLabel>
                <Select
                    menuPlacement={this.props.menuPlacement || "auto"}
                    value={this.getItemByValue(this.props.value)}
                    onChange={this.handleChange}
                    placeholder={this.props.placeholder}
                    options={this.convertedData}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: "2px",
                    })}
                    noOptionsMessage={() => {
                        return "Keine passendes Element";
                    }}
                />
            </Fieldgroup>
        );
    }
}
