//@flow

import {i18n} from "../../i18n";
import {BritishDateTimeFormat, GermanDateTimeFormat} from "./DateTimeFormats";
import {SIODateTime} from "./SIODateTime";


export class DateTimeFormatter {
    static DATE_TIME_FORMAT = "dd.MM.yyyy HH:mm 'Uhr'";

    static toDateTimeString(dateTime?: ?SIODateTime): string {
        let dateTimeFormat = GermanDateTimeFormat;
        switch (i18n.language) {
            case "de": {
                dateTimeFormat = GermanDateTimeFormat;
                break;
            }


            case "en":
            default: {
                dateTimeFormat = BritishDateTimeFormat;
                break;
            }
        }

        if (dateTime && (dateTime.isValid)) {
            return dateTime.toFormat(dateTimeFormat);
        }
        return " - ";
    }
}
