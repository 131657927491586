import React from "react";
import styled from "styled-components";
import {FlexRow} from "../Flex";
import {Paragraph} from "../Font";

export const CardContent = styled.div`
  padding: 1.5rem;
`;

export const CardHead = styled.div`
  padding: 1.5rem;
  height: 21rem;
`;

export const CardMedia = styled(FlexRow)`
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  font-weight: bold;
  font-size: 1.2rem;
`;

export const Card = styled.div`
  box-shadow: 0 .5em 1em -.125em rgba(10,10,10,.1),0 0 0 1px rgba(10,10,10,.02);
  max-width: 100%;
  
  ${Paragraph}{
    margin: 0;
  }
`;
