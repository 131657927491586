import {
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TablePagination,
    TableRow,
    withStyles,
    withTheme
} from "@material-ui/core"
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment"
import Search from "@material-ui/icons/Search"
import React from "react"
import {withTranslation} from "react-i18next"
import {compose} from "redux"
import {SearchController} from "./SearchController"
import {Subheadline} from "../../StyledComponents/Font/Font"
import {TextField} from "../Input"
import Loader from "../Loader/Loader"
import SortableTableHead from "./SortableTableHead"
import TablePaginationActions from "./TablePaginationActions"
import {LocalStorage} from "../../areas/Task/components/Table/LocalStorage"
import styled from "styled-components"


const styles = theme => ({
    root: {
        height: "100%",
        marginTop: theme.spacing(3)
    },
    center: {
        textAlign: "center"
    },
    tableTopBar: {
        display: "flex",
        justifyContent: "flex-end",
        padding: "0.63rem"
    }
})

type Props = {
    classes: typeof styles;
    theme: any;
    elements: Array<any>;
    children: any;
    elementGenerator: (any, ? number) => any;
    columnData: Array<ColumnData>;
    headline: string;
    orderBy: string;
    order: Direction;
    showSearchField: boolean;
    searchOn: Array<string>;
    actions?: any;
    t: any;
}

type State = {
    _exception?: any;
    _loading: boolean,
    filterText: string,
    rowsPerPage: number,
    page: number,
    orderBy: string,
    order: Direction,
    elements: Array<any>
}

const ROW_HEIGHT: number = 3.13

const TableRowOverwrite = styled(TableRow)`
    &:hover {
        background: none !important;
        box-shadow: none !important;
    }
`

class _EnhancedTable extends React.Component <Props, State> {

    static defaultProps = {
        showSearchField: true
    }
    timer: any

    constructor(props: Props) {
        super(props)
        this.state = {
            rowsPerPage: 10,
            page: 0,
            orderBy: props.orderBy || "",
            order: props.order || "asc",
            elements: props.elements,
            filterText: "",
            _exception: null,
            _loading: false
        }
    }

    static getDerivedStateFromProps(props, state) {
        return {
            elements: props.elements
        }
    }

    componentDidMount() {
        const cacheKey = this.props.cacheKey
        if (cacheKey) {
            const localStorage = new LocalStorage(`${cacheKey}-orderBy`)
            const loadedState = localStorage.loadState()

            if (loadedState) {
                const {orderBy} = loadedState
                /*
                orderBy: string,
                order: Direction,
                 */

                this.setState({
                    orderBy: orderBy.orderBy,
                    order: orderBy.order
                })
            }

            const pageStorage = new LocalStorage(`${cacheKey}-rowsPerPage`)
            const pageState = pageStorage.loadState()

            if (pageState) {
                const {rowsPerPage} = pageState
                if (rowsPerPage) {
                    this.setState({rowsPerPage})
                }
            }

        }

        this.setState({_loading: false})
    }

    handleChangePage = (event, page) => {
        this.setState({page})
    }

    handleChangeRowsPerPage = event => {
        const cacheKey = this.props.cacheKey
        const val = event.target.value

        if (cacheKey) {
            const localStorage = new LocalStorage(`${cacheKey}-rowsPerPage`)
            localStorage.saveState({rowsPerPage: val})
        }

        this.setState({rowsPerPage: val})
    }

    handleRequestSort = (event, column) => {
        const orderBy = column.id
        let order = "desc"

        if (this.state.orderBy === column.id && this.state.order === "desc") {
            order = "asc"
        }

        const newOrderState = {
            order: order,
            orderBy: orderBy
        }

        const cacheKey = this.props.cacheKey
        if (cacheKey) {
            const localStorage = new LocalStorage(`${cacheKey}-orderBy`)

            localStorage.saveState({orderBy: newOrderState})
        }


        this.setState(newOrderState)
    }

    handleSearchInputChange = (event) => {
        const text = event.target.value

        clearTimeout(this.timer)

        this.timer = setTimeout(() => {
            this.setState({filterText: text})
        }, 250)

    }

    render() {
        const {classes, searchOn, showSearchField, columnData, t} = this.props
        const {rowsPerPage, page, orderBy, order, filterText, elements} = this.state

        if (this.state._exception) {
            throw this.state._exception
        }

        if (this.state._loading) {
            return <Loader/>
        }

        const filteredElements = new SearchController(elements)
            .search(filterText, searchOn || [])
            .orderBy(orderBy, order)

        const filteredElementsCount = filteredElements.results.length;
        // page is 0-indexed, so always subtract 1 from the calculation for maxPage
        const maxPage = Math.ceil(filteredElementsCount / this.state.rowsPerPage - 1)
        // the valid range for page is 0 <= page <= maxPage
        const curPage = Math.max(
            0,
            Math.min(
                page,
                maxPage
            )
        )

        const elementsForCurrentPage = filteredElements
            .paging(curPage + 1, rowsPerPage)
            .results

        let emptyRowCount = 0

        if (rowsPerPage > elementsForCurrentPage.length)
            emptyRowCount = 0


        if (rowsPerPage > elementsForCurrentPage.length && page > 0) {
            emptyRowCount = rowsPerPage - elementsForCurrentPage.length
        }

        let TableElement = Table
        if (this.props.tableElement) {
            TableElement = this.props.tableElement
        }


        return <>
            <div className={classes.tableTopBar} style={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid rgba(224, 224, 224, 1)",
                alignItems: "center"
            }}>
                <div>
                    <Subheadline style={{marginBottom: "0px"}}>
                        {this.props.headline}
                    </Subheadline>

                    <div>
                        {this.props.actions}
                    </div>
                </div>

                {showSearchField && <TextField onChange={this.handleSearchInputChange}
                                               placeholder={t("common.search")}
                                               InputProps={{
                                                   startAdornment: (
                                                       <InputAdornment position="start">
                                                           <Search/>
                                                       </InputAdornment>
                                                   )
                                               }}
                />}
            </div>

            <TableElement>
                <SortableTableHead
                    onRequestSort={this.handleRequestSort}
                    order={this.state.order}
                    orderBy={this.state.orderBy}
                    columnData={this.props.columnData}
                />
                <TableBody>
                    {elementsForCurrentPage.map((entity, index) => (this.props.elementGenerator(entity, index)))}
                    {emptyRowCount > 0 && (
                        <TableRowOverwrite style={{height: `calc(${ROW_HEIGHT}rem * ${emptyRowCount})`}}>
                            <TableCell colSpan={columnData.length}/>
                        </TableRowOverwrite>
                    )}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            count={filteredElements.results.length}
                            rowsPerPage={rowsPerPage}
                            page={curPage}
                            onPageChange={this.handleChangePage}
                            onRowsPerPageChange={this.handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                            labelRowsPerPage={t("components.table.enhanced.linePerPage")}
                            labelDisplayedRows={({
                                                     from,
                                                     to,
                                                     count
                                                 }) => `${from}-${to} ${t("components.table.enhanced.of")} ${count}`}
                        />
                    </TableRow>
                </TableFooter>
            </TableElement>
        </>
    }
}

export const EnhancedTable = compose(withStyles(styles),
    withTranslation(),
    withTheme
)(_EnhancedTable)
