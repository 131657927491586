// @flow

import * as React from "react";
import {withTranslation} from "react-i18next";
import {Col, Row} from "reactstrap";
import {Text} from "../../../StyledComponents/Font/Font";
import {Colors} from "../../../Theme";
import {TabBox} from "./TabBox";
import {compose} from "redux";


type Props = {
    title: string;
    aliveTest: string;
    portList: string;
    ipCount: number;
    active?: boolean;
    onClick?: any;
    style?: any;
    t: any;
}

const KeyValue = (props: { name: string, value: string }) => (
    <div>
        <div style={{color: Colors.grey.light}}>{props.name}</div>
        <div style={{color: Colors.grey.dark, textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}}
             title={props.value}>{props.value}</div>
    </div>
);

const _IpCountBox = (props: { ipCount: number, t: any }) => {
    const {ipCount, t} = props;
    return (

        <div style={{display: "flex", alignItems: "baseline"}}>
            <div style={{
                marginRight: "0.70rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: Colors.grey.lighter,
                width: "1.88rem",
                height: "1.88rem",
                color: Colors.grey.dark,
            }} title={props.ipCount}>
                {ipCount > 999 ? "*" : ipCount}
            </div>
            <Text style={{color: Colors.grey.dark}}>{t("wizard.target.tabBox.hosts")}</Text>
        </div>
    );
};

const IpCountBox = withTranslation()(_IpCountBox);

class _TargetTabBox extends React.Component<Props> {
    render() {
        const {t} = this.props;
        const {title, active, onClick, style, aliveTest, ipCount, portList} = this.props;

        return <TabBox style={style} title={title} active={active} onClick={onClick}>
            <Row style={{marginBottom: "0.63rem"}}>
                <Col lg={12} xl={6}>
                    <KeyValue name={t("common.aliveTest")} value={aliveTest}/>
                </Col>
                <Col lg={12} xl={6}>
                    <KeyValue name={t("common.portList")} value={portList}/>
                </Col>
            </Row>
            <Row style={{marginBottom: "10px"}}>
                <Col>
                    <IpCountBox ipCount={ipCount}/>
                </Col>
            </Row>
        </TabBox>;
    }
}

export const TargetTabBox = compose(
    withTranslation(),
)(_TargetTabBox);
