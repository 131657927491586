//@flow

import {withStyles} from "@material-ui/core";
import React from "react";
import {connect} from "react-redux";
import {bindActionCreators, compose} from "redux";
import {FullWidthDialog} from "../FullWidthDialog/components/FullWidthDialog";
import {closeAlertDialog, openAlertDialog} from "./actions";


const styles = theme => ({
    alertDialog: {
        zIndex: 1350,
    },
});

type Props = {
    classes: typeof styles,
    open_dialog: typeof openAlertDialog,
    close_dialog: typeof closeAlertDialog,
    open: boolean,
    title: string,
    text: string,
    callback: any;
}

class AlertDialog extends React.Component<Props> {

    render() {
        return (<FullWidthDialog open={this.props.open} title={this.props.title} message={this.props.text}
                                 callback={this.props.callback} onClose={this.props.close_dialog}/>);
    }
}

function mapDispatchToProps(dispatch: any) {
    const actions = bindActionCreators({close_dialog: closeAlertDialog, open_dialog: openAlertDialog}, dispatch);
    return {...actions, dispatch};
}

function mapStateToProps(state) {
    return {
        open: state.alertDialog.Open,
        title: state.alertDialog.Title,
        text: state.alertDialog.Text,
        callback: state.alertDialog.Callback,
    };
}

export default compose(
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps),
)(AlertDialog);

