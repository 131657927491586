import React from "react"
import {FlexRow, Radio} from "@greenbone/cloud-component-library"
import {Col, Row} from "reactstrap"
import {CardPaymentInformation} from "./CardPaymentInformation"

import {Elements} from "@stripe/react-stripe-js"
import {globalInstanceStorage} from "../../../../../GlobalSingle"


export function CreditCard({selected, onSelect, payFormRef, setCardHolder, style}) {
    const stripePromise = globalInstanceStorage.getStripe()

    return <div style={style}>
        <Elements stripe={stripePromise}>
            <div>
                <Row>
                    <Col>
                        <FlexRow fullWidth>
                            <Radio style={{visibility: "hidden"}}/>
                            <CardPaymentInformation setCardHolder={setCardHolder} payFormRef={payFormRef}/>
                        </FlexRow>
                    </Col>
                </Row>
            </div>
        </Elements>
    </div>
}
