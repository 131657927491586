import Other from "../assets/os/other.svg"
import React from "react"
import {OSIconMapper} from "./OSIconMapper"

const {stringToOperatingSystem} = require("./RegexOSMap")


export function ResolveOSIcon({os}) {
    if (!os) {
        return <Other/>
    }

    const operatingSystemType = stringToOperatingSystem(os)

    if (operatingSystemType) {
        const icon = OSIconMapper(operatingSystemType)


        switch (typeof icon) {
            case "string":
                return <div dangerouslySetInnerHTML={{__html: icon}}></div>

            case "object": {
                const Comp = icon
                return <Comp/>
            }


        }

    }


    return <Other/>
}
