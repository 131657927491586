//@flow

import * as HttpStatuscode from "http-status-codes"
import {Logger} from "../../../controller/loggingController"
import {BadRequestError, InvalidStatusCodeError, NotFoundError} from "../../Exceptions"
import {HostValidationUrlProvider} from "./HostValidationUrlProvider"
import i18next from "i18next"
import {globalInstanceStorage} from "../../../../GlobalSingle"
import {FetchClient} from "@greenbone/cloud-component-library"


class HostValidationRestApiClient {
    httpJsonClient: FetchClient
    urlPorvider: HostValidationUrlProvider

    constructor(apiClient: any = globalInstanceStorage.getFetchClient()) {
        this.httpJsonClient = apiClient
        this.urlPorvider = new HostValidationUrlProvider()
    }

    async resendHostValidationEmail(hostId: string): Promise<void> {
        const response = await this.httpJsonClient.put(this.urlPorvider.hostValidationEmailResend(hostId))
        if (response.status === HttpStatuscode.NO_CONTENT) {
            return
        }

        throw new InvalidStatusCodeError(response.status)
    }

    async resetHostValidation(hostId: string): Promise<void> {
        const response = await this.httpJsonClient.put(this.urlPorvider.hostValidationReset(hostId))
        if (response.status === HttpStatuscode.NO_CONTENT) {
            return
        }
        throw new InvalidStatusCodeError(response.status)
    }

    async getHostValidation(hostId: string): Promise<any> {
        const response = await this.httpJsonClient.get(this.urlPorvider.hostValidation(hostId))
        if (response.status === HttpStatuscode.OK) {
            return await response.json()
        }

        throw new InvalidStatusCodeError(response.status)
    }

    async getHostValidationStatus(hostId: string): Promise<any> {
        const response = await this.httpJsonClient.get(this.urlPorvider.hostValidationStatus(hostId))
        if (response.status === HttpStatuscode.OK) {
            return response.text().then(text => {
                return text.replace(/"/g, "")
            })
        }
        throw new InvalidStatusCodeError(response.status)
    }

    /**
     * Get host validation contacts for a host
     * @param hostId
     * @returns {Promise[]}
     */
    async getHostValidationContacts(hostId: string): Promise<any> {
        Logger.debug(`Get host validation contacts. hostId:'${hostId}'`)

        const response = await this.httpJsonClient.get(this.urlPorvider.hostValidationContacts(hostId))
        if (response.status === HttpStatuscode.OK) {
            return await response.json()
        }
        throw new InvalidStatusCodeError(response.status)
    }

    /**
     * Let the user validate the host himself
     * @param hostId
     */
    async setHostValidationUser(hostId: string): Promise<any> {

        Logger.debug(`ValidateSelf. hostId:'${hostId}'`)

        const response = await this.httpJsonClient.put(
            this.urlPorvider.hostValidationUser(hostId)
        )
        if (response.status === HttpStatuscode.NO_CONTENT || response.status === HttpStatuscode.OK) {
            return true
        } else if (response.status === HttpStatuscode.NOT_FOUND) {
            throw new NotFoundError(i18next.t("common.hostValidation.updateFailed.notFound"))
        } else if (response.status === HttpStatuscode.BAD_REQUEST) {
            throw new BadRequestError(i18next.t("common.hostValidation.updateFailed.invalidRequest"))
        }
        throw new InvalidStatusCodeError(response.status)
    }


    /**
     * Set host validation via contact
     * @param hostId
     * @param contactId
     */
    async setHostValidationContact(hostId: string, contactId: string): Promise<any> {

        Logger.debug(`Save contact. hostId:'${hostId}' contactId:'${contactId}'`)

        const response = await this.httpJsonClient.put(
            this.urlPorvider.hostValidationContactForHost(hostId, contactId)
        )
        if (response.status === HttpStatuscode.NO_CONTENT || response.status === HttpStatuscode.OK) {
            return true
        } else if (response.status === HttpStatuscode.NOT_FOUND) {
            throw new NotFoundError(i18next.t("common.hostValidation.updateFailed.notFound"))
        } else if (response.status === HttpStatuscode.BAD_REQUEST) {
            throw new BadRequestError(i18next.t("common.hostValidation.updateFailed.invalidRequest"))
        }
        throw new InvalidStatusCodeError(response.status)
    }

    /**
     * Set host validation via admin
     * @param hostId
     */
    async setHostValidationAdminContact(hostId: string): Promise<void> {

        Logger.debug(`Save admin contact. hostId:${hostId}`)

        const response = await this.httpJsonClient.put(
            this.urlPorvider.hostValidationAdminContactForHost(hostId)
        )
        if (response.status === HttpStatuscode.NO_CONTENT || response.status === HttpStatuscode.OK) {
            return
        } else if (response.status === HttpStatuscode.NOT_FOUND) {
            throw new NotFoundError(i18next.t("common.hostValidation.adminUpdateFailed.notFound"))
        } else if (response.status === HttpStatuscode.BAD_REQUEST) {
            throw new BadRequestError(i18next.t("common.hostValidation.adminUpdateFailed.invalidRequest"))
        }
        throw new InvalidStatusCodeError(response.status)
    }

    /**
     * Start host validation contact request
     * @param targetId
     * @param hostId
     */
    async startHostValidationContactRequest(targetId: string, hostId: string): Promise<any> {
        Logger.debug(`Start host validation contact request. targetID:'${targetId}' hostID:'${hostId}'`)

        const response = await this.httpJsonClient.post(
            this.urlPorvider.hostValidationContactRequest(targetId, hostId)
        )
        if (response.status === HttpStatuscode.OK) {
            return
        }
        throw new InvalidStatusCodeError(response.status)
    }

}

export {HostValidationRestApiClient}
