import {addObjectToList, deleteObjectFromList, Snackbar, updateObjectInList} from "@greenbone/cloud-component-library"
import React from "react"
import {TeamRestApiClient} from "../service/TeamRestApiClient"


export const TeamContext = React.createContext({})

export class TeamProvider extends React.Component {
    state = {
        members: [],
        invites: []
    }

    constructor(props) {
        super(props)
        this.teamRestApiClient = new TeamRestApiClient()
    }

    componentDidMount() {
        const memberRequest = this.teamRestApiClient.getMembers()
        const inviteRequest = this.teamRestApiClient.getInvites()

        Promise.all([memberRequest, inviteRequest])
            .then(([members, invites]) => {
                this.setState({members, invites})
            })
            .catch(e => {
                Snackbar.Error(e.toString())
            })
    }

    async sendInvite(email) {
        return this.teamRestApiClient.postInvite(email)
            .then(response => {
                if (response) {
                    this.setState({invites: addObjectToList(this.state.invites, response)})
                }
                return response
            })
    }

    async deleteInvite(id) {
        return await this.teamRestApiClient.deleteInvite(id)
            .then(() => {
                this.setState(prevState => {
                    return {invites: deleteObjectFromList(this.state.invites, {id}, "id")}
                })
            })
    }

    async resendInvite(id) {
        return await this.teamRestApiClient.getResendInvite(id)
            .then((response) => {
                if (response) {
                    this.setState(prevState => {
                        return {invites: updateObjectInList(prevState.invites, response, "id")}
                    })
                }
            })
    }

    async activateUser(id) {
        return await this.teamRestApiClient.putActivateUser(id)
            .then(response => {
                if (response) {
                    this.setState(prevState => {
                        return {members: updateObjectInList(prevState.members, response, "id")}
                    })
                }
            })
    }

    async deactivateUser(id) {
        return await this.teamRestApiClient.putDeactivateUser(id)
            .then(response => {
                if (response) {
                    this.setState(prevState => {
                        return {members: updateObjectInList(prevState.members, response, "id")}
                    })
                }
            })
    }

    async makeMainUser(userId) {
        return await this.teamRestApiClient.makeMainUser(userId)
            .then(response => {
                this.setState(prevState => {
                    return {
                        members: prevState.members.map(member => {
                            member.mainUser = member.userId === userId
                            return member
                        })
                    }
                })
            })
    }

    render() {
        return <TeamContext.Provider
            value={{
                ...this.state,
                sendInvite: this.sendInvite.bind(this),
                deleteInvite: this.deleteInvite.bind(this),
                resendInvite: this.resendInvite.bind(this),
                activateUser: this.activateUser.bind(this),
                deactivateUser: this.deactivateUser.bind(this),
                makeMainUser: this.makeMainUser.bind(this)
            }}>
            {this.props.children}
        </TeamContext.Provider>
    }
}
