import React from "react"
import {Col, Row} from "reactstrap"
import {FixPercentageWithTheFollowingSolutions} from "./graphs/FixPercentageWithTheFollowingSolutions"
import {useReportUrlParamParser} from "../ReportUrlParamReader"
import {VulnerabilityBySeverityAbstraction} from "./graphs/VulnerabilitiyBySeverity"
import {TopHosts} from "./graphs/TopHosts"
import {ReportSeverityAbstraction} from "./graphs/ReportSeverityAbstraction"
import {BoxHeader} from "../components/BoxHeader"
import {ReportOverview} from "./graphs/ReportOverview"
import {TotalVulnerabilities} from "./graphs/TotalVulnerabilities"
import {useTranslation} from "react-i18next"
import ErrorBoundary from "../../../Components/ErrorBoundary"

export function ReportDashboard(props) {

    const {t} = useTranslation()
    const {report} = useReportUrlParamParser()

    return <>
        <Row>
            <Col xs={2}>
                <BoxHeader>
                    {t("report.dashboard.vulnerabilitiesTotal")}

                </BoxHeader>
                <ErrorBoundary>
                    <TotalVulnerabilities id={report}/>
                </ErrorBoundary>
            </Col>
            <Col>
                <BoxHeader>
                    {t("report.dashboard.groupedByTypeOfSolution")}

                </BoxHeader>
                <ErrorBoundary>
                    <ReportOverview id={report}/>
                </ErrorBoundary>
            </Col>
        </Row>
        <Row>
            <Col xs={4}>
                <BoxHeader>
                    {t("report.dashboard.groupedBySeverity")}

                </BoxHeader>
                <ErrorBoundary>
                    <VulnerabilityBySeverityAbstraction id={report}/>
                </ErrorBoundary>

            </Col>
            <Col>
                <BoxHeader>
                    {t("report.dashboard.fixWithSolution")}

                </BoxHeader>
                <ErrorBoundary>
                    <FixPercentageWithTheFollowingSolutions id={report}/>
                </ErrorBoundary>
            </Col>
        </Row>
        <Row>
            <Col xs={4}>
                <BoxHeader>
                    {t("report.dashboard.reportRiskLevel")}

                </BoxHeader>
                <ErrorBoundary>
                    <ReportSeverityAbstraction id={report}/>
                </ErrorBoundary>
            </Col>
            <Col>

                <ErrorBoundary>
                    <TopHosts id={report}/>
                </ErrorBoundary>

            </Col>
        </Row>
    </>;
}
