//@flow
import React, {useEffect, useState} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {Container} from "reactstrap";
import {bindActionCreators, compose} from "redux";
import {InfoText} from "../../../Components/Basic/InfoText";
import {ListContainer} from "../../../Components/Basic/ListContainer";
import {Button, ErrorBoundary} from "@greenbone/cloud-component-library";
import AdditionalEmailForm from "./AdditionalEmailForm";
import {AdditionalEmailList} from "./AdditionalEmailList";
import {ModalSlideIn} from "../../../Components/Dialogs/SlideIn/ModalSlideIn";
import {openAlertDialog} from "../../../Components/Dialogs/AlertDialog/actions";
import {Headline2} from "../../../Components/Basic/Headlines";
import {JWT} from "../../../Helper/JWT";
import {Validator} from "../../../Helper/Validator";

type Props = {
    t: any;
    additionalEmail: string;
    setAdditionalEmail: Function;
    updateNotificationConfig: Function;
    valid: ?string;
    setValid: Function;
};

const AdditionalEmails = ({t, additionalEmail, setAdditionalEmail, updateNotificationConfig, valid, setValid}: Props) => {
    const [isSlideInOpen, setIsSlideInOpen] = useState(false);
    const [selectedEmail, setSelectedEmail] = useState(null);
    const [emails, setEmails] = useState([]);
    const [domainMismatch, setDomainMismatch] = useState(false);
    const jwtToken = new JWT();
    const currentUserEmail = jwtToken.getEmail();

    useEffect(() => {
        if (additionalEmail && additionalEmail.length > 0) {
            const emailObjects = Array.of({
                id: additionalEmail,
                email: additionalEmail
            })
            setDomainMismatch(Validator.isEmailDomainMismatch(additionalEmail, currentUserEmail))
            setEmails(emailObjects)
        } else {
            setEmails([])
        }
    }, [additionalEmail]);

    const openEmailSlideIn = () => {
        setIsSlideInOpen(true);
        setSelectedEmail(null);
        setValid(null);
    };

    const openEditEmailSlideIn = (email: string) => {
        setValid(null)
        setIsSlideInOpen(true);
        setSelectedEmail(email);
    };

    const handleCloseSlide = (event) => {
        if (event) {
            event.preventDefault();
        }
        setIsSlideInOpen(false);
        setValid(null)
    };

    const handleUpdateOrCreate = (event: any, emailValue: string) => {
        if (emailValue) {
            setAdditionalEmail(emailValue.trim().toLowerCase(), () => {
                let result = updateNotificationConfig();
                if (result === true) {
                    handleCloseSlide(event);
                }
            });
        }
    };

    const handleDeleteEmail = (emailToDelete: string) => {
        setAdditionalEmail(null, () => {
            updateNotificationConfig();
        });
    };

    return (
        <div>
            <Headline2>{t("notificationPage.additionalEmails.title")}</Headline2>
            <InfoText headline={""}>
                <p>{t("notificationPage.additionalEmails.description")}</p>
            </InfoText>
            <ListContainer>
                <ErrorBoundary>
                    <AdditionalEmailList emails={emails} actions={emails.length === 0 ? (
                        <Button style={{margin: 0}} onClick={openEmailSlideIn}>
                            {t("notificationPage.additionalEmails.action.createNew")}
                        </Button>
                    ) : null} onUpdateEntry={openEditEmailSlideIn} onDeleteEntry={handleDeleteEmail} domainMismatch={domainMismatch}/>
                </ErrorBoundary>
                <ModalSlideIn isOpen={isSlideInOpen} key="emailSlideIn">
                    <Container fluid style={{maxWidth: "830px", minWidth: "830px", padding: "4rem"}}>
                        <ErrorBoundary>
                            <AdditionalEmailForm onClose={handleCloseSlide} email={selectedEmail}
                                                 onUpdateOrCreate={handleUpdateOrCreate} valid={valid} currentUserEmail={currentUserEmail}/>
                        </ErrorBoundary>
                    </Container>
                </ModalSlideIn>
            </ListContainer>
        </div>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    ...bindActionCreators({openAlertDialog}, dispatch),
    dispatch
});

const mapStateToProps = (state) => ({});

export default compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps)
)(AdditionalEmails);
