//@flow

import {saveAs} from "file-saver"
import React from "react"
import {withTranslation} from "react-i18next"
import {connect} from "react-redux"
import ReactTooltip from "react-tooltip"
import {bindActionCreators, compose} from "redux"
import Download from "../../../../assets/icons/download-bottom.svg"
import Loader from "../../../Components/Loader/Loader"
import {openErrorSnackbar} from "../../../Components/Snackbar/actions"
import {IconButton, Snackbar} from "@greenbone/cloud-component-library"
import {FileReportRestApiClient} from "../../../services/apiClients/Report/FileReportRestApiClient"
import {TaskRestApiClient} from "../../../services/apiClients/Task/TaskRestApiClient"
import {SIODateTime} from "../../../controller/SIODateTime"

type Props = {
    reportId: GSPSmallReport;
    openErrorSnackbar: typeof openErrorSnackbar;
    executive?: boolean;
    disabled?: boolean;
    latestReportDate: ?SIODateTime;
    taskId: any;
    t: any;
    isDownloadable: boolean;
    lastTaskExecutionId: ?number;

}

type State = {
    _loading: boolean;
    _exception: ?Error;
    reportFile: any;
}

const TooltipIconButton = ({text, id, disabled}) => {
    return <>
        <IconButton
            data-for={id}
            data-tip={text}
            disabled={disabled}
        >
            <Download />
        </IconButton>
        <ReactTooltip id={id}/>
    </>
}

class _ReportDownloadLink extends React.Component<Props, State> {

    fileReportRestApiClient = new FileReportRestApiClient()
    taskRestApiClient = new TaskRestApiClient()

    state = {
        _loading: false,
        _exception: null,
        reportFile: null
    }

    downloadReport = () => {
        this.setState({_loading: true})

        const {executive, lastTaskExecutionId, t} = this.props

        let scanEnd = this.props.latestReportDate
        if (!scanEnd) {
            scanEnd = SIODateTime.local()
        }


        const reportRequest = executive ? this.fileReportRestApiClient.getExecutivePDFReport(lastTaskExecutionId) : this.fileReportRestApiClient.getTechnicalPDFReport(lastTaskExecutionId)
        reportRequest
            .then(async reportBlob => {
                const task = await this.taskRestApiClient.getOne(this.props.taskId)
                const date = scanEnd ? scanEnd.toDateString() : "-"

                saveAs(reportBlob, `${date}_${executive ? "executive" : "technical"}_${task.name}_GSP.pdf`)
                this.setState({_loading: false})

            })
            .catch(_exception => {
                Snackbar.Error(t("components.reportDownloadLink.canNotDownLoadReport"))
                this.setState({_loading: false, _exception})
            })

    }

    handleClick = (event: any) => {
        event.preventDefault()
        this.downloadReport()
    }

    render() {
        const {_loading, _exception} = this.state
        const {reportId, t, isDownloadable} = this.props

        if (_loading) {
            return <Loader type={"INLINE"}/>
        }


        if (_exception) {
            return (
                <>
                    <TooltipIconButton
                        id={"scanmanagement.reportTemporaryUnavailableTryAgainLater"}
                        text={t("scanmanagement.reportTemporaryUnavailableTryAgainLater")}
                        disabled={true}/>
                </>
            )
        }

        if (!reportId || !isDownloadable) {
            return <>
                <TooltipIconButton
                    id={"scanmanagement.reportTemporaryUnavailable"}
                    text={t("scanmanagement.reportTemporaryUnavailable")}
                    disabled={true}/>
            </>
        }

        if (this.props.disabled) {
            return <>
                <TooltipIconButton
                    id={"scanmanagement.reportTemporaryUnavailableStillRunning"}
                    text={t("scanmanagement.reportTemporaryUnavailableStillRunning")}
                    disabled={true}/>

            </>
        }

        return (
            <IconButton onClick={this.handleClick}>
                <Download />
            </IconButton>
        )

    }
}

function mapDispatchToProps(dispatch: any) {
    let actions = bindActionCreators({
            openErrorSnackbar: openErrorSnackbar
        }, dispatch
    )
    return {...actions, dispatch}
}

function mapStateToProps(state) {
    return {}
}

export const ReportDownloadLink = compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps)
)(_ReportDownloadLink)

