import React from 'react'
import {ManagedSecuritySetting} from "./components/ManagedSecuritySetting"
import {Col, Row} from "reactstrap"

export function GroupSecurityPage() {
    return <>

        <Row>
            <Col xs={7}>
                <ManagedSecuritySetting/>
            </Col>
        </Row>
    </>
}
