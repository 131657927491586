//@flow

import {Badge, IconButton, Tooltip, withStyles} from "@material-ui/core"
import {Assignment, Info} from "@material-ui/icons"
import React from "react"
import {useTranslation, withTranslation} from "react-i18next"
import {connect} from "react-redux"
import {bindActionCreators, compose} from "redux"
import ActionElements from "../../../Components/ActionElements"
import StateLabel from "../../../Components/Label/StateLabel"
import {StyledTableCell} from "../../../Components/Table/StyledTableCell"
import {StyledTableRow} from "../../../Components/Table/StyledTableRow"
import {EntityUrlProvider} from "../../../UrlProvider/EntityUrlProvider"
import {ValidationStatus} from "../../Hostvalidation/types/Host"
import {TargetDetails} from "./TargetDetails"
import styled from "styled-components"


const StyledBadge = styled(Badge)`
  .MuiBadge-colorPrimary {
    background-color: ${props => props.theme.button.normal.background};
  }

`

const styles = theme => ({
    root: {
        height: "100%",
        marginTop: theme.spacing(3)
    },
    actions: {
        width: "11.25rem"
    },
    actionButton: {
        padding: "0px"
    }
})

type Props = {
    target: GSPTarget;
    informationPopOver: any;
    onTargetDelete: any;
    onTargetUpdate: any;
    classes: typeof styles;
    history: any;
    t: any;
}

const HostVerified = (props: { target: GSPTarget, includedHosts: Array<GSPHost> }) => {
    const areAllVerified: boolean = isEveryHostVerified(props.includedHosts)
    const isAnyRejected: boolean = isAnyHostRejected(props.includedHosts)
    const {t} = useTranslation()

    let stateColor = "primaryBlueLight2"
    let stateLabel = t("targetEntry.verificationPending")

    if (isAnyRejected) {
        stateColor = "stateColorRed1"
        stateLabel = t("targetEntry.verificationRejected")
    } else if (areAllVerified) {
        stateColor = "primaryBlueLight1"
        stateLabel = t("targetEntry.verified")
    }

    return (
        <StyledTableCell>
            {(!props.target.isInternal) &&
            <StateLabel colorState={stateColor}>
                {stateLabel}
            </StateLabel>
            }
        </StyledTableCell>
    )
}

const _ValidationInformation = (props: { includedHosts: Array<GSPHost>, elementCount: number, onclick: ()=>void, t: any }) => {
    const areAllVerified: boolean = isEveryHostVerified(props.includedHosts)
    const isAnyRejected: boolean = isAnyHostRejected(props.includedHosts)
    const {t} = props

    let title = t("targetEntry.validationPending")
    if (isAnyRejected) {
        title = t("targetEntry.validationRejected")
    }

    return <span>{!areAllVerified && (
        <Tooltip title={title}>
            <IconButton
                onClick={() => props.onclick()}
                style={{display: "inline-block"}}>
                <StyledBadge badgeContent={props.elementCount} color="primary" overlap="rectangular">
                    <Assignment/>
                </StyledBadge>
            </IconButton>
        </Tooltip>
    )}
    </span>
}

const ValidationInformation = compose(
    withTranslation()
)(_ValidationInformation)

type State = {
    _isDetailsOpen: boolean,
}

export function isEveryHostVerified(hosts: Array<GSPHost>): boolean {
    return (hosts || []).find(h => h.validationStatus !== ValidationStatus.APPROVED) === undefined
}

export function isAnyHostRejected(hosts: Array<GSPHost>): boolean {
    return (hosts || []).find(h => h.validationStatus === ValidationStatus.REJECTED) !== undefined
}

export function getPortList(target: GSPTarget, portLists: Array<GSPPortList>): string {
    let portList = portLists.find(p => p.id === target.portList.id)
    return portList !== undefined ? portList.name : ""
}

class _TargetEntry extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props)
        this.state = {
            _isDetailsOpen: false
        }
    }

    actionGenerator = () => {
        return (
            <IconButton onClick={() => {
                this.setState({_isDetailsOpen: true})
            }}>
                <Info/>
            </IconButton>
        )
    }

    handleDetailClose = () => {
        this.setState({
            _isDetailsOpen: false
        })
    }

    render() {
        const {target, classes, t} = this.props

        const unverifiedHostCount = (target.includedHosts || [])
            .filter((host) => host.validationStatus !== ValidationStatus.APPROVED &&
                host.validationStatus !== ValidationStatus.REJECTED)
            .length

        return (
            <StyledTableRow key={target.id}>
                <StyledTableCell>{target.name}</StyledTableCell>
                <StyledTableCell>{target.portList.name}</StyledTableCell>

                <HostVerified target={target} includedHosts={target.includedHosts}/>

                <StyledTableCell>{target.ipAddressAmount}</StyledTableCell>
                <StyledTableCell className={classes.actions}>
                    <ActionElements
                        entity={target}
                        editRoute={`${EntityUrlProvider.target()}/edit/` + (target.id || "")}
                        onUpdateEntry={() => this.props.onTargetUpdate(target.id, target.isInternal)}
                        deleteEntity={() => this.props.onTargetDelete(target.id)}
                        confirmMessage={t("targetEntry.deleteMessage", {name: target.name})}
                        additionalActionGenerator={this.actionGenerator}
                    >
                        <ValidationInformation
                            onclick={() => this.props.history.push(EntityUrlProvider.targetHostValidation(this.props.target.id))}
                            includedHosts={target.includedHosts}
                            elementCount={unverifiedHostCount}
                        />
                    </ActionElements>
                </StyledTableCell>
                <TargetDetails
                    _isOpen={this.state._isDetailsOpen}
                    target={this.props.target}
                    onClose={this.handleDetailClose}
                />
            </StyledTableRow>

        )
    }
}

function mapDispatchToProps(dispatch: any) {
    let actions = bindActionCreators({}, dispatch)
    return {...actions, dispatch}
}

function mapStateToProps(state) {
    return {}
}

export const TargetEntry = compose(
    withStyles(styles),
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps)
)(_TargetEntry)
