export class SubscriptionUrlProvider {

    getIpPricing(language){
        return `/billing/user/pricing/${language}`
    }

    getSubscriptionOverview() {
        return `/billing/user/subscriptions/overview`;
    }

    getCurrentSubscriptions() {
        return `/billing/user/subscriptions`;
    }

    getAllSubscriptions() {
        return `/billing/user/subscriptions/all`;
    }

    getPaymentMethod() {
        return `/billing/user/payment/payment_option`;
    }

    setBillingMethodToInvoice() {
        return `/billing/user/payment/payment_option/invoice`;
    }

    buySubscription() {
        return `/billing/user/subscriptions`;
    }

    terminateSubscription() {
        return `/billing/user/subscriptions/repurchase/disable`;
    }

    renewSubscription() {
        return `/billing/user/subscriptions/repurchase/enable`;
    }

    getGroupBillingAddress() {
        return `/billing/user/group/billing_address`;
    }

    activeLicenceKey() {
        return `/billing/user/subscriptions/add_licence_key`;
    }

    getAvailableSubscriptionMethods() {
        return `/billing/user/payment/payment_options`;
    }

    setBillingMethodToCreditCard() {
        return `/billing/user/payment/payment_option/credit_card`;
    }

    setBillingMethodToLicenceKey() {
        return `/billing/user/payment/payment_option/licence_key`;
    }

}
