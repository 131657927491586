import React, {useState} from "react"
import styled from "styled-components"
import {Subheadline} from "../Font"
import {times} from ".."
import {isEqual, uniqWith} from "lodash"
import TariffCrossIcon from "./assets/tarif-cross.svg"
import TariffCheckIcon from "./assets/tarif-checkmark.svg"


import {BuyButton, Colgroup, PlanCell, PlanHeadCell, PriceDisplay, Spacer, TableBody} from "./PlanComponents"


const Checkmark = styled.div``

export function PlanSelection({plans, purchaseAction, buyActionText, ipCountText, priceText, tableHeadline}) {
    const featureBaseList = []
    const customFeatureBaseList = []

    const [activePlanName] = useState()

    const onMouseEnter = articleNumber => (event) => {
        //setActivePlanName(articleNumber);
    }

    const onMouseLeave = articleNumber => (event) => {
        /* setActivePlanName(prevState => {
             if (activePlanName === articleNumber) {
                 return (null);
             }
         });*/
    };


    plans.forEach(plan => {

        plan.customFeatures.forEach(feature => {
            customFeatureBaseList.push(feature);
        });

        plan.features.forEach(feature => {
            featureBaseList.push(feature);
        });
    });


    const handleClick = (articleNumber, type) => (event) => {
        event.preventDefault();
        if (purchaseAction) {
            purchaseAction(articleNumber, type);
        }
    };

    const featureSet = uniqWith(featureBaseList, isEqual);
    const customFeatureSet = uniqWith(customFeatureBaseList, isEqual);

    return <>
        <table style={{borderCollapse: "separate"}} cellSpacing="0" cellPadding="0">
            <Colgroup>
                {times(plans.length * 2 + 1).map((i) => <col key={i}/>)}
            </Colgroup>
            <TableBody>
                <tr>
                    <td>
                        <Subheadline>{tableHeadline}</Subheadline>
                    </td>
                    {plans.map(plan => <React.Fragment key={plan.articleNumber}>
                        <Spacer></Spacer>
                        <PlanHeadCell onMouseEnter={onMouseEnter(plan.articleNumber)}
                                      onMouseLeave={onMouseLeave(plan.articleNumber)}>
                            <div>{plan.description}</div>
                        </PlanHeadCell>
                    </React.Fragment>)}
                </tr>
                <tr>
                    <td>{ipCountText}</td>
                    {plans.map(plan => <React.Fragment key={plan.articleNumber}>
                        <Spacer></Spacer>
                        <PlanCell onMouseEnter={onMouseEnter(plan.articleNumber)}
                                  onMouseLeave={onMouseLeave(plan.articleNumber)}>{plan.ipLimit}</PlanCell>
                    </React.Fragment>)}
                </tr>
                <tr>
                    <td>{priceText}</td>
                    {plans.map(plan => <React.Fragment key={plan.articleNumber}>
                        <Spacer></Spacer>
                        <PlanCell onMouseEnter={onMouseEnter(plan.articleNumber)}
                                  onMouseLeave={onMouseLeave(plan.articleNumber)}>
                            <PriceDisplay price={plan.price} text={"month"}/>
                        </PlanCell>
                    </React.Fragment>)}
                </tr>


                {featureSet.map((feature, i) => <tr key={feature.description}>
                    <td>{feature.description}</td>
                    {plans.map(plan => <React.Fragment key={plan.articleNumber}>
                        <Spacer></Spacer>
                        <PlanCell className={"color-icon"} data-highlight={activePlanName === plan.articleNumber}
                                  onMouseEnter={onMouseEnter(plan.articleNumber)}
                                  onMouseLeave={onMouseLeave(plan.articleNumber)}>
                            {plan.features.find(f => f.description === feature.description) ?
                                <>
                                    {(activePlanName === plan.articleNumber) ?
                                        <Checkmark/> :
                                        <img src={TariffCheckIcon} alt={"Included"}/>
                                    }
                                </>
                                :
                                <img src={TariffCrossIcon} alt="Excluded"/>}
                        </PlanCell>
                    </React.Fragment>)}
                </tr>)}


                <tr>
                    <td></td>
                    {plans.map(plan => <React.Fragment key={plan.articleNumber}><Spacer/>
                        <PlanCell data-highlight={activePlanName === plan.articleNumber}
                                  onMouseEnter={onMouseEnter(plan.articleNumber)}
                                  onMouseLeave={onMouseLeave(plan.articleNumber)}>
                            <span style={{color: "transparent"}}>I</span>
                        </PlanCell>
                    </React.Fragment>)}
                </tr>


                {customFeatureSet.map((feature, i) => <tr key={feature.description}>
                    <td>{feature.description}</td>
                    {plans.map(plan => <React.Fragment key={plan.articleNumber}>
                        <Spacer></Spacer>
                        <PlanCell className={"color-icon"} data-highlight={activePlanName === plan.articleNumber}
                                  onMouseEnter={onMouseEnter(plan.articleNumber)}
                                  onMouseLeave={onMouseLeave(plan.articleNumber)}>
                            {plan.customFeatures.find(f => f.description === feature.description) ?
                                <>
                                    {(activePlanName === plan.articleNumber) ?
                                        <Checkmark/> :
                                        <img src={TariffCheckIcon} alt={"Included"}/>
                                    }
                                </>
                                :
                                <img src={TariffCrossIcon} alt="Excluded"/>}
                        </PlanCell>
                    </React.Fragment>)}
                </tr>)}

                {purchaseAction ?
                    <tr>
                        <td>

                        </td>
                        {plans.map(plan => <React.Fragment key={plan.articleNumber}>
                            <Spacer></Spacer>
                            <PlanCell onMouseEnter={onMouseEnter(plan.articleNumber)}
                                      onMouseLeave={onMouseLeave(plan.articleNumber)}>
                                <BuyButton onClick={handleClick(plan.articleNumber, plan.type)}>
                                    {buyActionText}
                                </BuyButton>
                            </PlanCell>
                        </React.Fragment>)}
                    </tr> :
                    <tr>
                        <td>

                        </td>
                        {plans.map(plan => <React.Fragment key={plan.articleNumber}>
                            <Spacer></Spacer>
                            <PlanCell style={{borderTop: "1px solid #D5D5D5"}}
                                      onMouseEnter={onMouseEnter(plan.articleNumber)}
                                      onMouseLeave={onMouseLeave(plan.articleNumber)}>
                            </PlanCell>
                        </React.Fragment>)}
                    </tr>}
            </TableBody>

        </table>
    </>;
}
