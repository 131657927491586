import React, {useContext, useEffect, useMemo} from "react"
import {DynamicTable, DynamicTableCell, DynamicTableRow} from "./Table/DynamicTable"
import {SimpleEntityContext} from "../../../services/Context/SimpleEntityContext"
import ExpansionPanelRow from "../../../Components/Dialogs/SlideIn/ExpansionPanelRow"
import {TargetInformation} from "../../Target/components/TargetInformation"
import {ScheduleInformation} from "../../Schedule/components/ScheduleInformation"
import {StyledTableCell} from "../../../Components/Table/StyledTableCell"
import ActionElements from "../../../Components/ActionElements"
import {useTranslation} from "react-i18next"


export function TaskList({actions, editAction}) {

    const {tasks, portLists, credentials, targets, schedules, deleteTask, updateAll} = useContext(SimpleEntityContext)
    const ladata = React.useMemo(() => tasks, [tasks])

    const {t} = useTranslation()

    const updateAllMemoized = useMemo(() => updateAll, [updateAll])

    useEffect(() => {
        updateAllMemoized()
    }, [updateAllMemoized])


    const columns = [
        {
            Header: t("task.header.name.name"),
            accessor: "name"
        },
        {
            Header: t("task.header.targetType"),
            accessor: "isInternal"
        },
        {
            Header: t("task.header.targetName"),
            id: "target",
            accessor: (a) => {
                return a?.target?.name
            }
        },
        {
            Header: t("task.header.scanConfigName"),
            id: "scanConfiguration",
            accessor: (a) => {
                return a?.scanConfiguration?.name
            }
        },
        {
            Header: t("task.header.scheduleName"),
            id: "schedule",
            accessor: (a) => {
                return a?.schedule?.name
            }
        },
        {
            Header: t("task.header.action"),
            accessor: () => ""
        }
    ]


    const entryGenerator = ({row, getValue, dataset}) => {

        const schedule = schedules.find(schedule => dataset.schedule?.id === schedule.id)

        return <DynamicTableRow {...row.getRowProps()}>
            <DynamicTableCell>
                {getValue("name")}
            </DynamicTableCell>
            <DynamicTableCell>
                {dataset.isInternal ? "Intern" : "Extern"}
            </DynamicTableCell>
            <ExpansionPanelRow element={DynamicTableCell} key={1} data={(<TargetInformation
                portLists={portLists}
                credentials={credentials}
                target={targets.find(target => target.id === dataset.target.id)}/>)}>
                {getValue("target")}
            </ExpansionPanelRow>
            <DynamicTableCell>
                {getValue("scanConfiguration")}
            </DynamicTableCell>
            {schedule ? <ExpansionPanelRow element={DynamicTableCell}
                                           data={(<ScheduleInformation
                                               schedule={schedule}/>)}
                >
                    {schedule.name}
                </ExpansionPanelRow> :
                <StyledTableCell>
                    {""}
                </StyledTableCell>}
            <DynamicTableCell>
                <ActionElements
                    entity={dataset.task}
                    onUpdateEntry={() => editAction(dataset.id)}
                    deleteEntity={() => deleteTask(dataset.id)}
                    confirmMessage={t("taskentry.deleteTaskMessage", {name: dataset.name})}

                />


            </DynamicTableCell>
        </DynamicTableRow>
    }

    return <>
        <DynamicTable data={ladata}
                      actions={actions}
                      entryGenerator={entryGenerator}
                      columns={columns}
                      searchableColumns={["name", "target", "schedule", "scanConfiguration"]}
                      defaultSortBy={["name"]}
                      cacheKey={"TaskList/2020-10-01"}/>
    </>
}
