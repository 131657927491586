//@flow

import React from "react"
import {useTranslation} from "react-i18next"
import {Col, Row} from "reactstrap"
import ErrorBoundary from "../../Components/ErrorBoundary"
import {Headline} from "../../StyledComponents/Font/Font"
import {Colors} from "../../Theme"
import {SeverityGraph} from "./components/SeverityGraph/SeverityGraph"
import {TaskList} from "./components/TaskList"
import styled, {css} from "styled-components"
import {TaskProvider} from "./TaskContext"

const Wrapper = styled.div`
  ${props => props.theme.bordered && css`
    border-radius: 3px;
    border: 1px solid ${Colors.grey.light};
  `}
  padding-top: 0.63rem;
  width: 100%;
`

const TaskListWrapper = styled.div`
    ${props => props.theme.bordered && css`
      border-top: 1px solid ${Colors.grey.light};
    `}

    margin-top: 1.25rem ;
    padding-bottom: 0;
    overflow-x: auto;
`;

export function Scanmanagement() {
    const {t} = useTranslation();

    return <React.Fragment>
        <TaskProvider>
            <Row>
                <Col>
                    <Headline>{t("scan.management")}</Headline>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Wrapper>
                        <Row>
                            <Col>
                                <ErrorBoundary>
                                    <SeverityGraph/>
                                </ErrorBoundary>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <ErrorBoundary>
                                    <TaskListWrapper>
                                        <TaskList/>
                                    </TaskListWrapper>
                                </ErrorBoundary>
                            </Col>
                        </Row>
                    </Wrapper>

                </Col>
            </Row>
        </TaskProvider>
    </React.Fragment>;

}
