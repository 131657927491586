import React from "react";
import styled from "styled-components";
import {Caption, Paragraph} from "../Font";

export const AlertBoxType = {
    Error: "error",
    Success: "success",
    Warning: "warning",
    Info: "info"

};

const Box = styled.div`
  border-top: 3px solid ${props => props.theme.alertBox[props.type].top};
  box-sizing: border-box;
  padding: 0.938rem;
  background: ${props => props.theme.alertBox[props.type].background};
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
`;

const Title = styled(Caption)`
  font-weight: 400;
  color: ${props => props.theme.alertBox[props.type].text};
`;

const Text = styled(Paragraph)`
    color: ${props => props.theme.alertBox[props.type].text};
`;


export function AlertBox({title, text, type, children, ...props}) {

    return (<Box type={type} {...props}>
        {title && <Title type={type}>{title}</Title>}
        {text && <Text type={type}>
            {text}
        </Text>}


        {children}
    </Box>);
}
