import React from "react";
import {GreyBoxHead, Icon} from "./GreyBox";
import PayPalLogo from "./assets/de-pp-logo-200px.png";

export function PayPal({selected, onSelect}) {
    return <div>
        <GreyBoxHead selected={selected}
                     onSelect={onSelect}
                     title={"PayPal"}
                     text={"lorem  ipsum dolor set amet naret taret valet vivorium"}>
            <Icon as={"img"} src={PayPalLogo} alt="PayPal Logo">
            </Icon>
        </GreyBoxHead>
    </div>;
}
