//@flow

import {EntityRestApiClient} from "../Base/EntityRestApiClient";
import {ScheduleUrlProvider} from "./ScheduleUrlProvider";


class ScheduleRestApiClient extends EntityRestApiClient {

    constructor() {
        super(new ScheduleUrlProvider());
    }
}

export {ScheduleRestApiClient};
