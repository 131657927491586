import React, {useContext, useEffect, useState} from "react"
import {AlertBox, AlertBoxType, Button, Caption, FlexRow, Input} from "@greenbone/cloud-component-library"
import {Col, Row} from "reactstrap"
import {RangeSlider} from "./RangeSlider"
import {SubscriptionContext} from "../SubscriptionProvider"
import {Trans, useTranslation} from "react-i18next"

const minLabel = ({value}) => `Intern ${value}`
const maxLabel = ({value, max}) => `Extern ${max - value}`

export function AmountForm({next, onAmountChange, showNext}) {

    const [amounts, setAmounts] = useState(null)
    const [startupAmount, setStartupAmount] = useState(null)
    const {subscriptions, ipOrderMinimum} = useContext(SubscriptionContext)
    const {t} = useTranslation()

    useEffect(() => {
        if (onAmountChange) {
            onAmountChange(amounts)
        }
    }, [amounts])

    useEffect(() => {
        const product = subscriptions?.current?.product

        if (product) {

            if (product.ipsInternal + product.ipsExternal < ipOrderMinimum) {
                setAmounts({ipsInternal: ipOrderMinimum, ipsExternal: 0})
                setStartupAmount({ipsInternal: ipOrderMinimum, ipsExternal: 0})
            } else {
                setAmounts({...product})
                setStartupAmount({...product})
            }


        } else {
            setAmounts({ipsInternal: ipOrderMinimum, ipsExternal: 0})
            setStartupAmount({ipsInternal: ipOrderMinimum, ipsExternal: 0})
        }
    }, [subscriptions, ipOrderMinimum])

    const updateAmount = amount => {

        if (amount < 0) {
            return
        }

        const oldInternal = Number(startupAmount.ipsInternal)
        const oldExternal = Number(startupAmount.ipsExternal)
        if (amount > oldInternal + oldExternal) {
            setAmounts({...amounts, ipsInternal: amount - oldExternal, ipsExternal: oldExternal})
        } else if (amount >= oldInternal) {
            setAmounts({...amounts, ipsInternal: oldInternal, ipsExternal: amount - oldInternal})
        } else {
            setAmounts({...amounts, ipsInternal: Number(amount), ipsExternal: 0})
        }
    }

    const onChange = e => {
        const value = parseInt(e.target.value)

        if (value < 0) {
            return
        }
        const ipAmount = amounts.ipsExternal + amounts.ipsInternal

        setAmounts({...amounts, ipsInternal: value, ipsExternal: (ipAmount - value)})
    }


    if (!amounts) {
        return "Not Found"
    }
    const ipAmount = amounts?.ipsInternal + amounts?.ipsExternal

    const handleSubmit = (event) => {
        event.preventDefault()
        next(amounts.ipsInternal, amounts.ipsExternal)
    }


    const isAmountBelowMinOrderThreshold = ipAmount < ipOrderMinimum
    const IpOrderMinimum = () => {
        return <span>{ipOrderMinimum}</span>
    }

    return <>
        <Row>
            <Col xs={7}>
                <Row style={{marginBottom: "2rem"}}>
                    <Col xs={6}>
                        <Input onChange={e => updateAmount(e.target.value)}

                               margin={"normal"} type={"number"} label={t("amountForm.totalIps")}
                               value={ipAmount.toString()}/>
                    </Col>
                </Row>


                {isAmountBelowMinOrderThreshold &&
                    <Row style={{marginBottom: "2rem"}}>
                        <Col>
                            <AlertBox title={"Info"} type={AlertBoxType.Warning}>
                                <Trans i18nKey={"amountform.isAmountBelowMinOrderThreshold"}>
                                    You've not reached the minimal required count of ip addresses.
                                    Please buy at least <IpOrderMinimum/>.
                                </Trans>
                            </AlertBox>
                        </Col>
                    </Row>}
                <Row>
                    <Col>
                        <Caption>Total IP's {ipAmount}</Caption>
                        <RangeSlider step={1} maxLabel={maxLabel} minLabel={minLabel} min={0} max={ipAmount}
                                     value={amounts.ipsInternal} onChange={onChange}/>
                    </Col>
                </Row>
                {showNext && <Row>
                    <Col>
                        <FlexRow justifyContent={"flex-end"}>
                            <Button disabled={isAmountBelowMinOrderThreshold}
                                    onClick={handleSubmit}>{t("common.action.continue")}</Button>
                        </FlexRow>
                    </Col>
                </Row>}


            </Col>
        </Row>

    </>
}
