import {ListMenuRootItem} from "./ListMenuRootItem";
import {Collapsible, TopLi} from "./Shared";
import {ListMenuItem} from "./ListMenuItem";
import React from "react";


export function ListMenu({isActive, isOpen, onClickRoot, rootUrl, rootIcon, rootText, minified, menuItems, additionalItems}) {
    return <TopLi open={isActive}>
        <ListMenuRootItem open={isActive} extended={isOpen} onClick={onClickRoot} to={rootUrl} icon={rootIcon}
                          text={rootText}/>
        <Collapsible style={{display: isOpen ? "block" : "none"}}>
            {menuItems.map(item => (
                <ListMenuItem key={item.to} open={isActive} minified={minified} to={item.to}
                              icon={item.icon}>
                    {item.text}
                </ListMenuItem>
            ))}
            {additionalItems}
        </Collapsible>
    </TopLi>;
}
