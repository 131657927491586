import React from "react"
import {FlexRow} from "@greenbone/cloud-component-library"
import {FirstButton, SecondButton} from "../../../StyledComponents/Button/SwitchButton"
import {useTranslation} from "react-i18next"

export function TabView({showExternal, onChange}) {

    const {t} = useTranslation()


    return <FlexRow justifyContent={"start"}>
        <FirstButton
            active={!showExternal}
            onClick={() => onChange(false)}>
            {t("tabView.internalTarget")}
        </FirstButton>
        <SecondButton
            active={showExternal}
            onClick={() => onChange(true)}>
            {t("tabView.externalTarget")}
        </SecondButton>
    </FlexRow>

}

export function SwitchButton({primaryText, secondaryText}) {
    return <FlexRow justifyContent={"start"}>
        <FirstButton
            active={showExternal}
            onClick={() => onChange(true)}>
            {t("tabView.externalTarget")}
        </FirstButton>
        <SecondButton
            active={!showExternal}
            onClick={() => onChange(false)}>
            {t("tabView.internalTarget")}
        </SecondButton>
    </FlexRow>
}
