//@flow

import React from "react"
import {useTranslation} from "react-i18next"
import styled, {css} from "styled-components"
import Checkmark from "../../../../assets/icons/check-1.svg?url"
import CheckmarkGrey from "../../../../assets/icons/Check_Grey.svg?url"
import CrossDarkGrey from "../../../../assets/icons/remove_dark_grey.svg?url"
import CrossRed from "../../../../assets/icons/remove_red.svg?url"
import {Bold} from "../../../StyledComponents/Font/Font"
import {Colors} from "../../../Theme"
import {ValidationStatus} from "../../Hostvalidation/types/Host"
import {Button} from "@greenbone/cloud-component-library"


type Props = {
    onClick: EventCallback;
    validationStatus: ?GSPValidationStatus;
    representation: string;
}

function IsHostValidationFinished(validationStatus: any) {
    switch (validationStatus) {
        case ValidationStatus.CONTACT_SELECTED:
        case ValidationStatus.ADMIN_SELECTED:
        case ValidationStatus.APPROVED:
        case ValidationStatus.REJECTED:
            return true;
        default:
            return false;
    }
}

function HostValidationIcon(validationStatus: any) {
    switch (validationStatus) {
        case ValidationStatus.FETCHED:
        case ValidationStatus.INITIALISED:
            return <Icon src={CrossDarkGrey}/>;
        case ValidationStatus.ADMIN_SELECTED:
        case ValidationStatus.CONTACT_SELECTED:
            return <Icon src={CheckmarkGrey}/>;
        case ValidationStatus.REJECTED:
            return <Icon src={CrossRed}/>;
        default :
            return <Icon src={Checkmark}/>;
    }
}

function HostValidationStatus({validationStatus}) {
    const {t} = useTranslation();

    switch (validationStatus) {
        case  ValidationStatus.FETCHED:
        case ValidationStatus.INITIALISED:
            return t("wizard.components.hostValidation.askFor");
        case ValidationStatus.CONTACT_SELECTED:
        case ValidationStatus.ADMIN_SELECTED:
            return t("wizard.components.hostValidation.askedFor");
        case ValidationStatus.APPROVED:
            return t("wizard.components.hostValidation.allReadyValidated");
        case ValidationStatus.REJECTED:
            return t("wizard.components.hostValidation.hostDenied");
        default:
            return t("wizard.components.hostValidation.loadingValidation");
    }
}

const BaseHalf = styled.div`
  padding: 0.44rem;
  box-sizing: border-box;
  display: flex;
  align-content: center;
  align-items: center;
`;

const FirstHalf = styled(BaseHalf)`
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  background-color: white;
  color: ${Colors.grey.dark};
  border: 1px solid ${Colors.grey.dark};

  ${props => props.status === ValidationStatus.APPROVED && css`
    border: 1px solid ${Colors.green.normal};
    color: ${Colors.green.normal};
    background-color: white;`
};

  ${props => props.status === ValidationStatus.REJECTED && css`
    border: 1px solid ${Colors.red.normal};
    color: ${Colors.red.normal};
    background-color: white;
    &:hover {
      cursor: default;
    }`
};

  border-right: 0;
`;

const SecondHalf = styled(BaseHalf)`
  padding-left: 1rem;
  background-color: ${Colors.green.normal};
  color: white;

  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;

  ${props => props.status === ValidationStatus.APPROVED && css`
    border: 1px solid ${Colors.green.normal};
    color: ${Colors.green.normal};
    background-color: white;
    &:hover {
      cursor: default;
    }`
};

  ${props => (props.status === ValidationStatus.ADMIN_SELECTED || props.status === ValidationStatus.CONTACT_SELECTED) && css`
    border: 1px solid ${Colors.blue.normal};
    color: ${Colors.blue.normal};
    background-color: white;
    &:hover {
      cursor: default;
    }`
};

  ${props => props.status === ValidationStatus.REJECTED && css`
    border: 1px solid ${Colors.red.normal};
    color: ${Colors.red.normal};
    background-color: white;
    &:hover {
      cursor: default;
    }`
};
`;

const Icon = styled.img`
  height: 0.94rem;
  margin-right: 0.9rem;
  margin-left: 0.5rem;
`;

export function HostValidationRow(props: Props) {
    const {validationStatus, onClick, representation} = props;

    return (
        <React.Fragment>
            <FirstHalf status={validationStatus}>
                {
                    HostValidationIcon(validationStatus)
                }
                <Bold>{representation}</Bold>
            </FirstHalf>
            <SecondHalf
                status={validationStatus}>
                <Bold>
                    <HostValidationStatus validationStatus={validationStatus} />
                </Bold>
            </SecondHalf>
            {!!IsHostValidationFinished(validationStatus) === false &&
            <BaseHalf>
                <Button onClick={IsHostValidationFinished(validationStatus) ? undefined : onClick}
                        style={{margin: "0"}}>
                    <HostValidationStatus validationStatus={validationStatus} />

                </Button>
            </BaseHalf>
            }

        </React.Fragment>
    );
}
