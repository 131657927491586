//@flow
import * as HttpStatuscode from "http-status-codes";


export class FailedDependencyError extends Error {
    httpStatus = HttpStatuscode.FAILED_DEPENDENCY;
    type = FailedDependencyError;

    constructor(message: string = "") {
        super(`FailedDependency: '${message}'`);
    }
}
