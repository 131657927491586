import {v4 as uuidv4} from "uuid"
import md5 from "md5"

export function createElement(type, {className, content, onClick, ...params}) {
    const element = document.createElement(type)

    if (className) {
        element.classList.add(className)
    }

    if (onClick) {
        element.addEventListener("click", function (e) {
            onClick()
        }, false)
    }

    element.innerHTML = content || ""
    element.id = `S${md5(uuidv4())}`

    for (const key in params) {
        element[key] = params[key]
    }
    return element
}

export function renderElement(element, renderParent) {
    const elementExists = document.querySelector(`#${element.id}`)
    if (elementExists) {
        elementExists.replaceWith(element)
    }

    renderParent.append(element)
}


export function deleteElement(selector) {
    const toBeDeleted = document.querySelector(selector)
    if (toBeDeleted) {
        toBeDeleted.parentNode.removeChild(toBeDeleted)
    }
}
