//@flow

import React from "react";
import {Route} from "react-router-dom";
import {Switcher} from "../../Components/Router";
import {TaskPage} from "./components/TaskPage";


type Props = {
    match: any;
    history: any;
}

export class TaskRouter extends React.Component<Props, {}> {

    render() {
        return (
            <Switcher>
                <Route path="/list" Component={TaskPage}/>

            </Switcher>
        );
    }
}
