//@flow

import React from "react";
import {WelcomeScreenController} from "../../Wizard/controller/WelcomeScreenController";
import {GlobalConstants} from "../../../constants";
import {Cookie} from "../../../Helper/Cookie";
import {persistor} from "../../../store";


class _ClearPersistance extends React.Component<any, any> {

    clearReduxStore = (event: any) => {
        const sure = window.confirm("Wollen Sie den Redux Store wirklich leeren?");
        if (sure) {
            persistor.purge();
            alert("Der Redux Store wurde geleert");
        }
    };

    clearWELCOM_WIZARD_COOKIE = (event: any) => {
        const sure = window.confirm("Wollen Sie den WELCOME_WIZARD_COOKIE wirklich entfernen?");
        if (sure) {
            Cookie.erase(GlobalConstants.WELCOME_WIZARD_ACCEPTED);
            if (Cookie.get(GlobalConstants.WELCOME_WIZARD_ACCEPTED) === null) {
                alert("Cookie wurde entfernt");
            }
            else {
                alert("Cookie konnte nicht entfernt werden");
            }

        }
    };

    clearWizardCookie = (event: any) => {
        const sure = window.confirm("Wollen Sie den Wizard Cookie wirklich entfernen?");
        if (sure) {
            const controller = new WelcomeScreenController();
            controller.clearDoNotShowAgain();
        }
    };

    render() {
        return (
            <div>
                <ul>
                    <li>
                        <button onClick={this.clearReduxStore} variant={"contained"} color={"primary"}>
                            Purge Redux Store
                        </button>
                    </li>
                    <li>
                        <button onClick={this.clearWELCOM_WIZARD_COOKIE} variant={"contained"} color={"primary"}>
                            Clear WELCOME_COOKIE
                        </button>
                    </li>
                    <li>
                        <button onClick={this.clearWizardCookie}>Clear Wizard Cookie</button>
                    </li>
                </ul>

            </div>
        );
    }
}

export const ClearPersistance = _ClearPersistance;
