//@flow

export class InvalidStatusCodeError extends Error {
    type = InvalidStatusCodeError;
    status: number;

    httpStatus() {
        return this.status;
    }

    constructor(statusCode: number) {
        super(`Received invalid status from server '${statusCode}'`);
        this.status = statusCode;
    }
}
