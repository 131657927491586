//@flow

import React from "react";
import {Routes} from "react-router";
import {NotFound} from "./NotFound";
import {Route} from "react-router-dom";


class Switcher extends React.Component<any, any> {

    render() {
        return (
            <Routes>
                {this.props.children}
                <Route path="*" Component={NotFound}/>
            </Routes>);
    }
}

export default Switcher;
