import React, {useEffect} from "react"
import {useTranslation} from "react-i18next"
import {Snackbar, Spinner, useRequest} from "@greenbone/cloud-component-library"
import {LegalRestApiClient} from "./LegalRestApiClient"
import {RichTextCss} from "./RichTextCss"


export function PrivacyPolicy(props) {
    const apiClient = new LegalRestApiClient()
    const {i18n} = useTranslation()

    const language = i18n?.language


    const [data, loading, error, setData] = useRequest(apiClient.dataProtectionPolicy(i18n.language).bind(this))


    useEffect(() => {
        const client = new LegalRestApiClient()
        client.dataProtectionPolicy(language)()
            .then(response => {
                if (response) {
                    setData(response)
                }
            })
            .catch(e => Snackbar.Error(e))
    }, [language])

    if (error) {
        Snackbar.Error(error.toString())
    }

    const createMarkup = () => {
        return {__html: data?.value}
    }
    return <>
        <RichTextCss/>
        {loading ? <div><Spinner/></div> :
            <div className={"ql-editor"} dangerouslySetInnerHTML={createMarkup()}/>}
    </>
}

