//@flow

export class TaskExecutionUrlProvider {
    ENTITY_ROUTE = "/task_execution";

    startTask(taskId: string) {
        return `${this.ENTITY_ROUTE}/${taskId}`;
    }

    stopTask(taskId: string) {
        return `${this.ENTITY_ROUTE}/${taskId}`;
    }

    taskExecutionStatus(taskId: string) {
        return `${this.ENTITY_ROUTE}/${taskId}`;
    }

    taskExecutionLatest() {
        return `${this.ENTITY_ROUTE}/latest`;
    }
}
