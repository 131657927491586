import React, {useState} from "react"
import styled, {css} from "styled-components"

const hexCharacters = "a-f\\d"
const match3or4Hex = `#?[${hexCharacters}]{3}[${hexCharacters}]?`
const match6or8Hex = `#?[${hexCharacters}]{6}([${hexCharacters}]{2})?`
const nonHexChars = new RegExp(`[^#${hexCharacters}]`, "gi")
const validHexSize = new RegExp(`^${match3or4Hex}$|^${match6or8Hex}$`, "i")

function hexToRgb(hex) {
    if (typeof hex !== "string" || nonHexChars.test(hex) || !validHexSize.test(hex)) {
        throw new TypeError("Expected a valid hex string")
    }

    hex = hex.replace(/^#/, "")
    let alpha = 1

    if (hex.length === 8) {
        alpha = parseInt(hex.slice(6, 8), 16) / 255
        hex = hex.slice(0, 6)
    }

    if (hex.length === 4) {
        alpha = parseInt(hex.slice(3, 4).repeat(2), 16) / 255
        hex = hex.slice(0, 3)
    }

    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
    }

    const num = parseInt(hex, 16)
    const red = num >> 16
    const green = (num >> 8) & 255
    const blue = num & 255

    return {red, green, blue, alpha}
}

function addAlphaToHex(hex, alpha) {
    const {red, green, blue} = hexToRgb(hex)
    return `rgba(${red}, ${green}, ${blue}, ${alpha})`
}


export const SolutionTypeOuter = styled.div`
  transition: width 1s;

  box-sizing: border-box;

  & + & {
  margin-left: 1rem;
  }

  width: 3.125rem;
  ${props => props.open && css`
    width: 12rem;
    justify-content: space-between;
  `};

  height: 3.125rem;
  border-radius: 1.5625rem;

  background: ${props => addAlphaToHex(props.color, props.active ? 1 : 0.15)};
  color: ${props => props.iconColor};
  display: flex;
  align-items: center;
    padding-left: 0.5rem;
    padding-right: 0.5rem;

  ${props => props.active && css`
    color: white;
    border: 2px solid ${props => props.iconColor};
  `}
`

export const ScaleFilterTab = styled.div`
  ${SolutionTypeOuter}{
    ${({left}) => left && "transform-origin: left"};
    transform: scale(${props => props.scale ? props.scale : 0.8});
  }
`

const Icon = styled.div`
  width: 1.875rem;
  height: 1.875rem;
  flex-shrink: 0;

  svg, img {
    width: 100%;
    height: 100%;
  }
`

const Text = styled.div`
  white-space: nowrap;
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
`

export function FilterTab({icon, children, color, iconColor, active, defaultOpen, disabled, reverse, ...other}) {
    const [open, setOpen] = useState(!!defaultOpen)

    const onMouseEnter = event => {
        if (!defaultOpen && !disabled) {
            setOpen(true)
        }

    }

    const onMouseLeave = event => {
        if (!defaultOpen && !disabled)
            setOpen(false)
    }

    if (reverse) {
        return <SolutionTypeOuter active={active} color={color} iconColor={iconColor} open={open}
                                  onMouseEnter={onMouseEnter}
                                  onMouseLeave={onMouseLeave} {...other}>
            <Text style={{display: open ? "flex" : "none"}}>
                {children}
            </Text>
            <Icon>
                {icon}
            </Icon>
        </SolutionTypeOuter>
    }

    return <SolutionTypeOuter active={active} color={color} iconColor={iconColor} open={open}
                              onMouseEnter={onMouseEnter}
                              onMouseLeave={onMouseLeave} {...other}>
        <Icon>
            {icon}
        </Icon>
        <Text style={{display: open ? "flex" : "none"}}>
            {children}
        </Text>
    </SolutionTypeOuter>
}
