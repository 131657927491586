import React from "react"
import {withTranslation} from "react-i18next"
import {connect} from "react-redux"
import {NavLink} from "react-router-dom"
import {bindActionCreators, compose} from "redux"
import {FlexColumn, FlexRow} from "@greenbone/cloud-component-library"
import {Logout} from "./Logout"
import AlertDialog from "../Components/Dialogs/AlertDialog/AlertDialog"
import ConfirmDialog from "../Components/Dialogs/ConfirmDialog/ConfirmDialog"
import {FullWidthDialog} from "../Components/Dialogs/FullWidthDialog/components/FullWidthDialog"
import {CustomizedSnackbar} from "../Components/Snackbar/components/Snackbars"
import {WelcomeScreen} from "../Components/Welcome/WelcomeScreen"
import {JWT} from "../Helper/JWT"
import {withUserContext} from "../User/UserProvider"
import {Minify} from "./components/Minify"
import {Branding, Content, Navbar, Wrapper} from "./components/Navigation"
import {MainMenu} from "./components/Siderbar/MainMenu"
import {Tiny} from "../StyledComponents/Font/Font"
import {VERSION} from "../constants"
import {globalInstanceStorage} from "../../GlobalSingle"
import {InformationBarView} from "./components/InformationBarView"
import {SubscriptionAlertBarRenderer} from "../User/Subscritpion/components/SubscriptionAlertBarRenderer"
import {LayoutContext} from "./LayoutContext"
import {StaleWarning} from "./components/StaleWarning"
import {withProductUpdateContext} from "../Context/ProductUpdatesProvider"
import SlideIn from "../Components/Dialogs/SlideIn"
import {LayoutMessageBubble} from "./components/ProductUpdates/LayoutMessageBubble"
import ErrorBoundary from "../Components/ErrorBoundary"
import {AddressAlertBarRenderer} from "../User/Profile/AddressAlertBarRenderer"
import {MigrationUpdates} from "./components/ProductUpdates/MigrationUpdates";

class Layout extends React.Component {
    body
    jwtToken = new JWT()


    state = {
        drawerWidth: 18.75,
        menuOpen: true,
        showStaleWarning: false
    }


    componentDidMount() {
        if (this.jwtToken.isValid) {
            this.props.loadUser()
        }
    }


    render() {
        const {productUpdateContext} = this.props
        return (

            <React.Fragment>
                {this.state.showStaleWarning && <StaleWarning/>}
                <Navbar>
                    <FlexRow justifyContent={"space-between"} alignItems={"center"} fullWidth>
                        <NavLink to={"/"}>
                            <Branding src="/vmssp-admin/public/assets/logoSmall"/>
                        </NavLink>

                        <FlexRow>
                            <Logout keycloak={{
                                logout: () => {
                                    const keycloak = globalInstanceStorage.getKeycloak()
                                    keycloak.wipeAndLogout()
                                },
                                token: globalInstanceStorage.getKeycloak()?.token
                            }}>
                                <LayoutMessageBubble/>
                            </Logout>
                        </FlexRow>

                    </FlexRow>
                </Navbar>
                <Wrapper>
                    <MainMenu/>
                    <Minify/>
                    <FlexColumn fullWidth>

                        <div ref={(ref) => this.context.setRef(ref)} id={"content-wrapper-subnavbar-renderer"}>

                        </div>
                        <InformationBarView/>
                        <Content className={"override-layout-container"} fluid>
                            {this.props.children}
                            <CustomizedSnackbar/>
                            <ConfirmDialog/>
                            <AlertDialog/>
                            <FullWidthDialog/>
                            <WelcomeScreen/>
                            <FlexRow className={"version-view"} justifyContent={"flex-end"}>
                                <Tiny>Version: {VERSION}</Tiny>
                            </FlexRow>
                        </Content>
                    </FlexColumn>

                </Wrapper>
                <ErrorBoundary>
                    <SubscriptionAlertBarRenderer/>
                </ErrorBoundary>
                <ErrorBoundary>
                    <AddressAlertBarRenderer/>
                </ErrorBoundary>

                <SlideIn onClose={productUpdateContext.close} open={productUpdateContext.isOpen}>
                    <ErrorBoundary>
                        <MigrationUpdates/>
                    </ErrorBoundary>
                </SlideIn>
            </React.Fragment>

        )
    }
}

function mapDispatchToProps(dispatch) {
    let actions = bindActionCreators(dispatch)
    return {...actions, dispatch}
}

function mapStateToProps(state) {
    return {
        title: state.app.Title
    }
}

Layout.contextType = LayoutContext

export const GSPApplicationLayout = compose(
    withUserContext,
    withProductUpdateContext,
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps)
)(Layout)
