import {RestApiClient} from "../services/apiClients/Base/RestApiClient";

export class DomainResolver {
    constructor(restApiClient = new RestApiClient()) {
        this.restApiClient = restApiClient;
    }

    /**
     *
     * @param domain - String Domain to resolve google.de
     * @returns {Promise<String>}
     */
    async resolveARecords(domain) {
        return await this.restApiClient.post("/targets/lookupHost", {domain});
    }


}
