import {RestApiClient} from "@greenbone/cloud-component-library"
import {globalInstanceStorage} from "../../../../GlobalSingle"
import {SubscriptionUrlProvider} from "./SubscriptionUrlProvider"
import HttpStatuscode from "http-status-codes";
import {ForbiddenError} from "../../../services/Exceptions";
import i18next from "i18next";

function ErrorHandler(e) {
    switch (e.status) {
        case HttpStatuscode.FORBIDDEN:
            throw new ForbiddenError(i18next.t("components.error.boundary.noPermission"))
        default:
            throw e
    }
}

export class SubscriptionRestApiClient {
    constructor(
        apiClient = new RestApiClient(
            globalInstanceStorage.getFetchClient(),
            ErrorHandler
        )) {
        this.apiClient = apiClient
        this.urlProvider = new SubscriptionUrlProvider()
    }

    getCurrentSubscriptions = async () => {
        return await this.apiClient.get(this.urlProvider.getCurrentSubscriptions())

    }

    getCurrentCreditCard = async () => {
        return await this.apiClient.get(`/billing/user/payment/payment_option/credit_card`)
    }

    getSubscriptionOverview = async () => {
        return await this.apiClient.get(this.urlProvider.getSubscriptionOverview())
    }

    getAllSubscriptions = async () => {
        return await this.apiClient.get(this.urlProvider.getAllSubscriptions())
    }

    getPaymentMethod = async () => {
        return await this.apiClient.get(this.urlProvider.getPaymentMethod())
    }

    subscribe = async (body) => {

        if (body.switchImmediate) {
            return await this.apiClient.post(`/billing/user/subscriptions`, body)
        }
        return await this.apiClient.put(`/billing/user/subscriptions/repurchase/enable`, body)

    }


    setBillingMethodToInvoice = async () => {
        return await this.apiClient.put(this.urlProvider.setBillingMethodToInvoice())
    }

    setBillingMethodToCreditCard = async (token) => {
        return await this.apiClient.put(this.urlProvider.setBillingMethodToCreditCard(), {cardToken: token})
    }

    getIpPricing = async (language) => {
        return await this.apiClient.get(this.urlProvider.getIpPricing(language))
    }

    calculatePrice = async (count) => {
        return this.apiClient.put(`/billing/user/pricing/price`, {ipCount: count})
    }

    terminateSubscription = async (type, feedback) => {
        return await this.apiClient.put(this.urlProvider.terminateSubscription(), {
            productType: type,
            feedback
        })
    }


    getGroupBillingAddress = async () => {
        return await this.apiClient.get(this.urlProvider.getGroupBillingAddress())
    }

    setGroupVat = async (body) => {
        return await this.apiClient.put(`/billing/user/group/vat`, body)
    }

    getGroupVat = async () => {
        return await this.apiClient.get(`/billing/user/group/vat`)
    }

    setGroupBillingAddress = async (address) => {
        return await this.apiClient.put(this.urlProvider.getGroupBillingAddress(), address)
    }

    activeLicenceKey = async (key, immediate) => {
        return await this.apiClient.post(this.urlProvider.activeLicenceKey(), {
            licenceKey: key,
            switchImmediate: immediate
        })
    }

    getAvailableSubscriptionMethods = async () => {
        return await this.apiClient.get(this.urlProvider.getAvailableSubscriptionMethods())
    }

    setBillingMethodToLicenceKey = async () => {
        return await this.apiClient.put(this.urlProvider.setBillingMethodToLicenceKey())
    }
}
