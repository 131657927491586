import {Col, Row} from "reactstrap"
import {Subheadline, Text} from "../../../StyledComponents/Font/Font"
import {ChipInput} from "../../../StyledComponents/Form/ChipInput"
import React from "react"
import {useTranslation} from "react-i18next"


export function ExcludedAddressInputArea({
                                             isInternal,
                                             handleExcludedHostsAdd,
                                             handleExcludedHostRemove,
                                             excludedHosts,
                                             inputRef
                                         }) {
    const {t} = useTranslation()

    return <Row style={{marginBottom: "4rem"}}>
        <Col>
            <Subheadline
                style={{marginBottom: 0}}>{t("common.targetCreate.excludedHosts")}</Subheadline>

            {isInternal ? <Text>{t("common.targetCreate.hostFormat.intern")}</Text>
                : <Text>{t("common.targetCreate.hostFormat")}</Text>}

            <ChipInput style={{width: "100%"}}
                       id={"hosts"}
                       name={"hosts"}
                       className={"hosts"}
                       onBlur={(event) => handleExcludedHostsAdd(event.target.value)}
                       label={t("common.targetCreate.addExcludedHosts")}
                       helperText={t("common.targetCreate.separation")}
                       value={excludedHosts}
                       newChipKeyCodes={[32, 188, 13, 9]}
                       onAdd={handleExcludedHostsAdd}
                       onDelete={handleExcludedHostRemove}
                       inputRef={inputRef}
            />
        </Col>
    </Row>
}
