import React, {useContext} from "react"
import {ReportContext} from "../ReportContext"
import {IconButton, Table, TableFooter, TableRow} from "@material-ui/core"
import TablePagination from "@material-ui/core/TablePagination"
import {useTranslation} from "react-i18next"
import {FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage} from "@material-ui/icons"


function TablePaginationActions({nextIconButtonProps, backIconButtonProps}) {


    const {changePage, paging} = useContext(ReportContext)
    const {pageTotal, pageIndex} = paging

    const handleFirstPageButtonClick = (event: any) => {
        changePage(0)
    }

    const handleBackButtonClick = (event: any) => {
        changePage(pageIndex - 1)
    }

    const handleNextButtonClick = (event: any) => {
        changePage(pageIndex + 1)
    }

    const handleLastPageButtonClick = (event: any) => {
        changePage(
            pageTotal - 1
        )
    }

    return (
        <div style={{display: "flex"}}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={pageIndex === 0 || backIconButtonProps?.disabled}
                aria-label="First Page"
            >
                <FirstPage/>
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={pageIndex === 0 || backIconButtonProps?.disabled}
                aria-label="Previous Page"
            >
                <KeyboardArrowLeft/>
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={(pageIndex + 1) >= pageTotal || nextIconButtonProps?.disabled}
                aria-label="Next Page"
            >
                <KeyboardArrowRight/>
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={pageIndex >= pageTotal - 1 || nextIconButtonProps?.disabled}
                aria-label="Last Page"
            >
                <LastPage/>
            </IconButton>
        </div>
    )

}


export function ReportPaging(props) {

    const {paging, changePageSize, hasBeenFlushed} = useContext(ReportContext)
    const {t} = useTranslation()
    if (!paging) {
        return null
    }

    const {pageIndex, pageSize, pageTotal, allTotal} = paging

    return <Table>
        <TableFooter>
            <TableRow>
                <TablePagination
                    backIconButtonProps={{disabled: !hasBeenFlushed}}
                    nextIconButtonProps={{disabled: !hasBeenFlushed}}
                    SelectProps={{disabled: !hasBeenFlushed}}
                    count={allTotal}
                    rowsPerPage={pageSize}
                    page={pageIndex}
                    onPageChange={() => {
                    }}
                    onRowsPerPageChange={changePageSize}
                    ActionsComponent={TablePaginationActions}
                    labelRowsPerPage={t("reportfull.perpage")}
                    labelDisplayedRows={({page}) => `${
                        t("reportfull.pagination", {
                            pageCount: pageTotal > 0 ? page + 1 : 0,
                            pageTotal: pageTotal
                        })}`
                    }
                    rowsPerPageOptions={[9, 18, 27]}
                />
            </TableRow>
        </TableFooter>
    </Table>

}
