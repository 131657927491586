//@flow

import React from "react";
import {Route} from "react-router-dom";
import {Switcher} from "../../Components/Router";
import {TargetPage} from "./components/TargetPage";


type Props = {
    history: any;
}

export class TargetRouter extends React.Component<Props, {}> {

    render() {

        return (
            <Switcher>
                <Route path="/list" Component={TargetPage}/>
            </Switcher>
        );
    }
}
