// @flow

import Promise from "bluebird"
import React from "react"
import {withTranslation} from "react-i18next"
import {connect} from "react-redux"
import {Col, Row} from "reactstrap"
import {bindActionCreators, compose} from "redux"
import {openAlertDialog} from "../../../Components/Dialogs/AlertDialog/actions"
import {SearchableDropdown} from "../../../Components/Input"
import {DateTimeInput} from "../../../Components/Input/Components/DateTimeInput"
import {openErrorSnackbar, openInfoSnackbar, openSuccessSnackbar} from "../../../Components/Snackbar/actions"
import {SIODateTime} from "../../../controller/SIODateTime"
import {Value} from "../../../Helper/Validator"
import {AbortButton, Button, Snackbar} from "@greenbone/cloud-component-library"
import {Headline, Subheadline} from "../../../StyledComponents/Font/Font"
import {Input} from "../../../StyledComponents/Form/Input"
import {getValidationResult} from "../../../controller/FieldValidator"
import {IsNotEmptyString, IsOptionalString, Required} from "../../../controller/FormValidators"
import {createGSPScheduleDto, IntervalType, IntervalTypeReadable} from "../../Schedule/components/ScheduleForm"
import {ScheduleRestApiClient} from "../../../services/apiClients/Schedule/ScheduleRestApiClient"
import {CreateErrors, ForbiddenError} from "../../../services/Exceptions"


type Props = {
    openInfoSnackbar: typeof openInfoSnackbar;
    openErrorSnackbar: typeof openErrorSnackbar;
    openSuccessSnackbar: typeof openSuccessSnackbar;
    openAlertDialog: typeof openAlertDialog;
    onClose: EventCallback;
    onSave: EventCallback;
    t: any;
}

type State = {
    fields: {
        name: string;
        comment: string;
        startDate: SIODateTime,
        endDate: ?SIODateTime,
        intervalType: string,
        intervalGap: number,
    },
    fieldStatus: {
        name: ?boolean;
        comment: ?boolean;
        startDate: ?boolean,
        endDate: ?boolean,
        intervalType: ?boolean,
        intervalGap: ?boolean,
    };
    isValid: boolean;
    _loading: boolean;
    _saving: boolean;
    _exception: any
}

export class _ScheduleCreate extends React.Component<Props, State> {
    scheduleRestClient: ScheduleRestApiClient;
    setStateAsync: Promise;

    state: State = {
        fields: {
            name: "",
            comment: "",
            startDate: SIODateTime.local(),
            endDate: undefined,
            intervalType: IntervalType.DAY,
            intervalGap: 1,
        },
        fieldStatus: {
            name: null,
            comment: null,
            startDate: undefined,
            endDate: undefined,
            intervalGap: null,
            intervalType: null,
        },
        isValid: false,
        _exception: null,
        _loading: false,
        _saving: false,
    };
    FieldValidators = {
        name: new IsNotEmptyString(),
        comment: new IsOptionalString(),
        startDate: new Required(),
        endDate: new IsOptionalString(),
        intervalGap: new IsNotEmptyString(),
        intervalType: new IsNotEmptyString(),
    };

    constructor(props: Props) {
        super(props);
        this.scheduleRestClient = new ScheduleRestApiClient();
        this.setStateAsync = Promise.promisify(this.setState);
    }

    handleOnChangeField = (event: any) => {
        const {name, value} = event.target;

        this.setState(prevState => {
            let fields = prevState.fields;
            fields[name] = value;
            return {fields};
        });
    };

    handleDateTimeChange = (name: string, date: ?Date) => {
        this.setState(prevState => {
            let fields = prevState.fields;
            fields[name] = date;
            return {fields};
        });
    };

    validateFields = async (): Promise<void> => {
        const {isValid, fieldValidity} = getValidationResult(
            this.state.fields,
            this.FieldValidators,
        );

        await this.setStateAsync({fieldStatus: fieldValidity, isValid});
    };

    onFormSubmit = (event: SyntheticEvent<HTMLFormElement>) => {
        event.preventDefault();

        this.validateFields()
            .then(() => {
                this.handleFormSubmit();
            });
    };

    handleFormSubmit = () => {
        const {t} = this.props;
        const {isValid, fields} = this.state;

        if (!isValid) {
            return;
        }

        //$FlowFixMe
        if (fields.endDate && fields.startDate && fields.startDate > fields.endDate) {
            Snackbar.Error(t("wizard.components.scheduleCreate.endDateMustBeLater"));
            return false;
        }

        //$FlowFixMe
        if (fields.endDate && fields.endDate < SIODateTime.local()) {
            Snackbar.Error(t("wizard.components.scheduleCreate.endDateMustBeAfterToday"));
            return false;
        }

        this.createSchedule(createGSPScheduleDto(this.state.fields));
    };

    redirectToList = () => {
        this.props.onSave();
    };

    createSchedule = (schedule: any) => {
        const {t} = this.props;

        this.setState({_saving: true});

        this.scheduleRestClient.createEntity(schedule)
            .then(() => {
                this.redirectToList();
                Snackbar.Success(t("wizard.components.scheduleCreate.created", {name: schedule.name}));
            })
            .catch(error => {
                if (error.type === ForbiddenError) {
                    Snackbar.Error(error.message);
                }
                else if (Value(error.type).isInList(CreateErrors)) {
                    this.props.openAlertDialog(t("common.messages.error"), error.message);
                    this.setState({_saving: false});
                }
                else {
                    this.setState({_exception: error});
                }
            });
    };

    render() {
        const {t} = this.props;
        const {fields, fieldStatus} = this.state;

        return <React.Fragment>
            <Headline>Neuen Zeitplan erstellen</Headline>

            <Row style={{marginBottom: "2rem"}}>
                <Col>
                    <Input onChange={this.handleOnChangeField} name={"name"}
                           label={t("wizard.components.scheduleCreate.timePLanName")}
                           value={fields.name || ""}
                           isValid={fieldStatus.name}/>
                </Col>
            </Row>

            <Row style={{marginBottom: "5rem"}}>
                <Col>
                    <Input onChange={this.handleOnChangeField} name={"comment"} label={t("common.description.optional")}
                           value={fields.comment || ""}
                           isValid={fieldStatus.comment}/>
                </Col>
            </Row>

            <Row style={{marginBottom: "5rem"}}>
                <Col>
                    <Subheadline>{t("wizard.components.scheduleCreate.startTime")}</Subheadline>
                    <DateTimeInput name={"startDate"}
                                   date={fields.startDate}
                                   handleDateTimeChange={this.handleDateTimeChange}
                                   isValid={fieldStatus.startDate}/>
                </Col>
                <Col>
                    <Subheadline>{t("wizard.components.scheduleCreate.endTime")}</Subheadline>
                    <DateTimeInput name={"endDate"}
                                   date={fields.endDate}
                                   handleDateTimeChange={this.handleDateTimeChange}
                                   isValid={fieldStatus.endDate}/>
                </Col>
            </Row>

            <Row><Col><Subheadline>{t("wizard.components.scheduleCreate.executionInterval")}</Subheadline></Col></Row>

            <Row style={{marginBottom: "4rem", alignItems: "flex-end"}}>
                <Col xs={6}>
                    <SearchableDropdown
                        onChange={this.handleOnChangeField}
                        name={"intervalType"}
                        value={fields.intervalType || ""}
                        options={{
                            data: Object.keys(IntervalType).map((type, i) => {
                                return {
                                    id: type,
                                    name: IntervalTypeReadable[IntervalType[type]],
                                };
                            }), valueFieldName: "id", labelFieldName: "name",
                        }}
                        label={t("wizard.components.scheduleCreate.chooseInterval")}
                        isValid={fieldStatus.intervalType}/>
                </Col>
                <Col xs={3}>
                    <Input type={"number"} onChange={this.handleOnChangeField} name={"intervalGap"}
                           label={t("wizard.components.scheduleCreate.intervalDistance")}
                           value={fields.intervalGap || ""}
                           isValid={fieldStatus.intervalGap}/>
                </Col>
            </Row>

            <Row>
                <Col>
                    <div style={{justifyContent: "space-between", display: "flex"}}>
                        <AbortButton onClick={this.props.onClose}>{t("common.action.abort")}</AbortButton>
                        <Button
                            onClick={this.onFormSubmit}>{t("wizard.components.scheduleCreate.createTimePlan")}</Button>
                    </div>
                </Col>
            </Row>
        </React.Fragment>;
    }
}

function mapDispatchToProps(dispatch) {
    let actions = bindActionCreators({
        openAlertDialog: openAlertDialog,
        openSuccessSnackbar: openSuccessSnackbar,
        openErrorSnackbar: openErrorSnackbar,
        openInfoSnackbar: openInfoSnackbar,
    }, dispatch);
    return {...actions, dispatch};
}

function mapStateToProps(state) {
    return {};
}

export const ScheduleCreate = compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps),
)(_ScheduleCreate);
