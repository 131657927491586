//@flow

class ReportUrlProvider {

    reportSmallAll() {
        return `/reports/small`
    }

    reportSmallGraph() {
        return `/reports/small/graph`
    }

    reportOverview() {
        return `/reports/small/overview`
    }

    reportsSmallByTaskId(taskId: string) {
        return `/reports/small/byTaskId?id=${taskId}`
    }

    reportFull(reportId: string) {
        return `/reports/${reportId}/full`
    }

    reportSmall(reportId: string) {
        return `/reports/${reportId}/small`;
    }

    report(reportId: string) {
        return `/reports/${reportId}`
    }
}

export {ReportUrlProvider};
