import React, {useEffect} from "react"
import {Routes, useNavigate} from "react-router"
import {Navigate, Route} from "react-router-dom"
import CredentialsRouter from "./areas/Credentials"
import {HelpPage} from "./areas/Help/HelpPage"
import {HostRouter} from "./areas/Hostvalidation"
import {Scanmanagement} from "./areas/Scanmanagement/Scanmanagement"
import {ScheduleRouter} from "./areas/Schedule"
import {TargetRouter} from "./areas/Target"
import {TaskRouter} from "./areas/Task"
import {Wizard} from "./areas/Wizard"
import Switcher from "./Components/Router/Components/Switcher"
import {Parser} from "./Helper/Parser"
import {GSPApplicationLayout} from "./Layout/GSPApplicationLayout"
import {EntityUrlProvider} from "./UrlProvider/EntityUrlProvider"
import {HelpUrlProvider} from "./UrlProvider/HelpUrlProvider"
import {ScanmanagementUrlProvider} from "./UrlProvider/ScanmanagementUrlProvider"
import {UserSettingsUrlProvider} from "./UrlProvider/UserSettingsUrlProvider"
import {NotificationPage} from "./User/Notification/NotificationPage"
import {SecurityPage} from "./User/Security/SecurityPage"
import {ColorPage} from "./areas/Develop/ColorPage"
import {GatewayRouter} from "./areas/Gateway"
import {ReportIndex} from "./areas/Reporting/ReportIndex"
import {ManualPage} from "./areas/Help/ManualPage"
import {GroupSettingsUrlProvider} from "./UrlProvider/GroupSettingsUrlProvider"
import {TeamRouter} from "./User/Team/TeamRouter"
import {InvoicePage} from "./User/Invoice/InvoicePage"
import {ProfilePage} from "./User/Profile/ProfilePage"
import {SubscriptionRouter} from "./User/Subscritpion/SubscriptionRouter"
import {CombinedLegal} from "./User/Terms/CombinedLegal"
import {GroupSecurityPage} from "./areas/Group/Security/GroupSecurityPage"
import ErrorBoundary from "./Components/ErrorBoundary"

const EntityRouter = () => {
    return <Switcher>
        <Route path="/task/*" Component={TaskRouter}/>
        <Route path="/target/*" Component={TargetRouter}/>
        <Route path="/hosts/*" Component={HostRouter}/>
        <Route path="/schedule/*" Component={ScheduleRouter}/>
        <Route path="/credentials/*" Component={CredentialsRouter}/>
        <Route path="/gateway/*" Component={GatewayRouter}/>
        <Route
            path="/"
            element={<Navigate to={EntityUrlProvider.taskList()}/>}
        />
    </Switcher>
}


const UserSettingsRouter = () => {
    return (
        <Switcher>
            <Route
                exact
                path="/"
                element={<Navigate to={UserSettingsUrlProvider.notification()} />}
            />
            <Route path="/notification" Component={NotificationPage}/>
            <Route path="/security" Component={SecurityPage}/>
        </Switcher>
    )
}

const GroupSettingsRouter = () => {
    return (
        <Switcher>
            <Route exact path="/" element={<Navigate to={GroupSettingsUrlProvider.team()}/>} />
            <Route path="/team/*" Component={TeamRouter}/>
            <Route path="/billing" Component={ProfilePage}/>
            <Route path="/subscription/*" Component={SubscriptionRouter}/>
            <Route path="/invoice" Component={InvoicePage}/>
            <Route path="/security" Component={GroupSecurityPage}/>
        </Switcher>
    )
}

const HelpRouter = () => {
    return <Switcher>
        <Route path="/text" Component={HelpPage}/>
        <Route path="/manual" Component={ManualPage}/>
        <Route path="/terms" Component={CombinedLegal}/>
    </Switcher>
}

const GSPApplication = () => {
    return (
        <>
            <GSPApplicationLayout>

                <ErrorBoundary>
                    <Switcher>
                        <Route path="/scanmanagement" Component={Scanmanagement}/>
                        <Route path="/entities/*" Component={EntityRouter}/>

                        <Route path="/settings/group/*"
                               Component={GroupSettingsRouter}/>

                        <Route path="/settings/user/*"
                               Component={UserSettingsRouter}/>


                        <Route path="/help/*" Component={HelpRouter}/>
                        <Route path="/color" Component={ColorPage}/>
                        <Route path="/report/*" Component={ReportIndex}/>
                    </Switcher>
                </ErrorBoundary>

            </GSPApplicationLayout>

        </>
    )
}

export class ApplicationRouter extends React.Component {

    render() {
        return (
            <Routes>
                <Route path="/wizard" Component={Wizard}/>
                <Route path="/" element={<Navigate
                    to="scanmanagement"
                />}/>
                <Route path="*" Component={GSPApplication}/>
            </Routes>
        )
    }
}


