import React from 'react'
import {FlexRow, Spinner} from "@greenbone/cloud-component-library"

export function IsList({message, list, loading, children}) {

    if (loading === true) {
        return <FlexRow justifyContent={"center"}>
            <Spinner/>
        </FlexRow>
    }

    if (Array.isArray(list)) {

        if (typeof children === "function") {
            return children()
        }
        return children
    }

    return <>{message}</>

}
