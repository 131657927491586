import React from "react"

import styled from "styled-components"
import {FlexRow} from "../Flex"

export const DashboardCellBanner = styled(FlexRow)`
  height: 2.5rem;;
  align-items: center;
  padding-left: 2rem;
  background: #F7F7F7;
  color: #4C4C4D;
  font-size: 1.25rem;
  justify-content: space-between;
`


export const DashboardCellText = styled.span`
  font-size: 2.5rem;
  color:#4C4C4D;
`

const Circle = styled.div`
  background: #EBF5E5;
  width: 2.9375rem;
  height: 2.9375rem;
  padding: 0.625rem;
  margin-right: 1rem;
  border-radius: 50%;
  flex-shrink: 0;
  
  svg {
    width: 100%;
    height: 100%;
  }
`

export const DashboardCellBody = styled(FlexRow)`
    align-items: center;
    padding-left: 0.5rem;
    height: 8.75rem;
    padding: 2rem;
`

export function DashboardCellIcon({icon, ...other}) {
    return <Circle {...other}>
        {icon}
    </Circle>
}

export const DashboardCellAutoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
`
export const DashboardCellHelperText = styled.div`
  color: #707070;
  font-size: 0.875rem;
  position:absolute;
  
`

export function DashboardCellIconTextHelper({icon, text, helper, ...other}) {
    return <DashboardCellBody {...other}>
        <DashboardCellIcon icon={icon}/>
        <DashboardCellText>{text} <DashboardCellHelperText>{helper}</DashboardCellHelperText></DashboardCellText>

    </DashboardCellBody>

}
