//@flow

import React from "react";
import * as Sentry from "@sentry/browser";
import {AlertIdentifier} from "./AlertIdentifier";

export class ErrorBoundary extends React.Component<any, any> {
    state = {
        hasError: false,
        error: null
    };

    componentDidCatch(error: Error, errorInfo: any /*React.ErrorInfo */): void {
        this.setState({hasError: true, error});

        if (error) {
            Sentry.withScope(scope => {
                Object.keys(errorInfo).forEach(key => {
                    scope.setExtra(key, errorInfo[key]);
                });
                Sentry.captureException(error);
            });
        }
    }

    render() {
        const {hasError, error} = this.state;

        if (hasError) {
            return <AlertIdentifier exception={error}/>;
        }

        return this.props.children;
    }
}
