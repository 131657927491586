//@flow

import {i18n} from "../../i18n";
import {Language, LANGUAGE_FALLBACK} from "../constants";
import {Value} from "./Validator";


/**
 * Get language from store. Will be set from i18n.
 * i18n can have a value that is not supported from language files, in this case the language is the fallback language.
 * @returns {string}
 */
export function getSupportedLanguageFromStore(): string {
    const language = i18n.language.slice(0, 2);
    return getSupportedLanguage(language);
}

/**
 * Get supported language from input language
 * @param inputLanguage
 * @returns {string}
 */
export function getSupportedLanguage(inputLanguage: string): string {
    if (!Value(inputLanguage).isInList(Object.values(Language))) {
        return LANGUAGE_FALLBACK;
    }
    return inputLanguage;
}
