import * as React from "react"
import {useTranslation} from "react-i18next"
import {Col, Row} from "reactstrap"
import {FlexColumn, Input} from "@greenbone/cloud-component-library"
import {PaymentForm} from "./PaymentForm"
import styled from "styled-components"
import {CardNumberElement, useElements, useStripe} from "@stripe/react-stripe-js"


export const FormRow = styled(Row)`
  margin-bottom: 1rem;
`


export function CardPaymentInformation({setCardHolder, payFormRef}) {
    const stripe = useStripe()

    const {t} = useTranslation()
    const elements = useElements()

    if (!elements) {
        return null
    }

    const cardElement = elements.getElement(CardNumberElement)

    return <>
        <FlexColumn fullWidth>
            <FormRow>
                <Col sm={6}>
                    <Input name={"cardOwner"}
                           margin={"normal"}
                           placeholder={"Name"}
                           label={t("extern.registration.CardPaymentInformation.cardOwner")}
                           onChange={setCardHolder}/>
                </Col>
            </FormRow>

            <PaymentForm cardElement={cardElement} stripe={stripe} onRef={payFormRef}/>
        </FlexColumn>
    </>
}


