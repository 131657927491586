//@flow

export class FileReportUrlProvider {

    technicalReport(reportId: string) {
        return `/reports/${reportId}/technical/pdf`
    }

    executiveReport(reportId: string) {
        return `/reports/${reportId}/executive/pdf`
    }

    technicalReportJson(reportId: string) {
        return `/reports/${reportId}/technical/json`
    }

    executiveReportJson(reportId: string) {
        return `/reports/${reportId}/executive/json`
    }

    openVasXML(reportId: string) {
        return `/reports/${reportId}/gmp/xml`
    }

}

