import {useEffect} from "react"
import {LocalStorage} from "../LocalStorage"

//TODO - jwerremeyer - MUST Pull from library when extracting
/**
 * Return the first closes number inside the array
 * @param needle
 * @param haystack
 * @returns {*}
 */
function getClosestNumber(needle, haystack) {
    return haystack.reduce((a, b) => {
        return Math.abs(b - needle) < Math.abs(a - needle) ? b : a
    })
}

/**
 * Enables caching the selected pageSize inside localStorage
 * @param cacheKey - string caching key of the table component
 * @param pageSize - object react tables pageSize state
 * @param setPageSize - function react tables setPageSize function
 * @param allowedPagesSizes OPTIONAL - Array<number> a list of allowed page sizes. If the saved size is not inside the array the closes one will be used
 */
export function useCachePageSize(cacheKey, pageSize, setPageSize, allowedPagesSizes) {
    useEffect(() => {
        if (cacheKey) {
            const localStorage = new LocalStorage(`${cacheKey}-pageSize`)
            const loadedState = localStorage.loadState()
            if (loadedState?.pageSize) {
                const pageSizeNumber = Number(loadedState.pageSize)

                if (Array.isArray(allowedPagesSizes) && allowedPagesSizes?.size > 0) {
                    if (allowedPagesSizes.includes(pageSizeNumber)) {
                        setPageSize(pageSizeNumber)
                    } else {
                        setPageSize(getClosestNumber(pageSizeNumber, allowedPagesSizes))
                    }
                } else {
                    setPageSize(pageSizeNumber)
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cacheKey])

    useEffect(() => {
        if (cacheKey) {
            const localStorage = new LocalStorage(`${cacheKey}-pageSize`)
            localStorage.saveState({pageSize})
        }
    }, [pageSize, cacheKey])
}
