//@flow

export class ServiceNotAvailableError extends Error {
    type = ServiceNotAvailableError;

    constructor(message: string = "") {
        super(`Service not available '${message}'`);
    }
}

