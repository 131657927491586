import React from "react"
import {Checkbox as MaterialCheckbox, FormControlLabel} from "@material-ui/core"
import styled from "styled-components"


const ColoredCheckbox = styled(MaterialCheckbox)`
  color: ${props => props.theme.checkbox.color} !important; 
`

export function Checkbox({checked, onChange, value, label, name, className, ...props}) {
    return <FormControlLabel className={className}
                             control={
                                 <ColoredCheckbox
                                     checked={checked}
                                     onChange={onChange}
                                     value={value}
                                     color="primary"
                                     {...props}
                                 />
                             }
                             label={label}
    />;
}
