import React from 'react'
import styled from "styled-components"
import {useTranslation} from "react-i18next"

const Cardbox = styled.div`
    color: #989898;
    /*border: 2px solid #b2b2b2; */
    padding: 1rem;

    border-radius: 2px;
`

export function CreditCardInfo({cardInfo}) {
    const {t} = useTranslation()

    return <>
        <Cardbox>
            <table>
                <tbody>
                <tr>
                    <td style={{fontSize: "1.1rem", fontWeight: "bold"}}>
                        {t("payment.currentCreditCard")}
                    </td>

                </tr>
                <tr>
                    <td>{t("payment.last4Digits")}</td>
                    <td>{cardInfo?.last4Digits}</td>
                </tr>
                <tr>
                    <td>{t("payment.expires")}</td>
                    <td>{cardInfo?.expireMonth}/{cardInfo?.expireYear}</td>
                </tr>
                </tbody>
            </table>
        </Cardbox>
    </>
}
