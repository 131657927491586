//@flow

import {keyBy} from "../../Helper/Filter";


export class ScanmanagementController {

    static reformatTaskExecutionsToKeyByTaskId = (taskExcutionStatus: Array<GSPTaskExecutionStatus>) => {
        return keyBy(taskExcutionStatus, "taskId");
    };

    /**
     * Groups the small Reports by TaskId
     * @param smallReports
     */
    static groupSmallReportsByTaskId = (smallReports: Array<GSPSmallReport>) => {
        let result = {};
        smallReports.forEach((value: GSPSmallReport) => {
            if (!result[value.taskId]) {
                result[value.taskId] = [];
            }
            (result[value.taskId] || (result[value.taskId] = [])).push(value);
        });
        return result;
    };

    static getSmallReportsForTask(smallReportsMap: { [string]: Array<GSPSmallReport> }, taskId: string) {
        return smallReportsMap[taskId] || [];
    }

}
