import React, {useEffect} from "react"
import {Button, Headline, Paragraph} from "@greenbone/cloud-component-library"
import {Logger} from "../../../controller/loggingController"
import {useNavigate} from "react-router-dom"

export function NotFound() {

    useEffect(() => {
        Logger.warn("Page not found")
    }, [])

    const navigate = useNavigate()

    return <div>
        <Headline>Page Not Found - 404</Headline>
        <Paragraph>
            We can't seem to find the page you're looking for.
        </Paragraph>
        <Button onClick={() => navigate(-1)}>
            Back
        </Button>
    </div>
}


