// @flow

import {Col, Row} from "reactstrap"
import styled from "styled-components"


export const ColSmall = styled(Col)`
  && {
    padding-right: 10px;
    padding-left: 10px;
  }
`

export const RowSmall = styled(Row)`
  && {
    margin-right: -10px;
    margin-left: -10px;
  }
`;
