import * as d3 from "d3"

export class LineHelper {
    static FindMax(data, dataAccessors) {
        let max = dataAccessors[0](data[0])

        dataAccessors.forEach(accessor => {
            const maxInDataset = d3.max(data, accessor)
            max = Math.max(maxInDataset, max)
        })

        return max
    }
}