//@flow

import {withStyles} from "@material-ui/core"
import MaterialChipInput from "../../inputs/ChipInput"
import React from "react"
import styled from "styled-components"
import {Colors} from "../../Theme"


const styles = {
    underline: {
        "&:after": {
            borderBottomColor: `${Colors.grey.normal}`,
            transform: "scaleX(1)"
        },
        "&:before": {
            borderBottomColor: `${Colors.grey.normal}`,
            transform: "scaleX(1)"
        },
        "&:hover": {
            borderBottom: "none"
        },
        borderBottomColor: `${Colors.grey.normal}`,
        "&:hover:not($disabled):not($focused):not($error):before": {
            borderBottom: `none`
        }

    },
    focused: {},
    disabled: {},
    error: {}
}

const MessageWrapper = styled.div`
    margin-top: 5px;
    font-size: .75rem;
    color: #f44336;
`

function ErrorMessage({message}) {

    if (typeof message === "string" || React.isValidElement(message)) {
        return <MessageWrapper>
            {message}
        </MessageWrapper>
    }

    if (Array.isArray(message)) {
        return <MessageWrapper>
            {
                message.map(text =>
                    (<div style={{marginBottom: "2.5px"}} key={text}>
                            {text}
                        </div>
                    ))
            }
        </MessageWrapper>
    }

    return null
}

class _ChipInput extends React.Component<any, any> {

    render() {
        const {classes, isValid, ...other} = this.props


        if (isValid !== true && !!isValid) {
            return <div style={{width: "100%"}}>
                <MaterialChipInput {...other} error={true} helperText={null}/>
                <ErrorMessage message={isValid}/>
            </div>
        }


        return (
            <MaterialChipInput {...other}/>
        )
    }
}

export const ChipInput = withStyles(styles)(_ChipInput)
