//@flow

import {withStyles} from "@material-ui/core";
import Radio from "@material-ui/core/Radio/Radio";
import classNames from "classnames";
import React from "react";
import {withTranslation} from "react-i18next";
import {compose} from "redux";


const styles = ({
    item: {
        flexGrow: 1,
        flexBasis: 0,
        textAlign: "center",
        margin: "3px",
        paddingTop: "1.25rem",
        paddingBottom: "1.25rem",
        "&:first-child": {
            marginLeft: "0",
        },
        "&:last-child": {
            marginRight: "0",
        },
    },
    itemLow: {
        backgroundColor: "#9dd883",
        color: "#57933b",
    },
    itemMedium: {
        backgroundColor: "#ffe490",
        color: "#f0af4f",
    },
    itemHigh: {
        backgroundColor: "#f36d68",
        color: "#9f4944",
    },
    containerBoxes: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
    },
    label: {
        textAlign: "center",
        flexGrow: 1,
        flexBasis: 0,
        "&:first-child": {
            flexGrow: 1,
        },
        "&:last-child": {
            flexGrow: 1,
        },
    },
    labelText: {
        textAlign: "center",
        position: "relative",
        top: "0.75rem",
    },
    containerInputs: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
    },
});

type Props = {
    +onChange: any;
    +severity: ?number;
    +classes: typeof styles;
    t: any;
}

class _SeverityInput extends React.Component<Props> {
    render() {
        const {classes, severity, t} = this.props;

        const severityInputs = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(index => {
            return (
                <label key={index} className={classes.label}>
                    <div className={classes.labelText}>{index}</div>
                    <Radio
                        checked={severity === index}
                        onChange={this.props.onChange}
                        value={index.toString()}
                        name="severity"
                        color="default"
                    />
                </label>
            );
        });

        return (
            <div>
                <div className={classes.containerBoxes}>
                    <div
                        className={classNames(classes.item, classes.itemLow)}>{t("components.serverInput.risk.low")}</div>
                    <div
                        className={classNames(classes.item, classes.itemMedium)}>{t("components.serverInput.risk.medium")}</div>
                    <div
                        className={classNames(classes.item, classes.itemHigh)}>{t("components.serverInput.risk.high")}</div>
                </div>
                <div className={classes.containerInputs}>
                    {severityInputs}
                </div>
            </div>
        );
    }
}

export const SeverityInput = compose(
    withStyles(styles),
    withTranslation(),
)(_SeverityInput);
