// @flow

import {withStyles, withTheme} from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import {compose} from "redux";
import {Theme} from "../../Theme";


const styles = theme => ({
    stateLabel: {
        borderRadius: "3px",
        padding: "0.30rem",
        paddingRight: "0.63rem",
        paddingLeft: "0.63rem",
        textAlign: "center",
        whiteSpace: "nowrap",
        width: "100%",
    },
    neutral: {
        backgroundColor: "lightgrey",
    },
    primaryBlueLight1: {
        backgroundColor: "#5f6e8b",
        color: "#ffffff",
        fontWeight: "bold",
    },
    primaryBlueLight2: {
        backgroundColor: "#a4acbd",
        color: "#ffffff",
        fontWeight: "bold",
    },
    stateColorGreen1: {
        backgroundColor: "#657d71",
        color: "#ffffff",
        fontWeight: "bold",
    },
    stateColorGreen2: {
        backgroundColor: "#325141",
        color: "#ffffff",
        fontWeight: "bold",
    },
    stateColorRed1: {
        backgroundColor: "#96090f",
        color: "#ffffff",
        fontWeight: "bold",
    },
    stateColorGreen3: {
        backgroundColor: "#6bc290",
        borderColor: "#39ae6b",
        color: "#ffffff",
        fontWeight: "bold",
    },
    stateColorGreen4: {
        backgroundColor: "#39ae6b",
        color: "#ffffff",
        fontWeight: "bold",
    },
    stateColorYellow1: {
        backgroundColor: "#d0c986",
        color: "#ffffff",
        fontWeight: "bold",
    },
    stateColorYellow2: {
        backgroundColor: "#c0b75e",
        color: "#ffffff",
        fontWeight: "bold",
    },
    stateColorBlue1: {
        backgroundColor: "#658398",
        color: "#ffffff",
        fontWeight: "bold",
    },
    stateColorRed2: {
        backgroundColor: "#e33918",
        color: "#ffffff",
        fontWeight: "bold",
    },
    stateColorGreen5: {
        backgroundColor: "#34ae2c",
        color: "#ffffff",
        fontWeight: "bold",
    },
    stateColorGreen6: {
        backgroundColor: "#598478",
        color: "#ffffff",
        fontWeight: "bold",
    },
    stateColor1: {
        backgroundColor: "#a4acbd",
        color: "#ffffff",
        fontWeight: "bold",
    },
    stateColorHigh: {
        backgroundColor: Theme.severity.high,
        borderColor: Theme.severity.high,
        color: "#ffffff",
        fontWeight: "bold",
    },
    stateColorWarning: {
        backgroundColor: Theme.severity.warning,
        color: "#ffffff",
        fontWeight: "bold",
    },
    stateColorInfo: {
        backgroundColor: Theme.severity.info,
        color: "#ffffff",
        fontWeight: "bold",
    },
});

type Props = {
    classes: typeof styles;
    children: any;
    colorState: "neutral";
}

class StateLabel extends React.Component<Props> {
    static defaultProps = {
        colorState: "neutral",
    };

    render() {
        const {classes, colorState} = this.props;

        return (
            <div className={classNames(classes.stateLabel, classes[colorState])}>
                {this.props.children}
            </div>
        );
    }
}

export default compose(
    withStyles(styles),
    withTheme
)(StateLabel);
