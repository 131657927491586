//@flow

import {withStyles} from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import {Input} from "../../../StyledComponents/Form/Input";
import {Colors} from "../../../Theme";


const styles = theme => ({
    bar: {
        position: "relative;",
        display: "block;",
        height: "0.25rem;",
        width: "0;",
        border: "1px;",
        backgroundColor: "#9b59b6;",
        transition: "all .4s;",
    },
    weak: {
        width: "25% !important",
        backgroundColor: Colors.red.normal,
    },
    medium: {
        width: "50% !important",
        backgroundColor: Colors.yellow.normal,
    },
    well: {
        width: "75% !important",
        backgroundColor: Colors.yellow.normal,
    },
    strong: {
        width: "100% !important;",
        backgroundColor: Colors.green.normal,
    },
    full: {
        width: "100%",
    },
});

type State = {
    score: number,
    additionalClass: any;
    missingChars: Array<string>
}

class _PasswordInput extends React.Component<any, State> {
    static SpecialChars = /[^a-zA-Z0-9]/;
    static UppercaseLetters = /.*?[A-Z].*?/;
    static LowercaseLetters = /.*?[a-z].*?/;
    static Numbers = /.*?[0-9]+.*?/;
    requiredPasswordLength: number = 8;
    state = {
        score: 0,
        additionalClass: null,
        missingChars: [],
    };

    static isPasswordStrongEnough(password: string): boolean {
        return PasswordInput.calculatePasswordStrength(password) >= 4;
    }

    static calculatePasswordStrength(password: string): number {
        let score = 0;
        //$FlowFixMe

        if (password.length >= 6) {
            score = 1;
        }
        if (password.length >= 8) {
            score = 2;
        }
        if ((this.SpecialChars.test(password) || _PasswordInput.UppercaseLetters.test(password)) && password.length >= 5) {
            score = 2;
        }
        if ((this.SpecialChars.test(password) &&
            (this.UppercaseLetters.test(password) || _PasswordInput.Numbers.test(password))) &&
            password.length >= 6) {
            score = 3;
        }
        if (this.SpecialChars.test(password) &&
            this.UppercaseLetters.test(password) &&
            this.Numbers.test(password) &&
            password.length >= 8) {
            score = 4;
        }

        return score;
    }

    /**
     * Updating the state for the progress bar based on the event score
     *
     * @param password password
     */
    missingInPassword = (password: string) => {

        let missingExpressedInWords = [];
        if (_PasswordInput.UppercaseLetters.test(password) === false) {
            missingExpressedInWords.push("Großbuchstabe");
        }

        if (_PasswordInput.LowercaseLetters.test(password) === false) {
            missingExpressedInWords.push("Kleinbuchstabe");
        }
        if (_PasswordInput.Numbers.test(password) === false) {
            missingExpressedInWords.push("Zahl");
        }
        if (_PasswordInput.SpecialChars.test(password) === false) {
            missingExpressedInWords.push("Sonderzeichen");
        }

        if (password.length < this.requiredPasswordLength) {
            missingExpressedInWords.push(`${this.requiredPasswordLength - password.length} Zeichen`);
        }

        return missingExpressedInWords;
    };

    updatePasswordStrength = (event) => {
        const {classes} = this.props;

        const password = event.target.value;
        let score = PasswordInput.calculatePasswordStrength(password);

        let newState;
        switch (score) {
            case 1:
            case 2: {
                newState = classes.medium;
                break;
            }
            case 3: {
                newState = classes.well;
                break;
            }
            case 4: {
                newState = classes.strong;
                score = 4;
                break;
            }
            default: {
                newState = classes.weak;
            }
        }

        this.setState({
            score: score,
            additionalClass: newState,
        });

        const missingGroups = this.missingInPassword(password);
        this.setState({missingChars: missingGroups});
        this.props.onChange(event, score, missingGroups);
    };

    /**
     * Generates a JSX component for the password field
     *
     * @returns {*} - password-field component
     */
    render() {
        const {classes} = this.props;

        return (
            <div className={classes.full}>
                <Input
                    disabled={this.props.disabled}
                    isValid={this.props.isValid}
                    className={classNames(this.props.className, classes.full)}
                    required={this.props.required}
                    type={"password"}
                    label={this.props.label}
                    onChange={this.updatePasswordStrength}
                    name={this.props.name}/>

                <div className={classNames(classes.bar, this.state.additionalClass)}/>
                <div>
                    {
                        (this.state.missingChars.length > 0) &&
                        <div>
                            <span className={classes.fontSize}>Es fehlen noch: </span>
                            {this.state.missingChars.map((missingChar, index) => {
                                if (index + 1 === this.state.missingChars.length) {
                                    return <span key={index}>{missingChar}</span>;

                                }
                                return <span key={index}>{missingChar}, </span>;
                            })}
                        </div>
                    }
                </div>
            </div>
        );
    }
}

export const PasswordInput = withStyles(styles)(_PasswordInput);
