import React, {useContext, useEffect, useMemo, useState} from "react"
import {SimpleEntityContext} from "../../../services/Context/SimpleEntityContext"
import {DynamicTable, DynamicTableCell, DynamicTableRow} from "../../Task/components/Table/DynamicTable"
import {useTranslation} from "react-i18next"
import {Button, FlexRow, SlideIn} from "@greenbone/cloud-component-library"
import {IconButton} from "@material-ui/core"
import {Edit} from "@material-ui/icons"
import {GatewayForm} from "./GatewayForm"
import DeleteButton from "../../../Components/ActionElements/DeleteButton"
import {GatewaySelectDownloader} from "./GatewaySelectDownloader"


const TableActions = ({updateEntity}) => {
    const [open, setOpen] = useState(false)
    const {t} = useTranslation()


    return <>
        <FlexRow alignItems={"first baseline"}>

            <Button onClick={(event) => {
                setOpen(true)
                return false
            }} style={{marginRight: "1rem"}}>{t("gatewaylist.newGateway")}</Button>
            <GatewaySelectDownloader/>

            <SlideIn open={open} onClose={() => {
                setOpen(false)
            }}>
                <GatewayForm updateEntity={updateEntity} onClose={() => setOpen(false)}/>
            </SlideIn>
        </FlexRow>

    </>
}

const EditSlideIn = ({updateEntity, id}) => {
    const [open, setOpen] = useState(false)
    return <>
        <IconButton onClick={() => setOpen(true)}>
            <Edit/>
        </IconButton>
        <SlideIn open={open} onClose={() => setOpen(false)}>
            <GatewayForm id={id} updateEntity={updateEntity} onClose={() => setOpen(false)}/>
        </SlideIn>

    </>
}

export function GatewayList(props) {

    const {gateways, deleteGateway, updateGateways} = useContext(SimpleEntityContext)

    const memoizedUpdateGateway = useMemo(() => updateGateways, [updateGateways])

    useEffect(() => {
        memoizedUpdateGateway()
    }, [memoizedUpdateGateway])


    const data = useMemo(() => gateways, [gateways])

    const {t} = useTranslation()

    const entryGenerator = ({row, getValue, dataset}) => {
        return <DynamicTableRow {...row.getRowProps()}>
            <DynamicTableCell>
                {getValue("location")}
            </DynamicTableCell>
            <DynamicTableCell>
                {getValue("status")}
            </DynamicTableCell>
            <DynamicTableCell>
                {getValue("description")}
            </DynamicTableCell>
            <DynamicTableCell>
                <EditSlideIn id={dataset.id} updateEntity={updateGateways}/>
                <DeleteButton
                    deleteEntity={() => {
                        deleteGateway(dataset.id)
                    }}
                    confirmMessage={t("gatewaylist.confirm.delete")}
                />
            </DynamicTableCell>
        </DynamicTableRow>
    }

    const columns = [
        {
            Header: t("gatewayList.location"),
            accessor: "location"
        },
        {
            Header: t("gatewayList.status"),
            accessor: "status"
        },
        {
            Header: t("gatewayList.description"),
            accessor: "description"
        },
        {
            Header: t("gatewayList.actions"),
            accessor: () => ""
        }
    ]

    return <DynamicTable data={data}
                         actions={<TableActions updateEntity={() => updateGateways()}/>}
                         entryGenerator={entryGenerator}
                         columns={columns}
                         searchableColumns={["location", "status"]}
                         defaultSortBy={["location"]}
                         cacheKey={"GatewayList/2020-10-01"}/>
}
