import React, {useState} from "react"
import {Spinner, TableCell, TableRow} from "@greenbone/cloud-component-library"
import {IconButton} from "@material-ui/core"
import {useTranslation} from "react-i18next"
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf"
import {Formatter} from "../../controller/Formatter"
import {BillingMethod} from "./BillingMethod"
import {PaymentOptions} from "../Subscritpion/SubscriptionProvider"


function DownloadLink({pdfLink, paymentMethod}) {
    const {t} = useTranslation()

    const [loading, setLoading] = useState(false)

    const handleClick = e => {
        setLoading(true)
        setTimeout(() => setLoading(false), 5000)
    }

    if (loading) {
        return <Spinner/>
    }


    if (paymentMethod === PaymentOptions.NONE) {
        return null
    }

    if (pdfLink && paymentMethod !== PaymentOptions.NONE) {
        return <a onClick={handleClick} href={pdfLink}>
            <IconButton>
                <PictureAsPdfIcon/>
            </IconButton>
        </a>
    }

    return t("invoiceTableRow.noInvoice")

}

function ShowDate({date}) {
    const {i18n} = useTranslation()

    if (!date) {
        return null
    }

    if (date.isSIODateTime) {
        return Formatter.toDate(date.toJSDate(), i18n.language)
    }

    return null
}

function Currency({amount}) {
    const {i18n} = useTranslation()
    return Formatter.toCurrency(amount, i18n.language)
}

export function InvoiceTableRow({row, getValue, dataset}) {
    return <TableRow {...row.getRowProps()}>
        <TableCell>
            <ShowDate date={dataset.startAt}/>
            &nbsp; - &nbsp;
            <ShowDate date={dataset.endAt}/>
        </TableCell>
        <TableCell style={{textAlign: "right"}}>
            <Currency amount={dataset.amount}/>
        </TableCell>
        <TableCell style={{textAlign: "right"}}>
            <BillingMethod method={dataset.paymentOption}/>
        </TableCell>
        <TableCell style={{textAlign: "right"}}>
            <DownloadLink pdfLink={dataset.pdfLink} paymentMethod={dataset.paymentOption}/>
        </TableCell>
    </TableRow>
}
