import {withStyles, withTheme} from "@material-ui/core"
import React from "react"
import {Trans, withTranslation} from "react-i18next"
import {connect} from "react-redux"
import {bindActionCreators, compose} from "redux"
import IconEmail from "../../../assets/icons/notification/icon-mail.svg?url"
import IconSlack from "../../../assets/icons/notification/icon-slack.svg?url"
import IconTeams from "../../../assets/icons/notification/icon-teams.svg?url"
import {Headline2} from "../../Components/Basic/Headlines"
import {Spacer} from "../../Components/Basic/Spacer"
import {openAlertDialog} from "../../Components/Dialogs/AlertDialog/actions"
import {BlockCheckBox} from "../../Components/Input/Components/BlockCheckBox"
import {BlockCheckBoxContainer} from "../../Components/Input/Components/BlockCheckBoxContainer"
import {SeverityInput} from "../../Components/Input/Components/SeverityInput"
import Loader from "../../Components/Loader/Loader"
import {openSuccessSnackbar} from "../../Components/Snackbar/actions"
import {Converter} from "../../Helper/Converter"
import {JWT} from "../../Helper/JWT"
import {Parser} from "../../Helper/Parser"
import {Bold, Headline} from "../../StyledComponents/Font/Font"
import {ListItem, UnorderedList} from "../../StyledComponents/List/List"
import {Colors} from "../../Theme"
import {UserRestApiClient} from "../service/UserRestApiClient"
import {NotificationConfigBox} from "./UserNotificationConfigBox"
import styled from "styled-components"
import {Snackbar} from "@greenbone/cloud-component-library"
import AdditionalEmails from "./AdditionalEmails/AdditionalEmails";
import {TeamRestApiClient} from "../Team/service/TeamRestApiClient";
import {ForbiddenError} from "../../services/Exceptions";

const styles = () => ({
    paragraph: {
        lineHeight: "1.2em",
        textAlign: "justify"
    }
})

const LinkGreen = styled.a`
    color: ${Colors.green.dark};

    &:hover {
        color: ${Colors.green.darker};
    }
`

type Props = {
    +classes: typeof styles;
    +openAlertDialog: typeof openAlertDialog;
    +openSuccessSnackbar: typeof openSuccessSnackbar;
    t: any
}

type State = {
    isDailyReportEnabled: boolean,
    isEmailMessageEnabled: boolean,
    isMonthlyReportEnabled: boolean,
    isSlackMessageEnabled: boolean,
    isTeamsMessageEnabled: boolean,
    isWeeklyReportEnabled: boolean,
    severityThreshold: number,
    webhookSlack: ?string,
    webhookTeams: ?string,

    webhookSlackValidation: any,
    webhookTeamsValidation: any,

    _loading: boolean;
    _exception: any;
}

class _UserNotification extends React.Component<Props, State> {

    jwtToken = new JWT()
    userClient: UserRestApiClient

    state = {
        isDailyReportEnabled: false,
        isEmailMessageEnabled: false,
        isMonthlyReportEnabled: false,
        isSlackMessageEnabled: false,
        isTeamsMessageEnabled: false,
        isWeeklyReportEnabled: false,
        severityThreshold: 4,
        webhookSlack: "",
        webhookTeams: "",
        webhookSlackValidation: null,
        webhookTeamsValidation: null,
        _loading: false,
        _exception: null,
        additionalEmail: null,
        isMainUser: false,
        valid: null
    }

    constructor(props: Props) {
        super(props)

        this.userClient = new UserRestApiClient()
    }

    setAdditionalEmail = (newAdditionalEmail: string, callback: Function) => {
        this.setState({additionalEmail: newAdditionalEmail}, callback);
    };

    setValid = (message: string) => {
        this.setState({valid: message});
    };

    componentDidMount() {
        this.setState({_loading: true})
        this.loadUserNotificationConfig()
        const teamRestApiClient = new TeamRestApiClient()
        teamRestApiClient.getMembers()
            .then((members) => {
                this.checkMainUser(members)
            })
            .catch(e => {
                Snackbar.Error(e.toString())
            });
    }

    checkMainUser(members) {
        const userEmail = this.jwtToken.getEmail()
        const isMainUser = members.some(member => member.email === userEmail && member.mainUser);
        this.setState({isMainUser});
    }

    loadUserNotificationConfig = () => {
        this.userClient.getUerNotificationConfig()
            .then((notificationConfig: UserNotificationConfig) => {
                this.setState({
                    isDailyReportEnabled: notificationConfig.isDailyReportEnabled,
                    isEmailMessageEnabled: notificationConfig.isEmailMessageEnabled,
                    isMonthlyReportEnabled: notificationConfig.isMonthlyReportEnabled,
                    isSlackMessageEnabled: notificationConfig.isSlackMessageEnabled,
                    isTeamsMessageEnabled: notificationConfig.isTeamsMessageEnabled,
                    isWeeklyReportEnabled: notificationConfig.isWeeklyReportEnabled,
                    severityThreshold: notificationConfig.severityThreshold,
                    webhookSlack: notificationConfig.webhookSlack,
                    webhookTeams: notificationConfig.webhookTeams,
                    webhookSlackValidation: !!notificationConfig.webhookSlack ? true : null,
                    webhookTeamsValidation: !!notificationConfig.webhookTeams ? true : null,
                    additionalEmail: notificationConfig.additionalEmail,
                    _loading: false
                })
            })
            .catch(exception => {
                this.setState({
                    _exception: exception,
                    _loading: false
                })
            })
    }

    updateUserNotificationConfig = () => {
        const {t} = this.props
        let notificationConfig: UserNotificationConfig = {
            isDailyReportEnabled: this.state.isDailyReportEnabled,
            isEmailMessageEnabled: this.state.isEmailMessageEnabled,
            isMonthlyReportEnabled: this.state.isMonthlyReportEnabled,
            isWeeklyReportEnabled: this.state.isWeeklyReportEnabled,
            severityThreshold: this.state.severityThreshold,
            webhookSlack: this.state.webhookSlack,
            webhookTeams: this.state.webhookTeams,
            isSlackMessageEnabled: this.state.isSlackMessageEnabled,
            isTeamsMessageEnabled: this.state.isTeamsMessageEnabled,
            additionalEmail: this.state.additionalEmail
        }

        if (!(!!this.state.webhookSlack)) {
            notificationConfig.isSlackMessageEnabled = false
        }

        if (!(!!this.state.webhookTeams)) {
            notificationConfig.isTeamsMessageEnabled = false
        }
        this.userClient.updateUerNotificationConfig(notificationConfig)
            .then(response => {
                if (response !== undefined && response.status === "BAD_REQUEST") {
                    this.setValid(response.fieldErrors.additionalEmail.join(", "))
                    this.loadUserNotificationConfig()
                    return false;
                }

                this.setValid(true)
                Snackbar.Success(t("common.messages.success.ConfigurationSaved"))
                return true;
            })
            .catch(exception => {
                if (exception instanceof ForbiddenError) {
                    Snackbar.Error(exception.message)
                } else {
                    this.props.openAlertDialog(t("common.messages.error"), exception.message)
                }
                this.setState({_loading: true})
                this.loadUserNotificationConfig()
                return false;
            })
    }

    setStateAndSave(state) {
        this.setState(
            state,
            () => {
                this.updateUserNotificationConfig()
            }
        )
    }

    onChangeNotificationSelection = (name: string) => {
        this.setStateAndSave(prevState => {
            return {[name]: !prevState[name]}
        })
    }

    onWebhookChange = (webhookname, event) => {
        this.setState({[webhookname]: event.target.value})
    }

    onSaveWebhook = (webhookname, event) => {
        const webhook = this.state[webhookname] || ""
        if (!(!!webhook)) {
            if (webhookname === "webhookTeams") {
                this.setStateAndSave({webhookTeams: "", isTeamsMessageEnabled: false})
            }
            if (webhookname === "webhookSlack") {
                this.setStateAndSave({webhookSlack: "", isSlackMessageEnabled: false})
            }
            return
        }

        this.userClient.webhookCheck({
            webhook: webhook,
            webhookType: (webhookname === "webhookSlack" ? "SLACK" : "TEAMS")
        })
            .then(async (response) => {
                if (response && response.status === 400) {
                    const json = await response.json()


                    if (json.fieldErrors?.webhook) {
                        if (webhookname === "webhookTeams") {
                            this.setState({webhookTeamsValidation: json.fieldErrors.webhook.join(", ")})
                        }

                        if (webhookname === "webhookSlack") {
                            this.setState({webhookSlackValidation: json.fieldErrors.webhook.join(", ")})
                        }
                    }

                } else {

                    if (webhookname === "webhookTeams") {
                        this.setStateAndSave({webhookTeamsValidation: true})
                    }

                    if (webhookname === "webhookSlack") {
                        this.setStateAndSave({webhookSlackValidation: true})
                    }
                }

            })
            .catch((error) => {

                if (webhookname === "webhookTeams") {
                    this.setState({webhookTeamsValidation: "Invalid WebHook"})
                }
                if (webhookname === "webhookSlack") {
                    this.setState({webhookSlackValidation: "Invalid WebHook"})
                }
            })
    }

    isValidWebhook(webhook: ?string): boolean {
        return webhook !== null && webhook !== "" && webhook !== undefined
    }

    handleCheckMessageEnabled = (MessageName, event) => {
        this.setState({
            [MessageName]: !this.state[MessageName]
        }, () => {
            if (
                (MessageName === "isSlackMessageEnabled" && !this.isValidWebhook(this.state.webhookSlack) && this.state[MessageName] !== false)
                || (MessageName === "isTeamsMessageEnabled" && !this.isValidWebhook(this.state.webhookTeams) && this.state[MessageName] !== false)
            ) {
                return
            } else {
                this.updateUserNotificationConfig()
            }
        })
    }

    onChangeSeverityThreshold = (event) => {
        this.setStateAndSave({
            severityThreshold: Parser.parseInt(event.target.value)
        })
    }

    render() {
        const {t, classes} = this.props
        const {
            _exception, _loading, webhookSlack, webhookTeams, isSlackMessageEnabled, isEmailMessageEnabled,
            isTeamsMessageEnabled, webhookSlackValidation, webhookTeamsValidation
        } = this.state

        const userEmailAddress = this.jwtToken.getEmail()

        if (_loading) {
            return (<Loader/>)
        }

        if (_exception) {
            throw _exception
        }

        return (
            <div style={{paddingRight: "0.50rem"}}>
                <Headline2>
                    {t("notificationPage.scanSummary.description")}
                </Headline2>

                <div style={{marginBottom: "1rem"}}>
                    <BlockCheckBoxContainer>
                        <BlockCheckBox checked={Converter.toBoolean(this.state.isDailyReportEnabled)}
                                       title={t("extern.user.notification.daily")}
                                       description={t("extern.user.notification.timeDaily")}
                                       onChange={() => {
                                           this.onChangeNotificationSelection("isDailyReportEnabled")
                                       }}/>
                        <BlockCheckBox checked={Converter.toBoolean(this.state.isWeeklyReportEnabled)}
                                       title={t("extern.user.notification.weekly")}
                                       description={t("extern.user.notification.timeWeekly")}
                                       onChange={() => {
                                           this.onChangeNotificationSelection("isWeeklyReportEnabled")
                                       }}/>
                        <BlockCheckBox checked={Converter.toBoolean(this.state.isMonthlyReportEnabled)}
                                       title={t("extern.user.notification.monthly")}
                                       description={t("extern.user.notification.timeMonthly")}
                                       onChange={() => {
                                           this.onChangeNotificationSelection("isMonthlyReportEnabled")
                                       }}/>
                    </BlockCheckBoxContainer>
                </div>

                <p className={classes.paragraph}>
                    <Trans i18nKey={"notificationPage.scanSummary.sendTo"}>
                        Benachrichtigungen werden an <Bold>{{userEmailAddress}}</Bold> versendet
                    </Trans>
                </p>

                <Spacer/>

                <Headline>{t("notificationPage.type.headline")}</Headline>
                <Headline2>{t("notificationPage.type.description")}</Headline2>

                <NotificationConfigBox onCheck={() => {
                    this.handleCheckMessageEnabled("isEmailMessageEnabled")
                }} isChecked={isEmailMessageEnabled} icon={IconEmail}
                                       title={t("notificationPage.type.email.title")}
                                       description={<Trans i18nKey={"notificationPage.type.email.description"}>
                                           Benachrichtigungen werden an <Bold>{{userEmailAddress}}</Bold> versendet
                                       </Trans>}/>

                <NotificationConfigBox onCheck={() => {
                    this.handleCheckMessageEnabled("isTeamsMessageEnabled")
                }} isChecked={isTeamsMessageEnabled} onChange={(event) => {
                    this.onWebhookChange("webhookTeams", event)
                }} onSave={(event) => {
                    this.onSaveWebhook("webhookTeams", event)
                }} webhook={webhookTeams} icon={IconTeams} title={t("notificationPage.type.teams.title")}
                                       description={t("notificationPage.type.teams.description")}
                                       helpTitle={t("notificationPage.type.teams.help.title")}
                                       isValid={webhookTeamsValidation}>
                    <UnorderedList>
                        <ListItem>
                            <Trans i18nKey={"notificationPage.type.teams.help1"}>
                                Wählen Sie in Microsoft Teams neben dem Kanalnamen die <Bold>„Weitere Optionen
                                (⋯)“</Bold> und dann <Bold>„Konnektoren.“</Bold>
                            </Trans>
                        </ListItem>
                        <ListItem>
                            <Trans i18nKey={"notificationPage.type.teams.help2"}>
                                Scrollen Sie durch die Liste der Konnektoren zu <Bold>„Incoming Webhook“</Bold> und
                                wählen Sie <Bold>„Konfigurieren“</Bold>.
                            </Trans>
                        </ListItem>
                        <ListItem>
                            <Trans i18nKey={"notificationPage.type.teams.help3"}>
                                Geben Sie einen Namen für den Webhook ein und wählen Sie <Bold>„Erstellen“</Bold>.
                            </Trans>
                        </ListItem>
                        <ListItem>{t("notificationPage.type.teams.help4")}</ListItem>
                    </UnorderedList>
                </NotificationConfigBox>

                <NotificationConfigBox onCheck={() => {
                    this.handleCheckMessageEnabled("isSlackMessageEnabled")
                }} onChange={(event) => {
                    this.onWebhookChange("webhookSlack", event)
                }} onSave={(event) => {
                    this.onSaveWebhook("webhookSlack", event)
                }} isChecked={isSlackMessageEnabled} webhook={webhookSlack} icon={IconSlack}
                                       title={t("notificationPage.type.slack.title")}
                                       description={t("notificationPage.type.slack.description")}
                                       helpTitle={t("notificationPage.type.slack.help.title")}
                                       isValid={webhookSlackValidation}>
                    <UnorderedList>
                        <ListItem>
                            <Trans i18nKey={"notificationPage.type.slack.help1"}>
                                Unter diesem Link erhalten Sie eine ausführliche Erklärung. <LinkGreen target={"_blank"}
                                                                                                       href="https://api.slack.com/incoming-webhooks">»
                                Zur Erklärung</LinkGreen>
                            </Trans>
                        </ListItem>
                    </UnorderedList>
                </NotificationConfigBox>

                <Headline2>{t("extern.user.notification.messages.doneReports")}</Headline2>
                <SeverityInput key={1} onChange={this.onChangeSeverityThreshold}
                               severity={this.state.severityThreshold}/>
                <Spacer/>
                {this.state.isMainUser && (
                    <AdditionalEmails
                        t={t}
                        additionalEmail={this.state.additionalEmail}
                        setAdditionalEmail={this.setAdditionalEmail}
                        updateNotificationConfig={this.updateUserNotificationConfig}
                        valid={this.state.valid}
                        setValid={this.setValid}
                    />
                )}
            </div>
        )
    }
}

function mapDispatchToProps(dispatch: any) {
    let actions = bindActionCreators({
        openAlertDialog: openAlertDialog,
        openSuccessSnackbar: openSuccessSnackbar
    }, dispatch)
    return {...actions, dispatch}
}

function mapStateToProps() {
    return {}
}

export const UserNotification = compose(
    withStyles(styles),
    withTheme,
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps)
)(_UserNotification)
