//@flow

import MaterialTableCell from "@material-ui/core/TableCell"
import React from "react"
import styled from "styled-components"


type Props = {
    children: any;
    element?: any;
}

const TableCell = styled(MaterialTableCell)`
  font-family: Netto, sans-serif;
  font-size: 1rem;
  height: 3.13rem;
`;

export class StyledTableCell extends React.Component<Props, {}> {

    render() {

        const element = this.props.element || TableCell;

        return React.createElement(
            element,
            this.props,
            this.props.children,
        );
    }
}
