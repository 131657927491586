//@flow

import {withStyles} from "@material-ui/core";
import React from "react";


const styles = theme => ({});

class _TermDescription extends React.Component<any, any> {

    render() {
        return (
            <dd>
                {this.props.children}
            </dd>
        );
    }
}

export const TermDescription = withStyles(styles)(_TermDescription);
