//@flow

import {ApiClient} from "./ApiClient"
import {globalInstanceStorage} from "../../../../GlobalSingle"
import {FetchClient} from "@greenbone/cloud-component-library"

interface IBaseApiClient {
    httpJsonClient: FetchClient;
    urlProvider: any;
}

export class BaseApiClient extends ApiClient implements IBaseApiClient {
    httpJsonClient: FetchClient;
    urlProvider: any;

    constructor(urlProvider: any, authenticationRequired: boolean = true) {
        super();
        if (authenticationRequired) {
            this.httpJsonClient = globalInstanceStorage.getFetchClient();
        } else {
            this.httpJsonClient = globalInstanceStorage.getFetchClient().getUnauthenticatedInstance();
        }
        this.urlProvider = urlProvider;
    }

}

