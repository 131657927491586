import React, {useEffect, useState} from "react"
import styled from "styled-components"
import {FlexColumn, FlexRow, Tiny} from "@greenbone/cloud-component-library"
import ShieldIcon from "../../assets/check-shield.svg"
import BubbleIcon from "../../assets/alert-user.svg"
import CogHandIcon from "../../assets/cog-hand-give.svg"
import CogSearchIcon from "../../assets/cog-search-1.svg"
import LaptopIcon from "../../assets/alert-message-laptop.svg"
import {ReportOverviewRestApiClient} from "../../service/ReportOverviewRestApiClient"
import {CenteredSpinner} from "../../components/CenteredSpinner";

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(5,1fr);
  justify-items: center;

`

const Number = styled.div`
  font-size: 3.125rem;
  margin-left: 2.25rem;

`;

const BlueNumber = styled(Number)`
  color: #7DB5D0;
`;


const Information = styled(FlexRow)`
  justify-content: space-between;
  align-items: center;

`;

const IconSurrounder = styled.div`
  width: 3.125rem;
  height: 3.125rem;
  display: flex;
  justify-content: center;

  padding: 0.5rem;
  border-radius: 50%;


  svg {
    width: 100%;
    height: 100%;
  }
`;

const BlueIconSurrounder = styled(IconSurrounder)`
  background: #7DB5D026;
  color: #7DB5D0;
`;


export function ReportOverview({id}) {

    const [data, setData] = useState(null);

    useEffect(() => {
        async function f() {
            const apiClient = new ReportOverviewRestApiClient();
            const response = await apiClient.getVulnerabilitySortedBySolution(id);

            setData(response.data);
        }


        f();


    }, [id]);

    if (data) {
        return <Grid>
            <FlexColumn justifyContent={"center"} style={{height: "13.25rem"}}>
                <Information>
                    <BlueIconSurrounder>
                        <ShieldIcon/>
                    </BlueIconSurrounder>
                    <BlueNumber>{data.workaround}</BlueNumber>
                </Information>
                <Tiny>Risks Reduction Info</Tiny>
            </FlexColumn>
            <FlexColumn justifyContent={"center"} style={{height: "13.25rem"}}>
                <Information>
                    <BlueIconSurrounder>
                        <BubbleIcon/>
                    </BlueIconSurrounder>
                    <BlueNumber>{data.mitigation}</BlueNumber>
                </Information>
                <Tiny>Temporary Fix Info</Tiny>
            </FlexColumn>
            <FlexColumn justifyContent={"center"} style={{height: "13.25rem"}}>
                <Information>
                    <BlueIconSurrounder>
                        <CogHandIcon/>
                    </BlueIconSurrounder>
                    <BlueNumber>{data.vendorFix}</BlueNumber>
                </Information>
                <Tiny>Official Fix Available</Tiny>
            </FlexColumn>
            <FlexColumn justifyContent={"center"} style={{height: "13.25rem"}}>
                <Information>
                    <BlueIconSurrounder>
                        <CogSearchIcon/>
                    </BlueIconSurrounder>
                    <BlueNumber>{data.willNotFix}</BlueNumber>
                </Information>
                <Tiny>Searching For Fix</Tiny>
            </FlexColumn>
            <FlexColumn justifyContent={"center"} style={{height: "13.25rem"}}>
                <Information>
                    <BlueIconSurrounder>
                        <LaptopIcon/>
                    </BlueIconSurrounder>
                    <BlueNumber>{data.noneAvailable}</BlueNumber>
                </Information>
                <Tiny>No Fix Available</Tiny>
            </FlexColumn>
        </Grid>;
    }

    return <CenteredSpinner height={"13.25rem"}/>


}
