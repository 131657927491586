//@flow

import Promise from "bluebird"
import React from "react"
import {Trans, withTranslation} from "react-i18next"
import {connect} from "react-redux"
import {Col, Row} from "reactstrap"
import {bindActionCreators, compose} from "redux"
import styled from "styled-components"
import ErrorBoundary from "../../../Components/ErrorBoundary"
import {Button, GhostButton, Snackbar} from "@greenbone/cloud-component-library"
import {Bold, Headline, Paragraph, Subheadline, TextLink} from "../../../StyledComponents/Font/Font"
import {Input} from "../../../StyledComponents/Form/Input"
import {getValidationResult} from "../../../controller/FieldValidator"
import {IsNotEmptyString, IsOptionalString} from "../../../controller/FormValidators"
import {InfoBox} from "../components/InfoBox"
import {ScanConfigurationsSelect} from "../components/ScanConfigurationsSelect"


export type TaskFields = {
    name: string;
    comment: string;
    scanConfigurationId: ?number;
}

type Props = {
    nextStep: EventCallback;
    previousStep: EventCallback;
    cancelWizard: EventCallback;
    handleSaveAndNext: EventCallback;
    pageNumber: number;
    initValues: TaskFields;
    t: any
}

type State = {
    fields: TaskFields;
    fieldStatus: {
        name: ?boolean;
        comment: ?boolean;
        scanConfigurationId: ?boolean;
    };
    isValid: boolean;
    scanConfigurationsLimit: number;
}

const InfoHeadline: any = styled.h1`
  font-size: 1.5rem;
  font-weight: normal;
  margin-bottom: 0;
`;

export class _Task extends React.Component<Props, State> {
    setStateAsync: Promise;
    defaultscanConfigurationsLimit = 3;
    FieldValidators = {
        name: new IsNotEmptyString(),
        comment: new IsOptionalString(),
        scanConfigurationId: new IsNotEmptyString(),
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            fields: {...props.initValues},
            fieldStatus: {
                name: null,
                comment: null,
                scanConfigurationId: null,
            },
            isValid: false,
            scanConfigurationsLimit: this.defaultscanConfigurationsLimit,
        };
        this.setStateAsync = Promise.promisify(this.setState);
    }

    handleOnChangeField = (event: any) => {
        const {name, value} = event.target;

        this.setState(prevState => {
            let fields = prevState.fields;
            fields[name] = value;
            return {fields};
        });
    };

    handleChangeScanConfiguration = (scanConfigurationId: number) => {
        this.setState(prevState => {
            let fields = prevState.fields;
            fields["scanConfigurationId"] = scanConfigurationId;
            return {fields};
        });
    };

    validateFields = async (): Promise<void> => {
        const {isValid, fieldValidity} = getValidationResult(
            this.state.fields,
            this.FieldValidators,
        );

        await this.setStateAsync({fieldStatus: fieldValidity, isValid});
    };

    onFormSubmit = (event: SyntheticEvent<HTMLFormElement>) => {
        event.preventDefault();

        this.validateFields()
            .then(() => {
                this.handleFormSubmit();
            });
    };

    handleFormSubmit = () => {
        const {t} = this.props;
        const {isValid, fieldStatus} = this.state;
        const {name, comment, scanConfigurationId} = this.state.fields;

        if (!isValid) {
            if (fieldStatus.scanConfigurationId !== true) {
                Snackbar.Error(t("wizard.steps.task.youHaveToChooseAConfiguration"));
            }
            return;
        }

        this.props.handleSaveAndNext({
            name, comment, scanConfigurationId,
        });
    };

    toggleScanConfigurationsLimit = () => {
        this.setState(prevState => {
            return {
                scanConfigurationsLimit: prevState.scanConfigurationsLimit === this.defaultscanConfigurationsLimit ?
                    99 : this.defaultscanConfigurationsLimit,
            };
        });
    };

    render() {
        const {t} = this.props;
        const {cancelWizard} = this.props;
        const {fields, fieldStatus, scanConfigurationsLimit} = this.state;

        return <React.Fragment>
            <Row style={{marginBottom: "2rem"}}>
                <Col lg={9} md={12}>
                    <Headline>{t("wizard.steps.task.nameTheTask")}</Headline>
                    <Paragraph>
                        {t("wizard.steps.task.createATask")}
                    </Paragraph>
                </Col>
            </Row>

            <Row style={{marginBottom: "4rem"}}>
                <Col lg={9} md={12}>
                    <Row>
                        <Col lg={4}>
                            <Input isValid={fieldStatus.name} value={fields.name}
                                   onChange={this.handleOnChangeField}
                                   name={"name"} label={t("wizard.steps.task.taskName")}/>
                        </Col>
                        <Col lg={8}>
                            <Input isValid={fieldStatus.comment} value={fields.comment}
                                   onChange={this.handleOnChangeField}
                                   name={"comment"} label={t("common.description.optional")}/>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row style={{marginBottom: "4rem"}}>
                <Col lg={{size: 9, order: 1}} md={{size: 12, order: 2}}>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-end",
                        marginBottom: "2.81rem",
                    }}>
                        <Subheadline style={{marginBottom: "0"}}>
                            {t("wizard.steps.task.chooseScanConfiguration")}
                        </Subheadline>
                        <TextLink to={"#"} onClick={this.toggleScanConfigurationsLimit}>
                            {scanConfigurationsLimit === this.defaultscanConfigurationsLimit ?
                                t("wizard.steps.task.extendOptions") : t("wizard.steps.task.extendOptions.hide")
                            }
                        </TextLink>
                    </div>

                    <Row>
                        <ErrorBoundary>
                            <ScanConfigurationsSelect onChange={this.handleChangeScanConfiguration}
                                                      selectedId={fields.scanConfigurationId}
                                                      limit={scanConfigurationsLimit}/>
                        </ErrorBoundary>
                    </Row>
                </Col>
                <Col lg={{size: 3, order: 2}} md={{size: 12, order: 1}}>
                    <InfoBox type={"tip"}>
                        <InfoHeadline>{t("wizard.steps.task.ScanConfiguration")}</InfoHeadline>
                        <Paragraph>
                            <Trans i18nKey={"wizard.task.bestResult"}>
                                <Bold>Für das optimale Resultat und eine angemessene</Bold> Scan-Dauer empfehlen wir die
                                Scan-Konfiguration <Bold>Analyse [Standard]</Bold>.
                            </Trans>
                        </Paragraph>
                        <Paragraph>
                            {t("scanConfigurations.always")}
                        </Paragraph>
                    </InfoBox>
                </Col>
            </Row>

            <Row>
                <Col lg={9} md={12}>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <GhostButton onClick={cancelWizard}>{t("common.action.abort")}</GhostButton>
                        <Button onClick={this.onFormSubmit}>{t("common.action.saveAndContinue")}</Button>
                    </div>
                </Col>
            </Row>
        </React.Fragment>;
    }
}

function mapDispatchToProps(dispatch: any) {
    let actions = bindActionCreators({
        }, dispatch,
    );
    return {...actions, dispatch};
}

function mapStateToProps(state) {
    return {};
}

export const Task = compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps),
)(_Task);
