import React, {useContext, useEffect, useReducer, useState} from "react"
import {ReportContext} from "../ReportContext"
import {ClickableTableCell, LastCell, NameCell, Severity, Table, TableCell} from "../components/TableComponents"
import {Bold, FlexRow, IconButton, Paragraph, SlideIn, Subheadline} from "@greenbone/cloud-component-library"
import {ReportHelper} from "../ReportHelper"
import {ReportRestApiClient} from "../service/ReportRestApiClient"
import {Col, Container, Row} from "reactstrap"
import {Square, SquareWithIcon} from "../components/Squares"
import MonitorIcon from "../assets/monitor.svg"
import ReportIcon from "../assets/report.svg"
import {Extendable} from "../components/MiniVulnerability"
import {VulnerabilityDetails} from "../components/VulnerabilityDetails"
import {ViewSwitcher} from "../components/ViewSwitcher"
import {Cell, TableHead} from "../components/ReportSorting"
import {ReportPaging} from "../components/ReportPaging"
import {GetSolutionTabByType, ScaleSolutionTab} from "../components/SolutionTab"
import {ReportSelectDownloader} from "../components/ReportSelectDownloader"
import {useTheme} from "styled-components"
import {useTranslation} from "react-i18next"
import ErrorBoundary from "../../../Components/ErrorBoundary";


const ShowDetailsPort = ({oid, name, maxSeverity}) => {
    const {getFilter, report} = useContext(ReportContext)

    const [hosts, setHosts] = useState([])
    const [vulnerability, setVulnerability] = useState(null)
    useEffect(() => {
        async function f() {
            const apiClient = new ReportRestApiClient()
            const response = await apiClient.topPortsByOid(report, oid, getFilter())
            setHosts(response.data)


            const vul = await apiClient.getVulnerability(report, oid)
            setVulnerability(vul.data)
        }

        f()
    }, [oid])

    const {base} = useTheme()
    const {t} = useTranslation()

    return <>
        <Container style={{width: "40rem"}}>
            <Row style={{marginBottom: "1rem"}}>
                <Col>
                    <FlexRow alignItems={"center"}>
                        <SquareWithIcon>
                            <MonitorIcon/>
                        </SquareWithIcon>
                        <Subheadline style={{margin: 0}}>
                            {t("report.ShowDetailsPort.headline")}

                        </Subheadline>
                    </FlexRow>
                </Col>
            </Row>
            <Row style={{marginBottom: "1rem"}}>
                <Col>
                    <FlexRow alignItems={"center"}>
                        <Square severity={maxSeverity}>
                            {ReportHelper.SeverityToString(maxSeverity)}
                        </Square>
                        <Bold style={{margin: 0}}>{name}</Bold>
                    </FlexRow>
                </Col>
            </Row>
            {vulnerability && <>
                <Row style={{marginBottom: "1.5rem"}}>
                    <Col>
                        <Bold>
                            {t("report.description")}

                        </Bold>
                        <Paragraph>
                            {vulnerability.summary || "None available"}
                        </Paragraph>
                    </Col>
                </Row>
                <Row style={{marginBottom: "1.5rem"}}>
                    <Col>
                        <div style={{marginBottom: "0.94rem"}}>

                            <Bold>{t("report.solutionType")}</Bold>
                        </div>

                        <ScaleSolutionTab left>
                            {GetSolutionTabByType(vulnerability.solutionType)}
                        </ScaleSolutionTab>
                    </Col>
                </Row>
                <Row style={{marginBottom: "1.5rem"}}>
                    <Col>
                        <Bold>{t("report.solution")}</Bold>
                        <Paragraph>{vulnerability.solution || "None available"}</Paragraph>
                    </Col>
                </Row>

            </>}


            <Extendable defaultOpen={true} name={"Ports"} color={ReportHelper.SeverityToColor(vulnerability?.severity)}>
                {hosts.map((host, index) => <div style={{marginBottom: "0.5rem"}} key={index}>
                        <span style={{
                            marginRight: "1rem",
                            fontWeight: "bold",
                            color: base
                        }}>
                           {index + 1}
                        </span>
                    {host}
                </div>)}
            </Extendable>


        </Container>
    </>
}

const ShowDetailsHost = ({oid, name, maxSeverity}) => {
    const {getFilter, report} = useContext(ReportContext)

    const [hosts, setHosts] = useState([])
    const [vulnerability, setVulnerability] = useState(null)

    useEffect(() => {
        async function f() {
            const apiClient = new ReportRestApiClient()
            const response = await apiClient.topHostsByOid(report, oid, getFilter())
            setHosts(response.data)

            const vul = await apiClient.getVulnerability(report, oid)
            setVulnerability(vul.data)
        }

        f()
    }, [oid])

    const {base} = useTheme()
    const {t} = useTranslation()

    return <>
        <Container style={{width: "40rem"}}>
            <Row style={{marginBottom: "1rem"}}>
                <Col>
                    <FlexRow alignItems={"center"}>
                        <SquareWithIcon>
                            <MonitorIcon/>
                        </SquareWithIcon>
                        <Subheadline style={{margin: 0}}>
                            {t("report.ShowDetailsHost.headline")}

                        </Subheadline>
                    </FlexRow>
                </Col>
            </Row>
            <Row style={{marginBottom: "1rem"}}>
                <Col>
                    <FlexRow alignItems={"center"}>
                        <Square severity={maxSeverity}>
                            {ReportHelper.SeverityToString(maxSeverity)}
                        </Square>
                        <Bold style={{margin: 0}}>{name}</Bold>
                    </FlexRow>
                </Col>
            </Row>

            {vulnerability && <>
                <Row style={{marginBottom: "1.5rem"}}>
                    <Col>
                        <Bold>
                            {t("report.description")}
                        </Bold>
                        <Paragraph>
                            {vulnerability.summary || "None available"}
                        </Paragraph>
                    </Col>
                </Row>
                <Row style={{marginBottom: "1.5rem"}}>
                    <Col>
                        <div style={{marginBottom: "0.94rem"}}>
                            <Bold>{t("report.solutionType")}</Bold>
                        </div>

                        <ScaleSolutionTab left>
                            {GetSolutionTabByType(vulnerability.solutionType)}
                        </ScaleSolutionTab>
                    </Col>
                </Row>
                <Row style={{marginBottom: "1.5rem"}}>
                    <Col>
                        <Bold>{t("report.solution")}</Bold>
                        <Paragraph>{vulnerability.solution || "None available"}</Paragraph>
                    </Col>
                </Row>

            </>}

            <Extendable defaultOpen={true} name={"Hosts"} color={ReportHelper.SeverityToColor(vulnerability?.severity)}>
                {hosts.map((host, index) => <div style={{marginBottom: "0.5rem"}} key={index}>
                        <span style={{
                            marginRight: "1rem",
                            fontWeight: "bold",
                            color: base
                        }}>
                           {index + 1}
                        </span>
                    {host}
                </div>)}
            </Extendable>
        </Container>
    </>
}

const reducer = (state, action) => {
    switch (action.type) {
        case "OPEN-HOSTS":
            return {hostOpen: true}
        case "OPEN-PORTS":
            return {portOpen: true}
        case "OPEN-DETAILS":
            return {detailsOpen: true}
        default:
            break
    }

    return {}
}

const ShowDetails = ({oid}) => {
    const {report} = useContext(ReportContext)

    const [vulnerability, setVulnerability] = useState(null)

    useEffect(() => {
        async function f() {
            const apiClient = new ReportRestApiClient()
            const response = await apiClient.getVulnerability(report, oid)
            setVulnerability(response)
        }

        f()
    }, [oid])

    return <>
        {vulnerability && <VulnerabilityDetails vulnerability={vulnerability.data}/>}
    </>

}

const GridRow = ({vulnerability}) => {


    const [state, dispatch] = useReducer(reducer, {})

    const severity = ReportHelper.toFixed(vulnerability.maxSeverity)


    return <>
        <NameCell severity={severity}>
            {vulnerability.name}
        </NameCell>
        <TableCell>
            <Severity severity={severity}>
                {severity}
            </Severity>
        </TableCell>
        <ClickableTableCell onClick={() => dispatch({type: "OPEN-HOSTS"})}>
            {vulnerability.hostCount}
        </ClickableTableCell>
        <ClickableTableCell onClick={() => dispatch({type: "OPEN-PORTS"})}>
            {vulnerability.portCount}
        </ClickableTableCell>
        <TableCell>
            <ScaleSolutionTab>
                {GetSolutionTabByType(vulnerability.solutionType)}
            </ScaleSolutionTab>
        </TableCell>
        <TableCell>
            {vulnerability.qod}
        </TableCell>
        <LastCell>
            <IconButton onClick={() => dispatch({type: "OPEN-DETAILS"})}>
                <ReportIcon/>
            </IconButton>
        </LastCell>
        <SlideIn open={state.hostOpen} onClose={() => dispatch({})}>
            {state.hostOpen && <ShowDetailsHost {...vulnerability} />}
        </SlideIn>
        <SlideIn open={state.portOpen} onClose={() => dispatch({})}>
            {state.portOpen && <ShowDetailsPort {...vulnerability} />}
        </SlideIn>
        <SlideIn open={state.detailsOpen} onClose={() => dispatch({})}>
            {state.detailsOpen && <ShowDetails {...vulnerability} />}
        </SlideIn>
    </>
}


function Sort(props) {
    const {sort} = useContext(ReportContext)
    const {t} = useTranslation()
    return <>
        <TableHead>{t("report.name")}</TableHead>
        <Cell handleSort={sort("severity")}>{t("report.severity")}</Cell>
        <Cell handleSort={sort("host")}>{t("report.host")}</Cell>
        <Cell handleSort={sort("port")}>{t("report.port")}</Cell>
        <Cell handleSort={sort("solutionType")}>{t("report.solution")}</Cell>
        <Cell handleSort={sort("qod")}>{t("report.qod")}</Cell>
        <TableHead>{t("report.details")}</TableHead>
    </>
}

export function GroupedByVulnerability(props) {
    const {groupedVulnerabilities} = useContext(ReportContext)

    return <ErrorBoundary>
        <FlexRow alignItems={"center"} justifyContent={"space-between"}>
            <ViewSwitcher/>
            <FlexRow alignItems={"center"}>
                <ReportSelectDownloader/>
            </FlexRow>
        </FlexRow>

        <hr/>

        <Table templateColumns={"4fr 1fr 1fr 1fr 1fr 1fr 1fr"}>
            <Sort/>
            {groupedVulnerabilities?.map((vulnerability, index) => <GridRow vulnerability={vulnerability} key={index}/>)}
        </Table>
        <ReportPaging/>
    </ErrorBoundary>

}
