//@flow

interface ICONSTANTS {
    OPEN_ALERT_DIALOG: string;
    CLOSE_ALERT_DIALOG: string;
}

export const CONSTANTS: ICONSTANTS = {
    OPEN_ALERT_DIALOG: "OPEN_ALERT_DIALOG",
    CLOSE_ALERT_DIALOG: "CLOSE_ALERT_DIALOG",

};

export const openAlertDialog = (title: string, text: string) => ({
    type: CONSTANTS.OPEN_ALERT_DIALOG,
    title: title,
    text: text,
});

export const closeAlertDialog = () => ({
    type: CONSTANTS.CLOSE_ALERT_DIALOG,
});
