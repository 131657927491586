

export const loadState = (key) => {
    try {
        const serializedState = localStorage.getItem(key);
        if (serializedState) {
            return JSON.parse(serializedState);
        }

        return null;
    } catch (err) {
        return null;
    }
};
export const persistState = (key, state) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem(key, serializedState);
    } catch (err) {
        // ignoring write errors
    }
};

export const asyncPersistState = async (key, state) => {
    persistState(key, state);
};


