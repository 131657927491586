/* eslint-disable react/prop-types */

import {createGenerateClassName, jssPreset} from "@material-ui/core/styles"
import {create} from "jss"
import React, {Suspense} from "react"

import {Provider} from "react-redux"
import {BrowserRouter, Navigate, Route} from "react-router-dom"
import {PersistGate} from "redux-persist/lib/integration/react"
import {createGlobalStyle} from "styled-components"
import "./App.css"
import Loader from "./Components/Loader/Loader"
import {media} from "./controller/mediaController"
import {MenuProvider, StyleProvider} from "@greenbone/cloud-component-library"
import {appStore, persistor} from "./store"
import {LanguageProvider} from "./Layout/components/LanguageProvider"
import {UserProvider} from "./User/UserProvider"
import {AlertProvider} from "./Context/AlertContext"
import {globalInstanceStorage} from "../GlobalSingle"
import {JssProvider} from "react-jss"
import {SubscriptionProvider} from "./User/Subscritpion/SubscriptionProvider"
import {InformationBarProvider} from "./Layout/components/InformatinBarContext"
import {LayoutProvider} from "./Layout/LayoutContext"
import "@greenbone/cloud-component-library/build/Library.css"
import {SimpleEntityProvider} from "./services/Context/SimpleEntityContext"
import {ProductUpdateProvider} from "./Context/ProductUpdatesProvider"
import {MuiThemeLayer} from "./MuiThemeLayer"
import {Routes} from "react-router";
import {AuthUrlProvider} from "./UrlProvider/AuthUrlProvider";
import {ScanmanagementUrlProvider} from "./UrlProvider/ScanmanagementUrlProvider";
import {RootUrlProvider} from "./UrlProvider/RootUrlProvider";
import {DeveloperLayout} from "./Layout/DeveloperLayout";
import {ApplicationRouter} from "./ApplicationRouter";
import {HelpUrlProvider} from "./UrlProvider/HelpUrlProvider";
import {HelpWithLayout} from "./areas/Help/components/HelpPageWithLayout";
import {NotFound} from "./Components/Router/Components/NotFound";


const GlobalStyle = createGlobalStyle`

    .__react_component_tooltip.type-info {
        background-color: #42801F;
    }

    html {
        font-size: 16px;

        @media (max-width: 1600px) {
            font-size: 13px;
        }

        ${media.isGiant`
        font-size: 13px;
    `}
        ${media.isDesktop`
        font-size: 13px;
    `}
        ${media.isTablet`
        font-size: 13px;
    `}
        ${media.isPhone`
        font-size: 13px;
    `}
    }

    #TABLE_GLOBAL_OVERRIDE_TEXT_ALIGN_CENTER {
        text-align: center;
    }
`

const generateClassName = createGenerateClassName()
const jss = create({
    plugins: [...jssPreset().plugins]
})
// We define a custom insertion point that JSS will look for injecting the styles in the DOM.
jss.options.insertionPoint = document.getElementById("jss-insertion-point")


export function App({theme}) {
    return (

        <StyleProvider theme={theme}>
            <MuiThemeLayer>
                <Suspense fallback={<Loader/>}>
                    <JssProvider jss={jss} generateClassName={generateClassName}>
                        <LayoutProvider>
                            <Provider store={appStore}>
                                <PersistGate persistor={persistor}>
                                    <MenuProvider keycloak={globalInstanceStorage.getKeycloak()}>
                                        <AlertProvider>
                                            <LanguageProvider>
                                                <UserProvider>
                                                    <SimpleEntityProvider>
                                                        <SubscriptionProvider>
                                                            <InformationBarProvider>
                                                                <BrowserRouter>
                                                                    <ProductUpdateProvider>
                                                                        <Routes>
                                                                            <Route
                                                                                path="/"
                                                                                element={<Navigate to="/ui"/>}
                                                                            />
                                                                            <Route exact
                                                                                   path={AuthUrlProvider.login()}
                                                                                   element={<Navigate
                                                                                       to={ScanmanagementUrlProvider.getBase()}
                                                                                   />}/>
                                                                            <Route exact strict={true}
                                                                                   path={RootUrlProvider.getStartpage()}
                                                                                   element={<Navigate
                                                                                       to={ScanmanagementUrlProvider.getBase()}
                                                                                   />}
                                                                            />
                                                                            <Route path="/ui/developer/*"
                                                                                   Component={DeveloperLayout}
                                                                            />
                                                                            <Route
                                                                                path={RootUrlProvider.getWildcard()}
                                                                                Component={ApplicationRouter}
                                                                            />
                                                                            <Route path={HelpUrlProvider.getBase()}
                                                                                   Component={HelpWithLayout}/>
                                                                            <Route
                                                                                path="/ui"
                                                                                element={<Navigate
                                                                                    to={ScanmanagementUrlProvider.getBase()}
                                                                                />}
                                                                            />
                                                                            <Route path="/ui/*" Component={NotFound}/>
                                                                        </Routes>
                                                                        <GlobalStyle/>
                                                                    </ProductUpdateProvider>
                                                                </BrowserRouter>
                                                            </InformationBarProvider>
                                                        </SubscriptionProvider>
                                                    </SimpleEntityProvider>
                                                </UserProvider>
                                            </LanguageProvider>
                                        </AlertProvider>
                                    </MenuProvider>
                                </PersistGate>
                            </Provider>
                        </LayoutProvider>
                    </JssProvider>

                </Suspense>
            </MuiThemeLayer>
        </StyleProvider>

    )
}




