import React from "react"
import {useLocation, useNavigate, useSearchParams} from "react-router-dom"

function isInt(param) {
    return (/^[0-9]+$/.test(param))
}

export function withReportUrlParamParser(Component) {
    return function WrappedComponent(props) {
        const location = useLocation()
        const navigate = useNavigate()

        const {report, task} = useReportUrlParamParser()
        if (isInt(report) && isInt(task)) {
            return <Component {...props} report={report} task={task} navigate={navigate} location={location} />
        }
        return <Component {...props} report={null} task={null} navigate={navigate} location={location} />

    }
}

export function useReportUrlParamParser() {
    const [searchParams] = useSearchParams()

    return Object.fromEntries(searchParams)
}
