import {IconButton, Typography} from "@material-ui/core"
import {Description, Warning} from "@material-ui/icons"
import React, {useState} from "react"
import styled from "styled-components"
import {Trans, useTranslation} from "react-i18next"
import {Link} from "react-router-dom"
import Loader from "../../../Components/Loader/Loader"
import {loaderType} from "../../../Components/Loader/LoaderType"
import {DateTimeFormatter} from "../../../controller/DateTimeFormatter"
import {Value} from "../../../Helper/Validator"
import {ReportDownloadLink} from "../../Reporting/components/ReportDownloadLink"
import {ReportStatus} from "../types"
import {ReportErrorView} from "./ReportErrorView"
import {Tooltip} from "../../../Components/Dialogs/Callout"
import {ReportNavigationUrlProvider} from "../../Reporting/ReportNavigationUrlProvider"
import {DynamicTableCell, DynamicTableRow} from "../../Task/components/Table/DynamicTable"
import DeleteButton from "../../../Components/ActionElements/DeleteButton"
import {Spinner} from "@greenbone/cloud-component-library"

const StyledWarning = styled(Warning)`
    vertical-align: bottom;
    font-size: 1.25rem;
    color: #b1a61c;
    padding-left: 0.31rem;
`

function isDeleteEnabled(status) {
    return (status === ReportStatus.DONE ||
        status === ReportStatus.STOPPED ||
        status === ReportStatus.INTERNAL_ERROR ||
        status === ReportStatus.INTERRUPTED)
}


export function ReportTableRow(props) {

    const {t} = useTranslation()
    const {report} = props
    const {deleteEntity} = props
    const validDownloadList = [ReportStatus.DONE]
    const [isDeleting, setDeleting] = useState(false)


    const deleteActionHandler = (e) => {
        e?.preventDefault()

        setDeleting(true)
        deleteEntity(report.id, () => setDeleting(false))
    }

    if (!report.isMigrated) {
        return <DynamicTableRow key={report.id}>
            <DynamicTableCell>
                {report.scanEnd ? <nobr>{DateTimeFormatter.toDateTimeString(report.scanEnd)}</nobr> : ""}
            </DynamicTableCell>
            <DynamicTableCell colSpan={9}>
                {t("reportTableTow.notMigrated")}
            </DynamicTableCell>
        </DynamicTableRow>
    }


    if (report.status === ReportStatus.INTERNAL_ERROR) {
        return <DynamicTableRow key={report.id}>
            <DynamicTableCell>
                {report.scanEnd ? <nobr>{DateTimeFormatter.toDateTimeString(report.scanEnd)}</nobr> : ""}
            </DynamicTableCell>
            <DynamicTableCell colSpan={8}>{t("scanmanagement.report.error")}</DynamicTableCell>
        </DynamicTableRow>
    }

    if (report.status === ReportStatus.REQUESTED) {
        return <DynamicTableRow key={report.id}>
            <DynamicTableCell><Loader type={loaderType.INLINE}/></DynamicTableCell>
            <DynamicTableCell colSpan={8}>{t("scanmanagement.reportTable.NoData")}</DynamicTableCell>
        </DynamicTableRow>
    }

    return (
        <DynamicTableRow key={report.id}>
            <DynamicTableCell>
                {report.scanEnd ? <nobr>{DateTimeFormatter.toDateTimeString(report.scanEnd)}</nobr> :
                    <nobr>Not yet</nobr>}
            </DynamicTableCell>
            <DynamicTableCell>
                {report.progress === 100 ? (null) :
                    <Tooltip type={"warning"} id={"WARNING-REPORT-NOT-FIN"} tooltip={(
                        <div>
                            <Typography
                                variant="subtitle1">{t("reportTableRow.reportUnfinished")}</Typography>
                            <Trans i18nKey={"reportTableRow.reportUnfinishedText"}>
                                Stoppen Sie die Scan-Aufgabe.
                            </Trans>
                        </div>
                    )}>
                        <StyledWarning/>
                    </Tooltip>}
            </DynamicTableCell>
            <DynamicTableCell>{t("report.vulnerability.severity", {"number": report.severity})} </DynamicTableCell>
            <DynamicTableCell>{report.high}</DynamicTableCell>
            <DynamicTableCell>{report.medium}</DynamicTableCell>
            <DynamicTableCell>{report.low}</DynamicTableCell>
            <DynamicTableCell>{report.log}</DynamicTableCell>
            <DynamicTableCell>
                {report.errors.length <= 0 && (
                    <>
                        <Link to={{
                            pathname: ReportNavigationUrlProvider.Dashboard(),
                            search: `?${new URLSearchParams({
                                task: props.task.id,
                                report: report.id
                            }).toString()}`
                        }}>
                            <IconButton>
                                <Description/>
                            </IconButton>
                        </Link>
                    </>)}
                <ReportErrorView errors={report.errors}/>
            </DynamicTableCell>
            <DynamicTableCell style={{textAlign: "center"}}>
                {isDeleting ?
                    <Spinner/>
                    :
                    <DeleteButton
                        disabled={!isDeleteEnabled(report.status)}
                        deleteEntity={deleteActionHandler}
                        confirmMessage={t("scanmanagement.reportTable.deleteReportIllegalStateWarning")}
                    />
                }
            </DynamicTableCell>
            <DynamicTableCell style={{textAlign: "center"}}>
                <ReportDownloadLink executive
                                    isDownloadable={Value(report.status).isInList(validDownloadList)}
                                    report={report}
                                    disabled={!Value(report.status).isInList(validDownloadList)}
                                    reportId={report.id}
                                    lastTaskExecutionId={report.taskExecutionId}
                                    taskId={report.taskId}
                />
            </DynamicTableCell>
            <DynamicTableCell style={{textAlign: "center"}}>
                <ReportDownloadLink report={report}
                                    reportId={report.id}
                                    isDownloadable={Value(report.status).isInList(validDownloadList)}
                                    disabled={!Value(report.status).isInList(validDownloadList)}
                                    lastTaskExecutionId={report.taskExecutionId}
                                    taskId={report.taskId}

                />
            </DynamicTableCell>
        </DynamicTableRow>
    )

}

