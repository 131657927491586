import React, {useContext} from "react";
import {IconSurrounder} from "./Shared";
import styled, {css, ThemeContext} from "styled-components";
import {NavLink} from "react-router-dom";


const MenuListItem = styled.li`
  list-style: outside none none;

  span {
    margin-left: 0.5rem;
  }
`;


const LinkInSubmenuStyledComponent = styled((
        {
            to,
            children,
            active,
            className
        }
    ) => <NavLink
        to={to}
        active={active}
        className={className}
    >
        {children}
    </NavLink>
)`
  display: flex;
  justify-content: flex-start;
  padding: 0.63rem;
  padding-left: 2.88rem;
  align-items: center;
  height: 4rem;
  box-sizing: border-box;
  color: ${props => props.theme.sidebar.font};

  &:hover {
    text-decoration: none;
    color: ${props => props.theme.sidebar.hoverFont};
    background-color: ${props => props.theme.sidebar.hoverBackground};
    ${props => props.open && css`box-shadow: inset 4px 0 0 ${props => props.theme.sidebar.insetShadow};`}
  }

  &.active {
    background: ${props => props.theme.sidebar.activeMenuitem};
    box-shadow: ${props => `inset 4px 0 0 ${props.theme.sidebar.insetShadow}`}
  }

  ${props => props.minified && css`
    padding: 0;
    justify-content: center;

    img {
      margin: 0;
    }
  `}
`;


function LinkInSubmenu({children, minified, ...other}) {
    //TODO - jwerremeyer move to styled-components api as soon as it ships --> https://github.com/styled-components/styled-components/pull/2765

    return <LinkInSubmenuStyledComponent
        minified={minified}
        {...other}
    >
        {children}
    </LinkInSubmenuStyledComponent>;
}

export const ListMenuItem = ({to, icon, children, minified, open, active}) => {
    const IconComponent = icon;
    return <MenuListItem>
        <LinkInSubmenu open={open} minified={minified} to={to} active={active}>
            <IconSurrounder>
                <IconComponent/>
            </IconSurrounder>
            {minified === false && <span>{children}</span>}
        </LinkInSubmenu>
    </MenuListItem>;
};
