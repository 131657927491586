/*
TODO - jwerremeyer MUST Move into Library after Review and QA
 */
import React, {useEffect, useState} from "react"
import {usePagination, useSortBy, useTable} from "react-table"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import TableFooter from "@material-ui/core/TableFooter"
import TablePagination from "@material-ui/core/TablePagination"
import {TablePaginationActions} from "./TablePaginationActions"
import {useSearch} from "./plugins/useSearch"
import {FlexRow, Headline} from "@greenbone/cloud-component-library"
import TableSortLabel from "@material-ui/core/TableSortLabel"
import styled from "styled-components"
import {useSortCache} from "./plugins/useSortCache"
import {useCachePageSize} from "./plugins/useCachePageSize"
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment"
import Search from "@material-ui/icons/Search"
import {TextField} from "../../../../Components/Input"
import {useTranslation} from "react-i18next"
import {UnfoldMore} from "@material-ui/icons"
//TODO - jwerremeyer - MUST Pull from library when extracting
export const MuiTableStyled = styled(Table)`
    tbody tr:nth-of-type(2n+1) {
        background: #FBFBFB;
    }


    tbody tr:hover {
        background: rgb(213, 213, 213) none repeat scroll 0% 0%;
        box-shadow: rgb(112, 112, 112) 4px 0px 0px inset;
    }
`

const tableCellHeight = "3.13rem"

const MuiTableHeadStyles = styled(TableHead)`
    th {
        font-weight: bold;
    }

    tr:last-child th {
        border-bottom: 2px solid #707070;
    }

`

const MuiTableRowStyled = styled(TableRow)`
    &.not-found {
        background: none !important;

        &:hover {
            background: none;
            box-shadow: none;
        }
    }
`

const MuiTableCellStyled = styled(TableCell)`
    && {
        height: ${tableCellHeight};
        padding: 1rem;
        padding-top: 0;
        padding-bottom: 0;
    }

    &.no-border {
        border-bottom-color: transparent;
    }
`


const pageSizeOptions = [5, 10, 25]

export function DynamicTable({
                                 data,
                                 columns,
                                 searchableColumns,
                                 defaultSortBy,
                                 cacheKey,
                                 actionCell,
                                 entryGenerator,
                                 actions
                             }) {
    const [isMounted, setMounted] = useState(false)

    useEffect(() => {
        setMounted(true)
    }, [isMounted, setMounted])

    if (!Array.isArray(data)) {
        throw  new Error("data props must be an array.")
    }

    if (!Array.isArray(columns)) {
        throw new Error("columns props must be an array ")
    }

    if (actionCell) {
        columns.push({
            Header: "Actions",
            accessor: "actions"
        })
    }
    const ActionCell = actionCell


    const initialState = {
        sortBy: [...defaultSortBy.map(col => ({id: col, desc: false}))]
    }

    const tableInstance = useTable({
        columns,
        data,
        initialState,
        searchableColumns,
        cacheKey,
        autoResetSortBy: false
    }, useSearch, useSortBy, usePagination, useSortCache)

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        gotoPage,
        foundRows,
        searchValue,
        setPageSize,
        setSearchValue,
        state: {pageIndex, pageSize}
    } = tableInstance


    useCachePageSize(cacheKey, pageSize, setPageSize, pageSizeOptions, isMounted)

    const {t} = useTranslation()

    return <>
        <TableContainer>
            <MuiTableStyled {...getTableProps()}>
                <MuiTableHeadStyles>
                    <MuiTableRowStyled>
                        <MuiTableCellStyled colSpan={columns.length}>
                            <FlexRow justifyContent={"space-between"}>
                                {actions}
                                <TextField onChange={e => setSearchValue(e.target.value)}
                                           placeholder={t("common.search")}
                                           InputProps={{
                                               startAdornment: (
                                                   <InputAdornment position="start">
                                                       <Search/>
                                                   </InputAdornment>
                                               )
                                           }}
                                />
                            </FlexRow>
                        </MuiTableCellStyled>
                    </MuiTableRowStyled>
                    {
                        headerGroups.map(headerGroup => (
                            <MuiTableRowStyled {...headerGroup.getHeaderGroupProps()}>
                                {
                                    headerGroup.headers.map(column => (
                                            <MuiTableCellStyled {...column.getHeaderProps(column.getSortByToggleProps())}>
                                                {column.canSort ?
                                                    <TableSortLabel active={column.isSorted}
                                                                    direction={column.isSortedDesc ? "desc" : "asc"}>
                                                        {column.render("Header")}
                                                        {column.isSorted !== true && <UnfoldMore/>}
                                                    </TableSortLabel> :
                                                    column.render("Header")
                                                }
                                            </MuiTableCellStyled>
                                        )
                                    )
                                }
                            </MuiTableRowStyled>
                        ))
                    }

                </MuiTableHeadStyles>
                <TableBody {...getTableBodyProps()}>
                    {page.map(row => {
                        prepareRow(row)
                        if (entryGenerator) {
                            const EntryGenerator = entryGenerator
                            return <EntryGenerator {...row.getRowProps()} row={row} dataset={row.original}
                                                   getValue={(name) => {
                                                       const cell = row.cells.find(cell => cell.column.id === name)
                                                       if (cell) {
                                                           return cell.render("Cell")
                                                       }
                                                       return null
                                                   }
                                                   }/>
                        }
                        return (
                            <MuiTableRowStyled {...row.getRowProps()}>
                                {row.cells.map(cell => (
                                    <>
                                        {cell.column.id === "actions" ?
                                            <MuiTableCellStyled {...cell.getCellProps()}>
                                                <ActionCell/>
                                            </MuiTableCellStyled>
                                            :
                                            <MuiTableCellStyled {...cell.getCellProps()}>
                                                {cell.render("Cell")}
                                            </MuiTableCellStyled>
                                        }
                                    </>
                                ))}
                            </MuiTableRowStyled>
                        )
                    })}

                    {(page.length < pageSize && foundRows.length !== 0) &&
                    Array(pageSize - page.length).fill().map((_, index) => (
                        <MuiTableRowStyled key={index} className={"not-found"}>
                            <MuiTableCellStyled className={"no-border"} colSpan={columns.length}></MuiTableCellStyled>
                        </MuiTableRowStyled>
                    ))}

                    {
                        (foundRows.length === 0 && searchValue && searchValue !== "") &&

                        <MuiTableRowStyled className={"not-found"}>
                            <MuiTableCellStyled style={{height: `calc(${tableCellHeight} * ${pageSize})`}}
                                                colSpan={columns.length}>
                                <FlexRow justifyContent={"center"} alignItems={"center"}>
                                    <Headline noMargin>No results for: {searchValue}</Headline>
                                </FlexRow>
                            </MuiTableCellStyled>
                        </MuiTableRowStyled>


                    }
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination colSpan={columns.length}
                                         rowsPerPageOptions={pageSizeOptions}
                                         count={rows.length}
                                         rowsPerPage={pageSize}
                                         page={pageIndex}
                                         SelectProps={{
                                             inputProps: {"aria-label": "rows per page"},
                                             native: false
                                         }}
                                         onPageChange={gotoPage}
                                         onRowsPerPageChange={e => {
                                             setPageSize(Number(e.target.value))
                                         }}
                                         ActionsComponent={TablePaginationActions}


                        />
                    </TableRow>
                </TableFooter>
            </MuiTableStyled>
        </TableContainer>
    </>
}

export const DynamicTableRow = MuiTableRowStyled
export const DynamicTableCell = MuiTableCellStyled
