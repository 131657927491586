const URL_PARAM_NAME = "jumptoken"

async function fetchAuthToken(jumpToken) {
    const url = new URL(`/usermanagement/public/jumpIn/login/${jumpToken}`, window.location.origin)


    return await fetch(url.toString())
        .then(async response => {
            const json = await response.json()
            return json
        })
        .catch(e => {
            console.log(e)
        })
}

export function jumpInParameterFound() {
    try {
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString)

        const jumpToken = urlParams.get(URL_PARAM_NAME)

        if (jumpToken) {
            return true
        }
    } catch (e) {
        //Swallow and pretend nothing happened
    }
    return false
}

export async function buildJumpInConfig() {

    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    const jumpToken = urlParams.get("jumptoken")

    const token = await fetchAuthToken(jumpToken)

    return {
        enabled: true,
        ...token
    }

}
