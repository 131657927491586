//@flow

import {withStyles, withTheme} from "@material-ui/core"
import React from "react"
import {Trans, withTranslation} from "react-i18next"
import {NavLink} from "react-router-dom"
import {compose} from "redux"
import styled from "styled-components"
import {EnhancedTable} from "../../../Components/Table/EnhancedTable"
import {media} from "../../../controller/mediaController"
import {Button} from "@greenbone/cloud-component-library"
import {RootUrlProvider} from "../../../UrlProvider/RootUrlProvider"
import {DashboardTaskEntry} from "./DashboardTaskEntry"
import {TaskExecutionStatusMapper} from "./TaskExecutionStatus"
import {TaskContext} from "../TaskContext"
import {MuiTableStyled} from "../../Task/components/Table/DynamicTable"
import {IsList} from "../../../Components/IsList"

function statusToInt(status) {
    if (status === "RUNNING")
        return 0
    if (status === "REQUESTED")
        return 1
    if (status === "AVAILABLE")
        return 2
    if (status === "INTERNAL_ERROR")
        return 3
    if (status === "NEW")
        return 4
    if (status === "EXPORT_REQUESTED")
        return 5
    if (status === "EXPORTED")
        return 6
    if (status === "EXPORT_FAILED")
        return 7
    if (status === "STOP_REQUESTED")
        return 8
    if (status === "STOPPED")
        return 9
    if (status === "DONE")
        return 10
    if (status === "DELETE_REQUESTED")
        return 11

    return 12
}

const TableSurrounder = styled.div`
    table {
        ${media.isUltra`
        th:nth-child(9),
        td:nth-child(9),
        th:nth-child(10),
        td:nth-child(10)
        {
          display: none;
        }
    `};
    }`

const styles = theme => ({
    root: {
        flexGrow: 1
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular
    },
    chip: {
        backgroundColor: "green"
    },
    table: {
        width: "100%",
        textAlign: "center",
        fontFamily: ["Netto", "sans-serif"]
    },
    startStopHeader: {width: "3.13rem", padding: 0},
    lastReportHeader: {width: "3.13rem", padding: 0},
    showOnChartHeader: {width: "3.13rem", padding: 0, textAlign: "center"},
    displayHeader: {},
    nameHeader: {},
    statusHeader: {},
    reportsHeader: {},
    lastScanHeader: {minWidth: "9.38rem"},
    progressHeader: {}
})

type Props = {
    classes: typeof styles;
    t: any;
}


class _TaskList extends React.Component<Props, any> {


    render() {
        const {classes, t} = this.props

        const columnData: Array<ColumnData> = [
            {id: "startStop", headerStyle: classes.startStopHeader, numeric: false, label: "", sortable: false},
            {id: "lastReport", headerStyle: classes.lastReportHeader, numeric: false, label: "", sortable: false},
            {
                id: "name",
                headerStyle: classes.nameHeader,
                numeric: false,
                label: t("scanmanagement.list.task"),
                sortable: true
            },
            {
                id: "status",
                headerStyle: classes.statusHeader,
                numeric: false,
                label: t("scanmanagement.list.status"),
                sortable: true
            },
            {
                id: "progress",
                headerStyle: classes.progressHeader,
                numeric: false,
                label: t("scanmanagement.list.progress"),
                sortable: false
            },
            {
                id: "reports",
                headerStyle: classes.reportsHeader,
                numeric: false,
                label: t("scanmanagement.list.reports"),
                sortable: true
            },
            {
                id: "lastScan",
                headerStyle: classes.lastScanHeader,
                numeric: false,
                label: t("scanmanagement.list.lastScan"),
                sortable: true
            },
            {
                id: "target",
                headerStyle: classes.lastScanHeader,
                numeric: false,
                label: t("scanmanagement.list.target"),
                sortable: true
            },
            {
                id: "executive",
                headerStyle: null,
                numeric: false,
                label: t("scanmanagement.list.executive"),
                sortable: false
            },
            {
                id: "technical",
                headerStyle: null,
                numeric: false,
                label: t("scanmanagement.list.technical"),
                sortable: false
            }
        ]

        return <TaskContext.Consumer>
            {({tasks, taskExecutions, overview, _loading}: any) => {


                function entryGenerator(task: GSPTask) {

                    let progress = -1
                    let statusInformation = null
                    let taskExecutionStatus = TaskExecutionStatusMapper.NEW


                    if (taskExecutions[task.id]) {
                        progress = taskExecutions[task.id].progress
                        statusInformation = taskExecutions[task.id].statusInformation
                        taskExecutionStatus = TaskExecutionStatusMapper[taskExecutions[task.id].status]

                    }


                    const findResult = overview.find(e => e.taskId === task.id)


                    return <DashboardTaskEntry key={task.id}
                                               task={task}
                                               reportCount={findResult?.reportCount}
                                               lastScan={findResult?.scanStart}
                                               latestReportId={findResult?.lastReportId}
                                               progress={progress}
                                               transition={taskExecutions[task.id]?.transition}
                                               statusInformation={statusInformation}
                                               taskExecutionStatus={taskExecutionStatus}
                                               lastTaskExecutionId={findResult?.lastTaskExecutionId}
                                               isDownloadable={findResult?.isDownloadable}
                    />
                }

                return <TableSurrounder>
                    <IsList loading={_loading} list={tasks}
                            message={<Trans i18nKey={"alertContext.serviceUnavailable"}></Trans>}>
                        {() =>
                            <EnhancedTable
                                tableElement={MuiTableStyled}
                                headline={
                                    <NavLink to={RootUrlProvider.wizard()}>
                                        <Button style={{margin: "0"}}>{t("scanManagement.prepareNewScan")}</Button>
                                    </NavLink>
                                }
                                Ena elements={tasks.map(task => {
                                const taskExecution = taskExecutions[task.id]
                                const findResult = overview.find(e => e.taskId === task.id)
                                return {
                                    ...task, ...taskExecution,
                                    status: statusToInt(taskExecution?.status),
                                    lastScan: findResult?.scanStart,
                                    reports: findResult?.reportCount
                                }
                            })}
                                elementGenerator={entryGenerator}
                                columnData={columnData}
                                searchOn={["name"]}
                                order={"asc"}
                                orderBy={"status"}
                                cacheKey={"Scanmanagement/2020-10-02"}
                            />
                        }
                    </IsList>
                </TableSurrounder>
            }
            }

        </TaskContext.Consumer>
    }
}

export const TaskList = compose(
    withStyles(styles),
    withTheme,
    withTranslation()
)(_TaskList)
