//@flow

import {css} from "styled-components"


const displaySizes = {
    phone: 576,
    tablet: 768,
    desktop: 992,
    giant: 1200,
    ultra: 1350,
};

/**
 * media queries must be defined in the following order: isGiant, isDesktop, isTablet, isPhone
 */
export const media: { isGiant: css, isDesktop: css, isTablet: css, isPhone: css, isUltra: css }
    = (Object.keys(displaySizes)
    .reduce((finalMedia, size) => {
        return {
            ...finalMedia,
            ["is" + size[0].toUpperCase() + size.slice(1)]: function (...args: any) {
                return css`
              @media(max-width: ${displaySizes[size] + 1}px) {
                ${css(...args)}
              }
            `;
            },
        };
    }, {}): any);
