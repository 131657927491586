export function objectIsInList(list, object, propertyName) {
    if (!Array.isArray(list)) {
        throw new Error("Expected Array, received something else");
    }

    let found = false;

    list.forEach(item => {
        // eslint-disable-next-line eqeqeq
        if (item[propertyName] == object[propertyName]) {
            found = true;
        }
    });

    return found;
}

export function updateObjectInList(list, object, propertyName) {
    if (!Array.isArray(list)) {
        throw new Error("Expected Array, received something else");
    }

    list.forEach((item, index) => {
        // eslint-disable-next-line eqeqeq
        if (item[propertyName] == object[propertyName]) {
            list[index] = object;
        }
    });

    return list;
}

export function deleteObjectFromList(list, object, propertyName) {
    if (!Array.isArray(list)) {
        throw new Error("Expected Array, received something else");
    }

    const hitprop = object[propertyName];
    let index = null;
    list.forEach((item, i) => {
        // eslint-disable-next-line eqeqeq
        if (item[propertyName] == hitprop) {
            index = i;
        }
    });

    if (index !== null) {
        list.splice(index, 1);
        return list;
    }

    return list;
}

export function addObjectToList(list, object) {
    if (!Array.isArray(list)) {
        throw new Error("Expected Array, received something else");
    }

    return [...list, object];
}
