//@flow
import * as HttpStatuscode from "http-status-codes"


export class ConflictError extends Error {
    httpStatus = HttpStatuscode.CONFLICT
    type = ConflictError
    responseBody = null

    constructor(message: string = "", responseBody) {
        super(`Conflict: '${message}'`)
        this.responseBody = responseBody
    }
}
