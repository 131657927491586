import React from "react";
import styled from "styled-components";
import {Container} from "reactstrap";


export const Navbar = styled.div`
  position: relative;
  height: 3.75rem;
  background: ${props => props.theme.navbar.background};
  display: flex;
  align-items: center;
  align-content: center;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,0.32);

`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: stretch;
`;

export const Content = styled(Container)`
  padding: 1.875rem;
  min-width: 0;
`;

export const Branding = styled.img`
  height: 2.8rem;
  margin-left: 1.25rem;
`;
