//@flow


const Address4 = require("ip-address").Address4
const IP = require("ip")
var Addr = require("netaddr").Addr

export class IP4 {

    static trim(input: string) {
        input = input?.trim()
        input = input?.replace(/ /g, "")
        return input
    }

    static isValid(input: string) {
        let isValid = false

        input = IP4.trim(input)

        if (IP4.listIsValid(input)) {
            isValid = true
        }

        if (input.indexOf("-") > -1) {
            isValid = IP4.rangeIsValid(input)
        }

        if (IP4.addressIsValid(input)) {
            isValid = true
        }

        return isValid
    }

    static convertIfNecessary(input: string) {
        if (input.indexOf("/") > -1) {
            return IP4.convertCIDRAddress(input)
        }
        return input
    }

    static addressIsValid(addr: string) {
        const v4 = new Address4(addr)
        return v4.isValid()
    }

    static convertCIDRAddress(addr: string): string {
        if (addr.indexOf("/") > 0) {
            if (addr.split("/").length > 2) {
                throw new Error("Ungültige Eingabe")
            }

            const end = addr.split("/")[1]

            return `${IP.cidrSubnet(addr).networkAddress}/${end}`
        }
        throw new Error("Invalid input")
    }

    static splitToAddresses(address: string) {
        return address.split(",").map(addr => addr?.trim()).filter(addr => (new Address4(addr)).isValid())
    }

    static listIsValid(addr: string) {

        if (addr.indexOf(",") >= 0) {

            const ipAddresses = addr.split(",")

            for (let i = 0; i < ipAddresses.length; i++) {
                const ip = new Address4(ipAddresses[i]?.trim())
                if (!ip.isValid()) {
                    return false
                }
            }

            return true
        }

        return false

    }

    static rangeIsValid(addr: string) {
        if (addr.indexOf("-") > 0) {
            const ipAddresses = addr.split("-")
            if (ipAddresses.length > 2) {
                return false
            }
            const first = new Address4(ipAddresses[0])
            const last = new Address4(ipAddresses[1])

            if (first.isValid() === false || last.isValid() === false) {
                return false
            }

            return IP.toLong(first.correctForm()) < IP.toLong(last.correctForm())

        }
    }

    static ipRangeIntersects(needle: string, haystack: Array<string>) {
        const ipAddresses = needle.split("-")
        if (this.isAddressIncluded(ipAddresses[0], haystack)) {
            return true
        }
        if (this.isAddressIncluded(ipAddresses[1], haystack)) {
            return true
        }
        return false
    }

    static ipNetworkIntersects(needle: string, haystack: Array<string>) {
        const subnet = IP.cidrSubnet(needle)
        const firstAddress = subnet.firstAddress
        const lastAddress = subnet.lastAddress
        if (this.isAddressIncluded(firstAddress, haystack)) {
            return true
        }
        if (this.isAddressIncluded(lastAddress, haystack)) {
            return true
        }

        return false
    }

    static ipInputIntersects(needle: string, haystack: Array<string>) {
        if (needle.indexOf("-") > -1) {
            return this.ipRangeIntersects(needle, haystack)
        }

        if (needle.indexOf("/") > -1) {
            return this.ipNetworkIntersects(needle, haystack)
        }

        return this.isAddressIncluded(needle, haystack)
    }

    /**
     * Returns true if the ip is inside the subnet
     * @param ip
     * @param subnet
     * @returns {boolean|boolean}
     */
    static isAddressInSubnet(ip: string, subnet: string): boolean {
        const createdSubnet = IP.cidrSubnet(subnet)
        const ipAsLong = IP.toLong(ip)

        return (ipAsLong <= IP.toLong(createdSubnet.lastAddress) && ipAsLong >= IP.toLong(createdSubnet.firstAddress))
    }

    static isSubnetInSubnet(n1: string, n2: string): boolean {
        const subnet = Addr(n1)
        return subnet.intersect(Addr(n2))
    }


    static isSubnet(ip: any): any {
        const createdSubnet = IP.cidrSubnet(ip)
        return ip.split("/")[0] === createdSubnet.networkAddress
    }

    static isAddressIncluded(needle: string, haystack: Array<string>) {

        let isIncluded = false

        for (let i = 0; i < haystack.length; i++) {
            const address = haystack[i]

            if (address.split("-").length > 2 ||
                address.split("/").length > 2) {
                throw new Error("Ungültige Eingabe")
            }

            if (address.indexOf("-") > -1) {
                const range = address.split("-")
                const start = IP.toLong(range[0])
                const end = IP.toLong(range[1])
                const host = IP.toLong(needle)

                if (host <= end && host >= start) {
                    isIncluded = true
                }
            }

            if (address.indexOf("/") > -1) {

                const subnet = IP.cidrSubnet(address)
                const firstAddress = subnet.firstAddress
                const lastAddress = subnet.lastAddress
                const host = IP.toLong(needle)
                const start = IP.toLong(firstAddress)
                const end = IP.toLong(lastAddress)

                if (host <= end && host >= start) {
                    isIncluded = true
                }
            }

            if (address === needle) {
                throw new Error("Same IP")
            }
        }
        return isIncluded
    }

}


