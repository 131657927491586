import {IP4} from "../../../Helper/IP4"
import {IP6} from "@greenbone/cloud-ip-library"
import {Snackbar} from "@greenbone/cloud-component-library"
import {DomainResolver} from "../../../Helper/DomainResolver"

export class TargetFormHostHandler {
    constructor(setState, getState, translate, snackbar = Snackbar, domainResolver = new DomainResolver()) {
        this.setState = setState
        this.getState = getState
        this.translate = translate
        this.snackbar = snackbar
        this.domainResolver = domainResolver
    }

    addHost(userInput, callback) {
        if (IP4.trim(userInput) === "" || !!IP4.trim(userInput) === false) {
            return
        }


        if (IP4.isValid(userInput)) {
            this.resolveAddress(userInput, callback)
        } else if (IP6.isValid(userInput)) {
            this.resolveAddress(userInput, callback)
        } else {
            this.resolveDomains(userInput, callback)
        }

    }


    async resolveDomains(userInput, callback) {
        this.setState({resolvingDomain: true})
        const domainList = await this.domainResolver.resolveARecords(userInput)
        if (!domainList || !domainList.ips || domainList.ips.length === 0) {
            const t = this.translate
            this.snackbar.Error(t("targetForm.hosthandler.noAFound", {domain: userInput}))
            this.setState({resolvingDomain: false})
            return
        }

        this.setState(prevState => {
            return {fields: {...prevState.fields, comment: `${prevState.fields.comment} ${userInput}`}}
        })
        domainList.ips.forEach(ip => {
            this.addAddress(ip, callback)
        })

        this.setState({resolvingDomain: false})
    }


    resolveAddress(userInput, callback) {
        let chip = userInput

        const t = this.translate

        if (IP4.isValid(chip)) {


            if (IP4.listIsValid(chip)) {
                const list = IP4.splitToAddresses(chip)

                this.addListOfAddresses(list, callback)
            } else {
                const oldChip = chip
                chip = IP4.convertIfNecessary(chip)
                if (chip !== oldChip)
                    this.snackbar.Info(t("common.targetCreate.autoCorrected"))

                this.addAddress(chip, callback)
            }
        } else if (IP6.isValid(chip)) {
            this.addAddress(chip, callback)
        } else {
            this.snackbar.Error(t("common.targetCreate.error.noValidAddress", {address: chip}))
        }
    }

    addListOfAddresses(addressList, callback) {
        const {includedHosts} = this.getState().fields

        const filteredHosts = addressList.filter(addr => includedHosts.indexOf(addr) <= -1)

        this.setState({
            fields: {
                ...this.getState().fields,
                includedHosts: [...includedHosts, ...filteredHosts]
            }
        }, callback)
    }

    addAddress(ip, callback) {
        const {includedHosts} = this.getState().fields

        if (includedHosts.includes(ip)) {
            const t = this.translate
            this.snackbar.Info(t("common.targetCreate.hostAlreadyUsed"))
            return
        }
        this.setState({
            fields: {
                ...this.getState().fields,
                includedHosts: [...includedHosts, ip]
            }
        }, callback)

    }

}
