import {Tooltip} from "@material-ui/core"
import {Delete, Description} from "@material-ui/icons"
import React from "react"
import {Trans, useTranslation, withTranslation} from "react-i18next"
import {Container} from "reactstrap"
import StateLabel from "../../../Components/Label/StateLabel"
import {EnhancedTable} from "../../../Components/Table/EnhancedTable"
import {Converter} from "../../../Helper/Converter"
import {Headline} from "../../../StyledComponents/Font/Font"
import {ReportTableRow} from "./ReportTableRow"
import {Paragraph, Snackbar} from "@greenbone/cloud-component-library"

import Loader from "../../../Components/Loader/Loader"
import {ReportRestApiClient} from "../../../services/apiClients/Report/ReportRestApiClient"
import {MuiTableStyled} from "../../Task/components/Table/DynamicTable"
import {compose} from "redux"

const styles = theme => ({
    nameHeader: {
        width: "25%",
        minWidth: "11rem"
    },
    unfinishedHeader: {
        width: "5%"
    },
    severityHeader: {
        width: "10%"
    },
    highHeader: {
        width: "10%"
    },
    middleHeader: {
        width: "10%"
    },
    lowHeader: {
        width: "10%"
    },
    reportHeader: {
        width: "25%"
    },
    executive: {
        width: "2rem"
    },
    technical: {
        width: "2rem"
    }
})


const Note = props => {
    const {t} = useTranslation()
    return t("reportTable.qod")
}

const TryAgain = props => {
    const {t} = useTranslation()
    return <Paragraph>
        {t("report.tableErrorOccured")}
    </Paragraph>
}

type Props = {
    task: GSPTask;
    classes: typeof styles;
    t: any;
}

type State = {
    smallReports: ?any,
    loading: boolean,
    error: ?Error
}

const ReportHeader = (props: any) => {
    const {t} = useTranslation()

    return <Tooltip
        id="tooltip-report"
        placement="top"
        title={t("reportTable.reportHeader")}>
        <div style={{paddingLeft: "0.55rem", paddingRight: "0.55rem"}}>
            <Description/>
        </div>
    </Tooltip>

}

const DeleteHeader = (props: any) => {
    const {t} = useTranslation()

    return <Tooltip
        id="tooltip-delete-report"
        placement="top"
        title={t("reportTable.deleteReportHeader")}
    >
        <Delete/>
    </Tooltip>
}

class _ReportTable extends React.Component<Props, State> {
    state = {
        smallReports: null,
        loading: true,
        error: null
    }

    apiClient = new ReportRestApiClient()

    columnData: Array<ColumnData> = [
        {
            id: "scanEnd",
            numeric: false,
            label: <Trans i18nKey={"scanmanagement.date"}/>,
            sortable: true
        },
        {
            id: "unfinished",
            numeric: false,
            label: "",
            sortable: false
        },
        {
            id: "severity",
            numeric: false,
            label: <Trans i18nKey={"scanmanagement.severity"}/>,
            sortable: true
        },
        {
            id: "high",
            numeric: false,
            label: <Trans i18nKey={"scanmanagement.high"}/>,
            sortable: true,
            element: <StateLabel
                colorState={"stateColorHigh"}><Trans i18nKey={"scanmanagement.critical"}/></StateLabel>
        },
        {
            id: "medium",
            numeric: false,
            label: <Trans i18nKey={"scanmanagement.medium"}/>,
            sortable: true,
            element: <StateLabel
                colorState={"stateColorWarning"}><Trans i18nKey={"scanmanagement.medium"}/></StateLabel>
        },
        {
            id: "low",
            numeric: false,
            label: <Trans i18nKey={"scanmanagement.low"}/>,
            sortable: true,
            element: <StateLabel
                colorState={"stateColorInfo"}><Trans i18nKey={"scanmanagement.trivial"}/></StateLabel>
        },
        {
            id: "log",
            numeric: false,
            label: <Trans i18nKey={"scanmanagement.log"}/>,
            sortable: true
        },
        {
            id: "report",
            numeric: false,
            label: <Trans i18nKey={"scanmanagement.report"}/>,
            sortable: false,
            element: <ReportHeader/>
        },
        {
            id: "delete",
            sortable: false,
            label: "Delete",
            numeric: false,
            centerHead: true,
            element: <DeleteHeader/>
        },
        {
            id: "executive",
            numeric: false,
            label: <Trans i18nKey={"scanmanagement.executive"}/>,
            sortable: false,
            element: <span><Trans i18nKey={"scanmanagement.list.executive"}/></span>
        },
        {
            id: "technical",
            numeric: false,
            label: <Trans i18nKey={"scanmanagement.technical"}/>,
            sortable: false,
            element: <span><Trans i18nKey={"scanmanagement.technical"}/></span>
        }
    ]

    loadReportsSmallByTaskId = taskId => {
        return this.apiClient.getReportsSmallByTaskId(taskId)
            .then(response => {
                this.setState({smallReports: response, loading: false})
            })
            .catch(e => {
                this.setState({
                    error: e,
                    loading: false
                })
            })
    }

    deleteReport = (reportId, successCallback) => {
        this.apiClient.deleteReport(reportId)
            .then(() => {
                this.loadReportsSmallByTaskId(this.props.task.id)
                    .finally(() => {
                        if (successCallback) {
                            successCallback()
                        }
                    })
            })
            .catch(e => {
                if (successCallback) {
                    successCallback()
                }
                if (e?.status === 428) {
                    Snackbar.Warning(this.props.t("scanmanagement.reportTable.deleteReportWarning"))
                    return
                }

                if (e?.status === 404) {
                    Snackbar.Warning(this.props.t("scanmanagement.reportTable.reportNotFound"))
                    return
                }
                Snackbar.Error(e.message)
            })
    }

    rowGenerator = (report: GSPSmallReport) => {
        return <ReportTableRow key={report.id} report={report} task={this.props.task} status={this.props.task.status}
                               deleteEntity={this.deleteReport}/>
    }

    componentDidMount() {
        const {task, t} = this.props


        this.loadReportsSmallByTaskId(task.id)
    }

    render() {
        const {task} = this.props

        if (this.state.loading) {
            return <Container style={{maxWidth: "100vw"}}>
                <Loader/>
            </Container>
        }

        if (this.state.error) {
            return <Container style={{maxWidth: "100vw"}}>
                <Headline>{task.name}</Headline>
                <TryAgain/>
            </Container>
        }

        //$FlowFixMe - Flow does not understand the indirect null check
        const elements = this.state.smallReports.map(report => {
            if (report.scanEnd)
                return report
            else
                report.scanEnd = report.scanStart
            return report
        })
            .sort((a, b) => Converter.cmpDate(a.scanEnd, b.scanEnd, false, true))

        return (
            <Container style={{maxWidth: "100vw"}}>
                <Headline>{task.name}</Headline>
                <Note/>

                <div style={{width: "69.75rem"}}>
                    <EnhancedTable
                        tableElement={MuiTableStyled}
                        elements={elements}
                        elementGenerator={this.rowGenerator}
                        columnData={this.columnData}
                        showSearchField={false}
                        orderBy={"scanEnd"}
                        order={"desc"}
                    />
                </div>

            </Container>
        )
    }
}

//

export const ReportTable = compose(withTranslation())(_ReportTable)
