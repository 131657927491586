import React from "react";
import Select from "react-select";
import {useTheme} from "styled-components";
import {InputLabel} from "@material-ui/core";


export const SearchableSelect = props => {
    const {searchable} = useTheme();

    const convertedData = () => {
        const {options} = props;
        if (!options.data) {
            return [];
        }

        let optionList = [];
        for (let i = 0; i < options.data.length; i++) {
            optionList.push({
                value: options.data[i][options.valueFieldName],
                label: options.data[i][options.labelFieldName]
            });
        }
        return optionList;
    };

    const getItemByValue = (value) => {
        if (convertedData().length === 0) {
            return null;
        }
        for (let i = 0; i < convertedData().length; i++) {
            if (convertedData()[i].value === value) {
                return convertedData()[i];
            }
        }
    };


    const handleChange = (value) => {
        props.onChange({
            target: {
                value: value.value,
                name: props.name
            }
        });
    };

    const {isValid, label} = props;
    if (isValid === null || isValid === undefined || isValid === true) {
        return (
            <>
                {label && <InputLabel>{label}</InputLabel>}
                <Select {...props} onChange={handleChange}
                        options={convertedData()}
                        value={getItemByValue(props.value)}
                        theme={theme => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                ...searchable
                            }
                        })}/>
            </>
        );
    }

    let helperText = isValid;
    if (typeof isValid === "string" || React.isValidElement(isValid)) {
        helperText = isValid;
    }

    if (Array.isArray(isValid)) {
        helperText = <>{isValid.map(message =>
            (<div style={{marginBottom: "5px"}} key={message}>
                    {message}
                </div>
            ))
        }</>;
    }
    return (
        <>
            <Select {...props} onChange={handleChange}
                    options={convertedData()}
                    value={getItemByValue(props.value)}
                    theme={theme => ({
                        ...theme,
                        colors: {
                            ...theme.colors,
                            ...searchable,
                            neutral80: "#f44336"
                        }
                    })}/>
            <div style={{color: "#f44336", fontSize: "0.75rem"}}>
                {helperText}
            </div>
        </>
    );


};
