import {useContext, useEffect, useState} from "react"
import {SubscriptionContext} from "../SubscriptionProvider"
import {useInformationBar} from "../../../Layout/components/InformatinBarContext"
import {useNavigate} from "react-router"
import {useTranslation} from "react-i18next"
import {JWT} from "../../../Helper/JWT"
import {Logger} from "../../../controller/loggingController"
import {GroupSettingsUrlProvider} from "../../../UrlProvider/GroupSettingsUrlProvider"
import {DateTime} from "luxon";

export function SubscriptionAlertBarRenderer(props) {
    const {subscriptions, loading} = useContext(SubscriptionContext)
    const {warning, closeByUuid} = useInformationBar()
    const navigate = useNavigate()
    const {t} = useTranslation()
    const [bUuid, setbUuid] = useState(null)
    const [noPlanUuid, setNoPlanUuid] = useState(null)

    useEffect(() => {
        if (loading === false) {
            try {

                if (subscriptions?.current === null) {
                    var uuid
                    if (new JWT()?.isManaged() === true) {
                        uuid = warning(`${t("subscriptionAlertBarRenderer.noPlan") + " " + t("subscriptionAlertBarRenderer.noPlanNotifyContact")}`)
                    } else {
                        uuid = warning(`${t("subscriptionAlertBarRenderer.noPlan")}`
                            , () => {
                                navigate(GroupSettingsUrlProvider.subscription())
                            }, t("subscriptionAlertBarRenderer.upgrade.text"))
                    }
                    setNoPlanUuid(uuid)
                } else {
                    closeByUuid(noPlanUuid)
                }

                const endDate = subscriptions?.current?.endedAt;
                const diffDays = endDate ? DateTime.fromISO(endDate).diff(DateTime.local(), "days").days : 0;
                const end = Math.ceil(Math.max(0, diffDays));

                if (subscriptions?.current?.paymentOption === "NONE" && (new JWT()?.isManaged() === true)) {
                    const uuid = warning(`
                    ${end > 1 || end === 0 ? t("subscriptionAlertBarRenderer.free", {date: end}) : t("subscriptionAlertBarRenderer.free.day", {date: end})}
                    ${t("subscriptionAlertBarRenderer.contactPartnerForUpgrade")}
                        `
                    )
                    if (uuid !== null) {
                        setbUuid(uuid)
                    }
                } else {
                    closeByUuid(bUuid)
                }

                if (subscriptions?.current?.paymentOption === "NONE" && (new JWT()?.isManaged() !== true)) {
                    const uuid = warning(`
                      ${end > 1 || end === 0 ? t("subscriptionAlertBarRenderer.free", {date: end}) : t("subscriptionAlertBarRenderer.free.day", {date: end})}
                   `
                        , () => {
                            navigate(GroupSettingsUrlProvider.subscription())
                        }, t("subscriptionAlertBarRenderer.free.text"))
                    if (uuid !== null) {
                        setbUuid(uuid)
                    }
                } else {
                    closeByUuid(bUuid)
                }
            } catch (e) {
                Logger.exception(e)
            }
        }
        // eslint-disable-next-line
    }, [loading, subscriptions])


    return null

}
