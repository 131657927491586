// @flow

import {SIODateTime} from "../../controller/SIODateTime"
import {dateFormats} from "../../services/apiClients/Base/ApiClient"

const VAT_RATE = "19"

function isIsoDateString(value) {
    for (let i = 0; i < dateFormats.length; i++) {
        if (dateFormats[i].regex.test(value)) {
            return true
        }
    }
}


export class Converter {
    static VAT = parseFloat(VAT_RATE)

    /**
     * Converts a number to string and adds leading symbols with given length
     *
     * @param value - number to convert
     * @param filler - filler symbol
     * @param length - resulting string length
     * @returns {string}
     */
    static numberToString(value, filler = "0", length = -1) {
        let result = String(value)
        while (result.length < length) {
            result = String(filler) + result
        }
        return result
    }

    static cmpAny(a, b, asc = true, isNullLowest = true) {

        const aType = typeof a
        const bType = typeof b

        if (aType !== bType) {
            return Converter.cmpString(a, b, asc, isNullLowest)
        }

        switch (aType) {
            case "string": {
                if (isIsoDateString(a) || isIsoDateString(b)) {
                    const aDateTime = SIODateTime.parseIsoString(a)
                    const bDateTime = SIODateTime.parseIsoString(b)

                    return Converter.cmpSIODate(aDateTime, bDateTime, asc, isNullLowest)
                }
                break
            }

            case "object": {
                if (a?.isSIODateTime && b?.isSIODateTime) {
                    return Converter.cmpSIODate(a, b, asc, isNullLowest)
                }
            }
        }


        return Converter.cmpString(a, b, asc, isNullLowest)
    }

    static cmpNumber(a, b, asc = true, isNullLowest = true) {
        let result = 0
        if (!a) {
            if (b) {
                result = isNullLowest ? -1 : 1
            }
        } else if (!b) {
            result = isNullLowest ? 1 : -1
        } else {
            const small_or_greater = (a < b ? -1 : 1)
            result = (a === b ? 0 : small_or_greater)
        }
        return result * (asc ? 1 : -1)
    }

    static cmpString(a, b, asc = true, isNullLowest = true) {
        return Converter.cmpNumber(a, b, asc, isNullLowest)
    }

    static cmpSIODate(a, b, asc = true, isNullLowest = true) {
        const aNumber = a?.toJSDate().getTime()
        const bNumber = b?.toJSDate().getTime()

        return Converter.cmpNumber(aNumber, bNumber, asc, isNullLowest)
    }

    static cmpDate(a, b, asc = true, isNullLowest = true) {
        const aString = a ? a.valueOf().toString() : null
        const bString = b ? b.valueOf().toString() : null

        return Converter.cmpString(aString, bString, asc, isNullLowest)
    }

    static toBoolean(value) {
        return !!value
    }

    static numberToPixel(pixel) {
        return `${pixel}px`
    }

    static numberToRem(pixel) {
        return `${pixel}rem`
    }

    static ParamObjectToUrlParamString(params) {
        const parts = []
        Object.keys(params)
            .forEach(key => {
                parts.push(
                    `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
                )
            })
        return parts.join("&")
    }

    static centsToEuros(totalInCents) {
        return (totalInCents / 100.0).toLocaleString("de-DE", {style: "currency", currency: "EUR"})
    }

    static asCurrency(amount, currency = "EUR") {
        return amount.toLocaleString("de-DE", {style: "currency", currency: currency})
    }

    static getTax(amount, tax = Converter.VAT) {
        return amount * tax / 100.0
    }

    static withTax(amount, tax = Converter.VAT) {
        return amount * tax / 100.0 + amount
    }
}

export function chain(value, ...functions) {
    let returnValue = value
    functions.forEach(func => {
        returnValue = func(returnValue)
    })
    return returnValue
}
