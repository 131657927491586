//@flow

import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import MaterialSelect from "@material-ui/core/Select"
import React from "react"

export {Input} from "@greenbone/cloud-component-library";

export function Select(props: any) {
    const {label, onChange, name, children, value} = props;
    return (
        <FormControl>
            <InputLabel>{label}</InputLabel>
            <MaterialSelect onChange={onChange} name={name}
                            value={value} {...props}>
                {children}
            </MaterialSelect>
        </FormControl>
    );
}

