//@flow

import {
    Drawer,
    IconButton,
    Portal,
    Table,
    TableBody,
    TableCell,
    TableRow,
    withStyles,
    withTheme
} from "@material-ui/core"
import {Close} from "@material-ui/icons"
import React from "react"
import {withTranslation} from "react-i18next"
import {connect} from "react-redux"
import {bindActionCreators, compose} from "redux"
import {Headline1} from "../../../Components/Basic/Headlines"
import {openAlertDialog} from "../../../Components/Dialogs/AlertDialog/actions"
import {CredentialsRestApiClient} from "../../../services/apiClients/Credentials/CredentialsRestApiClient"
import {RemoteResourceRestApiClient} from "../../../services/apiClients/RemoteResource/RemoteResourceRestApiClient"
import {TargetRestApiClient} from "../../../services/apiClients/Target/TargetRestApiClient"
import {HostList} from "./HostList"


const styles = theme => ({
    headline: {
        display: "flex",
        justifyContent: "flex-start",
        marginLeft: "1.25rem"
    },
    drawerDiv: {
        width: "43.75rem"
    },
});

type Props = {
    classes: typeof styles;
    theme: any;
    onClose: () => void;
    target: GSPTarget;
    _isOpen: boolean;
    t: any;
}

type State = {
    portList: number,
    ssh: ?GSPCredentialsRef,
    smb: ?GSPCredentialsRef,
    esxi: ?GSPCredentialsRef,
    port: ? number,
    _loadingCredentials: boolean,
    _loadingAliveTest: boolean,

    _exception: any
}

class _TargetDetails extends React.Component<Props, State> {
    targetRestClient: TargetRestApiClient;
    remoteResourceRestClient: RemoteResourceRestApiClient;
    credentialsRestClient: CredentialsRestApiClient;

    state = {
        portList: 123,
        ssh: null,
        smb: null,
        esxi: null,
        port: null,
        _loadingCredentials: false,
        _loadingAliveTest: false,
        _exception: undefined,
    };

    displayCredentials = (credentials: ?GSPCredentialsRef) => {
        const {t} = this.props;
        if (!credentials) {
            return (t("target.details.withoutCredentials"));
        }
        return (credentials.name);

    };

    render() {
        const {target, classes, t} = this.props;

        return (
            <Portal>
                <Drawer anchor="right" open={this.props._isOpen} onClose={this.props.onClose}
                        style={{margin: "0.63rem"}}>
                    <div className={classes.drawerDiv}>
                        <div style={{marginLeft: "0.31rem"}}>
                            <IconButton onClick={this.props.onClose}>
                                <Close/>
                            </IconButton>
                        </div>
                        <div className={classes.headline}>
                            <Headline1> {t("target.details.information", {name: this.props.target.name})}</Headline1>
                        </div>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell>
                                        {t("target.details.targetName")}
                                    </TableCell>
                                    <TableCell>
                                        {target.name}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        {t("target.details.host")}
                                    </TableCell>
                                    <TableCell>
                                        <HostList
                                            targetType={target.targetType}
                                            includedHosts={target.includedHosts}
                                            excludedHosts={target.excludedHosts}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        {t("target.details.portList")}
                                    </TableCell>
                                    <TableCell>
                                        {target.portList.name}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={2} style={{fontWeight: "bold"}}>
                                        {t("target.details.credentials")}
                                    </TableCell>

                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        {"SSH"}
                                    </TableCell>
                                    <TableCell>
                                        {this.displayCredentials(target.credentials["SSH"])}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        {"SSH Port"}
                                    </TableCell>
                                    <TableCell>
                                        {target.port ? target.port : null}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>
                                        {"SMB"}
                                    </TableCell>
                                    <TableCell>
                                        {this.displayCredentials(target.credentials["SMB"])}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>{"ESXI"}</TableCell>
                                    <TableCell>
                                        {this.displayCredentials(target.credentials["ESXI"])}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                </Drawer>
            </Portal>
        );
    }

}

function mapDispatchToProps(dispatch) {
    let actions = bindActionCreators({
        openAlertDialog: openAlertDialog,
    }, dispatch);
    return {...actions, dispatch};
}

function mapStateToProps(state) {
    return {};
}

export const TargetDetails = compose(
    withStyles(styles),
    withTranslation(),
    withTheme,
    connect(mapStateToProps, mapDispatchToProps)
)(_TargetDetails);
