//@flow

import React from "react"
import {withTranslation} from "react-i18next"
import {connect} from "react-redux"
import {Link} from "react-router-dom"
import {Col, Container, Row} from "reactstrap"
import {bindActionCreators, compose} from "redux"
import styled from "styled-components"
import {openAlertDialog} from "../../../Components/Dialogs/AlertDialog/actions"
import Loader from "../../../Components/Loader/Loader"
import {AbortButton, Button} from "@greenbone/cloud-component-library"
import {Headline} from "../../../StyledComponents/Font/Font"
import {Colors} from "../../../Theme"
import {EntityUrlProvider} from "../../../UrlProvider/EntityUrlProvider"
import {HostValidationRestApiClient} from "../../../services/apiClients/HostValidation/HostValidationRestApiClient"
import {TargetRestApiClient} from "../../../services/apiClients/Target/TargetRestApiClient"
import {
    BadRequestError,
    ConflictError,
    ForbiddenError,
    MethodNotAllowedError,
    NotFoundError,
    ServiceNotAvailableError,
    UnauthorizedError,
    UnprocessableEntityError
} from "../../../services/Exceptions"
import {HostValidationEntry} from "./HostValidationEntry"
import {HostValidationInformationBox} from "./HostValidationInformationBox"
import {withParams} from "../../../../hocs/withParams";


const HostsTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  color: ${Colors.grey.dark};
  font-size: 1rem;

  td, th {
    box-sizing: border-box;
    height: 3.13rem;
  }

  tr, th {
    border-bottom: 1px solid ${Colors.grey.light};
  }

  tr:last-child {
    border-bottom: none;
  }

  th:nth-child(1), td:nth-child(1){
    width: 13rem;
  }

   th:nth-child(3), td:nth-child(1){
    width: 29rem;
  }

  ${Button}{
    margin: 0;
  }
`;

type Props = {
    history: any;
    openAlertDialog: typeof openAlertDialog;
    t: any;
    params: { id: string };
}

type State = {
    targetId: string;
    hostsContact: any;
    target: ?GSPTarget;
    _saving: boolean;
    _exception: any;
    _loading: boolean;
}

class _HostValidation extends React.Component<Props, State> {

    hostValidationRestClient: HostValidationRestApiClient;
    targetRestClient: TargetRestApiClient;

    constructor(props) {
        super(props);

        const targetId = this.props.params.id;

        this.state = {
            targetId: targetId,
            hostsContact: {},
            target: null,
            _saving: false,
            _loading: false,
            _exception: null,
        };

        this.hostValidationRestClient = new HostValidationRestApiClient();
        this.targetRestClient = new TargetRestApiClient();
    }

    componentDidMount() {
        this.setState({
            _loading: false,
            _saving: false,
        });
        this.loadTarget(this.state.targetId);
    }

    loadTarget = (targetId: string) => {
        this.setState({_loading: true});
        this.targetRestClient.getOne(targetId)
            .then(target => {
                this.setState({
                    target: target,
                    _loading: false,
                });
            })
            .catch(error => {
                this._catchServerError(error);
                this.setState({_loading: false});
            });
    };

    _catchServerError(error: any) {
        const {t} = this.props;
        switch (error.type) {
            case ServiceNotAvailableError:
            case NotFoundError:
            case UnprocessableEntityError:
            case ForbiddenError:
            case UnauthorizedError:
            case MethodNotAllowedError:
            case ConflictError:
            case BadRequestError: {
                this.props.openAlertDialog(t("common.messages.error"), error.message);
                break;
            }
            default: {
                this.setState({
                    _exception: error,
                });
            }
        }
    }

    render() {
        const {t} = this.props;
        const {target} = this.state;

        if (this.state._exception) {
            throw this.state._exception;
        }
        if (this.state._loading) {
            return (<Loader/>);
        }

        return <>
            <Container fluid>
                <Row>
                    <Col>
                        <Link to={EntityUrlProvider.targetList()}>
                            <AbortButton>{t("hostValidation.components.validateNow.backToGoals")}</AbortButton>
                        </Link>

                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12} lg={12} xl={8}>
                        <Headline>{t("hostValidation.components.validate.validateHost")}</Headline>
                        <HostsTable>
                            <thead>
                            <tr>
                                <th>{t("hostValidation.components.validate.host")}</th>
                                <th>{t("hostValidation.components.validate.status")}</th>
                                <th>{t("hostValidation.components.validate.action")}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {target && target.includedHosts &&
                            target.includedHosts.map((host: GSPHost) => (
                                <HostValidationEntry key={host.id} hostId={host.id} targetId={target.id}/>
                            ))}
                            </tbody>
                        </HostsTable>
                    </Col>
                    <Col xs={12} sm={12} lg={12} xl={4}>
                        <HostValidationInformationBox/>
                    </Col>
                </Row>
            </Container>
        </>;
    }
}

function mapDispatchToProps(dispatch: any) {
    let actions = bindActionCreators({
        openAlertDialog: openAlertDialog,
    }, dispatch);
    return {...actions, dispatch};
}

function mapStateToProps(state) {
    return {};
}

export const HostValidation = compose(
    withTranslation(),
    withParams,
    connect(mapStateToProps, mapDispatchToProps),
)(_HostValidation);
