import React from "react";
import {AlertIdentifier} from "../AlertIdentifier/AlertIdentifier";

export class ErrorBoundary extends React.Component {
    state = {hasError: false, error: null};

    static getDerivedStateFromError(error) {
        return {
            hasError: true,
            error
        };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({error, hasError: true});
    }

    render() {
        if (this.state.hasError) {
            return <AlertIdentifier exception={this.state.error}/>;
        }
        return this.props.children;
    }

}
