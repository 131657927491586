//@flow

import {Done} from "@material-ui/icons"
import React from "react"
import styled from "styled-components"
import {Colors, ColorTheme} from "../../../Theme"


const CheckBox = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  padding: 0.63rem;
  border: 1px solid ${Colors.grey.normal};
  cursor: pointer;
  background-color: ${props => props.checked ? ColorTheme.background.normal : "transparent"};

  &:first-child {
    border-right: 0
  };
  &:last-child {
    border-left: 0
  };
`;

const Headline = styled.div`
  font-weight: bold;
  margin-bottom: 0.44rem;
  font-size: 0.81rem;
  color: ${props => props.checked ? "#ffffff" : ColorTheme.background.normal}
`;

const Description = styled.div`
  font-size: 0.81rem;
  color: ${props => props.checked ? "#ffffff" : "#abacac"}
`;

const CheckIcon = styled.div`
  float: right;
  position: relative;
  top: -0.38rem;
  right: -0.38rem;
  color: white;
`;

type Props = {
    +title: string;
    +description: string;
    +checked: boolean;
    +onChange: any;
}

export class BlockCheckBox extends React.Component<Props> {
    render() {
        const {title, description, checked} = this.props;

        return (
            <CheckBox checked={checked} onClick={this.props.onChange}>
                {checked && <CheckIcon><Done/></CheckIcon>}
                <Headline checked={checked}>{title}</Headline>
                <Description checked={checked}>{description}</Description>
            </CheckBox>
        );
    }
}
