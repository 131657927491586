import React from "react"
import {useTranslation} from "react-i18next"
import {FlexRow, Headline} from "@greenbone/cloud-component-library"
import {SwitchButtonContainer, SwitchButtonElement} from "../../../Components/SwitchButton"
import {TargetFormMode} from "./TargetFormMode"


export function TargetFormHeadlineText({id, isInternal}) {
    const {t} = useTranslation()

    const isNewEntityForm = !!!id

    if (isNewEntityForm) {
        if (isInternal) {
            {
                return t("common.targetCreateInternal.headline")
            }
        } else {
            {
                return t("common.targetCreate.headline")
            }
        }
    } else {
        if (isInternal) {
            {
                return t("common.targetCreate.editInternalHeadline")
            }
        } else {
            {
                return t("common.targetCreate.editHeadline")
            }
        }
    }
}


export function TargetFormHeader({id, isInternal, mode, handleModeSwitch}) {
    const {t} = useTranslation()
    const isNewEntityForm = !!!id

    return <FlexRow justifyContent={"space-between"} alignItems={"center"}>
        <Headline noMargin>
            <TargetFormHeadlineText isInternal={isInternal} id={id}/>
        </Headline>
        {(isNewEntityForm && !isInternal) &&
        <SwitchButtonContainer>
            <SwitchButtonElement
                active={mode === TargetFormMode.IP}
                onClick={() => handleModeSwitch(TargetFormMode.IP)}
            >
                {t("ipAddress")}
            </SwitchButtonElement>
            <SwitchButtonElement
                active={mode === TargetFormMode.HOSTNAME}
                onClick={() => handleModeSwitch(TargetFormMode.HOSTNAME)}
            >
                {t("hostname")}
            </SwitchButtonElement>
        </SwitchButtonContainer>
        }
    </FlexRow>
}
