import React from 'react'
import {useTranslation} from "react-i18next"
import {useLocation} from "react-router"
import {ListMenu, MenuContext} from "@greenbone/cloud-component-library"
import {UserSettingsUrlProvider} from "../../../../UrlProvider/UserSettingsUrlProvider"
import ConfigurationIcon from "../../../../../assets/icons/configuration.svg"
import Envelope from "../../../../../assets/icons/envelope.svg"
import Lock from "../../../../../assets/icons/shield-lock.svg"
import {Logger} from "../../../../controller/loggingController"
import {JWT} from "../../../../Helper/JWT"


const MENU_NAME = "userSettingsMenu"

function getMenuItems(t) {
    const notificationItem = {
        text: t("menu.userMenu.notifications"),
        to: UserSettingsUrlProvider.notification(),
        icon: Envelope
    }

    try {
        const jwt = new JWT()
        if (jwt.hasUserRole()) {
            return [notificationItem,
                {
                    text: t("menu.userMenu.security"),
                    to: UserSettingsUrlProvider.security(),
                    icon: Lock
                }]
        }
    } catch (e) {
        Logger.exception(e)
    }

    return [notificationItem]

}


export function UserSettingsMenu() {
    const {t} = useTranslation()
    const {pathname} = useLocation()

    const isActive = () => UserSettingsUrlProvider.pathHasSameBase(pathname)

    try {
        const jwt = new JWT()
        if (jwt.hasUserRole()) {
            return <MenuContext.Consumer>
                {({toggle, minified, ...rest}) => {
                    const toggleMenu = toggle(MENU_NAME)
                    const isMenuOpen = rest[MENU_NAME]

                    return <ListMenu isActive={isActive()} isOpen={isMenuOpen} rootIcon={<ConfigurationIcon />}
                                     rootText={t("User Settings")} minified={minified} onClickRoot={(event) => {
                        toggleMenu(event, true)
                    }} rootUrl={UserSettingsUrlProvider.getBase()}
                                     menuItems={getMenuItems(t)}/>
                }}
            </MenuContext.Consumer>
        }
    } catch (e) {
        Logger.exception(e)
    }

    return null


}
