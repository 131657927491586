import React from "react";
import {loadState, persistState} from "../Helper/Persist";
import {getProperty} from "../Helper/JWT"


export const MenuContext = React.createContext({});


export class MenuProvider extends React.Component {
    key = "MenuProvider";
    state = {
        minified: false,
        showMinifyButton: false
    };

    loadData(key) {
        try {
            const id = getProperty(this.props.keycloak.token, "sub");
            if (id) {
                return loadState(`${key}-${id}`);
            } else {
                return loadState(`${key}`);
            }
        } catch (e) {
            return loadState(`${key}`);
        }
    }

    saveData(key, data) {

        try {
            const id = getProperty(this.props.keycloak.token, "sub");
            if (id) {
                return persistState(`${key}-${id}`, data);
            } else {
                return persistState(`${key}`, data);
            }
        } catch (e) {
            return persistState(`${key}`, data);
        }

    }

    componentDidMount() {
        const state = this.loadData(this.key);
        if (state) {
            this.setState({...state});
        }
    }

    toggle = (fieldName) => (event, open = null) => {


        if (["BUTTON", "IMG", "svg", "div", "path"].indexOf(event.target.tagName) >= 0) {
            if (!this.state.minified) {
                event.preventDefault();
            }
            this.setStateAndPersist(prevState => {
                if (this.state.minified) {
                    return {[fieldName]: true};
                }
                return {[fieldName]: !prevState[fieldName]};
            });
            return;
        }

        if (open) {
            this.setStateAndPersist({[fieldName]: true});
        }
    };


    setStateAndPersist = (params) => {
        this.setState(params, () => {
            const data = {
                ...this.state,
                showMinifyButton: undefined
            };
            this.saveData(this.key, data);
        });
    };


    toggleMinify = (event) => {
        event.preventDefault();
        this.setStateAndPersist(prevState => {
            return {minified: !prevState.minified};
        });
    };

    showMinifyButtonEvent = (event) => {
        this.setState({showMinifyButton: true});
    };

    hideMinifyButtonEvent = (event) => {
        this.setState({showMinifyButton: false});
    };

    render() {
        return (
            <MenuContext.Provider value={{
                ...this.state,
                toggle: this.toggle,
                toggleMinify: this.toggleMinify,
                showMinifyButtonEvent: this.showMinifyButtonEvent,
                hideMinifyButtonEvent: this.hideMinifyButtonEvent
            }}>
                {this.props.children}
            </MenuContext.Provider>
        );
    }
}

