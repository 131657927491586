import React, {useEffect, useMemo} from "react";
import {
    FlexRow,
    Headline,
    MuiTableCellStyled,
    MuiTableFooterStyled,
    MuiTableHeadStyles,
    MuiTableRowStyled,
    MuiTableStyled,
} from "./components/StyledComponents";
import {PageableTablePaginationActions} from "./PageableTablePaginationActions";
import {InputAdornment, TableBody, TableContainer, TablePagination, TableSortLabel, TextField,} from "@mui/material";
import {Search} from "@mui/icons-material";
import PropTypes from "prop-types";
import _ from 'lodash';
import {pageSizeOptions, tableCellHeight} from "./constants";
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

export function PageableTable({
                                  data,
                                  columns,
                                  actions,
                                  pageSizeOption = [5, 10, 25],
                                  defaultPageSize = 10,
                                  searchText = "",
                                  setSearchText,
                                  page,
                                  setPage,
                                  pageSize,
                                  setPageSize,
                                  totalElements,
                                  totalPages,
                                  sorting,
                                  setSorting,
                                  EntryGenerator,
                                  enableRowSelection = false,
                              }) {

    const handleSortChange = (columnAccessor) => {
        if (columnAccessor) {
            const currentSort = sorting.find(sort => sort.startsWith(columnAccessor));
            const newDirection = !currentSort || currentSort.endsWith('desc') ? 'asc' : 'desc';
            setSorting([`${columnAccessor},${newDirection}`]);
        }
    };

    const handleChange = (e) => {
        setSearchText(e.target.value);
    };

    const debouncedResults = useMemo(() => {
        return _.debounce(handleChange, 300);
    }, []);

    useEffect(() => {
        return () => {
            debouncedResults.cancel();
        };
    });

    const colspan = enableRowSelection ? columns.length + 1 : columns.length

    return (
        <TableContainer>
            <MuiTableStyled>
                <MuiTableHeadStyles>
                    <MuiTableRowStyled>
                        <MuiTableCellStyled colSpan={colspan}>
                            <FlexRow style={{alignItems: "center"}}>
                                {actions}
                                <TextField
                                    key={"search-textField"}
                                    onChange={debouncedResults}
                                    placeholder=""
                                    variant="standard"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start" key={"inputAdornment"}>
                                                <Search key={"search-icon"}/>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </FlexRow>
                        </MuiTableCellStyled>
                    </MuiTableRowStyled>
                    <MuiTableRowStyled>
                        {columns.map((column, index) => (
                            <MuiTableCellStyled
                                key={column.Header || index}>
                                {column.Header}
                                {(column.accessor && column.sortable === true) && (
                                    <TableSortLabel
                                        active={sorting.some(sort => sort.startsWith(column.accessor))}
                                        direction={sorting.find(sort => sort.startsWith(column.accessor))?.endsWith('desc') ? 'desc' : 'asc'}
                                        IconComponent={sorting.some(sort => sort.startsWith(column.accessor)) ? undefined : UnfoldMoreIcon}
                                        onClick={() => handleSortChange(column.accessor)}
                                    />)}
                            </MuiTableCellStyled>
                        ))}
                    </MuiTableRowStyled>
                </MuiTableHeadStyles>
                <TableBody>
                    {data.length > 0 ? (
                        data.map((item, index) => (
                            <EntryGenerator key={item.id || index} dataset={item}/>
                        ))
                    ) : searchText ? (
                        <MuiTableRowStyled className={"not-found"}>
                            <MuiTableCellStyled style={{height: `calc(${tableCellHeight} * ${pageSize})`}}
                                                colSpan={colspan}>
                                <FlexRow style={{justifyContent: "center", alignItems: "center"}}>
                                    <Headline noMargin>No results for: {searchText}</Headline>
                                </FlexRow>
                            </MuiTableCellStyled>
                        </MuiTableRowStyled>
                    ) : (
                        <MuiTableRowStyled className={"not-found"}>
                            <MuiTableCellStyled className={"no-border"} colSpan={colspan}>
                            </MuiTableCellStyled>
                        </MuiTableRowStyled>
                    )}
                </TableBody>
                <MuiTableFooterStyled>
                    <MuiTableRowStyled>
                        <TablePagination
                            rowsPerPageOptions={pageSizeOptions}
                            count={totalElements}
                            page={page}
                            rowsPerPage={pageSize}
                            onPageChange={(event, newPage) => setPage(newPage)}
                            onRowsPerPageChange={(event) => {
                                setPageSize(parseInt(event.target.value))
                                setPage(0)
                            }}
                            slotProps={{
                                select: {
                                    inputProps: {"aria-label": "rows per page"},
                                    native: true
                                }
                            }}
                            ActionsComponent={props => (
                                <PageableTablePaginationActions
                                    {...props}
                                    onChangePage={(newPage) => setPage(newPage)}
                                    totalPages={totalPages}
                                />
                            )}
                        />
                    </MuiTableRowStyled>
                </MuiTableFooterStyled>
            </MuiTableStyled>
        </TableContainer>
    );
}

PageableTable.propTypes = {
    data: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    actions: PropTypes.node,
    pageSizeOptions: PropTypes.array,
    defaultPageSize: PropTypes.number,
    searchText: PropTypes.string,
    setSearchText: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    setPage: PropTypes.func.isRequired,
    pageSize: PropTypes.number.isRequired,
    setPageSize: PropTypes.func.isRequired,
    totalElements: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    sorting: PropTypes.array,
    setSorting: PropTypes.func,
    EntryGenerator: PropTypes.func.isRequired
};


PageableTable.defaultProps = {
    pageSizeOptions: [5, 10, 25],
    defaultPageSize: 10,
    searchText: "",
    actions: null,
    enableRowSelection: false,
    sorting: null,
};

export const PageableTableRow = MuiTableRowStyled;
export const PageableTableCell = MuiTableCellStyled;
