import React from 'react'
import styled from "styled-components"

const Light = styled.div`
    color: ${props => props.theme.font.tiny};
    font-size: 0.8rem;
`

export function Oid({oid}) {
    return <Light>OID: {oid}</Light>
}
