import React from "react"
import {useTranslation} from "react-i18next"
import {useTheme} from "styled-components"
import {ShadeBlendColor} from "@greenbone/cloud-component-library"
import {Link} from "react-router-dom"

const tcpOutbound0 = 443
const ipOutbound0 = "45.135.106.140"
const tcpOutbound1 = 443
const ipOutbound1 = "195.252.156.97"
const tcpOutbound2 = 443
const ipOutbound2 = "13.69.64.90"
const tcpOutbound3 = 443
const ipOutbound3 = "52.178.18.8"


export function Graphic(props) {

    const {t} = useTranslation()
    const {base} = useTheme()

    const color1 = base// "#0476ac"
    const color2 = "#4c4c4d"
    const color3 = ShadeBlendColor(base, -4)//"#025b93"
    const color4 = ShadeBlendColor(base, -6)//"#02447b"

    return <svg style={{width: "100%", height: "auto"}} xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink" width="100%"
                viewBox="0 0 474 301">
        <defs>
            <linearGradient id="linear-gradient" x1="0.5" y1="1" x2="0.5" gradientUnits="objectBoundingBox">
                <stop offset="0" stopColor="#fff"/>
                <stop offset="1" stopColor={color1}/>
            </linearGradient>
            <linearGradient id="linear-gradient-2" x1="0.731" y1="0.716" x2="0.422" y2="0.369"
                            xlinkHref="#linear-gradient"/>
            <linearGradient id="linear-gradient-3" x1="-0.096" y1="0.148" x2="1" y2="0.788"
                            xlinkHref="#linear-gradient"/>
        </defs>
        <g id="Group_2474" transform="translate(-1416 -113)">
            <text id="Password_for_the_CLI:_admin_" transform="translate(1593 267)" fill={color1} fontSize="14"
                  fontFamily="DroidSans-Bold, Droid Sans" fontWeight="700">
                <tspan x="0" y="0">{t("gatewayGraphic.passwordForCli")}</tspan>
                <tspan y="0" fontSize="13"></tspan>
                <tspan y="0" fill={color2} fontSize="13"> „admin“</tspan>
            </text>
            <g id="password-desktop-lock-approved" transform="translate(1546 249.341)">
                <path id="Path_732" d="M14.383,18.75a10.687,10.687,0,0,0,1.794,5.383H9a10.687,10.687,0,0,0,1.794-5.383"
                      transform="translate(0.869 2.781)" fill="none" stroke={color1} strokeLinecap="round"
                      strokeLinejoin="round" strokeWidth="1.5"/>
                <line id="Line_159" x2="12.56" transform="translate(7.177 26.914)" fill="none" stroke={color1}
                      strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <line id="Line_160" x2="26.915" transform="translate(0 17.943)" fill="none" stroke={color1}
                      strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_733"
                      d="M27.665,18.693v1.794a1.794,1.794,0,0,1-1.794,1.794H2.544A1.794,1.794,0,0,1,.75,20.487V2.544A1.794,1.794,0,0,1,2.544.75H15.1"
                      transform="translate(-0.75 -0.75)" fill="none" stroke={color1} strokeLinecap="round"
                      strokeLinejoin="round" strokeWidth="1.5"/>
                <rect id="Rectangle_724" width="10.766" height="8.971" rx="1.5" transform="translate(16.149 5.383)"
                      strokeWidth="1.5" stroke={color1} strokeLinecap="round" strokeLinejoin="round" fill="none"/>
                <path id="Path_734"
                      d="M15.75,6.133V4.339A3.589,3.589,0,0,1,19.339.75h0a3.589,3.589,0,0,1,3.589,3.589V6.133"
                      transform="translate(2.193 -0.75)" fill="none" stroke={color1} strokeLinecap="round"
                      strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_735" d="M18.824,8.625a.447.447,0,1,0,.318.131.449.449,0,0,0-.318-.131h0"
                      transform="translate(2.708 0.795)" fill="none" stroke={color1} strokeLinecap="round"
                      strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_736" d="M11.677,6.75,7.191,12.133,4.5,9.441" transform="translate(-0.014 0.427)"
                      fill="none"
                      stroke={color1} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            </g>
            <text id="Username:_admin_" transform="translate(1593 220)" fill={color1} fontSize="14"
                  fontFamily="DroidSans-Bold, Droid Sans" fontWeight="700">
                <tspan x="0" y="0">{t("gatewayGraphic.username")}:</tspan>
                <tspan y="0" fontSize="13"></tspan>
                <tspan y="0" fill={color2} fontSize="13"> „admin“</tspan>
            </text>
            <g id="single-neutral-shield" transform="translate(1546 202.199)">
                <path id="Path_787"
                      d="M27.665,11.739A16.149,16.149,0,0,1,14.205,27.665,16.149,16.149,0,0,1,.75,11.739v-9.2A1.794,1.794,0,0,1,2.544.75H25.87a1.794,1.794,0,0,1,1.794,1.794Z"
                      transform="translate(-0.75 -0.75)" fill="none" stroke={color1} strokeLinecap="round"
                      strokeLinejoin="round" strokeWidth="1.5"/>
                <ellipse id="Ellipse_404" cx="4.037" cy="4.037" rx="4.037" ry="4.037" transform="translate(9.42 5.383)"
                         fill="none" stroke={color1} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_788" d="M19.5,17.4a7.216,7.216,0,0,0-12.888,0" transform="translate(0.4 1.738)"
                      fill="none"
                      stroke={color1} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
            </g>
            <g id="firewall" transform="translate(1546 296.105)">
                <rect id="Rectangle_729" width="27.148" height="10.859" transform="translate(0 16.289)"
                      strokeWidth="1.5"
                      stroke={color1} strokeLinecap="round" strokeLinejoin="round" fill="none"/>
                <line id="Line_193" x2="27.148" transform="translate(0 21.718)" fill="none" stroke={color1}
                      strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <line id="Line_194" y2="5.43" transform="translate(18.098 16.289)" fill="none" stroke={color1}
                      strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <line id="Line_195" y2="5.43" transform="translate(9.049 16.289)" fill="none" stroke={color1}
                      strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <line id="Line_196" y2="5.43" transform="translate(5.43 21.718)" fill="none" stroke={color1}
                      strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <line id="Line_197" y2="5.43" transform="translate(14.479 21.718)" fill="none" stroke={color1}
                      strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <line id="Line_198" y2="5.43" transform="translate(23.528 21.718)" fill="none" stroke={color1}
                      strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5"/>
                <path id="Path_792"
                      d="M12.515,17.039C9.258,17.039,6,14.607,6,11.609c0-3.258,1.629-5.43,4.344-5.43-.362,1.81-.543,3.258,1.629,3.258,3.258,0,2.373-4.736-1.086-8.687,4.5,0,9.23,4.525,9.23,8.144C20.117,13.392,19.031,17.039,12.515,17.039Z"
                      transform="translate(0.334 -0.75)" fill="none" stroke={color1} strokeLinecap="round"
                      strokeLinejoin="round" strokeWidth="1.5"/>
            </g>
            <g id="Group_1290" transform="translate(1593.11 299.626)">
                <text id="Overview" transform="translate(-0.11 26.374)" fill={color2} fontSize="12"
                      fontFamily="DroidSans, Droid Sans" letterSpacing="0.007em">
                    <tspan x="0" y="11"><Link
                        to={`/ui/gsp/help/manual?jump=${encodeURIComponent("faq.html#frequently-asked-questions")}`}>{t("see.documentation")}</Link>
                    </tspan>


                </text>
                <text id="The_Firewall_settings:" transform="translate(-0.11 12.374)" fill={color1} fontSize="14"
                      fontFamily="DroidSans-Bold, Droid Sans" fontWeight="700">
                    <tspan x="0" y="0">{t("gatewayGraphic.firewallSettings")}</tspan>
                </text>
            </g>
            <text id="Data_overview" transform="translate(1416 138)" fill={color1} fontSize="26.8"
                  fontFamily="DroidSans, Droid Sans" letterSpacing="0.007em">
                <tspan x="0" y="0">Details</tspan>
            </text>
            <g id="Group_2417" transform="translate(1416.033 208.495)">
                <g id="Group_2275" transform="translate(0 0)">
                    <g id="Group_2251">
                        <g id="Group_1971" transform="translate(0 44.51)">
                            <path id="Path_1374" d="M498.048,439.962,463.094,415.02l34.954-24.942L533,415.02Z"
                                  transform="translate(-463.094 -390.078)" fill={color1}/>
                            <path id="Path_1373" d="M553.2,429.435v17.111l-34.954,24.942V454.377Z"
                                  transform="translate(-483.295 -404.493)" fill={color3}/>
                            <path id="Path_1376" d="M498.048,454.377v17.111l-34.954-24.942V429.435Z"
                                  transform="translate(-463.094 -404.493)" fill={color4}/>
                        </g>
                        <g id="Group_1972" transform="translate(0 16.974)">
                            <path id="Path_1378" d="M498.048,439.962,463.094,415.02l34.954-24.942L533,415.02Z"
                                  transform="translate(-463.094 -390.078)" fill={color1}/>
                            <path id="Path_1375" d="M553.2,429.435v17.111l-34.954,24.942V454.377Z"
                                  transform="translate(-483.295 -404.493)" fill={color3}/>
                            <path id="Path_1377" d="M498.048,454.377v17.111l-34.954-24.942V429.435Z"
                                  transform="translate(-463.094 -404.493)" fill={color4}/>
                        </g>
                        <g id="Group_1973">
                            <path id="Path_1381" d="M498.048,439.962,463.094,415.02l34.954-24.942L533,415.02Z"
                                  transform="translate(-463.094 -390.078)" opacity="0.4" fill="url(#linear-gradient)"/>
                            <path id="Path_1379" d="M553.2,429.435v17.111l-34.954,24.942V454.377Z"
                                  transform="translate(-483.295 -404.493)" opacity="0.65"
                                  fill="url(#linear-gradient-2)"/>
                            <path id="Path_1380" d="M498.048,454.377v17.111l-34.954-24.942V429.435Z"
                                  transform="translate(-463.094 -404.493)" opacity="0.65"
                                  fill="url(#linear-gradient-3)"/>
                        </g>
                    </g>
                </g>
            </g>
            <path id="Path_1579" d="M0,21.493v22.08L23.069,57.708" transform="translate(1557.793 215.738) rotate(90)"
                  fill="none" stroke={color1} strokeWidth="1"/>
            <path id="Path_1586" d="M0,36.215V14.135L23.068,0" transform="translate(1500.085 311.807) rotate(-90)"
                  fill="none" stroke={color1} strokeWidth="1"/>
            <line id="Line_299" x2="36" transform="translate(1500.5 260.5)" fill="none" stroke={color1}
                  strokeWidth="1"/>
            <g id="Ellipse_775" transform="translate(1532 213)" fill="#fff" stroke={color1} strokeWidth="1">
                <circle cx="3" cy="3" r="3" stroke="none"/>
                <circle cx="3" cy="3" r="2.5" fill="none"/>
            </g>
            <g id="Ellipse_776" transform="translate(1532 258)" fill="#fff" stroke={color1} strokeWidth="1">
                <circle cx="3" cy="3" r="3" stroke="none"/>
                <circle cx="3" cy="3" r="2.5" fill="none"/>
            </g>
            <g id="Ellipse_777" transform="translate(1532 309)" fill="#fff" stroke={color1} strokeWidth="1">
                <circle cx="3" cy="3" r="3" stroke="none"/>
                <circle cx="3" cy="3" r="2.5" fill="none"/>
            </g>
        </g>
    </svg>

}

