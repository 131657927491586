(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"));
	else if(typeof define === 'function' && define.amd)
		define("Library", ["react"], factory);
	else if(typeof exports === 'object')
		exports["Library"] = factory(require("react"));
	else
		root["Library"] = factory(root["react"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__155__) => {
return 