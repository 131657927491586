import React from "react"
import {useTranslation} from "react-i18next"
import ErrorBoundary from "../../Components/ErrorBoundary"
import {Headline} from "../../StyledComponents/Font/Font"
import {InvoiceTable} from "./InvoiceTable"


export function InvoicePage() {

    const {t} = useTranslation()

    return <React.Fragment>
        <Headline>{t("invoice.headline")}</Headline>
        <ErrorBoundary>
            <InvoiceTable/>
        </ErrorBoundary>
    </React.Fragment>

}

