
import * as HttpStatuscode from "http-status-codes";


export class NotFoundError extends Error {
    httpStatus = HttpStatuscode.NOT_FOUND;
    type = NotFoundError;

    constructor(message = "") {
        super(`Not found: '${message}'`);
    }
}
