export function checkProps(data, columns, searchableColumns, defaultSortBy, cacheKey, actionCell, entryGenerator, actions, searchText) {
    if (!Array.isArray(data)) {
        throw  new Error("data props must be an array.")
    }

    if (!Array.isArray(columns)) {
        throw new Error("columns props must be an array ")
    }

    if (Array.isArray(searchableColumns)) {
        if (searchableColumns.length === 0) {
            throw new Error("searchableColumns cannot be an empty array. Either pass an array of fields or undefined.")
        }
    }
}
