//@flow


import {TaskRestApiClient} from "../../services/apiClients/Task/TaskRestApiClient"
import {TaskExecutionRestApiClient} from "../../services/apiClients/TaskExecution/TaskExecutionRestApiClient"
import {ReportRestApiClient} from "../../services/apiClients/Report/ReportRestApiClient"
import {ScanmanagementController} from "./ScanmanagementController"
import React from "react"
import {AlertContext} from "../../Context/AlertContext"
import {InvalidStatusCodeError} from "../../services/Exceptions"
import {SECOND} from "../../constants"

//$FlowFixMe
export const TaskContext = React.createContext({})

type State = {
    tasks: ?Array<GSPTask>;
    smallReports: ?Array<GSPSmallReport>;
    taskExecutions: {
        [string]: GSPTaskExecutionStatus;
    };
    overview: any;
    _loading: boolean;
    _exception: any;
    _sending: any;
}

class _TaskProvider extends React.Component<any, State> {

    taskRestApiClient: TaskRestApiClient
    taskExecutionRestApiClient: TaskExecutionRestApiClient
    reportRestClient: ReportRestApiClient
    timer: any

    state = {
        _loading: false,
        _exception: null,
        tasks: [],
        smallReports: null,
        taskExecutions: {},
        overview: undefined
    }

    constructor(props: any) {
        super(props)

        this.taskRestApiClient = new TaskRestApiClient()
        this.taskExecutionRestApiClient = new TaskExecutionRestApiClient()
        this.reportRestClient = new ReportRestApiClient()
    }

    componentDidMount(): void {
        this.loadData()
        this.timer = setInterval(() => this.intervalUpdate(), 5 * SECOND)
    }

    componentWillUnmount() {
        clearInterval(this.timer)
    }

    intervalUpdate = () => {
        this.loadData(false)
    }

    loadReports = async () => {
        return await this.reportRestClient.getAllReportsSmall()
            .catch(this.handleError)
    }

    loadExecutions = async () => {
        //$FlowFixMe
        return await this.taskExecutionRestApiClient.getLatestTaskExecutions()
            .catch(this.handleError)
    }

    loadTasks = async () => {
        return await this.taskRestApiClient.getAll()
            .catch(this.handleError)
    }

    handleError = (error: typeof InvalidStatusCodeError) => {
        this.context.handleError(error)
    }

    loadData = (showLoading: boolean = true) => {
        this.setState({_loading: showLoading})

        const allTasks = this.loadTasks()
        const latestTaskExecutions = this.loadExecutions()
        const overview = this.reportRestClient.getOverview()


        Promise.all([allTasks, latestTaskExecutions, overview])
            .then(([tasks, taskExecutions, overview]) => {

                this.setState({
                    tasks: tasks,
                    taskExecutions: ScanmanagementController.reformatTaskExecutionsToKeyByTaskId(taskExecutions),
                    overview: overview
                })
            })
            .catch(error => {
                this.setState({_exception: error})
            })
            .finally(() => {
                this.setState({
                    _loading: false,
                    _sending: false
                })
            })
    }

    setSending = () => {
        this.setState({_sending: true})
    }

    render() {
        return (
            <TaskContext.Provider value={{
                ...this.state,
                setSending: this.setSending
            }}>
                {this.props.children}
            </TaskContext.Provider>
        )
    }

}

//$FlowFixMe
_TaskProvider.contextType = AlertContext


export const TaskProvider = _TaskProvider
