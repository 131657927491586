//@flow

import {CONSTANTS} from "./actions";


type State = {
    Open: boolean;
    Title: string;
    Text: string;
    Callback: any;

}

const initialState: State = {
    Open: false,
    Title: "",
    Text: "",
    Callback: null,
};

const reducer = (state: State = initialState, action: any) => {
    switch (action.type) {

        case CONSTANTS.OPEN_DIALOG :
            return {
                ...state,
                Open: true,
                Title: action.title,
                Text: action.text,
                Callback: action.callback,
            };

        case CONSTANTS.CLOSE_DIALOG:
            return {
                ...state,
                Open: false,
            };

        default:
            return {...state};
    }
};

export default reducer;
