//@flow

import React from "react"
import {withTranslation} from "react-i18next"
import {connect} from "react-redux"
import {Container} from "reactstrap"
import {bindActionCreators, compose} from "redux"
import {InfoText} from "../../../Components/Basic/InfoText"
import {ListContainer} from "../../../Components/Basic/ListContainer"
import {openAlertDialog} from "../../../Components/Dialogs/AlertDialog/actions"
import {ModalSlideIn} from "../../../Components/Dialogs/SlideIn/ModalSlideIn"
import {ErrorBoundary} from "../../../Components/ErrorBoundary"
import {Button} from "@greenbone/cloud-component-library"
import {Headline} from "../../../StyledComponents/Font/Font"
import {ScheduleForm} from "./ScheduleForm"
import {ScheduleList} from "./ScheduleList"

type State = {
    isSlideInOpen: boolean;
    refresh: boolean;
    id: ?string;
}

type Props = {
    t: any;
}

class _SchedulePage extends React.Component<Props, State> {

    state = {
        isSlideInOpen: false,
        refresh: true,
        id: null
    };

    openScheduleSlideIn = () => {
        this.setState({
            isSlideInOpen: true,
            id: null
        });
    };

    openEditScheduleSlideIn = (id: string) => {
        this.setState({
            isSlideInOpen: true,
            id: id
        });
    };

    handleCloseSlide = () => {
        this.setState({
            isSlideInOpen: false,
            refresh: !this.state.refresh
        });
    };

    render() {
        const {t} = this.props;

        return (
            <div>
                <Headline>{t("menu.taskMenu.schedules")}</Headline>
                <InfoText headline={""}>
                    <p>
                        {t("schedule.description")}
                    </p>
                </InfoText>
                <ListContainer>
                    <ErrorBoundary>
                        <ScheduleList actions={<Button style={{margin: 0}} onClick={this.openScheduleSlideIn}>
                            {t("schedule.action.createNew")}
                        </Button>} refresh={this.state.refresh} onUpdateEntry={this.openEditScheduleSlideIn}/>
                    </ErrorBoundary>
                    <ModalSlideIn isOpen={this.state.isSlideInOpen} key={2}>
                        <Container fluid style={{maxWidth: "830px", minWidth: "830px", padding: "4rem"}}>
                            <ErrorBoundary>
                                <ScheduleForm onClose={this.handleCloseSlide} id={this.state.id}/>
                            </ErrorBoundary>
                        </Container>
                    </ModalSlideIn>
                </ListContainer>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    let actions = bindActionCreators(
        {
            openAlertDialog: openAlertDialog
        },
        dispatch
    );
    return {...actions, dispatch};
}

function mapStateToProps(state) {
    return {};
}

export const SchedulePage = compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps)
)(_SchedulePage);
