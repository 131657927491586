import React from 'react'
import {IconButton} from "@material-ui/core";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";
import {FlexRow} from "../Flex";
import {getEmail} from "..";
import LogoutIcon from "../../../assets/icons/Logout";

const Mail = styled.div`
  border-right: 1px solid ${props => props.theme.navbar.font};
  color: ${props => props.theme.navbar.font};
  padding-right: 10px;
`;

const IconButtonWithColor = styled(IconButton)`
  color: ${props => props.theme.navbar.font} !important;
`;


export function Logout(props) {
    const {keycloak} = props;

    const onLogout = () => {
        keycloak.logout();
    };

    return <FlexRow justifyContent={"flex-end"} alignItems={"center"}>
        <Mail>
            <div>{getEmail(keycloak.token)}</div>
        </Mail>
        <IconButtonWithColor data-tip data-for='LOGOUT-ICON' onClick={() => {
            onLogout();
        }}>
            <LogoutIcon style={{height: "20px"}}/>
        </IconButtonWithColor>
        <ReactTooltip id='LOGOUT-ICON' place="bottom" type='dark' effect='solid'>
            Sign out
        </ReactTooltip>
    </FlexRow>;
}

