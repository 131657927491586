import {Colors} from "../app/Theme"

export class PublicStyleRestApiClient {
    constructor(fetchClient) {
        this.httpClient = fetchClient
    }


    static async getTheme(fetchClient) {
        try {
            const client = new PublicStyleRestApiClient(fetchClient)
            const result = await client.getStyles()
            return PublicStyleRestApiClient.compileTheme(result)
        } catch (e) {
            console.warn("Could not fetch styles, falling back to default")
        }
        return PublicStyleRestApiClient.compileTheme({

            "base": {
                "mainColor": "#66C430",
                "contrastColor": "#ffffff",
                "footerTextColor": "#ffffff",
                "footerBackgroundColor": "#000000",
                "languageSelectActiveColor": "#ffffff",
                "languageSelectInactiveColor": "#000000"
            },
            "animation": {"backgroundColor": "#ffffff"},
            "login": {
                "text": "#000",
                "screenBackgroundColor": "#E2E2E2",
                "illustrationContrastColor": "#707070"
            },
            "button": {
                "background": "#4c4c4d",
                "disabled": "#c2c2c2",
                "hover": "#212121",
                "text": "#ffffff"
            },
            "navbar": {
                "background": "#66C430",
                "text": "#000"
            },
            "checkbox": {
                "color": "#4c4c4d"
            }
        })

    }

    static compileTheme(result) {
        return {
            base: result.base.mainColor,
            contrast: result.base.contrastColor,
            animation: {
                background: result.animation.backgroundColor
            },
            login: {
                text: result.login.text,
                screenBackgroundColor: result.login.screenBackgroundColor,
                illustrationContrastColor: result.login.illustrationContrastColor,
                footerTextColor: result.base.footerTextColor,
                footerBackgroundColor: result.base.footerBackgroundColor,
                languageSelectActiveColor: result.base.languageSelectActiveColor,
                languageSelectInactiveColor: result.base.languageSelectInactiveColor
            },
            button: {
                normal: {
                    text: result.button.text,
                    background: result.button.background,
                    hover: result.button.hover,
                    disabled: result.button.disabled
                },
                abort: {
                    text: result.button.text,
                    background: result.button.background,
                    hover: result.button.hover,
                    disabled: result.button.disabled
                },
                icon: {
                    background: "transparent",
                    hover: "#e6e6e6",
                    active: "#c2c2c2"
                }
            },
            slider: {
                thumb: {
                    background: "#7EC14B",
                    border: "#FFFFFF"
                },
                background: "#D5D5D5"
            },
            navbar: {
                background: result.navbar.background,
                font: result.navbar.text
            },
            checkbox: {
                color: result.button.background
            },
            spinner: {
                background: Colors.grey.light,
                foreground: result.base.mainColor
            },
            searchable: {
                primary25: "rgb(211,211,211)",
                primary50: "rgb(189,189,189)",
                primary75: "rgb(163,163,163)",
                primary: result.button.background
            }
        }
    }

    async getStyles() {
        const response = await this.httpClient.get("/vmssp-admin/public/design")
        return JSON.parse(await response.text())
    }

}

