import styled, {css} from "styled-components"
import {ReportHelper} from "../ReportHelper"


export const Table = styled.div`
    display: grid;
    grid-template-columns: 4fr .5fr 1fr 1fr 1fr 1fr 1fr 1fr;

    ${props => props.templateColumns && css`
    grid-template-columns: ${props.templateColumns};
  `};
  grid-row-gap: .5rem;
`

export const Name = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: bold;
`

export const Severity = styled.div`
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  color: white;
  background: ${props => ReportHelper.SeverityToColor(props.severity)};
  flex-shrink: 0;
`

export const TableCell = styled.div`
  height: 64px;
  border-top: 1px solid #707070;
  border-bottom: 1px solid #707070;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  a {
    color: rgb(33, 37, 41)
  }
`

export const ClickableTableCell = styled(TableCell)`
  text-decoration: underline;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background:  ${props => props.theme.button.normal.hover};
    color: ${props => props.theme.button.normal.text};
    cursor: pointer;
  }
`

export const LastCell = styled(TableCell)`
  border-right: 1px solid #707070;
  border-radius: 3px;
`
export const LastClickableTableCell = styled(LastCell)`
  text-decoration: underline;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background:  ${props => props.theme.button.normal.hover};
    color: ${props => props.theme.button.normal.text};
    cursor: pointer;
  }
`

export const NameCell = styled.div`
  height: 64px;
  border-top: 1px solid #707070;
  border-bottom: 1px solid #707070;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: bold;
  display: flex;
  align-items: center;
  padding-left: 1rem;

  border-left: 5px solid ${props => ReportHelper.SeverityToColor(props.severity)};
`
