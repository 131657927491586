// @flow

import type {ComponentType} from "react"
import React from "react"
import styled from "styled-components"
import {Colors, ColorTheme} from "../../../Theme"


const Box: ComponentType<{ type: "info" | "tip" }> = styled.div`
  border-top: 0.24rem solid ${props => props.type === "info" ? ColorTheme.background.dark : Colors.yellow.normal};
  background: ${props => props.type === "info" ? ColorTheme.background.normal : Colors.yellow.light};
  color: ${props => props.type === "info" ? "white" : Colors.grey.dark};
  margin-bottom: 1.25rem;
  padding: 0.94rem 0.94rem 0.94rem 0.94rem;
`

type Props = {
    type: "info" | "tip";
    children?: any;
}

export const InfoBox = (props: Props) => (
    <Box type={props.type}>
        {props.children}
    </Box>
);

export const InfoHeadline: any = styled.h1`
  font-size: 1.5rem;
  font-weight: normal;
  margin-bottom: 0;
`;
