//@flow

import React from "react"
import {withTranslation} from "react-i18next"
import {compose} from "redux"
import styled from "styled-components"
import {AbortButton, Button, FlexRow} from "@greenbone/cloud-component-library"
import {Bold, Caption, Headline, Paragraph} from "../../../StyledComponents/Font/Font"
import {Modal} from "../../../StyledComponents/Modal/Modal"
import {RootUrlProvider} from "../../../UrlProvider/RootUrlProvider"
import {NavigateFunction} from "react-router";
import {withNavigate} from "../../../../hocs/withNavigate";


const LastParagraph = styled(Paragraph)`
  margin-bottom: 1rem;
`

const TopHeadline = styled(Headline)`
  margin-bottom: 0.125rem !important;
`

type Props = {
    open: boolean;
    closeDialog: EventCallback;
    navigate: NavigateFunction;
    t: any;
}

class _CancelDialog extends React.Component<Props> {
    handleStay = (event: any) => {
        this.props.closeDialog(event);
    };

    handleLeave = (event: any) => {
        this.props.navigate(RootUrlProvider.getBase());
    };

    render() {
        const {open, t} = this.props;

        return (
            <Modal open={open}>
                <TopHeadline>{t("wizard.components.cancelDialog.cancelWizard")}</TopHeadline>
                <Caption style={{marginBottom: "1rem"}}>{t("wizard.components.cancelDialog.prepareNewScan")}</Caption>

                <Paragraph>
                    {t("wizard.components.cancelDialog.sureYouCancel")}
                </Paragraph>

                <LastParagraph>
                    <Bold>{t("wizard.components.cancelDialog.allWillBeLost")}</Bold>
                </LastParagraph>

                <FlexRow justifyContent={"flex-end"}>
                    <div style={{
                        marginTop: "1.5rem",
                        display: "grid",
                        gridTemplateColumns: "auto auto",
                        gridColumnGap: "2.3rem",
                    }}>
                        <AbortButton
                            onClick={this.handleStay}>{t("wizard.components.cancelDialog.cancelCancellation")}</AbortButton>
                        <Button onClick={this.handleLeave}>{t("wizard.components.cancelDialog.goOnAndCancel")}</Button>
                    </div>
                </FlexRow>
            </Modal>
        );
    }
}

export const CancelDialog = compose(
    withTranslation(),
    withNavigate,
)(_CancelDialog);
