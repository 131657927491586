// @flow

import {UrlProvider} from "./UrlProvider";


export class RootUrlProvider extends UrlProvider {
    static BASE_URL = UrlProvider.ROOT_PATH;

    static getBase() {
        return RootUrlProvider.BASE_URL;
    }

    static getWildcard() {
        return `${this.getBase()}/*`
    }

    static getStartpage() {
        return `${RootUrlProvider.getBase()}/dashboard`;
    }

    static wizard() {
        return `${RootUrlProvider.getBase()}/wizard`;
    }
}
