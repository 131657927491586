//@flow
import React from "react"
import {TermsOfUsePage} from "./TermsOfUsePage"
import {PrivacyPolicy} from "./PrivicayPolicy"
import {GreenbonePrivacyPolicy} from "./GreenbonePrivicayPolicy"
import {useLegalConfig} from "./UseLegalConfig"


export function CombinedLegal(props: {}) {
    const {privacyPolicyEnabled, isLoading} = useLegalConfig()

    return <React.Fragment>

        <TermsOfUsePage/>
        <hr/>


        {(!isLoading && privacyPolicyEnabled === true) && <>
            <PrivacyPolicy/>
            <hr/>
        </>}


        <GreenbonePrivacyPolicy/>
    </React.Fragment>
}
