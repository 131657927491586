import React from "react";
import {ThemeProvider} from "styled-components";
import {ColorTheme} from "../../../Theme";


export const StyleContext = React.createContext({});

export class StyleProvider extends React.Component {
    key = "StyleProvider";
    state = {
        ...ColorTheme
    };

    componentDidMount() {
        const {theme} = this.props;
        if (theme) {
            this.setState({...theme});
        }
    }


    updateTheme = (blockName, block) => {
        this.setState({[blockName]: block});
    };

    reset = () => {
        this.setState({...ColorTheme});
    };

    render() {
        return (
            <StyleContext.Provider value={{...this.state, updateTheme: this.updateTheme, reset: this.reset}}>
                <ThemeProvider theme={this.state}>
                    {this.props.children}
                </ThemeProvider>
            </StyleContext.Provider>
        );
    }

}
