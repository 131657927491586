//@flow

import styled from "styled-components"
import {Theme} from "../../Theme"
import {Text} from "../Font/Font"


export const UnorderedList = styled.ul`
  padding-left: 1.25rem;
`

export const ListItem = styled(Text.withComponent("li"))`
  font-family: ${Theme.default.font};
  font-size: 1rem;
`

