import React from 'react'
import styled, {css} from "styled-components"

export const IconSurrounder = styled.div`
  svg {
    height: 1.125rem;
    /*margin-right: 0.56rem;*/
  }
`;

export const TopLi = styled((
        {
            className,
            children
        }
    ) => <li className={className}>{children}</li>
)`
  box-sizing: border-box;
  list-style: none;


  ${(props) => props.open && css`background: ${props => props.theme.sidebar.activeMenu};
    box-shadow: inset 4px 0 0 ${props => props.theme.sidebar.insetShadow};`}
`;

export const Collapsible = styled.ul`
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
  color: white;
  display: ${props => props.isOpen ? "block" : "none"};
`;

export const Menu = styled.ul`
  list-style: none;
  padding-left: 0;
`

export const SimpleMenu = styled.ul`
  list-style: none;
  padding-left: 0;
  margin: 0;
`
