import {useReducer} from "react"

const Actions = {
    SET_DATA: "SET_DATA",
    SET_LOADING: "SET_LOADING"
}

function reducer(state, action) {
    switch (action.type) {
        case Actions.SET_DATA: {
            return {...state, data: action.payload, isLoading: false}
        }

        case Actions.SET_LOADING: {
            return {...state, isLoading: action.payload}
        }

        default:
            return state
    }
}

export function useDataFetchState(dataName) {
    const [state, dispatch] = useReducer(reducer, {data: null, isLoading: false})

    const setData = (data) => {
        dispatch({type: Actions.SET_DATA, payload: data})
    }

    const startLoading = (data) => {
        dispatch({type: Actions.SET_LOADING, payload: true})
    }

    const stopLoading = (data) => {
        dispatch({type: Actions.SET_LOADING, payload: false})
    }

    return {
        data: state.data,
        isLoading: state.isLoading,
        setData,
        startLoading,
        stopLoading,
        [dataName]: state.data
    }
}
