import React from "react"
import styled, {css} from "styled-components"
import {IconButton as MuiIB, Tooltip} from "@material-ui/core"
import {ButtonSpinner} from "../Spinner/ButtonSpinner"


const Types = {
    outline: css`
      background: transparent;
      border: 1px solid ${props => props.theme.button.normal.background};
      color: ${props => props.theme.button.normal.background};

      &:hover {
        background: transparent;
        border: 1px solid ${props => props.theme.button.normal.hover};
        color: ${props => props.theme.button.normal.hover};
      }
    ;
      font-weight: normal;
    `
}

export const Button = (styled.button`
  box-sizing: border-box;
  color: ${props => props.theme.button.normal.text};
  background-color: ${props => props.theme.button.normal.background};
  border: none;
  border-radius: 3px;
  font-size: 1rem;
  font-weight: normal;
  font-family: Netto, sans-serif;
  min-height: 2.25rem;

  margin: 0;
  margin-top: 0.94rem;
  margin-bottom: 0.94rem;
  padding-right: 1.25rem;
  padding-left: 1.25rem;

  ${props => props.noMargin && css`
    margin: 0
  `}
  &:hover {
    cursor: pointer;
    background-color: ${props => props.theme.button.normal.hover};
  }

  ${props => props.disabled && css`
    background-color: ${props => props.theme.button.normal.disabled};

    &:hover {
      cursor: not-allowed;
      background-color: ${props => props.theme.button.normal.disabled};
    }
  `} ${props => props.loading && css`
    &:hover {
      background-color: ${props => props.theme.button.normal.background};
      cursor: wait;
    }
  `} ${props => props.type && Types[props.type]};

  & + & {
    margin-left: 1.25rem;
  }

`)

export const ActionButton = styled(Button)`
  margin: 0;
`

const GhostButtonVariants = {
    negative: css`
      border: 1px solid ${props => props.theme.button.normal.background};
      color: ${props => props.theme.button.normal.background};

      &:hover {
        background: transparent;
        border: 1px solid ${props => props.theme.button.normal.hover};
        color: ${props => props.theme.button.normal.hover};
      }
    ;
    `
}

export const GhostButton = styled(Button)`
  background: transparent;
  border: 1px solid ${props => props.theme.button.normal.background};
  color: ${props => props.theme.button.normal.background};
  font-weight: normal;

  &:hover {
    background: transparent;
    border: 1px solid ${props => props.theme.button.normal.hover};
    color: ${props => props.theme.button.normal.hover};
  }
;

  ${props => props.variant && GhostButtonVariants[props.variant]}
`

export const AbortButton = GhostButton


export const IconButtonTooltip = ({label, children, ...other}) => {
    return <Tooltip title={label}>
        <MuiIB aria-label={label} {...other}>
            {children}
        </MuiIB>
    </Tooltip>
}

export const IconButton = styled.button`
  border: none;
  background: ${props => props.theme.button.icon.background};
  box-sizing: border-box;
  min-height: 2.35rem;
  width: 2.35rem;
  padding: 0.55rem;
  border-radius: 3px;
  transition: all 0.3s ease;

  &:hover {
    background: ${props => props.theme.button.icon.hover};
    cursor: pointer;
  }

  &:active {
    background: ${props => props.theme.button.icon.active};
  }

  ${props => props.disabled && css`
    opacity: 0.5;

    &:hover {
      background: ${props => props.theme.button.icon.background};
      cursor: default;
    }

    &:active {
      background: ${props => props.theme.button.icon.background};
    }
  `}
`

export const LinkButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0;

  &:hover {
    text-decoration: underline;
  }

  ${props => props.disabled && css`
    color: lightgrey;
    cursor: default;

    &:hover {
      text-decoration: none;
    }
  `}
`

export function SaveButton({children, saving, disabled, ...other}) {

    return <Button disabled={saving || disabled} style={{display: "flex", alignItems: "center"}} {...other}>{children}
        {saving && <ButtonSpinner style={{margin: 0, marginLeft: "1rem"}}/>}

    </Button>
}

