import React, {useEffect, useRef} from "react"
import {Spinner} from "@greenbone/cloud-component-library"
import {buildUrl} from "../../../mocks/buildUrl"
import {useTranslation} from "react-i18next"

export function GreenboneTermsOfUse() {

    const {i18n} = useTranslation()
    const containerElement = useRef(null)

    const language = i18n?.language

    useEffect(() => {
        fetch(buildUrl(`/ui/manual/legal/${i18n.language}/terms.html`))
            .then(async response => {
                const html = await response.text()
                containerElement.current.innerHTML = html
            })
            .catch(e => {
                console.log(e)
            })
    }, [language])

    return <div ref={containerElement}>
        <Spinner/>
    </div>
}
