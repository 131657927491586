import React, {useState} from "react"
import {Spinner} from "../Spinner"
import styled from "styled-components"
import Arrow from "./arrow-down-1.svg?url"
import {Colors} from "../../../Theme"
import {FlexRow} from "../Flex"
import DownloadIcon from "./download-bottom.svg?url"


const DownloadControl = styled(FlexRow)`
  min-width: 12.5rem;
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
`

const SaveButton = styled.button`
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.button.normal.background};
  min-width: 2.188rem;
  max-width: 2.188rem;
  height: 2.188rem;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;

   &:hover {
    cursor: pointer;
    background: ${props => props.theme.button.normal.hover};
   }

   &:active {
    cursor: pointer;
    background: ${props => props.theme.button.normal.hover};
   }

  span {
      display: block;
      height: 100%;
      width: 100%;
      background:  url(${DownloadIcon});
      color: white;
      background-size: 70%;
      background-position: center;
      background-repeat: no-repeat;
  }
`

const DownloadText = styled.div`
  color: ${Colors.grey.normal};
  position: absolute;
  margin-top: -1.5rem;
`


const Selector = styled.div`
  box-sizing: border-box;
  border: 1px solid ${Colors.grey.light};
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  height: 2.188rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  
  &:hover {
    cursor: pointer;
    border: 1px solid ${Colors.grey.normal};;
  }
`

const ArrowArea = styled.div`
  box-sizing: border-box;  
  width: 1.875rem;
  background-image: url(${Arrow});
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center ;
`

const Selections = styled.div`
  box-sizing: border-box;
  border: 1px solid ${Colors.grey.light};
  position: absolute;
  min-height: 2.188rem;
  width: inherit;
  background: red;
  margin-top: 2.188rem;
  z-index: 10;
  
  &:last-child{
    border-bottom: none;
  }
`

const Option = styled.div`
  height: 2.188rem;
  background: white;
  box-sizing: border-box;
  border-bottom: 1px solid ${Colors.grey.light};
  color: ${Colors.grey.normal};
  
  display: flex;
  padding-left: 0.313rem;
  align-items: center;
  
  &:hover {
    color: white;
    background: ${Colors.blue.normal};
  }
`

const DisplayText = styled.div`
  padding-left: 0.625rem;
  display: flex;
  align-items: center;
  color: ${Colors.grey.normal};
`


export class Select extends React.Component {
    node = null
    state = {
        isOpen: false
    }

    componentDidMount() {
        window.document.body.addEventListener("click", this.handleClose)
    }

    componentWillUnmount() {
        window.document.body.removeEventListener("click", this.handleClose)
    }

    selectOption = (value) => {
        if (this.props.onChange) {
            this.props.onChange({name: this.props.name, value: value})
        }
    }

    handleOpen = (event) => {
        this.setState(prevState => {
            return {isOpen: !prevState.isOpen}
        })
    }

    handleClose = (event) => {
        if (!this.node) {
            return
        }

        if (!this.node.contains(event.target)) {
            if (this.state.isOpen) {
                this.setState({isOpen: false})
            }
        }
    }

    render() {
        const {options, value} = this.props
        const {isOpen} = this.state

        return (
            <>
                <Selector ref={node => this.node = node} onClick={this.handleOpen} {...this.props}>
                    <DisplayText>
                        {options.find(op => op.value === value).label}
                    </DisplayText>
                    <ArrowArea>
                    </ArrowArea>
                    {isOpen && <Selections>
                        {options.map(data => {
                            return <Option key={data.value} onClick={(event) => {
                                event.preventDefault()
                                this.selectOption(data.value)
                            }} value={data.value}>{data.label}</Option>
                        })}
                    </Selections>}
                </Selector>
            </>
        )
    }
}


export function DownloadSelect({loading, onClick, value, options, name, ...props}) {

    const [selected, setSelected] = useState(value)

    const handleChange = ({value}) => {
        setSelected(value)
    }

    const handleClick = (event) => {
        const option = options.find(option => option.value === selected)
        if (option) {
            option.action()
        }
    }

    return <DownloadControl {...props}>
        <DownloadText>
            Download
        </DownloadText>
        <FlexRow>
            <Select
                value={selected}
                onChange={handleChange}
                options={options}
                name={name}
            />
            <SaveButton disabled={loading} onClick={handleClick}>
                {loading ? <Spinner/> : <span></span>}
            </SaveButton>
        </FlexRow>
    </DownloadControl>
}
