import React from "react"
import {TermsOfUse} from "./TermsOfUse"
import {GreenboneTermsOfUse} from "./GreenboneTermsOfUse"
import {useLegalConfig} from "./UseLegalConfig"


export function TermsOfUsePage() {

    const {termsOfUseEnabled, isLoading} = useLegalConfig()


    return <React.Fragment>
        {(!isLoading && termsOfUseEnabled === true) && <> <TermsOfUse/>
            <hr/>
        </>}


        <GreenboneTermsOfUse/>
    </React.Fragment>

}
