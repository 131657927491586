//@flow

import Promise from "bluebird"
import React from "react"
import {withTranslation} from "react-i18next"
import {connect} from "react-redux"
import {Col, Container, Row} from "reactstrap"
import {bindActionCreators, compose} from "redux"
import {AbortButton, Button, FlexRow, GhostButton, Snackbar} from "@greenbone/cloud-component-library"
import {ModalSlideIn} from "../../../Components/Dialogs/SlideIn/ModalSlideIn"
import Loader from "../../../Components/Loader/Loader"
import {Converter} from "../../../Helper/Converter"
import {Headline, Paragraph, Subheadline} from "../../../StyledComponents/Font/Font"
import {getValidationResult} from "../../../controller/FieldValidator"
import {IsOptionalString} from "../../../controller/FormValidators"
import {ScheduleRestApiClient} from "../../../services/apiClients/Schedule/ScheduleRestApiClient"
import {RowSmall} from "../components/Grid"
import {ScheduleCreate} from "../components/ScheduleCreate"
import {ScheduleSelect} from "../components/ScheduleSelect"

export type ScheduleFields = {
    scheduleId: ?string;
}

type Props = {
    nextStep: EventCallback;
    previousStep: EventCallback;
    cancelWizard: EventCallback;
    pageNumber: number;
    handleSaveAndNext: EventCallback;
    initValues: ScheduleFields;
    t: any;
}

type State = {
    fields: ScheduleFields;
    fieldStatus: {
        scheduleId: ?boolean;
    };
    isValid: boolean;
    isScheduleCreateOpen: boolean;
    _loading: boolean;
    _exception: ?any;
    schedules: Array<GSPScheduleEntity>;
}

class ScheduleCreateSlideIn extends React.Component<{ onClose: any, onSave: any }> {
    render() {
        return <Container style={{minWidth: "800px"}}>
            <Row style={{margin: "1.0rem", marginTop: "2.5rem"}}>
                <Col>
                    <ScheduleCreate onClose={this.props.onClose} onSave={this.props.onSave}/>
                </Col>
            </Row>
        </Container>;
    }
}

class _Schedule extends React.Component<Props, State> {
    setStateAsync: Promise;
    scheduleApiClient: ScheduleRestApiClient;
    FieldValidators = {
        scheduleId: new IsOptionalString(),
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            fields: {...props.initValues},
            fieldStatus: {
                scheduleId: null,
            },
            isValid: false,
            isScheduleCreateOpen: false,
            _loading: false,
            _exception: null,
            schedules: [],
        };
        this.setStateAsync = Promise.promisify(this.setState);
        this.scheduleApiClient = new ScheduleRestApiClient();
    }

    componentDidMount() {
        this.loadSchedules();
    }

    loadSchedules = () => {
        this.setState({_loading: true});

        this.scheduleApiClient.getAll()
            .then((schedules: Array<GSPScheduleEntity>) => {
                this.setState({
                    schedules: schedules.sort((a: GSPSchedule, b: GSPSchedule) => {
                        return Converter.cmpString(a.name.toUpperCase(), b.name.toUpperCase());
                    }),
                });
            })
            .catch(error => {
                this.setState({
                    _exception: error,
                });
            })
            .finally(() => {
                this.setState({_loading: false});
            });
    };

    handleChangeSchedule = (scheduleId: string) => {
        this.setState(prevState => {
            let fields = prevState.fields;
            fields["scheduleId"] = prevState.fields.scheduleId === scheduleId ? null : scheduleId;
            return {fields};
        });
    };

    validateFields = async (): Promise<void> => {
        const {isValid, fieldValidity} = getValidationResult(
            this.state.fields,
            this.FieldValidators,
        );

        await this.setStateAsync({fieldStatus: fieldValidity, isValid});
    };

    onFormSubmit = (event: SyntheticEvent<HTMLFormElement>) => {
        event.preventDefault();

        this.validateFields()
            .then(() => {
                this.handleFormSubmit();
            });
    };

    handleFormSubmit = () => {
        const {t} = this.props;
        const {isValid, fieldStatus} = this.state;
        const {scheduleId} = this.state.fields;

        if (!isValid) {
            if (fieldStatus.scheduleId !== true) {
                Snackbar.Error(t("wizard.steps.schedule.chooseATimePlan"));
            }
            return;
        }

        this.props.handleSaveAndNext({
            scheduleId,
        });
    };

    handleScheduleCreateOpen = () => {
        this.setState({isScheduleCreateOpen: true});
    };

    handleScheduleCreateSaveAndClose = () => {
        this.handleScheduleCreateClose();
        this.loadSchedules();
    };

    handleScheduleCreateClose = () => {
        this.setState({isScheduleCreateOpen: false});
    };

    render() {
        const {cancelWizard, previousStep, t} = this.props;
        const {fields, isScheduleCreateOpen, schedules, _loading} = this.state;

        return <React.Fragment>
            <Row style={{marginBottom: "2rem"}}>
                <Col lg={9} md={12}>
                    <FlexRow justifyContent={"space-between"}>
                        <Headline noMargin>{t("wizard.steps.schedule.createAPlan")}</Headline>
                        <GhostButton style={{margin: 0}} variant={"negative"} onClick={this.props.nextStep}>
                            {t("wizard.steps.schedule.ignoreTimePLan")}
                        </GhostButton>
                    </FlexRow>

                    <Paragraph>
                        {t("wizard.steps.schedule.createATask")}
                    </Paragraph>
                </Col>
            </Row>

            <Row style={{marginBottom: "4rem"}}>
                <Col lg={{size: 9, order: 1}} md={{size: 12, order: 2}}>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-end",
                        marginBottom: "1rem",
                    }}>
                        <Subheadline
                            style={{marginBottom: "0"}}>{t("wizard.steps.schedule.tasksAlreadyCreated")}</Subheadline>
                        <Button onClick={this.handleScheduleCreateOpen} style={{margin: "0"}}>
                            {t("wizard.steps.schedule.createANewTask")}
                        </Button>
                        <ModalSlideIn isOpen={isScheduleCreateOpen}>
                            <ScheduleCreateSlideIn onClose={this.handleScheduleCreateClose}
                                                   onSave={this.handleScheduleCreateSaveAndClose}/>
                        </ModalSlideIn>
                    </div>

                    <RowSmall>
                        {_loading ?
                            <Col><Loader/></Col>
                            :
                            <ScheduleSelect onChange={this.handleChangeSchedule}
                                            selectedId={fields.scheduleId}
                                            schedules={schedules}/>
                        }
                    </RowSmall>
                </Col>
            </Row>

            <Row>
                <Col lg={9} md={12}>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <GhostButton onClick={cancelWizard}>{t("common.action.abort")}</GhostButton>
                        <div style={{gridColumnGap: "2.3rem", display: "grid", gridTemplateColumns: "auto auto"}}>
                            <AbortButton onClick={previousStep}>{t("common.action.back")}</AbortButton>
                            <Button onClick={this.onFormSubmit}>{t("common.action.saveAndContinue")}</Button>
                        </div>
                    </div>
                </Col>
            </Row>
        </React.Fragment>;
    }
}

function mapDispatchToProps(dispatch: any) {
    let actions = bindActionCreators({
        }, dispatch,
    );
    return {...actions, dispatch};
}

function mapStateToProps(state) {
    return {};
}

export const Schedule = compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps),
)(_Schedule);
