import {useSearchParams} from "react-router-dom"
import React, {useEffect, useState} from "react"
import {TeamRestApiClient} from "../service/TeamRestApiClient"
import {useTranslation} from "react-i18next"
import {Button, ButtonSpinner, Headline, Paragraph, Snackbar} from "@greenbone/cloud-component-library"
import {Col} from "reactstrap"

export function ConfirmDelete() {

    const {t} = useTranslation()
    const [token, setToken] = useState(null)
    const [isSending, setSending] = useState(false)
    const [done, setDone] = useState(false)

    const [searchParams] = useSearchParams()
    useEffect(() => {
        setToken(searchParams.get('token'))
    }, [searchParams])


    const handleDeleteConfirm = e => {
        e.preventDefault()

        const apiClient = new TeamRestApiClient()
        setSending(true)
        apiClient.deleteConfirm(token)
            .then(response => {
                if (!response) {
                    setDone(true)
                    setSending(false)
                    return
                }

                throw response
            })
            .catch(e => {
                setSending(false)

                if (e?.status === 422) {
                    Snackbar.Error(t("deleteTeam.alreadyRequested"))
                    return
                }

                Snackbar.Error(t("deleteTeam.tokenInvalid"))
            })
    }

    if (done) {
        return <Col xl={7}>
            <Headline>{t("deleteTeam.confirmed.headline")}</Headline>
            <Paragraph>
                {t("deleteTeam.text")}
            </Paragraph>
        </Col>
    }

    return <Col xl={7}>

        <Headline>{t("deleteTeam.headline")}</Headline>

        <Paragraph>
            {t("deleteTeam.willBeDeleted.text")}
        </Paragraph>

        <Button disabled={isSending} onClick={handleDeleteConfirm}>{t("deleteTeam.confirm")}
            {isSending && <ButtonSpinner/>}
        </Button>

    </Col>
}
