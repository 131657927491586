// @flow

import * as is_UUID from "is-uuid";
import {Parser} from "./Parser";


export class Validator {
    static DATE_REGEX = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/i;
    static TIME_HOUR_MINUTE_REGEX = /^[0-9]{2}:[0-9]{2}$/i;

    static isEMail(email: string) {
        const regex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
        return regex.test(String(email).toLowerCase());
    }

    static isDateString(dateString: string): boolean {
        return Validator.DATE_REGEX.test(dateString);
    }

    static isNotEmptyString(name: ?string): boolean {
        return name !== null && name !== undefined && name.length >= 1;
    }

    static isTimeHourMinuteString(timeString: string): boolean {
        return Validator.TIME_HOUR_MINUTE_REGEX.test(timeString);
    }

    static isUUID(uuid: string): boolean {
        return is_UUID.anyNonNil(uuid);
    }

    static allNestedTrue(fields: any): boolean {
        for (let field in fields) {
            let value = true;
            if (fields[field] === null) {
                return false;
            }
            else if (typeof (fields[field]) === "boolean") {
                value = fields[field];
            }
            else if (typeof (fields[field]) === "object") {
                value = Validator.allNestedTrue(fields[field]);
            }
            else {
                throw new Error(`Invalid value:'${fields[field]}' type:'${typeof (fields[field])}' for '${field}'`);
            }

            if (value !== true) {
                return false;
            }
        }
        return true;
    }

    /**
     * Checks if the domains of two email addresses do not match.
     *
     * This function extracts the domain parts from two provided email addresses
     * and compares them to determine if they are different.
     *
     * @param email The first email address to compare.
     * @param anotherEmail The second email address to compare.
     * @returns {boolean} Returns true if the domains do not match, otherwise false.
     */
    static isEmailDomainMismatch(email: string, anotherEmail: string): boolean {
        const domain = Parser.extractDomainFromEmail(email);
        const anotherDomain = Parser.extractDomainFromEmail(anotherEmail);
        return domain !== anotherDomain;
    }
}

class ValueCompare {
    _value: string;

    constructor(value: any) {
        this._value = value;
    }

    isInList(values: Array<any>): boolean {
        return values.includes(this._value);
    }
}

export function Value(value: any): ValueCompare {
    return new ValueCompare(value);
}
