import {buildUrl} from "../buildUrl"
import {rest} from "msw"


export const userManagementServiceHandler = [
    rest.get(buildUrl("/usermanagement/group/settings"), (req, res, context) => {
        return res(
            context.status(200),
            context.json({adminReportAccessEnabled: true, adminJumpInEnabled: true})
        )
    }),

    rest.put(buildUrl("/usermanagement/group/settings"), (req, res, context) => {
        return res(
            context.status(428),
            context.json({asd: "asd"})
        )
    })

    // rest.get((`http://localhost:3000/usermanagement/public/jumpIn/login/abc`), ((req, res, context) => {
    //     return res(
    //         context.status(200),
    //         context.json({
    //             accessToken: "string",
    //             refreshToken: "string"
    //         })
    //     )
    // }))

]
