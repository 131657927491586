//@flow

import {Drawer, IconButton, withStyles} from "@material-ui/core"
import {Close} from "@material-ui/icons"
import React from "react"


type Props = {
    open: boolean;
    onClose: any;
    classes: any;
    children: any;
}

class SlideIn extends React.Component<Props, any> {

    constructor(props) {
        super(props);

        (this: any).state = {
            open: this.props.open,
        };

    }

    render() {
        const {classes} = this.props;

        return (
            <Drawer anchor="right" open={this.props.open} onClose={this.props.onClose}>
                <div>
                    <IconButton onClick={this.props.onClose}>
                        <Close className={classes.close}/>
                    </IconButton>
                </div>
                <div className={classes.rightPane}>
                    {this.props.children}
                </div>
            </Drawer>
        );
    }
}

const styles = theme => ({
    rightPane: {
        width: "auto",
        margin: "0.94rem",
        minWidth: "46.88rem",
    },
});

export default withStyles(styles)(SlideIn);
