import React, {useEffect, useState} from "react"
import {ReportSeverity} from "@greenbone/cloud-chart-library"
import { FlexRow, Spinner} from "@greenbone/cloud-component-library"
import {ReportOverviewRestApiClient} from "../../service/ReportOverviewRestApiClient"
import {CenteredSpinner} from "../../components/CenteredSpinner";

function f(maxSeverity) {
    const number = parseFloat(maxSeverity)
    if (number % 1 === 0) {
        return number
    }

    return number.toFixed(1)
}

export function ReportSeverityAbstraction({id}) {

    const [data, setData] = useState(null)

    useEffect(() => {
        async function f() {
            const apiClient = new ReportOverviewRestApiClient()
            const response = await apiClient.getRiskOfReport(id)

            setData(response.data);

        }

        f();
    }, [id]);


    if (data) {
        return <FlexRow full justifyContent={"center"} alignItems={"center"}>
            <div style={{maxWidth: "230px"}}>
                <ReportSeverity severity={f(data.maxSeverity)} width={230} height={230}/>
            </div>
        </FlexRow>;
    }

    return <CenteredSpinner height={230}/>
}
