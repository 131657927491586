//@flow

import MaterialCheckbox from "@material-ui/core/Checkbox/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel"
import CheckBoxIcon from "@material-ui/icons/CheckBox"
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank"
import React from "react"
import styled from "styled-components"
import {Text} from "../../Components/Font/Font"
import {Colors} from "../../Theme"

const CheckboxText = styled(Text)``

const StyledUncheckedIcon = styled(CheckBoxOutlineBlankIcon)`
  font-size: 1.875rem;
  color: ${Colors.blue.normal};
`

const StyledUncheckedErrorIcon = styled(CheckBoxOutlineBlankIcon)`
  font-size: 1.875rem;
  color: ${Colors.red.normal};
`;

const StyledCheckedIcon = styled(CheckBoxIcon)`
  font-size: 1.875rem;
   color: ${Colors.blue.normal};
`;

const StyledFormControlLabel = styled(FormControlLabel)``;

export function Checkbox(props: any) {
    const {isValid, label, ...others} = props;

    return (
        <StyledFormControlLabel
            control={
                <MaterialCheckbox
                    {...others}
                    icon={isValid === false ? <StyledUncheckedErrorIcon/> : <StyledUncheckedIcon/>}
                    checkedIcon={<StyledCheckedIcon/>}
                />
            }
            label={(<CheckboxText noMargin color={"black"}>{label}</CheckboxText>)}
        />
    );
}
