import React, {useEffect, useReducer} from "react"
import {FlexRow} from "@greenbone/cloud-component-library"
import {SolutionTab} from "./SolutionTab"
import ShieldIcon from "../assets/check-shield.svg"
import BubbleIcon from "../assets/alert-user.svg"
import CogHandIcon from "../assets/cog-hand-give.svg"
import CogSearchIcon from "../assets/cog-search-1.svg"
import LaptopIcon from "../assets/alert-message-laptop.svg"

//"Mitigation", "Workaround", "VendorFix", "WillNotFix", "NoneAvailable"

function reducer(state, action) {
    if (Array.isArray(action))
        return action

    if (state.includes(action))
        return [...state].filter(e => e !== action)
    else
        return [...state, action]
}

export function SolutionFilter({value, onChange, ...other}) {

    const [state, dispatch] = useReducer(reducer, value)

    useEffect(() => {
        onChange(state)
    }, [state])

    useEffect(() => {
        dispatch(value)
    }, [value])

    return <FlexRow {...other}>
        <SolutionTab onClick={() => dispatch("Mitigation")} active={state.includes("Mitigation")} color={"#7DB5D0"}
                     iconColor={"#0476AC"}
                     icon={<BubbleIcon/>}>
            Temporary Fix
        </SolutionTab>
        <SolutionTab onClick={() => dispatch("Workaround")} active={state.includes("Workaround")} color={"#7DB5D0"}
                     iconColor={"#0476AC"} icon={<ShieldIcon/>}>
            Risk Reduction
        </SolutionTab>
        <SolutionTab onClick={() => dispatch("VendorFix")} active={state.includes("VendorFix")} color={"#7DB5D0"}
                     iconColor={"#0476AC"} icon={<CogHandIcon/>}>
            Official Fix
        </SolutionTab>
        <SolutionTab onClick={() => dispatch("WillNotFix")} active={state.includes("WillNotFix")} color={"#7DB5D0"}
                     iconColor={"#0476AC"} icon={<CogSearchIcon/>}>
            Searching For Fix
        </SolutionTab>
        <SolutionTab onClick={() => dispatch("")} active={state.includes("")} color={"#7DB5D0"}
                     iconColor={"#0476AC"} icon={<LaptopIcon/>}>
            No Fix Available
        </SolutionTab>
    </FlexRow>
}
