//@flow

class RemoteResourceUrlProvider {
    SCAN_CONFIGURATION_ROUTE = "/scanconfigurations";
    ALIVE_TESTS_ROUTE = "/alivetests";
    PORT_LISTS_ROUTE = "/portlists";
    GATEWAY_ROUTE = "/gateway";

    getAllGateways() {
        return `${this.GATEWAY_ROUTE}`;
    }

    getAllScanConfigurations() {
        return `${this.SCAN_CONFIGURATION_ROUTE}`;
    }

    getAllAliveTests() {
        return `${this.ALIVE_TESTS_ROUTE}`;
    }

    getAllPortLists() {
        return `${this.PORT_LISTS_ROUTE}`;
    }
}

export {RemoteResourceUrlProvider};
