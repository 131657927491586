import React, {useReducer} from "react"
import {useTranslation} from "react-i18next"
import {Button, Checkbox, Table, TableCell, TableRow} from "@greenbone/cloud-component-library"
import IconButton from "@material-ui/core/IconButton"
import {Delete, Edit} from "@material-ui/icons"
import SlideIn from "../../../Components/Dialogs/SlideIn"
import {RouteForm} from "./RouteForm"
import {Logger} from "../../../controller/loggingController"

const ACTIONS = {
    NEW: "NEW",
    EDIT: "EDIT",
    CLOSE: "CLOSE",
    DELETE: "DELETE"
}

const reducer = (state, action) => {
    switch (action.type) {
        case ACTIONS.CLOSE:
            return {open: false, index: null}

        case ACTIONS.EDIT:
            return {open: true, index: action.payload}

        case ACTIONS.NEW:
            return {open: true, index: null}

        case ACTIONS.DELETE:
            return {open: false, index: null}

        default: {
            Logger.warn("Reached default case in GatewayRouteList")
            Logger.warn(state)
            Logger.warn(action)
            return {open: false, index: null}
        }

    }
}

export function GatewayRouteList({setState, routes, fieldErrors, isMacNatEnabled, handleCheckboxChange, networkAddress}) {

    const {t} = useTranslation()

    const [state, dispatch] = useReducer(reducer, {open: false, index: null})


    const newRoute = data => {
        setState(prevState => {
            return {
                fields: {
                    ...prevState.fields,
                    routes: [...prevState.fields.routes, data]
                }
            }
        })

        dispatch({type: ACTIONS.CLOSE})
    }

    const updateRoute = (data, index) => {
        setState(prevState => {
            let routes = prevState.fields.routes
            routes[index] = data

            return {
                fields: {
                    ...prevState.fields,
                    routes
                }
            }
        })

        dispatch({type: ACTIONS.CLOSE})
    }

    const onDelete = (index) => event => {
        dispatch({type: ACTIONS.DELETE})

        setState(prevState => {
            let routes = prevState.fields.routes
            if (index > -1) {
                routes.splice(index, 1)
            }

            return {
                fields: {
                    ...prevState.fields,
                    routes
                }
            }
        })
    }

    const onEdit = index => event => {
        event.preventDefault()
        dispatch({type: ACTIONS.EDIT, payload: index})
    }

    const generator = (row, i) => {
        return <TableRow key={i}>
            <TableCell>{row.network}</TableCell>
            <TableCell>{row.gateway}</TableCell>
            <TableCell>{row.metric}</TableCell>
            <TableCell>
                <IconButton onClick={onEdit(i)}>
                    <Edit/>
                </IconButton>
                <IconButton onClick={onDelete(i)}>
                    <Delete/>
                </IconButton>
            </TableCell>
        </TableRow>
    }

    const columns = [
        {
            id: "network",
            numeric: false,
            sortable: true,
            label: t("gatewayRouteList.network")
        },
        {
            id: "gateway",
            numeric: false,
            sortable: true,
            label: t("gatewayRouteList.gateway")
        },
        {
            id: "metric",
            numeric: false,
            sortable: true,
            label: t("gatewayRouteList.metric")
        },
        {
            id: "actions",
            numeric: false,
            sortable: false,
            label: t("gatewayRouteList.actions")
        }
    ]

    return <div>

        <div style={{paddingTop: "2rem"}}>
            <Checkbox checked={isMacNatEnabled}
                      onChange={handleCheckboxChange}
                      label={"Use MAC-NAT"}/>
        </div>


        {fieldErrors?.advanced &&
        <div style={{color: "red"}}>{fieldErrors.advanced}</div>
        }

        <Table generator={generator}
               order={"asc"}
               orderBy={"metric"}
               data={routes}
               columns={columns}
               actions={<Button onClick={() => {
                   dispatch({type: ACTIONS.NEW})
               }}>{t("gatewayRouteList.createNewNetwork")}</Button>}
        />

        <SlideIn open={state.open} onClose={() => dispatch({type: ACTIONS.CLOSE})}>
            {state.open && <RouteForm networkAddress={networkAddress}
                                      set={routes[state.index]}
                                      index={state.index}
                                      cancel={() => dispatch({type: ACTIONS.CLOSE})}
                                      update={updateRoute}
                                      add={newRoute}
            />}

        </SlideIn>
    </div>
}
