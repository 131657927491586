import React from "react"
import * as d3 from "d3"
import {LineHelper} from "./LineHelper"
import {DonutController} from "../Donut/DonutController"
import styled from "styled-components"
import {Dot} from "../Donut/DonutComponents"

const AutoGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
`

export class Line extends React.Component {

    dimensions = {
        width: this.props.width,
        height: this.props.height
    }

    componentDidMount() {
        this.createBaseGraph()
        this.draw(this.props.data)
    }

    shouldComponentUpdate(nextProps, nextState) {
        this.data = nextProps.data
        this.draw(this.data)
        return false
    }


    createBaseGraph = () => {
        const node = this.node
        const margin = 30

        d3.select(node)
            .attr("viewBox", `0 0 ${this.dimensions.width + margin * 2} ${this.dimensions.height + margin * 2}`)
            .append("g")
            .attr("transform", `translate(${0},${5})`)

    }

    draw(data) {
        const node = this.node


        const g = d3.select(node)
            .select("g")


        const x = d3.scaleTime()
            .domain(d3.extent(data, this.props.dateAccessor))
            .range([0, this.dimensions.width])


        const y = d3.scaleLinear()
            .domain([0, LineHelper.FindMax(data, this.props.dataAccessors)])
            .range([this.dimensions.height, 0])

        const existing_x_axis = g.select(".x-axis")
        if (existing_x_axis.empty()) {
            g
                .append("g")
                .attr("class", "x-axis")
                .attr("transform", `translate(0, ${this.dimensions.height})`)
                .call(d3.axisBottom(x))
                .call(g => g.select("path")
                    .remove())
        } else {
            existing_x_axis
                .attr("transform", `translate(0, ${this.dimensions.height})`)
                .call(d3.axisBottom(x))
                .call(g => g.select("path")
                    .remove())
        }


        const existing_y_axis = g.select(".y-axis")
        if (existing_y_axis.empty()) {
            g
                .append("g")
                .attr("class", "y-axis")
                .attr("transform", `translate(${this.dimensions.width},0)`)
                .call(d3.axisLeft(y)
                    .tickSize(this.dimensions.width))
                .call(g => g.selectAll(".tick line")
                    .attr("stroke-opacity", 0.1)
                )
                .call(g => g.select("path")
                    .remove())
        } else {
            existing_y_axis
                .attr("transform", `translate(${this.dimensions.width},0)`)
                .call(d3.axisLeft(y)
                    .tickSize(this.dimensions.width))
                .call(g => g.selectAll(".tick line")
                    .attr("stroke-opacity", 0.1)
                )
                .call(g => g.select("path")
                    .remove())
        }


        g.selectAll(".data-path").remove()

        this.props.dataAccessors.forEach((accessor, index) => {
            g
                .append("path")
                .attr("class", "data-path")
                .datum(data)
                .attr("fill", "none")
                .attr("stroke", DonutController.GetColorFromArray(this.props.colorScheme, index))
                .attr("stroke-width", 2)
                .attr("d", d3.line()
                    .x(d => x(this.props.dateAccessor(d)))
                    .y(d => y(accessor(d))
                    ))
        })
    }


    render() {
        return <>
            <svg style={this.props.style} height={`${this.dimensions.height}px`} width={`${this.dimensions.width}px`}
                 ref={node => this.node = node}>
            </svg>
            {this.props.showLegend && <>
                <AutoGrid>
                    {this.props.nameAccessors.map((accessor, index) => {
                        return <div style={{display: "flex", alignItems: "center"}} key={index}>
                            <Dot
                                color={DonutController.GetColorFromArray(this.props.colorScheme, index)}></Dot>{accessor(this.props.data[index])}
                        </div>
                    })}

                </AutoGrid>
            </>}
        </>
    }
}