import React from "react"

import styled from "styled-components"

const Li = styled.li`
  padding-top: 1rem;
  padding-bottom: 1rem;
`

export function PoweredBy({icon, style}) {
    return <Li style={style}>
        {icon}
    </Li>
}
