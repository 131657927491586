import React, {useContext, useState} from "react"
import {useTranslation} from "react-i18next"
import {
    Button,
    ButtonSpinner,
    FlexRow,
    GhostButton,
    Headline,
    Input,
    Snackbar
} from "@greenbone/cloud-component-library"
import {Col, Row} from "reactstrap"
import {TeamContext} from "./TeamContext"

export function Invite({close}) {
    const {t} = useTranslation()

    const [mail, setMail] = useState("")
    const [sending, setSending] = useState(false)
    const [valid, setValid] = useState(null)
    const {sendInvite} = useContext(TeamContext)

    const handleSubmit = event => {
        event.preventDefault()
        setSending(true)
        sendInvite(mail)
            .then(response => {

                if (response.status === "BAD_REQUEST") {
                    setValid(response.fieldErrors.email.join(", "))
                    return
                }

                setValid(true)
                Snackbar.Success("Invite has been sent")
                close()

            })
            .catch(e => {
                if (e.status === 403) {
                    Snackbar.Error(t("components.error.boundary.noPermission"))
                    return
                }
                Snackbar.Error(e)
            })
            .finally(() => {
                setSending(false)
            })
    }

    return <div style={{width: "30rem"}}>
        <Headline className={"Font__Headline"}>Invite</Headline>
        <form onSubmit={handleSubmit}>
            <Row style={{marginBottom: "2rem"}}>
                <Col>
                    <FlexRow fullWidth>
                        <Input fullWidth isValid={valid} label={t("invite.mail")} name={"mail"} value={mail}
                               onChange={event => setMail(event.target.value)}/>
                    </FlexRow>
                </Col>
            </Row>

            <Row>
                <Col>
                    <FlexRow justifyContent={"space-between"} style={{flexDirection: "row-reverse"}}>
                        <Button id={"SEND_INVITE"} style={{margin: 0}}>{t("invite.invite")} <ButtonSpinner
                            hidden={!sending}/></Button>
                        <GhostButton onClick={event => {
                            event.preventDefault()
                            close()
                        }} id={"CANCEL_INVITE"} style={{margin: 0}}>{t("invite.cancel")}</GhostButton>
                    </FlexRow>
                </Col>
            </Row>
        </form>
    </div>

}
