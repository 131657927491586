//@flow

import {EntityRestApiClient} from "../Base/EntityRestApiClient";
import {CredentialsUrlProvider} from "./CredentialsUrlProvider";


class CredentialsRestApiClient extends EntityRestApiClient {

    constructor() {
        super(new CredentialsUrlProvider());
    }

}

export {CredentialsRestApiClient};
