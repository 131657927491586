import React, {useContext, useState} from "react"
import {FlexRow, IconButton, SlideIn} from "@greenbone/cloud-component-library"
import {ReportContext} from "../ReportContext"
import {ReportPaging} from "../components/ReportPaging"
import {ReportSorting} from "../components/ReportSorting"
import {LastCell, Name, NameCell, Severity, Table, TableCell} from "../components/TableComponents"
import {ViewSwitcher} from "../components/ViewSwitcher"
import ReportIcon from "../assets/report.svg"
import {VulnerabilityDetails} from "../components/VulnerabilityDetails"
import {GetSolutionTabByType, ScaleSolutionTab} from "../components/SolutionTab"
import {ReportSelectDownloader} from "../components/ReportSelectDownloader"
import {OperatingSystemIcon} from "../components/OperatingSystemTab"
import styled from "styled-components"
import {HideNullable} from "../components/NullableText"
import {useTranslation} from "react-i18next"
import ErrorBoundary from "../../../Components/ErrorBoundary";

const Hostname = styled.div`
    font-size: 0.75rem;
    color: #545454;
`

const GridRow = ({vulnerability}) => {
    const [open, setOpen] = useState()

    return <>
        <NameCell severity={vulnerability.severity}>
            <Name>{vulnerability.name}</Name>
        </NameCell>
        <TableCell>
            <Severity severity={vulnerability.severity}>
                {vulnerability.severity}
            </Severity>
        </TableCell>
        <TableCell>
            <OperatingSystemIcon os={vulnerability.operatingSystem}/>
        </TableCell>
        <TableCell>
            {vulnerability.host}
            <HideNullable value={vulnerability.hostname}>
                <Hostname>{vulnerability.hostname}</Hostname>
            </HideNullable>
        </TableCell>
        <TableCell>{vulnerability.port}</TableCell>
        <TableCell>
            <ScaleSolutionTab>
                {GetSolutionTabByType(vulnerability.solutionType)}
            </ScaleSolutionTab>
        </TableCell>
        <TableCell>{vulnerability.qod}</TableCell>
        <LastCell>
            <IconButton onClick={() => setOpen(true)}>
                <ReportIcon/>
            </IconButton>
            <SlideIn open={open} onClose={() => setOpen(false)}>
                <VulnerabilityDetails vulnerability={vulnerability}/>
            </SlideIn>
        </LastCell>

    </>
}


export function ReportTable(props) {
    const {vulnerabilitiesTable} = useContext(ReportContext)
    const {sort} = useContext(ReportContext)
    const {t} = useTranslation()

    return <ErrorBoundary>
        <FlexRow alignItems={"center"} justifyContent={"space-between"}>
            <ViewSwitcher/>
            <FlexRow alignItems={"center"}>
                <ReportSelectDownloader/>
            </FlexRow>
        </FlexRow>
        <hr/>


        <Table templateColumns={"3fr .5fr .5fr 1fr 1fr 1fr .5fr .5fr"}>
            <ReportSorting/>
            {vulnerabilitiesTable?.map((vulnerability, index) =>
                <GridRow vulnerability={vulnerability} key={index}/>
            )}
        </Table>
        <FlexRow justifyContent={"flex-end"}>
            <ReportPaging/>
        </FlexRow>
    </ErrorBoundary>

}
