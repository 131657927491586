//@flow

import {SIODateTime} from "../../controller/SIODateTime";
import {Converter} from "../../Helper/Converter";


export class SmallReportDataController {
    static filterSmallReportsByDate(smallReports: Array<GraphSmallReport>, date: SIODateTime) {
        //$FlowFixMe - Missing type annotation for `T`
        return smallReports.filter((smallReport: GSPSmallReport) => {
            if (smallReport.scanEnd) {
                return smallReport.scanEnd > date;
            }
            return false;
        });
    }

    static groupByTask = (list: Array<GraphSmallReport>) => {
        let newList = [];
        list.forEach((smallReport: GraphSmallReport) => {
            const dataSet = newList.find((element) => {
                return element.id === smallReport.taskId;
            });

            if (!dataSet) {
                const sortedReports = list
                    .filter(report => report.taskId === smallReport.taskId)
                    .sort((a, b) => Converter.cmpDate(a.scanEnd, b.scanEnd, false, false));
                newList.push({id: smallReport.taskId, name: sortedReports[0].taskName, data: [smallReport]});
            } else {
                dataSet.data.push(smallReport);
            }
        });

        return newList;
    };

}
