import {UrlProvider} from "./UrlProvider"


export class GroupSettingsUrlProvider extends UrlProvider {
    static getBasePath = () => `/settings/group`
    static BASE_URL = `${UrlProvider.ROOT_PATH}${GroupSettingsUrlProvider.getBasePath()}`

    static getBase() {
        return this.BASE_URL
    }

    static security() {
        return `${GroupSettingsUrlProvider.getBase()}/security`
    }

    static terminate() {
        return `${GroupSettingsUrlProvider.subscription()}/terminate`
    }

    static billing() {
        return `${GroupSettingsUrlProvider.getBase()}/billing`
    }

    static subscription() {
        return `${GroupSettingsUrlProvider.getBase()}/subscription`
    }

    static invoice() {
        return `${GroupSettingsUrlProvider.getBase()}/invoice`
    }

    static team() {
        return `${GroupSettingsUrlProvider.getBase()}/team`
    }

}
