import React from "react"
import {Col} from "reactstrap"
import {Input, SearchableSelect} from "@greenbone/cloud-component-library"
import {FormRow} from "../../StyledComponents/Layout/FormRow"
import {useTranslation} from "react-i18next"
import styled from "styled-components"


export type AddressFormFields = {
    streetAddress: string;
    houseNumber: string;
    city: string;
    companyName: string;
    postalCode: string;
    firstName: string;
    lastName: string;
    title: string;
}

export type RegistrationFormFields = {
    isDifferentBillingAddressActive: boolean;
    email: string;
    password: string;
    passwordRepeat: string;
    termsAccepted: boolean;
    termsOfUseAccepted: boolean;
    cardOwner: string;
    billingAddress: AddressFormFields;
}


export type AddressFormFieldsValid = {
    streetAddress: ?boolean;
    houseNumber: ?boolean;
    city: ?boolean;
    companyName: ?boolean;
    postalCode: ?boolean;
    firstName: ?boolean;
    lastName: ?boolean;
    title: ?boolean;
}



type Props = {
    handleChange: any;
    fields: GSPBillingAddress;
    validFields: AddressFormFieldsValid;
    disabled?: boolean;
}

const A = styled.div`
  .MuiFormLabel-root {
    transform: translate(0, 1.5px) scale(0.75);
    transform-origin: top left;
  }
`

export function Address({validFields, handleChange, fields, countrys, disabled = false}: Props) {
    const {t} = useTranslation()
    return (
        <React.Fragment>
            <FormRow>
                <Col>
                    <Input disabled={disabled} isValid={validFields.companyName} value={fields.companyName}
                           onChange={handleChange} name={"companyName"}
                           label={t("extern.registration.address.companyName")}/>
                </Col>
            </FormRow>
            <FormRow>
                <Col sm={2}>
                    <Input disabled={disabled} isValid={validFields.title}
                           label={t("extern.registration.address.title")} name={"title"}
                           value={fields.title}
                           onChange={handleChange}>
                    </Input>
                </Col>
            </FormRow>
            <FormRow>
                <Col>
                    <Input disabled={disabled} isValid={validFields.firstName} value={fields.firstName}
                           onChange={handleChange} name={"firstName"}
                           label={t("extern.registration.address.firstName")}/>
                </Col>
                <Col>
                    <Input disabled={disabled} isValid={validFields.lastName} value={fields.lastName}
                           onChange={handleChange} name={"lastName"} label={t("extern.registration.address.lastName")}/>
                </Col>
            </FormRow>
            <FormRow>
                <Col sm={6}>
                    <Input disabled={disabled} isValid={validFields.streetAddress} value={fields.streetAddress}
                           onChange={handleChange} name={"streetAddress"}
                           label={t("extern.registration.address.streetAddress")}/>
                </Col>
                <Col sm={2}>
                    <Input disabled={disabled} isValid={validFields.houseNumber} value={fields.houseNumber}
                           onChange={handleChange} name={"houseNumber"}
                           label={t("extern.registration.address.houseNumber")}/>
                </Col>

            </FormRow>
            <FormRow>
                <Col sm={2}>
                    <Input disabled={disabled} isValid={validFields.postalCode} value={fields.postalCode}
                           onChange={handleChange} name={"postalCode"}
                           label={t("extern.registration.address.postalCode")}/>
                </Col>
                <Col sm={4}>
                    <Input disabled={disabled} isValid={validFields.city} value={fields.city} onChange={handleChange}
                           name={"city"} label={t("extern.registration.address.city")}/>
                </Col>
            </FormRow>
            <FormRow>
                <Col sm={6}>
                    <A>
                        <SearchableSelect label={"Country"} options={{
                            data: countrys,
                            labelFieldName: "name",
                            valueFieldName: "id"
                        }} onChange={handleChange} value={fields.countryId} isValid={validFields.countryId}
                                          name={"countryId"}/>
                    </A>

                </Col>
            </FormRow>
        </React.Fragment>
    );
}
