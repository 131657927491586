//@flow

import React from "react"
import {withTranslation} from "react-i18next"
import {compose} from "redux"
import {i18n} from "../../../i18n"
import {Snackbar} from "@greenbone/cloud-component-library"
import {LANGUAGE_FALLBACK} from "../../constants"
import {Logger} from "../../controller/loggingController"
import {getSupportedLanguage} from "../../Helper/language"
import {UserRestApiClient} from "../../User/service/UserRestApiClient"

//$FlowFixMe - Flow does not know what a context is
export const LanguageContext = React.createContext({})

class _LanguageProvider extends React.Component<{ children: any, t: any }, any> {
    userRestApiClient: UserRestApiClient
    state = {
        language: LANGUAGE_FALLBACK,
        _sending: false,
        _loading: false
    }

    constructor(props: any) {
        super(props)
        this.userRestApiClient = new UserRestApiClient()
    }

    componentDidMount() {
        this.loadLanguage()
    }

    loadLanguage = () => {
        this.setState({_loading: true})
        this.userRestApiClient.getLanguage()
            .then((response) => {
                if (response) {
                    let language = getSupportedLanguage(response.language)
                    i18n.changeLanguage(language)
                    this.setState({language})
                }
            })
            .catch(exception => {
                Logger.exception(exception)
            })
            .finally(() => {
                this.setState({_loading: false})
            })
    }

    changeLanguage = (language: string) => {
        this.setState({_sending: true})
        this.setState(prevState => {
            this.saveLanguage(language)
            i18n.changeLanguage(language)
            return {language}
        })
        this.setState({_sending: false})
    }

    saveLanguage(language: string) {
        const {t} = this.props

        this.userRestApiClient.setLanguage(language)
            .then(() => {
                Snackbar.Success(t("language.change.success"))

            })
            .catch(exception => {
                Snackbar.Error(t("language.change.failed"))
                Logger.exception(exception)
            })
    }

    render() {
        return (
            <LanguageContext.Provider value={{
                changeLanguage: this.changeLanguage,
                language: this.state.language,
                processing: this.state._loading || this.state._sending
            }}>
                {this.props.children}
            </LanguageContext.Provider>
        )
    }
}

//$FlowFixMe - Flow does not allow "Component" with an uppercase 'C'
export const withLanguageContext = (Component) => {
    return (props: any) => (
        <LanguageContext.Consumer>
            {(contextProps) => {
                return <Component {...contextProps} {...props} />
            }}
        </LanguageContext.Consumer>
    )
}

export const LanguageProvider = compose(
    withTranslation()
)(_LanguageProvider)
