//@flow

import {UrlProvider} from "./UrlProvider";


export class AuthUrlProvider extends UrlProvider {
    static BASE_URL = `/auth`;

    static getBase() {
        return AuthUrlProvider.BASE_URL;
    }

    static login(target?: string) {
        return AuthUrlProvider.addSearchParam(`${this.BASE_URL}/login`, "target", target);
    }

    static logout() {
        return `${AuthUrlProvider.getBase()}/logout`;
    }

    static addSearchParam(url: string, key: string, value?: string) {
        return url + (value !== undefined ? `?${key}=${encodeURIComponent(value)}` : "");
    }
}
