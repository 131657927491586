import {IP4} from "./Helper/IP4"

export const isStringBlankOrEmpty = value => {
    if (!value) {
        return true
    }

    if (typeof value !== "string") {
        value = String(value)
    }

    return value === ""
}


export const isStringBlankOrWhitespace = value => {

    if (isStringBlankOrEmpty(value)) {
        return true
    }

    if (typeof value !== "string") {
        value = String(value)
    }

    if (!value) {
        return true
    }

    return value.replace(/\s/g, "") === ""
}

export const isNumberPrimitive = value => {
    if (value === null || value === undefined || value === "") {
        return false
    }

    if (isNaN(value)) {
        return false
    }
    return true
}

export const isSubnetPrimitive = value => {
    if (IP4.isSubnet(value)) {
        return true
    }
    return false
}

export const isSubnetInsideSubnetPrimitive = net => value => {
    if (IP4.isSubnetInSubnet(net, value)) {
        return true
    }
    return false
}

export const isAddressInsideSubnetPrimitive = net => value => {
    if (IP4.isAddressInSubnet(value, net)) {
        return true
    }
    return false
}


export const isMinPrimitive = min => value => {
    if (!isNumberPrimitive(value)) {
        return false
    }

    const val = Number(value)
    if (val >= min) {
        return true
    }
    return false
}

export const isMaxPrimitive = max => value => {
    if (!isNumberPrimitive(value)) {
        return false
    }

    const val = Number(value)
    if (val <= max) {
        return true
    }
    return false
}

export const regexMatchPrimitive = regex => value => {
    if (!value) {
        return false
    }

    if (value.match(regex)) {
        return true
    }

    return false
}

export const regexNoMatchPrimitive = regex => value => {
    if (value.match(regex)) {
        return false
    }

    return true
}

export const isEqualPrimitive = comparer => value => {
    if (comparer === value) {
        return true
    }
    return false
}

export const unEqualPrimitive = comparer => value => {
    if (comparer === value) {
        return false
    }
    return true
}
