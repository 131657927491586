/**
 * @return {boolean}
 */


export function ObjectContainsFieldFromObject(objectToBeSearched, objectWithFieldsThatWillBeSearchedFor) {
    if (!objectToBeSearched || !objectWithFieldsThatWillBeSearchedFor) {
        return false;
    }


    for (const field in objectWithFieldsThatWillBeSearchedFor) {
        if (ObjectContainsField(objectToBeSearched, field)) {
            return true;
        }
    }

    return false;
}

/**
 * @return {boolean}
 */
export function ObjectContainsField(objectToBeSearched, name) {
    for (const field in objectToBeSearched) {
        if (field === name) {
            return true;
        }
    }

    return false;
}
