//@flow

import React from "react";
import {GSPApplicationLayout} from "../../../Layout/GSPApplicationLayout";
import {HelpPage} from "../HelpPage";


export function HelpWithLayout(props: {}) {
    return <GSPApplicationLayout><HelpPage/></GSPApplicationLayout>;
}
