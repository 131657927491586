export class UrlProvider {
    static ROOT_PATH = "/";
    static BASE_URL;

    static getBase() {
        return this.BASE_URL;
    }

    /**
     * Validates that a given url starts with base url
     * @param url
     * @returns {boolean}
     */
    static pathHasSameBase(url) {
        return url.startsWith(this.getBase());
    }
}
