import React, {useEffect, useState} from "react"
import {ReportOverviewRestApiClient} from "../../service/ReportOverviewRestApiClient"
import {FlexColumn, Tiny} from "@greenbone/cloud-component-library"
import {CenteredSpinner} from "../../components/CenteredSpinner";

export function TotalVulnerabilities({id}) {

    const [data, setData] = useState(null)

    useEffect(() => {
        async function f() {
            const apiClient = new ReportOverviewRestApiClient()
            const response = await apiClient.getTotalVulnerabilities(id)

            setData(response.data);

        }

        f();
    }, [id]);

    if (data) {
        return <div style={{
            fontSize: "3.125rem",
            height: "13.25rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}>
            <FlexColumn justifyContent={"center"} style={{height: "13.25rem"}}>

                {data.count}
                <Tiny style={{height: "1rem"}}></Tiny>
            </FlexColumn>
        </div>;
    }

    return <CenteredSpinner height={"13.25rem"}/>

}
