//@flow

import React from "react";
import ErrorBoundary from "../../../Components/ErrorBoundary";
import {Logger} from "../../../controller/loggingController";
import {Caption, Headline, Paragraph} from "../../../StyledComponents/Font/Font";


const Failure = (props: any) => {
    throw new Error("Development error tester, failing on purpose.");
};

export class ErrorPerpetrator extends React.Component<any, any> {

    sendException = (event: any) => {
        event.preventDefault();
        Logger.exception(new Error("Testing error from dev page"));
        alert("Exception logged");
    };

    sendError = (event: any) => {
        event.preventDefault();
        Logger.error("Testing error message from dev page");
        alert("Error logged");
    };

    sendWarning = (event: any) => {
        event.preventDefault();
        Logger.warn("Testing warning message from dev page");
        alert("Warning logged");
    };

    render() {
        return (
            <div>
                <Headline>ErrorPerpetrator</Headline>
                <Paragraph>
                    This page is designed to trow an error.
                    If you don't see an error something wen't terribly wrong.
                    Also the error you see is supposed to show up in sentry.
                </Paragraph>

                <div>
                    <Caption>Send one to Sentry</Caption>
                    <ul>
                        <li>
                            <button onClick={this.sendException}>exception</button>
                        </li>
                        <li>
                            <button onClick={this.sendError}>error</button>
                        </li>
                        <li>
                            <button onClick={this.sendWarning}>warning</button>
                        </li>
                    </ul>
                </div>

                <ErrorBoundary>
                    <Failure/>
                </ErrorBoundary>
            </div>
        );
    }
}
