// @flow

import {compose, createStore} from "redux";
import {persistCombineReducers, persistStore} from "redux-persist";
import storage from "redux-persist/lib/storage";
import AlertDialogReducer from "./Components/Dialogs/AlertDialog/reducer";
import ConfirmDialogReducer from "./Components/Dialogs/ConfirmDialog/reducer";
import SnackbarReducer from "./Components/Snackbar/reducer";
import WelcomeDialogReducer from "./Components/Welcome/reducer";
import appReducer from "./reducer";


const config = {
    key: "root",
    storage,
};

const reducer = persistCombineReducers(config, {
    app: appReducer,
    dialog: ConfirmDialogReducer,
    alertDialog: AlertDialogReducer,
    snackbar: SnackbarReducer,
    welcomeDialog: WelcomeDialogReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

//$FlowFixMe
export const appStore = createStore(
    reducer,
    composeEnhancers(),
);

export const persistor = persistStore(appStore);
