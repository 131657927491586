//@flow

import {Divider, Grid, withStyles, withTheme} from "@material-ui/core";
import React from "react";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {Term} from "../../../Components/Basic/Term";
import {TermDescription} from "../../../Components/Basic/TermDescription";
import {TermList} from "../../../Components/Basic/TermList";
import {DateTimeFormatter} from "../../../controller/DateTimeFormatter";
import {Subheadline} from "../../../StyledComponents/Font/Font";


const styles = theme => ({});

type Props = {
    schedule: GSPSchedule;
    classes: typeof styles;
    theme: any;
    t: any;
}

class _ScheduleInformation extends React.Component<Props> {

    render() {
        const {schedule, theme, t} = this.props;
        return (
            <Grid container spacing={0} style={{minWidth: "31.25rem"}}>
                <Grid item xs={theme.grid.width.full}>
                    <Subheadline>{t("scheduleInformation.title")}</Subheadline>
                    <Divider/>
                    <TermList>
                        <Term>
                            {t("scheduleInformation.name")}
                        </Term>
                        <TermDescription>
                            {schedule.name}
                        </TermDescription>
                        <Term>
                            {t("scheduleInformation.comment")}
                        </Term>
                        <TermDescription>
                            {schedule.comment || t("scheduleInformation.noData")}
                        </TermDescription>
                        <Term>
                            {t("scheduleInformation.startDate")}
                        </Term>
                        <TermDescription>
                            {DateTimeFormatter.toDateTimeString(schedule.startDate)}
                        </TermDescription>
                        <Term>
                            {t("scheduleInformation.endDate")}
                        </Term>
                        <TermDescription>
                            {DateTimeFormatter.toDateTimeString(schedule.endDate)}
                        </TermDescription>
                        <Term>
                            {t("scheduleInformation.intervalGap")}
                        </Term>
                        <TermDescription>
                            {schedule.intervalGap.toString() || t("scheduleInformation.noData")}
                        </TermDescription>
                        <Term>
                            {t("scheduleInformation.intervalType")}
                        </Term>
                        <TermDescription>
                            {schedule.intervalType.toString() || t("scheduleInformation.noData")}
                        </TermDescription>
                    </TermList>
                </Grid>
            </Grid>
        );
    }
}

export const ScheduleInformation = compose(
    withStyles(styles),
    withTheme,
    withTranslation()
)(_ScheduleInformation);
