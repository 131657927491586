//@flow

import {withStyles, withTheme} from "@material-ui/core";
import React from "react";
import {compose} from "redux";
import DeleteButton from "./DeleteButton";
import EditButton from "./EditButton";


const styles = theme => ({
    root: {
        height: "100%",
        marginTop: theme.spacing(3)
    },
    center: {
        textAlign: "center",
    },
});

type Props = {
    classes: typeof styles,
    theme: any,
    editRoute: string,
    onUpdateEntry: () => void;
    deleteEntity: () => {},
    confirmMessage: string,
    additionalActionGenerator: ?() => void;
    children?: any;
}

class ActionElements extends React.Component<Props, {}> {

    render() {
        return (
            <div>
                {this.props.additionalActionGenerator ? this.props.additionalActionGenerator() : null}
                <EditButton
                    editRoute={this.props.editRoute}
                    onUpdateEntry={this.props.onUpdateEntry}
                />
                <DeleteButton
                    deleteEntity={this.props.deleteEntity}
                    confirmMessage={this.props.confirmMessage}
                />
                {this.props.children}
            </div>
        );
    }
}

export default compose(
    withStyles(styles),
    withTheme
)(ActionElements);
