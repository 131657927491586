import React, {useContext} from "react"
import styled, {css} from "styled-components"
import {ReportContext, Views} from "../ReportContext"
import {ReportNavigationUrlProvider} from "../ReportNavigationUrlProvider"
import {useLocation} from "react-router"
import {useTranslation} from "react-i18next"

const Element = styled.div`
    height: 2.25rem;
    box-sizing: border-box;
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #c2c2c2;
    border: 1px solid #c2c2c2;
    width: 8rem;

    &:hover {
      cursor: pointer;
    }

    &:active {
      color: ${props => props.theme.button.normal.text};
      border: 1px solid  ${props => props.theme.button.normal.background};
      background: ${props => props.theme.button.normal.background};
      z-index: 1;
      font-weight: bold;
    }

    ${props => props.active && css`
      color: ${props => props.theme.button.normal.text};
      border: 1px solid  ${props => props.theme.button.normal.background};
      background: ${props => props.theme.button.normal.background};
      z-index: 1;
      font-weight: bold;
    `}

    &:last-child {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }

    &:first-child {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }

    &:nth-child(2){
      margin-left: -1px;
      margin-right: -1px;
    }
`

const Layout = styled.div`
  display: flex;
  box-sizing: border-box;

`

export function ViewSwitcher(props) {

    const {navigate, changeView} = useContext(ReportContext)
    const {pathname} = useLocation()
    const {t} = useTranslation()

    const navigateView = (view, path) => {
        changeView(view)

        navigate(path)
    }

    return <Layout>

        <Element active={pathname === (ReportNavigationUrlProvider.Table())}
                 onClick={() => navigateView(Views.TABLE_OVERVIEW, ReportNavigationUrlProvider.Table())}>{t("report.viewSwitcher.overview")}</Element>
        <Element active={pathname === (ReportNavigationUrlProvider.TableGroupHost())}
                 onClick={() => navigateView(Views.TABLE_HOSTS, ReportNavigationUrlProvider.TableGroupHost())}>{t("report.host")}</Element>
        <Element active={pathname === (ReportNavigationUrlProvider.TableGroupName())}
                 onClick={() => navigateView(Views.TABLE_VULNERABILITIES, ReportNavigationUrlProvider.TableGroupName())}>{t("report.vulnerability")}</Element>
    </Layout>
}
