import React, {useEffect, useState} from "react"
import styled, {useTheme} from "styled-components"
import {v4 as uuidv4} from "uuid"
import {FlexRow} from "@greenbone/cloud-component-library"
import {staticGradientFactory, updateGradientFactory, updateTooltipFactory} from "./SliderHelper"

const Around = styled.div`
  height: 4rem;

  input[type=range]::-moz-focus-outer {
    border: 0;
}

input[type=range]:focus {
    outline: none;
}

input[type=range]:focus::-webkit-slider-thumb,
input[type=range]:focus::-moz-range-thumb,
input[type=range]:focus::-ms-thumb
{
      outline: none;
}

input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  pointer-events: none;
  display: block;
  height: 2rem;
  margin: 0;
}

input[type="range"] + input[type="range"] {
  margin-top: -2rem;
}

input[type="range"]::-moz-range-thumb{
  border-radius: 50%;
  pointer-events: all;
  height: 0.875rem;
  width: 0.875rem;
  background: #FBFBFB;
  border: 1px solid #BEBEBE;
  -webkit-appearance: none;
}

input[type=range]::-webkit-slider-thumb {
  border-radius: 50%;
  pointer-events: all;
  height: 0.875rem;
  width: 0.875rem;
  background: #FBFBFB;
  border: 1px solid #BEBEBE;
  margin-top: -0.375rem;
  z-index: 9999999;
  -webkit-appearance: none;
}

#range-two::-webkit-slider-thumb {
  border-radius: 50%;
  pointer-events: all;
  height: 0.875rem;
  width: 0.875rem;
  background: #FBFBFB;
  border: 1px solid #BEBEBE;
  margin-top: -0.430rem;
  z-index: 9999999;
  -webkit-appearance: none;
}

input[type="range"]:first-child::-moz-range-track {
  height: 0.1875rem;
  background: #ddd;
  border: none;
  border-radius: 50%;
  display: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  height: 0.1875rem;
  border: none;
}

#range-two::-webkit-slider-runnable-track {
background: transparent;
height: 0;
}


input[type="range"]::-ms-thumb {
  pointer-events: all;
}
`


const Tooltip = styled.div`
  position: absolute;
  margin-top: -40px;
  min-width: 2rem;

  color: ${props => props.theme.base};
  background: ${props => props.theme.base};
  padding: 5px;
  border-radius: 3px;

  justify-content: center;
  display: none;

  &::after{
    content: '';
    position: absolute;
    left: calc(50% - 10px);
    top: 100%;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid  currentColor;
    clear: both;
}

`

const Scale = styled.div`
  margin-top: -0.5rem;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  padding-left: 0.4375rem;
  padding-right: 0.4375rem;

  div {
    height: 0.5rem;
    border-left: 1px solid black;
  }

  div:last-child {
    border-right: 1px solid black;
  }
`

const ScaleNumbers = styled(Scale)`
  grid-template-columns: repeat(10, 1fr);
  margin-top: 0.3125rem;
  div {
    border: none;
    height: 1rem;
    margin-left: -0.25rem;
  }

  div:last-child {
    border: none;

    span {
      margin-right: -0.5rem;
    }
   }
`


export function Slider({
                           min,
                           max,
                           onChange,
                           scale,
                           step,
                           showStartStop,
                           customGradient,
                           showScale,
                           tooltipColor,
                           tooltipValue
                       }) {
    const {base} = useTheme()

    const [lower, setLower] = useState(min)
    const [higher, setHigher] = useState(max)
    const [styleRef, setStyleRef] = useState(null)
    const [tooltipRef, setTooltipRef] = useState(null)
    const [outerRef, setOuterRef] = useState(null)
    const [classNameModifier] = useState(uuidv4())

    const updateTooltip = updateTooltipFactory(scale[0], scale[1], tooltipRef, outerRef, tooltipColor, tooltipValue, "#000000")
    const updateGradient = customGradient ?
        () => {
        }
        : updateGradientFactory(scale[0], scale[1], styleRef, setStyleRef, classNameModifier, base)

    if (customGradient)
        (staticGradientFactory(scale[0], scale[1], styleRef, setStyleRef, classNameModifier, base))(customGradient)

    useEffect(() => {
        setLower(min)
        setHigher(max)


        updateGradient(min, max)

    }, [min, max])

    const handleChange = (event) => {

        const value = parseInt(event.target.value)
        const target = event.target


        if (target.id === "range-one") {

            if (value >= higher) {
                setLower(higher - 1)
                updateGradient(higher - 1, higher)
                updateTooltip(higher - 1)
            } else {
                setLower(value)
                updateGradient(value, higher)
                updateTooltip(value)
            }
        }


        if (target.id === "range-two") {

            if (value <= lower) {
                setHigher(lower + 1)
                updateGradient(lower, value)
                updateTooltip(lower + 1)
            } else {
                setHigher(value)
                updateGradient(lower, value)
                updateTooltip(value)
            }
        }
    }

    const onMouseDown = event => {
        const target = event.target

        const value = parseInt(event.target.value)

        if (target.id === "range-one")
            updateTooltip(value)
        else
            updateTooltip(value)

        tooltipRef.style.display = "flex"
    }

    const onMouseUp = event => {
        tooltipRef.style.display = "none"
        onChange([lower, higher])
    }

    return <>
        <Around ref={ref => setOuterRef(ref)}>
            <Tooltip ref={ref => setTooltipRef(ref)}></Tooltip>
            <input onMouseDown={onMouseDown} onMouseUp={onMouseUp} className={`range-${classNameModifier}`}
                   id={"range-one"} value={lower} onChange={handleChange}
                   type={"range"} min={scale[0]} max={scale[1]} step={step}/>

            <input onMouseDown={onMouseDown} onMouseUp={onMouseUp} id={"range-two"} value={higher}
                   onChange={handleChange}
                   type={"range"} min={scale[0]} max={scale[1]} step={step}/>
            <FlexRow style={{display: showStartStop ? "flex" : "none"}} fullWidth justifyContent={"space-between"}>
                <span>1%</span>
                <span>100%</span>
            </FlexRow>
            {showScale && <>
                <Scale>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </Scale>
                <ScaleNumbers>
                    <div>0</div>
                    <div>1</div>
                    <div>2</div>
                    <div>3</div>
                    <div>4</div>
                    <div>5</div>
                    <div>6</div>
                    <div>7</div>
                    <div>8</div>
                    <FlexRow justifyContent={"space-between"}>
                        <span>9</span>
                        <span>10</span>
                    </FlexRow>
                </ScaleNumbers>
            </>}
        </Around>
    </>
}
