//@flow
import React from "react";


export class JWTDevelop extends React.Component<any, any> {

    state = {
        stringToken: null,
        baseToken: null
    };

    componentDidMount(): void {
        // keycloak.init({...KeycloakBaseConfig})
        //     .then(async () => {
        //         await keycloak.updateToken(30);
        //         console.log(keycloak.token);
        //         console.log(jwt.decode(keycloak.token));
        //         this.setState({
        //             stringToken: JSON.stringify(jwt.decode(keycloak.token), null, "    "),
        //             baseToken: keycloak.token
        //         });
        //     })
        //     .catch((error) => {
        //         keycloak.login();
        //     });
    }


    render() {
        return (
            <div>
                <p>If the user is logged in the jwt info will be printed below</p>
                <hr/>
                {this.state.baseToken}
                <hr/>
                <pre>
                    {this.state.stringToken}
                </pre>
                <hr/>

            </div>
        );

    }
}
