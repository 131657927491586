import React from "react";


export class TopTenHelper {
    static ConvertDataSet(data) {
        const newList = [];

        data.forEach(item => {
            newList.push({
                ...item, sum: item.low + item.medium + item.high
            });

        });

        return newList;
    }
}
