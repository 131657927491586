(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("chartist"));
	else if(typeof define === 'function' && define.amd)
		define("react-chartist", ["react", "chartist"], factory);
	else if(typeof exports === 'object')
		exports["react-chartist"] = factory(require("react"), require("chartist"));
	else
		root["react-chartist"] = factory(root["react"], root["chartist"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__155__, __WEBPACK_EXTERNAL_MODULE__140__) => {
return 