//@flow

import {withStyles} from "@material-ui/core";
import React from "react";


const styles = theme => ({});

class _TermList extends React.Component<any, any> {

    render() {
        return (
            <dl>
                {this.props.children}
            </dl>
        );
    }
}

export const TermList = withStyles(styles)(_TermList);
