//@flow

import {isEqual} from "lodash"
import * as React from "react"
import {Trans, withTranslation} from "react-i18next"
import {compose} from "redux"
import styled from "styled-components"
import CheckWhite from "../../../assets/icons/check-white.svg?url"
import WhiteGSPLogo from "../../../assets/logos/ShieldWhite.svg?url"
import {CustomizedSnackbar} from "../../Components/Snackbar/components/Snackbars"
import {Branding, Content, List, ListItem, Navbar, Sidebar, Wrapper} from "../../Layout/components/Navigation"
import {CancelDialog} from "./components/CancelDialog"
import {WelcomeScreen} from "./components/WelcomeScreen"
import {Credential} from "./steps/Credential"
import {HostValidation} from "./steps/HostValidation"
import type {ScheduleFields} from "./steps/Schedule"
import {Schedule} from "./steps/Schedule"
import type {TargetFields} from "./steps/Target"
import {Target} from "./steps/Target"
import type {TaskFields} from "./steps/Task"
import {Task} from "./steps/Task"

type Props = {
    t: any;
}

type State = {
    currentPageNumber: number;
    stepStore: {
        Task: TaskFields;
        Target: TargetFields;
        Credential: {
            selectedWindowsCredentialId: ?string;
            selectedSSHCredentialId: ?string;
            selectedVMWareCredentialId: ?string;
        };
        Schedule: ScheduleFields;
    };
    userRequestedCancel: boolean;
}

const WhiteCheckmark = styled.img`
  height: 12px;
`;

const StepStoreDefaultValues = {
    "Task": {
        name: "",
        comment: "",
        scanConfigurationId: 3,
    },
    "Target": {
        targetId: null,
        isInternal: false
    },
    "Schedule": {
        scheduleId: null,
    },
    "Credential": {
        selectedWindowsCredentialId: null,
        selectedSSHCredentialId: null,
        selectedSSHCredentialPort: 22,
        selectedVMWareCredentialId: null,
    },
    "HostValidation": {},
};

class _Wizard extends React.Component<Props, State> {
    steps: Array<{ name: string, component: any, menuName: React.Node }> = [
        {name: "Task", component: Task, menuName: <Trans i18nKey={"wizard.menu.task"}/>},
        {name: "Target", component: Target, menuName: <Trans i18nKey={"wizard.menu.target"}/>},
        {name: "Credential", component: Credential, menuName: <Trans i18nKey={"wizard.menuu.credentials"}/>},
        {name: "Schedule", component: Schedule, menuName: <Trans i18nKey={"wizard.menu.schedule"}/>},
        {name: "HostValidation", component: HostValidation, menuName: <Trans i18nKey={"wizard.menu.hostValidation"}/>},
    ];

    state = {
        currentPageNumber: 1,
        stepStore: {
            "Task": {
                ...StepStoreDefaultValues["Task"],

            },
            "Target": {
                ...StepStoreDefaultValues["Target"],

            },
            "Schedule": {
                ...StepStoreDefaultValues["Schedule"],

            },
            "Credential": {
                ...StepStoreDefaultValues["Credential"],

            },
            "HostValidation": {
                ...StepStoreDefaultValues["HostValidation"],

            },
        },
        userRequestedCancel: false,
    };

    changeStep = (direction: number) => () => {
        const {currentPageNumber} = this.state;

        if (currentPageNumber + direction <= 0 || currentPageNumber + direction > this.steps.length) {
            return;
        }
        this.setState(prevState => {
            return {
                currentPageNumber: prevState.currentPageNumber + direction,
            };
        });
    };

    nextStep = this.changeStep(1);
    previousStep = this.changeStep(-1);

    cancelWizard = () => {
        this.setState({userRequestedCancel: true});
    };

    handleSaveAndNext = (stepName: string) => (values: any): any => {
        this.setState(prevState => {
            let stepStore = prevState.stepStore;

            stepStore[stepName] = {...values};
            return {stepStore};
        }, this.nextStep);
    };

    render() {
        const {t} = this.props;
        const {currentPageNumber, stepStore, userRequestedCancel} = this.state;

        const Step = this.steps[currentPageNumber - 1].component;
        const stepName = this.steps[currentPageNumber - 1].name;
        //$FlowFixMe
        const stepInitialValues = stepStore[stepName];

        return (
            <React.Fragment>
                <Navbar>
                    <Branding src="/vmssp-admin/public/assets/logoSmall"/>
                </Navbar>
                <Wrapper>
                    <Sidebar>
                        <List>
                            <ListItem style={{display: "flex", alignItems: "center"}}>
                                <div>
                                    <img style={{height: "1rem", marginRight: "0.625rem"}} src={WhiteGSPLogo}
                                         alt=""/> {t("wizard.menu.title")}
                                </div>
                            </ListItem>

                            {this.steps.map((step, index) => {
                                return <ListItem key={index} active={currentPageNumber === index + 1}>
                                    {index + 1}. {step.menuName}
                                    {

                                        (!isEqual(this.state.stepStore[step.name], StepStoreDefaultValues[step.name]))
                                        && (<WhiteCheckmark src={CheckWhite} alt=""/>)
                                    }


                                </ListItem>;
                            })}
                        </List>
                    </Sidebar>
                    <Content fluid>
                        <Step pageNumber={currentPageNumber}
                              nextStep={this.nextStep}
                              previousStep={this.previousStep}
                              cancelWizard={this.cancelWizard}
                              handleSaveAndNext={this.handleSaveAndNext(stepName)}
                              initValues={stepInitialValues}
                              readOnlyStore={this.state.stepStore}/>
                    </Content>
                </Wrapper>

                <WelcomeScreen/>
                <CustomizedSnackbar/>
                <CancelDialog open={userRequestedCancel} closeDialog={(event) => {
                    this.setState({userRequestedCancel: false});
                }}/>
            </React.Fragment>
        );
    }
}

export const Wizard = compose(
    withTranslation(),
)(_Wizard);
