//@flow

import React from "react"
import {Trans, useTranslation} from "react-i18next"
import styled from "styled-components"
import Step1 from "../../../../assets/icons/host-validation/steps/step1.png"
import Step2 from "../../../../assets/icons/host-validation/steps/step2.png"
import Step3 from "../../../../assets/icons/host-validation/steps/step3.png"
import {Bold, Caption, Paragraph} from "@greenbone/cloud-component-library"
import {Colors} from "../../../Theme"


const Box = styled.div`
  border-top: 3px solid ${Colors.blue.dark};
  box-sizing: border-box;
  padding: 0.938rem;
  background: ${Colors.grey.lighter};

`



const StepImage = styled.img`

  margin-left: 0.938rem;
  display: block;
  margin-top: 0.938rem;
`;

const StepOneImage = styled(StepImage)`
  width: 18.75rem;
  max-width: 100%;
  padding-right: 1em;
`;

const StepTwoImage = styled(StepImage)`
  width: 24.063rem;
  max-width: 100%;
  padding-right: 1em;
`;

const StepThreeImage = styled(StepImage)`
  width: 14.375rem;
  max-width: 100%;
  padding-right: 1em;
`;

const Step = styled.div`
  margin-bottom: 1.875rem;
`;

export const HostValidationInformationBox = () => {
    const {t} = useTranslation();
    return <>
        <Box>
            <Caption>{t("hostValidation.components.informationBox.expiringOfValidation")}</Caption>
            <HostValidationInformationStep
                headline={t("hostValidation.components.informationBox.ripe")}
                image={<StepOneImage src={Step1}/>}
            >

                <Trans i18nKey={"hostValidation.components.infoBox.step1"}>
                    In diesem Schritt werden alle Kontakte von Ripe.net abgeholt.
                    <Bold>
                        Dieser Vorgang kann einen Moment dauern (in der Regel max 30-60 Sekunden).
                    </Bold>
                </Trans>


            </HostValidationInformationStep>
            <HostValidationInformationStep
                headline={t("hostValidation.components.informationBox.chooseContactForValidation")}
                image={<StepTwoImage src={Step2}/>}
            >
                <Trans i18nKey={"hostValidation.components.infoBox.step2"}>
                    <Bold>
                        Entscheiden Sie von wem die Host-Validierung durchgeführt werden soll.
                    </Bold>
                    Falls kein Ripe-Kontakt
                    aufgelistet wird, können Sie auf einen Admin vom GSP-Demo-Team zurückgreifen.
                </Trans>
            </HostValidationInformationStep>
            <HostValidationInformationStep
                headline={t("hostValidation.components.informationBox.waitForAnswers")}
                image={<StepThreeImage src={Step3}/>}
            >
                <Trans i18nKey={"hostValidation.components.infoBox.step3"}>
                    <Bold>
                        Im Anschluss an diesen Schritt wird eine Validierungs-Mail an den ausgewählten Kontakt versendet
                        -
                        GSP wartet nun auf die Rückmeldung dieses Kontaktes.
                    </Bold>

                    Sollten Probleme auftauchen, lässt sich die Validierungs-Mail erneut versenden, oder die
                    Kontaktauswahl
                    zurücksetzen. Die manuelle Prüfung durch einen Admin vom GSP-Demo-Team kann der Regel 1-2 Tage in
                    Anspruch nehmen.
                </Trans>
            </HostValidationInformationStep>
        </Box>
    </>;
};

type Props = {
    headline: string;
    image: any;
    children: any;

}

const HostValidationInformationStep = ({headline, image, children}: Props) => (
    <Step>
        <Bold>{headline}</Bold>
        {image}

        <Paragraph>
            {children}
        </Paragraph>
    </Step>
);


