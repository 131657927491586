import React from "react"
import {Col, Container, Row} from "reactstrap"
import {
    AbortButton,
    Bold,
    Button,
    Caption,
    FlexRow,
    FormHelper,
    Input,
    Paragraph,
    Radio,
    RadioGroup,
    Snackbar,
    Spinner,
    Subheadline,
    Text
} from "@greenbone/cloud-component-library"
import {GatewayRestApiClient} from "../../../services/apiClients/Gateway/GatewayRestApiClient"
import Copy from "clipboard-copy"
import {withTranslation} from "react-i18next"
import {InfoBox} from "../../Wizard/components/InfoBox"
import {ViewSwitch} from "./ViewSwitch"
import {GatewayRouteList} from "./GatewayRouteList"
import {RegTypeSwitch} from "./RegTypeSwitch"
import {SimpleEntityContext} from "../../../services/Context/SimpleEntityContext"
import {Value} from "../../../Helper/Validator";
import {BadRequestError} from "../../../services/Exceptions";

class _GatewayForm extends React.Component {

    state = {
        fields: {
            "description": "",
            "ip": "",
            "location": "",
            "networkMode": "MANUAL",
            "isMacNatEnabled": true,
            "routes": [],
            "dnsServer": ""
        },
        fieldErrors: {
            "description": null,
            "ip": null,
            "location": null,
            "networkMode": null,
            "dnsServer": null
        },
        regType: "WEB",
        view: "BASIC",
        gateway_key: null,
        _loading: false,
        _sending: false,
        _editMode: false,
        _exception: null

    }

    constructor(props) {
        super(props)
        this.apiClient = new GatewayRestApiClient()
    }

    onViewChange = (view) => {
        this.setState({view})
    }

    componentDidMount(): void {
        const {id} = this.props
        if (id) {
            this.setState({_loading: true, _editMode: true})
            this.apiClient.getOne(id)
                .then(response => {
                    const gateway = response
                    this.setState({
                        fields: {
                            ...gateway,
                            routes: gateway.advanced?.routes || [],
                            ip: gateway.basic?.ip || "",
                            isMacNatEnabled: gateway.advanced?.isMacNatEnabled || true,
                            dnsServer: gateway.basic?.dnsServer || ""
                        },
                        view: gateway.type || "BASIC"
                    })

                })
                .catch(e => {
                    this.setState({_loading: false, _exception: e})
                }).finally(() => {
                this.setState({_loading: false})
            })
        }
    }

    onChange = (event) => {
        const {name, value} = event.target

        this.setState(prevState => ({fields: {...prevState.fields, [name]: value}}))
    }


    handleFormSubmit = (event) => {
        event.preventDefault()
        this.setState({_sending: true})

        const {t} = this.props

        let gateway = {
            location: this.state.fields.location,
            description: this.state.fields.description,
            networkMode: this.state.fields.networkMode,
            isMacNatEnabled: this.state.fields.isMacNatEnabled,
            type: "BASIC"
        }


        if (this.state.fields.routes?.length > 0 ||
            this.state.fields.isMacNatEnabled === false) {
            gateway = {
                ...gateway,
                type: "ADVANCED"
            }
        }

        gateway = {
            ...gateway,
            basic: {
                isMacNatEnabled: true,
                ip: this.state.fields.ip,
                dnsServer: this.state.fields.dnsServer
            },
            advanced: {
                isMacNatEnabled: this.state.fields.isMacNatEnabled,
                routes: this.state.fields.routes
            }
        }


        if (this.state._editMode) {
            gateway = {
                ...gateway,
                id: this.props.id
            }
        }

        if (this.state._editMode) {
            this.apiClient.updateEntity(gateway)
                .then(response => {
                    Snackbar.Success(t("gatewayForm.update.success"))
                    this.context.updateGateways()
                    this.setState({_sending: false})
                    this.props.updateEntity(response)
                    this.props.onClose()
                })
                .catch(error => {
                    this.handleError(error)
                })
        } else {
            this.apiClient.createEntity(gateway)
                .then(response => {

                    Snackbar.Success(t("gatewayForm.create.success"))
                    this.context.updateGateways()
                    this.setState({_sending: false})
                    this.props.updateEntity(response)
                    this.props.onClose()
                })
                .catch(error => {
                    debugger
                    this.handleError(error)
                })
        }
    }

    handleError = (error: any) => {
        this.setState({_saving: false})
        this.setState({_sending: false})

        if (Value(error.type).isInList([BadRequestError])) {
            const errorObject = error.fieldErrors
            if (errorObject["basic.ip"]) {
                errorObject["ip"] = errorObject["basic.ip"]
            }

            if (errorObject["basic.dnsServer"]) {
                errorObject["dnsServer"] = errorObject["basic.dnsServer"]
            }

            if (errorObject["basic"]) {
                errorObject["ip"] = errorObject["basic"]
            }

            FormHelper.handleFieldErrors(this.state.fieldErrors, errorObject, this.setState.bind(this))

            Snackbar.Error(this.props.t("gatewayform.checkInput"))
            return
        }
        Snackbar.Error(error.message)
    }

    handleCheckboxChange = (event) => {
        this.setState(prevState => {
            return {fields: {...prevState.fields, isMacNatEnabled: !prevState.fields.isMacNatEnabled}}
        })
    }

    render() {
        const {t} = this.props

        const {_loading, _exception, _editMode, _sending} = this.state

        if (_exception) {
            throw _exception
        }

        if (_loading) {
            return <Container style={{minWidth: "40rem"}}><Spinner/></Container>
        }

        const {networkMode, description, ip, location, dnsServer} = this.state.fields
        const {fieldErrors} = this.state


        return <Container style={{width: "45rem"}}>

            <Row>
                <Col>
                    {_editMode ?
                        <Subheadline>{t("gatewayForm.edit", {location})}</Subheadline>
                        : <Subheadline>{t("gatewayForm.create")}</Subheadline>}
                </Col>
            </Row>
            <Row>
                <Col>
                    <Caption>{t("gatewayForm.gateway")}</Caption>
                    <Input isValid={fieldErrors.location} onChange={this.onChange} value={location}
                           name={"location"} margin={"normal"}
                           label={t("gatewayForm.fields.location")}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Input isValid={fieldErrors.description} onChange={this.onChange} value={description}
                           name={"description"} margin={"normal"}
                           label={t("gatewayForm.fields.description")}/>
                </Col>
            </Row>

            {this.state.fields?.apiKeyStatus === "REGISTERED" ? <>
                <Row>
                    <Col>
                        <Caption style={{marginTop: "1rem", marginBottom: "0.5rem"}}>Registration</Caption>
                        <Paragraph>
                            The Gateway has already been registered.
                        </Paragraph>
                    </Col>
                </Row>
            </> : <>

                {this.state.fields.gatewayKey &&
                <>
                    <RegTypeSwitch type={this.state.regType} onChange={type => this.setState({regType: type})}/>

                    {this.state.regType === "WEB" &&
                    <>
                        <Row>
                            <Col>
                                <Caption style={{marginTop: "1rem", marginBottom: "0"}}>Web Registration</Caption>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Input disabled multiline value={this.state.fields.gatewayKey} margin={"normal"}
                                       label={"API-Key"}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button onClick={(event) => {
                                    event.preventDefault()
                                    Copy(this.state.fields.gatewayKey)
                                        .then(() => {
                                            Snackbar.Success((t("gateway.form.copy.success")))
                                        })
                                        .catch(() => {
                                            Snackbar.Error(t("gateway.form.copy.error"))
                                        })

                                }}>{t("gatewayForm.copyToClipboard")}</Button>
                            </Col>
                        </Row>
                    </>
                    }
                </>
                }
            </>}

            {this.state.regType === "CLI" &&
            <>
                {(this.state.fields.token && this.state.fields.hostAddress) ?
                    <Caption style={{marginTop: "1rem", marginBottom: "0"}}>CLI Registration</Caption>
                    :
                    <Caption style={{marginTop: "1rem", marginBottom: "0"}}>Not available</Caption>

                }


                {this.state.fields.hostAddress &&
                <>
                    <Row>
                        <Col>
                            <Input readOnly multiline value={this.state.fields.hostAddress} margin={"normal"}
                                   label={"URL"}/>
                        </Col>
                    </Row>
                </>
                }

                {this.state.fields.token &&
                <>
                    <Row>
                        <Col>
                            <Input readOnly multiline value={this.state.fields.token} margin={"normal"}
                                   label={"Token"}/>
                        </Col>
                    </Row>
                </>
                }


            </>
            }


            <Row>
                <Col>
                    <Caption style={{marginBottom: 0, marginTop: "2rem"}}>{t("gatewayform.network")}</Caption>
                </Col>
            </Row>

            <Row>
                <Col>
                    <ViewSwitch onChange={this.onViewChange} view={this.state.view}/>
                </Col>
            </Row>


            {this.state.view === "BASIC" ?
                <>
                    <Row style={{display: "none"}}>
                        <Col style={{paddingTop: "2rem"}}>
                            <Caption noMargin>Scanner</Caption>
                            <RadioGroup row={true} style={{margin: "0", flexDirection: "row"}} label={""}
                                        value={networkMode} name={"networkMode"}
                                        onChange={this.onChange}>
                                <Radio disabled value={"DHCP"} label={"DHCP"}/>
                                <Radio value={"MANUAL"} label={"Manual"}/>
                            </RadioGroup>
                        </Col>
                    </Row>

                    {networkMode === "MANUAL" &&
                    <>

                        <Row>
                            <Col>
                                <Caption>Scanner</Caption>
                                <InfoBox type={"tip"}>
                                    <Text style={{marginBottom: "1rem", display: "block"}}>
                                        <Bold>
                                            {t("gatewayForm.warningFreeIp")}
                                        </Bold>
                                    </Text>
                                </InfoBox>
                                <Input margin={"normal"} helperText={"192.168.178.55/24"} isValid={fieldErrors.ip}
                                       onChange={this.onChange}
                                       value={ip || ""} name={"ip"}
                                       label={t("gatewayForm.fields.ip")}/>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Input margin={"normal"} helperText={"192.168.178.1"} isValid={fieldErrors.dnsServer}
                                       onChange={this.onChange}
                                       value={dnsServer || ""} name={"dnsServer"}
                                       label={t("gatewayForm.fields.dnsServer")}/>
                            </Col>
                        </Row>
                    </>}

                </>
                :
                <>
                    <GatewayRouteList networkAddress={this.state.fields.ip}
                                      isMacNatEnabled={this.state.fields.isMacNatEnabled}
                                      handleCheckboxChange={this.handleCheckboxChange}
                                      fieldErrors={this.state.fieldErrors} setState={this.setState.bind(this)}
                                      routes={this.state.fields.routes}/>

                </>}

            <Row>
                <Col>
                    <FlexRow style={{flexDirection: "row-reverse"}} justifyContent={"space-between"}>
                        <Button onClick={this.handleFormSubmit} disabled={_sending}>
                            {_editMode ? <span>{t("gatewayForm.update")}</span> :
                                <span>{t("gatewayForm.create")}</span>}
                            {_sending && <Spinner/>}</Button>
                        <AbortButton style={{marginLeft: 0}} onClick={(event) => {
                            event.preventDefault()
                            this.props.onClose()

                        }}>{t("common.action.abort")}</AbortButton>
                    </FlexRow>
                </Col>
            </Row>


        </Container>
    }
}

_GatewayForm.contextType = SimpleEntityContext

export const GatewayForm = withTranslation()(_GatewayForm)

