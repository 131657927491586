//@flow

import {withStyles} from "@material-ui/core"
import MaterialUIPaper from "@material-ui/core/Paper"
import React from "react"
import {connect} from "react-redux"
import {bindActionCreators, compose} from "redux"
import styled from "styled-components"
import {GlobalConstants, IS_LOCAL} from "../../constants"
import {Cookie} from "../../Helper/Cookie"
import {WelcomeScreenContent} from "./WelcomeScreenContent"
import {JWT} from "../../Helper/JWT"
import {RootUrlProvider} from "../../UrlProvider/RootUrlProvider"
import {withNavigate} from "../../../hocs/withNavigate";
import {NavigateFunction} from "react-router";


const Paper = styled(MaterialUIPaper)`
  padding: 2.50rem;
  padding-bottom: 1.25rem;
  width: 46.88rem;
  height: 36.25rem;
`

const styles = theme => ({
    container: {
        position: "fixed",
        height: "100%",
        width: "100%",
        backgroundColor: "rgba(0,0,0,0.5)",
        top: 0,
        left: 0,
        zIndex: 1201,
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }
});

type Props = {
    accessToken: string;
    classes: typeof styles;
    open: boolean;
    navigate: NavigateFunction
};

type State = {
    showPopup: boolean;
}

class _WelcomeScreen extends React.Component<Props, State> {

    state = {
        showPopup: false
    };

    updateWizardVisibility = () => {
        if (IS_LOCAL) {
            return
        }

        let cookie = Cookie.get(GlobalConstants.WELCOME_WIZARD_ACCEPTED)

        if (!cookie) {
            cookie = Cookie._dangerousGet("WELCOME_WIZARD_ACCEPTED_HIDDEN")
        }


        const jwt = new JWT()

        if (jwt.subject().trim()) {
            if (cookie === null) {
                this.setState({showPopup: true})
            }
        }


    };

    componentDidMount() {
        this.updateWizardVisibility();
    }

    render() {
        const {classes} = this.props;

        if (this.state.showPopup === false || this.props.open === false) {
            return null;
        }

        return (
            <div className={classes.container}>
                <Paper>
                    <WelcomeScreenContent callback={(gotoWizard) => {
                        this.setState({showPopup: false});
                        if (gotoWizard) {
                            //$FlowFixMe
                            this.props.navigate(RootUrlProvider.wizard());
                        }
                    }}/>
                </Paper>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    let actions = bindActionCreators({}, dispatch);
    return {...actions, dispatch};
}

function mapStateToProps(state) {
    return {
        open: state.welcomeDialog.Open
    };
}

export const WelcomeScreen = compose(
    withNavigate,
    withStyles(styles),
    connect(mapStateToProps, mapDispatchToProps)
)(_WelcomeScreen);
