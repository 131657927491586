import React from "react"
import styled from "styled-components"
import Arrow from "./arrow-down-1.svg"
import {Colors} from "../../../Theme"


const Selector = styled.div`
 
  max-width: 15rem;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  
  
  &:hover {
    cursor: pointer;
    background: #e6e6e6;
  }
`

const ArrowArea = styled.div`
  box-sizing: border-box;  
  width: 1.875rem;
  background-image: url(${Arrow});
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: center ;
  height: 70%;
  border-left: 2px solid #7c7c7e;
`

const Selections = styled.div`
  box-sizing: border-box;
  border: 1px solid ${Colors.grey.light};
  position: absolute;
  min-height: 2.5rem;
  width: inherit;
  background: red;
  margin-top: 2.188rem;
  z-index: 10;
  
  &:last-child{
    border-bottom: none;
  }
`

const Option = styled.div`
  height: 2.375rem;
  background: white;
  box-sizing: border-box;
  border-bottom: 1px solid ${Colors.grey.light};
  color: #4C4C4D;
  
  display: flex;
  padding-left: 0.313rem;
  align-items: center;
  
  &:hover {
    color: white;
    background: ${({theme}) => theme.base};
  }
`

const DisplayText = styled.div`
  padding-left: 0.625rem;
  display: flex;
  align-items: center;
  color: #4C4C4D;
  font-size: 1rem;
  font-weight: bold;
`

/*
Example Usage:
            <DashboardCellBannerDropdown value={"asd"} options={[
                {label: "asd", value: "asd"},
                {label: "bsd", value: "bsd"}
            ]}
            onChange={({name, value}) =>  {}}
            name={"inputName"}
            />
 */


export class DashboardCellBannerDropdown extends React.Component {
    node = null
    state = {
        isOpen: false
    }

    componentDidMount() {
        window.document.body.addEventListener("click", this.handleClose)
    }

    componentWillUnmount() {
        window.document.body.removeEventListener("click", this.handleClose)
    }

    selectOption = (value) => {
        if (this.props.onChange) {
            this.props.onChange({name: this.props.name, value: value})
        }
    }

    handleOpen = (event) => {
        this.setState(prevState => {
            return {isOpen: !prevState.isOpen}
        })
    }

    handleClose = (event) => {
        if (!this.node) {
            return
        }

        if (!this.node.contains(event.target)) {
            if (this.state.isOpen) {
                this.setState({isOpen: false})
            }
        }
    }

    render() {
        const {options, value} = this.props
        const {isOpen} = this.state

        return (

            <Selector ref={node => this.node = node} onClick={this.handleOpen}>
                <DisplayText>
                    {options.find(op => op.value === value).label}
                </DisplayText>
                <ArrowArea>
                </ArrowArea>
                {isOpen && <Selections>
                    {options.map(data => {
                        return <Option key={data.value} onClick={(event) => {
                            event.preventDefault()
                            this.selectOption(data.value)
                        }} value={data.value}>{data.label}</Option>
                    })}
                </Selections>}
            </Selector>


        )
    }
}
