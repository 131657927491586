import React from "react"
import styled from "styled-components"
import Dog from "./assets/head.svg"

const RocketMagix = styled.div`
body {
  background-color: #fff;
  overflow: hidden;
}

h1 {
  position: absolute;
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  left: 50%;
  top: 45%;
  margin-left: -20px;
  font-family: sans-serif;
}

.body {
  position: absolute;
  top: 50%;
  margin-left: -225px;
  left: 50%;
  animation: speeder .4s linear infinite;
}
.body > span {
  height: 9px;
  width: 49px;
  background: #1e1916;
  position: absolute;
  top: 50px;
  left: 17px;
  border-radius: 2px 10px 1px 0;
}

.base span {
  position: absolute;
  width: 0;
  height: 0;
  border-top: 6px solid transparent;
  border-right: 100px solid #000;
  border-bottom: 6px solid transparent;
}
.base span:before {
  content: "";
  height: 22px;
  width: 22px;
  border-radius: 50%;
  background: #000;
  position: absolute;
  right: -110px;
  top: -16px;
}
.base span:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-top: 0 solid transparent;
  border-right: 55px solid #000;
  border-bottom: 16px solid transparent;
  top: -16px;
  right: -98px;
}


.body > span > span:nth-child(1),
.body > span > span:nth-child(2),
.body > span > span:nth-child(3),
.body > span > span:nth-child(4) {
  width: 30px;
  height: 1px;
  background: #000;
  position: absolute;
  animation: fazer1 .2s linear infinite;
}

.body > span > span:nth-child(2) {
  top: 3px;
  animation: fazer2 .4s linear infinite;
}

.body > span > span:nth-child(3) {
  top: 1px;
  animation: fazer3 .4s linear infinite;
  animation-delay: -1s;
}

.body > span > span:nth-child(4) {
  top: 4px;
  animation: fazer4 1s linear infinite;
  animation-delay: -1s;
}

@keyframes fazer1 {
  0% {
    left: 0;
  }
  100% {
    left: -80px;
    opacity: 0;
  }
}
@keyframes fazer2 {
  0% {
    left: 0;
  }
  100% {
    left: -100px;
    opacity: 0;
  }
}
@keyframes fazer3 {
  0% {
    left: 0;
  }
  100% {
    left: -50px;
    opacity: 0;
  }
}
@keyframes fazer4 {
  0% {
    left: 0;
  }
  100% {
    left: -150px;
    opacity: 0;
  }
}
@keyframes speeder {
  0% {
    transform: translate(2px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -3px) rotate(-1deg);
  }
  20% {
    transform: translate(-2px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 3px) rotate(-1deg);
  }
  60% {
    transform: translate(-1px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-2px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(2px, 1px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
.longfazers {
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
}
.longfazers span {
  position: absolute;
  height: 2px;
  width: 20%;
  background: #000;
}
.longfazers span:nth-child(1) {
  top: 20%;
  animation: lf .6s linear infinite;
  animation-delay: -5s;
}
.longfazers span:nth-child(2) {
  top: 40%;
  animation: lf2 .8s linear infinite;
  animation-delay: -1s;
}
.longfazers span:nth-child(3) {
  top: 60%;
  animation: lf3 .6s linear infinite;
}
.longfazers span:nth-child(4) {
  top: 80%;
  animation: lf4 .5s linear infinite;
  animation-delay: -3s;
}

@keyframes lf {
  0% {
    left: 200%;
  }
  100% {
    left: -200%;
    opacity: 0;
  }
}
@keyframes lf2 {
  0% {
    left: 200%;
  }
  100% {
    left: -200%;
    opacity: 0;
  }
}
@keyframes lf3 {
  0% {
    left: 200%;
  }
  100% {
    left: -100%;
    opacity: 0;
  }
}
@keyframes lf4 {
  0% {
    left: 200%;
  }
  100% {
    left: -100%;
    opacity: 0;
  }
}

svg {
    height: 150px;
}
`;


export class RocketLoader extends React.Component {

    state = {
        visible: false
    };

    componentDidMount(): void {
        setTimeout(this.setState.bind(this), 100, {visible: true});
        //this.setState({visible: true});
    }

    render() {
        return (
            <RocketMagix style={{visibility: this.state.visible ? "visible" : "hidden"}}>

                <div className='body'>
                    <span style={{display: "none"}}>
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                    </span>
                    <div className='base'>
                        <Dog />
                    </div>
                </div>
                <div className='longfazers'>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <h1>loading...</h1>

            </RocketMagix>
        );
    }
}
