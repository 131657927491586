//@flow
import * as HttpStatuscode from "http-status-codes"


export class BadRequestError extends Error {
    httpStatus = HttpStatuscode.BAD_REQUEST;
    type = BadRequestError
    fieldErrors: any

    constructor(message: string = "", fieldErrors: any) {
        super(`Bad request: '${message}'`)
        this.fieldErrors = fieldErrors
    }
}
