// @flow

import React from "react";
import {withTranslation} from "react-i18next";
import {compose} from "redux";
import {ColSmall} from "./Grid";
import {TargetTabBox} from "./TargetTabBox";


type Props = {
    onChange: EventCallback;
    selectedId: ?string;
    targets: Array<GSPTarget>;
    t: any;
}

export class _TargetSelect extends React.Component<Props> {

    render() {
        const {targets, selectedId, onChange, t} = this.props;

        return <React.Fragment>{
            targets.length ?
                targets.map((target: GSPTarget) => {
                    return <ColSmall key={target.id} xl={4} lg={6} md={6} sm={12} style={{marginBottom: "1.88rem"}}>
                        <TargetTabBox style={{}}
                                      title={target.name}
                                      aliveTest={target.aliveTest.name}
                                      portList={target.portList.name}
                                      ipCount={target.ipAddressAmount}
                                      active={target.id === selectedId}
                                      onClick={() => {
                                          onChange(target);
                                      }}/>
                    </ColSmall>;
                })
                :
                <ColSmall>{t("wizard.components.credentialSelect.notFound")}</ColSmall>
        }</React.Fragment>;
    }
}

export const TargetSelect = compose(
    withTranslation(),
)(_TargetSelect);
