//@flow

import React from "react";
import {Route} from "react-router-dom";
import {Switcher} from "../../Components/Router";

import {SchedulePage} from "./components/SchedulePage";


type Props = {
    history: any;
}

export class ScheduleRouter extends React.Component<Props, {}> {

    render() {
        return (

            <Switcher>
                <Route path="/list" Component={SchedulePage}/>
            </Switcher>

        );
    }
}
