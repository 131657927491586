import React from "react"
import {withTranslation} from "react-i18next"
import {connect} from "react-redux"
import {Col, Row} from "reactstrap"
import {bindActionCreators, compose} from "redux"
import {AbortButton, Button, FlexRow, Snackbar, Subheadline} from "@greenbone/cloud-component-library"
import Loader from "../../../Components/Loader/Loader"
import {openErrorSnackbar} from "../../../Components/Snackbar/actions"
import {Paragraph} from "../../../StyledComponents/Font/Font"
import {Checkbox} from "../../../StyledComponents/Form/Checkbox"
import {Colors} from "../../../Theme"
import {HostValidationRestApiClient} from "../../../services/apiClients/HostValidation/HostValidationRestApiClient"
import {HostValidationContactCard} from "./HostValidationContactCard"

type State = {
    contacts: Array<any>;
    _loading: boolean;
    adminValidation: boolean;
    selectedContactId: ?number;
    userValidation: boolean;
}

type Props = {
    hostId: string;
    selectContact: any;
    selectAdminContact: any;
    handleClose: EventCallback;
    openErrorSnackbar: typeof openErrorSnackbar;
    validateUser: any;
    t: any;
}

class _HostValidationContactSelection extends React.Component<Props, State> {
    hostValidationRestApiClient: HostValidationRestApiClient

    state = {
        contacts: [],
        _loading: false,
        adminValidation: false,
        userValidation: false,
        selectedContactId: null
    }

    constructor(props: any) {
        super(props)

        this.hostValidationRestApiClient = new HostValidationRestApiClient()
    }

    componentDidMount() {
        this.loadHostValidationContacts()
    }

    loadHostValidationContacts = () => {
        this.setState({_loading: true})
        this.hostValidationRestApiClient.getHostValidationContacts(this.props.hostId)
            .then(response => {
                this.setState({contacts: response})
            })
            .catch(_exception => {

            }).finally(() => {
            this.setState({_loading: false})
        })
    }

    handleAdminCheckboxChange = (event: any) => {
        this.setState(prevState => {

            if (prevState.adminValidation === false) {
                return {adminValidation: !prevState.adminValidation, selectedContactId: null, userValidation: false}
            }

            return {adminValidation: !prevState.adminValidation}
        })
    }

    handleOwnerCheckboxChange = (event: any) => {
        this.setState(prevState => {

            if (prevState.userValidation === false) {
                return {userValidation: !prevState.userValidation, selectedContactId: null, adminValidation: false}
            }

            return {userValidation: !prevState.userValidation}
        })
    }

    handleSelectContact = (id: number) => {
        this.setState(prevState => {
            return {
                selectedContactId: id === prevState.selectedContactId ? null : id,
                adminValidation: false,
                userValidation: false
            }
        })
    }

    handleHostValidationSubmit = (event: any) => {
        const {t} = this.props
        if (!this.state.adminValidation && !this.state.userValidation && this.state.selectedContactId == null) {
            Snackbar.Error(t("wizard.components.hostValidation.confirmYourTheOwner"))
            return
        }

        if (this.state.adminValidation) {
            this.props.selectAdminContact()
        } else if (this.state.userValidation) {
            this.props.validateUser()
        } else {
            const selectedContactId = this.state.selectedContactId
            this.props.selectContact(selectedContactId)
        }

    }

    render() {
        const {t} = this.props
        if (this.state._loading) {
            return <Loader/>
        }


        return (
            <React.Fragment>
                <Row style={{marginBottom: "2rem"}}>
                    <Col>
                        <Row>
                            <Col>
                                <Subheadline>I Am The Owner</Subheadline>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Checkbox onChange={this.handleOwnerCheckboxChange} checked={this.state.userValidation}
                                          label={t("wizard.components.hostValidation.selfcheck")}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row style={{marginBottom: "2rem"}}>
                    <Col>

                        <Row>
                            <Col>
                                <Subheadline>Technical Contact Person</Subheadline>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {this.state.contacts.map((contact, index) => {
                                    return <HostValidationContactCard key={index}
                                                                      active={this.state.selectedContactId === contact.id}
                                                                      onClick={(event) => {
                                                                          this.handleSelectContact(contact.id)
                                                                      }}
                                                                      contact={contact}/>
                                })}
                                {this.state.contacts.length === 0 && (
                                    <Paragraph style={{marginTop: "0"}}>
                                        {t("wizard.components.hostValidation.hostNotFoundChooseAdmin")}
                                    </Paragraph>
                                )}
                            </Col>
                        </Row>


                    </Col>
                </Row>
                <Row style={{marginBottom: "2rem"}}>
                    <Col>
                        <Row>
                            <Col>
                                <Subheadline>{t("wizard.components.hostValidation.partnerVerification")}</Subheadline>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Checkbox onChange={this.handleAdminCheckboxChange} checked={this.state.adminValidation}
                                          label={t("wizard.components.hostValidation.manuelCheckByGreenbone")}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>


                {
                    (!!this.state.selectedContactId) && (
                        <Row>
                            <Col>
                                <Paragraph>
                                    <span style={{color: Colors.blue.normal}}>
                                        {t("hostValidation.mailWillBeSend", {mail: this.state.contacts.find(contact => (contact.id === this.state.selectedContactId))["name"]})}
                                    </span>
                                </Paragraph>
                            </Col>
                        </Row>
                    )
                }

                <Row>
                    <Col>
                        <FlexRow justifyContent={"space-between"}>
                            <AbortButton onClick={this.props.handleClose}>{t("common.action.abort")}</AbortButton>
                            <Button
                                onClick={this.handleHostValidationSubmit}>
                                {this.state.userValidation ? t("wizard.components.hostValidation.validateHostNow") : t("wizard.components.hostValidation.askForValidation")}
                            </Button>
                        </FlexRow>
                    </Col>
                </Row>


            </React.Fragment>
        )
    }
}

function mapDispatchToProps(dispatch) {
    let actions = bindActionCreators({
        openErrorSnackbar: openErrorSnackbar
    }, dispatch)
    return {...actions, dispatch}
}

function mapStateToProps(state) {
    return {}
}

export const HostValidationContactSelection = compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps)
)(_HostValidationContactSelection)
