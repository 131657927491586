// @flow

import {withStyles} from "@material-ui/core";

import Snackbar from "@material-ui/core/Snackbar";
import React from "react";
import {connect} from "react-redux";
import {bindActionCreators, compose} from "redux";
import {closeSnackbar} from "../actions";
import {SnackbarTypes} from "../index";
import {SnackbarContentWrapper} from "./SnackBarContent";


const customizedSnackbarStyles = theme => ({
    margin: {
        margin: theme.spacing(1)
    },
    snackbar: {
        top: "2%",
        zIndex: 2300,
    },

});

type Props = {
    isOpen: boolean;
    snackbarType: typeof SnackbarTypes;
    message: string;
    closeSnackbar: typeof closeSnackbar;
    classes: typeof customizedSnackbarStyles
}

class _CustomizedSnackbar extends React.Component<Props, {}> {

    handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        this.props.closeSnackbar();
    };

    render() {

        const {classes} = this.props;

        return (
            <div>
                <Snackbar
                    className={classes.snackbar}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                    open={this.props.isOpen}
                    autoHideDuration={4000}
                    onClose={this.handleClose}
                >
                    <SnackbarContentWrapper
                        onClose={this.handleClose}
                        variant={this.props.snackbarType}
                        message={this.props.message}
                    />
                </Snackbar>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch) {
    let actions = bindActionCreators({
        closeSnackbar: closeSnackbar,
    }, dispatch);
    return {...actions, dispatch};
}

function mapStateToProps(state) {
    return {
        isOpen: state.snackbar.snackbarOpen,
        snackbarType: state.snackbar.snackbarType,
        message: state.snackbar.message,
    };
}

export const CustomizedSnackbar = compose(
    withStyles(customizedSnackbarStyles),
    connect(mapStateToProps, mapDispatchToProps),
)(_CustomizedSnackbar);
