// @flow

import {Drawer, IconButton} from "@material-ui/core"
import {Autorenew, Close} from "@material-ui/icons"
import React from "react"
import {withTranslation} from "react-i18next"
import {connect} from "react-redux"
import {Col, Container, Row} from "reactstrap"
import {bindActionCreators, compose} from "redux"
import {openAlertDialog} from "../../../Components/Dialogs/AlertDialog/actions"
import Loader from "../../../Components/Loader/Loader"
import {Portal} from "../../../Components/Portal"
import {openSuccessSnackbar} from "../../../Components/Snackbar/actions"
import {Logger} from "../../../controller/loggingController"
import {Value} from "../../../Helper/Validator"
import {Headline, Paragraph} from "../../../StyledComponents/Font/Font"
import {ValidationStatus} from "../../Hostvalidation/types/Host"
import {HostValidationRestApiClient} from "../../../services/apiClients/HostValidation/HostValidationRestApiClient"
import {TargetRestApiClient} from "../../../services/apiClients/Target/TargetRestApiClient"
import {HostValidationContactSelection} from "./HostValidationContactSelection"
import {Snackbar} from "@greenbone/cloud-component-library";


type State = {
    _handlingHostValidationStartPost: boolean;
    _hostValidationContactSetSending: boolean;
    _loading: boolean;
    host: ?GSPHost;
    validationStatus: ?GSPValidationStatus;
}

type Props = {
    _isOpen: boolean;
    hostId: string;
    onClose: any;
    openAlertDialog: typeof openAlertDialog;
    representation: string;
    targetId: string;
    t: any;
}

class _HostValidationWizard extends React.Component<Props, State> {
    timer: any
    hostValidationRestClient: HostValidationRestApiClient
    targetRestApiClient: TargetRestApiClient

    hostValidationRestClientWithoutAuth: HostValidationRestApiClient

    state = {
        _handlingHostValidationStartPost: false,
        _hostValidationContactSetSending: false,
        _loading: false,
        _resendEmailSending: false,
        host: null,
        validationStatus: null
    }

    constructor(props: any) {
        super(props)
        this.hostValidationRestClient = new HostValidationRestApiClient()
        this.targetRestApiClient = new TargetRestApiClient()
        this.hostValidationRestClientWithoutAuth = new HostValidationRestApiClient()
    }

    intervallUpdate = () => {
        if (!this.props._isOpen) {
            return
        }
        if (!Value(this.state.validationStatus).isInList([ValidationStatus.APPROVED, ValidationStatus.REJECTED, ValidationStatus.FETCHED])) {
            this.updateCurrentHost()
        }
    }

    componentDidMount() {
        this.timer = window.setInterval(() => this.intervallUpdate(), 2000)
        this.updateCurrentHost()
    }

    componentWillUnmount() {
        try {
            window.clearInterval(this.timer)
        } catch (e) {
            Logger.error(e)
        }

    }

    componentDidUpdate(prevProps: any) {
        if (this.props._isOpen && prevProps._isOpen === false) {
            this.updateCurrentHost()
        }
    }


    validateUser = () => {

        const {t} = this.props

        this.setState({_hostValidationContactSetSending: true})
        this.hostValidationRestClient.setHostValidationUser(this.props.hostId)
            .then(response => {
                this.updateCurrentHost()
            })
            .catch(_exception => {
                this.handleException(_exception)
            })
            .finally(() => {
                this.setState({_hostValidationContactSetSending: false})
                this.props.onClose()
            })
    }

    setHostContactSelected = (contactId: string) => {
        const {t} = this.props

        this.setState({_hostValidationContactSetSending: true})
        this.hostValidationRestClient.setHostValidationContact(this.props.hostId, contactId)
            .then(response => {
                this.updateCurrentHost()
            })
            .catch(_exception => {
                this.handleException(_exception)
            })
            .finally(() => {
                this.setState({_hostValidationContactSetSending: false})
                this.props.onClose()
            })
    }

    fetchContacts = () => {
        if (this.state.validationStatus === ValidationStatus.INITIALISED) {
            this.handleStartHostValidation()
        }
    }

    updateCurrentHost = () => {
        this.setState({_loading: true})
        this.hostValidationRestClient.getHostValidationStatus(this.props.hostId)
            .then(response => {
                if (response !== undefined) {
                    this.setState({validationStatus: response})
                }
            })
            .catch(exception => {
                this.handleException(exception)
                this.loadHostFromTargetService()
            })
            .finally(() => {
                this.setState({_loading: false})
            })
    }

    loadHostFromTargetService = () => {
        this.setState({_loading: true})
        this.targetRestApiClient.getOne(this.props.targetId)
            .then((target: GSPTarget) => {
                target.includedHosts.forEach(host => {
                    if (host.id === this.props.hostId) {
                        this.setState({validationStatus: host.validationStatus}, this.fetchContacts)
                    }
                })
            })
            .finally(() => {
                this.setState({_loading: false})
            })
    }

    setHostAdminContactSelected = () => {
        const {t} = this.props

        this.setState({_hostValidationContactSetSending: true})
        this.hostValidationRestClient.setHostValidationAdminContact(this.props.hostId)
            .then(response => {
                this.updateCurrentHost()
            })
            .catch(exception => {
                this.handleException(exception)
            })
            .finally(() => {
                this.setState({_hostValidationContactSetSending: false})
                this.props.onClose()
            })
    }

    handleStartHostValidation = () => {
        const {t} = this.props
        this.setState({_handlingHostValidationStartPost: true})
        this.hostValidationRestClient.startHostValidationContactRequest(this.props.targetId, this.props.hostId)
            .then(() => {
                this.updateCurrentHost()
            })
            .catch(exception => {
                this.handleException(exception)
            })
            .finally(() => {
                this.setState({_handlingHostValidationStartPost: false})
            })
    }

    handleException = (exception: any) => {
        const {t} = this.props

        if (exception?.status === 403) {
            Snackbar.Error(t("components.error.boundary.noPermission"))
        } else {
            this.props.openAlertDialog(t("common.messages.error"), exception.message)
        }
    }

    render() {
        const {t} = this.props
        return (
            <Portal>
                <Drawer anchor="right" open={this.props._isOpen} onClose={this.props.onClose}>
                    <Container style={{minWidth: "800px"}}>
                        <Row style={{margin: "2.5rem 1rem 1rem", maxWidth: "700px"}}>
                            <Col>
                                <Row>
                                    <Col>
                                        <div style={{marginLeft: "-0.8rem"}}>
                                            <IconButton onClick={this.props.onClose}>
                                                <Close/>
                                            </IconButton>
                                            <IconButton onClick={this.updateCurrentHost}>
                                                <Autorenew/>
                                            </IconButton>
                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{marginBottom: "2rem"}}>
                                    <Col>
                                        <Headline>{t("wizard.components.hostValidation.request", {representation: this.props.representation})}</Headline>
                                        <Paragraph>
                                            {t("wizard.components.hostValidation.reasonToChooseAHost")}
                                        </Paragraph>
                                    </Col>
                                </Row>

                                {
                                    (this.state.validationStatus === null || this.state._loading) ? <Loader/> : (
                                        <Row>
                                            <Col>
                                                <HostValidationContactSelection
                                                    handleClose={this.props.onClose}
                                                    selectAdminContact={this.setHostAdminContactSelected}
                                                    selectContact={this.setHostContactSelected}
                                                    validateUser={this.validateUser}
                                                    hostId={this.props.hostId}
                                                    targetId={this.props.targetId}/>
                                            </Col>
                                        </Row>
                                    )
                                }
                            </Col>
                        </Row>
                    </Container>

                </Drawer>
            </Portal>
        )
    }
}

function mapDispatchToProps(dispatch: any) {
    let actions = bindActionCreators({
        openAlertDialog: openAlertDialog,
        openSuccessSnackbar: openSuccessSnackbar
    }, dispatch)
    return {...actions, dispatch}
}

function mapStateToProps(state) {
    return {}
}

export const HostValidationWizard = compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps)
)(_HostValidationWizard)
