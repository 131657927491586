// @flow

import React, {useEffect, useState} from 'react';
import {Col, Row} from 'reactstrap';
import {AbortButton, Button, Input} from '@greenbone/cloud-component-library';
import {withTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {bindActionCreators, compose} from 'redux';
import {openAlertDialog} from '../../../Components/Dialogs/AlertDialog/actions';
import {Headline} from "../../../StyledComponents/Font/Font";
import {Validator} from "../../../Helper/Validator";
import InputWarning from "../../../Layout/components/InputWarning";

type Props = {
    onClose: Function,
    onUpdateOrCreate: Function,
    email: ?string,
    t: any,
    valid: ?string,
    currentUserEmail: ?string
};

const AdditionalEmailForm = ({onClose, onUpdateOrCreate, email, t, valid, currentUserEmail}: Props) => {
    const [emailValue, setEmailValue] = useState(email || '');
    const [isValid, setIsValid] = useState(null);
    const [domainMismatch, setDomainMismatch] = useState(false);

    useEffect(() => {
        setIsValid(valid);
        if (valid === true) {
            onClose();
        }
    }, [valid]);

    useEffect(() => {
        if (emailValue) {
            setDomainMismatch(Validator.isEmailDomainMismatch(emailValue, currentUserEmail));
        }
    }, [emailValue, currentUserEmail]);

    const handleInputChange = (e) => {
        setEmailValue(e.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!emailValue) {
            return;
        }

        onUpdateOrCreate(event, emailValue)
    };

    return (
        <React.Fragment>
            <Headline>{t("additionalEmails.edit.headline")}</Headline>
            <form onSubmit={handleSubmit}>
                <Row>
                    <Col>
                        <Input
                            type="text"
                            name="email"
                            isValid={isValid}
                            label={t("additionalEmails.email.label")}
                            value={emailValue}
                            onChange={handleInputChange}
                            required
                        />
                        {domainMismatch && (
                            <InputWarning
                                message={t("additionalEmails.domain.warning")}
                            />
                        )}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div style={{justifyContent: 'space-between', display: 'flex', marginTop: '20px'}}>
                            <AbortButton onClick={onClose}>{t("additionalEmails.action.abort")}</AbortButton>
                            <Button
                                type="submit">{t("additionalEmails.action.update")}</Button>
                        </div>
                    </Col>
                </Row>
            </form>
        </React.Fragment>
    );
};

function mapDispatchToProps(dispatch) {
    return {
        ...bindActionCreators({openAlertDialog}, dispatch),
    };
}

export default compose(
    connect(null, mapDispatchToProps),
    withTranslation()
)(AdditionalEmailForm);
