import {productUpdateService} from "./Services/product-update-service"
import {targetServiceHandler} from "./Services/target-service"
import {userManagementServiceHandler} from "./Services/user-management-service"
import {taskExecutionHandler} from "./Services/task-execution-service"
import {taskServiceHandler} from "./Services/task-service"

export const handlers = [
    ...productUpdateService,
    ...targetServiceHandler,
    ...taskServiceHandler,
    ...taskExecutionHandler,
    ...userManagementServiceHandler
]
