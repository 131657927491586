export class ArrayHelper {
    static Contains(haystack, needle) {
        if (!haystack)
            return false;
        if (!Array.isArray(haystack))
            return false;


        return haystack.includes(needle);
    }

    static Add(haystack, hay) {
        return [...haystack, hay];
    }

    static Remove(haystack, needle) {

        haystack = [...haystack];

        while (haystack.indexOf(needle) > -1) {
            const index = haystack.indexOf(needle);
            if (index > -1) {
                haystack.splice(index, 1);
            }
        }

        return haystack;

    }
}