import React, {useContext} from "react"
import styled, {css} from "styled-components"
import {Bold, FlexRow} from "@greenbone/cloud-component-library"

import BillIcon from "./assets/bill.svg"
import CheckPaymentsIcon from "./assets/check-payment-give.svg"
import ChecklistIcon from "./assets/checklist.svg"
import StartupLaunchIcon from "./assets/startup-launch.svg"
import Checkmark from "./assets/checkmark.svg"
import {SubscriptionContext, SubscriptionStep} from "../SubscriptionProvider"
import {ShadeBlendColor} from "./ShadeBlendColors"
import {Col, Row} from "reactstrap"
import {useTranslation} from "react-i18next"

const Circle = styled.div`
  background-color: #F5F5F5;
  border-radius: 50%;
  height: 5.5rem;
  width: 5.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #aaa;


 ${props => props.done && css`
    background-color: ${ShadeBlendColor(props.theme.base, 8)};
    color: ${props.theme.base};
  `}

  ${props => props.active && css`
    background-color: ${ShadeBlendColor(props.theme.base, 8)};
    color: ${props.theme.base};
  `}
`;

const CircleText = styled(Bold)`

    display: flex;
    align-items: center;
    margin: 0;
    position: absolute;
    color: #aaa;
    top: 9rem;

    ${props => props.active && css`
      color: ${props.theme.base};
    `}

    ${props => props.done && css`
      color: ${props.theme.base};
    `}
    div {
        text-align: center;
        max-width: 5.5rem;
    }
`;

const Line = styled.div`
  background: #AAAAAA;
  height: 2px;
    flex-grow: 1;
`;

const ShortLineRight = styled(Line)`
  margin-right:  1rem;
`;

const ShortLineLeft = styled(Line)`
  margin-left: 1rem;
`;

const Outer = styled.div`
  height: 8rem;
  width: 100%;
  margin-top: 3.125rem;
  margin-bottom: 5.3125rem;
`;

export function SlideNavigation(props) {
    const {currentStep, finishedSteps} = useContext(SubscriptionContext);
    const {t} = useTranslation()

    return <Row>
        <Col xs={7}>
            <Outer>
                <FlexRow alignItems={"center"} justifyContent={"space-between"}>
                    <Circle active={currentStep === SubscriptionStep.Plan}
                            done={finishedSteps.includes(SubscriptionStep.Plan)}>
                        <ChecklistIcon style={{width: "3rem", height: "3rem"}}/>

                        <CircleText active={currentStep === SubscriptionStep.Plan}
                                    done={finishedSteps.includes(SubscriptionStep.Plan)}>
                            <div>
                                {t("slideNavigation.step.limit")}
                            </div>

                            {finishedSteps.includes(SubscriptionStep.Plan) && <Checkmark style={{height: "1rem"}}/>}
                        </CircleText>
                    </Circle>
                    <ShortLineLeft/>
                    <Circle active={currentStep === SubscriptionStep.Address}
                            done={finishedSteps.includes(SubscriptionStep.Address)}>
                        <BillIcon style={{width: "3rem", height: "3rem"}}/>
                        <CircleText active={currentStep === SubscriptionStep.Address}
                                    done={finishedSteps.includes(SubscriptionStep.Address)}>
                            <div>
                                {t("slideNavigation.step.address")}
                            </div>


                            {finishedSteps.includes(SubscriptionStep.Address) && <Checkmark style={{height: "1rem"}}/>}
                        </CircleText>
                    </Circle>
                    <Line/>
                    <Circle active={currentStep === SubscriptionStep.Payment}
                            done={finishedSteps.includes(SubscriptionStep.Payment)}>
                        <CheckPaymentsIcon style={{width: "3rem", height: "3rem"}}/>
                        <CircleText active={currentStep === SubscriptionStep.Payment}
                                    done={finishedSteps.includes(SubscriptionStep.Payment)}>
                            <div>{t("slideNavigation.step.payment")}</div>
                            {finishedSteps.includes(SubscriptionStep.Payment) && <Checkmark style={{height: "1rem"}}/>}
                        </CircleText>
                    </Circle>
                    <ShortLineRight/>
                    <Circle active={currentStep === SubscriptionStep.Confirm}
                            done={finishedSteps.includes(SubscriptionStep.Confirm)}>
                        <StartupLaunchIcon style={{width: "3rem", height: "3rem"}}/>
                        <CircleText active={currentStep === SubscriptionStep.Confirm}
                                    done={finishedSteps.includes(SubscriptionStep.Confirm)}>
                            <div>{t("slideNavigation.step.confirm")}</div>
                            {finishedSteps.includes(SubscriptionStep.Confirm) && <Checkmark style={{height: "1rem"}}/>}
                        </CircleText>
                    </Circle>
                </FlexRow>
            </Outer>
        </Col>
    </Row>;


}
