import React from "react"
import {useTranslation} from "react-i18next"
import {Table} from "@greenbone/cloud-dynamic-table"
import {Spinner, useRequest} from "@greenbone/cloud-component-library"
import {UserRestApiClient} from "../service/UserRestApiClient"
import {InvoiceTableRow} from "./InvoiceTableRow"
import {createGlobalStyle} from "styled-components"


const Global = createGlobalStyle`
    table.InvoiceTable {
        thead tr th:nth-child(2) {
            text-align: right;
        }

        thead tr th:last-child {
            text-align: right;
        }
    }
`


export function InvoiceTable(props) {

    const apiClient = new UserRestApiClient()
    const [data, loading, error] = useRequest(apiClient.getInvoices.bind(apiClient))
    const {t} = useTranslation()

    const columns = [
        {
            Header: t("invoiceTable.runtime"),
            id: "runtime",
            accessor: row => row.startAt?.isSIODateTime ? row.startAt.toIsoString() : ""
        },
        {
            Header: t("invoiceTable.amount"),
            id: "amount",
            accessor: row => row.amount
        },
        {
            Header: t("invoiceTable.paymentMethod"),
            id: "paymentOption",
            accessor: row => row.paymentMethod
        },
        {
            Header: t("invoiceTable.download")
        }

    ]

    if (loading) {
        return <Spinner/>
    }

    if (error) {
        throw error
    }

    return <>
        <Global/>
        <Table actions={<div></div>}
               defaultSortBy={[{id: "runtime", desc: true}]}
               className={"InvoiceTable"}
               cacheKey={"InvoiceTable/2021.1.1"}
               data={data}
               columns={columns}
               entryGenerator={InvoiceTableRow}/>
    </>

}
