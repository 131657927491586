//@flow

import MaterialUITextField from "@material-ui/core/TextField";
import React from "react";


class CustomTextFieldComponent extends React.Component<any, {}> {

    static defaultProps = {
        inputProps: {maxLength: "255"},
    };

    render() {

        return (
            <MaterialUITextField {...this.props}  >
                {this.props.children}
            </MaterialUITextField>
        );
    }
}

export const TextField = CustomTextFieldComponent;
