import React from "react";
import {TerminateSubscription} from "./TerminateSubscription";


export function TerminationPage(props) {


    return <>
        <TerminateSubscription/>
    </>;
}
