import {rest} from "msw"
import Chance from "chance"
import {buildUrl} from "../buildUrl"

const chance = new Chance(Math.random)


export const taskServiceHandler = [
    // rest.get(buildUrl("/tasks"), (req, res, context) => {
    //     return res(
    //         context.status(503),
    //         context.json({message: "Kaputt"})
    //     )
    // })
]
