/*  TODO - jwerremeyer
    Refactor DateTime formatting
    As soon as the designer commits a standard way of displaying time information
    trow that into its own library and remove all formatting from this project.

    The current implementation is merely a temporary solution

 */

import React, {useContext, useEffect, useState} from "react"
import styled from "styled-components"
import {FlexColumn, FlexRow, Headline} from "@greenbone/cloud-component-library"
import {ReportContext} from "../ReportContext"
import {ReportHelper} from "../ReportHelper"
import {SIODateTime} from "../../../controller/SIODateTime"
import humanizeDuration from "humanize-duration"
import {useTranslation} from "react-i18next"
import {AUTO_UPDATE_TIME} from "../../../constants"
import {ReportFilter} from "../ReportFilter";
import {useLocation} from "react-router";

const SeverityBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 3px;
  width: 3.75rem;
  height: 3.75rem;
  color: white;
  font-size: 1.675rem;
  margin-right: 1.5rem;
  background: ${props => ReportHelper.SeverityToColor(props.severity)};
`


// const a = {
//     "id": 23871,
//     "taskId": 74,
//     "taskExecutionId": 394,
//     "taskName": "Training Network",
//     "status": "DONE",
//     "high": 1353,
//     "medium": 1638,
//     "low": 205,
//     "log": 28612,
//     "severity": 10.0,
//     "progress": 100.0,
//     "scanStart": "2020-03-16T15:29:01+0000",
//     "scanEnd": "2020-03-16T17:08:16+0000",
//     "archived": false,
//     "errors": []
// }

export function RunningFor({startDateTime}) {
    const [date, setDate] = useState(SIODateTime.local().dateTime.diff(startDateTime.dateTime))
    const {i18n} = useTranslation()

    useEffect(() => {
        const interval = setInterval(() => {
            setDate(SIODateTime.local().dateTime.diff(startDateTime.dateTime))
        }, AUTO_UPDATE_TIME)
        return () => clearInterval(interval)
    }, [startDateTime])
    return humanizeDuration(date.get("milliseconds"), {round: true, language: i18n.language})

}

export function ReportHead(props) {
    const {head} = useContext(ReportContext)
    const {pathname} = useLocation()
    const {t, i18n} = useTranslation()
    const { filter } = useContext(ReportContext)

    if (!head) {
        return null
    }

    let duration = SIODateTime.local().dateTime.diff(head.scanStart.dateTime)

    if (head.scanEnd) {
        duration = head.scanEnd.dateTime.diff(head.scanStart.dateTime)
    }

    const humanDuration = humanizeDuration(duration.get("milliseconds"), {round: true, language: i18n.language})
    const items = [];
    if (filter.solutionTypes.length !== 0) items.push(t("reportHead.filter.solution"));
    if (filter.ports.length !== 0) items.push(t("reportHead.filter.port"));
    if (filter.operatingSystems.length !== 0) items.push(t("reportHead.filter.os"));
    if (filter.hostnames.length !== 0) items.push(t("reportHead.filter.hostname"));
    if (filter.hosts.length !== 0) items.push(t("reportHead.filter.hosts"));

    return <FlexRow style={{marginBottom: "3rem"}} {...props}>
        <SeverityBox severity={head.severity}>{head.severity}</SeverityBox>
        <FlexColumn justifyContent={"space-between"}>
            <Headline style={{margin: 0}}>
                {head.taskName}
            </Headline>
            <FlexColumn style={{paddingLeft: "2px"}}>
                <div>{t("reportHead.scanStart")} {head.scanStart.toDateString()}, {head.scanStart.toTimeString()}</div>
                {head.scanEnd ?
                    <>
                        <div>{t("reportHead.scanEnd")} {head.scanEnd.toDateString()}, {head.scanEnd.toTimeString()}</div>
                        <div>{t("reportHead.scanDuration")} {humanDuration} </div>
                    </> :
                    <div>
                        {t("reportHeader.scanningFor")} <RunningFor startDateTime={head.scanStart}/>
                    </div>
                }
                {pathname !== "/ui/gsp/report/dashboard" && (
                    <>
                        <div>
                            <ReportFilter/>
                        </div>
                        <div>
                            <span style={{ marginRight: '2.5rem' }}>
                                {t("reportHead.filter.qod")} {filter.minimalQod}%-{filter.maximalQod}%
                            </span>
                            <span>{t("reportHead.filter.severity")} {filter.minimalSeverity}-{filter.maximalSeverity}</span>
                        </div>

                        {items.length > 0 && (
                            <div>
                                {t("reportHead.filter.active")}: {items.join(', ')}
                            </div>
                        )}
                    </>
                )}
            </FlexColumn>
        </FlexColumn>
    </FlexRow>
}
