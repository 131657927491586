import {RestApiClient} from "@greenbone/cloud-component-library"
import {globalInstanceStorage} from "../../GlobalSingle"
import HttpStatuscode from "http-status-codes";
import {ForbiddenError} from "../services/Exceptions";
import i18next from "i18next";

function ErrorHandler(e) {
    switch (e.status) {
        case HttpStatuscode.FORBIDDEN:
            throw new ForbiddenError(i18next.t("components.error.boundary.noPermission"))
        default:
            throw e
    }
}

export class UserUrlProvider {
    getBillingAddress() {
        return `/usermanagement/group/company_address`
    }
}

export class GroupRestApiClient {
    constructor(
        apiClient = new RestApiClient(
            globalInstanceStorage.getFetchClient(),
            ErrorHandler
        )) {
        this.apiClient = apiClient
        this.urlProvider = new UserUrlProvider()
    }

    async getCountryCodes() {
        return await this.apiClient.get(`/usermanagement/public/countries`)
    }

    async getBillingAddress() {
        return await this.apiClient.get(this.urlProvider.getBillingAddress())
    }

    async setBillingAddress(address) {
        return await this.apiClient.put(this.urlProvider.getBillingAddress(), address)
    }


    async getSettings() {
        return await this.apiClient.get("/usermanagement/group/settings")
    }

    async setSetting(settings) {
        return await this.apiClient.put("/usermanagement/group/settings", settings)
    }
}
