//@flow

export const AlertBarType = {
    Success: "success",
    Error: "error",
    Warning: "warning",
    Info: "info"
};

export const InformationBarType = AlertBarType;
