import HttpStatuscode from "http-status-codes"
import {
    ConflictError,
    FailedDependencyError,
    ForbiddenError,
    InvalidStatusCodeError,
    NotFoundError
} from "../../Exceptions"
import {TaskExecutionUrlProvider} from "./TaskExecutionUrlProvider"
import {globalInstanceStorage} from "../../../../GlobalSingle"
import {FetchClient} from "@greenbone/cloud-component-library"
import {i18n} from "../../../../i18n"


export class TaskExecutionRestApiClient {
    urlProvider: TaskExecutionUrlProvider
    httpJsonClient: FetchClient

    constructor(apiClient = globalInstanceStorage.getFetchClient()) {
        this.httpJsonClient = apiClient
        this.urlProvider = new TaskExecutionUrlProvider()
    }

    async startTask(taskId: string): Promise<void> {
        const response = await this.httpJsonClient.put(this.urlProvider.startTask(taskId))

        if (response.status === HttpStatuscode.ACCEPTED) {
            return
        } else if (response.status === HttpStatuscode.FAILED_DEPENDENCY) {
            throw new FailedDependencyError("")
        } else if (response.status === HttpStatuscode.CONFLICT) {
            throw new ConflictError(i18n.t("common.taskExecutionClient.startFailed.invalidData"))
        } else if (response.status === HttpStatuscode.NOT_FOUND) {
            throw new NotFoundError(i18n.t("common.taskExecutionClient.startFailed.notFound"))
        } else if (response.status === HttpStatuscode.FORBIDDEN) {
            throw new ForbiddenError(i18n.t("components.error.boundary.noPermission"))
        }
        throw new InvalidStatusCodeError(response.status)
    }

    async stopTask(taskId: string): Promise<void> {
        const response = await this.httpJsonClient.delete(this.urlProvider.stopTask(taskId))

        if (response.status === HttpStatuscode.ACCEPTED || response.status === HttpStatuscode.NO_CONTENT) {
            return
        } else if (response.status === HttpStatuscode.CONFLICT) {
            throw new ConflictError(i18n.t("common.taskExecutionClient.stopFailed.invalidData"))
        } else if (response.status === HttpStatuscode.NOT_FOUND) {
            throw new NotFoundError(i18n.t("common.taskExecutionClient.stopFailed.notFound"))
        } else if (response.status === HttpStatuscode.FORBIDDEN) {
            throw new ForbiddenError(i18n.t("components.error.boundary.noPermission"))
        }
        throw new InvalidStatusCodeError(response.status)
    }

    async getTaskExecutionStatus(taskId: string): Promise<GSPTaskExecutionStatus> {
        const response = await this.httpJsonClient.get(this.urlProvider.taskExecutionStatus(taskId))
        if (response.status === HttpStatuscode.OK) {
            return await response.json()
        } else if (response.status === HttpStatuscode.CONFLICT) {
            throw new ConflictError(i18n.t("common.taskExecutionClient.updateFailed.invalidData"))
        } else if (response.status === HttpStatuscode.NOT_FOUND) {
            throw new NotFoundError(i18n.t("common.taskExecutionClient.updateFailed.notFound"))
        } else if (response.status === HttpStatuscode.FORBIDDEN) {
            throw new ForbiddenError(i18n.t("components.error.boundary.noPermission"))
        }
        throw new InvalidStatusCodeError(response.status)
    }

    async getLatestTaskExecutions(): Promise<Array<GSPTaskExecutionStatus>> {
        const response = await this.httpJsonClient.get(this.urlProvider.taskExecutionLatest())
        if (response.status === HttpStatuscode.OK) {
            return await response.json()
        } else if (response.status === HttpStatuscode.FORBIDDEN) {
            throw new ForbiddenError(i18n.t("components.error.boundary.noPermission"))
        }
        throw new InvalidStatusCodeError(response.status)
    }
}
