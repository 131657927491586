//@flow

class HostValidationUrlProvider {

    hostValidationReset(hostId: string) {
        return `/host-validations/contact-notifications/${hostId}/reset_workflow`
    }

    hostValidationEmailResend(hostId: string) {
        return `/host-validations/contact-notifications/${hostId}/resend_email`
    }

    hostValidation(hostId: string) {
        return `/host-validations/ripe-entry-management/${hostId}/`
    }

    hostValidationStatus(hostId: string) {
        return `/host-validations/ripe-entry-management/${hostId}/status`
    }

    hostValidationContacts(hostId: string) {
        return `/host-validations/ripe-entry-management/${hostId}/contacts`
    }

    hostValidationContactForHost(hostId: string, contactId: string) {
        return `/host-validations/ripe-entry-management/${hostId}/start/contacts/${contactId}`
    }

    hostValidationAdminContactForHost(hostId: string) {
        return `/host-validations/ripe-entry-management/${hostId}/start/admin`
    }

    hostValidationContactRequest(targetId: string, hostId: string) {
        return `/hostvalidation/target/${targetId}/host/${hostId}`
    }


    hostValidationUser(hostId: string) {
        return `/host-validations/ripe-entry-management/${hostId}/start/user`
    }
}

export {HostValidationUrlProvider}
