import React, {useContext, useEffect, useState} from "react"
import {useNavigate} from "react-router"
import {Headline, Subheadline} from "@greenbone/cloud-component-library"
import {Col, Row} from "reactstrap"
import {useTranslation} from "react-i18next"
import {SlideNavigation} from "../components/SlideNavigation"
import {SubscriptionContext, SubscriptionStep} from "../SubscriptionProvider"
import {AmountForm} from "../components/AmountForm"
import {SubscriptionNavigationUrlProvider} from "../SubscriptionNavigationUrlProvider"
import {PricingTable} from "./components/PricingTable"
import ErrorBoundary from "../../../Components/ErrorBoundary"


export function PlanPage(props) {
    const navigate = useNavigate()

    const {t} = useTranslation()
    const {setStep} = useContext(SubscriptionContext)
    const [ipsInternal, setipsInternal] = useState(0)
    const [ipsExternal, setipsExternal] = useState(0)


    useEffect(() => {
        setStep(SubscriptionStep.Plan)
    }, [setStep])

    const handleChange = (amounts) => {
        //ipsInternal, ipsExternal
        setipsExternal(amounts?.ipsExternal)
        setipsInternal(amounts?.ipsInternal)
    }

    return <>
        <Row>
            <Col>
                <Headline>
                    {t("planPage.selectAmountAndDistribution")}
                </Headline>
            </Col>
        </Row>
        <Row>
            <Col>
                <SlideNavigation/>
            </Col>
        </Row>

        <Row>
            <Col>
                <Subheadline>{t("amountForm.changeDistribution")}</Subheadline>
                <AmountForm showNext={false} onAmountChange={handleChange}/>
            </Col>
        </Row>
        <Row>
            <Col xs={7}>
                <ErrorBoundary>
                    <PricingTable showNext={true} extern={ipsExternal} intern={ipsInternal} next={(intern, extern) => {
                        navigate({
                            pathname: SubscriptionNavigationUrlProvider.address(),
                            search: `?${new URLSearchParams({
                                intern,
                                extern
                            }).toString()}`
                        })
                    }}/>
                </ErrorBoundary>
            </Col>
        </Row>

    </>
}
