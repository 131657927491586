import {withTheme} from "@material-ui/core"
import React from "react"
import {withTranslation} from "react-i18next"
import {connect} from "react-redux"
import {bindActionCreators, compose} from "redux"
import styled from "styled-components"
import {openAlertDialog} from "../../../Components/Dialogs/AlertDialog/actions"
import Loader from "../../../Components/Loader/Loader"
import {loaderType} from "../../../Components/Loader/LoaderType"
import {Logger} from "../../../controller/loggingController"
import {HostValidationRestApiClient} from "../../../services/apiClients/HostValidation/HostValidationRestApiClient"
import {TargetRestApiClient} from "../../../services/apiClients/Target/TargetRestApiClient"
import {
    BadRequestError,
    ConflictError,
    ForbiddenError,
    MethodNotAllowedError,
    NotFoundError,
    ServiceNotAvailableError,
    UnauthorizedError,
    UnprocessableEntityError
} from "../../../services/Exceptions"
import {HostValidationRow} from "./HostValidationRow"
import {HostValidationWizard} from "./HostValidationWizard"


const Box = styled.div`
  display: grid;
  grid-template-columns: 25% 33% 40% ;
  margin-bottom: 10px;
  height: 50px;
`

type Props = {
    theme: any;
    hostId: string;
    targetId: string;
    onChange: any;
    openAlertDialog: typeof openAlertDialog;
    representation: string;
    t: any;
}

type State = {
    host: ?GSPHost;
    _loading: boolean;
    _exception: any;
    _isOpen: boolean;
    _handlingHostValidationStartPost: boolean;
}

class _HostValidationEntry extends React.Component<Props, State> {
    targetRestApiClient: TargetRestApiClient;
    hostValidationRestClient: HostValidationRestApiClient;
    timer;

    state = {
        host: null,
        _loading: false,
        _exception: null,
        _isOpen: false,
        _handlingHostValidationStartPost: false
    };

    constructor(props) {
        super(props);
        this.hostValidationRestClient = new HostValidationRestApiClient();
        this.targetRestApiClient = new TargetRestApiClient();
    }

    handleDrawerClose = () => {
        this.setState({_isOpen: false});
        this.loadHostFromTargetService();
    };

    componentDidMount() {
        this.setState({_loading: true});
        this.loadHostFromTargetService();
        this.timer = window.setInterval(() => this.loadHostFromTargetService(true), 5000);
    }

    componentWillUnmount(): void {
        window.clearInterval(this.timer);
    }

    loadValidationStatus = async (hostId: string): Promise<any> => {
        return await this.hostValidationRestClient.getHostValidationStatus(hostId)
            .then((response) => {
                return {"status": response.replace(/"/g, "")};
            }).catch(_exception => {
                Logger.warn("GetHostValidationStatus failed, falling back to TargetService data");
            });
    };

    loadHostFromTargetService = (silent = false) => {
        if(!silent)
            this.setState({_loading: true});
        this.targetRestApiClient.getOne(this.props.targetId)
            .then((target: GSPTarget) => {
                target.includedHosts.forEach(host => {
                    if (host.id !== this.props.hostId) {
                        return;
                    }
                    this.loadValidationStatus(host.id)
                        .then((hostValidationStatus: { status: GSPValidationStatus }) => {
                            if (hostValidationStatus.status) {
                                host.validationStatus = hostValidationStatus.status;
                            }
                        })
                        .catch(error => {
                            Logger.warn("Host validation status could not be found. Falling back to target service data");
                        })
                        .finally(() => {
                            this.setState({host: host});
                        });
                });
            })
            .catch(_exception => {
                this._catchServerError(_exception);
            })
            .finally(() => {
                if(!silent)
                    this.setState({_loading: false});
            });
    };

    _catchServerError(error: any) {
        const {t} = this.props;
        switch (error.type) {
            case ServiceNotAvailableError:
            case NotFoundError:
            case UnprocessableEntityError:
            case ForbiddenError:
            case UnauthorizedError:
            case MethodNotAllowedError:
            case ConflictError:
            case BadRequestError: {
                this.props.openAlertDialog(t("common.messages.error"), error.message);
                break;
            }
            default: {
                this.setState({
                    _exception: error
                });
            }
        }
    }

    handleDrawerOpen = () => {
        this.setState({_isOpen: true});
    };

    render() {
        const {host, _exception, _loading, _isOpen} = this.state;
        const {targetId, hostId, representation} = this.props;

        if (_exception) {
            throw _exception;
        }

        if (_loading) {
            return <Loader type={loaderType.INLINE}/>;
        }

        if (!host) {
            return null;
        }

        return (
            <Box key={hostId}>
                <HostValidationRow onClick={this.handleDrawerOpen} validationStatus={host.validationStatus}
                                   representation={host.representation}/>

                <HostValidationWizard targetId={targetId}
                                      hostId={hostId}
                                      _isOpen={_isOpen}
                                      onClose={this.handleDrawerClose}
                                      representation={representation}/>
            </Box>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    let actions = bindActionCreators({
        openAlertDialog: openAlertDialog
    }, dispatch);
    return {...actions, dispatch};
}

function mapStateToProps(state) {
    return {};
}

export const HostValidationEntry = compose(
    withTheme,
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps)
)(_HostValidationEntry);
