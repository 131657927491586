import {Badge, IconButton, Tooltip} from "@material-ui/core"
import {Assignment, Info} from "@material-ui/icons"
import React, {useContext, useEffect, useMemo, useState} from "react"
import {useTranslation, withTranslation} from "react-i18next"
import {useNavigate} from "react-router"
import {compose} from "redux"
import styled from "styled-components"
import ActionElements from "../../../Components/ActionElements"
import StateLabel from "../../../Components/Label/StateLabel"
import {SimpleEntityContext} from "../../../services/Context/SimpleEntityContext"
import {EntityUrlProvider} from "../../../UrlProvider/EntityUrlProvider"
import {ValidationStatus} from "../../Hostvalidation/types/Host"
import {DynamicTable, DynamicTableCell, DynamicTableRow} from "../../Task/components/Table/DynamicTable"
import {TargetDetails} from "./TargetDetails"
import {isAnyHostRejected, isEveryHostVerified} from "./TargetEntry"


const HostVerified = (props: { target: GSPTarget, includedHosts: Array<GSPHost> }) => {
    const areAllVerified: boolean = isEveryHostVerified(props.includedHosts)
    const isAnyRejected: boolean = isAnyHostRejected(props.includedHosts)
    const {t} = useTranslation()

    let stateColor = "primaryBlueLight2"
    let stateLabel = t("targetEntry.verificationPending")

    if (isAnyRejected) {
        stateColor = "stateColorRed1"
        stateLabel = t("targetEntry.verificationRejected")
    } else if (areAllVerified) {
        stateColor = "primaryBlueLight1"
        stateLabel = t("targetEntry.verified")
    }

    return (
        <DynamicTableCell>
            {(!props.target.isInternal) &&
                <StateLabel colorState={stateColor}>
                    {stateLabel}
                </StateLabel>
            }
        </DynamicTableCell>
    )
}

const StyledBadge = styled(Badge)`
    .MuiBadge-colorPrimary {
        background-color: ${props => props.theme.button.normal.background};
    }

`

const _ValidationInformation = (props: {
    includedHosts: Array<GSPHost>,
    elementCount: number,
    onclick: ()=>void,
    t: any
}) => {
    const areAllVerified: boolean = isEveryHostVerified(props.includedHosts)
    const isAnyRejected: boolean = isAnyHostRejected(props.includedHosts)
    const {t} = props

    let title = t("targetEntry.validationPending")
    if (isAnyRejected) {
        title = t("targetEntry.validationRejected")
    }

    return <span>{!areAllVerified && (
        <Tooltip title={title}>
            <IconButton
                onClick={() => props.onclick()}
                style={{display: "inline-block"}}>
                <StyledBadge badgeContent={props.elementCount} color="primary" overlap="rectangular">
                    <Assignment/>
                </StyledBadge>
            </IconButton>
        </Tooltip>
    )}
    </span>
}

const ValidationInformation = compose(
    withTranslation()
)(_ValidationInformation)

export function TargetList({actions, onUpdateEntry, isInternal, cacheKey}) {
    const {targets, deleteTarget, updateTargets} = useContext(SimpleEntityContext)
    const data = React.useMemo(() => targets.filter(target => target.isInternal === isInternal), [targets, isInternal])

    const memoizedUpdateTargets = useMemo(() => updateTargets, [updateTargets])

    useEffect(() => {
        memoizedUpdateTargets()
    }, [memoizedUpdateTargets])

    const {t} = useTranslation()

    let columns = []
    if (isInternal) {
        columns = [
            {
                Header: t("task.header.name.name"),
                accessor: "name"
            },
            {
                Header: t("target.details.portList"),
                id: "portList",
                accessor: row => {
                    return row?.portList?.name
                }
            },
            {
                Header: t("target.components.list.ip"),
                accessor: "ipAddressAmount"
            },
            {
                Header: t("hostValidation.components.validate.action"),
                accessor: () => ""
            }

        ]
    } else {
        columns = [
            {
                Header: t("task.header.name.name"),
                accessor: "name"
            },
            {
                Header: t("target.details.portList"),
                id: "portList",
                accessor: row => {
                    return row?.portList?.name
                }
            },
            {
                Header: t("targetInformation.verified"),
                accessor: "includedHosts"
            },
            {
                Header: t("target.components.list.ip"),
                accessor: "ipAddressAmount"
            },

            {
                Header: t("hostValidation.components.validate.action"),
                accessor: () => ""
            }
        ]
    }

    const EntryGenerator = ({row, getValue, dataset}) => {
        const target = dataset

        const navigate = useNavigate()
        const [_isDetailsOpen, set_isDetailsOpen] = useState(false)

        const unverifiedHostCount = (target.includedHosts || [])
            .filter((host) => host.validationStatus !== ValidationStatus.APPROVED &&
                host.validationStatus !== ValidationStatus.REJECTED)
            .length

        const actionGenerator = () => {
            return (
                <IconButton onClick={() => {
                    set_isDetailsOpen(true)
                }}>
                    <Info/>
                </IconButton>
            )
        }

        return <DynamicTableRow {...row.getRowProps()}>
            <DynamicTableCell>
                {getValue("name")}
            </DynamicTableCell>
            <DynamicTableCell>
                {getValue("portList")}
            </DynamicTableCell>
            {!isInternal && <HostVerified target={target} includedHosts={target.includedHosts}/>}
            <DynamicTableCell>
                {getValue("ipAddressAmount")}
            </DynamicTableCell>
            <DynamicTableCell>
                <ActionElements
                    entity={target}
                    onUpdateEntry={() => onUpdateEntry(target.id, target.isInternal)}
                    deleteEntity={() => deleteTarget(target.id)}
                    confirmMessage={t("targetEntry.deleteMessage", {name: target.name})}
                    additionalActionGenerator={actionGenerator}
                >
                    <ValidationInformation
                        onclick={() => navigate(EntityUrlProvider.targetHostValidation(target.id))}
                        includedHosts={target.includedHosts}
                        elementCount={unverifiedHostCount}
                    />
                </ActionElements>
            </DynamicTableCell>
            <TargetDetails
                _isOpen={_isDetailsOpen}
                target={target}
                onClose={() => set_isDetailsOpen(false)}
            />
        </DynamicTableRow>
    }

    return <DynamicTable name={"targetList"}
                         data={data}
                         actions={actions}
                         entryGenerator={EntryGenerator}
                         columns={columns}
                         searchableColumns={["name", "target", "schedule", "scanConfiguration", "portList"]}
                         defaultSortBy={["name"]}
                         cacheKey={cacheKey}/>

}
