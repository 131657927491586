import React from "react"
import styled from "styled-components"
import {Bold, FlexRow, Headline, isAbsoluteUrl, Subheadline} from "@greenbone/cloud-component-library"
import {useTranslation} from "react-i18next"
import {Oid} from "./Oid"
import {NewLineParagraph} from "./NewLineParagraph"
import {VulnerabilitySolutionInfo} from "../VulnerabilitySolutionInfo"
import {DetailsBlock} from "../DetailsBlock"

export const Code = styled.pre`
    font-family: JetBrainsMono, monospace;
    white-space: pre-wrap;
    font-size: 0.9rem;

`


function LinkBlock({vulnerability, small}) {

    const {t} = useTranslation()



    const isAtLeastOneReferenceAvailable = vulnerability.cve?.length + vulnerability.bid?.length
        + vulnerability.cert?.length + vulnerability.xref?.length > 0


    if (isAtLeastOneReferenceAvailable) {
        return <DetailsBlock small={small} title={t("scanmanagement.links")}>
            {
                vulnerability.cve.length > 0 &&
                <>
                    <Bold>CVE:</Bold>
                    <ul>
                        {vulnerability.cve.map((item, i) => {
                            return <li key={i}>{item}</li>
                        })}
                    </ul>
                </>
            }
            {
                vulnerability.bid.length > 0 &&
                <>
                    <Bold>BID:</Bold>
                    <ul>
                        {vulnerability.bid.map((item, i) => {
                            return <li key={i}>{item}</li>
                        })}
                    </ul>

                </>
            }
            {
                vulnerability.cert.length > 0 &&
                <>
                    <Bold>CERT:</Bold>
                    <ul>
                        {vulnerability.cert.map((item, i) => {
                            return <li key={i}>{item}</li>
                        })}
                    </ul>

                </>
            }
            {
                vulnerability.xref.length > 0 &&
                <>
                    <Bold>OTHER:</Bold>
                    <ul>
                        {vulnerability.xref.map((item, i) => {
                            return <li key={i}>
                                {isAbsoluteUrl(item) ?
                                    <a target="_blank" rel="noopener noreferrer" href={item}>{item}</a>
                                    :
                                    <div>{item}</div>
                                }

                            </li>
                        })}
                    </ul>

                </>
            }
        </DetailsBlock>
    }

    return null
}


export function VulnerabilityDetails({vulnerability, small}) {
    const {t} = useTranslation()


    return <div style={{maxWidth: "60rem", minWidth: "40rem"}}>
        {small ? <Subheadline>{vulnerability.name}</Subheadline> : <Headline>{vulnerability.name}</Headline>}


        {vulnerability.summary &&
        <DetailsBlock small={small} title={t("scanmanagement.details.summary")}>
            <NewLineParagraph>
                {vulnerability.summary}
            </NewLineParagraph>
        </DetailsBlock>
        }
        {
            (vulnerability.description && vulnerability.description.length > 0) &&
            <DetailsBlock small={small} title={t("scanmanagement.details.result")}>
                <Code>
                    {vulnerability.description ?
                        vulnerability.description.length > 0 ?
                            vulnerability.description.join().replace(/\n+$/, "")
                            : "Vulnerability was detected according to the Detection Method"
                        : "Vulnerability was detected according to the Detection Method"
                    }
                </Code>
            </DetailsBlock>
        }

        {vulnerability.impact &&
        <DetailsBlock small={small} title={t("scanmanagement.details.impact")}>
            <NewLineParagraph>
                {vulnerability.impact}
            </NewLineParagraph>
        </DetailsBlock>
        }

        {vulnerability.solutionType &&
        <VulnerabilitySolutionInfo vulnerability={vulnerability} small={small}/>
        }

        {vulnerability.affected &&
        <DetailsBlock small={small} title={t("scanmanagement.affectedSoftware")}>
            <NewLineParagraph>
                {vulnerability.affected}
            </NewLineParagraph>
        </DetailsBlock>
        }

        {vulnerability.insight &&
        <DetailsBlock small={small} title={t("scanmanagement.insight")}>
            <NewLineParagraph>
                {vulnerability.insight}
            </NewLineParagraph>
        </DetailsBlock>
        }

        {vulnerability.vuldetect &&
        <DetailsBlock small={small} title={t("scanmanagement.detectionMethod")}>
            <NewLineParagraph>
                {vulnerability.name || ""} {vulnerability.oid || ""}
            </NewLineParagraph>
        </DetailsBlock>
        }

        <LinkBlock small={small} vulnerability={vulnerability}/>

        <FlexRow justifyContent={"flex-end"}>
            <Oid {...vulnerability}/>
        </FlexRow>
    </div>
}
