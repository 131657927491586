import * as HttpStatuscode from "http-status-codes";
import {Value} from "./Value";
import {ApiClient} from "./ApiClient";
import {ForbiddenError, InvalidStatusCodeError, NotFoundError} from "./Exceptions";

export class EntityRestApiClient extends ApiClient {
    httpJsonClient;
    urlProvider;


    constructor(urlProvider, fetchClient) {
        super();
        this.httpJsonClient = fetchClient;
        this.urlProvider = urlProvider;

    }

    /**
     * Prepare an Object for rest transport and JSON stringify
     *
     * - convert all `Date` to `string` with format `YYYY-MM-DDTHH:mmZZ`
     *
     * @param value any json object
     * @returns {*}
     */
    static prepareObject(value) {
        Object.keys(value)
            .forEach(item => {
                if (value[item] && value[item].isSIODateTime) {
                    value[item] = value[item].toIsoString();
                }
            });
        return value;
    }

    /**
     * Handle the response from the
     * @param response
     * @returns {Promise<{entity: *, status: Number}|{errors: *, status: Number}>}
     */
    async handleResponse(response) {
        if (Value(response.status).isInList([HttpStatuscode.CREATED, HttpStatuscode.OK, HttpStatuscode.NO_CONTENT])) {
            return {
                status: response.status,
                entity: this.parseJsonText(await response.text())
            };
        } else if (response.status === HttpStatuscode.BAD_REQUEST) {
            return {
                status: response.status,
                errors: this.parseJsonText(await response.text())
            };
        } else if (response.status === HttpStatuscode.NOT_FOUND) {
            throw new NotFoundError(("common.client.entityNotFound"));
        } else if (response.status === HttpStatuscode.FORBIDDEN) {
            throw new ForbiddenError(("components.error.boundary.noPermission"));
        } else {
            throw new InvalidStatusCodeError(response.status);
        }
    }

    /***
     * Request all elements of this type
     * @returns {Promise<{entity: *, status: Number}|{errors: *, status: Number}>}
     */
    async getAll() {
        const response = await this.httpJsonClient.get(this.urlProvider.getAll());
        return this.handleResponse(response);
    }

    /**
     * Fetches paginated data from the server based on specified criteria.
     *
     * This method retrieves data based on pagination settings and optional search and sorting parameters.
     * It handles the server response by delegating to `handleResponse` to ensure proper error handling and parsing.
     *
     * @param {Number} page - The current page number to fetch.
     * @param {Number} pageSize - The number of items per page.
     * @param {String} search - A search query to filter the data. Optional.
     * @param {String} sorting - The sorting order and field (e.g., 'name,asc').
     * @returns {Promise<{entity: Object, status: Number}|{errors: Object, status: Number}>}
     */
    async getAllPageable(page, pageSize, search, sorting) {
        const url = this.urlProvider.getAllPageable(); // Get the base URL
        const params = new URLSearchParams({
            page: page,
            size: pageSize,
            searchTerm: search,
            sort: sorting
        }).toString();

        const fullUrl = `${url}?${params}`;
        const response = await this.httpJsonClient.get(fullUrl);
        return this.handleResponse(response);
    }

    /**
     * Requests one entity matching the id of the type
     * @param id
     * @returns {Promise<{entity: *, status: Number}|{errors: *, status: Number}>}
     */
    async getOne(id) {
        const response = await this
            .httpJsonClient
            .get(this.urlProvider.getOne(id));

        return this.handleResponse(response);
    }

    /**
     * Create one entity of the type
     * @param entity
     * @returns {Promise<{entity: *, status: Number}|{errors: *, status: Number}>}
     */
    async createEntity(entity) {
        const response = await this
            .httpJsonClient
            .post(this.urlProvider.create(), EntityRestApiClient.prepareObject(entity));

        return this.handleResponse(response);
    }

    /**
     * Update the entity matching the id
     * @param entity
     * @returns {Promise<{entity: *, status: Number}|{errors: *, status: Number}>}
     */
    async updateEntity(entity) {
        const response = await this
            .httpJsonClient
            .put(this.urlProvider.update(entity.id), EntityRestApiClient.prepareObject(entity));

        return this.handleResponse(response);
    }

    /**
     * Delete one entity of type
     * @param entityId
     * @returns {Promise<{entity: *, status: Number}|{errors: *, status: Number}>}
     */
    async deleteEntity(entityId) {
        const response = await this
            .httpJsonClient
            .delete(this.urlProvider.delete(entityId));

        return this.handleResponse(response);
    }
}


