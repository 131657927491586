
import * as HttpStatuscode from "http-status-codes";


export class ConflictError extends Error {
    httpStatus = HttpStatuscode.CONFLICT;
    type = ConflictError;

    constructor(message = "") {
        super(`Conflict: '${message}'`);
    }
}
