import React from "react";
import {FlexRow} from "../Flex";
import SvgKeyboardArrowLeft from "../../../assets/icons/KeyboardArrowLeft";
import SvgKeyboardArrowRight from "../../../assets/icons/KeyboardArrowRight";
import styled from "styled-components";


const Select = styled.select`
  border: none;

  font-family: 'DroidSans', Arial, sans-serif;
`;

export function TablePagination({
                                    count,
                                    rowsPerPage,
                                    page,
                                    handleChangePage,
                                    handleChangeRowPerPage,
                                    defaultPageSize
                                }) {

    let to = rowsPerPage * (page + 1);
    if (to > count) {
        to = count;
    }


    let addOption = false;
    if (defaultPageSize && defaultPageSize !== 0) {
        addOption = !([5, 10, 15].includes(defaultPageSize));
    }


    return (

        <FlexRow alignItems={"center"} justifyContent={"flex-end"}>
            <span style={{marginRight: "0.625rem"}}>Rows per page:</span>
            <Select onChange={handleChangeRowPerPage} value={rowsPerPage} style={{marginRight: "10px"}} name="" id="">
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                {addOption && <option value={defaultPageSize}>{defaultPageSize}</option>}
            </Select>

            <span style={{marginRight: "0.625rem"}}>{rowsPerPage * page + 1}-{to} of {count}</span>

            <FlexRow>
                <SvgKeyboardArrowLeft onClick={(event) => handleChangePage(event, page - 1)}
                                      style={{width: "1.2rem", cursor: "pointer", marginRight: "0.625rem"}}/>
                <SvgKeyboardArrowRight onClick={(event) => handleChangePage(event, page + 1)}
                                       style={{width: "1.2rem", cursor: "pointer"}}/>
            </FlexRow>
        </FlexRow>
    );
}
