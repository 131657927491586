export class InstanceStorage {
    keycloak = null;
    fetchClient = null;

    updateKeycloak(keycloakInstance) {
        this.keycloak = keycloakInstance;
    }

    updateFetchClient(fetchInstance) {
        this.fetchClient = fetchInstance;
    }

    getKeycloak() {
        return this.keycloak;
    }

    getFetchClient() {
        return this.fetchClient;
    }
}
