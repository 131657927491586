import styled from "styled-components";
import {FormControl, InputLabel, MenuItem as MaterialMenuItem, Select as MaterialSelect, FormHelperText} from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

const StyledSelect = styled(MaterialSelect)`
            &.MuiInput-underline::after,
            &.MuiFilledInput-underline::after {
                color: ${props => props.theme.input.borderBottomColor};;
                border-color: ${props => props.theme.input.borderBottomColor};;



                &:after{
                border-color: ${props => props.theme.input.borderBottomColor};
            }

                &:hover {
                border-bottom: none;
            }

                &:hover:not(:disabled):before{
                border-color: ${props => props.theme.input.borderBottomColor};
            }

            }
            `;

const InnerSelect = ({isValid, helperText, ...props}) => {

    if (isValid === true || isValid === null || isValid === undefined) {
        return <><StyledSelect {...props}/> <FormHelperText>{helperText}</FormHelperText> </>;
    }

    if (typeof isValid === "string" || React.isValidElement(isValid)) {
        helperText = isValid;
    }

    if (Array.isArray(isValid)) {
        helperText = <>{isValid.map(message =>
            (<span style={{marginBottom: "5px", display: "block"}} key={message}>
                    {message}
                </span>
            ))
        }</>;
    }

    return <> <StyledSelect error {...props}/>
        <FormHelperText error>{helperText}</FormHelperText>
    </>;
};

const StyledLabel = styled(InputLabel)`

            &.Mui-focused {
                color: ${props => props.theme.input.label} !important;
            }
            `;


export const Option = styled(MaterialMenuItem)``;


export function Select(props) {
    let {label, margin, ...other} = props;

    return (
        <FormControl variant="standard" margin={margin}>
            <StyledLabel>{label}</StyledLabel>
            <InnerSelect  {...other} />
        </FormControl>
    );
}

Select.propTypes = {
    label: PropTypes.node,
    onChange: PropTypes.func,
    name: PropTypes.string,
    children: PropTypes.node,
    value: PropTypes.any
};
