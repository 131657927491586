// @flow

import {TargetRestApiClient} from "../../../services/apiClients/Target/TargetRestApiClient";
import {TaskRestApiClient} from "../../../services/apiClients/Task/TaskRestApiClient";
import type {TargetDTO} from "../../Target/types/TargetDTO";
import type {TaskDTO} from "../../Task/types/TaskDTO";


type StepStore = {
    +Task: {
        +name: string;
        +comment: string;
        +scanConfigurationId: number;
    },
    +Target: {
        +targetId: string;
    },
    +Schedule: {
        +scheduleId: number;
    },
    +Credential: {
        +selectedWindowsCredentialId: ?string;
        +selectedSSHCredentialId: ?string;
        +selectedSSHCredentialPort: number;
        +selectedVMWareCredentialId: ?string;
    }
}

export class WizardController {
    +data: StepStore;
    taskRestApiClient: TaskRestApiClient;
    targetRestApiClient: TargetRestApiClient;

    constructor(data: StepStore) {
        this.data = data;
        this.taskRestApiClient = new TaskRestApiClient();
        this.targetRestApiClient = new TargetRestApiClient();
    }

    async updateTarget(targetId: string) {
        const target: GSPTarget = await this.targetRestApiClient.getOne(targetId);

        const newTarget: TargetDTO = {
            id: target.id,
            name: target.name,
            comment: target.comment,
            includedHosts: target.includedHosts.map((host: GSPHost) => host.representation),
            excludedHosts: target.excludedHosts.map((host: GSPHost) => host.representation),
            aliveTestId: target.aliveTest.id,
            portListId: target.portList.id,
            credentials: {
                ESXI: this.data.Credential.selectedVMWareCredentialId,
                SSH: this.data.Credential.selectedSSHCredentialId,
                SMB: this.data.Credential.selectedWindowsCredentialId,
            },
            port: this.data.Credential.selectedSSHCredentialPort,
            isInternal: target.isInternal
        };

        await this.targetRestApiClient.updateEntity(newTarget);
    }

    async createAll() {
        const task: TaskDTO = {
            id: undefined,
            name: this.data.Task.name,
            comment: this.data.Task.comment,
            scanConfigurationId: this.data.Task.scanConfigurationId,
            targetId: this.data.Target.targetId,
            scheduleId: this.data.Schedule.scheduleId,
            //$FlowFixMe
            isInternal: this.data.Target.isInternal
        };
        await this.updateTarget(this.data.Target.targetId);
        return await this.taskRestApiClient.createEntity(task);
    }
}
