//@flow
import * as HttpStatuscode from "http-status-codes";


export class UnauthorizedError extends Error {
    httpStatus = HttpStatuscode.UNAUTHORIZED;
    type = UnauthorizedError;

    constructor(message: string = "Anmeldung fehlgeschlagen") {
        super(message);
    }
}
