import React from "react";
import {
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio as MaterialRadio,
    RadioGroup as MaterialRadioGroup
} from "@material-ui/core";
import styled from "styled-components";

const StyledMaterialRadio = styled(MaterialRadio)`
 &&.Mui-checked {
    color: ${props => props.theme.checkbox.color};
 }
`;

const StyledFormLabel = styled(FormLabel)`
  &&, &&.Mui-focused {color: ${props => props.theme.input.label}};
`;

const StyledFormControlLabel = styled(FormControlLabel)`

  &.MuiRadio-colorSecondary.Mui-checked:hover {
    background-color: greenyellow !important;
  }
 
`;


export function RadioGroup({label, children, name, onChange, value, row, ...props}) {
    return (
        <FormControl variant="standard" component={"fieldset"} {...props}>
            <StyledFormLabel component="legend">{label}</StyledFormLabel>
            <MaterialRadioGroup row={row} aria-label={label} name={name} value={value} onChange={onChange}>
                {children}
            </MaterialRadioGroup>
        </FormControl>
    );
}

export function Radio({value, label, ...props}) {
    return <StyledFormControlLabel control={<StyledMaterialRadio color={"default"}/>} value={value}
                                   label={label} {...props} />;
}



