//@flow
import React from "react";


export const ArrowLeftIcon = ({color}: { color: string }) => (
    <svg version="1.1" id="Light" xmlns="http://www.w3.org/2000/svg"
         x="0px" y="0px" viewBox="0 0 24 24" style={{enableBackground: "new 0 0 24 24"}} xmlSpace="preserve">
        <style type="text/css">
            {`.st0ArrowLeftIcon{fill: ${color};}`}
        </style>
        <g>
            <path className="st0ArrowLeftIcon" d="M18.4,24c-0.1,0-0.2,0-0.3-0.1L5.5,12.7c0,0-0.1-0.1-0.1-0.1c-0.4-0.4-0.3-1,0.1-1.4L18.1,0.1
    C18.2,0,18.3,0,18.4,0c0.1,0,0.3,0.1,0.4,0.2c0.2,0.2,0.2,0.5,0,0.7L6.2,12l12.6,11.1c0.2,0.2,0.2,0.5,0,0.7
    C18.7,23.9,18.5,24,18.4,24z"/>
        </g>
    </svg>
);
