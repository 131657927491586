
import * as HttpStatuscode from "http-status-codes";


export class NotAcceptableError extends Error {
    httpStatus = HttpStatuscode.NOT_ACCEPTABLE;
    type = NotAcceptableError;

    constructor(message = "") {
        super(`'${message}'`);
    }
}
