import styled, {css} from "styled-components"

export const SwitchButtonElement = styled.div`
    height: 2.25rem;
    box-sizing: border-box;
    padding-left: 1rem;
    padding-right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #c2c2c2;
    border: 1px solid #c2c2c2;
    width: 8rem;

    &:hover {
        cursor: pointer;
    }

    &:active {
        color: ${props => props.theme.button.normal.text};
        border: 1px solid ${props => props.theme.button.normal.background};
        background: ${props => props.theme.button.normal.background};
        z-index: 1;
        font-weight: bold;
    }

    ${props => props.active && css`
        color: ${props => props.theme.button.normal.text};
        border: 1px solid ${props => props.theme.button.normal.background};
        background: ${props => props.theme.button.normal.background};
        z-index: 1;
        font-weight: bold;
    `}
    &:last-child {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
    }

    &:first-child {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
    }

    &:nth-child(2) {
        margin-left: -1px;
        margin-right: -1px;
    }
`

export const SwitchButtonContainer = styled.div`
    display: flex;
    box-sizing: border-box;

`

