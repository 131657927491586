//@flow
import "regenerator-runtime/runtime"
import * as Sentry from "@sentry/react"
import "bootstrap/dist/css/bootstrap.css"
import React from "react"
import ReactDOM from "react-dom"
import {App} from "./app/App"
import "./app/App.css"
import {BASEURL, IS_API_MOCKED, IS_DEVELOP, IS_LOCAL, SENTRY_KEY, VERSION} from "./app/constants"
import "./i18n" // import i18n (needs to be bundled)
import {unregister} from "./registerServiceWorker"
import "./index.css"
import {AuthenticationWrapperFactory} from "./Auth/AuthenticationWrapper"
import {globalInstanceStorage} from "./GlobalSingle"
import {RocketLoader} from "./RocketLoader"
import {buildJumpInConfig, jumpInParameterFound} from "./jumpInHelper"
import {worker} from "./mocks/browser"
import {createElement, deleteElement, renderElement} from "./domHelper"
import "react-datepicker/dist/react-datepicker.css"


Sentry.init({
    dsn: SENTRY_KEY,
    attachStacktrace: true,
    enabled: !IS_LOCAL,
    release: VERSION,
    environment: IS_DEVELOP ? "DEV" : "PROD"
})

function setupMockApi() {
    if (IS_API_MOCKED) {
        if (window.location.pathname === "/ui") {
            window.location.pathname = "/ui/"
            return
        }

        const {worker} = require("./mocks/browser")
        worker.start({
            onUnhandledRequest: "warn",
            serviceWorker: {

                url: "/ui/mockServiceWorker.js"
            }
        })
    }


    if (!IS_API_MOCKED) {
        unregister()
    }
}


function main(jumpInConfig) {
//localhost:3000?jumptoken=123

    deleteElement(".error-app")

    const AuthenticationWrapper = AuthenticationWrapperFactory(BASEURL, globalInstanceStorage, false, RocketLoader, "/mssp-admin/public/config/realm", jumpInConfig)
    ReactDOM.render(<AuthenticationWrapper app={App}/>, document.getElementById('root'));
}


setupMockApi()

function renderJumpInAuthErrorMessage() {
    const root = document.getElementById("root")
    const Parent = createElement("div", {className: "error-app"})
    const Headline = createElement("h1", {content: "Jump in token is invalid"})
    const Message = createElement("p", {content: "lorem ipsum dolor site amet"})
    const LoginLink = createElement("a", {
        href: "/", content: "Normal Login", onClick: (e) => {
            event.preventDefault()
            main()
        }
    })
    renderElement(Headline, Parent)
    renderElement(Message, Parent)
    renderElement(LoginLink, Parent)
    renderElement(Parent, root)

}


if (jumpInParameterFound()) {
    buildJumpInConfig()
        .then(response => {
            main(response)
        })
        .catch(e => {
            console.error(e)
            renderJumpInAuthErrorMessage()
        })
} else {
    main()
}

