import React from "react"
import {withReportUrlParamParser} from "./ReportUrlParamReader"
import {ReportRestApiClient} from "./service/ReportRestApiClient"
import {ReportNavigationUrlProvider} from "./ReportNavigationUrlProvider"

export const Views = {
    GRID: "GRID",
    TABLE_HOSTS: "TABLE_HOSTS",
    TABLE_VULNERABILITIES: "TABLE_VULNERABILITIES",
    TABLE_OVERVIEW: "TABLE_OVERVIEW",
    GetActiveView: () => {
        if (window.location.pathname.includes(ReportNavigationUrlProvider.TableGroupHost())) {
            return Views.TABLE_HOSTS
        }

        if (window.location.pathname.includes(ReportNavigationUrlProvider.TableGroupName())) {
            return Views.TABLE_VULNERABILITIES
        }

        if (window.location.pathname.includes(ReportNavigationUrlProvider.Table())) {
            return Views.TABLE_OVERVIEW
        }

        return Views.GRID
    }
}

export const ReportContext = React.createContext({})


export class _ReportProvider extends React.Component {

    state = {
        error: false,
        activeView: Views.GRID,
        head: null,
        vulnerabilities: [],
        groupedHosts: [],
        groupedVulnerabilities: [],
        vulnerabilitiesTable: [],
        filter: {
            hosts: [],
            maximalQod: 0,
            maximalSeverity: 10,
            minimalQod: 0,
            minimalSeverity: 0,
            ports: [],
            solutionTypes: [],
            operatingSystems: [],
            hostnames: []
        },
        uniqueOperatingSystems: [],
        uniqueHostNames: [],
        uniqueHosts: [],
        uniquePorts: [],
        paging: {
            GRID: {
                pageIndex: 0,
                pageSize: 9,
                pageTotal: 0
            },
            TABLE_HOSTS: {
                pageIndex: 0,
                pageSize: 9,
                pageTotal: 0
            },
            TABLE_VULNERABILITIES: {
                pageIndex: 0,
                pageSize: 9,
                pageTotal: 0
            },
            TABLE_OVERVIEW: {
                pageIndex: 0,
                pageSize: 9,
                pageTotal: 0
            }
        },
        sorting: {
            field: "severity",
            direction: "DESC"
        },
        searchTerm: "",
        filterOpen: false,
        hasBeenFlushed: true
    }

    constructor(props) {
        super(props)
        this.reportRestApiClient = new ReportRestApiClient()
    }

    changeView = (view) => {
        this.setState({activeView: view})
    }

    makeDirty = () => {
        this.state({hasBeenFlushed: false})
    }

    getFilter = () => {
        return {
            ...this.state.filter, ...this.state.paging, ...{
                sortDirection: this.state.sorting.direction,
                sortField: this.state.sorting.field
            }
        }
    }

    assembleRequestBody = (paging, noSort) => {
        return {
            ...this.state.filter, ...{...paging, pageTotal: undefined}, ...{
                sortDirection: noSort ? undefined : this.state.sorting.direction,
                sortField: noSort ? undefined : this.state.sorting.field,
                searchTerm: this.state.searchTerm
            }
        }
    }

    loadData = async (noSort, request, View, stateFieldName, resetPage) => {

        const body = resetPage ?
            this.assembleRequestBody({...this.state.paging[View], pageIndex: 0}, noSort) :
            this.assembleRequestBody(this.state.paging[View], noSort)

        return request(this.props.report, body)
            .then(response => {
                if (response.status === "BAD_REQUEST") {
                    if (response.fieldErrors.sortField) {
                        this.update(true, View, stateFieldName)
                    }
                } else {
                    this.setState(prevState => ({
                        [stateFieldName]: response.data,
                        filter: response.filter,
                        sorting: response.sorting
                    }))
                    this.setPaging(response.paging, View)
                }
            })
            .catch(e => {
                console.error(e)
            })
            .finally(() => {
                return true
            })
    }

    update = async (noSort, resetPage, updateAll) => {

        this.setState({hasBeenFlushed: false})

        resetPage = !!resetPage
        const {activeView} = this.state

        if (updateAll) {
            await this.loadData(noSort, this.reportRestApiClient.filteredReportFull.bind(this.reportRestApiClient), Views.GRID, "vulnerabilities", resetPage)
            await this.loadData(noSort, this.reportRestApiClient.getGroupedByHosts.bind(this.reportRestApiClient), Views.TABLE_HOSTS, "groupedHosts", resetPage)
            await this.loadData(noSort, this.reportRestApiClient.getGroupedByVulnerability.bind(this.reportRestApiClient), Views.TABLE_VULNERABILITIES, "groupedVulnerabilities", resetPage)
            await this.loadData(noSort, this.reportRestApiClient.filteredReportFull.bind(this.reportRestApiClient), Views.TABLE_OVERVIEW, "vulnerabilitiesTable", resetPage)

            this.setState({hasBeenFlushed: true})
            return
        }

        switch (activeView) {
            case Views.GRID: {
                await this.loadData(noSort, this.reportRestApiClient.filteredReportFull.bind(this.reportRestApiClient), Views.GRID, "vulnerabilities", resetPage)
                break
            }
            case Views.TABLE_HOSTS: {
                await this.loadData(noSort, this.reportRestApiClient.getGroupedByHosts.bind(this.reportRestApiClient), Views.TABLE_HOSTS, "groupedHosts", resetPage)
                break
            }
            case Views.TABLE_VULNERABILITIES: {
                await this.loadData(noSort, this.reportRestApiClient.getGroupedByVulnerability.bind(this.reportRestApiClient), Views.TABLE_VULNERABILITIES, "groupedVulnerabilities", resetPage)
                break
            }
            case Views.TABLE_OVERVIEW: {
                await this.loadData(noSort, this.reportRestApiClient.filteredReportFull.bind(this.reportRestApiClient), Views.TABLE_OVERVIEW, "vulnerabilitiesTable", resetPage)
                break
            }
            default: {
                Logger.warn(`Could not detect current reporting view.`)
            }
        }

        this.setState({hasBeenFlushed: true})
    }

    applyFilter = (filter) => {
        this.setState({filter, filterOpen: false}, () => {
            this.update(false, true, true)
        })
    }

    openFilter = () => {
        this.setState({filterOpen: true})
    }

    closeFilter = () => {
        this.setState({filterOpen: false})
    }

    sort = field => direction => {
        this.setState({
            sorting: {
                field: field,
                direction: direction
            }
        }, () => this.update())
    }

    setPaging = (pagingObject, view, callback) => {
        this.setState(prevState => ({
            paging: {...prevState.paging, [view]: pagingObject}
        }), callback)
    }

    componentDidMount() {
        const f = async () => {

            const activeView = Views.GetActiveView()
            this.setState({activeView})


            this.reportRestApiClient.filteredReportFull(this.props.report, {})
                .then(response => {
                    this.setState({vulnerabilities: response.data})
                    this.setState({vulnerabilitiesTable: response.data})
                    this.setState({filter: response.filter})
                    this.setPaging(response.paging, Views.GRID)
                    this.setPaging(response.paging, Views.TABLE_OVERVIEW)
                    this.setState({sorting: response.sorting})
                })
                .catch(e => {
                    this.setState({error: e.status})
                })

            this.reportRestApiClient.getGroupedByHosts(this.props.report, {})
                .then(response => {
                    this.setState({groupedHosts: response.data})
                    this.setPaging(response.paging, Views.TABLE_HOSTS)
                })
                .catch(e => console.error(e))

            this.reportRestApiClient.getGroupedByVulnerability(this.props.report, {})
                .then(response => {
                    this.setState({groupedVulnerabilities: response.data})
                    this.setPaging(response.paging, Views.TABLE_VULNERABILITIES)
                })
                .catch(e => console.error(e))

            this.reportRestApiClient.getUniqueHosts(this.props.report)
                .then(response => {
                    this.setState({uniqueHosts: response.data})
                })
                .catch(e => console.error(e))


            this.reportRestApiClient.getUniquePorts(this.props.report)
                .then(response => {
                    this.setState({uniquePorts: response.data})
                })
                .catch(e => console.error(e))

            this.reportRestApiClient.getUniqueHostNames(this.props.report)
                .then(response => {
                    this.setState({uniqueHostNames: response.data})
                })
                .catch(e => console.error(e))


            this.reportRestApiClient.getUniqueHostOperatingSystems(this.props.report)
                .then(response => {
                    this.setState({uniqueOperatingSystems: response.data})
                })
                .catch(e => console.error(e))


            this.reportRestApiClient.getHead(this.props.report)
                .then(response => {
                    this.setState({head: response})
                }).catch(e => console.error(e))
        }

        f()

    }

    navigate = (url) => {

        const {report, task} = this.props

        this.props.navigate({
            pathname: url,
            search: `?${new URLSearchParams({
                report,
                task
            })}`
        })
    }

    changePage = pageIndex => {
        this.setState(prevState => {
            return {
                paging: {
                    ...prevState.paging,
                    [prevState.activeView]:
                        {...prevState.paging[prevState.activeView], pageIndex}
                }
            }
        }, this.update)
    }

    changePageSize = (event) => {
        this.setState(prevState => {
            return {
                paging: {
                    ...prevState.paging,
                    [prevState.activeView]:
                        {...prevState.paging[prevState.activeView], pageSize: event.target.value}
                }
            }
        }, () => this.update(false, true, false))
    }


    search = term => {
        this.setState({searchTerm: term, hasBeenFlushed: false}, () => this.update(false, true, true))
    }


    render() {
        return <ReportContext.Provider value={{
            navigate: this.navigate,
            applyFilter: this.applyFilter,
            openFilter: this.openFilter,
            closeFilter: this.closeFilter,
            changePage: this.changePage,
            changePageSize: this.changePageSize,
            changeView: this.changeView,
            sort: this.sort,
            getFilter: this.getFilter,
            report: this.props.report,
            search: this.search,
            ...this.state,
            paging: this.state.paging[this.state.activeView]
        }}>
            {this.props.children}
        </ReportContext.Provider>
    }


}

export const ReportProvider = withReportUrlParamParser(_ReportProvider)

export const withReport = (Component) => {
    return function (props) {
        return <ReportContext.Consumer>
            {context => <Component {...props} {...context} />}
        </ReportContext.Consumer>
    }
}
