import React from "react"
import {IconButton} from "@material-ui/core"
import styled from "styled-components"
import ReactTooltip from "react-tooltip"
import {FlexRow, getEmail} from "@greenbone/cloud-component-library"
import {LanguageChange} from "./components/LanguageChange"
import {JWT} from "../Helper/JWT"
import {useTranslation} from "react-i18next"
import {Logger} from "../controller/loggingController"

const Mail = styled.div`
    color: ${props => props.theme.navbar.font};
`

const IconButtonWithColor = styled(IconButton)`
    color: ${props => props.theme.navbar.font} !important;
`

const Seperator = styled.div`
    width: 1px;
    height: 1.5rem;
    margin-left: .5rem;
    margin-right: .5rem;
    border-left: 1px solid ${props => props.theme.navbar.font};
`

const LogoutIcon = props => (
    <svg viewBox="0 0 24 24" {...props}>
        <path
            fill="currentColor"
            d="M19.499 16.5a.5.5 0 01-.354-.853l3.146-3.146H11.499a.5.5 0 010-1h10.793l-3.146-3.146a.5.5 0 01.707-.709l4 4a.505.505 0 010 .707l-3.999 4a.5.5 0 01-.355.147z"
        />
        <path
            fill="currentColor"
            d="M9.001 24a.988.988 0 01-.217-.024L.783 22.199a.995.995 0 01-.784-.975V2.778c0-.473.323-.874.784-.976l8-1.778A1.001 1.001 0 019.999 1v1h7c.552 0 1 .449 1 1v2.5a.5.5 0 01-1 0V3h-7v18h7v-2.5a.5.5 0 011 0V21a1 1 0 01-1 1h-7v1a.998.998 0 01-.998 1zM1 2.778v18.445L9 23V1L1 2.778z"
        />
        <path
            fill="currentColor"
            d="M5.999 14c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zm0-3a1.001 1.001 0 101 1c0-.551-.448-1-1-1z"
        />
    </svg>
)

function DisplayAdmin() {
    const {t} = useTranslation()

    try {
        const jwt = new JWT()
        if (!jwt.hasUserRole()) {
            return t("Managed Security Full Access")
        }
        return null
    } catch (e) {
        Logger.exception(e)
    }
}

export function Logout(props) {
    const {keycloak} = props

    const onLogout = () => {
        keycloak.logout()
    }


    return <FlexRow justifyContent={"flex-end"} alignItems={"center"}>
        <Mail>
            <DisplayAdmin/>
            {getEmail(keycloak.token)}
        </Mail>
        {props.children}
        <Seperator>
        </Seperator>
        <LanguageChange/>
        <Seperator>
        </Seperator>
        <IconButtonWithColor data-tip data-for="LOGOUT-ICON" onClick={() => {
            onLogout()
        }}>
            <LogoutIcon style={{height: "20px"}}/>
        </IconButtonWithColor>
        <ReactTooltip id="LOGOUT-ICON" place="bottom" type="dark" effect="solid">
            Sign out
        </ReactTooltip>


    </FlexRow>
}

