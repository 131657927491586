import React from "react"
import {Route} from "react-router-dom"
import {Switcher} from "../../Components/Router"
import {Accordion, ContentBlock, FlexRow, Headline, Paragraph} from "@greenbone/cloud-component-library"
import {GatewayList} from "./components/GatewayList"
import {InfoText} from "../../Components/Basic/InfoText"
import {Trans, useTranslation} from "react-i18next"
import {Col, Row} from "reactstrap"
import {Graphic} from "./components/Graphic"
import USBIcon from "./USB.svg"
import NetworkIcon from "./Network.svg"
import Cloud from "./cloud.svg"
import NetworkMonitor from "./network-monitor.svg"
import ErrorBoundary from "../../Components/ErrorBoundary"

const GatewayPage = (props) => {

    const {t} = useTranslation()

    return <>
        <Row>
            <Col>
                <FlexRow justifyContent={"space-between"}>
                    <div style={{width: "58%", minWidth: "490px", marginRight: "1rem"}}>
                        <Headline>Gateways</Headline>
                        <InfoText>
                            <p>
                                <Trans i18nKey={"gatewayPage.help.paragraph"}>
                                    Mit Gateways können Sie entfernte und interne Netze erreichen. Das Gateway baut
                                    einen
                                    Layer 2 Tunnel
                                    auschließlich für die Zeit des Scans auf. Nach dem Scannen wir der Tunnel getrennt.
                                    Durch den Layer 2
                                    Tunnel können alle Protokolle und
                                    Alive-Tests genutzt werden.
                                </Trans>
                            </p>
                        </InfoText>
                        <ErrorBoundary>
                            <GatewayList/>
                        </ErrorBoundary>
                    </div>
                    <div style={{width: "38%"}}>
                        <Graphic/>
                        <Headline style={{marginTop: "2rem"}}>
                            {t("gateway.config.instructions.headline")}
                        </Headline>
                        <Accordion title={"VMware ESXi"}>
                            <ContentBlock icon={<USBIcon/>} title={"PortGroup"}>
                                <Paragraph size={"0.75rem"}>
                                    <b>{t("gateway.config.vmware.portGroupBold")}</b> <br/>
                                    {t("gateway.config.vmware.portGroup")}
                                    {t("gateway.config.vmware.settings")}
                                </Paragraph>
                            </ContentBlock>
                            <ContentBlock icon={<NetworkIcon/>} title={"Network"}>
                                <Paragraph size={"0.75rem"}>
                                    {t("gateway.config.vmware.network")}
                                </Paragraph>
                            </ContentBlock>
                        </Accordion>
                        <Accordion title={"Hyper-V"}>
                            <ContentBlock icon={<Cloud/>} title={t("gateway.config.hyperv.vswitch")}>
                                <Paragraph size={"0.75rem"}>
                                    {t("gateway.config.hyperv.vwitch.text")}
                                </Paragraph>
                            </ContentBlock>
                            <ContentBlock icon={<NetworkMonitor/>} title={t("gateway.config.hyperv.vcomputer")}>
                                <Paragraph size={"0.75rem"}>
                                    {t("gateway.config.hyperv.vcomputer.text1")}
                                    <b>Generation 1</b>
                                    {t("gateway.config.hyperv.vcomputer.text2")}
                                </Paragraph>
                            </ContentBlock>
                        </Accordion>
                    </div>
                </FlexRow>
            </Col>
        </Row>
    </>
}


type Props = {
    history: any;
}

export class GatewayRouter extends React.Component<Props, {}> {

    render() {
        return (

            <Switcher>
                <Route path="/list" Component={GatewayPage}/>
            </Switcher>

        )
    }
}

