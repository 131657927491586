//@flow

import {globalInstanceStorage} from "../../GlobalSingle"
import {Logger} from "../controller/loggingController"

const jwt = require("jsonwebtoken")

export class JWT {
    token: any

    constructor() {
        this.decode()
    }

    get isValid() {
        return !!this.token
    }

    decode() {
        try {
            const rawToken = globalInstanceStorage.keycloak.token
            const decodedToken = jwt.decode(rawToken)
            this.token = decodedToken
        } catch (e) {
            console.error(e)
        }

    }

    getUserId() {
        return this.token.sub
    }

    getEmail() {
        const token = this.token
        return token.email
    }

    subject() {
        const token = this.token
        if (!token) {
            return ""
        }
        return token.sub
    }

    isManaged() {
        return this.hasPermissionV2("serviceMode:MANAGEDSERVICE");
    }

    familyName() {
        const token = this.token
        return token.family_name
    }

    givenName() {
        const token = this.token
        return token.given_name
    }

    getRoles(): [string] {
        try {
            if (this.token?.realm_access?.roles) {
                return this.token.realm_access.roles
            }
        } catch (e) {
            Logger.warn(`${e?.toString()}//${globalInstanceStorage?.keycloak?.token}`)
            Logger.exception(e)
        }
        return []
    }

    hasPermissionV2(permission: string): boolean {
        return this.token?.['permissions-v2']?.includes(permission) || false
    }

    hasUserRole(): boolean {
        return this.getRoles().includes("user")
    }
}
