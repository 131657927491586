import {buildUrl} from "../buildUrl"
import {rest} from "msw"


export const taskExecutionHandler = [
    rest.delete(buildUrl("/task_execution/3451"), (req, res, ctx) => {
        return res(
            ctx.status(404)
        )
    }),

    rest.put(buildUrl("/task_execution/5170"), (req, res, ctx) => {
        return res(
            ctx.status(404)
        )
    })

]
