// @flow

import {GlobalReduxActions} from "../../constants";
import {CONSTANTS} from "./actions";
import {SnackbarTypes} from "./index";


type State = {
    snackbarOpen: boolean;
    message: string;
};

const initialState: State = {
    snackbarOpen: false,
    message: "",
    snackbarType: SnackbarTypes.Info,
};

const reducer = (state: State = initialState, action: any) => {
    switch (action.type) {

        case CONSTANTS.OPEN_SNACKBAR :
            return {
                ...state,
                snackbarOpen: true,
                message: action.message,
                snackbarType: action.snackbarType,
            };

        case CONSTANTS.CLOSE_SNACKBAR:
            return {
                ...state,
                snackbarOpen: false,
                message: "",
            };

        case GlobalReduxActions.RESET: {
            return initialState;
        }

        default:
            return state;
    }
};

export default reducer;
