/** Hey you've gone too far, you weren't supposed to look at how this works.
 * Yes it uses a template string to pass params to the eval function.
 * This is sadly the only way i could get this function to work, i could not be bothered to decipher the original one.
 * Some madlad decided this is his way to do javascript. https://github.com/PimpTrizkit/PJs/wiki/12.-Shade,-Blend-and-Convert-a-Web-Color-(pSBC.js)
 * Originally copied from Stackoverflow https://stackoverflow.com/questions/5560248/programmatically-lighten-or-darken-a-hex-color-or-rgb-and-blend-colors
 *
 * If your tasked with changing or fixing this function. Good luck you'll need it.
 *
 *
 * Original Documentation incase Stackoverflow goes down:
 *
 * This function (pSBC) will take a HEX or RGB web color. pSBC can shade it darker or lighter, or blend it with a second color, and can also pass it right thru but convert from Hex to RGB (Hex2RGB) or RGB to Hex (RGB2Hex). All without you even knowing what color format you are using.
 *
 *This runs really fast, probably the fastest, especially considering its many features. It was a long time in the making. See the whole story on my github. If you want the absolutely smallest and fastest possible way to shade or blend, see the Micro Functions below and use one of the 2-liner speed demons. They are great for intense animations, but this version here is fast enough for most animations.
 *
 *This function uses Log Blending or Linear Blending. However, it does NOT convert to HSL to properly lighten or darken a color. Therefore, results from this function will differ from those much larger and much slower functions that use HSL.
 *
 *
 *
 *Well, this answer has become its own beast. Many new versions, it was getting stupid long. Many thanks to all of the great many contributors to this answer. But, in order to keep it simple for the masses. I archived all the versions/history of this answer's evolution to my github. And started it over clean on StackOverflow here with the newest version. A special thanks goes out to Mike 'Pomax' Kamermans for this version. He gave me the new math.
 *
 *This function (pSBC) will take a HEX or RGB web color. pSBC can shade it darker or lighter, or blend it with a second color, and can also pass it right thru but convert from Hex to RGB (Hex2RGB) or RGB to Hex (RGB2Hex). All without you even knowing what color format you are using.
 *
 *This runs really fast, probably the fastest, especially considering its many features. It was a long time in the making. See the whole story on my github. If you want the absolutely smallest and fastest possible way to shade or blend, see the Micro Functions below and use one of the 2-liner speed demons. They are great for intense animations, but this version here is fast enough for most animations.
 *
 *This function uses Log Blending or Linear Blending. However, it does NOT convert to HSL to properly lighten or darken a color. Therefore, results from this function will differ from those much larger and much slower functions that use HSL.
 *
 *jsFiddle with pSBC
 *
 *github > pSBC Wiki
 *
 *Features:
 *
 *Auto-detects and accepts standard Hex colors in the form of strings. For example: "#AA6622" or "#bb551144".
 *Auto-detects and accepts standard RGB colors in the form of strings. For example: "rgb(123,45,76)" or "rgba(45,15,74,0.45)".
 *Shades colors to white or black by percentage.
 *Blends colors together by percentage.
 *Does Hex2RGB and RGB2Hex conversion at the same time, or solo.
 *Accepts 3 digit (or 4 digit w/ alpha) HEX color codes, in the form #RGB (or #RGBA). It will expand them. For Example: "#C41" becomes "#CC4411".
 *Accepts and (Linear) blends alpha channels. If either the c0 (from) color or the c1 (to) color has an alpha channel, then the returned color will have an alpha channel. If both colors have an alpha channel, then the returned color will be a linear blend of the two alpha channels using the percentage given (just as if it were a normal color channel). If only one of the two colors has an alpha channel, this alpha will just be passed thru to the returned color. This allows one to blend/shade a transparent color while maintaining the transparency level. Or, if the transparency levels should blend as well, make sure both colors have alphas. When shading, it will pass the alpha channel straight thru. If you want basic shading that also shades the alpha channel, then use rgb(0,0,0,1) or rgb(255,255,255,1) as your c1 (to) color (or their hex equivalents). For RGB colors, the returned color's alpha channel will be rounded to 3 decimal places.
 *RGB2Hex and Hex2RGB conversions are implicit when using blending. Regardless of the c0 (from) color; the returned color will always be in the color format of the c1 (to) color, if one exists. If there is no c1 (to) color, then pass 'c' in as the c1 color and it will shade and convert whatever the c0 color is. If conversion only is desired, then pass 0 in as the percentage (p) as well. If the c1 color is omitted or a falsy is passed in, it will not convert.
 *A secondary function is added to the global as well. pSBCr can be passed a Hex or RGB color and it returns an object containing this color information. Its in the form: {r: XXX, g: XXX, b: XXX, a: X.XXX}. Where .r, .g, and .b have range 0 to 255. And when there is no alpha: .a is -1. Otherwise: .a has range 0.000 to 1.000.
 *For RGB output, it outputs rgba() over rgb() when a color with an alpha channel was passed into c0 (from) and/or c1 (to).
 *Minor Error Checking has been added. It's not perfect. It can still crash or create jibberish. But it will catch some stuff. Basically, if the structure is wrong in some ways or if the percentage is not a number or out of scope, it will return null. An example: pSBC(0.5,"salt") == null, where as it thinks #salt is a valid color. Delete the four lines which end with return null; to remove this feature and make it faster and smaller.
 *Uses Log Blending. Pass true in for l (the 4th parameter) to use Linear Blending.
 *
 *const RGB_Linear_Blend=(p,c0,c1)=>{
 *  var i=parseInt,r=Math.round,P=1-p,[a,b,c,d]=c0.split(","),[e,f,g,h]=c1.split(","),x=d||h,j=x?","+(!d?h:!h?d:r((parseFloat(d)*P+parseFloat(h)*p)*1000)/1000+")"):")";
 * return"rgb"+(x?"a(":"(")+r(i(a[3]=="a"?a.slice(5):a.slice(4))*P+i(e[3]=="a"?e.slice(5):e.slice(4))*p)+","+r(i(b)*P+i(f)*p)+","+r(i(c)*P+i(g)*p)+d;
 *}
 *
 *const RGB_Linear_Shade=(p,c)=>{
 *   var i=parseInt,r=Math.round,[a,b,c,d]=c.split(","),P=p<0,t=P?0:255*p,P=P?1+p:1-p;
 *  return"rgb"+(d?"a(":"(")+r(i(a[3]=="a"?a.slice(5):a.slice(4))*P+t)+","+r(i(b)*P+t)+","+r(i(c)*P+t)+(d?","+d:")");
 *}
 *
 *const RGB_Log_Blend=(p,c0,c1)=>{
 *  var i=parseInt,r=Math.round,P=1-p,[a,b,c,d]=c0.split(","),[e,f,g,h]=c1.split(","),x=d||h,j=x?","+(!d?h:!h?d:r((parseFloat(d)*P+parseFloat(h)*p)*1000)/1000+")"):")";
 * return"rgb"+(x?"a(":"(")+r((P*i(a[3]=="a"?a.slice(5):a.slice(4))**2+p*i(e[3]=="a"?e.slice(5):e.slice(4))**2)**0.5)+","+r((P*i(b)**2+p*i(f)**2)**0.5)+","+r((P*i(c)**2+p*i(g)**2)**0.5)+d;
 *}
 *
 *const RGB_Log_Shade=(p,c)=>{
 *   var i=parseInt,r=Math.round,[a,b,c,d]=c.split(","),P=p<0,t=P?0:p*255**2,P=P?1+p:1-p;
 *  return"rgb"+(d?"a(":"(")+r((P*i(a[3]=="a"?a.slice(5):a.slice(4))**2+t)**0.5)+","+r((P*i(b)**2+t)**0.5)+","+r((P*i(c)**2+t)**0.5)+(d?","+d:")");
 *}
 *
 *
 *
 * Params:
 *
 *pSBC(p,c0,c1,l)
 *
 *p = < Percentage Float > * REQUIRED
 *
 *Used, as a percentage, with typical range of -1.0 to 1.0. Out of range parameter will cause a return of null.
 *When shading, the range is -1.0 to 1.0. But when blending, it is 0.0 to 1.0. And if you want to use the converter only, then use a p value of 0 or 0.0.
 *When shading, Positive numbers will shade to white (lighten the color). Negative colors will shade to black (darken the color).
 *When shading, using exactly -1.0 or 1.0 will always shade to pure black or pure white, regardless of the from color.
 *When blending two colors, a negative p value will get converted to a positive one (absolute value).
 *When blending two colors, a p (percentage) value of 0.5 will be a perfect 50/50 blend of the two colors. Higher p values will produce a color closer to the to color, and p values less than 0.5 will produce a color more similar to the from color.
 *Using exactly 0.0 (or 0) will not shade/blend at all, the from color will be returned. Use this to disable the shade/blend and to use the Hex2RGB and RGB2Hex conversion only option.
 *Using 0 for p (percentage) and 'c' for the to color will enable the standard conversion only option, like a shim. However, you can also use a real color as the to color and with a p of 0, it will return the from color in whatever color format the to color is using, be it Hex or RGB. I call this a blind shim. Because you can still standardize the environment, but without knowing which color formats it uses. In fact, this whole function lets you manipulate colors without you knowing its color format.
 *
 *c0 = < "from" Color String > * REQUIRED
 *
 *Accepts color strings in the form of RGB colors with or without the alpha channel (the RGB color format). For example: rgb(23,4,55) or rgba(23,4,55,0.52).
 *Accepts color strings in the form of Hex colors with or without the alpha channel (the Hex color format). And it can also accept the short 3 or 4 digit notation. For example: #FF33DD or #FF33DDCC or #F3D or #F3DC.
 *Auto-detects Hex or RGB.
 *
 *c1 = < "to" Color String > Optional
 *
 *Accepts color strings in the form of RGB colors with or without the alpha channel (the RGB color format). For example: rgb(23,4,55) or rgba(23,4,55,0.52).
 *Accepts color strings in the form of Hex colors with or without the alpha channel (the hex color format). And it can also accept the short 3 or 4 digit notation. For example: #FF33DD or #FF33DDCC or #F3D or #F3DC.
 *Auto-detects Hex or RGB.
 *To enable the conversion only feature: Pass the single character 'c' as the to color, and pass 0 as the p (percentage). The auto-detect of the from color will know which direction to convert. Using 'c' is required because...
 *Omitting the to color (or using a falsy) will enable the shader-only mode.
 *Using a to color will enable the blender.
 *Using a to color of white or black is the same as using the shader.
 *Conversion between Hex2RGB and RGB2Hex is implicit. Therefore, if your color formats are different between your from color and your to color, the returned color will have a color format equal to the to color. If the to color is the single character 'c', then the returned color will be derived from only the from color and with its color format converted to the other. If there is no to argument at all (omitted), or if passed a falsy, it will not convert anything and will only attempt to shade.
 *Using the single character 'c' as your to color with a non-zero p (percentage) will allow you to convert and shade.
 *
 *l = < UseLinear Boolean > Optional
 *
 *Defaults to false. And will use Log Blending.
 *Pass in true to use Linear Blending.
 *
 *
 */


/**
 * Takes a hey or rgb color and returns a darker or brighter shade a positive pos returns a lighter color a negative color returns a darker color.
 * https://github.com/PimpTrizkit/PJs/wiki/12.-Shade,-Blend-and-Convert-a-Web-Color-(pSBC.js)
 * DO NOT LOOK AT THE IMPLEMENTATION
 * @param color
 * @param pos
 * @returns {any}
 * @constructor
 */
export function ShadeBlendColor(color, pos = 7) {
    // eslint-disable-next-line no-eval
    return eval(`const pSBC=(p,c0,c1,l)=>{
	let r,g,b,P,f,t,h,i=parseInt,m=Math.round,a=typeof(c1)=="string";
	if(typeof(p)!="number"||p<-1||p>1||typeof(c0)!="string"||(c0[0]!='r'&&c0[0]!='#')||(c1&&!a))return null;
	function pSBCr(d){
		let n=d.length,x={};
		if(n>9){
			[r,g,b,a]=d=d.split(","),n=d.length;
			if(n<3||n>4)return null;
			x.r=i(r[3]=="a"?r.slice(5):r.slice(4)),x.g=i(g),x.b=i(b),x.a=a?parseFloat(a):-1
		}else{
			if(n==8||n==6||n<4)return null;
			if(n<6)d="#"+d[1]+d[1]+d[2]+d[2]+d[3]+d[3]+(n>4?d[4]+d[4]:"");
			d=i(d.slice(1),16);
			if(n==9||n==5)x.r=d>>24&255,x.g=d>>16&255,x.b=d>>8&255,x.a=m((d&255)/0.255)/1000;
			else x.r=d>>16,x.g=d>>8&255,x.b=d&255,x.a=-1
		}return x};
	h=c0.length>9,h=a?c1.length>9?true:c1=="c"?!h:false:h,f=pSBCr(c0),P=p<0,t=c1&&c1!="c"?pSBCr(c1):P?{r:0,g:0,b:0,a:-1}:{r:255,g:255,b:255,a:-1},p=P?p*-1:p,P=1-p;
	if(!f||!t)return null;
	if(l)r=m(P*f.r+p*t.r),g=m(P*f.g+p*t.g),b=m(P*f.b+p*t.b);
	else r=m((P*f.r**2+p*t.r**2)**0.5),g=m((P*f.g**2+p*t.g**2)**0.5),b=m((P*f.b**2+p*t.b**2)**0.5);
	a=f.a,t=t.a,f=a>=0||t>=0,a=f?a<0?t:t<0?a:a*P+t*p:0;
	if(h)return"rgb"+(f?"a(":"(")+r+","+g+","+b+(f?","+m(a*1000)/1000:"")+")";
	else return"#"+(4294967296+r*16777216+g*65536+b*256+(f?m(a*255):0)).toString(16).slice(1,f?undefined:-2)

}; pSBC(${pos}/10,"${color}",false,true)`);
}

