//@flow

import {CircularProgress} from "@material-ui/core";
import React from "react";


type Props = {
    hidden?: boolean;
}

class _ButtonSpinner extends React.Component<Props, {}> {

    render() {

        if (this.props.hidden === true) {
            return null;
        }

        return (
            <CircularProgress style={{
                width: "1.25rem",
                height: "1.25rem",
                marginRight: "1rem",
                color: "white",
            }}/>
        );
    }
}

export const ButtonSpinner = _ButtonSpinner;
