import React from "react"
import {withTranslation} from "react-i18next"
import {compose} from "redux"
import {HelpUrlProvider} from "../../../../UrlProvider/HelpUrlProvider"
import {Collapsible, ListMenuItem, ListMenuRootItem, MenuContext, TopLi} from "@greenbone/cloud-component-library"
import QuestionMark from "../../../../../assets/icons/question-circle.svg"
import Book from "../../../../../assets/icons/book-close-2.svg"
import TaskScan from "../../../../../assets/icons/task_scan.svg"
import AccountingHandshake from "../../../../../assets/icons/legal-hammer.svg"
import {withLocation} from "../../../../../hocs/withLocation";

type Props = {
    location: any;
    t: any;
}

class _HelpMenu extends React.Component<Props, {}> {
    get isActive() {
        const {pathname} = this.props.location
        return HelpUrlProvider.pathHasSameBase(pathname)
    };

    render() {
        const {t} = this.props;

        return <MenuContext.Consumer>
            {({toggle, minified, ...rest}: any) => {

                const helpOpen = rest[`helpOpen`]
                const toggleHelp = toggle("helpOpen")

                return <TopLi open={this.isActive}>
                    <ListMenuRootItem open={this.isActive} extended={helpOpen} onClick={(event) => {
                        toggleHelp(event, true)
                    }} to={HelpUrlProvider.knowledgebase()} icon={<QuestionMark/>} text={t("menu.helpMenu.help")}/>

                    <Collapsible style={{display: helpOpen ? "block" : "none"}}>
                        <ListMenuItem open={this.isActive} minified={minified} to={HelpUrlProvider.knowledgebase()}
                                      icon={TaskScan}>
                            {t("menu.helpMenu.overview")}
                        </ListMenuItem>
                        <ListMenuItem open={this.isActive} minified={minified} to={HelpUrlProvider.manual()}
                                      icon={Book}>
                            {t("menu.helpMenu.manual")}
                        </ListMenuItem>
                        <ListMenuItem open={this.isActive} minified={minified} to={HelpUrlProvider.terms()}
                                      icon={AccountingHandshake}>
                            {t("menu.userMenu.legal")}
                        </ListMenuItem>
                    </Collapsible>
                </TopLi>
            }}
        </MenuContext.Consumer>;
    }
}

export const HelpMenu = compose(
    withLocation,
    withTranslation()
)(_HelpMenu);
