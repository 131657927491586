import React from 'react'
import {useLocation} from 'react-router'

export const withLocation = (Component: any) => {
    return (props: any) => {
        const location = useLocation()

        return <Component location={location} {...props} />;
    };
};
