// @flow

import {TableSortLabel, withStyles} from "@material-ui/core"
import {UnfoldMore} from "@material-ui/icons"

import React from "react"


const styles = theme => ({})

type Props = {
    classes: typeof styles,
    active: boolean,
    sortable: boolean,
    direction: string,
    onClick: () => void,
    children: any;
}

class _EnhancedTabelSortLabel extends React.Component<Props> {

    renderNotSortedIcon = (sortable: boolean, active: boolean) => {
        if (sortable && !active) {
            return (
                <UnfoldMore/>
            );
        }
        return null;
    };

    render() {

        return (
            <TableSortLabel
                hideSortIcon={true}
                active={this.props.active}
                direction={this.props.direction}
                onClick={this.props.onClick}
            >
                {this.props.children}
                {this.renderNotSortedIcon(this.props.sortable, this.props.active)}
            </TableSortLabel>);
    }
}

export const EnhancedTabelSortLabel = withStyles(styles)(_EnhancedTabelSortLabel);
