import React, {useContext, useState} from "react"
import {Col, Row} from "reactstrap"
import {Button, Caption, FlexRow, Headline, Snackbar} from "@greenbone/cloud-component-library"
import {SubscriptionRestApiClient} from "../service/SubscriptionRestApiClient"
import styled from "styled-components"
import {useNavigate, useSearchParams} from "react-router-dom"
import {SubscriptionContext} from "../SubscriptionProvider"
import {useTranslation} from "react-i18next"
import {GroupSettingsUrlProvider} from "../../../UrlProvider/GroupSettingsUrlProvider"


const TextArea = styled.div`
    border: 1px solid lightgrey;
    padding: 1rem;
    border-radius: 3px;
    height: 20rem;
    overflow: auto;
`


export function TerminateSubscription(props) {
    const [searchParams] = useSearchParams()
    const type = searchParams.get('type')

    const [feedback, setFeedback] = useState("")
    const {update} = useContext(SubscriptionContext)
    const navigate = useNavigate()
    const {t} = useTranslation()

    const handleChange = (event) => {
        setFeedback(event.target.innerText)
    }

    const handleTerminate = (event) => {

        const apiClient = new SubscriptionRestApiClient()
        apiClient.terminateSubscription(type, feedback)
            .then(r => {
                update()
                navigate(GroupSettingsUrlProvider.subscription())
                Snackbar.Success(t("termination.successful"))
            })
            .catch(e => {
                Snackbar.Error(e.toString())
            })
    }


    return <>
        <Row>
            <Col xs={7}>
                <Row>
                    <Col>
                        <Headline>
                            {t("terminateSubscription.headline")}
                        </Headline>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Caption>
                            {t("terminateSubscription.feedback")}
                        </Caption>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <TextArea contentEditable="true" onInput={handleChange}></TextArea>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FlexRow justifyContent={"flex-end"}>
                            <Button onClick={handleTerminate}>
                                {t("terminateSubscription.button")}
                            </Button>
                        </FlexRow>
                    </Col>
                </Row>
            </Col>
        </Row>
    </>
}
