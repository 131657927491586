//@flow

import {UrlProvider} from "./UrlProvider";


export class ScanmanagementUrlProvider extends UrlProvider {
    static BASE_URL = `${UrlProvider.ROOT_PATH}/scanmanagement`;

    static getBase() {
        return `${ScanmanagementUrlProvider.BASE_URL}`;
    }

    static scanmanagement() {
        return `${ScanmanagementUrlProvider.getBase()}/`;
    }

    static report(taskId?: string, reportId?: string) {
        const taskidParameter = taskId || ":taskId";
        const reportIdParameter = reportId || ":reportId";

        return `${ScanmanagementUrlProvider.getBase()}/task/${taskidParameter}/report/${reportIdParameter}`;
    }
}
