export class PagingController {
    results;

    constructor(data) {
        this.results = data || [];
    }

    getLastPageNumber(pageSize) {
        if (this.results.length === 0) {
            return 1;
        }
        return Math.ceil(this.results.length / pageSize);
    }

    paging(pageNumber, pageSize) {
        if (pageNumber < 1) {
            throw new Error("pageNumber starts with 1");
        }
        const sliceStart = pageNumber === 1 ? 0 : pageSize * (pageNumber - 1);
        const sliceEnd = pageNumber === 1 ? pageSize : ((pageNumber) * pageSize);
        const itemsPaged = this.results.slice(sliceStart, sliceEnd);
        return {
            results: itemsPaged,
            lastPageNumber: this.getLastPageNumber(pageSize)
        };
    }
}
