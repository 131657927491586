//@flow

import React from "react";
import ReactDOM from "react-dom";


type Props = {
    children?: any;
    root?: any;
}

export class Portal extends React.Component<Props, {}> {
    rootSelector: any;
    container: any;

    constructor(props: Props) {
        super(props);

        let id = props.root;
        if (!id) {
            id = "root-modal";
        }

        this.rootSelector = document.getElementById(id);
        this.container = document.createElement("div");
    }

    componentDidMount() {
        this.rootSelector.appendChild(this.container);
    }

    componentWillUnmount() {
        this.rootSelector.removeChild(this.container);
    }

    render() {
        if (this.props.children) {
            return ReactDOM.createPortal((this.props.children), this.container);
        }
        return null;

    }
}

export const SidePanel = (<Portal root={"paneDialog"}/>);
