import React from "react"
import {useTranslation} from "react-i18next"
import Shield from "../../../../assets/logos/Shield.svg"
import {ScanmanagementUrlProvider} from "../../../UrlProvider/ScanmanagementUrlProvider"
import {HelpMenu} from "./SubMenu/HelpMenu"
import {TaskMenu} from "./SubMenu/TaskMenu"
import {Menu, MenuContext, MenuItem, PoweredBy, Sidebar, SimpleMenu} from "@greenbone/cloud-component-library"
import PoweredByImage from "./powered_by_greenbone.svg"
import {ProductUpdatesMenuItem} from "./SubMenu/ProductUpdatesMenuItem"
import {UserSettingsMenu} from "./SubMenu/UserSettingsMenu"
import {GroupSettingsMenu} from "./SubMenu/GroupSettingsMenu"

export const MainMenu = (props: any) => {
    const {t} = useTranslation()
    return (
        <MenuContext.Consumer>
            {({minified, showMinifyButtonEvent, hideMinifyButtonEvent}: any) =>
                <Sidebar navStyle={{paddingBottom: 0}} minified={minified} onMouseEnter={showMinifyButtonEvent}
                         onMouseLeave={hideMinifyButtonEvent}>

                    <Menu>
                        <MenuItem minified={minified} to={ScanmanagementUrlProvider.scanmanagement()}
                                  icon={<Shield />} text={t("help.components.helpMovies.manageScans")}/>
                        <TaskMenu/>
                        <UserSettingsMenu/>
                        <GroupSettingsMenu/>
                        <HelpMenu/>
                        <ProductUpdatesMenuItem minified={minified}/>
                    </Menu>
                    <SimpleMenu>
                        <PoweredBy style={{
                            transform: minified ? "rotate(90deg)" : "inherit",
                            transition: minified ? "all 0.5s ease 0s" : "none",
                            marginBottom: minified ? "3rem" : "0"
                        }} icon={<PoweredByImage style={{width: 100, paddingLeft: "1.25rem"}} />}/>
                    </SimpleMenu>
                </Sidebar>
            }
        </MenuContext.Consumer>
    )

}
