// @flow

import {TableHead, TableRow, withStyles} from "@material-ui/core"
import classNames from "classnames"
import React from "react"
import styled from "styled-components"
import {EnhancedTabelSortLabel} from "./EnhancedTableSortLabel"
import {StyledTableCell} from "./StyledTableCell"


const styles = theme => ({
    root: {
        height: "100%",
        marginTop: theme.spacing(3)
    },
    header: {
        fontWeight: "bold"
    }
})

const StyledTableHead = styled(TableHead)`
    border-bottom: #4c4c4c solid 2px;
`

type Props = {
    classes: typeof styles,
    onRequestSort: (any, any) => void,
    order: string,
    orderBy: number,
    columnData: Array<ColumnData>,
}

class SortableTableHead extends React.Component<Props> {
    createSortHandler = column => event => {
        this.props.onRequestSort(event, column)
    }

    render() {
        const {order, orderBy, columnData, classes} = this.props

        return (
            <StyledTableHead>
                <TableRow>
                    {columnData.map(column => {
                        return (
                            <StyledTableCell
                                key={column.id}
                                sortDirection={orderBy === column.id ? order : false}
                                className={classNames(classes.header, column.headerStyle)}
                                id={column?.centerHead ? "TABLE_GLOBAL_OVERRIDE_TEXT_ALIGN_CENTER" : ""}
                            >
                                <EnhancedTabelSortLabel
                                    active={orderBy === column.id}
                                    direction={order}
                                    sortable={column.sortable}
                                    onClick={column.sortable ? this.createSortHandler(column) : null}
                                >
                                    {column.element || column.label}
                                </EnhancedTabelSortLabel>
                            </StyledTableCell>
                        )
                    }, this)}
                </TableRow>
            </StyledTableHead>
        )
    }
}

export default withStyles(styles)(SortableTableHead)
