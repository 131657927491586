import styled from "styled-components"
import TableRootElement from "@mui/material/Table"
import TableHead from "@mui/material/TableHead"
import TableRowBase from "@mui/material/TableRow"
import TableCellBase from "@mui/material/TableCell"
import {tableCellHeight} from "../constants"
import Select from "react-select"
import {TableFooter} from "@mui/material";

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const Headline = styled.h1`
  
`

export const MuiTableStyled = styled(TableRootElement)`
    tbody tr:nth-of-type(2n+1){
        background: #FBFBFB;
    }
    
    
    tbody tr:hover {
        background: rgb(213, 213, 213) none repeat scroll 0% 0%;
        box-shadow: rgb(112, 112, 112) 4px 0px 0px inset;
    }
`

export const MuiTableFooterStyled = styled(TableFooter)`
  p {
    margin: 0;
  }
`;

export const MuiTableHeadStyles = styled(TableHead)`
  th {
    font-weight: bold;
  }

  tr:last-child th {
    border-bottom: 2px solid #707070;
  }

`

export const MuiTableRowStyled = styled(TableRowBase)`
  &.not-found{
    background: none !important;
    &:hover {
      background: none;
      box-shadow: none;
    }
  }
`

export const MuiTableCellStyled = styled(TableCellBase)`
 && {
 height: ${tableCellHeight};
  padding: 1rem;
  padding-top: 0;
  padding-bottom: 0;
 }

 &.no-border {
   border-bottom-color: transparent;
 }
`

export const StyledSelect = styled(Select)`
  padding-top: 0.5rem;
  padding-bottom: .5rem;
`
