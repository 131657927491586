import React, {useContext, useState} from "react"
import {TeamContext} from "./TeamContext"
import {useTranslation} from "react-i18next"
import {ActionButton, SlideIn, Snackbar, TableCell, TableRow} from "@greenbone/cloud-component-library"
import {EnhancedTable} from "../../../Components/Table/EnhancedTable"
import {Invite} from "./Invite"
import Refresh from "../../../../assets/icons/button-refresh-arrow.svg"
import {Delete} from "@material-ui/icons"
import {Tooltip} from "@material-ui/core"
import IconButton from "@material-ui/core/IconButton"

export function InviteList(props) {
    const {invites, resendInvite, deleteInvite} = useContext(TeamContext);
    const {t} = useTranslation();

    const [open, setOpen] = useState(false);
    const resendInviteEvent = id => event => {
        resendInvite(id)
            .then(response => {
                Snackbar.Success(t("inviteList.resendetinvite"));
            })
            .catch(e => Snackbar.Error(e.toString()))
    }

    const deleteInviteEvent = id => event => {
        deleteInvite(id)
            .then(response => {
                Snackbar.Success(t("inviteList.deleted"))
            })
            .catch(e => Snackbar.Error(e.toString()))
    }

    const columns = [
        {
            id: "status",
            numeric: false,
            sortable: true,
            label: t("inviteList.status")
        },
        {
            id: "email",
            numeric: false,
            sortable: true,
            label: t("inviteList.email")
        },
        {
            id: "actions",
            numeric: false,
            sortable: false,
            label: t("inviteList.actions")
        }
    ]

    const generator = (row) => (
        <TableRow key={row.id}>
            <TableCell style={{width: "6rem"}}>
                {row.status}
            </TableCell>
            <TableCell>
                {row.email}
            </TableCell>
            <TableCell>
                <Tooltip title={t("inviteList.resend")}>
                    <IconButton onClick={resendInviteEvent(row.id)}>
                        <Refresh style={{width: "1rem"}}/>
                    </IconButton>
                </Tooltip>

                <Tooltip title={t("inviteList.delete")}>
                    <IconButton onClick={deleteInviteEvent(row.id)}>
                        <Delete/>
                    </IconButton>
                </Tooltip>
            </TableCell>
        </TableRow>
    )


    return <>

        <EnhancedTable elementGenerator={generator}
                       actions={<ActionButton id={"NEW_INVITE"}
                                              onClick={event => setOpen(true)}>{t("inviteList.invite")}</ActionButton>}
                       searchOn={["email"]}
                       order={"asc"} orderBy={"email"}
                       elements={invites}
                       columnData={columns}
        />

        <SlideIn open={open} onClose={() => setOpen(false)}>
            <Invite close={() => setOpen(false)}/>
        </SlideIn>

    </>
}
