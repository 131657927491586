/*
TODO - jwerremeyer MUST Move into Library after Review and QA
 */
import React, {useEffect} from "react"
import {useFilters, usePagination, useRowSelect, useSortBy, useTable} from "react-table"
import {TablePaginationActions} from "./TablePaginationActions"
import {useSearch} from "./plugins/useSearch"

import {useSortCache} from "./plugins/useSortCache"
import {useCachePageSize} from "./plugins/useCachePageSize"

import {DefaultColumnFilter} from "./components/DefaultFilter"
import {pageSizeOptions, tableCellHeight} from "./constants"
import {
    FlexRow,
    Headline,
    MuiTableCellStyled,
    MuiTableHeadStyles,
    MuiTableRowStyled,
    MuiTableStyled,
    MuiTableFooterStyled
} from "./components/StyledComponents"
import {checkProps} from "./helper/checkProps"
import {
    InputAdornment,
    TableBody,
    TableContainer,
    TableFooter,
    TablePagination,
    TableSortLabel,
    TextField
} from "@mui/material";
import {Search, UnfoldMore} from "@mui/icons-material";

const IndeterminateCheckbox = React.forwardRef(
    ({indeterminate, ...rest}, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])

        return (
            <>
                <input type="checkbox" ref={resolvedRef} {...rest} />
            </>
        )
    }
)


export function Table({
                          data,
                          columns,
                          searchableColumns,
                          defaultSortBy,
                          cacheKey,
                          actionCell,
                          entryGenerator,
                          actions,
                          searchText,
                          filtering = false,
                          enableRowSelection = false,
                          onSelectedRowsChange,
                          ...other
                      }) {
    checkProps(data, columns, searchableColumns, defaultSortBy, cacheKey, actionCell, entryGenerator, actions, searchText)

    if (actionCell) {
        columns.push({
            Header: "Actions",
            accessor: "actions"
        })
    }
    const ActionCell = actionCell

    const defaultColumn = React.useMemo(
        () => ({
            Filter: DefaultColumnFilter,
            filter: "fuzzyText"
        }),
        []
    )

    const initialState = {
        sortBy: defaultSortBy && [...defaultSortBy.map(col => {
            if (typeof col === "string") {
                return {id: col, desc: false}
            }
            return col
        })]
    }

    if (!defaultSortBy) {
        delete initialState.sortBy
    }

    let tableInstance = null


    if (enableRowSelection) {

        tableInstance = useTable({
                columns,
                data,
                defaultColumn,
                initialState,
                searchableColumns,
                cacheKey,
                autoResetSortBy: false,
                autoResetFilters: false,
                disableFilters: !filtering
            }, useFilters, useSearch, useSortBy, usePagination, useSortCache, useRowSelect,
            hooks => {
                hooks.visibleColumns.push(columns => [
                    // Let's make a column for selection
                    {
                        id: "selection",
                        // The header can use the table's getToggleAllRowsSelectedProps method
                        // to render a checkbox
                        Header: ({getToggleAllRowsSelectedProps}) => (
                            <div>
                                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
                            </div>
                        ),
                        // The cell can use the individual row's getToggleRowSelectedProps method
                        // to the render a checkbox
                        Cell: ({row}) => (
                            <div>
                                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                            </div>
                        )
                    },
                    ...columns
                ])
            })
    } else {

        tableInstance = useTable({
            columns,
            data,
            defaultColumn,
            initialState,
            searchableColumns,
            cacheKey,
            autoResetSortBy: false,
            autoResetFilters: false,
            disableFilters: !filtering
        }, useFilters, useSearch, useSortBy, usePagination, useSortCache)
    }

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        page,
        gotoPage,
        foundRows,
        searchValue,
        setPageSize,
        setSearchValue,
        state,
        selectedFlatRows
    } = tableInstance

    useEffect(() => {
        if (onSelectedRowsChange) {
            onSelectedRowsChange(selectedFlatRows)
        }
    }, [selectedFlatRows])

    const {pageIndex, pageSize, filters} = state

    useCachePageSize(cacheKey, pageSize, setPageSize, pageSizeOptions)

    const colspan = enableRowSelection ? columns.length + 1 : columns.length

    return <>
        <TableContainer>
            <MuiTableStyled {...getTableProps()} {...other}>
                <MuiTableHeadStyles>
                    <MuiTableRowStyled>
                        <MuiTableCellStyled colSpan={colspan}>
                            <FlexRow style={{alignItems: "center"}}>
                                {actions}
                                {Array.isArray(searchableColumns) &&
                                    <TextField
                                        onChange={e => setSearchValue(e.target.value)}
                                        placeholder={searchText ? searchText : "Search"}
                                        variant={"standard"}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Search/>
                                                </InputAdornment>
                                            )
                                        }}
                                    />}
                            </FlexRow>
                        </MuiTableCellStyled>
                    </MuiTableRowStyled>
                    {
                        headerGroups.map((headerGroup, index) => (
                            <React.Fragment key={index}>
                                <MuiTableRowStyled {...headerGroup.getHeaderGroupProps()}>
                                    {
                                        headerGroup.headers.map(column => (
                                                <MuiTableCellStyled {...column.getHeaderProps({...column.getSortByToggleProps({title: "Sort by"})})}>
                                                    <div>
                                                        {column.canSort ?
                                                            <TableSortLabel active={column.isSorted}
                                                                            direction={column.isSortedDesc ? "desc" : "asc"}>
                                                                {column.render("Header")}
                                                                {column.isSorted !== true && <UnfoldMore/>}
                                                            </TableSortLabel> :
                                                            column.render("Header")
                                                        }
                                                    </div>
                                                </MuiTableCellStyled>
                                            )
                                        )
                                    }
                                </MuiTableRowStyled>
                                {filtering &&
                                    <MuiTableRowStyled>
                                        {
                                            headerGroup.headers.map((column) => (
                                                <MuiTableCellStyled {...column.getHeaderProps()} >
                                                    <div>{column.canFilter ? column.render("Filter") : null}</div>
                                                </MuiTableCellStyled>
                                            ))
                                        }
                                    </MuiTableRowStyled>
                                }

                            </React.Fragment>

                        ))
                    }

                </MuiTableHeadStyles>
                <TableBody {...getTableBodyProps()}>
                    {page.map((row, index) => {
                        prepareRow(row)
                        if (entryGenerator) {
                            const EntryGenerator = entryGenerator
                            return <EntryGenerator
                                key={index}
                                {...row.getRowProps()} row={row} dataset={row.original}
                                getValue={(name) => {
                                    const cell = row.cells.find(cell => cell.column.id === name)
                                    if (cell) {
                                        return cell.render("Cell")
                                    }
                                    return null
                                }
                                }/>
                        }
                        return (
                            <MuiTableRowStyled key={index} {...row.getRowProps()}>
                                {row.cells.map((cell, index) => {
                                    if (cell.column.id === "actions") {
                                        return <MuiTableCellStyled  {...cell.getCellProps()}>
                                            <ActionCell/>
                                        </MuiTableCellStyled>
                                    } else {
                                        return <MuiTableCellStyled    {...cell.getCellProps()}>
                                            {cell.render("Cell")}
                                        </MuiTableCellStyled>
                                    }
                                })}
                            </MuiTableRowStyled>
                        )
                    })}

                    {(page.length < pageSize && foundRows.length !== 0) &&
                        Array(pageSize - page.length).fill().map((_, index) => (
                            <MuiTableRowStyled key={index} className={"not-found"}>
                                <MuiTableCellStyled className={"no-border"} colSpan={colspan}></MuiTableCellStyled>
                            </MuiTableRowStyled>
                        ))}

                    {
                        (foundRows.length === 0 && searchValue && searchValue !== "") &&

                        <MuiTableRowStyled className={"not-found"}>
                            <MuiTableCellStyled style={{height: `calc(${tableCellHeight} * ${pageSize})`}}
                                                colSpan={colspan}>
                                <FlexRow style={{justifyContent: "center", alignItems: "center"}}>
                                    <Headline noMargin>No results for: {searchValue}</Headline>
                                </FlexRow>
                            </MuiTableCellStyled>
                        </MuiTableRowStyled>


                    }
                </TableBody>
                <MuiTableFooterStyled>
                    <TableRow>
                        <TablePagination colSpan={colspan}
                                         rowsPerPageOptions={pageSizeOptions}
                                         count={rows.length}
                                         rowsPerPage={pageSize}
                                         page={pageIndex}
                                         slotProps={{
                                             select: {
                                                 inputProps: {"aria-label": "rows per page"},
                                                 native: true
                                             }
                                         }}
                                         onPageChange={gotoPage}
                                         onRowsPerPageChange={e => {
                                             setPageSize(Number(e.target.value))
                                         }}
                                         ActionsComponent={TablePaginationActions}


                        />
                    </TableRow>
                </MuiTableFooterStyled>
            </MuiTableStyled>
        </TableContainer>
    </>
}

export const TableRow = MuiTableRowStyled
export const TableCell = MuiTableCellStyled
