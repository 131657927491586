import {IconSurrounder} from "./Shared"
import {Bold} from "../Font"
import {FlexRow} from "../Flex"
import React from "react"
import styled from "styled-components"

export const MenuListItem = styled.li`
  list-style: outside none none;
  
  span {
    margin-left: 0.5rem;
  }
`


const Feedback = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;

  padding: 0.63rem;
  padding-left: 1.25rem;
  box-sizing: border-box;
  color: ${props => props.theme.sidebar.staticItemFont};
  background-color: ${props => props.theme.sidebar.staticItem};
          
  &:hover {
    text-decoration: none;
    color: ${props => props.theme.sidebar.staticItemFont};       
  }
`;

export function StandaloneMenuItem({minified, to, icon, headline, text}) {

    return <MenuListItem>
        <Feedback minified={minified} href={to}>
            {minified ? <IconSurrounder>{icon}</IconSurrounder> :
                <React.Fragment>
                    <Bold style={{margin: 0}}>
                        <>{headline}</>
                    </Bold>

                    <FlexRow alignItems={"center"}>
                        <IconSurrounder>{icon}</IconSurrounder>
                        <span>{text}</span>
                    </FlexRow>
                </React.Fragment>}
        </Feedback>
    </MenuListItem>;
}

