import React from 'react'
import Loader from "./Loader/Loader"
import ErrorBoundary from "./ErrorBoundary"

function LoadingBlockInner({isLoading, error, children}) {
    if (error) {
        throw error
    }

    if (isLoading) {
        return <Loader/>
    }

    if (typeof children === "function") {
        return children()
    }
    return children
}

/**
 * Handel default loading and error cases can be used with a render prop or children
 * @param isLoading
 * @param error
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
export function LoadingBlock({isLoading, error, children}) {
    return <ErrorBoundary>
        <LoadingBlockInner isLoading={isLoading} children={children} error={error}/>
    </ErrorBoundary>
}
