
//TODO - jwerremeyer - MUST Pull from library when extracting
export class LocalStorage {

    key = null

    constructor(key) {
        this.key = `${key}`
    }

    isValid = () => {
        if (!this.key) {
            throw new Error("To call this function create an instance of \"LocalStorage\" and initialize it with a key.")
        }
        return true
    }

    loadState = () => {
        this.isValid()

        try {
            const serializedState = localStorage.getItem(this.key)
            if (serializedState) {
                return JSON.parse(serializedState)
            }
            return null
        } catch (e) {
            return null
        }
    }

    saveState = (state) => {
        this.isValid()

        try {
            const serializedState = JSON.stringify(state)
            localStorage.setItem(this.key, serializedState)
        } catch (e) {
            //Ignore
        }
    }
}
