
import * as HttpStatuscode from "http-status-codes";


export class NoContentError extends Error {
    httpStatus = HttpStatuscode.NO_CONTENT;
    type = NoContentError;

    constructor(message = "") {
        super(`No content '${message}'`);
    }
}
