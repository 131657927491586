// @flow
import * as Sentry from "@sentry/browser"


class LoggerProduction {
    constructor() {
        window.console.debug = LoggerProduction.debug;
        window.console.error = LoggerProduction.error;
        window.console.info = LoggerProduction.info;
        window.console.log = LoggerProduction.log;
        window.console.warn = LoggerProduction.warn;
    }

    static debug = (...args): void => {
    };
    static error = (...args): void => {
    };
    static info = (...args): void => {
    };
    static log = (...args): void => {
    };
    static warn = (...args): void => {
    };
}

export class LoggingHandler {
    constructor() {
        if (process.env.NODE_ENV === "production") {
            new LoggerProduction();
        }
    }
}

export class Logger {
    static error(message: string) {
        console.error(message)
        Sentry.captureMessage(message, "error")
    }

    static exception(exception: any) {
        console.error(exception);
        Sentry.captureException(exception);
    }

    static warn(message: string) {
        console.warn(message);
        Sentry.captureMessage(message, "warning");
    }

    static debug(...message: any) {
        console.debug(...message);
    }
}
