

export class Formatter {


    static toCurrency(value, language) {

        switch (language) {
            case "de": {
                return new Intl.NumberFormat("de-DE", {style: "currency", currency: "EUR"}).format(value)
            }

            default: {
                return new Intl.NumberFormat("en-US", {style: "currency", currency: "EUR"}).format(value)
            }
        }
    }


    static toLongDate(value, language) {
        if (value.isSIODateTime) {
            value = value.toJSDate()
        }

        const options = {
            day: "numeric",
            month: "long",
            year: "numeric"
        }

        switch (language) {
            case "de": {
                return new Intl.DateTimeFormat("de-DE", options).format(value)
            }

            default: {
                return new Intl.DateTimeFormat("en-US", options).format(value)
            }
        }
    }

    static toDate(value, language) {
        switch (language) {
            case "de": {
                return new Intl.DateTimeFormat("de-DE").format(value)
            }

            default: {
                return new Intl.DateTimeFormat("en-US").format(value)
            }
        }
    }

}
