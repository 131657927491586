//@flow

import * as React from "react"
import styled from "styled-components"


const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`

type Props = {
    +children: any;
}

export class BlockCheckBoxContainer extends React.Component<Props> {
    render() {
        const {children} = this.props;

        return (
            <Container>
                {children}
            </Container>
        );
    }
}
