

export class ServiceNotAvailableError extends Error {
    type = ServiceNotAvailableError;

    constructor(message = "") {
        super(`Service not available '${message}'`);
    }
}

