//@flow

import {UrlProvider} from "./UrlProvider"


export class UserSettingsUrlProvider extends UrlProvider {
    static getBasePath = () => `/settings/user`
    static BASE_URL = `${UrlProvider.ROOT_PATH}${UserSettingsUrlProvider.getBasePath()}`


    static hash = {
        productUpdates: {
            url: "#showUpdates",
            param: "showUpdates"
        }
    }


    static getBase() {
        return this.BASE_URL
    }

    static language() {
        return `${UserSettingsUrlProvider.getBase()}/language/`
    }

    static security() {
        return `${UserSettingsUrlProvider.getBase()}/security/`
    }

    static notification() {
        return `${UserSettingsUrlProvider.getBase()}/notification/`
    }



}
