import React from "react";


export const ArrowRightIcon = ({color}) => (
    <svg id="Regular" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
        <defs>
            <style>
                {`.cls-1ArrowRightIcon{fill:${"white"};stroke:${color};stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;fill-rule:evenodd;} `}</style>
        </defs>
        <title>arrow-right-1</title>
        <path className="cls-1ArrowRightIcon" d="M5.5.75,16.22,11.47a.749.749,0,0,1,0,1.06L5.5,23.25"/>
    </svg>
);
