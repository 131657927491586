//@flow

import {IconButton} from "@material-ui/core"
import {Info} from "@material-ui/icons"
import React from "react"
import ReactTooltip from "react-tooltip"
import styled from "styled-components"


const Error = styled(IconButton)`
    color: #D0061A;
`

type Props = {
    errors: Array<GSPReportError>;
}

class _ReportErrorView extends React.Component<Props> {

    render() {
        const {errors} = this.props;

        if (errors.length <= 0) {
            return null;
        }

        return (
            <div>
                <Error data-tip={errors.map(n => n.description)}>
                    <Info/>
                </Error>
                <ReactTooltip place={"bottom"} type={"error"} effect={"solid"}/>
            </div>
        );
    }
}

export const ReportErrorView = _ReportErrorView;
