import React, {useContext, useEffect, useReducer, useState} from "react"
import {useLocation, useNavigate, useSearchParams} from "react-router-dom"
import {
    Button,
    Checkbox,
    FlexRow,
    GhostButton,
    Headline,
    Snackbar,
    Subheadline,
    Tiny
} from "@greenbone/cloud-component-library"
import {Col, Row} from "reactstrap"
import {PaymentOptions, SubscriptionContext, SubscriptionStep} from "../SubscriptionProvider"
import {useTranslation} from "react-i18next"
import {SlideNavigation} from "../components/SlideNavigation"
import {GreyBoxSimpleHead} from "./components/GreyBox"
import {BillingDetails} from "./components/BillingDetails"
import {ButtonSpinner} from "../../../Components/Animations/Button/ButtonSpinner"
import {SubscriptionNavigationUrlProvider} from "../SubscriptionNavigationUrlProvider"
import {isNumberPrimitive} from "@greenbone/cloud-validation-library"
import styled from "styled-components"


import {Elements, useStripe} from "@stripe/react-stripe-js"
import {globalInstanceStorage} from "../../../../GlobalSingle"
import {GroupSettingsUrlProvider} from "../../../UrlProvider/GroupSettingsUrlProvider"
import {PricingTable} from "./components/PricingTable"
import {SubscriptionRestApiClient} from "../service/SubscriptionRestApiClient"


//<AlertBox title={"Error"} text={"The action could not be executed."} type={AlertBoxType.Error}/>;
const Action = {
    SET_INTERN: "INTERN",
    SET_EXTERN: "EXTERN",
    SET_INVALID: "INVALID",
    SET_SENDING: "SET_SENDING",
    SET_CALCULATING: "CALCULATING_PRICE",
    SET_PRICE_VALID: "SET_PRICE_VALID",
    SET_INSTANT_UPGRADE: "SET_INSTANT_UPGRADE",
    FLIP_INSTANT_UPGRADE: "FLIP_INSTANT_UPGRADE",
    SET_PRICE: "SET_PRICE",
    ERROR_OCCURRED: "ERROR_OCCURRED",
    SET_PRICE_LIST: "SET_PRICE_LIST"
}

const initialState = {
    intern: null,
    extern: null,
    invalid: true,
    calculating: true,
    priceValid: false,
    instantUpgrade: true,
    sending: false,
    price: null,
    error: false,
    priceList: null

}

function reducer(state, action) {
    switch (action.type) {
        case Action.SET_INTERN :
            return {...state, intern: action.value}

        case Action.SET_EXTERN:
            return {...state, extern: action.value}

        case Action.SET_INVALID:
            return {...state, invalid: action.value}

        case Action.SET_SENDING:
            return {...state, sending: action.value}

        case Action.SET_CALCULATING:
            return {...state, calculating: action.value}

        case Action.SET_PRICE_VALID:
            return {...state, priceValid: action.value}

        case Action.SET_INSTANT_UPGRADE:
            return {...state, instantUpgrade: action.value}

        case Action.FLIP_INSTANT_UPGRADE:
            return {...state, instantUpgrade: !state.instantUpgrade}

        case Action.SET_PRICE : {
            return {...state, price: action.value, priceValid: true, calculating: false}
        }

        case Action.SET_PRICE_LIST : {
            return {...state, priceList: action.value}
        }

        case Action.ERROR_OCCURRED : {
            return {...state, error: true}
        }

        default:
            return {...initialState}
    }


}

function InnerConfirmationPage(props) {


    const stripe = useStripe()
    const location = useLocation()
    const navigate = useNavigate()
    const {t} = useTranslation()
    const {
        paymentOption,
        setStep,
        update,
        calculatePrice,
        subscribe,
        getPriceList,
    } = useContext(SubscriptionContext)

    const [newCreditCardParameterSet, setNewCreditCardParameterSet] = useState(false)
    const [state, dispatch] = useReducer(reducer, initialState)
    const [searchParams] = useSearchParams()
    const {
        intern,
        extern,
        invalid,
        calculating,
        priceValid,
        instantUpgrade,
        sending,
        error,
        price,
        priceList
    } = state

    useEffect(() => {
        setStep(SubscriptionStep.Confirm)
    }, [setStep])


    const handleBack = event => {
        navigate({
            pathname: SubscriptionNavigationUrlProvider.payment(),
            search: `?${new URLSearchParams({
                intern: searchParams.get('intern'),
                extern: searchParams.get('extern')
            }).toString()}`
        })

    }

    const handleSubscribe = () => {
        subscribe(intern, extern, instantUpgrade)
            .then(response => {

                if (!response) {
                    update()
                    navigate(GroupSettingsUrlProvider.subscription())
                    Snackbar.Success(t("confirmationPage.successBar"))
                    return
                }

                if (response?.status === "BAD_REQUEST") {
                    Snackbar.Error(response?.message)
                    dispatch({type: Action.SET_SENDING, value: false})
                    return
                }

                if (response?.paymentIntentClientSecret) {
                    stripe.confirmCardPayment(response?.paymentIntentClientSecret)
                        .then(result => {
                            if (result.error) {
                                Snackbar.Warning(t("confirmationPage.security.check.failed"))
                                dispatch({type: Action.SET_SENDING, value: false})
                                return
                            }

                            if (result.paymentIntent) {
                                if (result.paymentIntent.status === "succeeded") {
                                    update()
                                    navigate(GroupSettingsUrlProvider.subscription())
                                    Snackbar.Success(t("confirmationPage.successBar"))
                                    dispatch({type: Action.SET_SENDING, value: false})
                                    return
                                }
                                Snackbar.Warning(t("confirmationPage.security.payment.failed"))
                                dispatch({type: Action.SET_SENDING, value: false})
                                return
                            }

                            Snackbar.Error(t("confirmationPage.security.payment.failed"))
                            dispatch({type: Action.SET_SENDING, value: false})
                            return

                        })
                        .catch(e => {
                            Snackbar.Error(t("confirmationPage.security.payment.failed"))
                            dispatch({type: Action.SET_SENDING, value: false})
                            return
                        })

                    return
                }

                if (response.id) {
                    update()
                    navigate(GroupSettingsUrlProvider.subscription())
                    Snackbar.Success(t("confirmationPage.successBar"))
                    return
                }

                if (response?.message) {
                    Snackbar.Error(response?.message)
                } else {
                    Snackbar.Error("An error occurred, please try again later.")
                }


            })
            .catch(e => {
                dispatch({type: Action.SET_SENDING, value: false})

                if (e.status === 409) {
                    Snackbar.Error(t("confirmationPage.instantDowngradeError"))
                    return
                }

                if (e.status === 428) {
                    Snackbar.Error(t("confirmationPage.managederror"))
                    return
                }
                Snackbar.Error(e)

            })
    }

    const placeOrder = event => {
        dispatch({type: Action.SET_SENDING, value: true})

        handleSubscribe();
    }

    useEffect(() => {
        if (newCreditCardParameterSet === true) {
            handleBack()
        }
    }, [newCreditCardParameterSet])

    useEffect(() => {
        const intern = searchParams.get('intern')
        const extern = searchParams.get('extern')

        if (searchParams.get('newCard')) {
            setNewCreditCardParameterSet(true)
        } else {
            setNewCreditCardParameterSet(false)
        }

        if (!intern || !extern) {
            dispatch({type: Action.SET_INVALID, value: true})
            return
        }

        if (intern + extern <= 0) {
            dispatch({type: Action.SET_INVALID, value: true})

            return
        }


        dispatch({type: Action.SET_INTERN, value: intern})
        dispatch({type: Action.SET_EXTERN, value: extern})
        dispatch({type: Action.SET_INVALID, value: false})

    }, [location.search])

    useEffect(() => {
        if (!invalid) {
            const priceListPromise = getPriceList(intern + extern)
            const pricePromise = calculatePrice(intern, extern)
            Promise.all([pricePromise, priceListPromise])
                .then(([price, priceList]) => {
                    if (isNumberPrimitive(price.price)) {
                        dispatch({type: Action.SET_PRICE, value: price.price})
                        dispatch({type: Action.SET_PRICE_LIST, value: priceList})

                    } else {
                        Snackbar.Error(t("confirmationPage.couldNotCalculatePrice"))
                    }

                })
                .catch(e => {
                    Snackbar.Error(t("confirmationPage.calculationError"))
                    console.log(e)
                })
        }
    }, [intern, extern, invalid])


    return <>

        <Row>
            <Col>
                <Headline>{t("confirmationPage.headline")}</Headline>
            </Col>
        </Row>
        <Row>
            <Col>
                <SlideNavigation/>
            </Col>
        </Row>
        <Row>
            <Col xs={7}>
                <Row>
                    <Col>
                        <Subheadline>{t("confirmationPage.summary")}</Subheadline>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <hr/>
                    </Col>
                </Row>
                <Row style={{marginBottom: "2rem"}}>
                    <Col>
                        {paymentOption === PaymentOptions.CREDIT_CARD &&
                        <>
                            <GreyBoxSimpleHead text={t("paymentInfo.paymentOption.creditCard")}
                                               title={t("paymentInfo.paymentOption")}/>
                        </>
                        }

                        {paymentOption === PaymentOptions.INVOICE &&
                        <>
                            <GreyBoxSimpleHead text={t("paymentInfo.paymentOption.invoice")}
                                               title={t("paymentInfo.paymentOption")}/>
                        </>
                        }
                    </Col>
                    <Col>
                        <BillingDetails/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <PricingTable price={price} intern={intern} extern={extern}
                                      priceList={priceList}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Row>
                            <Col>
                                <Checkbox checked={instantUpgrade}
                                          onChange={() => dispatch({type: Action.FLIP_INSTANT_UPGRADE})}
                                          label={t("confirmationPage.instant")}
                                />
                                <div>
                                    <CheckBoxInfo>
                                        {t("confirmationPage.instantDetail")}
                                    </CheckBoxInfo>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FlexRow justifyContent={"flex-end"}>
                                    <GhostButton style={{marginRight: "1rem"}} onClick={handleBack}>
                                        {t("confirmationPage.back")}
                                    </GhostButton>
                                    <Button disabled={error || invalid || calculating || !priceValid || sending}
                                            onClick={placeOrder}>{t("confirmationPage.confirm")} {sending &&
                                    <ButtonSpinner/>}</Button>
                                </FlexRow>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Col>
        </Row>
    </>
}

export function ConfirmationPage(props) {
    const stripePromise = globalInstanceStorage.getStripe()

    return <Elements stripe={stripePromise}>
        <InnerConfirmationPage {...props}/>
    </Elements>
}

export const CheckBoxInfo = styled(Tiny)`
    color: #707070;
    font-size: 0.875rem;
    margin: 0;
`
