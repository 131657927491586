import {useTranslation} from "react-i18next"

export class ReportHelper {

    static SeverityColor = {
        high: "#D4003E",
        medium: "#FCB800",
        low: "#7DB5D0"
    }

    static toFixed(val, fraction = 1) {
        if (isNaN(val)) {
            return val
        }

        const int = parseFloat(val)

        if (int % 1 === 0) {
            return parseInt(val)
        } else {
            return int.toFixed(1)
        }

    }

    static RangeToColor(range) {

        if (range === "HIGH") {
            return "#D4003E"
        }

        if (range === "MEDIUM") {
            return "#FCB800"
        }

        if (range === "LOW") {
            return "#7DB5D0"
        }

        return "#000000"
    }

    static SeverityToColor(severity) {
        if (severity < 4) {
            return "#7DB5D0"
        }
        if (severity >= 4 && severity < 7) {
            return "#FCB800"
        }
        if (severity >= 7 && severity <= 10) {
            return "#D4003E"
        }
        return "black"
    }

    static SeverityToString(severity) {
        const {t} = useTranslation()

        if (severity < 4) {
            return t("scanmanagement.low")
        }
        if (severity >= 4 && severity < 7) {
            return t("scanmanagement.medium")
        }
        if (severity >= 7 && severity <= 10) {
            return t("scanmanagement.critical")
        }
        return "unknown"
    }


    static RangeToString(severity) {

        const {t} = useTranslation()

        if (severity === "LOW") {
            return t("scanmanagement.low")
        }
        if (severity === "MEDIUM") {
            return t("scanmanagement.medium")
        }
        if (severity === "HIGH") {
            return t("scanmanagement.critical")
        }
        return "unknown"
    }


}
