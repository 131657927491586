import {Col, Row} from "reactstrap"
import {Subheadline} from "@greenbone/cloud-component-library"
import {ChipInput} from "../../../StyledComponents/Form/ChipInput"
import React from "react"
import {useTranslation} from "react-i18next"

export function HostnameInputArea({
                                      includedHostnames,
                                      inputRef,
                                      handleIncludedHostnamesAdd,
                                      handleIncludedHostnamesRemove,
                                      isValid
                                  }) {
    const {t} = useTranslation()

    return <Row style={{marginBottom: "4rem"}}>
        <Col>
        <Subheadline style={{marginBottom: 0}}>
                {t("common.targetCreate.includedHostnames")}
            </Subheadline>


            <ChipInput name={"hostname"}
                       style={{width: "100%"}}
                       label={t("hostnameInputAreas.enterHostnames")}
                       onBlur={event => handleIncludedHostnamesAdd(event.target.value)}
                       onAdd={handleIncludedHostnamesAdd}
                       onDelete={handleIncludedHostnamesRemove}
                       value={includedHostnames}
                       newChipKeyCodes={[32, 188, 113, 9, 13]}
                       helperText={t("hostnameInputAreas.separateByComma")}
                       inputRef={inputRef}
                       isValid={isValid}
            />
        </Col>


    </Row>
}
