import React from "react"

import {FilterTab, FlexRow, ScaleFilterTab} from "@greenbone/cloud-component-library"
import MoneyLetter from "../../../assets/icons/money-letter.svg"
import LicenceKey from "../../../assets/icons/key.svg"
import Bag from "../../../assets/icons/money-bag.svg"
import Credit from "../../../assets/icons/credit-card.svg"
import {PaymentOptions} from "../Subscritpion/SubscriptionProvider"

export function BillingMethod({method}) {

    switch (method) {
        case PaymentOptions.INVOICE:
            return <FlexRow alignItems={"center"}>
                <ScaleFilterTab scale={0.5}>
                    <FilterTab disabled icon={<MoneyLetter/>} color={"#7EC14B"}>
                        Invoice
                    </FilterTab>
                </ScaleFilterTab>
                Invoice
            </FlexRow>
        case PaymentOptions.LICENCE_KEY:
            return <FlexRow alignItems={"center"}>
                <ScaleFilterTab scale={0.5}>
                    <FilterTab disabled icon={<LicenceKey/>} color={"#7EC14B"}>
                        Licence Key
                    </FilterTab>
                </ScaleFilterTab>
                Licence Key
            </FlexRow>
        case PaymentOptions.CREDIT_CARD:
            return <FlexRow alignItems={"center"}>
                <ScaleFilterTab scale={0.5}>
                    <FilterTab disabled icon={<Credit/>} color={"#7EC14B"}>
                        Credit Card
                    </FilterTab>
                </ScaleFilterTab>
                Credit Card
            </FlexRow>
        case PaymentOptions.NONE:
            return <FlexRow alignItems={"center"}>
                <ScaleFilterTab scale={0.5}>
                    <FilterTab disabled icon={<Bag/>} color={"#7EC14B"}>
                        Free
                    </FilterTab>
                </ScaleFilterTab>
                Free
            </FlexRow>

        default:
            return <div></div>

    }
}
