import styled, {css} from "styled-components"
import {ReportHelper} from "../ReportHelper"

export const Square = styled.div`
    width: 3.125rem;
    height: 3.125rem;


    ${props => props?.severity && css`
        background: ${props => ReportHelper.SeverityToColor(props.severity)} 0% 0% no-repeat padding-box;
        box-shadow: 0px 2px 10px ${props => ReportHelper.SeverityToColor(props.severity)}4D;
    `}


    ${props => props?.range && css`
        background: ${props => ReportHelper.RangeToColor(props.range)} 0% 0% no-repeat padding-box;
        box-shadow: 0px 2px 10px ${props => ReportHelper.RangeToColor(props.range)}4D;
    `}


    border-radius: 19px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
    margin-right: 1.5rem;
    flex-shrink: 0;
`

export const SquareWithIcon = styled(Square)`
    background: ${props => props.theme.base};
    box-shadow: 0px 2px 10px ${props => props.theme.base}4D;

    svg {
        width: 1.875rem;
        height: 1.875rem;
    }
`
