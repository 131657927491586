//@flow

import {IconButton, withStyles, withTheme} from "@material-ui/core"
import {Delete} from "@material-ui/icons"
import React from "react"
import {withTranslation} from "react-i18next"
import {connect} from "react-redux"
import {bindActionCreators, compose} from "redux"
import {openConfirmDialog} from "../Dialogs/ConfirmDialog/actions"


const styles = theme => ({
    root: {
        height: "100%",
        marginTop: theme.spacing(3)
    },
    center: {
        textAlign: "center"
    }
});

type Props = {
    classes: typeof styles,
    theme: any,
    openConfirmDialog: typeof openConfirmDialog,
    deleteEntity: () => {},
    confirmMessage: string,
    t: any,
}

class DeleteButton extends React.Component<Props, {}> {

    deleteEntity = (event: SyntheticEvent<HTMLButtonElement>) => {
        const {t} = this.props;
        this.props.openConfirmDialog(t("common.error.danger"), this.props.confirmMessage, () => {
            this.props.deleteEntity();
        });
    };

    render() {
        return (
            <IconButton disabled={this.props.disabled} onClick={this.deleteEntity}>
                <Delete/>
            </IconButton>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    let actions = bindActionCreators({
        openConfirmDialog: openConfirmDialog,
    }, dispatch);
    return {...actions, dispatch};
}

function mapStateToProps(state) {
    return {};
}

export default compose(
    withStyles(styles),
    withTheme,
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps)
)(DeleteButton);
