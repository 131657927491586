//@flow

import React from "react"
import {useTranslation, withTranslation} from "react-i18next"
import {compose} from "redux"
import {AbortButton as LibAbortButton, Button as LibButton} from "@greenbone/cloud-component-library"
import styled from "styled-components"
import {HostValidationWizard} from "../../Wizard/components/HostValidationWizard"

const Button = styled(LibButton)`
  padding: 0;
`

const AbortButton = styled(LibAbortButton)`
    padding: 0;
`


type Props = {
    host: any;
    targetId: string;
    resetContactSelection: EventCallback;
    resendEmail: EventCallback;
    handleDrawerOpen: any;
    isOpen: boolean;
    handleDrawerClose: any;
    t: any;
}

const ActionButtonGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 0.625rem;
`;

type LockableButtonState = {
    disabled: boolean;
}

type LockableButtonProps = {
    resendEmail: EventCallback;
    t: any;
}

const LockableEmailResendButton = withTranslation()(
    class _LockableEmailResendButton extends React.Component<LockableButtonProps, LockableButtonState> {
        state = {
            disabled: false
        };

        handleClick = (event: any) => {
            this.props.resendEmail(event);
            this.setState({disabled: true});
        };

        render() {
            const {t} = this.props;
            return (
                <AbortButton disabled={this.state.disabled}
                             onClick={this.handleClick}>{t("hostValidation.components.entryActions")}</AbortButton>
            );
        }
    }
);

const EmailRequest = () => {
    const {t} = useTranslation();

    return <Button disabled>
        {t("hostValidation.components.entryActions.sendNewMail")}
    </Button>;
};

class _HostValidationEntryActions extends React.Component<Props, any> {

    ActionButton: GSPValidationStatus = {
        "INITIALISED": null,
        "REQUESTED": null,
        "FETCHED": <Button
            onClick={this.props.handleDrawerOpen}>{this.props.t("wizard.components.hostValidation.askFor")}</Button>,
        "CONTACT_SELECTED": <AbortButton
            onClick={this.props.resetContactSelection}>{this.props.t("hostValidation.components.entryActions.deleteContact")}</AbortButton>,
        "ADMIN_SELECTED": <AbortButton
            onClick={this.props.resetContactSelection}>{this.props.t("hostValidation.components.entryActions.deleteContact")}</AbortButton>,
        "EMAIL_REQUESTED": <AbortButton
            disabled>{this.props.t("hostValidation.components.entryActions.deleteContact")}</AbortButton>,
        "EMAIL_REQUESTED_FAILED": null,
        "EMAIL_SENT": null,
        "REJECTED": null,
        "APPROVED": null
    };
    ResendEmailButton: GSPValidationStatus = {
        "INITIALISED": null,
        "REQUESTED": null,
        "FETCHED": null,
        "CONTACT_SELECTED": <LockableEmailResendButton resendEmail={this.props.resendEmail}/>,
        "ADMIN_SELECTED": null,
        //$FlowFixMe - Known flow bug
        "EMAIL_REQUESTED": <EmailRequest/>,
        "EMAIL_REQUESTED_FAILED": null,
        "EMAIL_SENT": null,
        "REJECTED": null,
        "APPROVED": null
    };

    render() {
        const {host, targetId, isOpen, handleDrawerClose} = this.props;

        return <ActionButtonGrid>
            {this.ActionButton[host.validationStatus]}
            {this.ResendEmailButton[host.validationStatus]}

            <HostValidationWizard targetId={targetId}
                                  hostId={host.id}
                                  _isOpen={isOpen}
                                  onClose={handleDrawerClose}
                                  representation={host.representation}

            />
        </ActionButtonGrid>;
    }
}

export const HostValidationEntryActions = compose(
    withTranslation()
)(_HostValidationEntryActions);
