import React, {useContext, useEffect, useState} from "react"
import {Col, Row} from "reactstrap"
import {Button, FlexRow, Headline, Paragraph} from "@greenbone/cloud-component-library"
import {SubscriptionContext} from "../SubscriptionProvider"
import {Link} from "react-router-dom"
import {useTranslation} from "react-i18next"
import {JWT} from "../../../Helper/JWT"
import {GroupSettingsUrlProvider} from "../../../UrlProvider/GroupSettingsUrlProvider"


export function TerminateSubscription(props) {

    const [isManaged, setManaged] = useState(null)
    const {subscriptions} = useContext(SubscriptionContext)
    const {t} = useTranslation()

    useEffect(() => {
        const jwt = new JWT()

        if (jwt.isManaged()) {
            setManaged(true)

        } else {
            setManaged(false)
        }
    }, [])

    const hasSubscription = (() => {
        if (!!subscriptions) {
            return true
        }
        return false

    })()

    if (isManaged === null) {
        return null
    }

    if (isManaged === true) {
        return <>
            <Row>
                <Col>
                    <Headline>{t("terminateSubscription")}</Headline>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Paragraph>
                        {t("terminate.partnerMessage")}
                    </Paragraph>
                </Col>
            </Row>
        </>
    }

    if (hasSubscription && isManaged === false) {
        return <>
            <Row>
                <Col>
                    <Headline>{t("terminateSubscription")}</Headline>
                </Col>
            </Row>
            {((!subscriptions?.next) && (!subscriptions?.next)) && <Row>
                <Col>
                    <Paragraph>
                        {t("planPage.nothingToTerminate")}

                    </Paragraph>
                </Col>
            </Row>}
            <Row>
                <Col>
                    <FlexRow>
                        {subscriptions?.next && <Link
                            to={GroupSettingsUrlProvider.terminate()}>
                            <Button>
                                {t("terminateSubscription.button")}
                            </Button>
                        </Link>}
                    </FlexRow>
                </Col>
            </Row>
        </>
    } else {
        return null
    }

}
