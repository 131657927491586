import {RestApiClient} from "@greenbone/cloud-component-library"
import {globalInstanceStorage} from "../../../../GlobalSingle"


function ErrorHandler(e) {
    throw e
}

export class ReportRestApiClient {
    constructor(
        apiClient = new RestApiClient(
            globalInstanceStorage.getFetchClient(),
            ErrorHandler
        )) {
        this.apiClient = apiClient
    }

    async getHead(id) {
        return await this.apiClient.get(`/reports/${id}/small`)
    }

    async filteredReportFull(id, body) {
        return await this.apiClient.post(`/reports/${id}/vulnerabilities`, body)
    }

    async getUniqueHosts(id) {
        return await this.apiClient.get(`/reports/${id}/host/unique`)
    }

    async getUniquePorts(id) {
        return await this.apiClient.get(`/reports/${id}/port/unique`)
    }

    async getGroupedByHosts(id, body) {
        return await this.apiClient.post(`/reports/${id}/vulnerabilities/groupBy/host`, body)
    }

    async getUniqueHostNames(id) {
        return await this.apiClient.get(`/reports/${id}/hostname/unique`)
    }

    async getUniqueHostOperatingSystems(id) {
        return await this.apiClient.get(`/reports/${id}/operatingSystem/unique`)
    }

    async getGroupedByVulnerability(id, body) {
        return await this.apiClient.post(`/reports/${id}/vulnerabilities/groupBy/name`, body)
    }

    async getVulnerability(report, oid) {
        return await this.apiClient.get(`/reports/${report}/oid/${oid}/details`)
    }

    async topHostsByOid(report, oid, body) {
        return await this.apiClient.post(`/reports/${report}/top/hosts/oid/${oid}`, body)
    }

    async topPortsByOid(report, oid, body) {
        return await this.apiClient.post(`/reports/${report}/top/ports/oid/${oid}`, body)
    }

    async vulnerabilitiesByHostAndSeverity(report, ip, range, body) {
        return await this.apiClient.post(`/reports/${report}/host/${ip}/${range}`, body)
    }

}
