import React from "react"
import {connect} from "react-redux"
import {bindActionCreators, compose} from "redux"
import styled from "styled-components"
import DownloadIcon from "../../../../assets/icons/white/download-bottom.svg?url"
import {FlexRow, Snackbar} from "@greenbone/cloud-component-library"
import Loader from "../../../Components/Loader/Loader"
import {openErrorSnackbar} from "../../../Components/Snackbar/actions"
import {Select} from "../../../StyledComponents/Form/Select"
import {Colors} from "../../../Theme"
import {withTranslation} from "react-i18next"

const types = {
    "VMWARE": "VMWARE",
    "HYPERV": "HYPERV"
}

const options = [
    {value: types.VMWARE, label: "VMWare ESXi"},
    {value: types.HYPERV, label: "Hyper-V"}
]


const DownloadControl = styled(FlexRow)`
  min-width: 12.5rem;
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
`

const SaveButton = styled.div`
  background: ${props => props.theme.button.normal.background};
  min-width: 2.188rem;
  max-width: 2.188rem;
  height: 2.188rem;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;

   &:hover {
    cursor: pointer;
    background: ${props => props.theme.button.normal.hover};
   }

   &:active {
    cursor: pointer;
    background: ${props => props.theme.button.normal.hover};
   }

  span {
      display: block;
      height: 100%;
      width: 100%;
      background:  url(${DownloadIcon});
      color: white;
      background-size: 70%;
      background-position: center;
      background-repeat: no-repeat;
  }
`

const DownloadText = styled.div`
  color: ${Colors.grey.normal};
  position: absolute;
  margin-top: -1.5rem;
`

function downloadURI(uri, name) {
    const link = document.createElement("a")
    link.download = name
    link.href = uri
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

class _GatewaySelectDownloader extends React.Component {

    state = {
        selectedOption: options[0].value,
        _loading: false,
        _exception: null
    }

    handleChange = ({name, value}: { name: string, value: any }) => {
        this.setState({selectedOption: value})
    }

    handleClick = (event: any) => {
        event.preventDefault()

        this.downloadGateway()
    }

    downloadGateway = async () => {
        const {t} = this.props

        switch (this.state.selectedOption) {

            case types.VMWARE: {
                downloadURI(`https://${window.location.host}/download/GMSP_Gateway_1.7.5.ova`, "GMSP_Gateway_1.7.5.ova")
                break
            }

            case types.HYPERV : {
                downloadURI(`https://${window.location.host}/download/GMSP_Gateway_1.7.5.zip`, "GMSP_Gateway_1.7.5.zip")
                break
            }

            default:
                Snackbar.Error(t("reportSelectDownload.downloadError"))
                return
        }


    }

    render() {
        const {selectedOption, _loading, _exception} = this.state

        return (
            <DownloadControl>
                <DownloadText>Download</DownloadText>
                <FlexRow>
                    <Select
                        value={selectedOption}
                        onChange={this.handleChange}
                        options={options}
                        name={"report type"}
                    />
                    <SaveButton error={_exception && true} loading={_loading.toString()} onClick={this.handleClick}>
                        {_loading ? <Loader type={"INLINE"}/> : <span></span>}
                    </SaveButton>
                </FlexRow>
            </DownloadControl>

        )
    }
}

function mapDispatchToProps(dispatch: any) {
    let actions = bindActionCreators({
            openErrorSnackbar: openErrorSnackbar
        }, dispatch
    )
    return {...actions, dispatch}
}

function mapStateToProps(state) {
    return {}
}


export const GatewaySelectDownloader = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(_GatewaySelectDownloader)

