export class GatewayUrlProvider {
    ENTITY_ROUTE = "/gateway";

    getAll() {
        return `${this.ENTITY_ROUTE}`;
    }

    getOne(id) {
        return `${this.ENTITY_ROUTE}/${id}`;
    }

    create() {
        return `${this.ENTITY_ROUTE}`;
    }

    update(id) {
        return `${this.ENTITY_ROUTE}/${id}`;
    }

    delete(id) {
        return `${this.ENTITY_ROUTE}/${id}`;
    }
}
