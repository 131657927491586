import InputAdornment from "@material-ui/core/InputAdornment"
import Search from "@material-ui/icons/Search"
import React, {useContext, useRef, useState} from "react"
import {useTranslation} from "react-i18next"
import {Input} from "../../../StyledComponents/Form/Input"
import {ReportContext} from "../ReportContext"
import {FlexRow, Spinner} from "@greenbone/cloud-component-library"


export function ReportSearch(props) {
    const {t} = useTranslation()

    const [text, setText] = useState("")
    const [isBouncing, setBouncing] = useState(false)
    const ref = useRef({timer: null})
    const {search, hasBeenFlushed} = useContext(ReportContext)

    const applySearch = text => {
        search(text)
    }

    const handleChange = (event) => {
        const {value} = event.target

        if (value.length > 257) {
            return
        }

        setBouncing(true)
        setText(value)


        if (ref.current.timer) {
            window.clearTimeout(ref.current.timer)
        }
        ref.current.timer = window.setTimeout(() => {

            applySearch(value)
            setBouncing(false)
        }, 1000)


    }


    return <FlexRow>
        <Input style={{width: "15.63rem"}} value={text} onChange={handleChange} placeholder={t("common.search")}
               label={""}
               adornment={(
                   <InputAdornment position="start">
                       <Search/>
                   </InputAdornment>
               )}/>
        {(showLoadingAnimation(text, isBouncing, hasBeenFlushed)) &&
        <Spinner style={{marginTop: "8px", marginLeft: "8px"}}/>}
    </FlexRow>
}


function showLoadingAnimation(searchTerm, isBouncing, hasBeenFlushed) {

    if (searchTerm) {
        if (isBouncing) {
            return true
        }

        if (!hasBeenFlushed) {
            return true
        }
    }
    return false
}
