import React from "react"
import {withTranslation} from "react-i18next"
import {connect} from "react-redux"
import Promise from "bluebird"
import {bindActionCreators, compose} from "redux"
import {ButtonSpinner} from "../../Components/Animations/Button/ButtonSpinner"
import {Button, FlexRow, Snackbar} from "@greenbone/cloud-component-library"
import {Spacer} from "../../Components/Basic/Spacer"
import Loader from "../../Components/Loader/Loader"
import {Address, AddressFormFieldsValid} from "./Address"
import {Headline} from "../../StyledComponents/Font/Font"
import {GroupRestApiClient} from "../UserRestApiClient"
import {IsInteger, IsNotEmptyString} from "../../controller/FormValidators"
import {getValidationResult} from "../../controller/FieldValidator"
import {SubscriptionContext} from "../Subscritpion/SubscriptionProvider"


type Props = {
    theme: any;
    t: any;
}

type State = {
    fields: GSPBillingAddress;
    fieldsValid: AddressFormFieldsValid;
    _exception: ?Error;
    _loading: boolean,
    _saving: boolean,
    isValid: boolean,
}

class _ProfileForm extends React.Component<Props, State> {

    FieldValidators = {
        title: new IsNotEmptyString(),
        firstName: new IsNotEmptyString(),
        lastName: new IsNotEmptyString(),
        companyName: new IsNotEmptyString(),
        streetAddress: new IsNotEmptyString(),
        houseNumber: new IsNotEmptyString(),
        postalCode: new IsNotEmptyString(),
        city: new IsNotEmptyString(),
        countryId: new IsInteger("A country must be selected")
    };

    state = {
        fields: {
            city: "",
            companyName: "",
            firstName: "",
            houseNumber: "",
            lastName: "",
            postalCode: "",
            streetAddress: "",
            title: "Herr",
            countryId: null
        },
        fieldsValid: {
            city: null,
            companyName: null,
            houseNumber: null,
            postalCode: null,
            streetAddress: null,
            countryId: null
        },
        countryCodes: [],
        _exception: null,
        _loading: false,
        _saving: false,
        isValid: false
    };

    constructor(props) {
        super(props);
        this.setStateSync = Promise.promisify(this.setState);
        this.groupRestApiClient = new GroupRestApiClient();
    }

    componentDidMount() {
        this.setState({_loading: true})
        this.groupRestApiClient.getCountryCodes()
            .then(codes => {
                this.setState({countryCodes: codes})
            })
            .catch(e => console.log(e))

        this.groupRestApiClient.getBillingAddress()
            .then(user => {
                if (!user) {
                    return
                }
                const fields = {
                    city: user.city || "",
                    companyName: user.companyName || "",
                    firstName: user.firstName || "",
                    houseNumber: user.houseNumber || "",
                    lastName: user.lastName || "",
                    postalCode: user.postalCode || "",
                    streetAddress: user.streetAddress || "",
                    title: user.title || "",
                    countryId: user.countryId || null

                }
                this.setState({fields}, this.validateFields);
            })
            .catch(exception => {
                this.setState({_exception: exception});
            })
            .finally(() => {
                this.setState({_loading: false});
            });
    }

    handleChange = (event) => {
        const {name, value} = event.target;
        this.setState(prevState => ({fields: {...prevState.fields, [name]: value}}))
    };


    validate = async () => {
        const {isValid, fieldValidity} = getValidationResult(
            this.state.fields,
            this.FieldValidators
        )



        await this.setStateSync({fieldsValid: fieldValidity})

        return isValid
    };

    handleSaveUserInformation = async (event: any) => {
        const {t} = this.props;
        event.preventDefault();

        const isValid = await this.validate();
        if (!isValid) {
            return;
        }

        const userUpdate = {
            title: this.state.fields.title || "",
            firstName: this.state.fields.firstName || "",
            lastName: this.state.fields.lastName || "",
            companyName: this.state.fields.companyName || "",
            city: this.state.fields.city || "",
            houseNumber: this.state.fields.houseNumber || "",
            streetAddress: this.state.fields.streetAddress || "",
            postalCode: this.state.fields.postalCode || "",
            countryId: this.state.fields.countryId

        };

        this.setState({_saving: true});
        this.groupRestApiClient.setBillingAddress(userUpdate)
            .then(() => {
                Snackbar.Success(t("common.messages.success.saved"));
                this.context.update();
            })
            .catch(exception => {
                Snackbar.Error(exception.message);
            })
            .finally(() => {
                this.setState({_saving: false});
            });

    };

    render() {
        const {fieldsValid, fields, _exception, _loading, _saving} = this.state;
        const {t} = this.props;

        if (_exception) {
            throw _exception;
        }

        if (_loading) {
            return <Loader/>;
        }

        return <React.Fragment>
            <Headline>{t("common.profile.groupAddress")}</Headline>
            <Address countrys={this.state.countryCodes} validFields={fieldsValid} fields={fields}
                     handleChange={this.handleChange}/>
            <Spacer height={"1.56rem"}/>
            <FlexRow justifyContent={"flex-end"}>
                <Button disabled={_saving} onClick={this.handleSaveUserInformation}>
                    <ButtonSpinner hidden={!_saving}/>
                    {t("common.profile.groupInformationSaved")}
                </Button>
            </FlexRow>
        </React.Fragment>;
    }
}

_ProfileForm.contextType = SubscriptionContext;

function mapDispatchToProps(dispatch: any) {
    const actions = bindActionCreators({}, dispatch);
    return {...actions, dispatch};
}

export const GroupBaseAddressInformation = compose(
    withTranslation(),
    connect(null, mapDispatchToProps)
)(_ProfileForm);
