import React, {useContext, useEffect, useMemo} from "react"

import {SimpleEntityContext} from "../../../services/Context/SimpleEntityContext"
import ActionElements from "../../../Components/ActionElements"
import {useTranslation} from "react-i18next"
import {DynamicTable, DynamicTableCell, DynamicTableRow} from "../../Task/components/Table/DynamicTable"


export function CredentialsList({actions, onUpdateEntry}) {

    const {credentials, deleteCredentials, updateCredentials} = useContext(SimpleEntityContext)
    const data = React.useMemo(() => credentials, [credentials])
    const memoizedUpdateCredentials = useMemo(() => updateCredentials, [updateCredentials])

    useEffect(() => {
        memoizedUpdateCredentials()
    }, [memoizedUpdateCredentials])

    const {t} = useTranslation()


    const columns = [
        {
            Header: t("credentials.components.information.name"),
            accessor: "name"
        },
        {
            Header: t("credentials.components.information.loginName"),
            accessor: "login"
        },
        {
            Header: t("schedule.list.comment"),
            accessor: "comment"
        },
        {
            Header: t("task.header.action"),
            accessor: () => ""
        }
    ]


    const entryGenerator = ({row, getValue, dataset}) => {

        return <DynamicTableRow {...row.getRowProps()}>
            <DynamicTableCell>
                {getValue("name")}
            </DynamicTableCell>
            <DynamicTableCell>
                {getValue("login")}
            </DynamicTableCell>
            <DynamicTableCell>
                {getValue("comment")}
            </DynamicTableCell>
            <DynamicTableCell>
                <ActionElements
                    entity={dataset}
                    onUpdateEntry={() => onUpdateEntry(dataset.id)}
                    deleteEntity={() => deleteCredentials(dataset.id)}
                    confirmMessage={t(`credentials.deleteConfirm`, {name: dataset.name})}
                />
            </DynamicTableCell>
        </DynamicTableRow>
    }

    return <>
        <DynamicTable data={data}
                      actions={actions}
                      entryGenerator={entryGenerator}
                      columns={columns}
                      searchableColumns={["name", "comment", "login"]}
                      defaultSortBy={["name"]}
                      cacheKey={"CredentialsList/2020-10-01"}/>
    </>
}
