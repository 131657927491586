import {MathHelper} from "./MathHelper";

export class WorkableNumber {
    value = 0;

    constructor(value = 0) {
        this.value = value;
    }

    addSumUp(list, accessor) {
        return new WorkableNumber(this.value + MathHelper.SumUp(list, accessor));
    }

    getPercentage(partitial) {
        return new WorkableNumber(MathHelper.Percentage(this.value, partitial));
    }

    toFixed(fraction = 0) {
        const roundedValue = this.value.toFixed(fraction);
        if (roundedValue === '0') {
            return '1';
        } else {
            return roundedValue;
        }
    }


}
