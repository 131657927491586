function scaleLinear(from, to, value) {
    const A = from[0]
    const B = from[1]
    const C = to[0]
    const D = to[1]
    const x = value
    return C * (1 - (x - A) / (B - A)) + D * ((x - A) / (B - A))
}

export const updateTooltipFactory = (min, max, tooltipRef, outerRef, tooltipColor, tooltipValue, textColor) => (value) => {

    const outerWidth = outerRef.offsetWidth
    const tooltipWidth = tooltipRef.offsetWidth

    if (tooltipValue)
        tooltipRef.innerHTML = `<span style="color: ${textColor}">${tooltipValue(value)}</span>`
    else
        tooltipRef.innerHTML = `<span style="color: ${textColor}">${value}%</span>`

    const left = scaleLinear([min, max], [min, outerWidth], value)
    const sub = scaleLinear([max / 2, min], [min, 8], value)

    if (tooltipColor) {
        tooltipRef.style.background = tooltipColor(value)
        tooltipRef.style.color = tooltipColor(value)
    }
    tooltipRef.style.marginLeft = `${left + sub - tooltipWidth / 2}px`
}

const applyStyles = (styleRef, setStyleRef, css) => {
    const head = document.head || document.getElementsByTagName("head")[0]

    if (styleRef) {
        styleRef.innerHTML = css
    } else {
        const style = document.createElement("style")
        style.appendChild(document.createTextNode(css))
        head.appendChild(style)
        setStyleRef(style)
    }
}

export const staticGradientFactory = (min, max, styleRef, setStyleRef, classNameModifier, color) => steps => {
    let css = `.range-${classNameModifier}::-moz-range-track {background: linear-gradient(to right, `

    steps.forEach(gradient => {
        const start = scaleLinear([min, max], [0, 100], gradient.from)
        const end = scaleLinear([min, max], [0, 100], gradient.to)
        css += `${gradient.color} ${start}% , ${gradient.color} ${end}% ,`
    })

    css = css.substring(0, css.length - 1)
    css += `);}`

    css += `    .range-${classNameModifier}::-webkit-slider-runnable-track {background: linear-gradient(to right, `

    steps.forEach(gradient => {
        const start = scaleLinear([min, max], [0, 100], gradient.from)
        const end = scaleLinear([min, max], [0, 100], gradient.to)
        css += `${gradient.color} ${start}% , ${gradient.color} ${end}% ,`
    })

    css = css.substring(0, css.length - 1)
    css += `);}`


    applyStyles(styleRef, setStyleRef, css)
}

export const updateGradientFactory = (min, max, styleRef, setStyleRef, classNameModifier, color) => (low, high) => {


    const lowPercentage = scaleLinear([min, max], [0, 100], low)
    const highPercentage = scaleLinear([min, max], [0, 100], high)

    let css = `.range-${classNameModifier}::-moz-range-track {background: linear-gradient(to right, rgba(0,0,0,.2) 0%, rgba(0,0,0,.2) ${lowPercentage}%, ${color} ${lowPercentage}%, ${color} ${highPercentage}%, rgba(0,0,0,.2) ${highPercentage}%)}`
    css += `.range-${classNameModifier}::-webkit-slider-runnable-track {background: linear-gradient(to right, rgba(0,0,0,.2) 0%, rgba(0,0,0,.2) ${lowPercentage}%, ${color} ${lowPercentage}%, ${color} ${highPercentage}%, rgba(0,0,0,.2) ${highPercentage}%)}`

    applyStyles(styleRef, setStyleRef, css)

}
