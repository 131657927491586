import React, {useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import {FlexRow, Headline, SaveButton, Snackbar, useRequest} from "@greenbone/cloud-component-library"
import {GroupRestApiClient} from "../../../../User/UserRestApiClient"
import {LoadingBlock} from "../../../../Components/LoadingBlock"
import {IsUserManaged} from "../../../../Components/IsUserManaged"
import {ReportAccess} from "./ReportAcces"
import {JumpIn} from "./JumpIn"


export function ManagedSecuritySetting() {

    const {t} = useTranslation()

    const [state, setState] = useState({adminReportAccessEnabled: false, adminJumpInEnabled: false})
    const [isSaving, setSaving] = useState(false)

    const apiClient = new GroupRestApiClient()
    const [data, loading, error] = useRequest(apiClient.getSettings.bind(apiClient))


    useEffect(() => {
        if (!error && data) {
            setState(data)
        }
    }, [data, error])

    const handleSubmit = event => {
        event.preventDefault()

        const successMessage = t("settingsSavedSuccessfully")

        setSaving(true)
        apiClient.setSetting(state)
            .then(response => {
                setSaving(false)
                if (!response) { //Empty 201 or 200
                    Snackbar.Success(successMessage)
                    return
                }

                if (response?.hasOwnProperty("adminReportAccessEnabled")) { //Checking for 200 with body
                    Snackbar.Success(successMessage)
                    return
                }

                throw response
            })
            .catch(e => {
                setSaving(false)
                if (e?.status === 428 || e?.status === "PRECONDITION_REQUIRED") {
                    Snackbar.Error(t("onlyAvailableForManagedCustomers"))
                    return
                }
                Snackbar.Error(t("anErrorOccurred"))
            })
    }

    return <IsUserManaged>
        {({isManaged}) => <>
            <Headline>
                Managed Security
            </Headline>

            <LoadingBlock isLoading={loading} error={error?.status !== 428 ? error : undefined}>

                <ReportAccess adminReportAccessEnabled={state?.adminReportAccessEnabled} isManaged={isManaged}
                              setState={setState}/>
                <JumpIn adminJumpInEnabled={state?.adminJumpInEnabled} isManaged={isManaged}
                        setState={setState}/>

                <FlexRow justifyContent={"flex-end"}>
                    <IsUserManaged>
                        <SaveButton saving={isSaving} onClick={handleSubmit}>Save</SaveButton>
                    </IsUserManaged>
                </FlexRow>

            </LoadingBlock>
        </>}
    </IsUserManaged>
}
