import {MuiThemeProvider} from "@material-ui/core/styles"
import React from "react"
import {useTheme} from "styled-components"
import createMuiTheme from "@material-ui/core/styles/createTheme"
import {Colors, ColorTheme} from "./Theme"

function useCustomMuiTheme() {

    const styledTheme = useTheme()

    return createMuiTheme({
        palette: {
            primary: {
                main: ColorTheme.background.normal,
                light: "#828da4",
                dark: ColorTheme.background.dark,
                contrastText: "#ffffff"
            },
            secondary: {
                main: styledTheme?.button?.normal?.background || ColorTheme.background.normal,
                light: "#941926",
                dark: "#E21E3A",
                contrastText: "#ffffff"
            }
        },
        grid: {
            spacing: 24,
            width: {
                full: 12,
                half: 6,
                oneQuarter: 3,
                threeQuarter: 9,
                oneThird: 4,
                twoThird: 8
            }
        },
        overrides: {
            MuiListItemIcon: {
                root: {
                    marginRight: "1px"
                }
            },
            MuiCircularProgress: {
                colorSecondary:
                    {color: Colors.green.normal}
            },
            MuiListItemText: {
                inset: {
                    "&:first-child": {
                        paddingLeft: "2.56rem"
                    }
                },
                root: {
                    padding: "0 1.00rem"
                }
            },
            MuiListItem: {
                gutters: {
                    "@media (min-width: 600px)": {
                        paddingLeft: "1.50rem",
                        paddingRight: "1.50rem"
                    }
                },
                root: {
                    paddingTop: "0.69rem",
                    paddingBottom: "0.69rem"
                }
            },
            MuiToolbar: {
                regular: {
                    height: "4.00rem",
                    minHeight: "4.00rem",
                    "@media (min-width: 600px)": {
                        minHeight: "4.00rem"
                    }
                }
            },
            MuiIconButton: {
                root: {
                    padding: "0.55rem"
                }
            },
            MuiTypography: {
                caption: {
                    fontSize: "1rem"
                }
            },
            MuiTablePagination: {
                root: {
                    fontSize: "1rem"
                }
            },
            MuiFormControlLabel: {
                root: {
                    marginLeft: "-0.70rem"
                }
            },
            MuiChip: {
                root: {
                    transition: "none",
                    fontSize: "1rem",
                    borderRadius: "2px",
                    backgroundColor: Colors.grey.dark
                },
                label: {
                    color: "white"
                },
                deleteIcon: {
                    color: "white"
                }
            },
            MuiSvgIcon: {
                root: {
                    fontSize: "20px"
                }
            },
            MuiRadio: {
                colorPrimary: {
                    color: Colors.grey.normal,
                    "&$checked": {
                        color: Colors.grey.normal
                    }
                }
            }
        },

        typography: {
            useNextVariants: true,
            fontFamily: "\"DroidSans\", \"sans-serif\""
        }
    })


}

export function MuiThemeLayer({children}) {
    const theme = useCustomMuiTheme()

    return <>
        <MuiThemeProvider theme={theme}>
            {children}
        </MuiThemeProvider>
    </>
}
