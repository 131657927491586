export class StyleRestApiClient {
    constructor(fetchClient) {
        this.httpClient = fetchClient;
    }

    async getStyles() {
        const response = await this.httpClient.get("/vmssp-admin/config/");
        return JSON.parse(await response.text());
    }

    async setStyles(styles) {
        return this.httpClient.put("/vmssp-admin/config", styles);
    }
}

