import React from "react"
import {Route} from "react-router"
import {ReportDashboard} from "./views/ReportDashboard"
import {ReportGrid} from "./views/ReportGrid"
import {ReportTable} from "./views/ReportTable"
import {GroupedByHost} from "./views/GroupedByHost"
import {GroupedByVulnerability} from "./views/GroupedByVulnerability"
import {Switcher} from "../../Components/Router";

export function ReportRouter() {
    return <Switcher>
        <Route path="/dashboard" Component={ReportDashboard}/>
        <Route path="/grid" Component={ReportGrid}/>
        <Route path="/table/group/host" Component={GroupedByHost}/>
        <Route path="/table/group/name" Component={GroupedByVulnerability}/>
        <Route strict path="/table" Component={ReportTable}/>
    </Switcher>;
}
