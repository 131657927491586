import {FlexRow} from "../Flex"
import {Caption} from "../Font"
import {Input} from "../Input"
import {InputAdornment} from "@material-ui/core"
import Search from "../../../assets/icons/Search"
import React from "react"
import {TableHead, TableRow} from "./index"
import SvgArrowDown from "../../../assets/icons/ArrowDownward"
import SvgArrowUpward from "../../../assets/icons/ArrowUpward"

const TableHeadSorted = ({direction, children, active, onClick, numeric}) => <TableHead numeric={numeric} clickable
                                                                                        onClick={onClick}>
    <FlexRow alignItems={"center"} justifyContent={numeric ? "flex-end" : "flex-start"}>
        {active && <>
            {direction === "asc" ? <SvgArrowUpward style={{width: "1rem", height: "1rem"}}/> :
                <SvgArrowDown style={{width: "1rem", height: "1rem"}}/>}
        </>}

        {children}
    </FlexRow>

</TableHead>;
TableHeadSorted.displayName = "TableHeadSorted";

export const SortableTableHead = ({headline, actions, onRequestSort, order, orderBy, columns, showSearchField, handleSearchInputChange}) => {
    const createSortHandler = column => event => {
        onRequestSort(event, column);
    };

    return (
        <thead>
        <TableRow>
            <TableHead style={{padding: 0}} colSpan={columns.length}>
                <FlexRow alignItems={"center"} full justifyContent={"space-between"}>
                    <div className={"table-action-header"}>
                        <Caption style={{marginBottom: "0px"}}>
                            {headline}
                        </Caption>

                        <div className={"table-action-containment"}>
                            {actions}
                        </div>
                    </div>
                    <div style={{maxWidth: "12.5rem"}}>
                        {showSearchField && <Input name={"search"} onChange={handleSearchInputChange}
                                                   placeholder={"Search"}
                                                   style={{"fontWeight": "normal"}}
                                                   InputProps={{
                                                       startAdornment: (
                                                           <InputAdornment position="start">
                                                               <Search style={{width: "1.125rem"}}/>
                                                           </InputAdornment>
                                                       )
                                                   }}
                        />}
                    </div>

                </FlexRow>
            </TableHead>


        </TableRow>
        <TableRow>
            {columns.map(column => {
                if (column.sortable) {
                    return <TableHeadSorted
                        numeric={column.numeric}
                        key={column.id}
                        direction={order}
                        active={orderBy === column.id}
                        onClick={column.sortable ? createSortHandler(column) : null}>
                        {column.label}
                    </TableHeadSorted>;
                }

                return <TableHead numeric={column.numeric} key={column.id}>
                    {column.label}
                </TableHead>;
            })}
        </TableRow>
        </thead>
    );
};

