import React, {Component} from "react"
import * as d3 from "d3"
import {DonutController} from "./DonutController"
import {DonutFactory} from "./DonutFactory"
import {Dot, Li} from "./DonutComponents"
import {XMLParser} from "../Helper/XMLParser"


/**
 * React Component representing a RadialChart
 * Example usage:
 *             <Donut data={data}
 width={322}
 height={322}
 accessor={d => d.amount}
 nameAccessor={d => d.planName} (optional)
 colorScheme={["#006B20", "#55C14A", "#00B3B7", "#B1EA8D"]} (optional)
 showLegend={true} (optional)
 thickness={50} (optional)
 mainTextColor={"#4C4C4D"} (optional)
 helperTextColor={"#707070"} (optional)
 helperText={"Total Internal"} (optional)
 */
export class Donut extends Component {

    constructor(props) {
        super(props)

        this.dimensions = {
            width: this.props.width,
            height: this.props.height,
            thickness: this.props.thickness || 20,
            iconSize: 30
        }


        this.data = DonutController.ConvertDataForDrawing(this.props.data, this.props.accessor, this.props.colorScheme)
        this.radius = Math.min(this.dimensions.width, this.dimensions.height) / 2
    }

    getMainTextColor = () => this.props.mainTextColor || "#000000"
    getHelperTextColor = () => this.props.helperTextColor || "#000000"

    componentDidMount() {
        this.createBaseGraph()
        this.draw(DonutController.GenerateNullData(this.data))
        this.draw(this.data)
    }


    shouldComponentUpdate(nextProps, nextState) {
        this.data = DonutController.ConvertDataForDrawing(nextProps.data, nextProps.accessor)
        this.draw(this.data)
        return false
    }

    createBaseGraph() {
        const node = this.node

        if (this.props.icon) {
            d3.select(node)
                .append("circle")
                .attr("r", 23)
                .attr("cx", this.dimensions.width / 2)
                .attr("cy", this.dimensions.height / 2)
                .attr("fill", "#EBF5E5")

            const iconGroup = d3.select(node)
                .append("g")
                .attr("class", "icon-group")
                .attr("transform", `translate(${this.dimensions.width / 2} , ${this.dimensions.height / 2})`)
                .node()
                .appendChild(XMLParser.Parse(this.props.icon).documentElement)

            iconGroup.setAttribute("width", `${this.dimensions.iconSize}`)
            iconGroup.setAttribute("height", `${this.dimensions.iconSize}`)
            iconGroup.setAttribute("class", "icon-svg")


            d3.select(node)
                .select("g.icon-group")
                .attr("transform", `translate(${this.dimensions.width / 2 - this.dimensions.iconSize / 2} , ${this.dimensions.height / 2 - this.dimensions.iconSize / 2})`)

        }


        const g = d3.select(node)
            .attr("viewBox", `0 0 ${this.dimensions.width} ${this.dimensions.height}`)
            .append("g")
            .attr("class", "canvas")
            .attr("transform", `translate(${this.dimensions.width / 2},${this.dimensions.height / 2})`)

        if (!this.props.icon) {
            g.append("text")
                .text(DonutController.CalculateTotal(this.data, this.props.accessor))
                .attr("class", "main")
                .attr("font-size", "3.125rem")
                .attr("dominant-baseline", "central")
                .style("text-anchor", "middle")
                .attr("fill", this.getMainTextColor())

            g.append("text")
                .text(this.props.helperText)
                .attr("font-size", ".875rem")
                .attr("dominant-baseline", "central")
                .attr("transform", `translate(${0},${this.dimensions.height / 8})`)
                .style("text-anchor", "middle")
                .attr("fill", this.getHelperTextColor())
        }

    }

    draw(data) {
        const node = this.node
        const arc = DonutFactory.ArcFactory(this.radius, this.dimensions.thickness)
        const arcTween = DonutFactory.ArcTweenFactory(arc)

        const pie = d3.pie()
            .value(d => d.count)
            .sort(null)

        d3.select(node).select("g")
            .select(".main")
            .transition()
            .text(DonutController.CalculateTotal(this.data, this.props.accessor))

        const g = d3.select(node).select("g.canvas")
            .attr("width", this.dimensions.width)
            .attr("height", this.dimensions.height)
            .attr("transform", `translate(${this.dimensions.width / 2},${this.dimensions.height / 2})`)

        const path = g.selectAll("path")
            .data(pie(data))
        path.transition().attrTween("d", arcTween)
        path.enter().append("path")
            .attr("fill", (d, i) => data[i].color)
            .attr("d", arc)
            .each(function (d) {
                this._current = d
            })
    }

    render() {
        if (this.props.showLegend) {
            if (!this.props.nameAccessor) {
                throw new Error("When using this options a nameAccessor must be passed as the nameAccessor props. For example: dataPoint => dataPoint.name")
            }
        }

        return <>
            <svg style={this.props.style} ref={node => this.node = node}>
            </svg>
            {this.props.showLegend && <>
                <ul style={{listStyle: "none", padding: 0}}>
                    {this.data.map((data, index) => <Li key={index}>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <Dot color={data.color}/>
                            <span>{this.props.nameAccessor(data)}</span>
                        </div>

                        <span>{data.count}</span>
                    </Li>)}
                </ul>
            </>}
        </>
    }
}


