// @flow
import React from "react"
import styled from "styled-components"


const Wrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.30);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Paper = styled.div`
  background-color: white;
  border-radius: 3px;
  box-shadow:  0 0 14px 0 rgba(0, 0, 0, 0.45);
  height: auto;
  width: auto;
  padding: 1.875rem;

`;

type Props = {
    children: any;
    open: boolean;
}

export class Modal extends React.Component<Props, {}> {

    render() {
        const {children, open} = this.props;

        if (!open) {
            return null;
        }

        return (
            <Wrapper>
                <Paper>
                    {children}
                </Paper>
            </Wrapper>

        );
    }
}
