import React from "react"
import {Trans, useTranslation, withTranslation} from "react-i18next"
import {Col, Row} from "reactstrap"
import {compose} from "redux"
import LocationTarget from "../../../assets/icons/currentColor/location-target_blue100.svg"
import NotesBook from "../../../assets/icons/currentColor/notes-book-text_blue100.svg"
import BlueShield from "../../../assets/icons/currentColor/Schild_Blau100_2.svg"
import SingleNeutral from "../../../assets/icons/currentColor/single-neutral-actions-lock_blue100.svg"
import StopWatch from "../../../assets/icons/currentColor/stopwatch_blue100.svg"
import TaskMultiple from "../../../assets/icons/currentColor/task-list-multiple_blue100.svg"
import {Bold, Caption} from "../../StyledComponents/Font/Font"
import {HelpLine} from "./components/HelpLine"


type Props = {
    t: any;
}

export const HowToStartAScan = () => {
    return <React.Fragment>
        <HelpLine icon={TaskMultiple}>

            <Trans i18nKey={"help.howToStartaScan.1"}>
                Erstellen Sie eine <Bold>Aufgabe</Bold>, die aus einem <Bold>Ziel</Bold>,
                <Bold> Anmeldedaten</Bold> (optional) und <Bold> einem Zeitplan</Bold> (optional) besteht.
            </Trans>

        </HelpLine>
        <HelpLine icon={BlueShield}>
            <Trans i18nKey={"help.howToStartaScan.2"}>
                Sobald Ihre zu <Bold>scannenden
                Hosts</Bold> (im <Bold>Ziel</Bold> hinterlegt) <Bold>validiert worden sind, starten</Bold> Sie in
                der <Bold>Scanverwaltung den Scan Ihrer Aufgabe</Bold>, falls kein Zeitplan
                hinterlegt wurde. Andernfalls greift der Zeitplan.
            </Trans>
        </HelpLine>
        <HelpLine icon={NotesBook}>
            <Trans i18nKey={"help.howToStartaScan.3"}>
                <Bold>Sehen</Bold> Sie in der <Bold>Scanverwaltung</Bold> Ihren <Bold>Report</Bold> ein.
                In diesem werden gefundene Schwachstellen, nach Schweregrad sortiert, dargestellt.
            </Trans>
        </HelpLine>
    </React.Fragment>
}

export const TasksTargetsCredentialsSchedules = () => {
    const {t} = useTranslation()
    return <React.Fragment>
        <HelpLine icon={LocationTarget} title={t("helpPage.target")}>
            <Trans i18nKey={"help.TasksTargetsCredentialsSchedules.1"}>
                Das <Bold>Ziel</Bold> sagt aus, <Bold>welche IP-Adressen
                gescannt</Bold> werden
                sollen.
            </Trans>
        </HelpLine>
        <HelpLine icon={SingleNeutral} title={t("helpPage.loginData")}>
            <Trans i18nKey={"help.TasksTargetsCredentialsSchedules.2"}>
                Legen Sie <Bold>Anmeldedaten</Bold> fest, um bei einem
                <Bold> Scan von öffentlichen Systemen</Bold> über SSH, SMB oder ESXi eine <Bold>Authentifizierung zu
                ermöglichen</Bold>.
            </Trans>
        </HelpLine>
        <HelpLine icon={StopWatch} title={t("helpPage.timePlan")}>
            <Trans i18nKey={"help.TasksTargetsCredentialsSchedules.3"}>
                <Bold>Planen</Bold> Sie den <Bold>Scan Ihrer Aufgabe</Bold>. Legen
                Sie eine <Bold>Startzeit</Bold>, eine Endzeit (optional), sowie
                das <Bold>Ausführungsintervall</Bold> und
                den <Bold>Intervall Abstand </Bold> fest.
            </Trans>
        </HelpLine>
    </React.Fragment>
}

export const ScanManagementAndReports = () => {
    const {t} = useTranslation()
    return <React.Fragment>
        <HelpLine icon={BlueShield} title={t("helpPage.scan")}>
            <Trans i18nKey={"help.ScanManagementAndReports.1"}>
                Ein <Bold>Scan</Bold> ist <Bold>a‌ktiver Status einer Aufgabe</Bold>, der einen Report als Ergebnis hat.
            </Trans>
        </HelpLine>
        <HelpLine icon={NotesBook} title={t("helpPage.report")}>
            <Trans i18nKey={"help.ScanManagementAndReports.2"}>
                Ein <Bold>Report</Bold> ist das <Bold>Ergebnis eines Scans</Bold> und beinhaltet mögliche <Bold>gefundene
                Schwachstellen</Bold>. Diese werden nach unterschiedlichen <Bold>Schweregraden sortiert</Bold>,
                dargestellt.
            </Trans>
        </HelpLine>
    </React.Fragment>
}

class _HelpPage extends React.Component<Props, any> {
    render() {
        const {t} = this.props
        return <React.Fragment>
            <Row>
                <Col xs={{order: 12, size: 12}} md={{order: 12, size: 12}} lg={{order: 0, size: 8}}>
                    <Row style={{"marginBottom": "2.50rem"}}>
                        <Col>
                            <Caption><Bold>{t("help.components.helpPage.howToStartAScan")}</Bold></Caption>
                            <HowToStartAScan/>
                        </Col>
                    </Row>
                    <Row style={{"marginBottom": "2.50rem"}}>
                        <Col>
                            <Caption><Bold>{t("help.components.helpPage.taskLoginDataAndPlans")}</Bold></Caption>
                            <TasksTargetsCredentialsSchedules/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Caption><Bold>{t("help.components.helpPage.scanManagement")}</Bold></Caption>
                            <ScanManagementAndReports/>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </React.Fragment>
    }
}

export const HelpPage = compose(
    withTranslation()
)(_HelpPage)

