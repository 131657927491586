//@flow

import {UrlProvider} from "./UrlProvider"


export class HelpUrlProvider extends UrlProvider {
    static BASE_URL = `${UrlProvider.ROOT_PATH}/help`;

    static getBase() {
        return HelpUrlProvider.BASE_URL
    }

    static knowledgebase() {
        return `${HelpUrlProvider.getBase()}/text`
    }

    static videos() {
        return `${HelpUrlProvider.getBase()}/videos`
    }

    static manual() {
        return `${HelpUrlProvider.getBase()}/manual`
    }

    static terms() {
        return `${HelpUrlProvider.getBase()}/terms`
    }
}
