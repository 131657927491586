class ValueCompare {
    _value;

    constructor(value) {
        this._value = value;
    }

    isInList(values) {
        return values.includes(this._value);
    }
}

export function Value(value) {
    return new ValueCompare(value);
}
