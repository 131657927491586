//@flow

import {IconButton, withStyles, withTheme} from "@material-ui/core";
import {Edit} from "@material-ui/icons";
import React from "react";
import {compose} from "redux";


const styles = theme => ({
    root: {
        height: "100%",
        marginTop: theme.spacing(3)
    },
    center: {
        textAlign: "center",
    },
});

type Props = {
    classes: typeof styles,
    theme: any,
    onUpdateEntry: () => void;
}

class EditButton extends React.Component<Props, {}> {

    render() {
        return (
            <IconButton onClick={this.props.onUpdateEntry}>
                <Edit/>
            </IconButton>
        );
    }
}

export default compose(
    withStyles(styles),
    withTheme
)(EditButton);
