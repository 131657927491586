import React from "react";
import * as d3 from "d3";
import {TopTenHelper} from "./TopTenHelper";

export class TopTenHosts extends React.Component {

    constructor(props) {
        super(props);


        this.colors = {
            high: "#D4003E",
            medium: "#FCB800",
            low: "#7DB5D0"
        };

        this.data = TopTenHelper.ConvertDataSet(this.props.data);

        this.dimensions = {
            width: 1000,
            height: this.data.length * 44 + 44
        };

        this.measurements = {
            outer: {
                width: 175
            },
            inner: {
                width: this.dimensions.width - 175 * 2
            }
        };


    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {

        const data = TopTenHelper.ConvertDataSet(nextProps.data);
        this.data = data;
        this.drawData(data);
        return false;
    }

    componentDidMount() {
        this.createBarChart();
    }


    drawHead() {
        const node = this.node;
        const svg = d3.select(node);
        const head = svg.append("g");
        const measurements = this.measurements;


        head
            .append("g")
            .attr("transform", (d, i) => `translate(0, 0)`)
            .attr("width", measurements.outer.width)
            .append("text")
            .attr("y", 44 / 2)
            .attr("x", measurements.outer.width / 2)
            .attr("fill", "#4C4C4D")
            .attr("font-size", "1rem")
            .attr("dominant-baseline", "central")
            .attr("font-weight", "bold")
            .style("text-anchor", "middle")
            .text("Host");

        head.append("text")
            .attr("y", 44 / 2)
            .attr("x", this.dimensions.width / 2)
            .attr("fill", "#4C4C4D")
            .attr("font-size", "1rem")
            .attr("dominant-baseline", "central")
            .style("text-anchor", "middle")
            .attr("font-weight", "bold")
            .text("Vulnerability");

        head
            .append("g")
            .attr("transform", (d, i) => `translate(${measurements.outer.width + measurements.inner.width}, 0)`)
            .attr("width", measurements.outer.width)
            .append("text")
            .attr("y", 44 / 2)
            .attr("x", measurements.outer.width / 2)
            .attr("fill", "#4C4C4D")
            .attr("font-size", "1rem")
            .attr("dominant-baseline", "central")
            .attr("font-weight", "bold")
            .style("text-anchor", "middle")
            .text("Quantity");

        head.append("rect")
            .attr("x", 0)
            .attr("y", 44)
            .attr("height", 2)
            .attr("class", "seperator")
            .attr("width", 1000)
            .style("fill", "black");
    }


    drawData(data) {
        const node = this.node;
        const svg = d3.select(node);
        const measurements = this.measurements;

        svg.selectAll("g.entry")
            .data(data)
            .each(function (d) {

                const rectStart = measurements.outer.width;


                const max = d3.max(data, d => d.sum);

                const subScale = d3.scaleLinear()
                    .domain([0, max])
                    .range([0, measurements.inner.width]);

                d3.select(this).select(".category")
                    .transition()
                    .text(d.host);

                d3.select(this).select("rect.low")
                    .transition()
                    .attr("width", subScale(d.low))
                    .attr("x", rectStart);

                d3.select(this).select("rect.medium")
                    .transition()
                    .attr("width", subScale(d.medium))
                    .attr("x", subScale(d.low) + rectStart);

                d3.select(this).select("rect.high")
                    .transition()
                    .attr("width", subScale(d.high))
                    .attr("x", subScale(d.medium) + rectStart + subScale(d.low));

                d3.select(this).select(".sum")
                    .transition()
                    .text(d.sum);
            });

    }

    drawInitial(data) {
        const node = this.node;
        const svg = d3.select(node);
        const measurements = this.measurements;

        svg.selectAll("g.entry")
            .data(data)
            .enter()
            .append("g").attr("class", "entry")
            .attr("transform", (d, i) => `translate(0, ${i * 44 + 44})`)
            .each(function (d) {
                const entry = d3.select(this);

                const rectStart = measurements.outer.width;

                const max = d3.max(data, d => d.sum);

                const subScale = d3.scaleLinear()
                    .domain([0, max])
                    .range([0, measurements.inner.width]);

                entry.append("rect")
                    .attr("x", 0)
                    .attr("y", 0)
                    .attr("height", 1)
                    .attr("class", "seperator")
                    .attr("width", 1000)
                    .style("fill", "#dbdbdb");


                entry.append("text").attr("class", "label category")
                    .attr("y", 44 / 2)
                    .attr("x", measurements.outer.width / 2)
                    .attr("fill", "#4C4C4D")
                    .attr("font-size", "1rem")
                    .attr("dominant-baseline", "central")
                    .style("text-anchor", "middle")
                    .attr("width", rectStart)
                    .text(d.host);


                entry.append("rect")
                    .attr("x", rectStart)
                    .attr("y", (44 - 10) / 2)
                    .attr("height", 10)
                    .attr("class", "low")
                    .attr("width", subScale(d.low))
                    .style("fill", "#7DB5D0");

                entry.append("rect")
                    .attr("x", subScale(d.low) + rectStart)
                    .attr("y", (44 - 10) / 2)
                    .attr("height", 10)
                    .attr("class", "medium")
                    .attr("width", subScale(d.medium))
                    .style("fill", "#FCB800");

                entry.append("rect")
                    .attr("x", subScale(d.medium) + rectStart + subScale(d.low))
                    .attr("y", (44 - 10) / 2)
                    .attr("height", 10)
                    .attr("class", "high")
                    .attr("width", subScale(d.high))
                    .style("fill", "#D4003E");


                entry.append("g")
                    .attr("transform", (d, i) => `translate(${measurements.outer.width + measurements.inner.width}, 0)`).append("text").attr("class", "label category")
                    .attr("y", 44 / 2)
                    .attr("x", measurements.outer.width / 2)
                    .attr("fill", "#4C4C4D")
                    .attr("font-size", "1rem")
                    .attr("dominant-baseline", "central")
                    .style("text-anchor", "middle")
                    .attr("width", 100)
                    .attr("class", "sum")
                    .text(d.sum);


            });
    }

    createBarChart() {
        const node = this.node;
        d3.select(node)
            .attr("viewBox", `0 0 ${this.dimensions.width} ${this.dimensions.height}`);

        this.drawHead();
        this.drawInitial(this.data);


    }

    render() {
        return <svg ref={node => this.node = node}></svg>;
    }
}
