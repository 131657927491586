export class GenericUrlProvider {
    constructor(ENTITY_ROUTE) {
        this.ENTITY_ROUTE = ENTITY_ROUTE;
    }

    getAll() {
        return this.ENTITY_ROUTE;
    }

    getAllPageable() {
        return this.ENTITY_ROUTE;
    }

    getOne(id) {
        return `${this.ENTITY_ROUTE}/${id}`;
    }

    create() {
        return this.ENTITY_ROUTE;
    }

    update(id) {
        return `${this.ENTITY_ROUTE}/${id}`;
    }

    delete(id) {
        return `${this.ENTITY_ROUTE}/${id}`;
    }
}
