import {Typography} from "@material-ui/core";
import React from "react";


export function Headline1(props: { children: any }) {
    return (
        <Typography variant="h5" gutterBottom  {...props}>
            {props.children}
        </Typography>
    );
}

export function Headline2(props: { children: any }) {
    return (
        <Typography variant="h6" gutterBottom {...props}>
            {props.children}
        </Typography>
    );
}

export function Headline3(props: { children: any }) {
    return (
        <Typography variant="subtitle1" gutterBottom {...props}>
            {props.children}
        </Typography>
    );
}
