export class StaticStyleAssetsUrlProvider {

    static getBackgroundUrl() {
        return `/vmssp-admin/public/assets/backgroundLogin`;
    }

    static getLogoBigUrl() {
        return `/vmssp-admin/public/assets/logoBig`;
    }

    static getLogoSmallUrl() {
        return `/vmssp-admin/public/assets/logoSmall`;
    }
}
