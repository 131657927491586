import React from "react"
import styled from "styled-components"
import {StepDownDonut} from "../Graph/Donut/StepDownDonut"
import {DotGrey, DotHigh, DotMedium} from "../Dot/Dot"
import {WorkableNumber} from "../Graph/Helper/WorkableNumber"


const Grid = styled.div`
  padding: 3.75rem;
  display: grid;
  grid-template-columns: 65% 35%;
`

const Information = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 50%;
  
  .sub {
  grid-column: 1/ span 2
  }
`;

const BigPercentage = styled.div
    .attrs({className: 'big-percentage'})`
  font-size: 3.75rem;
`;

const PatchName = styled.div
    .attrs({className: 'patch-name'})`
  font-size: 0.875rem;
  color: rgb(77, 77, 77);

`;

const BottomGrid = styled.div
    .attrs({className: 'bottom-grid'})`
  grid-column: 1/ span 2;
  align-self: end;
  display: grid;
  grid-template-columns: 30px 100px 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-row-gap: 1.25rem;
  align-items: center;
`;

export function VulnerabilitiesWithSolution({first, second, other}) {

    /*
        solution
        SolutionType
        count
    */

    const data = [{...first, color: "#D4003E"}, {...second, color: "#FCB800"}, {...other, color: "#D5D5D5"}];


    return <Grid>

        <Information>
            <div>
                <PatchName>{first.solutionType}</PatchName>
                <BigPercentage>{new WorkableNumber()
                    .addSumUp(data, d => d.count)
                    .getPercentage(first.count)
                    .toFixed()} %</BigPercentage>
            </div>
            <div>
                {second.solutionType !== "None" ? (
                    <>
                        <PatchName>{second.solutionType}</PatchName>
                        <BigPercentage>
                            {new WorkableNumber()
                                .addSumUp(data, d => d.count)
                                .getPercentage(second.count)
                                .toFixed()} %
                        </BigPercentage>
                    </>
                ) : null}
            </div>
            <BottomGrid className={"sub"}>
                <DotHigh/>
                <div>{first.solutionType}</div>
                <PatchName>{first.solution}</PatchName>
                {second.solutionType !== "None" && (
                    <>
                        <DotMedium/>
                        <div>{second.solutionType}</div>
                        <PatchName>{second.solution}</PatchName>
                    </>
                )}
                <DotGrey/>
                <div>{other.solutionType}</div>
                <PatchName>{other.solution}</PatchName>
            </BottomGrid>
        </Information>

        <div style={{justifySelf: "end"}}>
            <StepDownDonut style={{justifySelf: "center"}} width={315} height={315} data={data}
                           accessor={d => d.count}/>
        </div>

    </Grid>;
}
