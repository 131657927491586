//@flow

import {EntityUrlProvider} from "../Base/EntityUrlProvider";


export class TaskUrlProvider implements EntityUrlProvider {
    ENTITY_ROUTE = "/tasks";

    getAll() {
        return `${this.ENTITY_ROUTE}`;
    }

    getOne(id: string) {
        return `${this.ENTITY_ROUTE}/${id}`;
    }

    create() {
        return `${this.ENTITY_ROUTE}`;
    }

    update(id: string) {
        return `${this.ENTITY_ROUTE}/${id}`;
    }

    delete(id: string) {
        return `${this.ENTITY_ROUTE}/${id}`;
    }
}
