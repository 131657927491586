//@flow
import type {ComponentType} from "react"
import styled, {css} from "styled-components"
import {Colors} from "../../Theme"


const Types = {
    outline: css`
      background: transparent;
      border: 1px solid ${props => props.theme.button.normal.background};
      color: ${props => props.theme.button.normal.background};
      &:hover {
        background: transparent;
        border: 1px solid ${props => props.theme.button.normal.hover};
        color: ${props => props.theme.button.normal.hover};
      };
      font-weight: normal;
    `,
};

export const Button = (styled.button`
  box-sizing: border-box;
  color: ${props => props.theme.button.normal.text};
  background-color: ${props => props.theme.button.normal.background};
  border: none;
  border-radius: 2px;
  font-size: 1rem;
  font-weight: normal;
  height: 2.25rem;

  margin: 0;
  margin-top: 0.94rem;
  margin-bottom: 0.94rem;
  padding-right: 1.25rem;
  padding-left: 1.25rem;

  &:hover{
    cursor: pointer;
    background-color: ${props => props.theme.button.normal.hover};
  }

  ${props => props.disabled && css`
      background-color: ${props => props.theme.button.normal.disabled};

    &:hover  {
      cursor: not-allowed;
      background-color: ${props => props.theme.button.normal.disabled};
    }
  `}

  ${props => props.loading && css`
    &:hover {
      background-color: ${props => props.theme.button.normal.background};
      cursor: wait;
    }
  `}

  ${props => props.type && Types[props.type]}

`: ComponentType<{
    type?: "outline",
    loading?: ?boolean,
    disabled?: ?boolean
}>);

export const OrderButton = styled(Button)`
  height: 3.13rem;
  font-size: 1.25rem;
  padding-left: 0.94rem;
  padding-right: 0.94rem;
`;

const GhostButtonVariants = {
    negative: css`
       border: 1px solid ${props => props.theme.button.normal.background};
       color: ${props => props.theme.button.normal.background};
       &:hover {
         background: transparent;
         border: 1px solid ${props => props.theme.button.normal.hover};
         color: ${props => props.theme.button.normal.hover};
       };
    `,
};

export const GhostButton = styled(Button)`
  background: transparent;
  border: 1px solid ${props => props.theme.button.normal.background};
  color: ${props => props.theme.button.normal.background};
  font-weight: normal;
  &:hover {
     background: transparent;
     border: 1px solid ${props => props.theme.button.normal.hover};
     color: ${props => props.theme.button.normal.hover};
  };

   ${props => props.variant && GhostButtonVariants[props.variant]}
`;

export const AbortButton = styled(Button)`
  background-color: ${props => props.theme.button.abort.background};

  &:hover{
    background-color: ${props => props.theme.button.abort.hover};
  }

  ${props => props.disabled && css`
    background-color: ${props => props.theme.button.abort.disabled};

    &:hover  {
      background-color: ${props => props.theme.button.abort.disabled};
    }
  `}

  ${props => props.loading && css`
    &:hover {
      cursor: wait;
    }
  `}
`;

export const GhostButtonWhite = styled(GhostButton)`
  marign: 0;
  color: white;
  border-color: white;

  &:hover {
    color: ${Colors.grey.lighter};
    border-color: ${Colors.grey.lighter};
  }
`;

const ActionButton = styled.button`
  border: none;
  font-size: inherit;
  color: #fff;
  margin: 0;
  padding: 0.313rem;
  padding-left: 0.625rem;
  padding-right: 0.625rem;
  font-weight: inherit;
  border-radius: 0.063rem;
  height: 2.188rem;

  &:hover {
    cursor: pointer;
  }

`;

export const SecondaryActionButton = styled(ActionButton)`
  background: ${Colors.blue.normal};

  &:hover {
    background: ${Colors.blue.dark};
    ${props => props.disabled && `background: ${Colors.blue.light};`}

  }

 ${props => props.disabled && `background: ${Colors.blue.light};`}

`;

export const PrimaryActionButton = styled(ActionButton)`
  background: ${props => props.theme.button.normal.background};

  &:hover {
    background: ${props => props.theme.button.normal.hover};
    ${props => props.disabled && css`background: ${props => props.theme.button.normal.disabled}`}
  }

  ${props => props.disabled && css`background: ${props => props.theme.button.normal.disabled}`}

`;

export const IconButton = styled.button`
  border: none;
  background: ${props => props.theme.button.icon.background};
  box-sizing: border-box;
  height: 2.35rem;
  width: 2.35rem;
  padding: 0.55rem;
  border-radius: 3px;
  transition:all 0.3s ease;

  &:hover {
    background: ${props => props.theme.button.icon.hover};
    cursor: pointer;
  }

  &:active {
    background: ${props => props.theme.button.icon.active};
  }

  ${props => props.disabled && css`
    opacity: 0.5;
    &:hover {
        background: ${props => props.theme.button.icon.background};
        cursor: default;
    }

    &:active {
        background: ${props => props.theme.button.icon.background};
    }
  `}
`;
