// @flow

import {GlobalConstants, GlobalReduxActions} from "./constants";


type State = {
    menuOpen: boolean;
    Title: string;
};

const initialState: State = {
    menuOpen: false,
    Title: "MSP",
};

const reducer = (state: State = initialState, action: any) => {
    switch (action.type) {
        case GlobalConstants.SET_TITLE:
            return {
                ...state,
                Title: action.title,
            };

        case GlobalReduxActions.RESET: {
            return initialState;
        }

        default:
            return state;
    }
};

export default reducer;
