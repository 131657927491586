import * as React from "react";
const SvgLogout = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      enableBackground: "new 0 0 24 24",
    }}
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M19.499 16.5a.5.5 0 0 1-.354-.853l3.146-3.146H11.499a.5.5 0 0 1 0-1h10.793l-3.146-3.146a.5.5 0 0 1 .707-.709l4 4a.505.505 0 0 1 0 .707l-3.999 4a.5.5 0 0 1-.355.147z"
    />
    <path
      fill="currentColor"
      d="M9.001 24a.988.988 0 0 1-.217-.024L.783 22.199a.995.995 0 0 1-.784-.975V2.778c0-.473.323-.874.784-.976l8-1.778A1.001 1.001 0 0 1 9.999 1v1h7c.552 0 1 .449 1 1v2.5a.5.5 0 0 1-1 0V3h-7v18h7v-2.5a.5.5 0 0 1 1 0V21a1 1 0 0 1-1 1h-7v1a.998.998 0 0 1-.998 1zM1 2.778v18.445L9 23V1L1 2.778z"
    />
    <path
      fill="currentColor"
      d="M5.999 14c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zm0-3a1.001 1.001 0 1 0 1 1c0-.551-.448-1-1-1z"
    />
  </svg>
);
export default SvgLogout;
