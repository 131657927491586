//@flow

import React from "react";
import {Logger} from "../controller/loggingController";
import {UserRestApiClient} from "./service/UserRestApiClient";

//$FlowFixMe - Flow does not know what a context is
export const UserContext = React.createContext({});

export class UserProvider extends React.Component<{ children: any }, any> {
    userRestApiClient: UserRestApiClient;

    state = {
        subscription: null,
        _loading: true,
        user: null
    };

    constructor(props: any) {
        super(props);
        this.userRestApiClient = new UserRestApiClient();
    }

    loadUser = () => {
        this.setState({_loading: true});
        this.userRestApiClient.getUser()
            .then(user => {
                this.setState({user});
            })
            .catch(exception => {
                Logger.exception(exception);
            })
            .finally(() => {
                this.setState({_loading: false});
            });
    };

    getUsernameWithFallback = () => {
        const username = this.getUsername();
        const user = this.state.user;
        if (username) {
            return username;
        }

        if (!user) {
            return null;
        }

        return user.email;
    };

    getUsername = () => {
        const user = this.state.user;

        if (!user || !user.firstName || !user.lastName) {
            return null;
        }

        return `${user.firstName} ${user.lastName}`;
    };

    render() {

        return (
            <UserContext.Provider value={{
                loadUser: this.loadUser,
                user: this.state.user,
                userProvider: {
                    getUsername: this.getUsername,
                    getUsernameWithFallback: this.getUsernameWithFallback
                },
                loading: this.state._loading,
                userProviderLoading: this.state._loading
            }}>
                {this.props.children}
            </UserContext.Provider>
        );
    }
}

//$FlowFixMe - Flow does not allow "Component" with an uppercase 'C'
export const withUserContext = (Component) => {
    return (props: any) => (
        <UserContext.Consumer>
            {(contextProps) => {
                return <Component {...contextProps} {...props} />;
            }}
        </UserContext.Consumer>
    );
};
