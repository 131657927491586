import React from "react"
import {AlertBox, AlertBoxType} from "@greenbone/cloud-component-library"
import {useTranslation} from "react-i18next"

export function ReportError({status}) {
    const {t} = useTranslation()

    switch (status) {
        case 404: {
            return <>
                <AlertBox title={t("reportError.notFound")}
                          text={t("reportError.notFound.text")}
                          type={AlertBoxType.Error}/>
            </>
        }

        default : {
            return <>
                <AlertBox title={t("reportError.error")}
                          text={t("reportError.error.text")}
                          type={AlertBoxType.Error}/>
            </>
        }
    }
}
