import React from "react"
import {FetchClient} from "./FetchClient"
import {LayoutProvider, PublicStyleRestApiClient} from ".."
import Keycloak from "keycloak-js"
import {ConfigFetchClient} from "../Auth/ConfigFetchClient"

export function AuthenticationWrapperFactory(baseUrl, instanceStorage, ErrorComponent, LoadingComponent, configUrl, jumpInConfig, override = null) {
    return AuthenticationWrapperFactoryWithFetchClient(baseUrl, instanceStorage, ErrorComponent, LoadingComponent, FetchClient, configUrl, override)
}

export function AuthenticationWrapperFactoryWithFetchClient(baseUrl, instanceStorage, ErrorComponent, LoadingComponent, FetchClientClass, configUrl, jumpInConfig, override = null) {
    return class AuthenticationWrapper extends React.Component {
        state = {
            isAuthenticated: false,
            loading: true,
            error: null,
            theme: null
        }

        componentDidMount() {
            this.loadKeycloakConfig()
        }

        getConfig() {
            if (override) {
                return override
            }
        }

        loadKeycloakConfig = async () => {
            const KC_TOKEN_KEY = "kc_token"
            const KC_REFRESH_TOKEN_KEY = "kc_refreshToken"


            try {
                let config
                if (override) {
                    config = this.getConfig()
                } else {
                    const configFetchClient = new ConfigFetchClient(baseUrl)
                    config = await configFetchClient.fetchConfig(configUrl)
                }


                let token = localStorage.getItem(KC_TOKEN_KEY)
                let refreshToken = localStorage.getItem(KC_REFRESH_TOKEN_KEY)

                if (jumpInConfig) {
                    if (jumpInConfig.enabled) {
                        token = jumpInConfig.accessToken
                        refreshToken = jumpInConfig.refreshToken
                    }
                }

                const keycloak = new Keycloak(config)

                let authenticated = null

                if (!!token && !!refreshToken) {
                    authenticated = await keycloak.init({
                        promiseType: "native",
                        token,
                        refreshToken,
                        checkLoginIframe: false
                    })
                } else {
                    authenticated = await keycloak.init({promiseType: "native", checkLoginIframe: false})
                }

                if (!authenticated) {
                    keycloak.login()
                    return
                }

                if (keycloak.token && keycloak.refreshToken) {
                    localStorage.setItem(KC_TOKEN_KEY, keycloak.token)
                    localStorage.setItem(KC_REFRESH_TOKEN_KEY, keycloak.refreshToken)
                }


                keycloak.wipeAndLogout = (passThrough) => {
                    localStorage.removeItem(KC_TOKEN_KEY)
                    localStorage.removeItem(KC_REFRESH_TOKEN_KEY)
                    keycloak.logout(passThrough)
                }

                instanceStorage.updateKeycloak(keycloak)
                instanceStorage.updateFetchClient(new FetchClientClass(keycloak, baseUrl))

                try {
                    const theme = await PublicStyleRestApiClient.getTheme(instanceStorage.getFetchClient().getUnauthenticatedInstance())
                    await this.setState({theme})
                } catch (e) {
                    console.error(e)
                }

                this.setState({isAuthenticated: authenticated, loading: false})

            } catch (e) {
                console.error(e)
                this.setState({error: e, loading: false})
            }
        }

        render() {

            const {loading, error, theme} = this.state

            if (error) {
                if (ErrorComponent) {
                    return <ErrorComponent/>
                } else {
                    return <div>Could not load realm config</div>
                }
            }

            if (loading) {
                if (LoadingComponent) {
                    return <LoadingComponent/>
                } else {
                    return <div id={"loading"}>Fetching realm config...</div>
                }
            }

            const {app} = this.props
            const App = app

            return <div style={{animation: `fadeIn 1s`}}>
                <LayoutProvider>
                    <App theme={theme}/>
                </LayoutProvider>
            </div>

        }
    }
}
