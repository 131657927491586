export const NullableText = ({children}) => {
    if (!children) {
        return "Not Available"
    }

    return children
}

export const HideNullable = ({value, children}) => {
    if (!value) {
        return null
    }

    return children
}
