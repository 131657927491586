import React from "react"
import styled from "styled-components"
import {ActionButton, FlexRow} from "@greenbone/cloud-component-library"
import IconButton from "@material-ui/core/IconButton"
import {Refresh} from "@material-ui/icons"
import {useTranslation} from "react-i18next"

const Outer = styled.div`
  background: #ffa000;
  height: 4rem;
  color: white;
  padding-left: 1rem;
  padding-right: 1rem;
  cursor: pointer;
`

const Inner = styled.div`

  display: flex;
  align-items: center;
  justify-content: space-between;
`


export function StaleWarning(props) {
    const {t} = useTranslation()

    return <Outer onClick={(event) => {
        event.preventDefault()
        window.location.reload()
    }}>
        <FlexRow alignItems={"center"} full justifyContent={"space-between"}>
            <Inner>
                <IconButton style={{color: "white"}}>
                    <Refresh/>
                </IconButton>
                {t("staleWarning.updateAvailable")}
            </Inner>
            <ActionButton>{t("staleWarning.updateButton")}</ActionButton>
        </FlexRow>
    </Outer>
}
