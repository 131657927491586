import * as d3 from "d3"

export class DonutFactory {
    static ArcFactory = (radius, thickness) => d3.arc()
        .innerRadius(radius - (thickness || 20))
        .outerRadius(radius)


    static ArcTweenFactoryR = radius => function arcTween(a, index, allPath) {
        const i = d3.interpolate(this._current, a)

        const list = allPath.map((a, i) => {
            return i
        }).reverse()

        index = list[index] + 1;

        this._current = i(1);
        const arc = d3.arc()
            .innerRadius(radius - 70)
            .outerRadius(radius - (index) * 10);

        return (t) => arc(i(t));
    };

    static ArcTweenFactory = arc => function arcTween(a) {
        const i = d3.interpolate(this._current, a);
        this._current = i(1);
        return (t) => arc(i(t));
    };

}

