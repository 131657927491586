// @flow

import {FormControl, FormControlLabel, Radio, RadioGroup, withTheme} from "@material-ui/core"
import Promise from "bluebird"
import React from "react"
import {withTranslation} from "react-i18next"
import {connect} from "react-redux"
import {Col, Container, Row} from "reactstrap"
import {bindActionCreators, compose} from "redux"
import {ButtonSpinner} from "../../../Components/Animations/Button/ButtonSpinner"
import {AbortButton, Button, FlexRow, Snackbar} from "@greenbone/cloud-component-library"
import {openAlertDialog} from "../../../Components/Dialogs/AlertDialog/actions"
import Loader from "../../../Components/Loader/Loader"
import UploadComponent from "../../../Components/Upload"
import {Converter} from "../../../Helper/Converter"
import {Value} from "../../../Helper/Validator"
import {Headline} from "../../../StyledComponents/Font/Font"
import {Input} from "../../../StyledComponents/Form/Input"
import {getValidationResult} from "../../../controller/FieldValidator"
import {IsNotEmptyString, IsOptionalString} from "../../../controller/FormValidators"
import {CredentialsRestApiClient} from "../../../services/apiClients/Credentials/CredentialsRestApiClient"
import {CreateErrors, ForbiddenError, UpdateErrors} from "../../../services/Exceptions"
import {SimpleEntityContext} from "../../../services/Context/SimpleEntityContext"

const authenticationType = {
    Password: "PASSWORD",
    KeyFile: "KEYFILE"
}

type AuthenticationType = $Values<typeof authenticationType>;

type Props = {
    id: string;
    theme: any;
    history: any;
    openAlertDialog: typeof openAlertDialog;
    onClose: () => void;
    t: any;
}

type State = {
    name: string;
    comment: string;
    login: string;
    password: string;
    passphrase: string;
    privateKey: string;
    privateKeyData: string;
    fieldStatus: {
        name: ?boolean,
        comment: ?boolean,
        login: ?boolean,
        password: ?boolean,
        passphrase: ?boolean,
        privateKey: ?boolean,
    };
    _sending: boolean;
    _loading: boolean;
    _exception: any;
    isValid: boolean;
    credentialsType: AuthenticationType;
    initialCredentialsType: ?AuthenticationType;
}

class _CredentialsEdit extends React.Component<Props, State> {
    static defaultProps = {
        id: null
    };
    httpRestApiClient: CredentialsRestApiClient;
    setStateAsync: Promise;
    CredentialsFieldValidators = {
        name: new IsNotEmptyString(),
        comment: new IsOptionalString(),
        login: new IsNotEmptyString()
    };
    state = {
        name: "",
        comment: "",
        login: "",
        password: "",
        passphrase: "",
        privateKey: "",
        privateKeyData: "",
        fieldStatus: {
            name: null,
            comment: null,
            login: null,
            password: null,
            passphrase: null,
            privateKey: null
        },
        _exception: null,
        _sending: false,
        _loading: false,
        credentialsType: authenticationType.Password,
        initialCredentialsType: null,
        isValid: false
    };

    constructor(props) {
        super(props);
        this.httpRestApiClient = new CredentialsRestApiClient();
        this.setStateAsync = Promise.promisify(this.setState);
    }

    get PasswordCredentialsFieldValidators() {
        return {
            ...this.CredentialsFieldValidators,
            password: new IsNotEmptyString(),
            passphrase: new IsOptionalString(),
            privateKey: new IsOptionalString()
        };
    };

    get KeyfileCredentialsFieldValidators() {
        return {
            ...this.CredentialsFieldValidators,
            password: new IsOptionalString(),
            passphrase: new IsNotEmptyString(),
            privateKey: new IsNotEmptyString()
        };
    };

    get EditSameCredentialsTypeFieldValidators() {
        return {
            ...this.CredentialsFieldValidators,
            password: new IsOptionalString(),
            passphrase: new IsOptionalString(),
            privateKey: new IsOptionalString()
        };
    };

    getPasswordFieldValidators = () => {
        const {credentialsType, initialCredentialsType} = this.state;

        if (this.isInEditMode() && credentialsType === initialCredentialsType) {
            return this.EditSameCredentialsTypeFieldValidators;
        } else {
            return credentialsType === authenticationType.Password ?
                this.PasswordCredentialsFieldValidators
                :
                this.KeyfileCredentialsFieldValidators;
        }
    };

    componentDidMount() {
        this.isInEditMode() && this.loadCredential();
    }

    isInEditMode = (): boolean => {
        return Converter.toBoolean(this.props.id);
    };

    uploadedPrivateKeyFile = (fileName: string, data: string) => {
        this.setState({
            privateKey: fileName,
            privateKeyData: data
        });
    };

    onPrivateKeyRemove = () => {
        this.setState({
            privateKey: "",
            privateKeyData: ""
        });
    };

    errorFileSelection = (message) => {
        const {t} = this.props;

        this.props.openAlertDialog(t("common.messages.error"), message);
    };

    validateFields = async (): Promise<void> => {
        const validators = this.getPasswordFieldValidators();

        const {isValid, fieldValidity} = getValidationResult(
            this.state,
            validators
        );

        await this.setStateAsync({fieldStatus: fieldValidity, isValid});
    };

    loadCredential = () => {
        const {id} = this.props;
        if (!id) {
            return;
        }

        this.setState({_loading: true});

        this.httpRestApiClient.getOne(id)
            .then((credentials: GSPCredentialsDto) => {
                if (credentials) {
                    this.setState({
                        name: credentials.name || "",
                        comment: credentials.comment || "",
                        login: credentials.login || ""
                    });
                    this.setState({
                        credentialsType: credentials.loginType,
                        initialCredentialsType: credentials.loginType
                    });
                }
            })
            .catch(exception => {
                this.setState({_exception: exception});
            })
            .finally(() => (
                this.setState({_loading: false})
            ));
    };

    onFormSubmit = (event: SyntheticEvent<HTMLFormElement>) => {
        event.preventDefault();

        this.validateFields()
            .then(() => {
                this.handleFormSubmit();
            });
    };

    handleFormSubmit = () => {
        const {credentialsType, name, comment, login, password, privateKeyData, passphrase, isValid} = this.state;

        if (!isValid) {
            return;
        }

        let credentials: any;
        if (credentialsType === authenticationType.Password) {
            credentials = {
                id: null,
                name,
                comment,
                login,
                password: password ? password : undefined
            };
        } else {
            credentials = {
                id: null,
                name,
                comment,
                login,
                privateKeyFile: privateKeyData ? privateKeyData : undefined,
                passphrase: passphrase ? passphrase : undefined
            };
        }

        this.setState({_sending: true});

        if (this.isInEditMode()) {
            this.updateCredential(this.props.id, credentials);
        } else {
            this.createCredential(credentials);
        }
    };

    createCredential = (credentials: any): void => {
        const {t} = this.props;

        this.httpRestApiClient.createEntity(credentials)
            .then(() => {
                this.redirectToList()
                this.context.updateCredentials()
                Snackbar.Success(t(`credentials.create.success`, {name: credentials.name}))
            })
            .catch(error => {
                if (error.type === ForbiddenError) {
                    Snackbar.Error(error.message);
                } else if (Value(error.type).isInList(CreateErrors)) {
                    this.props.openAlertDialog(t("common.messages.error"), error.message);
                } else {
                    this.setState({_exception: error});
                }
            })
            .finally(() => {
                this.setState({_sending: false});
            });
    };

    updateCredential = (credentialId: string, credentials: any): void => {
        const {t} = this.props;
        credentials.id = credentialId;
        this.httpRestApiClient.updateEntity(credentials)
            .then(() => {
                this.redirectToList()
                this.context.updateCredentials()
                Snackbar.Success(t(`credentials.update.success`, {name: credentials.name}))
            })
            .catch(error => {
                if (error.type === ForbiddenError) {
                    Snackbar.Error(error.message);
                } else if (Value(error.type).isInList(UpdateErrors)) {
                    this.props.openAlertDialog(t("common.messages.error"), error.message);
                } else {
                    this.setState({_exception: error});
                }
                this.setState({_sending: false});
            });
    };

    onChange = (event: SyntheticInputEvent<EventTarget>) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    redirectToList = (event: any) => {
        if (event) {
            event.preventDefault();
        }

        this.props.onClose();
    };

    renderLoginAndPassword = () => {
        const {t} = this.props;
        const {fieldStatus} = this.state;

        return (
            <Row>
                <Col>
                    <Input
                        autoComplete='new-password off no false'
                        name="login"
                        value={this.state.login || ""}
                        onChange={this.onChange}
                        isValid={fieldStatus.login}
                        label={t("common.action.login")}
                        helperText={!this.isFieldStatusValid(fieldStatus.login) ? fieldStatus.login : ""}
                    />
                </Col>
                <Col>
                    <Input
                        autoComplete='new-password off no false'
                        name="password"
                        value={this.state.password || ""}
                        onChange={this.onChange}
                        isValid={fieldStatus.password}
                        label={t("credentials.components.edit.password")}
                        helperText={!this.isFieldStatusValid(fieldStatus.password) ? fieldStatus.password : ""}
                        type="password"
                    />
                </Col>


            </Row>
        );
    };

    renderLoginPasspharseAndKeyfile = () => {
        const {t} = this.props;
        const {fieldStatus, login, passphrase, privateKey} = this.state;

        return (
            <React.Fragment>
                <Row>
                    <Col>
                        <Input
                            autoComplete='new-password off no false'
                            name="login"
                            value={login || ""}
                            onChange={this.onChange}
                            isValid={fieldStatus.login}
                            label={t("common.action.login")}
                            helperText={!this.isFieldStatusValid(fieldStatus.login) ? fieldStatus.login : ""}
                        />
                    </Col>
                    <Col>
                        <Input
                            type={"password"}
                            autoComplete='new-password off no false'
                            name="passphrase"
                            value={passphrase || ""}
                            onChange={this.onChange}
                            isValid={fieldStatus.passphrase}
                            label={t("credentials.components.edit.passphrase")}
                            helperText={!this.isFieldStatusValid(fieldStatus.passphrase) ? fieldStatus.passphrase : ""}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Input
                            autoComplete='off'
                            name="privateKey"
                            value={privateKey || ""}
                            onChange={this.onChange}
                            isValid={fieldStatus.privateKey}
                            label={t("credentials.components.edit.privateKey")}
                            helperText={!this.isFieldStatusValid(fieldStatus.privateKey) ? fieldStatus.privateKey : ""}
                            disabled={true}
                            fullWidth
                            margin="normal"
                        />
                    </Col>
                    <Col>
                        <UploadComponent
                            onFileSelect={this.uploadedPrivateKeyFile}
                            errorFileSelection={this.errorFileSelection}
                            onFileRemove={this.onPrivateKeyRemove}
                        />
                    </Col>
                </Row>
            </React.Fragment>
        );
    };

    render() {
        const {t} = this.props;
        const {fieldStatus, _exception, _loading} = this.state;

        if (_exception) {
            throw _exception;
        }

        if (_loading) {
            return (<Loader/>);
        }

        return (
            <form autoComplete="off" style={{width: "700px", margin: "1.3rem", marginTop: "2.2rem"}}>
                <Container>
                    <Row>
                        <Col>
                            <Headline>
                                {this.isInEditMode() ? t("credentials.components.edit.editLoginData") : t("credentials.components.edit.newLoginData")}
                            </Headline>
                        </Col>
                    </Row>

                    <Row style={{marginBottom: "2rem"}}>
                        <Col>
                            <Input
                                autoComplete='off'
                                name="name"
                                value={this.state.name || ""}
                                onChange={this.onChange}
                                isValid={fieldStatus.name}
                                label={t("credentials.components.edit.name")}
                                helperText={!this.isFieldStatusValid(fieldStatus.name) ? fieldStatus.name : ""}/>
                        </Col>
                    </Row>

                    <Row style={{marginBottom: "5rem"}}>
                        <Col>
                            <Input
                                autoComplete='off'
                                name={"comment"}
                                value={this.state.comment || ""}
                                onChange={this.onChange}
                                isValid={fieldStatus.comment}
                                label={t("common.description.optional")}/>
                        </Col>
                    </Row>

                    <Row style={{marginBottom: "4rem"}}>
                        <Col>
                            <div style={{width: "100%"}}>
                                <FormControl style={{width: "100%"}}>
                                    <RadioGroup
                                        style={{flexWrap: "nowrap", flexDirection: "row", width: "100%"}}
                                        onChange={
                                            (event) => {
                                                this.setState({credentialsType: event.target.value});
                                            }}
                                        value={this.state.credentialsType}>
                                        <FormControlLabel value={authenticationType.Password}
                                                          control={<Radio color="primary"/>}
                                                          label={t("credentials.components.edit.LoginAndPassword")}/>

                                        <FormControlLabel value={authenticationType.KeyFile}
                                                          control={<Radio color="primary"/>}
                                                          label={t("credentials.components.edit.LoginPassphraseAndPrivateKey")}/>
                                    </RadioGroup>
                                </FormControl>

                                {this.state.credentialsType === authenticationType.Password && this.renderLoginAndPassword()}
                                {this.state.credentialsType === authenticationType.KeyFile && this.renderLoginPasspharseAndKeyfile()}
                            </div>
                        </Col>
                    </Row>
                    <Row style={{marginBottom: "4rem"}}>
                        <Col>
                            <FlexRow justifyContent={"space-between"}>
                                <AbortButton onClick={this.redirectToList}>
                                    {t("common.action.abort")}
                                </AbortButton>
                                <Button onClick={this.onFormSubmit}>
                                    <ButtonSpinner hidden={!this.state._sending}/>
                                    {this.props.id ?
                                        t("credentialForm.update")
                                        :
                                        t("credentialForm.create")
                                    }


                                </Button>
                            </FlexRow>
                        </Col>
                    </Row>
                </Container>
            </form>
        );
    }

    isFieldStatusValid = (fieldStatus: ?boolean | ?string) => {
        return fieldStatus === true || fieldStatus === null
    }
}

//$FlowFixMe
_CredentialsEdit.contextType = SimpleEntityContext

function mapDispatchToProps(dispatch) {
    let actions = bindActionCreators({
        openAlertDialog: openAlertDialog
    }, dispatch)
    return {...actions, dispatch}
}

function mapStateToProps(state) {
    return {}
}

export const CredentialsEdit = compose(
    withTheme,
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps),
)(_CredentialsEdit);
