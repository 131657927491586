export class SecurityException extends Error {
    constructor(message) {
        if (message) {
            super(message)
        } else {
            super("Security Exception")
        }

    }
}
