import React from "react"
import {FlexRow} from "@greenbone/cloud-component-library"
import {FirstButton, SecondButton} from "../../../StyledComponents/Button/SwitchButton"
import {useTranslation} from "react-i18next"

export function ViewSwitch({view, onChange}) {

    const {t} = useTranslation()


    return <FlexRow style={{marginTop: "1rem"}} justifyContent={"start"}>
        <FirstButton
            active={view === "BASIC"}
            onClick={(event) => {
                event.preventDefault()
                onChange("BASIC")
            }}>
            {t("gateway.viewSwitch.basic")}
        </FirstButton>
        <SecondButton
            active={view === "ADVANCED"}
            onClick={(event) => {
                event.preventDefault()
                onChange("ADVANCED")
            }}>
            {t("gateway.viewSwitch.advanced")}
        </SecondButton>
    </FlexRow>

}

