//@flow
import React from "react"
import {FlexRow} from "@greenbone/cloud-component-library"
import styled, {css} from "styled-components"
import Loader from "../../Components/Loader/Loader"
import {Language} from "../../constants"
import {getSupportedLanguageFromStore} from "../../Helper/language"
import {withLanguageContext} from "./LanguageProvider"

const BaseButton = styled.button`
    padding-left: .5rem;
    padding-right: .5rem;
    min-height: 2.25rem;

    background: transparent;
    color: ${props => props.theme.navbar.font};

    border: none;
    border-bottom: 2px solid ${props => props.theme.navbar.font};
    border-color: transparent;

    border-radius: 3px;
    ${props => props.active ? css`border-color: ${props => props.theme.navbar.font};` : ""}

`


const _LanguageChange = (props) => {
    const {working} = props

    return <>
        {
            working ?
                <Loader/> :
                <FlexRow>
                    {Language.hasOwnProperty("DE") && <BaseButton
                        active={getSupportedLanguageFromStore().startsWith(Language.DE)}
                        onClick={() => !getSupportedLanguageFromStore().startsWith(Language.DE) && props.changeLanguage(Language.DE)}>
                        {Language.DE.toUpperCase()}
                    </BaseButton>}
                    {Language.hasOwnProperty("EN") && <BaseButton
                        active={getSupportedLanguageFromStore().startsWith(Language.EN)}
                        onClick={() => !getSupportedLanguageFromStore().startsWith(Language.EN) && props.changeLanguage(Language.EN)}>
                        {Language.EN.toUpperCase()}
                    </BaseButton>}
                </FlexRow>
        }
    </>
}

export const LanguageChange = withLanguageContext(_LanguageChange)
