import React from 'react'
import {IconButton} from "@material-ui/core"
import styled from "styled-components"
import MailMessage from "./assets/Icon_Email.svg"
import {Link} from "react-router-dom"
import {useContext} from "react"
import {ProductUpdateContext} from "../../../Context/ProductUpdatesProvider"
import {UserSettingsUrlProvider} from "../../../UrlProvider/UserSettingsUrlProvider"

const StyledIconButton = styled(IconButton)`
    color: ${props => props.theme.navbar.font} !important;
    background: #FDC300 !important;
    margin-left: .5rem !important;
`

export function LayoutMessageBubble({}) {
    const {hasNew} = useContext(ProductUpdateContext)
    if (!hasNew) {
        return null
    }

    return <Link to={UserSettingsUrlProvider.hash.productUpdates.url}>
        <StyledIconButton>
            <MailMessage style={{height: "18px"}}/>
        </StyledIconButton>
    </Link>

}
