import * as React from "react";
const SvgArrowDown = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      enableBackground: "new 0 0 24 24",
    }}
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12 18.9c-.3 0-.6-.1-.7-.3L.2 6c-.2-.2-.2-.6 0-.7.1-.1.2-.1.3-.1.1 0 .3.1.4.2L12 17.9 23.2 5.3c.1-.1.2-.2.4-.2.1 0 .2 0 .3.1.2.2.2.5 0 .7L12.8 18.5l-.1.1c-.2.2-.4.3-.7.3z"
    />
  </svg>
);
export default SvgArrowDown;
