import React from "react"
import {Trans, withTranslation} from "react-i18next"
import {connect} from "react-redux"
import {Container} from "reactstrap"
import {bindActionCreators, compose} from "redux"
import {InfoText} from "../../../Components/Basic/InfoText"
import {ListContainer} from "../../../Components/Basic/ListContainer"
import {openAlertDialog} from "../../../Components/Dialogs/AlertDialog/actions"
import {ModalSlideIn} from "../../../Components/Dialogs/SlideIn/ModalSlideIn"
import {ErrorBoundary} from "../../../Components/ErrorBoundary"
import {Button, Snackbar} from "@greenbone/cloud-component-library"
import {Headline} from "../../../StyledComponents/Font/Font"
import {TargetCreate} from "../Form/TargetForm"
import {TargetList} from "./TargetList"
import {TargetRestApiClient} from "../../../services/apiClients/Target/TargetRestApiClient"
import Loader from "../../../Components/Loader/Loader"
import {DeleteErrors, DeleteWarnings, ForbiddenError} from "../../../services/Exceptions"
import {Value} from "../../../Helper/Validator"
import {TabView} from "./TabView"


type Props = {
    t: any;
}

type State = {
    refresh: boolean;
    isSlideInOpen: boolean;
    id: ?string;
    targets: Array<GSPTarget>;
    _loading: boolean,
    showExternal: boolean,
    _exception: boolean
}

class _TargetPage extends React.Component<Props, State> {
    targetRestApiClient: TargetRestApiClient;

    state = {
        isSlideInOpen: false,
        refresh: false,
        id: null,
        targets: [],
        _loading: false,
        _exception: null,
        showExternal: false
    };

    constructor(props) {
        super(props);

        this.targetRestApiClient = new TargetRestApiClient();
    }


    componentDidMount() {
        this.fetchData(true)
    }

    onTargetDelete = (targetId: string) => {
        const {t} = this.props;


        this.targetRestApiClient.deleteEntity(targetId)
            .then((response) => {
                this.setState({
                        targets: this.state.targets.filter(item => item.id !== targetId)
                    }
                );
            })
            .catch((error) => {
                    if (error.type === ForbiddenError) {
                        Snackbar.Error(error.message);
                    } else if (Value(error.type).isInList(DeleteWarnings)) {
                        Snackbar.Error(t("targetPage.delete.targetInUse"), error.message);
                    } else if (Value(error.type).isInList(DeleteErrors)) {
                        Snackbar.Error(t("common.messages.error"), error.message)
                    } else {
                        this.setState({_exception: error})
                    }
                }
            );

    };

    fetchData(showLoadingIndicator = false) {
        if (showLoadingIndicator)
            this.setState({_loading: true})

        this.targetRestApiClient.getAll()
            .then(targetData => {
                this.setState({
                    targets: targetData,
                    _loading: false
                })
            })
            .catch(error => {
                this.setState({
                    _exception: error,
                    _loading: false
                });
            });
    }


    openEditTargetSlideIn = (id: string, isInternal: boolean) => {
        this.setState({
            isSlideInOpen: true,
            id: id,
            isInternal
        })
    }

    openCreateTargetSlideIn = (id: string) => {
        this.setState({
            isSlideInOpen: true,
            id: null,
            isInternal: false
        });
    };

    openCreateInternalTargetSlideIn = () => {
        this.setState({
            isSlideInOpen: true,
            id: null,
            isInternal: true
        });
    };

    handleCloseSlideIn = () => {
        this.setState({
            isSlideInOpen: false
        })

        this.fetchData()
    }

    onTabChange = showExternal => {

        this.setState(prevState => ({showExternal}))
    }

    render() {
        const {t} = this.props
        return (
            <div>
                <Headline>{t("targetPage.help.headline")}</Headline>
                <InfoText headline={""}>
                    <p>
                        <Trans i18nKey={"targetPage.help.paragraph1"}>
                            Ziele definieren die IP-Adressen, die gescannt werden sollen. Sie können diese in
                            unterschiedlichen Formaten eingeben: IP/Netz (45.135.106.0/25), IP-IP
                            (185.135.33.1-5), IP (185.135.33.2). Bitte beachten Sie, dass Sie nur
                            IP-Adressen
                            scannen, für die eine Erlaubnis vorliegt. <strong>Scannen von Zielen ohne Erlaubnis ist ein
                            Straftatbestand!</strong> Wenn Sie ganze Netze scannen, können Sie einzelne IP-Adressen
                            ausklammern. Mit
                            dem Alive-Test definieren Sie die Methode, wie der Scan feststellt, ob das Ziel erreichbar
                            ist.
                            Für die einzelnen Dienste, wie SMB, SSH, ESXi, können die Anmeldeinformationen hinterlegt
                            werden, damit ein authentifizierter Scan durchgeführt wird.
                        </Trans>
                    </p>
                    <p>
                        {t("targetPage.help.paragraph2")}
                    </p>
                </InfoText>
                {this.state._loading ? <Loader/> :
                    <ListContainer>
                        <ErrorBoundary>
                            <TabView onChange={this.onTabChange} showExternal={this.state.showExternal}/>
                            {this.state.showExternal ?
                                <>
                                    <TargetList cacheKey={"TargetListExtern/2021-02-01"}
                                                handleDelete={this.onTargetDelete}
                                                isInternal={false}
                                                targets={this.state.targets.filter(target => !target.isInternal)}
                                                actions={<>
                                                    <Button style={{margin: 0}}
                                                            onClick={this.openCreateTargetSlideIn}>
                                                        {t("targetPage.help.createExternalTarget")}
                                                    </Button>
                                                </>} onUpdateEntry={this.openEditTargetSlideIn}/>
                                </>
                                : <>
                                    <TargetList cacheKey={"TargetListIntern/2021-02-01"}
                                                isInternal={true}
                                                handleDelete={this.onTargetDelete}
                                                targets={this.state.targets.filter(target => target.isInternal)}
                                                actions={<>
                                                    <Button style={{margin: 0}}
                                                            onClick={this.openCreateInternalTargetSlideIn}>
                                                        {t("targetPage.help.createInternalTarget")}
                                                    </Button>
                                                </>} onUpdateEntry={this.openEditTargetSlideIn}/>
                                </>}
                        </ErrorBoundary>
                        <ModalSlideIn isOpen={this.state.isSlideInOpen} key={2}>
                            <Container style={{maxWidth: "800px", minWidth: "800px", padding: "4rem"}}>
                                <TargetCreate isInternal={this.state.isInternal} onClose={this.handleCloseSlideIn}
                                              id={this.state.id}/>
                            </Container>
                        </ModalSlideIn>
                    </ListContainer>
                }


            </div>
        );
    }
}

function mapDispatchToProps(dispatch: any) {
    let actions = bindActionCreators(
        {
            openAlertDialog: openAlertDialog
        },
        dispatch
    );
    return {...actions, dispatch};
}

function mapStateToProps(state) {
    return {};
}

export const TargetPage = compose(
    connect(mapStateToProps, mapDispatchToProps),
    withTranslation()
)(_TargetPage);

