import {useTranslation} from "react-i18next"
import React, {useEffect, useState} from "react"
import {Logger} from "../controller/loggingController"
import {JWT} from "../Helper/JWT"
import {Text} from "@greenbone/cloud-component-library"

/**
 * Calls a render prop with {isManaged, message} when passed a render prop
 * Renders the child components or a blocker message when used with a react component
 * @param children
 * @param message
 * @returns {*}
 * @constructor
 */
export function IsUserManaged({children, message}) {

    const {t} = useTranslation()
    const [isManaged, setManaged] = useState()

    useEffect(() => {
        try {
            const jwt = new JWT()
            setManaged(jwt.isManaged())
        } catch (e) {
            Logger.exception(e)
        }
    }, [])

    if (!message) {
        message = <Text>{t("onlyAvailableForManagedCustomers")}</Text>
    }

    if (typeof children === "function") {
        return children({isManaged, message})
    }

    if (isManaged) {
        return children
    }

    return message

}
