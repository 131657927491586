//@flow

import Promise from "bluebird"
import React from "react"
import {Trans, withTranslation} from "react-i18next"
import {connect} from "react-redux"
import {Col, Container, Row} from "reactstrap"
import {bindActionCreators, compose} from "redux"
import {ModalSlideIn} from "../../../Components/Dialogs/SlideIn/ModalSlideIn"
import Loader from "../../../Components/Loader/Loader"
import {openErrorSnackbar} from "../../../Components/Snackbar/actions"
import {Converter} from "../../../Helper/Converter"
import {AbortButton, Button, GhostButton, Snackbar} from "@greenbone/cloud-component-library"
import {Bold, Headline, Paragraph, Subheadline} from "../../../StyledComponents/Font/Font"
import {getValidationResult} from "../../../controller/FieldValidator"
import {IsNotEmptyString} from "../../../controller/FormValidators"
import {TargetRestApiClient} from "../../../services/apiClients/Target/TargetRestApiClient"
import {RowSmall} from "../components/Grid"
import {InfoBox, InfoHeadline} from "../components/InfoBox"
import {TargetCreate} from "../../Target/Form/TargetForm"
import {TargetSelect} from "../components/TargetSelect"


export type TargetFields = {
    targetId: ?string;
}

type Props = {
    nextStep: EventCallback;
    previousStep: EventCallback;
    cancelWizard: EventCallback;
    handleSaveAndNext: EventCallback;
    pageNumber: number;
    initValues: TargetFields;
    openErrorSnackbar: typeof openErrorSnackbar;
    t: any;
}

type State = {
    fields: TargetFields;
    fieldStatus: {
        targetId: ?boolean;
    };
    isValid: boolean;
    isTargetCreateOpen: boolean;
    _loading: boolean;
    _exception: ?any;
    targets: Array<GSPTarget>;
    isInternal: boolean;
}

class TargetCreateSlideIn extends React.Component<{ onClose: any, onSave: any, isInternal: boolean }> {
    render() {
        return <Container style={{minWidth: "800px", maxWidth: "800px"}}>
            <Row style={{margin: "1.0rem", marginTop: "2.5rem"}}>
                <Col>
                    <TargetCreate isInternal={this.props.isInternal} onClose={this.props.onClose}
                                  onSave={this.props.onSave}/>
                </Col>
            </Row>
        </Container>;
    }
}

class _Target extends React.Component<Props, State> {
    setStateAsync: Promise;
    targetApiClient: TargetRestApiClient;
    FieldValidators = {
        targetId: new IsNotEmptyString()
    };

    constructor(props: Props) {
        super(props);
        this.state = {
            fields: {...props.initValues},
            fieldStatus: {
                targetId: null,
                isInternal: false
            },
            isValid: false,
            isTargetCreateOpen: false,
            _loading: false,
            _exception: null,
            targets: [],
            isInternal: false
        };
        this.setStateAsync = Promise.promisify(this.setState);
        this.targetApiClient = new TargetRestApiClient();
    }

    componentDidMount() {
        this.loadTargets();
    }

    loadTargets = () => {
        this.setState({_loading: true});

        this.targetApiClient.getAll()
            .then((targets: Array<GSPTarget>) => {
                this.setState({
                    targets: targets?.filter(target => target.isInternal === false)?.sort((a: GSPTarget, b: GSPTarget) => {
                        return Converter.cmpString(a.name.toUpperCase(), b.name.toUpperCase());
                    })
                });
            })
            .catch(error => {
                this.setState({
                    _exception: error
                });
            })
            .finally(() => {
                this.setState({_loading: false});
            });
    };

    handleChangeTarget = (target: any) => {
        this.setState(prevState => {
            let fields = prevState.fields;
            fields["targetId"] = target.id;
            //$FlowFixMe
            fields["isInternal"] = target.isInternal;
            return {fields};
        });
    };

    validateFields = async (): Promise<void> => {
        const {isValid, fieldValidity} = getValidationResult(
            this.state.fields,
            this.FieldValidators
        );

        await this.setStateAsync({fieldStatus: fieldValidity, isValid});
    };

    onFormSubmit = (event: SyntheticEvent<HTMLFormElement>) => {
        event.preventDefault();

        this.validateFields()
            .then(() => {
                this.handleFormSubmit();
            });
    };

    handleFormSubmit = () => {
        const {t} = this.props;
        const {isValid, fieldStatus} = this.state;
        //$FlowFixMe
        const {targetId, isInternal} = this.state.fields;

        if (!isValid) {
            if (fieldStatus.targetId !== true) {
                Snackbar.Error(t("wizard.steps.schedule.chooseAHost"));
            }
            return;
        }

        this.props.handleSaveAndNext({
            targetId, isInternal
        });
    };

    handleTargetCreateOpen = () => {
        this.setState({isTargetCreateOpen: true, isInternal: false});
    };

    handleInternalTargetCreateOpen = () => {
        this.setState({isTargetCreateOpen: true, isInternal: true});
    };

    handleTargetCreateSaveAndClose = () => {
        this.handleTargetCreateClose();
        this.loadTargets();
    };

    handleTargetCreateClose = () => {
        this.setState({isTargetCreateOpen: false});
    };

    render() {
        const {cancelWizard, previousStep, t} = this.props;
        const {fields, isTargetCreateOpen, targets, _loading} = this.state;

        return <React.Fragment>
            <Row style={{marginBottom: "2rem"}}>
                <Col lg={9} md={12}>
                    <Headline>{t("wizard.steps.schedule.addHost")}</Headline>
                    <Paragraph>
                        {t("wizard.steps.schedule.addException")}
                    </Paragraph>
                </Col>
            </Row>

            <Row style={{marginBottom: "4rem"}}>
                <Col lg={{size: 9, order: 1}} md={{size: 12, order: 2}}>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-end",
                        marginBottom: "1rem"
                    }}>
                        <Subheadline
                            style={{marginBottom: "0"}}>{t("wizard.steps.schedule.HostAlreadyCreated")}</Subheadline>
                        <div>
                            <Button onClick={this.handleTargetCreateOpen}
                                    style={{margin: "0"}}>{t("wizard.steps.target.createNewHost")}</Button>
                            <Button onClick={this.handleInternalTargetCreateOpen}
                                    style={{
                                        display: "none",
                                        margin: "0",
                                        marginLeft: "1rem"
                                    }}>{t("wizard.steps.target.createNewInternalHost")}</Button>
                        </div>

                        <ModalSlideIn isOpen={isTargetCreateOpen}>
                            <TargetCreateSlideIn isInternal={this.state.isInternal}
                                                 onClose={this.handleTargetCreateClose}
                                                 onSave={this.handleTargetCreateSaveAndClose}/>
                        </ModalSlideIn>
                    </div>

                    <RowSmall>
                        {_loading ?
                            <Col><Loader/></Col>
                            :
                            <TargetSelect onChange={this.handleChangeTarget}
                                          selectedId={fields.targetId}
                                          targets={targets}/>
                        }
                    </RowSmall>
                </Col>
                <Col lg={{size: 3, order: 2}} md={{size: 12, order: 1}}>
                    <InfoBox type={"tip"}>
                        <Trans i18nKey={"wizard.steps.schedule.scanForIPAddress"}>
                            <InfoHeadline>Scannen von IP-Adressen</InfoHeadline>
                            <Paragraph>
                                Es dürfen nur IP-Adressen gescannt werden, für die eine Erlaubnis vorliegt. <Bold>Scannen
                                von Zielen ohne Erlaubnis ist ein Straftatbestand!</Bold>
                            </Paragraph>
                        </Trans>
                    </InfoBox>
                </Col>
            </Row>

            <Row>
                <Col lg={9} md={12}>
                    <div style={{display: "flex", justifyContent: "space-between"}}>
                        <GhostButton onClick={cancelWizard}>{t("common.action.abort")}</GhostButton>
                        <div style={{gridColumnGap: "2.3rem", display: "grid", gridTemplateColumns: "auto auto"}}>
                            <AbortButton onClick={previousStep}>{t("common.action.back")}</AbortButton>
                            <Button onClick={this.onFormSubmit}>{t("common.action.saveAndContinue")}</Button>
                        </div>
                    </div>
                </Col>
            </Row>
        </React.Fragment>;
    }
}

function mapDispatchToProps(dispatch: any) {
    let actions = bindActionCreators({
            openErrorSnackbar: openErrorSnackbar
        }, dispatch
    );
    return {...actions, dispatch};
}

function mapStateToProps(state) {
    return {};
}

export const Target = compose(
    withTranslation(),
    connect(mapStateToProps, mapDispatchToProps)
)(_Target);
