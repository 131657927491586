import React from "react"
import {Button, FlexRow, GhostButton, Input, Subheadline} from "@greenbone/cloud-component-library"
import {Col, Container, Row} from "reactstrap"
import {useForm} from "react-hook-form"
import {useTranslation} from "react-i18next"
import {
    createSchema,
    isAddressInsideSubnet,
    isAddressNotInsideSubnet,
    isMatch,
    isMax,
    isMin,
    isNoMatch,
    isNoSubnetOverlap,
    isNumber,
    isSubnet
} from "@greenbone/cloud-validation-library"


export function RouteForm({set, update, add, cancel, index, networkAddress}) {

    const {t, i18n} = useTranslation()
    const schema = createSchema({
        network: [
            isMatch(/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/),
            isNoMatch(/^0.0.0.0\/(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/, t("routeform.0000")),
            isSubnet(t("routeform.isNotANetwork")),
            isNoSubnetOverlap("100.64.0.0/10", t("routeform.100notallowed"))
        ],
        gateway: [
            isMatch(/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/),
            isAddressNotInsideSubnet("100.64.0.0/10", t("routeform.100notallowed")),
            isAddressInsideSubnet(networkAddress, `${t("gatewayform.mustbeinside")} ${networkAddress}`)
        ],
        metric: [isNumber(), isMax(255), isMin(1)]
    }, i18n.language)

    const {register, handleSubmit,
                formState: {errors},
          } = useForm({
                defaultValues: {
                    ...set
                },
            resolver: schema
          })

    const network = register('network')
    const gateway = register('gateway')
    const metric = register('metric')

    const onSubmit = (data, event) => {
        event.preventDefault()
        if (!set) {
            add(data)
        } else {
            update(data, index)
        }
    }


    return <Container style={{width: "45rem"}}>
        <Row>
            <Col>
                <Subheadline>{index === null ? t("routeform.newRoute") : t("routeform.editRoute")}</Subheadline>
            </Col>
        </Row>


        <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Col>
                    <Input margin={"normal"} label={t("routeform.network")} name={network.name} inputRef={network.ref}
                           onChange={network.onChange} isValid={errors?.network} helperText={"192.168.179.0/24"}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Input margin={"normal"} label={"Gateway"} name={gateway.name} inputRef={gateway.ref}
                           onChange={gateway.onChange} isValid={errors?.gateway} helperText={"192.168.179.254"}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Input margin={"normal"} label={t("routeform.metric")} name={metric.name} inputRef={metric.ref}
                           onChange={metric.onChange}  isValid={errors?.metric} helperText={"10"}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FlexRow justifyContent={"space-between"} style={{flexDirection: "row-reverse"}}>

                        <Button>{t("routeform.save")}</Button>
                        <GhostButton style={{marginLeft: 0}} onClick={event => {
                            event.preventDefault()
                            cancel()
                        }}>{t("routeform.cancel")}</GhostButton>
                    </FlexRow>
                </Col>
            </Row>
        </form>
    </Container>
}
