//@flow

import React from "react"
import {Caption, Subheadline} from "../../StyledComponents/Font/Font"
import {Button, FlexRow, StyleContext} from "@greenbone/cloud-component-library"
import {SketchPicker} from "react-color"
import styled from "styled-components"
import {InfoText} from "../../Components/Basic/InfoText"
import {Checkbox} from "../../StyledComponents/Form/Checkbox"

const Cover = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`

const CardContainer = styled.div`
  box-shadow: 0 .5em 1em -.125em rgba(10,10,10,.1),0 0 0 1px rgba(10,10,10,.02);
  max-width: 15rem;
  min-width: 15rem;
  border: 2px solid grey;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 1rem;


  header {
    display: flex;
    align-items: center;
    height: 3rem;
    padding-left: 1rem;
    padding-right: 1rem;
    justify-content: space-between;

    .colorBlock {
      border-radius: 3px;
      background: ${props => props.color};
      width: 1.5rem;
      height: 1.5rem;
      border: 1px solid grey;
    }

    .picker {
      position: relative;
      top: 2.215rem;
      left: -4.6rem;
    }
  }
`;

class Card extends React.Component<any, any> {
    state = {
        picking: false
    };

    handleClose = (event) => {
        event.preventDefault();
        this.setState({picking: false});
    };

    handleOpen = (event) => {
        this.setState({picking: true});
    };

    handleChange = (color) => {
        const {name, object, objectName} = this.props;

        this.props.onChange(objectName, {...object, [name]: color.hex});
    };


    render() {
        const props = this.props;

        return <CardContainer color={props.object[props.name]}>
            {this.state.picking ?
                <Cover onClick={this.handleClose}/>
                : ""}

            <header onClick={this.handleOpen}>
                <Caption noMargin>{props.name}</Caption>
                <div className="colorBlock">
                    {this.state.picking ?
                        <div className="picker">
                            <SketchPicker onChange={this.props.rawChange || this.handleChange}
                                          color={props.object[props.name]}/>
                        </div>
                        : ""}
                </div>
            </header>
        </CardContainer>;

    }
}


export class ColorPage extends React.Component<any, any> {

    render() {
        return <>
            <InfoText headline={"Experimental"}>
                <p>
                    Experimental area! Changes in this area will persisted in your browser. To reset the theme use the
                    "Theme reset" Button or clear your local browser storage. Furthermore, the Color Theme is not yet
                    defined in every component. Some components
                    are not affected by changes made here.
                </p>
            </InfoText>
            <div style={{height: "1rem"}}></div>

            <StyleContext.Consumer>
                {({updateTheme, button, alerts, bordered, reset, ...other}) => (
                    <>
                        <Checkbox checked={bordered} onClick={(event) => {
                            updateTheme("bordered", !bordered);
                        }} label={"Bordered Design"}/>

                        <Button onClick={(event) => {
                            event.preventDefault();
                            reset();
                        }}>Theme reset
                        </Button>

                        <FlexRow flexWrap={"wrap"}>
                            {Object.keys(other).map(key => (
                                <div key={key}>
                                    <Subheadline>{key}</Subheadline>
                                    {Object.keys(other[key]).map((area, index) => <Card key={index} name={area}
                                                                                        object={other[key]}
                                                                                        objectName={key}
                                                                                        onChange={updateTheme}/>)}
                                </div>
                            ))}

                            {Object.keys(button).map(key => (
                                <div key={key}>
                                    <Subheadline>button {key}</Subheadline>
                                    {Object.keys(button[key]).map((area, index) => <Card key={index} name={area}
                                                                                         object={button[key]}
                                                                                         objectName={key}
                                                                                         rawChange={(color) => {
                                                                                             updateTheme("button", {
                                                                                                 ...button, [key]: {
                                                                                                     ...button[key],
                                                                                                     [area]: color.hex
                                                                                                 }
                                                                                             });
                                                                                         }}/>)}
                                </div>
                            ))}
                        </FlexRow>

                    </>
                )}
            </StyleContext.Consumer>
        </>;
    }
}
