//@flow

import {withStyles, withTheme} from "@material-ui/core"
import {AbortButton, Button} from "@greenbone/cloud-component-library"
import classNames from "classnames"
import React from "react"
import {withTranslation} from "react-i18next"
import {compose} from "redux"
import {Display1} from "../../../Basic/Display"
import {Portal} from "../../../Portal"


const styles = theme => ({
    "@global": {
        ".fullWidthDialog-light": {
            backgroundColor: "rgba(0,0,0,0.5)"
        },
        ".fullWidthDialog-light .dialog": {
            backgroundColor: "white"
        },
        ".fullWidthDialog-dark": {
            backgroundColor: "rgba(0,0,0,0.5)",
            color: "white"
        },
        ".fullWidthDialog-dark .dialog": {
            backgroundColor: "#272727"
        },
        ".fullWidthDialog-dark .dialog-title-display": {
            color: "white"
        },
        ".fullWidthDialog-dark .dialog-actions button": {
            border: "2px solid"
        }

    },
    wrapper: {
        position: "fixed",
        top: "0",
        height: "100%",
        width: "100%",
        zIndex: "1350",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    dialog: {
        height: "33%",
        minHeight: "18.75rem",
        width: "100%",
        display: "flex",
        justifyContent: "center"
    },
    dialogInner: {
        display: "flex",
        flexDirection: "column",
        width: "50%",
        paddingTop: "1.25rem",
        paddingBottom: "1.25rem"

    },
    dialogTitle: {
        alignSelf: "flex-start"
    },
    dialogContent: {
        height: "100%"
    },
    dialogActions: {
        alignSelf: "flex-end"
    },
    button: {
        marginLeft: "0.63rem",
        marginTop: "0.63rem"
    },
    black: {
        color: "black"
    }
});

type Props = {
    classes: typeof styles;
    title: string,
    message: string,
    actions: any;
    showCancel: boolean;
    callback: any;
    onClose: any;
    open: boolean;
    t: any;
}

class _FullWidthDialog extends React.Component<Props, {}> {

    handleClose = () => {
        if (this.props.callback) {
            this.props.callback();
        }

        this.props.onClose();
    };

    render() {
        const {classes, t} = this.props;

        const rootStyle = "#root {filter: blur(2px)}";

        if (!this.props.open) {
            return null;
        }
        return (
            <Portal root={"dialog"}>
                <style>
                    {rootStyle}
                </style>

                <div className={classNames(classes.wrapper, "fullWidthDialog-light")}>
                    <div className={classNames(classes.dialog, "dialog")}>
                        <div className={classNames(classes.dialogInner, "dialog-inner")}>
                            <div className={classNames(classes.dialogTitle, "dialog-title")}>
                                <Display1 className={classNames(classes.black, "dialog-title-display")}>
                                    {this.props.title}
                                </Display1>
                            </div>
                            <div className={classes.dialogContent}>
                                {this.props.message}
                            </div>
                            <div className={classNames(classes.dialogActions, "dialog-actions")}>
                                <div>
                                    {this.props.showCancel && (
                                        <AbortButton className={classes.button}
                                                     variant={"contained"}
                                                     color={"primary"}
                                                     onClick={this.props.onClose}
                                        >
                                            {t("common.action.abort")}
                                        </AbortButton>)}

                                    <Button className={classes.button}
                                            variant={"contained"}
                                            color={"primary"}
                                            onClick={this.handleClose}
                                    >
                                        {t("common.action.ok")}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Portal>
        );
    }
}

export const FullWidthDialog = compose(
    withTheme,
    withTranslation(),
    withStyles(styles)
)(_FullWidthDialog);
