//@flow
import * as HttpStatuscode from "http-status-codes";
import {FORBIDDEN_CONSTANT} from "../../constants";

export class ForbiddenError extends Error {
    httpStatus = HttpStatuscode.FORBIDDEN;
    type = ForbiddenError;

    constructor(defaultMessage: string, message?: string) {
        super(message && message.includes(FORBIDDEN_CONSTANT) ? message : defaultMessage);
    }
}
