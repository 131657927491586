import React from "react";
import {DonutController} from "./DonutController";
import * as d3 from "d3";
import {Colors} from "../../../Colors";
import {MathHelper} from "../Helper/MathHelper";
import {DonutFactory} from "./DonutFactory";


export class StepDownDonut extends React.Component {

    constructor(props) {
        super(props);

        this.dimensions = {
            width: this.props.width,
            height: this.props.height
        };

        this.data = DonutController.ConvertDataForDrawing(this.props.data, this.props.accessor).reverse();
        this.radius = Math.min(this.dimensions.width, this.dimensions.height) / 2;
    }


    componentDidMount() {
        this.createBaseGraph();
        this.draw(DonutController.GenerateNullData(this.data));
        this.draw(this.data);
    }

    createBaseGraph() {
        const node = this.node;
        const g = d3.select(node)
            .attr("viewBox", `0 0 ${this.dimensions.width} ${this.dimensions.height}`)
            .append("g")
            .attr("transform", `translate(${this.dimensions.width / 2},${this.dimensions.height / 2})`);


        g.append("text")
            .text(`${MathHelper.PercentageString(
                DonutController.CalculateTotal(this.data, this.props.accessor)
                ,
                this.props.accessor(this.data[this.data.length - 1])
            )} %`)
            .attr("class", "main")
            .attr("font-size", "50")
            .attr("fill", Colors.high)
            .attr("dominant-baseline", "central")
            .style("text-anchor", "middle");

    }


    draw(data) {
        const node = this.node;
        const arc = DonutFactory.ArcFactory(this.radius);

        const arcTween = DonutFactory.ArcTweenFactoryR(this.radius);

        const pie = d3.pie()
            .value(d => d.count)
            .sort(null);

        d3.select(node).select("g")
            .select(".main")
            .transition()
            .text(`${MathHelper.PercentageString(
                DonutController.CalculateTotal(this.data, this.props.accessor)
                ,
                this.props.accessor(this.data[this.data.length - 1])
            )} %`);

        const g = d3.select(node).select("g")
            .attr("width", this.dimensions.width)
            .attr("height", this.dimensions.height)
            .attr("transform", `translate(${this.dimensions.width / 2},${this.dimensions.height / 2})`);

        const path = g.selectAll("path")
            .data(pie(data));
        path.transition().attrTween("d", arcTween);
        path.enter().append("path")
            .attr("fill", (d, i) => {
                return data[i].color;
            })
            .attr("d", arc)
            .each(function (d) {
                this._current = d;
            });

    }

    render() {
        return <svg style={{width: "100%", height: "100%"}} ref={node => this.node = node}>
        </svg>;
    }
}
