// @flow

import {VAT_RATE} from "../constants";
import {SIODateTime} from "../controller/SIODateTime";


export class Converter {
    static VAT: number = parseFloat(VAT_RATE);

    /**
     * Converts a number to string and adds leading symbols with given length
     *
     * @param value - number to convert
     * @param filler - filler symbol
     * @param length - resulting string length
     * @returns {string}
     */
    static numberToString(value: number, filler: string = "0", length: number = -1) {
        let result = String(value);
        while (result.length < length) {
            result = String(filler) + result;
        }
        return result;
    }

    static cmpNumber(a: any, b: any, asc: boolean = true, isNullLowest: boolean = true): number {
        let result = 0;
        if (!a) {
            if (b) {
                result = isNullLowest ? -1 : 1;
            }
        }
        else if (!b) {
            result = isNullLowest ? 1 : -1;
        }
        else {
            const small_or_greater = (a < b ? -1 : 1);
            result = (a === b ? 0 : small_or_greater);
        }
        return result * (asc ? 1 : -1);
    }

    static cmpString(a: any, b: any, asc: boolean = true, isNullLowest: boolean = true): number {
        return Converter.cmpNumber(a, b, asc, isNullLowest);
    }

    static cmpDate(a: ?SIODateTime | string, b: ?SIODateTime | string, asc: boolean = true, isNullLowest: boolean = true): number {
        const aString: ?string = a ? a.valueOf().toString() : null;
        const bString: ?string = b ? b.valueOf().toString() : null;

        return Converter.cmpString(aString, bString, asc, isNullLowest);
    }

    static toBoolean(value: any): boolean {
        return !!value;
    }

    static numberToPixel(pixel: number) {
        return `${pixel}px`;
    }

    static numberToRem(pixel: number): string {
        return `${pixel}rem`;
    }

    static ParamObjectToUrlParamString(params: { [string]: string }): string {
        const parts = [];
        Object.keys(params)
            .forEach(key => {
                parts.push(
                    `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`,
                );
            });
        return parts.join("&");
    }

    static centsToEuros(totalInCents: number): string {
        return (totalInCents / 100.0).toLocaleString("de-DE", {style: "currency", currency: "EUR"});
    }

    static asCurrency(amount: number, language, currency: string = "EUR"): string {
        return amount.toLocaleString(language, { style: "currency", currency: currency });
    }

    static getTax(amount: number, tax: number = Converter.VAT) {
        return amount * tax / 100.0;
    }

    static withTax(amount: number, tax: number = Converter.VAT) {
        return amount * tax / 100.0 + amount;
    }
}

export function chain(value: any, ...functions: Array<Function>) {
    let returnValue = value;
    functions.forEach(func => {
        returnValue = func(returnValue);
    });
    return returnValue;
}
