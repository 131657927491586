//@flow

import MaterialTableRow from "@material-ui/core/TableRow"
import React from "react"
import styled from "styled-components"


type Props = {
    children: any;
    element?: any;
}

const TableRow = styled(MaterialTableRow)`

   &:nth-of-type(odd){
      background: #FBFBFB
    }
`;

export class StyledTableRow extends React.Component<Props, {}> {

    render() {
        const element = this.props.element || TableRow;

        return React.createElement(
            element,
            this.props,
            this.props.children,
        );
    }
}
