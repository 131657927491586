import {Typography} from "@material-ui/core";
import React from "react";


function Display(props: { children: any, size: any }) {
    return (
        <Typography variant={`${props.size}`} gutterBottom {...props}>
            {props.children}
        </Typography>
    );
}


export const Display1 = (props: { children: any }) => (<Display size={"h4"} {...props}/>);

