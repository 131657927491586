import React from "react"
import {CardCvcElement, CardExpiryElement, CardNumberElement} from "@stripe/react-stripe-js"
import {Col, Row} from "reactstrap"
import styled from "styled-components"
import {Text, Tiny} from "@greenbone/cloud-component-library"
import {Validator} from "../../../../Helper/Validator"
import {useTranslation} from "react-i18next"


class _Form extends React.Component {

    state = {
        error: null
    }

    componentDidMount() {
        this.props.onRef(this)
    }

    handleSubmit = async (name) => {
        if (!Validator.isNotEmptyString(name)) {
            return
        }

        return await this.props.stripe.createToken(this.props.cardElement, {name})
            .then((response) => {
                if (response.error) {
                    this.setState({error: response.error.message})
                    return
                } else {
                    this.setState({error: null})
                }
                return response
            })
            .catch(error => {
                this.setState({error})
            })
    }

    render() {
        return (
            <form>
                <CardSection/>
                <Text color={"red"}>{this.state.error}</Text>
            </form>
        )
    }
}

const MaterializesStripeElement = styled.div`
  border-bottom: 1px solid grey;
`

const Label = styled(Tiny)`
  display: block;
  color: rgba(0,0,0, 0.54);
  margin-bottom: 0.5rem;
`

export const CardSection = props => {
    const {t} = useTranslation()

    return (
        <>
            <Row style={{marginBottom: "1.8rem"}}>
                <Col>
                    <Label>{t("creditCard.number")}</Label>
                    <MaterializesStripeElement as={CardNumberElement}/>
                </Col>
            </Row>
            <Row>
                <Col sm={3}>
                    <Label>{t("creditCard.expiryDate")}</Label>
                    <MaterializesStripeElement as={CardExpiryElement}/>
                </Col>
                <Col sm={3}>
                    <Label>{t("creditCard.cvc")}</Label>
                    <MaterializesStripeElement as={CardCvcElement}/>
                </Col>
            </Row>
        </>
    )

}

export const PaymentForm = (_Form)
