import React from "react"
import {Caption} from "@greenbone/cloud-component-library"
import {useTranslation} from "react-i18next"


function ExtendoBox({title, extend}) {


    return <>
        <Caption style={{color: "white"}}>{title}</Caption>
        <p>
            {extend}
        </p>

    </>
}

export function ScanConfigurationInfo({scanConfigurationId}) {
    const {t} = useTranslation()

    switch (scanConfigurationId) {

        case 1 : {
            return <ExtendoBox
                title={t("scanConfigurations.analysestandard.headline")}
                extend={t("scanConfigurations.analysestandard.text")}
            />
        }

        case 2 : {
            return <ExtendoBox
                title={t("scanConfigurations.analyseaggressive.headline")}
                extend={t("scanConfigurations.analyseaggressive.text")}
            />
        }

        case 3 : {
            return <ExtendoBox
                title={t("scanConfigurations.Discovery.headline")}
                extend={t("scanConfigurations.Discovery.text")}
            />
        }

        case 4 : {
            return <ExtendoBox
                title={t("scanConfigurations.HostDiscovery.headline")}
                extend={t("scanConfigurations.HostDiscovery.text")}
            />
        }

        case 5 : {
            return <ExtendoBox
                title={t("scanConfigurations.SystemDiscovery.headline")}
                extend={t("scanConfigurations.SystemDiscovery.text")}
            />
        }

        case 8 : {
            return <ExtendoBox

                title={t("scanConfigurations.analysestandard.noBruteforceHeadline")}
                extend={t("scanConfigurations.analysestandard.text")}
            />
        }

        default:
            return <div></div>
    }
}
