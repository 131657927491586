//@flow

import React from "react"
import {Trans} from "react-i18next"
import styled from "styled-components"
import Checkmark from "../../../../assets/icons/check-1.svg?url"
import ButtonPlay from "../../../../assets/icons/host-validation/check-circle-1_blau100.svg?url"
import Hourglass from "../../../../assets/icons/host-validation/hourglass_blau100.svg?url"
import Cross from "../../../../assets/icons/remove_red.svg?url"
import BlueShield from "../../../../assets/logos/Schild_Blau100.svg?url"


const StateIcon: GSPValidationStatus = {
    "INITIALISED": Hourglass,
    "REQUESTED": Hourglass,
    "FETCHED": ButtonPlay,
    "CONTACT_SELECTED": Hourglass,
    "ADMIN_SELECTED": BlueShield,
    "EMAIL_REQUESTED": Hourglass,
    "EMAIL_REQUESTED_FAILED": Cross,
    "EMAIL_SENT": Hourglass,
    "REJECTED": Cross,
    "APPROVED": Checkmark,
};

const StateTranslation = {
    INITIALISED: <Trans i18nKey={"hostValidation.components.statusText.initialised"}/>,
    REQUESTED: <Trans i18nKey={"hostValidation.components.statusText.requested"}/>,
    FETCHED: <Trans i18nKey={"hostValidation.components.statusText.fetched"}/>,
    CONTACT_SELECTED: <Trans i18nKey={"hostValidation.components.statusText.contactAdmin"}/>,
    ADMIN_SELECTED: <Trans i18nKey={"hostValidation.components.statusText.adminSelected"}/>,
    EMAIL_REQUESTED: <Trans i18nKey={"hostValidation.components.statusText.eMailRequested"}/>,
    EMAIL_REQUESTED_FAILED: <Trans i18nKey={"hostValidation.components.statusText.eMailRequestedFailed"}/>,
    EMAIL_SENT: <Trans i18nKey={"hostValidation.components.statusText.eMailSent"}/>,
    REJECTED: <Trans i18nKey={"hostValidation.components.statusText.rejected"}/>,
    APPROVED: <Trans i18nKey={"hostValidation.components.statusText.approved"}/>
};

const Icon = styled.img`
  height: 1.5rem;
`;

export const HostValidationStatusText = ({validationStatus}: any) => (
    <>
        <Icon src={StateIcon[validationStatus]} alt=""/> {StateTranslation[validationStatus]}
    </>
);
